/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';

import {
  formatPhone,
  formatLocation,
  getNumberWithUSAFormat,
  convert24to12hoursformat,
} from 'utils/format';
import {
  GRAY,
  BLACK,
  WHITE,
  GRAY_MID,
  GRAY_TEXT,
  GRAY_DARK,
  PURPLE_FEEK,
  PURPLE_LIGHT,
  GRAY_LIGHTEST,
} from 'styles/colors';
import {
  setMoments,
  setCampaigns,
  setFeekspots,
  setCategories,
  setExperiences,
  setCampaignTypes,
  setParticipations,
} from 'redux/entities';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import { weekDaysShort } from 'constants';
import DropDown from 'components/DropDown';
import handleError from 'utils/handleError';
import { setFeeker } from 'redux/app/feekApp';
import Button from 'components/buttons/Button';
import { isDayAvailable } from 'utils/campaigns';
import { ROUTES_FEEKAPP } from 'constants/routes';
import ModalAuth from 'components/modals/ModalAuth';
import copyToClipboard from 'utils/copyToClipboard';
import ModalShare from 'components/modals/ModalShare';
import { campaignTypeMiniIconsById } from 'constants';
import { MEDIA_QUERIES, Z_INDEX } from 'styles/constants';
import DistanceIndicator from 'components/DistanceIndicator';
import { ReactComponent as Zap } from 'assets/icons/Zap.svg';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import getClassNameFromArray from 'utils/getClassNameFromArray';
import ModalBooking from 'screens/feekApp/Campaign/ModalBooking';
import ModalStories from 'screens/feekApp/Campaign/ModalStories';
import { ReactComponent as Phone } from 'assets/icons/Phone.svg';
import { ReactComponent as Check } from 'assets/icons/Check.svg';
import { ReactComponent as Share } from 'assets/icons/Share.svg';
import ImagePlaceholder from 'assets/images/ImagePlaceholder.jpg';
import { ReactComponent as LinkIcon } from 'assets/icons/Link.svg';
import { headerHeight } from 'screens/feekApp/OutletFeekapp/Header';
import ModalStrikesCount from 'components/modals/ModalStrikesCount';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import ButtonLikeFeekspot from 'components/buttons/ButtonLikeFeekspot';
import { ReactComponent as StarMini } from 'assets/icons/StarMini.svg';
import { ReactComponent as Location } from 'assets/icons/Location.svg';
import { ReactComponent as ClockMini } from 'assets/icons/ClockMini.svg';
import { ReactComponent as Instagram } from 'assets/icons/Instagram.svg';
import ModalPrivateUser from 'screens/feekApp/Campaign/ModalPrivateUser';
import ModalTicketPhoto from 'screens/feekApp/Campaign/ModalTicketPhoto';
import BadgeExtraParticipations from 'components/BadgeExtraParticipations';
import ModalImagePreview from 'screens/feekApp/Campaign/ModalImagePreview';
import { ReactComponent as CalendarMini } from 'assets/icons/CalendarMini.svg';
import { ReactComponent as StackedSheets } from 'assets/icons/StackedSheets.svg';
import ModalMomentCompleted from 'screens/feekApp/Campaign/ModalMomentCompleted';
import ModalAddParticipation from 'screens/feekApp/Campaign/ModalAddParticipation';
import ModalMomentCancellation from 'screens/feekApp/Campaign/ModalMomentCancellation';
import ModalExperiencesSuggest from 'screens/feekApp/Campaign/ModalExperiencesSuggest';
import ProgressIndicatorSpinnerMessage from 'components/ProgressIndicatorSpinnerMessage';

const styles = css`
  --main-padding-top: 50px;
  flex: 1;

  padding: var(--main-padding-top) 40px;
  margin: 0 auto;
  max-width: 1480px;

  .flex-align-center-justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ranking-stars {
    gap: 5px;
  }

  .campaign-info {
    flex-direction: column;
    gap: 20px;

    * {
      line-height: 100%;
    }

    &,
    .campaign-type-wrapper,
    .days-container {
      display: flex;
    }

    .campaign-type-wrapper {
      align-items: center;
    }

    .days-container {
      align-items: center;
      margin-block-start: 4px;
    }

    .time-container {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .card-shadow {
    flex: 0 0 auto;
    width: 50%;
    height: fit-content;
    top: calc(var(--main-padding-top) + ${headerHeight.normal});
    position: sticky;
    padding: 40px 0 0;
    margin-bottom: 40px;
    border-radius: 20px;
    border: 1px solid ${GRAY_MID};
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .card-container {
      padding: 0 40px;
    }

    .card-footer {
      margin-top: 48px;
      padding: 32px;
      border-top: 1px solid ${GRAY_MID};
      gap: 24px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background-color: ${WHITE};

      .action-button {
        max-width: 240px;
      }
    }
  }

  .mb-20 {
    margin-bottom: 20px;
  }
  .mt-20 {
    margin-top: 20px;
  }

  .me-20 {
    margin-right: 20px;
  }

  .mb-40 {
    margin-bottom: 40px;
  }

  .category-badge {
    padding: 8px 10px;
    width: fit-content;
    border-radius: 16px;
    background-color: ${PURPLE_LIGHT};
  }

  .campaign-image {
    width: 100%;
    max-width: 724px;
    max-height: 543px;
    border-radius: 20px;
    object-fit: cover;
    margin-bottom: 20px;
  }

  .description {
    padding-bottom: 40px;
    border-bottom: 1px solid ${GRAY_LIGHTEST};
    margin-bottom: 40px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .card {
    padding: 40px;
    border-radius: 20px;
    border: 1px solid ${GRAY_MID};
    margin-bottom: 20px;

    &.tags {
      display: flex;
      flex-wrap: wrap;
      gap: 18px 20px;

      .title {
        flex: 1 0 100%;
        margin-block-end: 2px;
      }
    }
  }

  .suggestion {
    display: flex;
    align-items: center;
    margin-top: 14px;
    gap: 6px;
  }

  .about-feekspot {
    padding: 20px;
  }

  .menu-badge {
    gap: 6px;
    display: flex;
    padding: 8px 12px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background-color: ${PURPLE_FEEK};
  }

  .contact-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .contact-item:nth-of-type(1) {
    margin-bottom: 12px;
  }
  .contact-item:nth-of-type(2) {
    border-top: 1px solid ${GRAY_MID};
    padding-top: 12px;
  }
  .contact-info-icon {
    padding: 9px;
    border-radius: 8px;
    border: 1px solid ${GRAY_MID};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }

  .text-upper {
    text-transform: uppercase;
  }

  .icon-button {
    padding: 16px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${GRAY_MID};
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .day-badge {
    padding: 4px 6px;
    user-select: none;
    border-radius: 8px;
    border: 1px solid ${GRAY_MID};
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .day-badge.active {
    border: 1px solid ${BLACK};
  }

  .places-badge {
    padding: 8px 14px;
    width: fit-content;
    border-radius: 21px;
    border: 1px solid ${BLACK};
    white-space: nowrap;
  }

  .action-button {
    flex-grow: 0.7;
  }

  .approved-photo-badge {
    gap: 12px;
    display: flex;
    padding: 12px 30px;
    border-radius: 40px;
    align-items: center;
    justify-content: center;
    border: 2px solid ${BLACK};
  }

  .feekspot-info-title {
    margin-bottom: 20px;
  }

  .description {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .gap-12 {
    gap: 12px;
  }

  .border-bottom {
    border-bottom: 1px solid ${GRAY_LIGHTEST};
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .badge-extra-participations {
    position: absolute;
    margin-left: 12px;
    margin-top: 12px;
  }

  .hide-on-desktop {
    display: none;
  }

  .loading-message {
    align-self: center;
    border: none;
  }

  .button-cancel-moment {
    width: fit-content;
    margin-inline: auto;
    color: red;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-decoration: underline;
  }

  ${MEDIA_QUERIES.mobile} {
    padding: 16px 0 100px;

    .hide-on-mobile {
      display: none;
    }

    .hide-on-desktop {
      display: revert;
    }

    .ranking-stars {
      display: flex;
    }

    .description-wrapper {
      display: flex;
      flex-direction: column;
      padding-inline: 0;

      > *:not(.campaign-image) {
        padding-inline: 16px;
      }

      .campaign-image {
        width: calc(100% - 32px);
        margin: 0 16px 16px;
      }

      .badge-extra-participations {
        margin-left: 28px;
        margin-top: 12px;
      }

      > .card {
        margin-inline: 16px;
      }
    }

    .card-shadow {
      flex: 0;
      width: 0;
      height: 0;
      top: 0;
      position: fixed;
      padding: 0;
      margin-bottom: 0;
      border-radius: 0;
      border: none;
      box-shadow: none;

      .card-footer {
        position: fixed;
        left: 0;
        bottom: 0;
        padding: 16px 24px 25px;
        justify-content: space-between;
      }
    }

    .places-badge {
      font-size: 14px;
    }

    .mb-sm-20 {
      margin-bottom: 20px;
    }
    .title {
      font-size: 16px;
      margin-bottom: 8px;
    }
    .feekspot-info-title {
      font-size: 18px;
      margin-bottom: 16px;
    }

    .card {
      padding: 20px;
    }

    .story-title {
      font-size: 16px;
    }

    .mb-sm-20 {
      margin-bottom: 20px;
    }

    .campaign-info {
      &,
      .campaign-type-wrapper,
      .days-container {
        display: flex;
      }

      gap: 12px;

      .days-container {
        margin-block-start: 4px;
      }
    }

    .day-badge {
      width: 36px;
      font-size: 14px;
      padding: 4px 2px;
      border: 1px solid ${GRAY_MID};
    }

    .day-badge.active {
      border: 1px solid ${BLACK};
    }

    .cards-container {
      border-bottom: 1px solid ${GRAY_LIGHTEST};
      margin-bottom: 16px;
    }
  }
`;

const modalStrikesStyles = css`
  .modalMain {
    padding: 38px 32px;
    inline-size: 502px;

    .container {
      padding: 0;
      inline-size: fit-content;

      .title {
        text-align: center;
      }

      .strikes-visualization {
        width: 390px;
        background-color: ${PURPLE_LIGHT};

        .strikes-count .red-cross-wrapper .red-cross {
          background-color: ${PURPLE_FEEK};
        }
      }

      .button-close {
        margin: 58px auto 0;
      }
    }
  }
`;

const dropDownTagStyles = css`
  z-index: ${Z_INDEX.content};

  .content {
    backdrop-filter: none;
    transform: translate(-50%, calc(-100% - 8px));
    padding: 0;
    border-radius: 6px;

    :after {
      --arrow-size: 8px;
      content: '';
      position: absolute;
      bottom: calc(var(--arrow-size) * -1);
      left: calc(50% - var(--arrow-size));
      border-left: var(--arrow-size) solid transparent;
      border-right: var(--arrow-size) solid transparent;
      border-top: var(--arrow-size) solid ${WHITE};
    }

    :has(> .tag-option:nth-child(2):hover) {
      :after {
        --arrow-size: 8px;
        content: '';
        position: absolute;
        bottom: calc(var(--arrow-size) * -1);
        left: calc(50% - var(--arrow-size));
        border-left: var(--arrow-size) solid transparent;
        border-right: var(--arrow-size) solid transparent;
        border-top: var(--arrow-size) solid ${PURPLE_LIGHT};
      }
    }

    .tag-option {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${WHITE};
      width: 120px;
      height: 28px;
      white-space: nowrap;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      color: ${BLACK};

      :hover {
        background-color: ${PURPLE_LIGHT};
        opacity: 1;
        color: ${PURPLE_FEEK};
      }

      :first-child {
        border-block-end: 0.5px solid ${GRAY_MID};
        border-radius: 6px 6px 0 0;
      }
      :nth-child(2) {
        border-radius: 0 0 6px 6px;
        ${'' /* border-block-start: 0.5px solid ${GRAY_MID}; */}
      }
    }
  }
`;

export default function Campaign() {
  const [isMain, setIsMain] = useState(null);
  const [authModalData, setAuthModalData] = useState({
    show: false,
    title: '',
  });
  const [loadingCampaign, setLoadingCampaign] = useState(true);
  const [feekspotInfoOpen, setFeekspotInfoOpen] = useState(false);
  const [modalShown, setModalShown] = useState(null);
  const [tagMenuShown, setTagMenuShown] = useState('');

  const {
    moments,
    campaigns,
    feekspots,
    categories,
    campaignTypes,
    participations,
  } = useSelector((state) => state.entities);
  const {
    strike,
    id: feekerId,
    isInstagramPrivate,
  } = useSelector((state) => state.feekApp);

  const { id: campaignId } = useParams();
  const fileRef = useRef(null);
  const dispatch = useDispatch();

  const campaign = campaigns[campaignId];
  const feekspot = feekspots[campaign?.customer]?.customer;

  const momentAgended = useMemo(
    () =>
      [...Object.values(moments)]?.find(
        (moment) =>
          campaignId === (moment.campaign || moment.campaign_id),
      ),
    [moments],
  );
  const isMomentActive = useMemo(
    () =>
      momentAgended?.status === 'Agendado' ||
      momentAgended?.status === 'Pending Verification',
    [momentAgended],
  );

  const shareUrl = useMemo(
    () =>
      `${window.location.protocol}//${window.location.hostname}/share/campaign/${campaignId}`,
    [],
  );

  document.title = [feekspot?.name, campaign?.title]
    .filter((value) => value)
    .join(' | ');

  useEffect(() => {
    setLoadingCampaign(true);

    APIClient.getCampaign(campaignId)
      .then(({ data }) => {
        dispatch(setCampaigns([data]));
        return data;
      })
      .then((campaign) =>
        APIClient.getFeekspotForFeeker(campaign.customer).then(
          ({ data }) => dispatch(setFeekspots([{ customer: data }])),
        ),
      )
      .then(() => {
        APIClient.getCampaignTypes().then(({ data }) =>
          dispatch(setCampaignTypes(data)),
        );
        APIClient.getCategories().then(({ data }) =>
          dispatch(setCategories(data)),
        );
      })
      .catch(handleError)
      .finally(() => setLoadingCampaign(false));
  }, []);

  useEffect(() => {
    if (campaign && feekerId)
      APIClient.getMomentsByUser({
        feekerId,
        status: 'Agendado',
      })
        .then(({ data: { docs } }) => {
          dispatch(
            setMoments(
              docs.map(({ moment: crrMoment }) => crrMoment),
            ),
          );
        })
        .then(() => APIClient.getInstagramAccountStatus())
        .then(({ data }) => {
          dispatch(
            setFeeker({
              isInstagramPrivate: data.is_private,
            }),
          );
          if (data.is_private) {
            setModalShown(modals.privateUser);
          }
        });
  }, [feekerId, campaign]);

  const openAgendar = (title) => {
    if (feekerId) {
      setModalShown(modals.booking);
    } else {
      setAuthModalData({
        show: true,
        title: typeof title === 'string' ? title : undefined,
      });
      setModalShown(modals.auth);
    }
  };

  const selectPhoto = (isMain) => {
    setIsMain(isMain);
    if (isInstagramPrivate) {
      fileRef.current.click();
    } else {
      setModalShown(modals.stories);
    }
  };

  const uploadImage = () => {
    setLoadingCampaign(true);
    const image = fileRef.current.files[0];
    APIClient.uploadToS3(image, 'moments_local_images')
      .then(async (resp) => {
        const { data } = isMain
          ? await APIClient.validateTaskLocalMoment(
              momentAgended?._id ?? '',
              resp.data.Location,
            )
          : await APIClient.validateExtraTaskLocalMoment(
              momentAgended?._id ?? '',
              resp.data.Location,
            );
        fileRef.current.value = null;
        setModalShown(null);
        dispatch(setMoments([data]));
      })
      .catch(handleError)
      .finally(() => {
        setLoadingCampaign(false);
      });
  };

  const onCompleteMoment = () => {
    APIClient.getParticipationsByUser({
      status: 'ACTIVE',
    })
      .then(async ({ data }) => {
        dispatch(
          setParticipations(
            data?.docs?.map((item) => item.participation),
          ),
        );
        dispatch(
          setExperiences(data?.docs?.map((item) => item.experience)),
        );
        if (data.totalDocs === 1) {
          await APIClient.bookExperience(
            data.docs[0].experience._id,
            true,
            momentAgended._id,
            momentAgended.campaign_id,
          );
          setModalShown(modals.momentCompleted);
        } else if (data.totalDocs > 1) {
          setModalShown(modals.addParticipation);
        } else {
          setModalShown(modals.momentCompleted);
        }
        setModalShown(modals.ticketPhoto);
      })
      .catch(handleError);
  };

  const onSelectStory = async (story) => {
    try {
      setLoadingCampaign(true);
      const { status, data } = isMain
        ? await APIClient.validateTaskMoment(
            momentAgended?._id ?? '',
            story.id,
            feekerId,
          )
        : await APIClient.validateExtraMoment(
            momentAgended?._id ?? '',
            story.id,
            feekerId,
          );

      if (status === 201) dispatch(setMoments([data]));
    } catch (error) {
      handleError(error);
    } finally {
      setModalShown(null);
      setLoadingCampaign(false);
    }
  };

  const closeModal = () => setModalShown(null);

  const openModalStrikesCount = (showStrikesCount, strikesAdded) =>
    setModalShown(
      !showStrikesCount ? null : (
        <ModalStrikesCount
          visible
          cssProp={modalStrikesStyles}
          strikesCount={strike + strikesAdded}
          onRequestClose={closeModal}
          title="Lugar cancelado"
          noStrikesData={!strikesAdded}
          message={
            <>
              {strikesAdded ? (
                <Text align="center" fontSize={20} lineHeight={32}>
                  Esta vez no cancelaste <u>4 horas antes </u>
                  de la fecha y hora agendadas, así que tendrás un
                  <strong> Strike.</strong>
                </Text>
              ) : (
                <Text align="center" fontSize={20} lineHeight={32}>
                  Gracias por cancelar
                  <u> antes de 4 horas </u>
                  de la fecha y hora agendadas, esta vez no tienes
                  ningún
                  <strong> Strike.</strong>
                </Text>
              )}

              <ButtonPrimary
                className="button-close"
                label="Entendido"
                onClick={closeModal}
                width={390}
              />
            </>
          }
        />
      ),
    );

  const modals = useMemo(
    () => ({
      auth: (
        <ModalAuth
          visible
          title={authModalData.title}
          onRequestClose={() => {
            setAuthModalData({ show: false, title: '' });
            closeModal();
          }}
        />
      ),
      ...(feekerId && campaign && feekspot
        ? {
            ...(isInstagramPrivate
              ? {
                  privateUser: (
                    <ModalPrivateUser
                      visible
                      campaignId={campaign?._id}
                      onRequestClose={closeModal}
                    />
                  ),
                }
              : null),

            addParticipation: (
              <ModalAddParticipation
                visible
                campaignId={campaign?._id}
                momentId={momentAgended?._id}
                onRequestClose={(isMomentComplete) => {
                  setModalShown(
                    isMomentComplete ? modals.momentCompleted : null,
                  );
                }}
              />
            ),

            experiencesSuggest: (
              <ModalExperiencesSuggest
                visible
                campaignId={campaign?._id}
                onRequestClose={closeModal}
              />
            ),

            momentCompleted: (
              <ModalMomentCompleted
                visible
                onRequestClose={() =>
                  setModalShown(
                    Object.values(participations).length === 0
                      ? modals.experiencesSuggest
                      : null,
                  )
                }
              />
            ),

            share: (
              <ModalShare
                visible
                url={shareUrl}
                body={
                  <div className="d-flex justify-content-start align-items-center mt-4 pt-1">
                    <img
                      src={campaign?.image || ImagePlaceholder}
                      alt="campaign image"
                      className="image me-3"
                    />
                    <div>
                      <Text
                        fontSize={16}
                        color={BLACK}
                        fontWeight={600}
                      >
                        {campaign?.title}
                      </Text>
                      <Text fontSize={16} color={GRAY_TEXT}>
                        {[
                          feekspot?.name,
                          [
                            feekspot?.location?.city,
                            feekspot?.location?.state,
                          ]
                            .filter(Boolean)
                            .join(', '),
                        ]
                          .filter(Boolean)
                          .join(' · ')}
                      </Text>
                    </div>
                  </div>
                }
                header="Comparte esta promoción"
                onRequestClose={closeModal}
                messageText={`Agenda y disfruta de ${campaign?.title} en ${feekspot?.name} y descubre todos los beneficios que Feek tiene para ti.`}
              />
            ),

            booking: (
              <ModalBooking
                visible
                endTime={campaign?.end_time}
                startTime={campaign?.start_time}
                campaignId={campaign?._id}
                onRequestClose={closeModal}
                availableDays={campaign?.days}
                feekspotBranches={feekspot?.branches || []}
                campaignBranches={campaign?.branches || []}
                momentId={momentAgended?._id}
                momentDate={momentAgended?.reservation_day}
                momentTime={momentAgended?.reservation_time}
              />
            ),

            ...(isMomentActive
              ? {
                  ticketPhoto: (
                    <ModalTicketPhoto
                      visible
                      momentId={momentAgended?._id}
                      campaignId={campaign?._id}
                      onSuccess={onCompleteMoment}
                      onRequestClose={closeModal}
                    />
                  ),

                  stories: (
                    <ModalStories
                      visible
                      isMain={isMain}
                      campaignId={campaign?._id}
                      onRequestClose={closeModal}
                      onSelectStory={onSelectStory}
                    />
                  ),

                  imagePreview: (
                    <ModalImagePreview
                      visible
                      disabled={loadingCampaign}
                      onRequestClose={() => {
                        fileRef.current.value = null;
                        closeModal();
                      }}
                      image={fileRef?.current?.files?.[0]}
                      onConfirm={uploadImage}
                    />
                  ),

                  cancelMoment: (
                    <ModalMomentCancellation
                      visible
                      momentId={momentAgended?._id}
                      disabled={loadingCampaign}
                      onRequestClose={openModalStrikesCount}
                      image={fileRef?.current?.files?.[0]}
                      onConfirm={uploadImage}
                    />
                  ),
                }
              : null),
          }
        : null),
    }),
    [
      feekerId,
      campaign,
      feekspot,
      isMomentActive,
      isInstagramPrivate,
    ],
  );

  return (
    <>
      {modalShown}

      <main className="row" css={styles}>
        {campaign ? (
          <>
            <div className="description-wrapper col-md-6">
              <div className="flex-align-center-justify-between mb-20 hide-on-mobile">
                <div className="d-flex align-items-center">
                  <RankingStars
                    qualification={
                      feekspot?.metrics.qualification || 0
                    }
                  />
                  <Text color={BLACK} fontSize={16}>
                    ·{' '}
                    {[
                      feekspot?.location?.city,
                      feekspot?.location?.state,
                    ]
                      .filter((item) => item)
                      .join(', ')}
                  </Text>
                </div>

                {categories[campaign?.categories?.[0]]?.name && (
                  <Text
                    className="category-badge"
                    color={GRAY_TEXT}
                    fontSize={16}
                    fontWeight={500}
                  >
                    {categories[campaign?.categories?.[0]]?.name}
                  </Text>
                )}
              </div>

              {campaign?.participation_experiences && (
                <BadgeExtraParticipations
                  className="badge-extra-participations"
                  participations={
                    campaign.participation_experiences || 0
                  }
                />
              )}

              <img
                src={campaign?.image || ImagePlaceholder}
                className="campaign-image"
                alt={`Feek ${campaign?.title} ${feekspot?.name}`}
              />

              <CampaignInfo
                typeName={campaignTypes[campaign?.type]?.name}
                className="hide-on-desktop border-bottom"
                campaign={campaign}
                feekspot={feekspot}
              />

              <Text
                color={BLACK}
                fontSize={24}
                className="title"
                fontWeight={600}
              >
                {campaign?.places === 0 && !isMomentActive
                  ? 'Finalizada'
                  : 'Descripción'}
              </Text>

              <Text
                color={BLACK}
                fontSize={16}
                className="description"
              >
                {campaign?.description}
              </Text>

              <div className="cards-container">
                <div className="card tags">
                  <Text
                    color={BLACK}
                    fontSize={24}
                    className="title"
                    fontWeight={600}
                  >
                    En esta promoción debes etiquetar a:
                  </Text>

                  {campaign.tags?.map((tag, i) => (
                    <DropDown
                      key={i + tag}
                      isOpen={tagMenuShown === i + tag}
                      onOpen={() => setTagMenuShown(i + tag)}
                      onClose={() => setTagMenuShown('')}
                      childrenWrapperCSS={dropDownTagStyles}
                      position="top"
                      icon={
                        <ButtonPrimary
                          layoutOnly
                          hideHoverActiveStyles
                          label={'@' + tag}
                          width="fit-content"
                          pillShape
                          color={GRAY_DARK}
                          gap={6}
                          icon={
                            <Icon icon={Instagram} color={WHITE} />
                          }
                        />
                      }
                    >
                      <>
                        <a
                          className="tag-option"
                          href={`https://www.instagram.com/${tag}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => setTagMenuShown('')}
                        >
                          Ir a Instagram
                        </a>
                        <Button
                          className="tag-option"
                          onClick={() => {
                            copyToClipboard(`@${tag}`);
                            setTagMenuShown('');
                          }}
                        >
                          Copiar username
                        </Button>
                      </>
                    </DropDown>
                  ))}
                </div>

                <div className="card">
                  <div className="flex-align-center-justify-between">
                    <Text
                      color={BLACK}
                      fontSize={24}
                      fontWeight={600}
                      className="story-title"
                    >
                      Historia Principal
                    </Text>
                    <div className="flex-align-center-justify-between">
                      <Icon
                        icon={Zap}
                        color={PURPLE_FEEK}
                        size={20}
                      />
                      <Text
                        fontSize={16}
                        fontWeight={600}
                        color={PURPLE_FEEK}
                      >
                        +{campaign?.task?.feek_points}
                      </Text>
                    </div>
                  </div>

                  {campaign?.task?.suggestions?.map((suggestion) => (
                    <div key={suggestion} className="suggestion">
                      <Icon
                        icon={Check}
                        color={PURPLE_FEEK}
                        size={11}
                      />
                      <Text color={BLACK} fontSize={16}>
                        {suggestion}
                      </Text>
                    </div>
                  ))}
                  {isMomentActive && (
                    <>
                      <input
                        type="file"
                        className="d-none"
                        ref={fileRef}
                        accept="image/*"
                        onChange={() =>
                          setModalShown(modals.imagePreview)
                        }
                      />
                      {momentAgended?.task_validate ? (
                        <ApprovedPhotoBadge />
                      ) : (
                        <ButtonPrimary
                          pillShape
                          color={BLACK}
                          className="mt-3"
                          label="Subir foto"
                          onClick={() => selectPhoto(true)}
                          disabled={momentAgended?.task_validate}
                        />
                      )}
                    </>
                  )}
                </div>

                {campaign?.extra_points && (
                  <div className="card">
                    <div className="flex-align-center-justify-between">
                      <Text
                        color={BLACK}
                        fontSize={24}
                        fontWeight={600}
                        className="story-title"
                      >
                        Historia Extra
                      </Text>
                      <div className="flex-align-center-justify-between">
                        <Icon
                          icon={Zap}
                          color={PURPLE_FEEK}
                          size={20}
                        />
                        <Text
                          fontSize={16}
                          fontWeight={600}
                          color={PURPLE_FEEK}
                        >
                          +{campaign?.extra_points?.feek_points}
                        </Text>
                      </div>
                    </div>

                    {campaign?.extra_points?.suggestions?.map(
                      (suggestion) => (
                        <div key={suggestion} className="suggestion">
                          <Icon
                            icon={Check}
                            color={PURPLE_FEEK}
                            size={11}
                          />
                          <Text color={BLACK} fontSize={16}>
                            {suggestion}
                          </Text>
                        </div>
                      ),
                    )}

                    {isMomentActive && (
                      <>
                        {momentAgended?.extra_points_validate ? (
                          <ApprovedPhotoBadge />
                        ) : (
                          <ButtonPrimary
                            label="Subir foto"
                            color={BLACK}
                            className="mt-3"
                            pillShape
                            disabled={
                              momentAgended?.extra_points_validate
                            }
                            onClick={() => selectPhoto()}
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>

              <Text
                color={BLACK}
                fontSize={24}
                fontWeight={600}
                className="feekspot-info-title"
              >
                Acerca del Feekspot
              </Text>

              <div className="card about-feekspot mb-40 ">
                <Button
                  className="flex-align-center-justify-between cursor-pointer"
                  onClick={() => setFeekspotInfoOpen((crr) => !crr)}
                >
                  <div className="flex-align-center-justify-between">
                    <Avatar
                      borderWidth={2}
                      size={80}
                      className="me-20"
                      image={feekspot?.image || ImagePlaceholder}
                      alt={'Feek ' + feekspot?.name}
                    />
                    <div>
                      <Text
                        fontSize={24}
                        color={BLACK}
                        fontWeight={600}
                      >
                        {feekspot?.name}
                      </Text>
                      <Text
                        fontSize={16}
                        color={GRAY_DARK}
                        fontWeight={500}
                      >
                        {[
                          feekspot?.location?.city,
                          feekspot?.location?.state,
                        ]
                          .filter((item) => item)
                          .join(', ')}
                      </Text>
                    </div>
                  </div>
                  <Icon
                    icon={Chevron}
                    size={14}
                    color={BLACK}
                    rotate={feekspotInfoOpen ? 180 : 0}
                  />
                </Button>

                {feekspotInfoOpen && (
                  <div className="mt-4">
                    {feekspot?.menu && (
                      <div className="flex-align-center-justify-between mb-4">
                        <Text
                          color={BLACK}
                          fontSize={16}
                          fontWeight={600}
                        >
                          Lo que ofrecemos
                        </Text>
                        <a
                          className="menu-badge"
                          href={feekspot?.menu}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Icon icon={LinkIcon} color={WHITE} />
                          <Text
                            color={WHITE}
                            fontSize={14}
                            fontWeight={600}
                          >
                            Ver nuestro menú
                          </Text>
                        </a>
                      </div>
                    )}
                    <div className="feekspot-contact-info">
                      {feekspot?.location && (
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${feekspot?.location?.coordinates?.toReversed()}`}
                          className="contact-item"
                        >
                          <div className="d-flex align-items-center">
                            <div className="contact-info-icon me-3">
                              <Icon
                                icon={Location}
                                color={BLACK}
                                size={22}
                              />
                            </div>
                            <Text color={BLACK} fontSize={16}>
                              {formatLocation(feekspot?.location)}
                            </Text>
                          </div>
                          <div
                            onClick={() =>
                              copyToClipboard(
                                formatLocation(feekspot?.location),
                              )
                            }
                            className="cursor-pointer"
                          >
                            <Icon
                              size={24}
                              color={GRAY_DARK}
                              icon={StackedSheets}
                            />
                          </div>
                        </a>
                      )}
                      {feekspot?.phone && (
                        <div className="contact-item">
                          <div className="d-flex align-items-center">
                            <div className="contact-info-icon me-3">
                              <Icon
                                icon={Phone}
                                color={BLACK}
                                size={22}
                              />
                            </div>
                            <Text color={BLACK} fontSize={16}>
                              {formatPhone(feekspot?.phone)}
                            </Text>
                          </div>
                          <div
                            className="cursor-pointer"
                            onClick={() =>
                              copyToClipboard(feekspot?.phone)
                            }
                          >
                            <Icon
                              size={24}
                              color={GRAY_DARK}
                              icon={StackedSheets}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {isMomentActive && (
                <Button
                  onClick={() => setModalShown(modals.cancelMoment)}
                  className="button-cancel-moment"
                >
                  Cancelar lugar
                </Button>
              )}
            </div>

            <div className="card-shadow">
              <div className="card-container hide-on-mobile">
                <div className="flex-align-center-justify-between mb-20 flex-wrap">
                  <Link
                    to={ROUTES_FEEKAPP.PROFILE_FEEKSPOT.replace(
                      /:\w*/,
                      feekspot?.instagram?.username,
                    )}
                  >
                    <Button
                      layoutOnly
                      className="d-flex align-items-center"
                    >
                      <Avatar
                        borderWidth={2}
                        size={60}
                        image={feekspot?.image || ImagePlaceholder}
                        alt={'Feek ' + feekspot?.name}
                      />
                      <Text
                        color={BLACK}
                        fontSize={24}
                        fontWeight={500}
                        className="text-upper px-2"
                      >
                        {feekspot?.name}
                      </Text>
                    </Button>
                  </Link>

                  <div className="d-flex">
                    {feekspot && (
                      <ButtonLikeFeekspot
                        dark
                        className="icon-button me-20"
                        feekspotId={feekspot?._id}
                        isLiked={feekspot?.is_liked}
                        {...(!feekerId && {
                          onClick: () =>
                            openAgendar(
                              'Inicia sesión para agregar a favoritos',
                            ),
                        })}
                      />
                    )}
                    <Button
                      className="icon-button"
                      onClick={() => setModalShown(modals.share)}
                    >
                      <Icon icon={Share} color={BLACK} size={18} />
                    </Button>
                  </div>
                </div>

                <CampaignInfo
                  typeName={campaignTypes[campaign?.type]?.name}
                  campaign={campaign}
                  feekspot={feekspot}
                />
              </div>

              <div className="card-footer">
                {isMomentActive ? (
                  <>
                    <ButtonPrimary
                      pillShape
                      strokeColor={BLACK}
                      textColor={BLACK}
                      strokeVariant
                      label="Editar"
                      className="action-button"
                      onClick={openAgendar}
                      disabled={
                        loadingCampaign ||
                        !momentAgended?.edit_enabled ||
                        momentAgended?.task_validate
                      }
                    />
                    <ButtonPrimary
                      pillShape
                      label="Validar"
                      className="action-button"
                      onClick={() =>
                        setModalShown(modals.ticketPhoto)
                      }
                      disabled={
                        loadingCampaign ||
                        !momentAgended?.task_validate
                      }
                    />
                  </>
                ) : (
                  (momentAgended === null &&
                    campaign?.places === 0 && (
                      <>
                        <Text
                          fontSize={16}
                          color={BLACK}
                          fontWeight={500}
                          className="col-lg-6 d-flex align-items-center justify-content-center"
                        >
                          Promoción finalizada
                        </Text>
                        <Link
                          className="col-lg-6"
                          to={ROUTES_FEEKAPP.PROFILE_FEEKSPOT.replace(
                            /:\w*/,
                            feekspot?.instagram?.username,
                          )}
                        >
                          <ButtonPrimary
                            pillShape
                            layoutOnly
                            color={BLACK}
                            textColor={WHITE}
                            className="action-button"
                            label="Explorar Feekspot"
                          />
                        </Link>
                      </>
                    )) || (
                    <>
                      <Text
                        color={BLACK}
                        fontSize={16}
                        fontWeight={500}
                        className="places-badge"
                      >
                        {campaign?.places} Lugares
                      </Text>

                      <ButtonPrimary
                        disabled={loadingCampaign}
                        heightMobile={48}
                        label="Agendar lugar"
                        className="action-button"
                        pillShape
                        onClick={openAgendar}
                      />
                    </>
                  )
                )}
              </div>
            </div>
          </>
        ) : (
          <ProgressIndicatorSpinnerMessage
            message="Cargando campaña"
            className="loading-message"
            size={50}
            fontSize={24}
          />
        )}
      </main>
    </>
  );
}

const RankingStars = ({ qualification, className }) => {
  return (
    <div
      className={getClassNameFromArray([
        className,
        'ranking-stars flex-align-center-justify-between',
      ])}
    >
      <Icon icon={StarMini} color={BLACK} size={16} />
      <Text color={BLACK} fontSize={16} lineHeight={16}>
        {getNumberWithUSAFormat(qualification)}
      </Text>
    </div>
  );
};

const ApprovedPhotoBadge = () => {
  return (
    <div className="approved-photo-badge mt-3">
      <Text fontSize={16} color={BLACK} fontWeight={600}>
        Foto Aprobada
      </Text>
      <Icon icon={Check} color={BLACK} size={14} />
    </div>
  );
};

const CampaignInfo = ({
  campaign,
  feekspot,
  className,
  typeName = '',
}) => (
  <div
    className={getClassNameFromArray(['campaign-info', className])}
  >
    <Text
      color={BLACK}
      fontSize={24}
      fontSizeMobile={20}
      fontWeight={600}
      className="campaign-title"
    >
      {campaign?.title}
    </Text>

    <div className="campaign-type-wrapper">
      <Icon
        className="me-1"
        height={24}
        heightMobile={16}
        color={GRAY}
        icon={campaignTypeMiniIconsById[campaign?.type] || Zap}
      />
      <Text
        fontSize={16}
        fontSizeMobile={14}
        fontWeight={600}
        color={GRAY}
        className="me-3"
      >
        {typeName}
      </Text>
      <Icon
        className="me-1"
        icon={Zap}
        color={GRAY}
        height={24}
        heightMobile={16}
      />
      <Text
        fontSize={16}
        fontSizeMobile={14}
        fontWeight={600}
        color={GRAY}
      >
        +{campaign?.task?.feek_points}
      </Text>
    </div>

    <div className="days-container">
      <Icon icon={CalendarMini} color={BLACK} size={16} />
      <div className="d-flex flex-wrap">
        {Object.keys(weekDaysShort).map((day) => {
          const currentDayAvailable = isDayAvailable(
            campaign?.days,
            day,
          );
          return (
            <Text
              className={`day-badge ${
                currentDayAvailable && 'active'
              }  ms-2`}
              key={day}
              color={currentDayAvailable ? BLACK : GRAY_MID}
              fontSize={16}
              fontWeight={500}
            >
              {weekDaysShort[day]}
            </Text>
          );
        })}
      </div>
    </div>

    <div className="d-flex align-items-center gap-12">
      <div className="time-container">
        <Icon
          size={16}
          color={BLACK}
          sizeMobile={14}
          icon={ClockMini}
        />
        <Text color={BLACK} fontSize={16} lineHeight={16}>
          {convert24to12hoursformat(campaign?.start_time)} a{' '}
          {convert24to12hoursformat(campaign?.end_time)}
        </Text>
      </div>
      <DistanceIndicator
        color={BLACK}
        fontSize={16}
        iconHeight={16}
        distance={feekspot?.distance}
      />
      <RankingStars
        className="hide-on-desktop"
        qualification={feekspot?.metrics.qualification}
      />
    </div>
  </div>
);

CampaignInfo.propTypes = {
  className: PropTypes.string,
  campaign: PropTypes.object,
  feekspot: PropTypes.object,
  typeName: PropTypes.string,
};

RankingStars.propTypes = {
  className: PropTypes.string,
  qualification: PropTypes.number,
};
