/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';

import APIClient from 'redux/api';
import Text from 'components/Text';
import { toast } from 'react-toastify';
import Avatar from 'components/Avatar';
import handleError from 'utils/handleError';
import Modal from 'components/modals/Modal';
import { setFeeker } from 'redux/app/feekApp';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import InputText from 'components/inputs/InputText';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { BLACK, GRAY, GRAY_DARK, GRAY_LIGHTEST } from 'styles/colors';
import ProgressIndicatorSpinner from 'components/ProgressIndicatorSpinner';

const styles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  inline-size: 400px;
  padding: 12px 24px 24px;

  .cancel-button {
    text-decoration: underline;
  }

  .instagramer {
    display: flex;
    align-items: center;
    gap: 10.5px;
    width: 100%;
    height: 88px;
    border: 1px solid ${GRAY_LIGHTEST};
    border-radius: 10px;
    padding: 20px 16px;
  }

  ${MEDIA_QUERIES.mobile} {
    gap: 16px;
  }
`;

let abortController = null;
let typingTimer = null;

function ModalInstagramVinculation({
  onRequestClose,
  visible,
  ...restProps
}) {
  const dispatch = useDispatch();
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUserSelected, setisUserSelected] = useState(false);

  const handleOnChange = async ({ target: { value } }) => {
    clearTimeout(typingTimer);
    abortController?.abort('New user search');
    setLoading(!!value && !user);
    if (!value) return;

    setUser(null);
    typingTimer = setTimeout(async () => {
      abortController = await new AbortController();

      APIClient.searchInstagramUser(
        value.trim(),
        abortController.signal,
      )
        .then(
          ({
            data: { username, full_name: fullName, avatar_base_64 },
          }) => setUser({ username, fullName, avatar_base_64 }),
        )
        .catch((e) => {
          handleError(
            abortController?.signal?.aborted
              ? `Aborted fetch. Reason: ${abortController.signal.reason}`
              : e,
          );
        })
        .finally(() => setLoading(abortController?.signal?.aborted));
    }, 1000);
  };

  function confirmUser() {
    APIClient.linkInstagram(user.username)
      .then(() => {
        dispatch(setFeeker({ instagramUserName: user.username }));
        toast.success('Instagram actualizado con éxito');
        onRequestClose?.();
      })
      .catch(handleError);
  }

  return (
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      {...restProps}
    >
      <article css={styles}>
        {isUserSelected ? (
          <>
            <Text fontSize={20} fontWeight={600} align="center">
              ¿Tu usuario es el siguiente?
            </Text>
            <Text fontSize={14} align="center">
              Si por error vinculaste una cuenta que no es tuya,
              puedes contáctarnos con el botón de ayuda que está a un
              lado de notificaciones.
            </Text>

            <article className="instagramer">
              <Avatar size={48} image={user?.avatar_base_64} />
              <div>
                <Text fontSize={16} fontWeight={600} color={BLACK}>
                  {user.username}
                </Text>
                <Text color={GRAY}>{user.fullName}</Text>
              </div>
            </article>

            <ButtonPrimary
              pillShape
              width="100%"
              onClick={confirmUser}
              label="Confirmar"
              color={GRAY_DARK}
            />

            <Button
              onClick={() => {
                setisUserSelected(false);
                setUser(null);
              }}
              className="cancel-button"
            >
              Volver a buscar
            </Button>
          </>
        ) : (
          <>
            <Text fontSize={20} fontWeight={600} align="center">
              Selecciona tu usuario de Instagram
            </Text>
            <Text fontSize={14} align="center">
              Para poder disfrutar de nuestras Promociones, es
              necesario que vincules tu cuenta de Instagram, busca y
              selecciona tu usuario en el campo siguiente.
            </Text>

            <InputText
              placeholder="Usuario"
              className="input username full-row"
              onChange={handleOnChange}
              width="100%"
            />

            {user ? (
              <Button
                onClick={() => setisUserSelected(true)}
                className="instagramer"
              >
                <Avatar size={48} image={user?.avatar_base_64} />
                <div>
                  <Text fontSize={16} fontWeight={600} color={BLACK}>
                    {user.username}
                  </Text>
                  <Text color={GRAY}>{user.fullName}</Text>
                </div>
              </Button>
            ) : loading ? (
              <Button onClick={() => abortController?.abort()}>
                <ProgressIndicatorSpinner />
              </Button>
            ) : null}
          </>
        )}
      </article>
    </Modal>
  );
}

ModalInstagramVinculation.propTypes = {
  type: PropTypes.oneOf(['phone', 'email']),
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default ModalInstagramVinculation;
