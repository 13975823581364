/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useMemo, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  BLACK,
  WHITE,
  GRAY_MID,
  GRAY_COLD,
  GRAY_DARK,
  PURPLE_FEEK,
  GRAY_LIGHTER,
  WHITE_ALMOST,
  PURPLE_MEDIUM,
  RED_NOTIFICATIONS,
} from 'styles/colors';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import { logout } from 'redux/app/feekApp';
import Button from 'components/buttons/Button';
import { formatNumberToShort } from 'utils/format';
import getFormattedUrl from 'utils/getFormattedUrl';
import { landingIds } from 'screens/manager/Landing';
import { MEDIA_QUERIES, Z_INDEX } from 'styles/constants';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as Bell } from 'assets/icons/Bell.svg';
import getClassNameFromArray from 'utils/getClassNameFromArray';
import { ROUTES_FEEKAPP, ROUTES_MANAGER } from 'constants/routes';
import DropDownLabelAndIcon from 'components/DropDownLabelAndIcon';
import { ReactComponent as ZapMini } from 'assets/icons/ZapMini.svg';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import { ReactComponent as FeekLogo } from 'assets/icons/FeekLogo.svg';
import { ReactComponent as Location } from 'assets/icons/Location.svg';
import { ReactComponent as LocationMini } from 'assets/icons/LocationMini.svg';
import CampaignsSearchInput from 'components/inputs/InputSearchCampaigns/Index';
import { ReactComponent as HamburguerButton } from 'assets/icons/HamburguerButton.svg';

export const headerHeight = { normal: '88px', mobile: '60px' };

const styles = css`
  display: flex;
  width: 100vw;
  height: ${headerHeight.normal};
  align-items: center;
  gap: 20px;
  padding: 20px 70px;
  border-bottom: 1px solid ${GRAY_LIGHTER};
  background-color: ${WHITE};
  position: fixed;
  top: 0;
  z-index: ${Z_INDEX.header};

  .showOnMobileOnly {
    display: none;
  }

  .feek-logo {
    margin-inline-end: 20px;
  }

  .location-container {
    display: flex;
    align-items: center;
    padding: 13px 35px 13px 20px;
    border-radius: 14px;
    border: 1px solid ${PURPLE_MEDIUM};

    .location-text {
      margin-inline: 10px 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 320px;
    }
  }

  .search-wrapper {
    flex: 1;
    justify-content: end;

    .search-input {
      margin-left: auto;
      max-width: 500px;
      align-self: center;
      background-color: ${GRAY_COLD};
      border-radius: 10px;
      border: none;
    }
  }

  .menu-wrapper {
    display: flex;
    gap: 20px;

    .user-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      border-radius: 30px;
      height: 50px;
      padding: 4px 4px 4px 25px;
    }
  }

  ${MEDIA_QUERIES.tablet} {
    .hideOnTablet {
      display: none !important;
    }
    .menu-wrapper {
      gap: 15px;

      .feek-points-button > div {
        gap: 2.5px;
        padding-inline: 14px;
      }

      .notifications-button {
        position: relative;

        > div {
          display: flex;
          align-items: center;
          height: 32px;
        }
      }
    }

    .showOnMobileOnly {
      display: revert;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    height: ${headerHeight.mobile};
    padding: 14px 16px;
    justify-content: space-between;
    gap: 10px;

    .hideOnMobile {
      display: none;
    }

    .button-back-responsive {
      background-color: ${WHITE_ALMOST};
      border-radius: 50%;
      padding: 9px;
      aspect-ratio: 1;
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
    }

    .feek-logo {
      margin: 0;
    }

    .location-container {
      padding: 0;
      border-radius: 0;
      border: none;

      .location-text {
        max-width: 130px;
        margin-inline-start: 4px;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
`;

const menuStyles = css`
  .content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-block-start: 15px;
    padding: 30px 0;
    background: ${WHITE};
    border-radius: 16px;
    border: 1px solid ${GRAY_MID};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    > * {
      padding-inline: 30px;
      font-size: 16;
      font-weight: 600;
      color: ${BLACK};
      white-space: nowrap;

      &.border-bottom {
        padding-block-end: 15px;
        border-bottom: 1px solid ${GRAY_MID};

        & ~ * {
          font-weight: normal;
        }
      }

      &.notifications-link {
        display: flex;

        .notifications-count {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-inline-start: 6px;
          height: 20px;
          min-width: 20px;
          padding-inline: 6px;
          border-radius: 100vmax;
          background-color: ${RED_NOTIFICATIONS};
        }
      }

      &.logout-button:hover {
        opacity: 1;
        transition-property: color;
        transition-duration: 300ms;
        color: ${RED_NOTIFICATIONS};
      }
    }
  }
`;

const Header = ({ setShowSelectLocationModal, responsiveAction }) => {
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { avatar, notificationsCount, totalFeekPoints } = useSelector(
    (state) => state.feekApp,
  );
  const notificationsCountFormatted = useMemo(
    () => formatNumberToShort(notificationsCount),
    [notificationsCount],
  );

  const currentFeekToken = localStorage.getItem('currentFeekToken');
  const lastSelectedLocation = JSON.parse(
    localStorage.getItem('last_location'),
  );

  const handleLogout = () => {
    setMenuIsOpen(false);
    dispatch(logout());
  };

  const dataDependentStyles = css`
    ${MEDIA_QUERIES.tablet} {
      ${!!notificationsCount &&
      `.menu-wrapper > .notifications-button:after {
        content: '${notificationsCountFormatted}';
        color: ${WHITE};
        position: absolute;
        bottom: 18px;
        aspect-ratio: 1;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        background: ${RED_NOTIFICATIONS};
        border-radius: 50%;
      }`}

      ${pathname !== ROUTES_FEEKAPP.EXPLORE &&
      pathname !== ROUTES_FEEKAPP.EXPLORE_REWARDS &&
      `.location-container {
          display: none !important;
        }
      `}
    }
  `;

  return (
    <header css={[styles, dataDependentStyles]}>
      {responsiveAction && (
        <Button
          onClick={responsiveAction}
          className="button-back-responsive showOnMobileOnly"
        >
          <Icon
            iconMobile={Chevron}
            icon={Chevron}
            color={BLACK}
            widthMobile={12}
            rotate={90}
          />
        </Button>
      )}
      <Link
        className={getClassNameFromArray([
          'feek-logo',
          responsiveAction ? 'hideOnMobile' : null,
        ])}
        to={ROUTES_FEEKAPP.EXPLORE}
      >
        <Icon
          icon={FeekLogo}
          color={PURPLE_FEEK}
          height={48}
          heightMobile={30}
        />
      </Link>

      <Button
        className={getClassNameFromArray(['location-container'])}
        onClick={() => setShowSelectLocationModal(true)}
      >
        <Icon
          icon={Location}
          iconMobile={LocationMini}
          color={BLACK}
          height={22}
          heightMobile={16}
        />

        <Text
          type="inline"
          color={BLACK}
          fontSize={16}
          className="location-text"
        >
          {lastSelectedLocation?.formatted_address ||
            'Selecciona tu ubicación'}
        </Text>
        <Text
          type="inline"
          color={BLACK}
          fontSize={16}
          className="hideOnTablet"
        >
          · Tu ubicación
        </Text>

        <Icon
          icon={Chevron}
          color={BLACK}
          width={10}
          className="showOnMobileOnly"
        />
      </Button>

      <CampaignsSearchInput
        className="search-wrapper hideOnTablet"
        placeholder="Buscar lugares, comida, promociones, categorías..."
      />
      <div className="menu-wrapper">
        {currentFeekToken ? (
          <>
            <Link
              className="feek-points-button showOnMobileOnly"
              to={ROUTES_FEEKAPP.PROFILE_FEEKER_EDIT}
            >
              <ButtonPrimary
                textColor={BLACK}
                label={formatNumberToShort(totalFeekPoints)}
                height={32}
                textProps={{ fontSize: 16, fontWeight: 600 }}
                strokeVariant
                strokeColor={GRAY_MID}
                pillShape
                layoutOnly
                distribution="center"
                icon={
                  <Icon height={16} color={BLACK} icon={ZapMini} />
                }
              />
            </Link>

            <Link
              className="notifications-button showOnMobileOnly"
              to={ROUTES_FEEKAPP.NOTIFICATIONS}
            >
              <Button layoutOnly>
                <Icon size={22} color={BLACK} icon={Bell} />
              </Button>
            </Link>
          </>
        ) : (
          <>
            <Link
              className="hideOnTablet"
              to={ROUTES_FEEKAPP.REGISTRATION}
            >
              <ButtonPrimary
                textColor={WHITE}
                label="Regístrate"
                color={GRAY_DARK}
                fontSize={16}
                width={125}
                pillShape
                layoutOnly
              />
            </Link>

            <Link
              className="showOnMobileOnly"
              to={ROUTES_FEEKAPP.LOGIN}
            >
              <ButtonPrimary
                textColor={BLACK}
                label="Ingresar"
                height={32}
                fontSize={16}
                strokeVariant
                strokeColor={GRAY_MID}
                pillShape
                layoutOnly
              />
            </Link>
          </>
        )}

        <DropDownLabelAndIcon
          childrenWrapperCSS={menuStyles}
          className="user-menu hideOnTablet"
          isOpen={menuIsOpen}
          onOpen={() => setMenuIsOpen(true)}
          onClose={() => setMenuIsOpen(false)}
          leftIcon={
            <Icon icon={HamburguerButton} color={BLACK} width={24} />
          }
          rightIcon={<Avatar image={avatar} size={40} />}
        >
          {currentFeekToken ? (
            <>
              <Link
                className="notifications-link"
                to={ROUTES_FEEKAPP.NOTIFICATIONS}
              >
                Notificaciones
                {!!notificationsCount && (
                  <Text
                    fontWeight={600}
                    color={WHITE}
                    type="inline"
                    align="center"
                    className="notifications-count"
                  >
                    {notificationsCountFormatted}
                  </Text>
                )}
              </Link>

              <Link to={ROUTES_FEEKAPP.AGENDA}>Agenda</Link>

              <Link
                className="border-bottom"
                to={ROUTES_FEEKAPP.PROFILE_FEEKER_EDIT}
              >
                Perfil
              </Link>

              <Link to={ROUTES_FEEKAPP.INFO}>Ayuda</Link>

              <Button
                className="logout-button"
                onClick={handleLogout}
              >
                Cerrar sesión
              </Button>
            </>
          ) : (
            <>
              <Link to={ROUTES_FEEKAPP.REGISTRATION}>Regístrate</Link>
              <Link
                className="border-bottom"
                to={ROUTES_FEEKAPP.LOGIN}
              >
                Iniciar Sesión
              </Link>

              <HashLink
                to={getFormattedUrl({
                  route: ROUTES_MANAGER.LANDING,
                  hash: landingIds.signInForm,
                  isManagerRoute: true,
                })}
              >
                Pon tu negocio en Feek
              </HashLink>
            </>
          )}
        </DropDownLabelAndIcon>
      </div>
    </header>
  );
};

Header.propTypes = {
  setShowSelectLocationModal: PropTypes.func,
  responsiveAction: PropTypes.func,
};

export default Header;
