/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, useFormikContext } from 'formik';

import {
  WHITE,
  GRAY_TEXT,
  GRAY_DEFAULT,
  GRADIENT_BLUE,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Modal from 'components/modals/Modal';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { selectFeekspot } from 'redux/app/managerApp';
import { convertGooglePlaceToAddress } from 'utils/format';
import { FormInputText } from 'components/inputs/InputText';
import FeekLogoWorld from 'assets/images/FeekLogoWorld.png';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { setCategories, setFeekspots } from 'redux/entities';
import ProgressLinearBar from 'components/ProgressLinearBar';
import FeekerLookingReport from 'assets/images/FeekerLookingReport.png';
import { ReactComponent as ArrowLeftCircle } from 'assets/icons/ArrowLeftCircle.svg';
import { FormPlacesAutoCompleteInput } from 'components/inputs/PlacesAutocompleteInput';

const formInitialValues = {
  name: '',
  location: '',
  categories: new Set(),
};

const formValidationSchema = Yup.object().shape({
  name: Yup.string().required('Campo requerido'),
  location: Yup.object().shape({
    formatedLocation: Yup.string().required(
      'La direccion del negocio es requerida',
    ),
    lng: Yup.number().required(
      'La direccion del negocio es inválida',
    ),
    lat: Yup.number().required(
      'La direccion del negocio es inválida',
    ),
  }),
});

const style = css`
  background-color: ${WHITE};
  border-radius: 8px;

  .firstStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 462px;
    padding: 40px 32px;
    gap: 10px;

    .image {
      width: 190px;
      height: 140px;
      margin: 10px 0;
    }

    .buttons {
      display: flex;
      gap: 24px;
      width: 100%;

      button {
        height: 46px;
        width: 100%;
      }
    }
  }

  .secondStep {
    display: flex;
    flex-direction: row;
    gap: 22px;
    padding: 46px 80px 50px 28px;
    width: 640px;

    .form {
      width: 100%;

      .title {
        margin-bottom: 10px;
      }

      .inputsWrapper {
        margin-top: 40px;
        margin-bottom: 20px;

        .label {
          margin-bottom: 10px;
        }
      }

      .progressBarLabel {
        margin-top: 40px;
        margin-bottom: 7px;
      }
    }
  }

  .thirdStep {
    display: flex;
    flex-direction: row;
    gap: 22px;
    padding: 46px 80px 50px 28px;
    width: 640px;

    .form {
      width: 100%;

      .title {
        margin-bottom: 10px;
      }

      .categories {
        margin: 40px 0;

        .categoriesList {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 15px;

          .category {
            display: flex;
            align-items: center;
            padding: 0 20px;
            border: 1px solid ${GRAY_DEFAULT};
            border-radius: 16px;
            height: 40px;
          }

          .--selected {
            background: ${GRADIENT_BLUE};
          }
        }
      }

      .progressBarLabel {
        margin-top: 40px;
        margin-bottom: 7px;
      }
    }
  }

  .fourthStep {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 50px;

    .image {
      height: 170px;
      width: 380px;
    }

    .progressBarLabel {
      margin-bottom: 7px;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    & > *[class] {
      width: 100%;
      padding: 0;
    }
  }
`;

function FirstStep({ next, back }) {
  return (
    <div className="firstStep">
      <Text
        fontSize={24}
        align="center"
        fontWeight="bold"
        lineHeight={32}
      >
        ¿Deseas agregar un nuevo Negocio a tu cuenta?
      </Text>
      <Text fontSize={16} lineHeight={24} align="center">
        Agrega múltiples sucursales o diferentes negocios a tu cuenta
        Feek.
      </Text>
      <img
        src={FeekerLookingReport}
        alt="Feeker looking report"
        className="image"
      />
      <div className="buttons">
        <ButtonPrimary
          strokeVariant
          label="Cancelar"
          onClick={back}
        />
        <ButtonPrimary label="Continuar" onClick={next} />
      </div>
    </div>
  );
}

FirstStep.propTypes = {
  next: PropTypes.func,
  back: PropTypes.func,
};

function SecondStep({ next, back, step, totalSteps }) {
  const form = useFormikContext();

  return (
    <div className="secondStep">
      <Button onClick={back}>
        <Icon icon={ArrowLeftCircle} size={30} color={GRAY_DEFAULT} />
      </Button>
      <div className="form">
        <Text
          fontSize={20}
          fontWeight="bold"
          lineHeight={24}
          className="title"
        >
          Crear nuevo negocio
        </Text>
        <Text fontSize={16} lineHeight={19}>
          Compártenos los datos principales de tu negocio.
        </Text>

        <div className="inputsWrapper">
          <Text fontSize={16} lineHeight={18} className="label">
            Crea un perfil para tu negocio
          </Text>
          <FormInputText
            name="name"
            placeholder="Estos datos servirán para que los Feekers identifiquen a tu negocio, asegúrate de que no tengan errores."
          />
          <Text fontSize={16} lineHeight={18} className="label">
            Domicilio
          </Text>
          <FormPlacesAutoCompleteInput name="location" withMap />
        </div>
        <ButtonPrimary
          label="Siguiente"
          onClick={next}
          disabled={!form.isValid}
        />
        <Text
          align="right"
          fontSize={12}
          fontWeight="600"
          className="progressBarLabel"
        >
          Paso {step} de {totalSteps}
        </Text>
        <ProgressLinearBar progress={(step * 100) / totalSteps} />
      </div>
    </div>
  );
}

SecondStep.propTypes = {
  next: PropTypes.func,
  back: PropTypes.func,
  step: PropTypes.number,
  totalSteps: PropTypes.number,
};

function ThirdStep({ back, step, totalSteps }) {
  const form = useFormikContext();
  const categories = useSelector(
    (state) => state.entities.categories,
  );

  const selectedItems = form.values.categories;

  const handleSelectCategory = (selected, id) => {
    if (!selected) {
      form.setFieldValue(
        'categories',
        new Set(selectedItems).add(id),
      );
    } else {
      const temp = new Set(selectedItems);
      temp.delete(id);
      form.setFieldValue('categories', temp);
    }
  };

  return (
    <div className="thirdStep">
      <Button onClick={back}>
        <Icon icon={ArrowLeftCircle} size={30} color={GRAY_DEFAULT} />
      </Button>
      <div className="form">
        <Text
          fontSize={20}
          fontWeight="bold"
          lineHeight={24}
          className="title"
        >
          Categoría del negocio
        </Text>
        <Text fontSize={16} lineHeight={19}>
          Esta información nos ayudará a conectarte con los Feekers
          adecuados, e identificará tu negocio con claridad.
        </Text>
        <div className="categories">
          <Text fontSize={16} lineHeight={19}>
            ¿Cuáles categorías identifican al negocio?
          </Text>
          <div className="categoriesList">
            {Object.values(categories).map((category) => {
              const selected = selectedItems.has(category._id);
              return (
                <Button
                  key={category._id}
                  className={`category ${
                    selected ? '--selected' : ''
                  }`}
                  onClick={() =>
                    handleSelectCategory(selected, category._id)
                  }
                  disabled={!selected && selectedItems.size > 2}
                >
                  <Text
                    fontSize={12}
                    lineHeight={14}
                    align="center"
                    color={selected ? WHITE : GRAY_TEXT}
                  >
                    {category.name}
                  </Text>
                </Button>
              );
            })}
          </div>
        </div>
        <ButtonPrimary
          label="Siguiente"
          onClick={form.submitForm}
          disabled={
            !form.isValid ||
            form.isSubmitting ||
            selectedItems.size === 0
          }
        />
        <Text
          align="right"
          fontSize={12}
          fontWeight="600"
          className="progressBarLabel"
        >
          Paso {step} de {totalSteps}
        </Text>
        <ProgressLinearBar progress={(step * 100) / totalSteps} />
      </div>
    </div>
  );
}

ThirdStep.propTypes = {
  next: PropTypes.func,
  back: PropTypes.func,
  step: PropTypes.number,
  totalSteps: PropTypes.number,
};

function FourthStep({ next, step, totalSteps }) {
  return (
    <div className="fourthStep">
      <img
        className="image"
        src={FeekLogoWorld}
        alt="Feek logo world"
      />
      <Text
        align="center"
        fontSize={24}
        lineHeight={28}
        fontWeight="bold"
      >
        ¡Felicidades!
      </Text>
      <Text align="center" fontSize={14} lineHeight={17}>
        <strong>¡Negocio creado con éxito!</strong> Completa el perfil
        para comenzar a crear campañas.
      </Text>
      <ButtonPrimary label="Comenzar" onClick={next} />
      <Text
        align="right"
        fontSize={12}
        fontWeight="600"
        className="progressBarLabel"
      >
        Paso {step} de {totalSteps}
      </Text>
      <ProgressLinearBar progress={(step * 100) / totalSteps} />
    </div>
  );
}

FourthStep.propTypes = {
  next: PropTypes.func,
  step: PropTypes.number,
  totalSteps: PropTypes.number,
};

function CreateFeekspotModal({ isOpen, handleClose }) {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const steps = [FirstStep, SecondStep, ThirdStep, FourthStep];

  useEffect(() => {
    APIClient.getCategories()
      .then(({ data }) => dispatch(setCategories(data)))
      .catch(handleError);
  }, []);

  const handleCloseModal = () => {
    handleClose();
    setStep(0);
  };

  function handleNext() {
    if (step === steps.length - 1) {
      handleCloseModal();
    } else {
      setStep((crr) => crr + 1);
    }
  }

  function handleBack() {
    if (step === 0) {
      handleClose();
    } else {
      setStep((crr) => crr - 1);
    }
  }

  const CurrentStepComponent = steps[step];

  const handleFormSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    const { lat, lng } = values.location;

    const addressComponents = convertGooglePlaceToAddress(
      values.location.addressComponents,
    );

    try {
      const response = await APIClient.createFeekspot({
        ...values,
        categories: [...values.categories],
        location: {
          state: addressComponents.administrative_area_level_1,
          exterior_number: addressComponents.street_number,
          city: addressComponents.locality,
          cp: addressComponents.postal_code,
          suburb: addressComponents.sublocality_level_1,
          street: addressComponents.route,
          country: addressComponents.country,
          coordinates: [lng, lat],
        },
      });
      if (response.status === 201) {
        dispatch(setFeekspots([response.data]));
        dispatch(selectFeekspot(response.data.customer._id));
        setStep(3);
      }
    } catch (error) {
      actions.setSubmitting(false);
      handleError(error);
    }
  };

  return (
    <Modal visible={isOpen} onRequestClose={handleCloseModal}>
      <Formik
        validateOnMount
        onSubmit={handleFormSubmit}
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
      >
        <Form css={style}>
          <CurrentStepComponent
            step={step}
            next={handleNext}
            back={handleBack}
            totalSteps={steps.length - 1}
          />
        </Form>
      </Formik>
    </Modal>
  );
}

CreateFeekspotModal.propTypes = {
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default CreateFeekspotModal;
