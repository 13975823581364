/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  BLACK,
  GRAY_MID,
  GRAY_COLD,
  PURPLE_FEEK,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { setCampaignTypes } from 'redux/entities';
import CardCampaign from 'components/cards/CardCampaign';
import TopFeekspots from 'screens/feekApp/Explore/TopFeekspots';
import { useScrollHorizontally } from 'hooks/useScrollHorizontally';
import { getFontSizeAndLineHeigh } from 'utils/cssStringManipulation';
import BannerExperience from 'screens/feekApp/Explore/BannerExperience';
import BadgeExtraParticipations from 'components/BadgeExtraParticipations';

const styles = css`
  .border-bottom {
    border-bottom: 1px solid ${GRAY_MID};
  }

  .tab-item {
    cursor: pointer;
    padding-bottom: 16px;
    font-weight: 500;
    color: ${BLACK};
  }
  .tab-item.active {
    font-weight: 700;
    color: ${PURPLE_FEEK};
    border-bottom: 4px solid ${PURPLE_FEEK};
  }

  .campaigns-feed {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 40px;
    padding-top: 24px;

    overflow-x: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    > :first-of-type {
      margin-inline-start: 70px;
    }
    > :last-of-type {
      margin-inline-end: 70px;
    }
  }

  .banners-feed {
    display: flex;
    overflow-x: scroll;
    margin-bottom: 100px;
    margin-top: 100px;
  }

  .banners-feed::-webkit-scrollbar {
    display: none;
  }

  .high-message {
    margin: 0 auto;
    width: fit-content;
    background: ${GRAY_COLD};
    padding: 24px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 20px;

    span:first-of-type {
      margin-inline: 20px 6px;
      line-height: 100%;
    }
  }

  * :has(> .campaign-image-content) {
    position: relative;
  }

  .badge-extra-participations {
    position: absolute;
    margin-left: 12px;
    margin-top: 12px;
  }

  .pb-100 {
    padding-bottom: 100px;
  }

  .top-feekspots-container {
    padding-left: 70px;
  }

  ${MEDIA_QUERIES.mobile} {
    .tab-item {
      ${getFontSizeAndLineHeigh(16)}
      padding-bottom: 8px;
    }

    .campaigns-feed {
      padding-top: 0;
      margin-top: 0;
      gap: 16px;

      .campaign-card {
        width: 85%;
        flex: 0 0 85%;

        :first-of-type {
          margin-inline-start: 16px;
        }
        :last-of-type {
          margin-inline-end: 16px;
        }

        .campaign-card-img {
          aspect-ratio: 6/5;
        }

        .campaign-card-info {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .header {
            justify-content: space-between;
            gap: 10px;

            .feekspot-name {
              text-transform: uppercase;
            }

            .places-badge {
              white-space: nowrap;
              padding: 6px 12px;
              border-radius: 30px;
              border: 1px solid ${GRAY_MID};
            }
          }

          .title {
            margin-block: 12px;
          }

          .campaign-type-info {
            gap: 12px;

            .icon-text {
              gap: 4px;
            }

            .distance {
              margin-left: auto;
            }
          }
        }

        .ellipsed {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .flexRow {
          display: flex;
          align-items: center;
        }
      }
    }

    .banners-feed {
      margin-bottom: 40px;
    }
    .top-feekspots-container {
      padding-left: 0px;
      padding-right: 0px;
      margin: 0 auto;
    }
    .banner-card {
      width: calc(100vw - 30px);
    }
  }
`;

const campaignTabs = [
  '1', //Top
  'hot', //Near to user
  '-1', //LAST_PLACES
];

export default function ExplorePromotions() {
  const [tabActive, setTabActive] = useState(0);

  const campaignsFeedRef = useScrollHorizontally({
    stopOnEdges: true,
  });
  const campaignsExtraParticipationRef = useScrollHorizontally({
    stopOnEdges: true,
  });

  const dispatch = useDispatch();
  const [campaigns, setCampaigns] = useState([]);

  const [extraParticipationCampaigns, setExtraParticipation] =
    useState([]);

  const [banners, setBanners] = useState([]);

  const campaignTabClick = (index) => {
    setTabActive(index);
    getCampaigns(campaignTabs[index]);
  };

  useEffect(() => {
    setTabActive(0);
    APIClient.getCampaignTypes()
      .then((res) => {
        dispatch(setCampaignTypes(res.data));
      })
      .catch(handleError);

    getCampaigns();

    APIClient.campaignWithExtraParticipations()
      .then(({ data }) => {
        setExtraParticipation(data.docs);
      })
      .catch(handleError);

    APIClient.getBanners()
      .then(({ data }) => {
        setBanners(data.docs);
      })
      .catch(handleError);
  }, []);

  const getCampaigns = (sort = campaignTabs[0] /* HOT */) => {
    APIClient.getCampaignList({ sort })
      .then(({ data }) => {
        setCampaigns(data?.docs || []);
      })
      .catch(handleError);
  };
  return (
    <div css={styles}>
      <div className="d-flex justify-content-center border-bottom">
        <Button onClick={() => campaignTabClick(0)}>
          <Text
            fontSize={24}
            className={`mx-3 mx-md-5 tab-item ${
              tabActive == 0 && 'active'
            }`}
          >
            Top
          </Text>
        </Button>
        <Button onClick={() => campaignTabClick(1)}>
          <Text
            fontSize={24}
            className={`mx-3 mx-md-5 tab-item ${
              tabActive == 1 && 'active'
            }`}
          >
            Cerca de ti
          </Text>
        </Button>
        <Button onClick={() => campaignTabClick(2)}>
          <Text
            fontSize={24}
            className={`mx-3 mx-md-5 tab-item ${
              tabActive == 2 && 'active'
            }`}
          >
            Últimos lugares
          </Text>
        </Button>
      </div>
      <div className="pt-4 campaigns-feed" ref={campaignsFeedRef}>
        {campaigns.map((campaign) => (
          <CardCampaign
            key={campaign._id}
            campaignId={campaign._id}
            className="campaign-card"
            image={campaign.campaign.image}
            places={campaign.campaign.places}
            title={campaign.campaign.title}
            type={campaign.campaign.type}
            feekspot={campaign?.customer?.name}
            feekpoints={campaign.campaign?.task?.feek_points}
            distance={campaign.customer?.distance}
          />
        ))}
      </div>
      <div className="banners-feed">
        {banners.map((banner) => (
          <BannerExperience
            className="mx-4 banner-card"
            key={banner._id}
            image={banner.image.url}
            logo={banner.logo}
            keyIcon={banner.key}
            mainText={banner.main_text.text}
            buttonText={banner.button.text}
            buttonLink={banner.button.web_link}
            secondaryText={banner.secondary_text.text}
            mainTextColor={banner.main_text.color}
            buttonTextColor={banner.button.color}
            backgroundColor={banner.background_color}
            secondaryTextColor={banner.secondary_text.color}
          />
        ))}
      </div>

      <div className="pt-4 pb-100">
        <Text
          color={BLACK}
          fontSize={32}
          align="center"
          fontWeight={600}
          className="mb-5 ps-md-4"
        >
          ¡Participación extra!
        </Text>

        <Text
          className="high-message hide-on-mobile"
          fontSize={16}
          fontWeight={400}
          color={BLACK}
          align="center"
        >
          Las promociones de esta sección te dan participación extra
          para ganar recompensas.
          <span
            fontSize={16}
            fontWeight={400}
            color={BLACK}
            align="center"
            className="ml-20"
          >
            Identifica el tag:
          </span>
          <BadgeExtraParticipations participations={2} />
        </Text>

        <div
          className="campaigns-feed"
          ref={campaignsExtraParticipationRef}
        >
          {extraParticipationCampaigns.map((campaign) => (
            <CardCampaign
              key={campaign._id}
              className="campaign-card"
              campaignId={campaign._id}
              image={campaign.campaign.image}
              places={campaign.campaign.places}
              title={campaign.campaign.title}
              type={campaign.campaign.type}
              feekspot={campaign?.customer?.name}
              feekpoints={campaign.campaign?.task?.feek_points}
              imageContent={
                <BadgeExtraParticipations
                  className="campaign-image-content badge-extra-participations"
                  participations={
                    campaign.campaign.participation_experiences
                  }
                />
              }
              distance={campaign.customer?.distance}
            />
          ))}
        </div>
      </div>

      <TopFeekspots className="top-feekspots-container" />
    </div>
  );
}
