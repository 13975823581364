import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  ROUTES_FEEKAPP,
  ROUTES_MANAGER,
  ROUTES_AGNOSTIC,
  MANAGER_SUBDOMAIN,
} from 'constants/routes';
import Map from 'screens/feekApp/Map';
import NotFound from 'screens/NotFound';
import Info from 'screens/feekApp/Info';
import Agenda from 'screens/feekApp/Agenda';
import Explore from 'screens/feekApp/Explore';
import LoginFeeker from 'screens/feekApp/Login';
import SearchFeekApp from 'screens/feekApp/Search';
import MapResults from 'screens/feekApp/MapResults';
import Experience from 'screens/feekApp/Experience';
import Payment from 'screens/manager/legacy/Payment';
import LandingFeekApp from 'screens/feekApp/Landing';
import LandingManager from 'screens/manager/Landing';
import HomeManager from 'screens/manager/legacy/Home';
import NotFoundFeekapp from 'screens/feekApp/NotFound';
import CampaignFeekApp from 'screens/feekApp/Campaign';
import Policy from 'screens/TermsAndConditions/Policy';
import LoginManager from 'screens/manager/legacy/Login';
import ShareAppDownload from 'screens/ShareAppDownload';
import Dashboard from 'screens/manager/legacy/Dashboard';
import OutletFeekapp from 'screens/feekApp/OutletFeekapp';
import Notifications from 'screens/feekApp/Notifications';
import ResetPassword from 'screens/feekApp/ResetPassword';
import ProfileFeeker from 'screens/feekApp/ProfileFeeker';
import Promotions from 'screens/feekApp/Agenda/Promotions';
import TermsAndConditions from 'screens/TermsAndConditions';
import AddCampaign from 'screens/manager/legacy/AddCampaign';
import Billing from 'screens/manager/legacy/Payment/Billing';
import FeekspotFeekApp from 'screens/feekApp/ProfileFeekspot';
import SeachByCategory from 'screens/feekApp/SeachByCategory';
import RegistrationFeekApp from 'screens/feekApp/Registration';
import CampaignsManager from 'screens/manager/legacy/Campaigns';
import OutletManagerLegacy from 'screens/manager/legacy/Outlet';
import Collaborators from 'screens/manager/legacy/Collaborators';
import ProfileFeekerEdit from 'screens/feekApp/ProfileFeekerEdit';
import ForgotPassword from 'screens/manager/legacy/ForgotPassword';
import Participations from 'screens/feekApp/Agenda/Participations';
import PaymentHistory from 'screens/manager/legacy/PaymentsHistory';
import ExploreRewards from 'screens/feekApp/Explore/ExploreRewards';
import ProfileCustomer from 'screens/manager/legacy/ProfileCustomer';
import ProfileFeekspot from 'screens/manager/legacy/ProfileFeekspot';
import RegistrationManager from 'screens/manager/legacy/Registration';
import ExplorePromotions from 'screens/feekApp/Explore/ExplorePromotions';
import CollaboratorNewPassword from 'screens/manager/legacy/CollaboratorNewPassword';
import RegistrationAddFeekspot from 'screens/manager/legacy/Registration/RegistrationAddFeekspot';
import SubscriptionFeekspotSuccess from 'screens/manager/legacy/Payment/SubscriptionFeekspotSuccess';
import RegistrationFeekspotCategories from 'screens/manager/legacy/Registration/RegistrationAddFeekspot';
import RegistrationAddFeekspotSuccess from 'screens/manager/legacy/Registration/RegistrationAddFeekspotSuccess';
import RegistrationVerificationEmailCodeManager from 'screens/manager/legacy/Registration/RegistrationVerificationEmailCode';
import RegistrationVerificationPhoneCodeManager from 'screens/manager/legacy/Registration/RegistrationVerificationPhoneCode';

const RootNavigator = () => {
  const userId = useSelector((state) => state.feekApp.id);

  return (
    <BrowserRouter>
      <Routes>
        {/* Auth agnostic */}
        <Route path={ROUTES_AGNOSTIC.POLICY} element={<Policy />} />
        <Route
          path={ROUTES_AGNOSTIC.TERMS_AND_CONDITIONS}
          element={<TermsAndConditions />}
        />
        <Route
          exact
          path={ROUTES_AGNOSTIC.SHARE_APP_DOWNLOAD}
          element={<ShareAppDownload />}
        />
        <Route
          exact
          path={ROUTES_AGNOSTIC.SHARE_APP_DOWNLOAD_PARAMS}
          element={<ShareAppDownload />}
        />

        {new RegExp(
          '^(?:www\\.)?' +
            MANAGER_SUBDOMAIN +
            '(feek\\.app|localhost)',
        ).test(window.location.hostname) ? (
          <>
            <Route path="*" element={<NotFound />} />

            <Route
              path={ROUTES_MANAGER.LANDING}
              element={<LandingManager />}
            />

            <Route
              path={ROUTES_MANAGER.COLLABORATOR_NEW_PASSWORD}
              element={<CollaboratorNewPassword />}
            >
              <Route
                path={':account_invite'}
                element={<CollaboratorNewPassword />}
              />
            </Route>

            <Route
              exact
              path={ROUTES_MANAGER.REGISTRATION}
              element={<RegistrationManager />}
            />

            <Route
              exact
              path={
                ROUTES_MANAGER.REGISTRATION_VERIFICATION_CODE_EMAIL
              }
              element={<RegistrationVerificationEmailCodeManager />}
            />

            <Route
              exact
              path={
                ROUTES_MANAGER.REGISTRATION_VERIFICATION_CODE_PHONE
              }
              element={<RegistrationVerificationPhoneCodeManager />}
            />

            <Route
              exact
              path={ROUTES_MANAGER.FORGOT_PASSWORD}
              element={<ForgotPassword />}
            />

            <Route
              path="/"
              element={
                <AuthRoute manager>
                  <OutletManagerLegacy />
                </AuthRoute>
              }
            >
              <Route
                path={ROUTES_MANAGER.COLLABORATORS}
                element={<Collaborators />}
              />
              <Route
                path={ROUTES_MANAGER.CAMPAIGNS}
                element={<CampaignsManager />}
              />
              <Route
                path={ROUTES_MANAGER.ADD_CAMPAIGNS}
                element={<AddCampaign />}
              />
              <Route
                path={ROUTES_MANAGER.UPDATE_CAMPAIGNS}
                element={<AddCampaign />}
              />
              <Route
                path={ROUTES_MANAGER.PAYMENT_HISTORY}
                element={<PaymentHistory />}
              />
              <Route
                path={ROUTES_MANAGER.PROFILE_CUSTOMER}
                element={<ProfileCustomer />}
              />
              <Route
                path={ROUTES_MANAGER.HOME}
                element={<HomeManager />}
              />
              <Route
                path={ROUTES_MANAGER.DASHBOARD}
                element={<Dashboard />}
              />
              <Route
                path={ROUTES_MANAGER.PROFILE_FEEKSPOT}
                element={<ProfileFeekspot />}
              />
              <Route
                path={ROUTES_MANAGER.BILLING}
                element={<Billing />}
              />
            </Route>

            <Route
              exact
              path={ROUTES_MANAGER.LOGIN}
              element={
                <AuthRoute login manager>
                  <LoginManager />
                </AuthRoute>
              }
            />

            <Route
              path={ROUTES_MANAGER.PAYMENT}
              element={
                <AuthRoute manager>
                  <Payment />
                </AuthRoute>
              }
            />

            <Route
              path={ROUTES_MANAGER.SUBSCRIPTION_SUCCESS}
              element={
                <AuthRoute manager>
                  <SubscriptionFeekspotSuccess />
                </AuthRoute>
              }
            />

            <Route
              exact
              path={ROUTES_MANAGER.REGISTRATION_FEEKSPOT}
              element={
                <AuthRoute manager>
                  <RegistrationAddFeekspot />
                </AuthRoute>
              }
            />

            <Route
              exact
              path={ROUTES_MANAGER.REGISTRATION_FEEKSPOT_SUCESS}
              element={
                <AuthRoute manager>
                  <RegistrationAddFeekspotSuccess />
                </AuthRoute>
              }
            />

            <Route
              exact
              path={ROUTES_MANAGER.REGISTRATION_FEEKSPOT_CATEGORIES}
              element={
                <AuthRoute manager>
                  <RegistrationFeekspotCategories />
                </AuthRoute>
              }
            />
          </>
        ) : (
          <>
            <Route
              exact
              path={ROUTES_FEEKAPP.LOGIN}
              element={
                <AuthRoute login>
                  <LoginFeeker />
                </AuthRoute>
              }
            />

            <Route
              exact
              path={ROUTES_FEEKAPP.REGISTRATION}
              element={<RegistrationFeekApp />}
            />

            <Route
              exact
              element={<ResetPassword />}
              path={ROUTES_FEEKAPP.RESET_PASSWORD}
            />

            <Route
              path={ROUTES_FEEKAPP.LANDING}
              element={<LandingFeekApp />}
            />
            {!userId && !localStorage.getItem('last_location') && (
              <Route path={'/'} element={<LandingFeekApp />} />
            )}

            <Route path="/" element={<OutletFeekapp />}>
              <Route path="*" element={<NotFoundFeekapp />} />

              <Route
                element={<Explore />}
                path={ROUTES_FEEKAPP.EXPLORE}
              >
                <Route path="" element={<ExplorePromotions />} />
                <Route
                  path={ROUTES_FEEKAPP.EXPLORE_REWARDS}
                  element={<ExploreRewards />}
                />
              </Route>

              <Route
                path={ROUTES_FEEKAPP.PROFILE_FEEKSPOT}
                element={<FeekspotFeekApp />}
              />
              <Route
                path={ROUTES_FEEKAPP.CATEGORIES}
                element={<SeachByCategory />}
              />
              <Route
                path={ROUTES_FEEKAPP.CATEGORY}
                element={<SeachByCategory />}
              />
              <Route
                path={ROUTES_FEEKAPP.CAMPAIGN}
                element={<CampaignFeekApp />}
              />
              <Route
                path={ROUTES_FEEKAPP.SEARCHER}
                element={<SearchFeekApp />}
              />
              <Route
                path={ROUTES_FEEKAPP.SEARCH}
                element={<SearchFeekApp />}
              />
              <Route path={ROUTES_FEEKAPP.MAP} element={<Map />} />
              <Route
                path={ROUTES_FEEKAPP.MAP_RESULTS}
                element={<MapResults />}
              />

              <Route
                path={ROUTES_FEEKAPP.EXPERIENCE}
                element={<Experience />}
              />
              <Route
                path={ROUTES_FEEKAPP.PROFILE_FEEKER}
                element={<ProfileFeeker />}
              />
            </Route>
            <Route
              path={'/'}
              element={
                <AuthRoute>
                  <OutletFeekapp />
                </AuthRoute>
              }
            >
              <Route
                exact
                path={ROUTES_FEEKAPP.NOTIFICATIONS}
                element={<Notifications />}
              />
              <Route
                exact
                path={ROUTES_FEEKAPP.INFO}
                element={<Info />}
              />
              <Route
                path={ROUTES_FEEKAPP.PROFILE_FEEKER_EDIT}
                element={<ProfileFeekerEdit />}
              />
              <Route
                element={<Explore />}
                path={ROUTES_FEEKAPP.EXPLORE}
              >
                <Route path="" element={<ExplorePromotions />} />
                <Route
                  path={ROUTES_FEEKAPP.EXPLORE_REWARDS}
                  element={<ExploreRewards />}
                />
              </Route>

              <Route
                path={ROUTES_FEEKAPP.CATEGORIES}
                element={<SeachByCategory />}
              />
              <Route
                path={ROUTES_FEEKAPP.CATEGORY}
                element={<SeachByCategory />}
              />
              <Route
                path={ROUTES_FEEKAPP.CAMPAIGN}
                element={<CampaignFeekApp />}
              />
              <Route
                path={ROUTES_FEEKAPP.PROFILE_FEEKSPOT}
                element={<FeekspotFeekApp />}
              />
              <Route
                element={<Agenda />}
                path={ROUTES_FEEKAPP.AGENDA}
              >
                <Route path="" element={<Promotions />} />
                <Route
                  path={ROUTES_FEEKAPP.AGENDA_PROMOTIONS}
                  element={<Promotions />}
                />
                <Route
                  path={ROUTES_FEEKAPP.AGENDA_REWARDS}
                  element={<Participations />}
                />
              </Route>
            </Route>
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

function AuthRoute({ children, login = false, manager = false }) {
  const currentFeekToken = localStorage.getItem('currentFeekToken');

  return (login && currentFeekToken) ||
    (!login && (!children || !currentFeekToken)) ? (
    <Navigate
      to={
        manager
          ? ROUTES_MANAGER[
              !children || !currentFeekToken ? 'LANDING' : 'HOME'
            ]
          : ROUTES_FEEKAPP[!children ? 'LANDING' : 'EXPLORE']
      }
      replace
    />
  ) : (
    children
  );
}

AuthRoute.propTypes = {
  children: PropTypes.node,
  login: PropTypes.bool,
  manager: PropTypes.bool,
};

export default RootNavigator;
