import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useFormik, FormikProvider } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import Modal from 'components/modals/Modal';
import { removeMoment } from 'redux/entities';
import { setFeeker } from 'redux/app/feekApp';
import RadioInput from 'components/inputs/RadioInput';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { PURPLE_LIGHT, RED_NOTIFICATIONS } from 'styles/colors';
import { FormInputTextArea } from 'components/inputs/InputTextArea';

const styles = css`
  .modalMain {
    max-width: 540px;
    padding: 46px 32px 38px;

    .content-wrapper {
      padding-block-start: 28px;

      .info {
        margin-block: 28px 58px;
      }

      .radio-input {
        .inputAndLabel {
          flex-direction: row-reverse;
          justify-content: start;

          .text-label {
            flex: 1;
          }
        }
      }

      .custom-message-label {
        margin-block-start: 38px;
      }

      .custom-message-input {
        margin-block: 20px;
        background-color: ${PURPLE_LIGHT};
      }

      .strikes-info {
        margin-block: 30px;

        .textUnderline {
          text-decoration: underline;
        }
      }
    }
  }
`;

const defaultCancellationReasons = [
  'No puedo asistir en esa fecha y hora',
  'Problemas con el Feekspot',
  'Ya no me interesa esta Promoción',
  'Otra razón',
];

const OTHER_REASON_INDEX = defaultCancellationReasons.length - 1;
const OTHER_REASON_MESSAGE =
  defaultCancellationReasons[OTHER_REASON_INDEX];

const formInitialValues = {
  cancellationReasonIndex: null,
  customCancellationMessage: '',
};

const formValidationSchema = Yup.object({
  cancellationReasonIndex: Yup.number().required(),
  customCancellationMessage: Yup.string().when(
    'cancellationReasonIndex',
    {
      is: OTHER_REASON_INDEX,
      then: (schema) => schema.required('La razón es requerida'),
    },
  ),
});

const ModalMomentCancellation = ({
  visible,
  momentId,
  onRequestClose,
}) => {
  const dispatch = useDispatch();
  const strikes = useSelector((state) => state.feekApp.strike);

  const formik = useFormik({
    validateOnMount: true,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      APIClient.cancelMoment({
        momentId,
        cancellationMessage:
          values.cancellationReasonIndex === OTHER_REASON_MESSAGE
            ? values.customCancellationMessage
            : defaultCancellationReasons[
                values.cancellationReasonIndex || 0
              ],
      })
        .then(({ data: { strikes } }) => {
          dispatch(setFeeker({ strike: strikes + strikes }));
          dispatch(removeMoment(momentId));
          onRequestClose(true, strikes);
        })
        .catch(handleError)
        .finally(() => actions.setSubmitting(false));
    },
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
  });

  return (
    <Modal
      cssProp={styles}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <article className="content-wrapper">
        <Text fontSize={24} fontWeight="600">
          Cancelar Promoción agendada
        </Text>

        <Text fontSize={20} className="info">
          Recuerda que te
          {strikes === 2 ? ' queda ' : ' quedan '}
          <strong>
            {Math.max(0, 3 - strikes)}
            {strikes === 2 ? ' strike ' : ' strikes '}
          </strong>
          antes de tener una penalización y no poder agendar
          Promociones.{'\n\n'}Si de verdad necesitas cancelar,
          cuéntanos la razón:
        </Text>

        <FormikProvider value={formik}>
          {defaultCancellationReasons.map(
            (cancellationMessage, i) => {
              const isSelected =
                formik.values.cancellationReasonIndex === i;
              return (
                <RadioInput
                  key={i + cancellationMessage}
                  size={24}
                  wrapperClassName="radio-input"
                  label={cancellationMessage}
                  labelProps={{
                    fontSize: 20,
                    lineHeight: 32,
                  }}
                  checked={isSelected}
                  onChange={({ target: checked }) => {
                    if (checked)
                      formik.setFieldValue(
                        'cancellationReasonIndex',
                        i,
                      );
                  }}
                />
              );
            },
          )}

          {formik.values.cancellationReasonIndex ===
            defaultCancellationReasons.length - 1 && (
            <>
              <Text
                className="custom-message-label"
                fontSize={20}
                fontWeight={600}
              >
                Explica la razón
              </Text>
              <FormInputTextArea
                className="custom-message-input"
                placeholder="Escribir..."
                name="customCancellationMessage"
                width="100%"
                height={85}
                maxLength={0}
              />
            </>
          )}

          <ButtonPrimary
            strokeVariant
            strokeColor={RED_NOTIFICATIONS}
            textColor={RED_NOTIFICATIONS}
            label="Confirmar Cancelación"
            height={56}
            disabled={
              !formik.isValid || formik.isSubmitting || !formik.dirty
            }
            onClick={formik.submitForm}
          />
        </FormikProvider>
      </article>
    </Modal>
  );
};

export default ModalMomentCancellation;

ModalMomentCancellation.propTypes = {
  visible: PropTypes.bool,
  momentId: PropTypes.string,
  onRequestClose: PropTypes.func,
};
