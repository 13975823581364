import { createSlice } from '@reduxjs/toolkit';

import APIClient from 'redux/api';

const initialState = {
  id: '',
  username: '',
  pronoun: '',
  birthdate: '',
  bio: '',
  avatar: '',
  backgroundImage: '',
  createdAt: '',
  momentsCompleted: 0,
  totalRewardsWinner: 0,
  totalFeekPoints: 0,
  strike: 0,
  referralPoints: 0,
  myReferralCode: '',
  selectedFeekspotId: localStorage.getItem('selectedFeekspotId'),
  instagramUserName: '',
  isInstagramPrivate: false,
  feekerPhone: '',
  feekerEmail: '',
  isFeekerVerifiedPhone: false,
  isFeekerVerifiedEmail: false,
  lastMomentCompletedId: '',
  notificationsCount: 0,
  isFooterHidden: false,
};

export const appSlice = createSlice({
  name: 'feekApp',
  initialState,
  reducers: {
    selectFeekspot: (state, action) => {
      localStorage.setItem('selectedFeekspotId', action.payload);
      state.selectedFeekspotId = action.payload;
    },

    setFeeker: (state, { payload }) => {
      state.username =
        payload.username || payload.user?.username || state.username;
      state.pronoun =
        payload.pronoun || payload.user?.pronoun || state.pronoun;
      state.birthdate =
        payload.birthdate ||
        payload.user?.birthdate ||
        state.birthdate;
      state.bio =
        payload.bio || payload.profile?.instagram?.bio || state.bio;
      state.avatar =
        payload.avatar ||
        payload.profile?.instagram?.avatar ||
        state.avatar;
      state.backgroundImage =
        payload.backgroundImage ||
        payload.user?.background_image ||
        state.backgroundImage;
      state.createdAt =
        payload.createdAt ||
        payload.profile?.createdAt ||
        state.createdAt;
      state.momentsCompleted =
        payload.momentsCompleted ||
        payload.profile?.statistics?.moments_completed ||
        state.momentsCompleted;
      state.totalRewardsWinner =
        payload.totalRewardsWinner ||
        payload.profile?.statistics?.total_rewards_winner ||
        state.totalRewardsWinner;
      state.totalFeekPoints =
        payload.totalFeekPoints ||
        payload.profile?.statistics?.total_feek_points ||
        state.totalFeekPoints;
      state.strike =
        (payload.strike ?? payload.profile?.strike) < 0
          ? 0
          : payload.strike ?? payload.profile?.strike ?? state.strike;
      state.referralPoints =
        payload.referralPoints ||
        payload.profile?.referral_points ||
        state.referralPoints;
      state.myReferralCode =
        payload.myReferralCode ||
        payload.profile?.my_referral_code ||
        state.myReferralCode;
      state.id = payload.id || payload.user?._id || state.id;
      state.instagramUserName =
        payload.instagramUserName ||
        payload.profile?.instagram?.username ||
        state.instagramUserName;
      state.isInstagramPrivate =
        payload.isInstagramPrivate ||
        payload.profile?.instagram?.is_private ||
        state.isInstagramPrivate;
      state.feekerPhone =
        payload.feekerPhone ||
        payload.user?.phone ||
        state.feekerPhone;
      state.feekerEmail =
        payload.feekerEmail ||
        payload.user?.email ||
        state.feekerEmail;
      state.isFeekerVerifiedPhone =
        payload.feekerVerifiedPhone ||
        payload.user?.verified_phone ||
        state.isFeekerVerifiedPhone;
      state.isFeekerVerifiedEmail =
        payload.isFeekerVerifiedEmail ||
        payload.user?.verified_email ||
        state.isFeekerVerifiedEmail;
      state.lastMomentCompletedId =
        payload.lastMomentCompletedId || state.lastMomentCompletedId;
      state.notificationsCount =
        payload.notificationsCount || state.notificationsCount;
    },

    logout: () => {
      APIClient.unsetAuthenticatedInstance();
    },

    showFooter: (state) => {
      state.isFooterHidden = false;
    },
    hideFooter: (state) => {
      state.isFooterHidden = true;
    },
  },
});

export const {
  logout,
  setFeeker,
  showFooter,
  hideFooter,
  toggleFooter,
  selectFeekspot,
} = appSlice.actions;

export default appSlice.reducer;
