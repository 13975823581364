/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import Carousel from 'react-material-ui-carousel';

import PixelSortedBg1 from 'assets/images/PixelSortedBg1.png';
import PixelSortedBg2 from 'assets/images/PixelSortedBg2.png';
import PixelSortedBgGuadalajaraCathedral from 'assets/images/PixelSortedBgGuadalajaraCathedral.png';

const carouselStyles = css`
  width: 100%;
  height: 100vh;
  margin: 0;
`;

function CarouselScreen() {
  var items = [
    {
      title:
        'Con Feek Manager, olvidate de intermediarios y comisiones.',
      backgroundImage: PixelSortedBgGuadalajaraCathedral,
    },
    {
      title:
        'Aumenta tu posicionamiento de marca eficazmente, con lo más nuevo en mercadotecnia.',
      backgroundImage: PixelSortedBg1,
    },
    {
      title:
        'La mejor publicidad es la que no es literal. Feek te hace crecer de la mejor forma.',
      backgroundImage: PixelSortedBg2,
    },
  ];

  return (
    <Carousel
      css={carouselStyles}
      indicatorIconButtonProps={{
        style: {
          display: 'none',
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          display: 'none',
        },
      }}
      indicatorContainerProps={{
        style: {
          display: 'none',
        },
      }}
      navButtonsAlwaysInvisible={true}
      next={() => {
        /* Do stuff */
      }}
      prev={() => {
        /* Do other stuff */
      }}
    >
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  const styles = css`
    width: 100%;
    height: 100vh;
    margin: 0;
    background-image: url(${props.item.backgroundImage});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-top-right-radius: 14;
    border-bottom-right-radius: 14;

    .carouselContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 20rem;
      bottom: 0;
      position: absolute;
      bottom: 4rem;
      left: 66px;
      overflow: hidden;
    }
  `;

  return (
    <div css={styles}>
      <section
        className="carouselContainer"
        aria-label="Gallery"
      ></section>
    </div>
  );
}

Item.propTypes = {
  item: PropTypes.object,
};

export default CarouselScreen;
