export const MEDIA_QUERIES = Object.freeze({
  /*
    Use example:
  
    ${MEDIA_QUERIES.mobile} {
      .background {
        display: none;
      }
    }
  */

  mobile: '@media (max-width: 520px)',
  tablet: '@media (max-width: 768px)',
  bigScreen: '@media (min-width: 768px)',
  smallScreen: '@media (max-width: 1200px)',
});

export const Z_INDEX = Object.freeze({
  content: '0',
  overContent: '1',
  header: '2',
  footer: '2',
  modal: '3',
  dropDown: '4',
});
