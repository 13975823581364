/** @jsxImportSource @emotion/react */
import usePlacesAutocomplete, {
  getLatLng,
  getGeocode,
} from 'use-places-autocomplete';
import {
  Marker,
  GoogleMap,
  useLoadScript,
} from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import { forwardRef, useLayoutEffect, useState } from 'react';

import {
  RED,
  WHITE,
  GRAY_TEXT,
  GRAY_DARK,
  RED_LIGHT,
  GRAY_LIGHT,
  PURPLE_FEEK,
  GRAY_LIGHTER,
  PURPLE_INACTIVE,
} from 'styles/colors';
import Text from 'components/Text';
import { defaultFont } from 'styles/fonts';
import Button from 'components/buttons/Button';

const apiConfig = {
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries: ['places'],
};

const initialCenter = {
  lat: 25.686613,
  lng: -100.316116,
};

const PlacesAutocompleteInput = forwardRef((props, ref) => {
  const {
    error,
    onChange,
    mapProps,
    className,
    placeholder,
    mapClassName,
    inputClassName,
    withMap = false,
    ...restProps
  } = props;

  const { isLoaded, loadError } = useLoadScript(apiConfig);
  const [center, setCenter] = useState(
    mapProps?.center || initialCenter,
  );

  const [zoom, setZoom] = useState(mapProps?.zoom || 11);
  const {
    init,
    ready,
    setValue,
    clearSuggestions,
    suggestions: { status, data },
  } = usePlacesAutocomplete({
    initOnMount: false,
    debounce: 300,
  });

  const styles = css`
    width: 100%;
    position: relative;

    .error-text {
      min-height: 19px;
      margin: 3px 0;
    }

    input {
      height: 60px;
      width: 100%;
      color: ${GRAY_TEXT};
      border-radius: 16px;
      padding: 0 19px;
      border: 1px solid ${error ? RED : PURPLE_INACTIVE};
      background-color: ${error ? RED_LIGHT : WHITE};
      font-family: ${defaultFont};
      font-size: 16px;
      outline: none;
      border-bottom-left-radius: ${!withMap ? '16px' : 'unset'};
      border-bottom-right-radius: ${!withMap ? '16px' : 'unset'};

      &:-webkit-autofill {
        -webkit-text-fill-color: ${GRAY_TEXT};
        background-color: ${error ? RED_LIGHT : WHITE} !important;
        box-shadow: inset 0 0 0 1px rgb(255 255 255 / 0%),
          inset 0 0 0 1000px ${WHITE};
      }

      &:focus {
        border-color: ${error ? RED : PURPLE_FEEK};
      }

      &::placeholder {
        color: ${GRAY_LIGHT};
      }

      &[disabled] {
        background: ${GRAY_LIGHTER};
        color: ${GRAY_DARK};
        cursor: not-allowed;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    .map-container {
      height: 270px;
      width: 100%;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      border: 1px solid ${error ? RED : PURPLE_INACTIVE};
      border-top: unset;
    }

    .place-suggestion-list {
      position: absolute;
      z-index: 1;
      bottom: 100%;
      left: 0px;
      width: 100%;
      background-color: ${WHITE};
      border-radius: 16px;
      overflow: hidden;
      border: 1px solid ${GRAY_LIGHT};
    }

    .place-suggestion-item {
      border-bottom: 1px solid ${GRAY_LIGHT};
      padding: 5px 10px;
    }
  `;

  const handleInputChange = (e) => {
    setValue(e.target.value);
    onChange?.({ formatedLocation: e.target.value });
  };

  const handleSelect = (placeId) => {
    getGeocode({ placeId }).then((results) => {
      const result = results[0];
      const { lat, lng } = getLatLng(result);
      clearSuggestions();
      setValue(result.formatted_address, false);
      setZoom(15);
      setCenter({ lat, lng });
      onChange?.({
        addressComponents: result.address_components,
        formatedLocation: result.formatted_address,
        lat,
        lng,
      });
    });
  };

  useLayoutEffect(() => {
    if (isLoaded) {
      init();
    }
  }, [isLoaded]);

  return (
    <div ref={ref} css={styles} className={className}>
      {status && status === 'OK' && (
        <div className="place-suggestion-list">
          {data.map((suggestion) => {
            const {
              place_id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
              <Button
                key={place_id}
                onClick={() => {
                  handleSelect(suggestion.place_id);
                }}
                className="place-suggestion-item"
              >
                <Text fontWeight="bold" fontSize={14}>
                  {main_text}
                </Text>
                <Text type="inline">{secondary_text}</Text>
              </Button>
            );
          })}
        </div>
      )}
      <input
        disabled={!ready}
        className={inputClassName}
        {...restProps}
        value={restProps.value?.formatedLocation || ''}
        placeholder={
          !isLoaded
            ? 'Cargando datos...'
            : placeholder || 'Buscar en Google Maps...'
        }
        type="text"
        autoComplete="off"
        onChange={handleInputChange}
      />
      {withMap && isLoaded && (
        <GoogleMap
          clickableIcons={false}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
          }}
          {...mapProps}
          zoom={zoom}
          center={center}
          mapContainerClassName={
            mapClassName
              ? `map-container ${mapClassName}`
              : 'map-container'
          }
        >
          {center && <Marker position={center} />}
        </GoogleMap>
      )}
      <Text
        fontSize={14}
        lineHeight={17}
        fontWeight="500"
        color={RED}
        className="error-text"
      >
        {loadError || typeof error === 'string'
          ? error
          : typeof error === 'object'
          ? Object.values(error)[0]
          : ''}
      </Text>
    </div>
  );
});

export const FormPlacesAutoCompleteInput = (props) => {
  const { name, ...restProps } = props;
  const { getFieldMeta, handleBlur, setFieldValue } =
    useFormikContext();
  const { value, error, touched } = getFieldMeta(name);

  return (
    <PlacesAutocompleteInput
      {...restProps}
      value={value}
      name={name}
      onBlur={handleBlur(name)}
      error={touched && !!error && error}
      onChange={(newValue) => setFieldValue(name, newValue)}
    />
  );
};

FormPlacesAutoCompleteInput.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
};

PlacesAutocompleteInput.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
  withMap: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  mapProps: function (props, propName, componentName) {
    if (props[propName]) {
      if (typeof props['withMap'] !== 'boolean') {
        return new Error(
          `Invalid prop ${propName} supplied to ${componentName}. ${propName} will not be supplied if the "withMap" prop value is false or if is not provided`,
        );
      } else {
        PropTypes.checkPropTypes(
          {
            mapProps: PropTypes.object,
          },
          props,
          propName,
          componentName,
        );
      }
    }
  },
  className: PropTypes.string,
  placeholder: PropTypes.string,
  mapClassName: PropTypes.string,
  inputClassName: PropTypes.string,
};

PlacesAutocompleteInput.displayName = 'PlacesAutocompleteInput';

export default PlacesAutocompleteInput;
