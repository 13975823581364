/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import Modal from 'components/modals/Modal';
import { BLACK, WHITE } from 'styles/colors';
import { MEDIA_QUERIES } from 'styles/constants';
import CardFeekspotSmall from 'components/cards/CardFeekspotSmall';

const styles = css`
  inline-size: 26vw;
  max-inline-size: 400px;
  padding: 24px 0;
  background: ${WHITE};
  border-radius: 20px;

  .feekspots-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-block-start: 20px;
    width: 100%;
  }

  ${MEDIA_QUERIES.mobile} {
    .title {
      text-align: start;
    }

    .feekspots-list {
      gap: 16px;
      margin-block-start: 24px;
    }
  }
`;

function ModalFavouriteFeekspots({
  visible,
  onRequestClose,
  favouriteFeekspots,
  title = 'Feekspots Favoritos',
  ...restProps
}) {
  return (
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      {...restProps}
    >
      <div css={styles}>
        <Text
          align="center"
          fontSize={24}
          fontSizeMobile={20}
          fontWeight="600"
          color={BLACK}
          className="title"
        >
          {title}
        </Text>

        <ul className="feekspots-list">
          {favouriteFeekspots?.map(
            ({
              id,
              name,
              image,
              instagram_username,
              location: { city, state },
            }) => (
              <li key={id}>
                <CardFeekspotSmall
                  instagramUsername={instagram_username}
                  id={id}
                  city={city}
                  state={state}
                  name={name}
                  image={image}
                  responsiveFullVersion
                />
              </li>
            ),
          )}
        </ul>
      </div>
    </Modal>
  );
}

ModalFavouriteFeekspots.propTypes = {
  title: PropTypes.string,
  favouriteFeekspots: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      location: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
      }),
      id: PropTypes.string,
    }),
  ),
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default ModalFavouriteFeekspots;
