/** @jsxImportSource @emotion/react */
import moment from 'moment';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  GRAY,
  BLACK,
  WHITE,
  GRAY_MID,
  GRAY_LIGHTEST,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import handleError from 'utils/handleError';
import { setMoments } from 'redux/entities';
import { setFeeker } from 'redux/app/feekApp';
import Modal from 'components/modals/Modal/index';
import { campaignTypeMiniIconsById } from 'constants';
import { ReactComponent as Zap } from 'assets/icons/Zap.svg';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import ProgressIndicatorSpinner from 'components/ProgressIndicatorSpinner';

const styles = css`
  .modalMain {
    background-color: ${GRAY_LIGHTEST};
  }

  .wrapper {
    width: 390px;
    padding: 40px 24px;
    max-width: 650px;
  }

  .feekspot-container {
    position: relative;
    width: 100%;
    margin-top: 80px;
    padding: 65px 16px 20px 16px;
    border-radius: 16px;
    background-color: ${WHITE};
    border-bottom: 2px dashed ${GRAY_LIGHTEST};
  }
  .action-button {
    border-radius: 40px;
    height: 48px;
  }

  .feekspot-image-container {
    top: -50px;
    width: 110px;
    height: 110px;
    position: absolute;
    border-radius: 16px;
    left: calc(50% - 52px);
    background-color: ${WHITE};
    border: 5px solid ${GRAY_LIGHTEST};
    box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.14);
  }
  .feekspot-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
  }

  .feekspot-name {
    text-transform: uppercase;
  }

  .feekspot-location {
    margin-top: 6px;
    margin-bottom: 8px;
  }

  .current-time {
    display: flex;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    align-self: center;
    padding-left: 14px;
    margin-bottom: 20px;
    border-radius: 13px;
    align-items: center;
    padding-right: 14px;
    justify-content: center;
    border: 2px solid ${BLACK};
  }

  .campaign-container {
    width: 100%;
    border-radius: 16px;
    background-color: ${WHITE};
  }
  .campaign-container-body {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px dashed ${GRAY_LIGHTEST};
  }
  .campaign-container-body,
  .campaign-container-footer {
    padding: 16px;
  }

  .campaign-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .circle {
    width: 24px;
    height: 24px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    padding: 0 !important;
    justify-content: center;
    border: 2px solid ${GRAY_LIGHTEST};
  }

  .image {
    width: 100%;
    height: 460px;
    object-fit: cover;
    margin: 24px auto;
    border-radius: 16px;
    border: 2px solid ${GRAY_MID};
  }
`;

export default function ModalTicketPhoto({
  visible,
  momentId,
  onSuccess,
  campaignId,
  onRequestClose,
}) {
  const [ticket, setTicket] = useState();
  const [loading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  const { campaigns, feekspots, campaignTypes } = useSelector(
    (state) => state.entities,
  );

  const selectedCampaign = campaigns[campaignId];
  const feekspot = feekspots[selectedCampaign?.customer];

  const fileRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const timerID = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  const fileChangeHandle = (e) => {
    setTicket(e.target.files[0]);
  };

  const uploadTicket = () => {
    setLoading(true);
    APIClient.uploadToS3(ticket, 'feekapp/tickets')
      .then(({ data: { Location } }) => {
        APIClient.validateMoment(momentId, Location)
          .then(({ data }) => {
            dispatch(setMoments([data]));
            dispatch(setFeeker({ lastMomentCompletedId: momentId }));
            onSuccess?.();
          })
          .catch(handleError);
      })
      .catch(handleError)
      .finally(() => setLoading(true));
  };

  return (
    <Modal
      cssProp={styles}
      visible={visible}
      onRequestClose={onRequestClose}
      responsiveFullscreen
    >
      <div
        className="wrapper"
        css={{ backgroundColor: ticket ? WHITE : GRAY_LIGHTEST }}
      >
        {ticket ? (
          <>
            <Text
              color={BLACK}
              fontSize={18}
              align="center"
              className="mb-4"
              fontWeight={600}
            >
              ¡Completa y recibe tus Feekpoints!
            </Text>
            <Text align="center" fontSize={16} color={BLACK}>
              Asegúrate de que el total de tu cuenta se vea bien en la
              foto.
            </Text>
            <img
              className="image"
              src={URL.createObjectURL(ticket)}
              alt="preview"
            />
            {!loading ? (
              <div className="row">
                <div className="col-md-6">
                  <ButtonPrimary
                    label="Regresar"
                    strokeVariant
                    fontSize={16}
                    textColor={BLACK}
                    strokeColor={BLACK}
                    className="action-button"
                    onClick={() => {
                      setTicket(null);
                      fileRef.current.value = null;
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <ButtonPrimary
                    fontSize={16}
                    color={BLACK}
                    textColor={WHITE}
                    label="Completar"
                    disabled={loading}
                    onClick={uploadTicket}
                    className="action-button"
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center align-content-center mt-4">
                <ProgressIndicatorSpinner />
                <Text
                  color={GRAY}
                  fontSize={16}
                  className="ms-2"
                  fontWeight={600}
                >
                  Validando fotografia
                </Text>
              </div>
            )}
          </>
        ) : (
          <>
            <Text
              color={BLACK}
              fontSize={24}
              align="center"
              fontWeight={600}
              className="mb-4"
            >
              ✨ ¡Muestra este cupón! ✨
            </Text>
            <Text
              color={BLACK}
              fontSize={16}
              className="mb-3"
              align="center"
            >
              Al recibir tu ticket tómale una foto y recibe tus{' '}
              <b>Feekpoints</b>.
            </Text>
            <div className="feekspot-container">
              <div className="feekspot-image-container">
                <img
                  src={feekspot?.customer?.image}
                  alt={feekspot?.customer?.name}
                  className="feekspot-image"
                />
              </div>
              <Text
                fontSize={16}
                color={BLACK}
                align="center"
                fontWeight={700}
                className="feekspot-name"
              >
                {feekspot?.customer?.name}
              </Text>
              <Text
                color={GRAY}
                align="center"
                className="feekspot-location"
              >
                {[
                  feekspot?.customer.location?.suburb,
                  feekspot?.customer.location?.city,
                  feekspot?.customer.location?.state,
                ]
                  .filter((item) => item)
                  .join(', ')}
              </Text>

              <div className="current-time">
                <Text color={BLACK} fontWeight={600} fontSize={16}>
                  {moment(currentTime).format('DD MMM YYYY')} |{' '}
                  {moment(currentTime).format('HH:mm:ss A')}
                </Text>
              </div>
              <input
                type="file"
                ref={fileRef}
                accept="image/*"
                className="d-none"
                onChange={fileChangeHandle}
              />
              <ButtonPrimary
                className="action-button"
                label="Tomar foto a ticket"
                onClick={() => {
                  fileRef.current.click();
                }}
              />
            </div>
            <div className="campaign-container">
              <div className="campaign-container-body">
                <img
                  className="campaign-image"
                  src={selectedCampaign?.image}
                  alt={selectedCampaign?.title}
                />
                <Text fontSize={16} color={BLACK}>
                  {selectedCampaign?.title}
                </Text>
              </div>
              <div className="campaign-container-footer">
                <div className="d-flex justify-content-between">
                  <div className="d-flex justify-content-between align-items-center">
                    <Icon
                      size={16}
                      color={GRAY}
                      icon={
                        campaignTypeMiniIconsById[
                          selectedCampaign?.type
                        ] || Zap
                      }
                    />
                    <Text
                      color={GRAY}
                      fontWeight={600}
                      className="ms-2"
                    >
                      {campaignTypes[selectedCampaign?.type]?.name}
                    </Text>
                  </div>
                  <div
                    onClick={() =>
                      setDescriptionOpen(!descriptionOpen)
                    }
                    className="d-flex justify-content-between align-items-center cursor-pointer"
                  >
                    <Text fontSize={12} color={BLACK}>
                      Descripción
                    </Text>
                    <div className="circle ms-1">
                      <Icon
                        size={12}
                        icon={Chevron}
                        color={BLACK}
                        rotate={descriptionOpen ? 180 : 0}
                      />
                    </div>
                  </div>
                </div>

                {descriptionOpen && (
                  <Text color={BLACK} className="mt-2" align="center">
                    {selectedCampaign?.description}
                  </Text>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

ModalTicketPhoto.propTypes = {
  visible: PropTypes.bool,
  onSuccess: PropTypes.func,
  momentId: PropTypes.string,
  campaignId: PropTypes.string,
  onRequestClose: PropTypes.func,
};
