/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';

import {
  RED,
  BLACK,
  WHITE,
  GRAY_TEXT,
  GRAY_LIGHT,
  PURPLE_FEEK,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import Modal from 'components/modals/Modal/index';
import { getColorWithAlpha } from 'utils/cssStringManipulation';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';

const styles = css`
  width: 626px;
  padding: 8px 40px 40px;
  max-width: 650px;
  background-color: ${WHITE};

  .stories-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .list {
    margin-top: 20px;
    margin-bottom: 24px;
  }

  .list-item {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    align-items: center;
    gap: 3px;
  }

  .select-input input[type='time'] {
    color: ${GRAY_TEXT} !important;
    font-size: 14px !important;
  }
  .select-input .inputWrapper {
    height: 46px;
    border: 1px solid ${getColorWithAlpha(PURPLE_FEEK, 50)};
  }

  .select-input .inputWrapper:hover,
  .dropdownDateRange:hover {
    border: 1px solid ${PURPLE_FEEK};
  }
  .dropDownDateRange {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    padding: 0 10px;
    border-radius: 10px;
    border: 1px solid ${getColorWithAlpha(PURPLE_FEEK, 50)};
  }
  .action-button {
    margin: 0 auto;
    border-radius: 40px;
    width: 300px;
  }

  .image {
    width: 108px;
    height: 180px;
    margin-top: 10px;
    border-radius: 8px;
  }

  .pagination-button {
    margin: auto 0;
    min-width: 48px;
    height: 48px;
    display: flex;
    border-radius: 36px;
    align-items: center;
    justify-content: center;
    border: 2px solid ${GRAY_LIGHT};
  }
`;

const storiesByPage = 4;

export default function ModalStories({
  isMain,
  visible,
  onSelectStory,
  onRequestClose,
}) {
  const [offset, setOffset] = useState(0);
  const [stories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!visible) return;
    fetchStories();
  }, [visible]);

  const fetchStories = () => {
    setStories([]);
    setIsLoading(true);
    APIClient.getStories()
      .then(({ data }) => {
        setStories(data);
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <div css={styles}>
        <Text
          color={BLACK}
          fontSize={20}
          fontWeight={600}
          className="mb-3"
        >
          Historia {isMain ? 'principal' : 'extra'}
        </Text>
        <Text color={BLACK} fontSize={16}>
          Selecciona la <b>Historia de Instagram</b> que corresponde a
          la promoción para validarla.
        </Text>
        {isLoading ? (
          <Text
            fontSize={20}
            align="center"
            fontWeight="600"
            className="mt-4"
            color={PURPLE_FEEK}
          >
            Cargando historias...
          </Text>
        ) : stories.length > 0 ? (
          <div className="stories-container">
            <Button
              disabled={!(offset > 0 && offset < stories.length)}
              onClick={() => setOffset(offset - 1)}
              className="pagination-button me-2"
            >
              <Icon icon={Chevron} rotate={90} />
            </Button>
            {stories
              .filter(
                (story, index) =>
                  index >= offset && index < offset + storiesByPage,
              )
              .map((story, index) => {
                return (
                  <Button
                    key={story.id}
                    className="me-2"
                    disabled={isLoading}
                    onClick={() => {
                      onSelectStory(story);
                    }}
                  >
                    <img
                      src={story.media_url_base_64}
                      className="image"
                      style={{
                        ...(index === stories?.length - 1
                          ? { marginRight: 0 }
                          : {}),
                      }}
                    />
                  </Button>
                );
              })}
            <Button
              onClick={() => setOffset(offset + 1)}
              disabled={
                !(
                  stories.length > storiesByPage &&
                  stories.length - offset > storiesByPage
                )
              }
              className="pagination-button"
            >
              <Icon icon={Chevron} rotate={270} />
            </Button>
          </div>
        ) : (
          <>
            <Text
              color={RED}
              fontSize={16}
              align="center"
              className="mt-4"
            >
              Debes subir historias a instagram para poder validarlas
            </Text>
            <Button onClick={fetchStories}>
              <Text
                fontSize={16}
                align="center"
                className="mt-3"
                fontWeight={600}
                color={PURPLE_FEEK}
              >
                Recargar
              </Text>
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
}

ModalStories.propTypes = {
  isMain: PropTypes.bool,
  visible: PropTypes.bool,
  onSelectStory: PropTypes.func,
  onRequestClose: PropTypes.func,
};
