/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Icon from 'components/Icon';
import Text from 'components/Text';
import { BLACK, GREEN_LIGHT } from 'styles/colors';
import { ReactComponent as TrophyMini } from 'assets/icons/TrophyMini.svg';

const styles = css`
  width: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 6px 12px;
  background-color: ${GREEN_LIGHT};
  border: 1px solid ${BLACK};
  border-radius: 14px;
`;

export default function BadgeExtraParticipations({
  participations,
  className,
}) {
  return (
    <article css={styles} className={className}>
      <Icon icon={TrophyMini} color={BLACK} height={16} />
      <Text
        color={BLACK}
        lineHeight={'100%'}
        fontSize={16}
        fontWeight="600"
      >
        x
      </Text>
      <Text
        color={BLACK}
        lineHeight={'100%'}
        fontSize={16}
        fontWeight="600"
      >
        {participations}
      </Text>
    </article>
  );
}

BadgeExtraParticipations.propTypes = {
  participations: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  className: PropTypes.string,
};
