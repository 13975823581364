/** @jsxImportSource @emotion/react */
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { forwardRef, useRef } from 'react';

import Text from 'components/Text';
import Button from 'components/buttons/Button';
import { forwardInputEventHandlers } from 'utils/form.utils';
import { RED, WHITE, GRAY_LIGHT, PURPLE_FEEK } from 'styles/colors';

const SwitchInput = forwardRef((props, ref) => {
  const {
    error,
    label,
    checked,
    labelProps,
    wrapperClassName,
    ...restProps
  } = props;

  const checkboxRef = useRef('');

  const styles = css`
    --slider-size: 20px;
    position: relative;
    display: inline-block;

    .inputAndLabel {
      display: flex;
      align-items: center;
      user-select: none;
      gap: 10px;

      .checkboxSurrogate {
        width: 45px;
        height: 26px;
        position: relative;
        background-color: ${GRAY_LIGHT};
        border-radius: 34px;
        border: 6px solid ${GRAY_LIGHT};
        transition: all 0.3s;
        display: flex;
        align-items: center;

        &:before {
          content: '';
          height: var(--slider-size);
          width: var(--slider-size);
          background-color: ${error ? RED : WHITE};
          border-radius: 50%;
          transition: transform 0.4s;
        }
      }

      input {
        display: none;
        visibility: hidden;
      }

      input:checked + .checkboxSurrogate {
        background-color: ${error ? RED : PURPLE_FEEK};
        border: 6px solid ${error ? RED : PURPLE_FEEK};
      }

      input:checked + .checkboxSurrogate:before {
        ${!error
          ? `
          -webkit-transform: translateX(13px);
          -ms-transform: translateX(13px);
          transform: translateX(13px);
          `
          : ''}
      }

      &:hover > .checkboxSurrogate {
        scale: 105%;
      }
    }

    .error-text {
      margin: 3px 0;
      min-height: 19px;
    }
  `;

  const handleClick = (e) => {
    e.stopPropagation();
    checkboxRef.current?.click();
  };

  return (
    <div css={styles} className={wrapperClassName}>
      <Button
        className="inputAndLabel"
        onClick={handleClick}
        ref={ref}
      >
        <input
          ref={checkboxRef}
          type="checkbox"
          checked={checked}
          {...restProps}
        />

        <span className="checkboxSurrogate"></span>

        {typeof label === 'string' ? (
          <Text fontSize={14} {...labelProps}>
            {label}
          </Text>
        ) : (
          label
        )}
      </Button>

      <Text
        fontSize={14}
        lineHeight={17}
        fontWeight="500"
        color={RED}
        className="error-text"
      >
        {typeof error === 'string' ? error : ''}
      </Text>
    </div>
  );
});

export const FormSwitchInput = (props) => {
  const { name, error, ...restProps } = props;

  return (
    <Field name={name}>
      {({ meta, field }) => (
        <SwitchInput
          name={name}
          checked={meta.value}
          error={meta.error || error}
          {...restProps}
          {...forwardInputEventHandlers(restProps, field)}
        />
      )}
    </Field>
  );
};

SwitchInput.propTypes = {
  checked: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  wrapperClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelProps: PropTypes.shape({ ...Text.propTypes }),
};

FormSwitchInput.propTypes = {
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

SwitchInput.displayName = SwitchInput;

export default SwitchInput;
