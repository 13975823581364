/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import moment from 'moment';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import {
  WHITE,
  PURPLE_FEEK,
  GRAY_LIGHTER,
  GRAY_LIGHTEST,
  GRADIENT_PURPLE,
} from 'styles/colors';
import APIClient from 'redux/api';
import Icon from 'components/Icon';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import { rolesSelectValues } from 'constants';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import Modal from 'components/modals/Modal/index';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { FormInputSelect } from 'components/inputs/InputSelect';
import { ReactComponent as Crown } from 'assets/icons/Crown.svg';
import { ReactComponent as Trash } from 'assets/icons/Trash.svg';
import { FormInputText } from 'components/inputs/InputText/index';
import DropDownLabelAndIcon from 'components/DropDownLabelAndIcon';
import { ReactComponent as PlusCircle } from 'assets/icons/PlusCircle.svg';
import { ReactComponent as PenInSquare } from 'assets/icons/PenInSquare.svg';
import { ReactComponent as PopcornBucket } from 'assets/icons/PopcornBucket.svg';
import ModalEditCollaborator from 'screens/manager/legacy/Collaborators/ModalEditCollaborator';
import ModalEraseCollaborator from 'screens/manager/legacy/Collaborators/ModalEraseCollaborator';
import { ReactComponent as MagnifyingGlassRealistic } from 'assets/icons/MagnifyingGlassRealistic.svg';

const sendCollaboratorInvitationFormInitialValues = {
  role: rolesSelectValues[0].value,
  email: '',
};

const sendCollaboratorInvitationFormValidationSchema =
  Yup.object().shape({
    email: Yup.string()
      .required('El email es requerido')
      .email('El email es inválido'),
  });

const styles = css`
  width: 100%;
  height: 80vh;
  padding-inline: 41px;

  .header {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 2rem;
    justify-content: space-between;

    .title {
      padding: 2rem;
    }

    .subtitleTextContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .subtitleText {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
    }

    .subtitleTextBold {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
    }

    .headerListSubTitleContainer {
      display: flex;
      height: 1rem;
      width: 100%;
      align-items: center;
    }

    .headerListTitleOuterContainer {
      display: flex;
      flex-direction: column;
    }

    .dropDown {
      height: 48px;
      border-radius: 16px;
      background: ${GRADIENT_PURPLE};

      .label {
        color: ${WHITE};
      }
    }
  }

  .listContainer {
    display: grid;
    grid-template-columns: repeat(3, minmax(max-content, 1fr)) auto;
    row-gap: 5px;
    align-items: center;
    overflow-y: auto;
    margin-block-start: 35px;

    thead,
    tbody,
    tr {
      display: contents;
    }

    th,
    td {
      padding-block: 12px;
      :nth-of-type(4) {
        justify-self: center;
      }
    }

    tbody tr {
      &:nth-of-type(odd) td {
        height: 100%;
        background: ${GRAY_LIGHTEST};
        display: flex;
        align-items: center;
      }
    }

    td,
    th {
      padding-inline-end: 15px;
    }

    td:first-of-type,
    th:first-of-type {
      padding-inline-start: 15px;
    }
  }

  .nameContainer {
    display: flex;
    align-items: center;
    padding-inline-start: 15px;

    *:nth-of-type(2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .iconContainer {
    flex: 0 0 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    background: ${PURPLE_FEEK};
    border-radius: 4.7619px;
    margin-inline-end: 10px;
  }

  .updateIconContainer {
    display: flex;
    align-items: center;
    gap: 25px;
  }
`;

const stylesaddExistentCollaboratorsModal = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 440px;
  padding: 50px;
  background-color: ${WHITE};
  border-radius: 16px;

  .title {
    margin-bottom: 24px;
  }

  .inputs {
    width: 100%;
    margin-top: 24px;

    .label {
      margin-bottom: 10px;
    }
  }

  .buttons {
    display: flex;
    gap: 24px;
    width: 100%;

    > div {
      height: 48px;
      text-align: center;

      > div {
        text-align: center;
      }
    }
  }

  ${MEDIA_QUERIES.mobile} {
    padding: 40px 24px;
    width: 100%;
  }
`;

const stylesDropDown = css`
  color: ${WHITE};

  .content {
    width: max-content;
    padding: 8px 0;
    .button {
      padding: 12px;

      &:hover {
        background-color: ${GRAY_LIGHTER};
      }
    }
  }
`;

const roles = {
  customerOwner: 'Owner',
  customerAdmin: 'Administrador',
  customerSupervisor: 'Supervisor',
  customerViewer: 'Espectador',
};

function getIcon(role) {
  switch (role) {
    case 'customerAdmin':
      return Crown;
    case 'customerSupervisor':
      return MagnifyingGlassRealistic;
    case 'customerViewer':
      return PopcornBucket;
    default:
      return Crown;
  }
}

function CollaboratorRow({
  handleEditCollaborator,
  handleEliminateCollaborator,
  name = '',
  role = '',
  createdAt,
}) {
  return (
    <tr>
      <td className="nameContainer">
        <div className="iconContainer">
          <Icon color={WHITE} icon={getIcon(role)} size={16} />
        </div>
        <Text fontSize={14} color={PURPLE_FEEK} fontWeight={600}>
          {name}
        </Text>
      </td>

      <td>
        <Text fontSize={12} fontWeight={500}>
          {roles[role]}
        </Text>
      </td>

      <td>
        <Text fontSize={12} fontWeight={500}>
          Desde {moment(createdAt).format('D MMM YY')}
        </Text>
      </td>

      <td className="updateIconContainer">
        <Button
          disabled={role === 'customerOwner'}
          onClick={handleEditCollaborator}
        >
          <Icon color={PURPLE_FEEK} icon={PenInSquare} size={20} />
        </Button>

        <Button
          disabled={role === 'customerOwner'}
          onClick={handleEliminateCollaborator}
        >
          <Icon icon={Trash} color={PURPLE_FEEK} size={20} />
        </Button>
      </td>
    </tr>
  );
}

function CollaboratorList(props) {
  const { handleIndex, collaborators, collaboratorsTotalPlaces } =
    props;
  const selectedFeekspotId = useSelector(
    (state) => state.managerApp.selectedFeekspotId,
  );
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [ModalContent, setModalContent] = useState(null);

  const closeModal = () => setModalContent(null);

  const handleDropDownOpen = () => {
    setIsDropDownOpen(true);
  };
  const handleDropDownClose = () => {
    setIsDropDownOpen(false);
  };

  const showEditCollaboratorModal = (collaboratorId) => {
    setModalContent(
      <ModalEditCollaborator
        collaborator={collaborators?.find(
          (collaborator) => collaborator._id === collaboratorId,
        )}
        handleClose={closeModal}
      />,
    );
  };

  const showEraseCollaboratorModal = (collaboratorId) => {
    setModalContent(
      <ModalEraseCollaborator
        onClose={closeModal}
        collaboratorToDelete={collaboratorId}
      />,
    );
  };

  const handleSendCollaboratorInvitation = async (
    values,
    actions,
  ) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);

    try {
      const response = await APIClient.inviteCollaborator({
        ...values,
        customer_id: selectedFeekspotId,
      });

      if (response.status === 201) {
        closeModal();
        toast.success('Invitación enviada con exito.');
      }
    } catch (error) {
      actions.setSubmitting(false);
      handleError(error);
    }
  };

  const AddExistingCollaborator = (
    <Formik
      onSubmit={handleSendCollaboratorInvitation}
      initialValues={sendCollaboratorInvitationFormInitialValues}
      validationSchema={
        sendCollaboratorInvitationFormValidationSchema
      }
    >
      {(formik) => (
        <Form css={stylesaddExistentCollaboratorsModal}>
          <Text
            fontSize={24}
            align="center"
            fontWeight="bold"
            lineHeight={32}
            className="title"
          >
            Agregar Colaborador existente
          </Text>

          <Text fontSize={16} lineHeight={24} align="center">
            Escoge el rol que deseas asignar al Colaborador y la
            dirección de correo electrónico donde recibirá la
            invitación.
          </Text>

          <div className="inputs">
            <Text fontWeight="bold" fontSize={16} className="label">
              Rol
            </Text>

            <FormInputSelect
              name="role"
              options={rolesSelectValues}
            />

            <Text fontWeight="bold" fontSize={16} className="label">
              Correo electrónico
            </Text>

            <FormInputText
              name="email"
              placeholder="Correo electrónico"
            />
          </div>

          <div className="buttons">
            <ButtonPrimary
              strokeVariant
              label="Cancelar"
              onClick={closeModal}
            />
            <ButtonPrimary
              label="Enviar Invitación"
              onClick={formik.submitForm}
              disabled={
                !formik.dirty ||
                !formik.isValid ||
                formik.isSubmitting
              }
            />
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div css={styles}>
      <Modal visible={!!ModalContent} onRequestClose={closeModal}>
        {ModalContent}
      </Modal>

      <div className="header">
        <div className="headerListTitleOuterContainer">
          <div className="headerListSubTitleContainer">
            <h5 className="subtitleTextContainer">
              <p className="subtitleTextBold">
                {Math.max(
                  collaboratorsTotalPlaces - collaborators.length,
                  0,
                )}{' '}
                Lugares &nbsp;
              </p>{' '}
              <span className="subtitleText">disponibles </span>
            </h5>
          </div>
        </div>
        {collaborators.length < 5 && (
          <DropDownLabelAndIcon
            className="dropDown"
            label="Agregar nuevo"
            isOpen={isDropDownOpen}
            onOpen={handleDropDownOpen}
            onClose={handleDropDownClose}
            rightIcon={<Icon icon={PlusCircle} color={WHITE} />}
            childrenWrapperCSS={stylesDropDown}
          >
            <Button className="button" onClick={() => handleIndex(1)}>
              <Text fontSize={14}>Agregar un Colaborador nuevo</Text>
            </Button>
            <Button
              className="button"
              onClick={() => {
                setModalContent(AddExistingCollaborator);
                setIsDropDownOpen(false);
              }}
            >
              <Text fontSize={14}>
                Agregar un Colaborador existente
              </Text>
            </Button>
          </DropDownLabelAndIcon>
        )}
      </div>

      <table className="listContainer">
        <thead>
          <tr>
            <th>
              <Text fontSize={12} fontWeight={600}>
                Colaborador
              </Text>
            </th>
            <th>
              <Text fontSize={12} fontWeight={600}>
                Rol
              </Text>
            </th>
            <th>
              <Text fontSize={12} fontWeight={600}>
                Fecha de creación
              </Text>
            </th>
            <th>
              <Text fontSize={12} fontWeight={600}>
                Acciones
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {collaborators?.map((collaborator) => {
            return (
              <CollaboratorRow
                collaboratorId={collaborator._id}
                name={collaborator.user.name}
                role={collaborator.role}
                key={collaborator._id}
                createdAt={collaborator.created_at}
                handleEditCollaborator={() =>
                  showEditCollaboratorModal(collaborator._id)
                }
                handleEliminateCollaborator={() =>
                  showEraseCollaboratorModal(collaborator._id)
                }
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

CollaboratorRow.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  createdAt: PropTypes.string,
  handleEditCollaborator: PropTypes.func,
  handleEliminateCollaborator: PropTypes.func,
};

CollaboratorList.propTypes = {
  handleIndex: PropTypes.func,
  collaborators: PropTypes.arrayOf(PropTypes.object),
  collaboratorsTotalPlaces: PropTypes.number,
};

export default CollaboratorList;
