/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import { BLACK, WHITE } from 'styles/colors';
import Modal from 'components/modals/Modal/index';

const styles = css`
  width: 400px;
  padding: 40px;
  max-width: 650px;
  background-color: ${WHITE};
`;

export default function ModalPrivateUser({
  visible,
  onRequestClose,
}) {
  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <div css={styles}>
        <Text
          color={BLACK}
          fontSize={20}
          align="center"
          fontWeight={600}
          className="mb-4"
        >
          ¡Usuario Privado! <br />
          🫣
        </Text>
        <Text
          color={BLACK}
          fontSize={16}
          className="mb-3"
          align="center"
        >
          Detectamos que <b>tu Instagram está privado</b>, no te
          preocupes podrás validar tu promoción subiendo una foto
          dentro de <b>Feek</b>.
        </Text>
        <Text color={BLACK} fontSize={14} align="center">
          *Usuarios privados reciben menos Feekpoints.
        </Text>
      </div>
    </Modal>
  );
}

ModalPrivateUser.propTypes = {
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func,
};
