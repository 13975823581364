/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import { weekDays } from 'constants';
import { formatLocation } from 'utils/format';
import AccordionStatus from 'components/AccordionStatus';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import ModalAddress from 'components/legacy/modals/ModalAddress';
import ModalSchedule from 'components/legacy/modals/ModalSchedule';

const styles = css`
  > :not(div:first-of-type) {
    margin-block-start: 12px;
  }

  .button {
    width: fit-content;
    height: 30px;

    div:first-of-type {
      font-weight: 400;
    }
  }
`;

const weekDaysAbbreviations = Object.fromEntries(
  Object.keys(weekDays).map((crrDay, i) => [
    crrDay,
    {
      index: i,
      ab:
        crrDay === 'wednesday'
          ? 'X'
          : weekDays[crrDay]?.substring(0, 1),
    },
  ]),
);

function AccordionProfileAddress({
  statusIcon = false,
  className,
  feekspot,
}) {
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);

  return (
    <>
      <ModalAddress
        visible={showAddressModal}
        onRequestClose={() => setShowAddressModal(false)}
        handleCloseUpdateAddress={() => setShowScheduleModal(false)}
        days={feekspot?.days}
        location={feekspot?.location}
        feekspotId={feekspot?._id}
      />
      <ModalSchedule
        visible={showScheduleModal}
        onRequestClose={() => setShowScheduleModal(false)}
        handleCloseUpdateAddress={() => setShowScheduleModal(false)}
        days={feekspot?.days}
        feekspotId={feekspot?._id}
      />

      <AccordionStatus
        status={
          statusIcon
            ? !!feekspot?.location && !!feekspot?.days.length
            : undefined
        }
        title={'Dirección y horario'}
        className={className}
        content={
          <div css={styles}>
            {feekspot?.location && (
              <Text fontSize={12} fontWeight="500" lineHeight={14.4}>
                {formatLocation(feekspot.location)}
              </Text>
            )}

            <ButtonPrimary
              strokeVariant
              onClick={() => setShowAddressModal(true)}
              label="Editar Dirección"
              fontSize={13}
              className="button"
            />
            <div>
              {feekspot?.days
                .reduce(
                  (accumulator, currentDay) => {
                    const currentHours = `${currentDay.open_hour.replace(
                      ':00',
                      '',
                    )}–${currentDay.close_hour.replace(
                      ':00',
                      '',
                    )} hrs`;
                    const currentDayAbb =
                      weekDaysAbbreviations[currentDay.day].ab;

                    let newStrings = [...accumulator.strings];
                    let hasDash = false;

                    if (
                      accumulator.lastIndex ===
                        weekDaysAbbreviations[currentDay.day].index -
                          1 &&
                      accumulator.lastHours === currentHours
                    ) {
                      if (accumulator.hasDash) {
                        newStrings[newStrings.length - 1] = newStrings
                          .at(-1)
                          .replace(/–[LMXJVSD]/, `–${currentDayAbb}`);
                      } else {
                        newStrings[newStrings.length - 1] = newStrings
                          .at(-1)
                          .replace(
                            / de.*/,
                            `–${currentDayAbb} de ${currentHours}`,
                          );
                      }
                      hasDash = true;
                    } else {
                      newStrings.push(
                        `${currentDayAbb} de ${currentHours}`,
                      );
                    }

                    return {
                      lastHours: currentHours,
                      hasDash: hasDash,
                      strings: newStrings,
                      lastIndex:
                        weekDaysAbbreviations[currentDay.day].index,
                    };
                  },
                  {
                    lastHours: null,
                    hasDash: false,
                    strings: [],
                  },
                )
                .strings.map((string, i) => (
                  <Text
                    key={i}
                    className="days"
                    fontSize={12}
                    fontWeight="500"
                    lineHeight={14.4}
                  >
                    {string}
                  </Text>
                ))}
            </div>

            <ButtonPrimary
              strokeVariant
              onClick={() => setShowScheduleModal(true)}
              label="Editar Horarios"
              fontSize={13}
              className="button"
            />
          </div>
        }
      />
    </>
  );
}

AccordionProfileAddress.propTypes = {
  className: PropTypes.string,
  feekspot: PropTypes.object,
  statusIcon: PropTypes.bool,
};

export default AccordionProfileAddress;
