/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';

import APIClient from 'redux/api';
import Text from 'components/Text';
import { weekDays } from 'constants';
import { WHITE } from 'styles/colors';
import Modal from 'components/modals/Modal';
import handleError from 'utils/handleError';
import { setFeekspots } from 'redux/entities';
import { MEDIA_QUERIES } from 'styles/constants';
import SwitchInput from 'components/inputs/SwitchInput';
import InputSelect from 'components/inputs/InputSelect';
import ButtonPrimary from 'components/buttons/ButtonPrimary';

const styles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  inline-size: 520px;
  padding: 40px 50px;
  background: ${WHITE};
  border-radius: 16px;

  > :first-of-type {
    margin-block-end: 25px;
  }

  .hourPickerSelector {
    .hourPickerMobileLabel {
      display: none;
    }

    .selectInput {
      .error-text {
        display: none;
      }
    }
  }

  .schedule {
    width: 100%;
    display: grid;
    grid: auto / repeat(4, auto);
    align-items: center;
    gap: 10px 25px;

    .dataWrapper {
      display: contents;

      .toggleWrapper {
        display: flex;
      }

      .toggle {
        grid-column: 2;
        .error-text {
          display: none;
        }
      }
    }
  }

  .button {
    margin-block-start: 40px;
    width: 249px;
    height: 60px;
  }

  ${MEDIA_QUERIES.mobile} {
    .schedule {
      grid: auto / repeat(4, 1fr);
      gap: 25px 15px;

      .dayName {
        grid-column: 1 / 2;
        display: flex;
        align-items: center;
      }
      .toggleWrapper {
        grid-column: 2 / 3;
      }
      .open_hour {
        grid-column: 1 / 3;
        width: max-content;
      }
      .close_hour {
        grid-column: 3 / 5;
        justify-self: end;
      }
      .title.open_hour,
      .title.close_hour {
        display: none;
      }

      .hourPickerSelector {
        > div {
          display: flex;
          gap: 12px;
          align-items: center;
        }

        .hourPickerMobileLabel {
          display: initial;
        }

        &.open_hour {
          display: none;
        }
        &.close_hour {
          display: none;
        }
        &.show.open_hour,
        &.show.close_hour {
          display: initial;
        }
      }
    }
  }
`;

const hours = ['open_hour', 'close_hour'];
function HoursSelection({ currentDay, setDaysCurrent, disabled }) {
  return (
    <>
      {hours.map((hour) => (
        <span
          key={hour}
          className={`${
            disabled ? '' : 'show'
          } hourPickerSelector ${hour}`}
        >
          <div>
            <Text
              type="inline"
              className="hourPickerMobileLabel"
              fontSize={13}
            >
              {hour === 'open_hour' ? 'Apertura' : 'Cierre'}
            </Text>

            <InputSelect
              className="selectInput"
              value={
                currentDay.active && currentDay[hour]
                  ? currentDay[hour]
                  : '0:00'
              }
              onChange={(e) => {
                setDaysCurrent((crr) => {
                  return {
                    ...crr,
                    [currentDay.day]: {
                      ...currentDay,
                      [hour]: e.target.value,
                    },
                  };
                });
              }}
              disabled={disabled}
              options={[
                { value: '0:00', label: '0:00' },
                { value: '1:00', label: '1:00' },
                { value: '2:00', label: '2:00' },
                { value: '3:00', label: '3:00' },
                { value: '4:00', label: '4:00' },
                { value: '5:00', label: '5:00' },
                { value: '6:00', label: '6:00' },
                { value: '7:00', label: '7:00' },
                { value: '8:00', label: '8:00' },
                { value: '9:00', label: '9:00' },
                { value: '10:00', label: '10:00' },
                { value: '11:00', label: '11:00' },
                { value: '12:00', label: '12:00' },
                { value: '13:00', label: '13:00' },
                { value: '14:00', label: '14:00' },
                { value: '15:00', label: '15:00' },
                { value: '16:00', label: '16:00' },
                { value: '17:00', label: '17:00' },
                { value: '18:00', label: '18:00' },
                { value: '19:00', label: '19:00' },
                { value: '20:00', label: '20:00' },
                { value: '21:00', label: '21:00' },
                { value: '22:00', label: '22:00' },
                { value: '23:00', label: '23:00' },
              ]}
            />
          </div>
        </span>
      ))}
    </>
  );
}

function ModalSchedule({
  days,
  feekspotId,
  visible,
  onRequestClose,
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [daysCurrent, setDaysCurrent] = useState(() => {
    const daysMap = new Map(
      days?.map((day) => [day.day, { ...day }]),
    );
    return Object.fromEntries(
      Object.keys(weekDays).map((dayName) => [
        dayName,
        daysMap?.has(dayName)
          ? { ...daysMap.get(dayName), active: true }
          : {
              day: dayName,
              open_hour: '0:00',
              close_hour: '0:00',
              active: false,
            },
      ]),
    );
  });

  const updateProfile = async () => {
    setIsLoading(true);
    try {
      await APIClient.updateFeekspot(
        {
          days: Array.from(Object.values(daysCurrent)).filter(
            (day) => day.active,
          ),
        },
        feekspotId,
      ).then(() => {
        APIClient.getFeekspotsByCustomer().then(({ data }) => {
          const feekspots = data.reduce(
            (prev, crr) => ({
              ...prev,
              [crr.customer._id]: crr,
            }),
            {},
          );
          dispatch(setFeekspots(feekspots));
        });
        setIsLoading(false);
        onRequestClose();
      });
    } catch (error) {
      setIsLoading(false);
      handleError(error);
    }
  };

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <div css={styles}>
        <Text align="center" fontSize={20} fontWeight="700">
          Horario
        </Text>

        <div className="schedule">
          <Text className="dayName" fontSize={14}>
            Día
          </Text>
          <Text className="toggleWrapper" fontSize={14}>
            Abierto
          </Text>
          <Text className="title open_hour" fontSize={14}>
            Apertura
          </Text>
          <Text className="title close_hour" fontSize={14}>
            Cierre
          </Text>

          {Object.keys(daysCurrent).map((dayName) => (
            <div className="dataWrapper" key={dayName}>
              <Text
                className="dayName"
                fontSize={14}
                fontWeight="700"
              >
                {weekDays[dayName]}
              </Text>

              <SwitchInput
                label={
                  daysCurrent[dayName]?.active ? 'Abierto' : 'Cerrado'
                }
                wrapperClassName="toggle"
                checked={daysCurrent[dayName].active}
                onChange={(e) =>
                  setDaysCurrent((crr) => {
                    return {
                      ...crr,
                      [dayName]: {
                        ...crr[dayName],
                        active: e.target.checked,
                      },
                    };
                  })
                }
              />

              <HoursSelection
                currentDay={daysCurrent[dayName]}
                setDaysCurrent={setDaysCurrent}
                disabled={!daysCurrent[dayName]?.active}
              />
            </div>
          ))}
        </div>

        <ButtonPrimary
          fontSize={16}
          className="button"
          disabled={isLoading}
          onClick={updateProfile}
          label="Guardar Cambios"
        />
      </div>
    </Modal>
  );
}

HoursSelection.propTypes = {
  currentDay: PropTypes.object,
  setDaysCurrent: PropTypes.func,
  disabled: PropTypes.bool,
};

ModalSchedule.propTypes = {
  days: PropTypes.arrayOf(PropTypes.object),
  feekspotId: PropTypes.string,
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default ModalSchedule;
