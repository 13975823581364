/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import Text from 'components/Text';
import { WHITE } from 'styles/colors';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_MANAGER } from 'constants/routes';
import CheckboxInput from 'components/inputs/CheckBox';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import WhirlpoolOfColorsBg from 'assets/images/WhirlpoolOfColorsBg.png';
import CampaignPreviewCard from 'screens/manager/legacy/AddCampaign/CampaignPreviewCard';

const styles = css`
  flex: 1;
  display: flex;
  padding: 24px;
  padding-top: 66px;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url(${WhirlpoolOfColorsBg});

  .title {
    margin: 0 0 30px 0;
    > div {
      margin: 0 auto 10px auto;
    }
  }

  .campaignPreviewCard {
    width: 420px;
    margin-block-end: 40px;
  }

  .saveCheckbox {
    width: fit-content;
    margin-bottom: 20px;

    .checkboxSurrogate {
      border: 2px solid ${WHITE};
      background: transparent;
    }
  }

  .menu {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    width: 480px;
    color: ${WHITE};

    & :first-of-type {
      border-color: ${WHITE};
    }
  }

  .availableCampaigns {
    margin: 40px 0 0 0;
  }

  ${MEDIA_QUERIES.mobile} {
    margin: 0;
    padding-top: 40px;

    .title {
      :first-of-type {
        font-size: 38px;
        line-height: 45.6px;
      }
    }

    .campaignPreviewCard {
      width: 100%;
    }

    .menu {
      width: 100%;
      flex-direction: column;
      gap: 15px;
      padding-inline: 24px;
    }
  }
`;

function Step5Success({ handleResetForm, handleInitForm }) {
  const navigate = useNavigate();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const selectedFeekspotId = useSelector(
    (state) => state.managerApp.selectedFeekspotId,
  );
  let [searchParams] = useSearchParams();
  const feekspots = useSelector((state) => state.entities.feekspots);
  const selectedFeekspot = feekspots[selectedFeekspotId]?.customer;
  const { values } = useFormikContext();

  const handleReset = () => {
    isCheckboxChecked ? handleInitForm() : handleResetForm();
  };

  return (
    <div css={styles}>
      <div className="title">
        <Text
          color={WHITE}
          fontSize={50}
          lineHeight={60}
          fontWeight="700"
          align="center"
        >
          ¡Felicidades!
        </Text>
        <Text
          color={WHITE}
          fontSize={18}
          lineHeight={22}
          fontWeight="400"
          align="center"
        >
          Campaña{' '}
          {searchParams.get('update_id')
            ? 'editada'
            : searchParams.get('clone_id')
            ? 'clonada'
            : 'creada'}{' '}
          con éxito
        </Text>
      </div>

      <CampaignPreviewCard
        values={values}
        className="campaignPreviewCard"
      />

      <CheckboxInput
        label="Guardar esta configuración de campaña"
        wrapperClassName="saveCheckbox"
        labelProps={{
          fontSize: 14,
          fontWeight: '400',
          color: WHITE,
          type: 'inline',
        }}
        checked={isCheckboxChecked}
        onChange={(e) => {
          setIsCheckboxChecked(e.target.checked);
        }}
      />

      <div className="menu">
        <ButtonPrimary
          onClick={() => navigate(ROUTES_MANAGER.CAMPAIGNS)}
          label="Regresar a Campañas"
          strokeVariant
          color="transparent"
          textColor={WHITE}
        />

        <ButtonPrimary
          onClick={handleReset}
          label="Crear otra Campaña"
        />
      </div>

      <Text
        color={WHITE}
        fontSize={14}
        lineHeight={22}
        fontWeight="400"
        align="center"
        className="availableCampaigns"
      >
        Tienes <strong>{selectedFeekspot?.places}</strong>
        {selectedFeekspot?.places === 1
          ? ' campaña disponible '
          : ' campañas disponibles '}
        este mes
      </Text>
    </div>
  );
}

Step5Success.propTypes = {
  handleResetForm: PropTypes.func,
  handleInitForm: PropTypes.func,
};

export default Step5Success;
