/** @jsxImportSource @emotion/react */
import moment from 'moment';
import es from 'date-fns/locale/es';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

import {
  RED,
  BLACK,
  WHITE,
  GREEN_C,
  GRAY_TEXT,
  GRAY_LIGHT,
  PURPLE_FEEK,
  PURPLE_INACTIVE,
  GRADIENT_DASHBOARD,
  PURPLE_INACTIVE_LIGHT,
} from 'styles/colors';
import APIClient from 'redux/api';
import Icon from 'components/Icon';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { campaignTypeMiniIconsById } from 'constants';
import Carousel from 'screens/manager/legacy/Dashboard/Carousel';
import DropDownLabelAndIcon from 'components/DropDownLabelAndIcon';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import LinearChart from 'screens/manager/legacy/Dashboard/LinearChart';
import DoughnutChart from 'screens/manager/legacy/Dashboard/DoughnutChart';
import DashboardCard from 'screens/manager/legacy/Dashboard/DashboardCard';
import { ReactComponent as AvatarFilled } from 'assets/icons/AvatarFilled.svg';
import { ReactComponent as CalendarMini } from 'assets/icons/CalendarMini.svg';
import DailyActivityChart from 'screens/manager/legacy/Dashboard/DailyActivityChart';
import { ReactComponent as ImagePlaceholder } from 'assets/icons/ImagePlaceholder.svg';
import MostViewedStoriesCarousel from 'screens/manager/legacy/Dashboard/MostViewedStoriesCarousel';

const datesOptions = [
  {
    label: 'Últimos 7 días',
    value: [
      moment(new Date()).subtract(7, 'days').toDate(),
      new Date(),
    ],
  },
  {
    label: 'Últimos 30 días',
    value: [
      moment(new Date()).subtract(30, 'days').toDate(),
      new Date(),
    ],
  },
  {
    label: 'Últimos 90 días',
    value: [
      moment(new Date()).subtract(90, 'days').toDate(),
      new Date(),
    ],
  },
];

const statusOfCampaignOptions = [
  {
    label: 'Todas',
    value: 'all',
  },
  {
    label: 'Completadas',
    value: 'completed',
  },
  {
    label: 'En curso',
    value: 'in_progress',
  },
];

const stylesDropDown = css`
  .filtersHandlersContainer_dropDownContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 250px;
  }

  .dropDownDateRange {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    padding: 0 10px;
    border-radius: 10px;
    border: 1px solid ${GRAY_LIGHT};
  }

  ${MEDIA_QUERIES.mobile} {
    .content {
      transform: translate(-100%, 0);
      width: max-content;
    }
  }
`;

const oldStyle = css`
  .filtersHandlersContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 0 24px 40px;
    margin: 1rem 0;
  }

  .filtersHandlersContainer_dropDown > .label {
    margin-bottom: 5px;
  }

  .filtersHandlersContainer_dropDown .input {
    flex: 1;
    min-width: 205px;
  }

  .chartsContainer {
    display: grid;
    grid-auto-rows: 367px;
    width: 100%;
    padding: 30px 24px;
    gap: 21px;
    background: ${GRADIENT_DASHBOARD};
  }

  .missingData {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 29px;
    gap: 26px;
  }

  .topCards {
    grid-column: 1 / -1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .topCards_card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;
    background-color: ${WHITE};
    width: 100%;
    flex-shrink: 10;
  }

  .topCards_card:last-of-type {
    flex-shrink: 9;
  }

  .card_data {
    display: flex;
    justify-content: space-between;
    height: 100%;
    margin-top: 5px;
  }

  .data_item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .carousel {
    flex: 1;
    margin: 0 5px;
  }

  .campaignsSummaryCard {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid ${PURPLE_INACTIVE};
  }

  .campaignsSummaryCard_header {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .campaignKind_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;
    margin-right: 6px;
    background-color: ${PURPLE_INACTIVE_LIGHT};
    border-radius: 2px;
  }

  .campaignsSummaryCard_data table {
    width: 100%;
    border-collapse: collapse;
  }

  .campaignsSummaryCard_data table td:first-of-type {
    width: 40%;
  }

  .campaignsSummaryCard_data table td {
    padding-bottom: 8px;
  }

  .campaignName {
    height: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* Scope summary and most active feekers */
  /* general */
  .table {
    overflow-x: auto;
    padding: 20px;
    height: 100%;
  }

  .table table {
    color: ${GRAY_TEXT};
    border-collapse: collapse;
    width: 100%;
  }

  .table th {
    font-size: 12px;
  }

  .table td {
    height: 37px;
    border-bottom: 1px solid ${GRAY_LIGHT};
    font-size: 14px;
    text-align: center;
  }

  .table tr:last-of-type td {
    border-bottom: unset;
  }

  .table th:first-of-type,
  .table td:first-of-type {
    text-align: left;
    font-weight: bold;
  }

  .mostActiveFeekers td:first-of-type {
    display: flex;
    align-items: center;
  }

  .mostActiveFeekers_avatar {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
    overflow: hidden;
    outline: 2px solid ${GRAY_LIGHT};
  }

  .mostActiveFeekers_avatar svg {
    margin-bottom: -2px;
  }

  .showOnMobileOnly {
    display: none;
  }

  @media screen and (min-width: 769px) {
    .filtersHandlersContainer {
      flex-direction: row;
    }

    .filtersHandlersContainer_dropDown .input {
      max-width: 250px;
    }

    .chartsContainer {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: 117px;
    }
    .topCards {
      flex-direction: row;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    .showOnMobileOnly {
      display: block;
      margin: 40px 24px 20px;
    }
  }
`;

registerLocale('es', es);

function Dashboard() {
  const [isDateDropDownOpen, setIsDateDropDownOpen] = useState(false);
  const [StatusOfCampaignFilter, setStatusOfCampaignFilter] =
    useState('all');
  const [datesOfCampaignFilter, setDatesOfCampaignFilter] = useState({
    startDate: moment(new Date()).subtract(30, 'days').toDate(),
    endDate: new Date(),
  });
  const [
    isStatusOfCampaignDropDownOpen,
    setIsStatusOfCampaignDropDownOpen,
  ] = useState(false);
  const [dateDropDownLabel, setDateDropDownLabel] =
    useState('Últimos 30 días');
  const [
    statusOfCampaignDropDownLabel,
    setStatusOfCampaignDropDownLabel,
  ] = useState('Todas');
  const [analytics, setAnalytics] = useState({});

  const [campaignsCarouselIndex, setCampaignsCarouselIndex] =
    useState(0);

  const handleCampaignsCarouselBack = () => {
    setCampaignsCarouselIndex((crr) =>
      crr - 1 < 0 ? campaignsSummary.length - 1 : crr - 1,
    );
  };

  const handleCampaignsCarouselNext = () => {
    setCampaignsCarouselIndex((crr) =>
      crr + 1 > campaignsSummary.length - 1 ? 0 : crr + 1,
    );
  };

  const handleDates = ([startDate, endDate]) => {
    setDatesOfCampaignFilter({
      startDate,
      endDate,
    });
  };
  const handleDateDropDownOpen = () => {
    setIsDateDropDownOpen(true);
  };
  const handleDateDropDownClose = () => {
    setIsDateDropDownOpen(false);
  };

  const handleDatePickerChange = (dates) => {
    setDateDropDownLabel(
      `${moment(dates[0])
        .locale('es')
        .format('D MMM')
        .replace(/[a-z]/, (char) => char.toUpperCase())
        .replace(/\./, '')} - ${
        dates[1]
          ? moment(dates[1])
              .locale('es')
              .format('D MMM')
              .replace(/[a-z]/, (char) => char.toUpperCase())
              .replace(/\./, '')
          : ''
      }
      `,
    );
    handleDates(dates);
  };
  const handleDateOptionsButtonClick = (i) => {
    setDateDropDownLabel(datesOptions[i].label);
    handleDates(datesOptions[i].value);
    handleDateDropDownClose();
  };
  const handleStatusOfCampaignDropDownOpen = () => {
    setIsStatusOfCampaignDropDownOpen(true);
  };
  const handleStatusOfCampaignDropDownClose = () => {
    setIsStatusOfCampaignDropDownOpen(false);
  };

  const handleStatusOfCampaignButtomClick = (i) => {
    setStatusOfCampaignDropDownLabel(
      statusOfCampaignOptions[i].label,
    );
    setStatusOfCampaignFilter(statusOfCampaignOptions[i].value);
    handleStatusOfCampaignDropDownClose();
  };

  const {
    ages,
    genders,
    devices,
    topFeekers,
    totalSummary,
    scopeSummary,
    personalities,
    activityByDay,
    dailyFollowers,
    campaignsSummary,
    mostViewedStories,
    feekspot_income,
  } = analytics;

  const getDateFromData = (date) =>
    moment(date || '')
      .format('D MMM')
      .replace(/[a-z]/, (char) => char.toUpperCase())
      .replace(/\./, '');

  for (const crr of [
    totalSummary || [],
    scopeSummary || [],
    ...(mostViewedStories || []),
  ]) {
    Object.keys(crr).forEach((prop) => {
      if (Number.isFinite(crr[prop]))
        crr[prop] = crr[prop].toLocaleString('en-US');
    });
  }

  const { selectedFeekspotId } = useSelector(
    (state) => state.managerApp,
  );

  const feekspots = useSelector((state) => state.entities.feekspots);
  const selectedFeekspot = feekspots[selectedFeekspotId]?.customer;

  useEffect(() => {
    if (
      ![
        selectedFeekspot,
        StatusOfCampaignFilter,
        datesOfCampaignFilter.endDate,
        datesOfCampaignFilter.endDate,
      ].some((value) => !value)
    ) {
      const payload = {
        feekspotId: selectedFeekspotId,
        get_campaigns: StatusOfCampaignFilter,
        start_date: moment(datesOfCampaignFilter.startDate).format(
          'YYYY/MM/DD',
        ),
        end_date: moment(datesOfCampaignFilter.endDate).format(
          'YYYY/MM/DD',
        ),
      };

      APIClient.getAnalytics(payload)
        .then(({ data }) => {
          setAnalytics({
            mostViewedStories: data.feekers_stories,
            totalSummary: data.total_summary,
            activityByDay: data.feekers_activity_day,
            campaignsSummary: data.campaigns_insights,
            scopeSummary: data.scope_summary,
            topFeekers: data.top_feekers,
            genders: data.feekers_gender,
            devices: data.feekers_device_os,
            ages: data.feekers_ages,
            personalities: data.feekers_personality,
            dailyFollowers: data.customer_followers.reduce(
              (objTotal, crr) => {
                objTotal?.values?.push(crr.followers || 0);
                objTotal?.labels?.push(
                  getDateFromData(crr.date || ''),
                );
                return objTotal;
              },
              { values: [], labels: [] },
            ),
            customer_income: data?.customer_income?.reduce(
              (objTotal, crr) => {
                objTotal.values.push(crr.income || 0);
                objTotal.labels.push(getDateFromData(crr.date || ''));
                return objTotal;
              },
              { values: [], labels: [] },
            ),
          });
        })
        .catch(handleError);
    }
  }, [
    selectedFeekspot,
    datesOfCampaignFilter,
    StatusOfCampaignFilter,
  ]);

  const datesShortLabel = `${moment(datesOfCampaignFilter.startDate)
    .locale('es')
    .format('D MMM')
    .replace(/[a-z]/, (char) => char.toUpperCase())
    .replace(/\./, '')} - ${moment(datesOfCampaignFilter.endDate)
    .locale('es')
    .format('D MMM')
    .replace(/[a-z]/, (char) => char.toUpperCase())
    .replace(/\./, '')}`;

  return (
    <div css={oldStyle}>
      <Text fontSize={20} className="showOnMobileOnly">
        Analíticas
      </Text>

      <div className="filtersHandlersContainer">
        <div className="filtersHandlersContainer_dropDown">
          <Text fontSize={14} className="label">
            Filtrar campañas
          </Text>
          <DropDownLabelAndIcon
            className="input"
            isOpen={isStatusOfCampaignDropDownOpen}
            onOpen={handleStatusOfCampaignDropDownOpen}
            onClose={handleStatusOfCampaignDropDownClose}
            label={statusOfCampaignDropDownLabel}
            childrenWrapperCSS={stylesDropDown}
          >
            <div className="filtersHandlersContainer_dropDownContent">
              <Text fontSize={16} fontWeight="600">
                Mostrar
              </Text>
              {statusOfCampaignOptions.map((data, i) => (
                <Button
                  key={i}
                  onClick={() => handleStatusOfCampaignButtomClick(i)}
                >
                  <Text fontSize={16}>{data.label}</Text>
                </Button>
              ))}
            </div>
          </DropDownLabelAndIcon>
        </div>

        <div className="filtersHandlersContainer_dropDown">
          <Text fontSize={14} className="label">
            Periodo mostrado
          </Text>
          <DropDownLabelAndIcon
            className="input"
            isOpen={isDateDropDownOpen}
            onOpen={handleDateDropDownOpen}
            onClose={handleDateDropDownClose}
            leftIcon={<Icon icon={CalendarMini} />}
            label={dateDropDownLabel}
            childrenWrapperCSS={stylesDropDown}
          >
            <div className="filtersHandlersContainer_dropDownContent">
              <Text fontSize={16} fontWeight="600">
                Rango de tiempo
              </Text>
              {datesOptions.map((data, i) => (
                <Button
                  key={i}
                  onClick={() => handleDateOptionsButtonClick(i)}
                >
                  <Text fontSize={16}>{data.label}</Text>
                </Button>
              ))}
              <ReactDatePicker
                customInput={
                  <div className="dropdownDateRange">
                    <Text fontSize={16}>
                      {moment(datesOfCampaignFilter.startDate)
                        .locale('es')
                        .format('D MMM YY')
                        .replace(/[a-z]/, (char) =>
                          char.toUpperCase(),
                        )
                        .replace(/\./, '')}{' '}
                      -{' '}
                      {moment(datesOfCampaignFilter.endDate)
                        .locale('es')
                        .format('D MMM YY')
                        .replace(/[a-z]/, (char) =>
                          char.toUpperCase(),
                        )
                        .replace(/\./, '')}
                    </Text>
                    <Icon icon={Chevron} size={14} />
                  </div>
                }
                className="dropDownDateRange"
                selectsRange={true}
                startDate={datesOfCampaignFilter.startDate}
                endDate={datesOfCampaignFilter.endDate}
                placeholder="Fecha de inicio > Fecha de termino"
                locale="es"
                onChange={handleDatePickerChange}
                withPortal={false}
              ></ReactDatePicker>
            </div>
          </DropDownLabelAndIcon>
        </div>
      </div>

      <div className="chartsContainer">
        <div className="topCards">
          <div className="topCards_card">
            <Text fontSize={18}>Impresiones</Text>
            <div className="card_data">
              <div className="data_item">
                <Text fontSize={32} fontWeight="bold">
                  {totalSummary?.impressions}
                </Text>
                <Text fontSize={12}>
                  Total de vistas de contenidos
                </Text>
              </div>
            </div>
          </div>
          <div className="topCards_card">
            <Text fontSize={18}>Campañas</Text>
            <div className="card_data">
              <div className="data_item">
                <Text fontSize={32} fontWeight="bold">
                  {totalSummary?.campaigns_completed}
                </Text>
                <Text fontSize={12}>Completadas</Text>
              </div>
              <div className="data_item">
                <Text fontSize={32} fontWeight="bold">
                  {totalSummary?.campaigns_progress}
                </Text>
                <Text fontSize={12}>En curso</Text>
              </div>
            </div>
          </div>
          <div className="topCards_card">
            <Text fontSize={18}>Contenidos</Text>
            <div className="card_data">
              <div className="data_item">
                <Text fontSize={32} fontWeight="bold">
                  {totalSummary?.stories_photo}
                </Text>
                <Text fontSize={12}>Photo Stories</Text>
              </div>
              <div className="data_item">
                <Text fontSize={32} fontWeight="bold">
                  {totalSummary?.stories_video}
                </Text>
                <Text fontSize={12}>Video Stories</Text>
              </div>
            </div>
          </div>
        </div>

        <DashboardCard
          title="Actividad por días"
          dates={datesShortLabel}
        >
          <DailyActivityChart data={activityByDay} />
        </DashboardCard>

        <DashboardCard
          title="Resumen de alcance"
          dates={datesShortLabel}
        >
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Rubro</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total de Feekers</td>
                  <td>{scopeSummary?.total_feekers}</td>
                </tr>
                <tr>
                  <td>Campañas completadas</td>
                  <td>{scopeSummary?.campaigns_completed}</td>
                </tr>
                <tr>
                  <td>Campañas activas</td>
                  <td>{scopeSummary?.campaigns_progress}</td>
                </tr>
                <tr>
                  <td>Porcentaje de asistencia</td>
                  <td>{scopeSummary?.attendance_percentage}%</td>
                </tr>
                <tr>
                  <td>Tareas completadas</td>
                  <td>{scopeSummary?.completed_tasks}</td>
                </tr>
                <tr>
                  <td>Extras completados</td>
                  <td>{scopeSummary?.extras_completed}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </DashboardCard>

        <DashboardCard title="Seguidores" dates={datesShortLabel}>
          <LinearChart
            data={{ ...dailyFollowers }}
            options={{
              title: (values) => {
                const total = values?.at(-1) - values?.[0] || 0;
                return (
                  <div className="title">
                    <Text
                      fontSize={14}
                      fontWeight="bold"
                      color={
                        total === 0
                          ? GRAY_TEXT
                          : total > 0
                          ? GREEN_C
                          : RED
                      }
                    >
                      {(total === 0 ? '' : total > 0 ? '+' : '-') +
                        Math.abs(total).toLocaleString('en-US')}
                    </Text>
                    <Text fontSize={14}>
                      {total > 0 && 'nuevos '}
                      {total !== 1 && total !== -1
                        ? 'seguidores'
                        : 'seguidor'}
                    </Text>
                  </div>
                );
              },
              tooltipCallbacks: (values) => ({
                labelTextColor: (labelItem) => {
                  const total =
                    values[labelItem.dataIndex] -
                      values[labelItem.dataIndex - 1] || 0;
                  return total === 0
                    ? GRAY_TEXT
                    : total > 0
                    ? GREEN_C
                    : RED;
                },
                label: (labelItem) => {
                  const followersDifference =
                    values[labelItem.dataIndex] -
                      values[labelItem.dataIndex - 1] || 0;
                  return (
                    (followersDifference >= 0 ? '+' : '-') +
                    Math.abs(followersDifference) +
                    ' seguidor' +
                    (Math.abs(followersDifference) !== 1 ? 'es' : '')
                  );
                },
              }),
            }}
          />
        </DashboardCard>

        <DashboardCard
          title="Ingresos por campañas"
          dates={datesShortLabel}
        >
          <LinearChart
            data={{ ...feekspot_income }}
            options={{
              title: (values) => (
                <div className="title">
                  <Text fontSize={14} fontWeight="bold" color={BLACK}>
                    {(Number(values.at(-1)) || 0).toLocaleString(
                      'en-US',
                    )}
                  </Text>
                  <Text fontSize={14}>total generado</Text>
                </div>
              ),
              tooltipCallbacks: (values) => ({
                labelTextColor: () => BLACK,
                label: (labelItem) =>
                  `${values[labelItem.dataIndex]} MXN`,
              }),
            }}
          />
        </DashboardCard>

        <DashboardCard
          title="Stories más vistas"
          dates={datesShortLabel}
        >
          <MostViewedStoriesCarousel
            dates={datesShortLabel}
            storiesList={mostViewedStories}
          />
        </DashboardCard>

        <DashboardCard
          title="Resumen de campañas"
          dates={datesShortLabel}
        >
          {!campaignsSummary || campaignsSummary.length === 0 ? (
            <div className="missingData">
              <Text
                fontSize={16}
                lineHeight={22.4}
                color={PURPLE_INACTIVE}
              >
                Aún no tenemos suficiente información para mostrar
                esta tabla.
              </Text>
            </div>
          ) : (
            <Carousel
              index={campaignsCarouselIndex}
              onBack={handleCampaignsCarouselBack}
              onNext={handleCampaignsCarouselNext}
              isBackDisabled={campaignsSummary.length === 1}
              isNextDisabled={campaignsSummary.length === 1}
              className="carousel"
              itemList={campaignsSummary.map((campaignData) => (
                <div
                  className="campaignsSummaryCard"
                  key={campaignData.id}
                >
                  <div className="campaignsSummaryCard_header">
                    <div className="campaignKind_icon">
                      <Icon
                        icon={
                          campaignTypeMiniIconsById[
                            campaignData.type?._id
                          ] || ImagePlaceholder
                        }
                        color={PURPLE_FEEK}
                        size={15}
                      />
                    </div>
                    <Text type="inline" fontSize={12}>
                      {campaignData.type?.name}
                    </Text>
                  </div>
                  <Text
                    className="campaignName"
                    fontWeight="bold"
                    fontSize={16}
                  >
                    {campaignData.title}
                  </Text>
                  <div className="campaignsSummaryCard_data">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <Text fontWeight="bold" fontSize={14}>
                              Status
                            </Text>
                          </td>
                          <td>
                            <Text fontSize={14} color={PURPLE_FEEK}>
                              {campaignData.status}
                            </Text>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Text fontWeight="bold" fontSize={14}>
                              Reservaciones
                            </Text>
                          </td>
                          <td>
                            <Text fontSize={14}>
                              {campaignData.places}
                            </Text>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Text fontWeight="bold" fontSize={14}>
                              Asistencia
                            </Text>
                          </td>
                          <td>
                            <Text fontSize={14}>
                              {campaignData.percentage_assistance}
                            </Text>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Text fontWeight="bold" fontSize={14}>
                              Historias
                            </Text>
                          </td>
                          <td>
                            <Text fontSize={14}>
                              {campaignData.total_stories}
                            </Text>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Text fontWeight="bold" fontSize={14}>
                              Impresiones
                            </Text>
                          </td>
                          <td>
                            <Text fontSize={14}>
                              {campaignData?.impressions || 0}
                            </Text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            />
          )}
        </DashboardCard>

        <DashboardCard title="Top 5 Feekers" dates={datesShortLabel}>
          {!topFeekers || topFeekers.length === 0 ? (
            <div className="missingData">
              <Text
                fontSize={16}
                lineHeight={22.4}
                color={PURPLE_INACTIVE}
              >
                Aún no tenemos suficiente información para mostrar
                esta tabla.
              </Text>
            </div>
          ) : (
            <div className="table mostActiveFeekers">
              <table>
                <thead>
                  <tr>
                    <th>Top</th>
                    <th>Campañas</th>
                    <th>Historias</th>
                    <th>Vistas</th>
                  </tr>
                </thead>
                <tbody>
                  {topFeekers.slice(0, 6).map((feeker, index) => (
                    <tr key={feeker.id}>
                      <td>
                        <div className="mostActiveFeekers_avatar">
                          <Icon
                            size={20}
                            icon={AvatarFilled}
                            color={GRAY_LIGHT}
                          />
                        </div>{' '}
                        Top {index + 1}
                      </td>
                      <td>{feeker.campaigns}</td>
                      <td>{feeker.stories}</td>
                      <td>{feeker.views}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </DashboardCard>

        <DashboardCard title="Generos" dates={datesShortLabel}>
          <DoughnutChart
            data={{
              labels: ['Hombres', 'Mujeres', 'Otros'],
              values: [
                genders?.male,
                genders?.female,
                genders?.not_specified,
              ],
            }}
          />
        </DashboardCard>

        <DashboardCard title="Edades" dates={datesShortLabel}>
          <DoughnutChart
            data={{
              labels: [
                '18 a 25 años',
                '26 a 30 años',
                '31 a 35 años',
                '36 a 50 años',
              ],
              values: [
                ages?.range_18_25,
                ages?.range_26_30,
                ages?.range_31_35,
                ages?.range_36_50,
              ],
            }}
          />
        </DashboardCard>

        <DashboardCard title="Dispositivos" dates={datesShortLabel}>
          <DoughnutChart
            data={{
              labels: ['Android', 'iPhone', 'Otros'],
              values: [
                devices?.android,
                devices?.ios,
                devices?.others,
              ],
            }}
          />
        </DashboardCard>

        <DashboardCard title="Perfiles" dates={datesShortLabel}>
          <DoughnutChart
            data={{
              labels: [
                'Aventurero',
                'Extrovertido',
                'Introvertido',
                'Objetivo',
              ],
              values: [
                personalities?.adventurous,
                personalities?.outgoing,
                personalities?.introverted,
                personalities?.objective,
              ],
            }}
          />
        </DashboardCard>
      </div>
    </div>
  );
}

export default Dashboard;
