/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import {
  useSearchBox,
  useInfiniteHits,
} from 'react-instantsearch-hooks';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';

import { GRAY } from 'styles/colors';
import NoDataMessage from 'components/NoDataMessage';
import CardCampaign from 'components/cards/CardCampaign';
import ProgressIndicatorSpinnerMessage from 'components/ProgressIndicatorSpinnerMessage';

const styles = css`
  .cursor-pointer {
    cursor: pointer;
    user-select: none;
  }

  .message {
    margin: 0 auto;
    max-width: 700px;
  }

  .campaign-card-img {
    width: 100%;
    height: 300px;
    min-width: 300px;
  }
`;
export default function RefinementList() {
  const { refine, isSearchStalled } = useSearchBox({
    attribute: 'title',
    operator: 'and',
  });

  const { search } = useParams();
  const { results } = useInfiniteHits();

  useEffect(() => {
    refine(search);
  }, [search]);

  return (
    <div className="row" css={styles}>
      {results?.hits?.length === 0 && (
        <NoDataMessage
          emoji="🥲"
          className="message "
          message={`No hay resultados para '${search}'`}
        />
      )}

      {isSearchStalled ? (
        <ProgressIndicatorSpinnerMessage className="message" />
      ) : (
        results.hits.map((item) => (
          <CardCampaign
            key={item.objectID}
            className="col-lg-6 col-xxl-4 mb-4"
            campaignId={item?._id}
            type={item?.type}
            image={item?.image}
            title={item?.title}
            feekspot={item?.customer?.name}
            places={item?.places}
            colorVariant={GRAY}
            feekpoints={item?.task?.feek_points}
          />
        ))
      )}
    </div>
  );
}

RefinementList.propTypes = {
  typesFilter: PropTypes.array,
  categoriesFilter: PropTypes.array,
};
