/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import {
  BLACK,
  WHITE,
  PURPLE_FEEK,
  TRANSPARENT,
} from 'styles/colors';
import Icon from 'components/Icon';
import Slogan from 'components/Slogan';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_FEEKAPP } from 'constants/routes';
import FooterLanding from 'components/FooterLanding';
import FriendsEating from 'assets/images/FriendsEating.webp';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import FriendsEatingPNG from 'assets/images/FriendsEating.png';
import { getColorWithAlpha } from 'utils/cssStringManipulation';
import { ReactComponent as FeekLogo } from 'assets/icons/FeekLogo.svg';
import { ReactComponent as Individual } from 'assets/icons/Individual.svg';

const styles = css`
  .main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 16px;
    background-image: url(${FriendsEatingPNG});
    background-image: url(${FriendsEating});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .header {
      --header-margin-inline: 70px;
      display: flex;
      align-items: center;
      width: calc(100vw - var(--header-margin-inline) * 2);
      margin-inline: var(--header-margin-inline);
      border-radius: 20px;
      padding: 30px 40px;
      justify-content: space-between;
      background-color: ${getColorWithAlpha(WHITE, 70)};
      backdrop-filter: blur(10px);

      .feekLogo {
        margin-right: 55px;
      }

      .slogan {
        flex: 1;
      }
    }

    .content-wrapper {
      width: 420px;
      margin-top: 100px;
      padding: 60px 40px;
      border-radius: 20px;
      background-color: ${WHITE};
    }
  }
  ${MEDIA_QUERIES.mobile} {
    background-color: ${BLACK};

    .main-wrapper {
      padding: 0 20px;
      border-radius: 0 0 20px 20px;

      .header {
        width: 100vw;
        margin: 0;
        padding: 20px;
        border-radius: 0px 0px 20px 20px;
        .slogan {
          display: none;
        }

        .feekLogo {
          margin-right: 20px;
          height: 40px;
        }
      }

      .content-wrapper {
        width: 100%;
        margin-block: 110px;
        padding: 40px 20px;
      }
    }
  }
`;

export default function LayoutLogin({
  headerButtons = (
    <Link to={ROUTES_FEEKAPP.LOGIN} className="text-decoration-none">
      <ButtonPrimary
        pillShape
        layoutOnly
        strokeVariant
        heightMobile={40}
        gap={10}
        textColor={BLACK}
        strokeColor={BLACK}
        color={TRANSPARENT}
        label="Iniciar sesión"
        textProps={{ fontSize: 20, fontWeight: 500 }}
        icon={<Icon icon={Individual} color={BLACK} size={16} />}
      />
    </Link>
  ),
  children,
}) {
  return (
    <main css={styles}>
      <div className="main-wrapper">
        <header className="header">
          <Link to={ROUTES_FEEKAPP.LANDING}>
            <Icon
              icon={FeekLogo}
              color={PURPLE_FEEK}
              height={60}
              className="feekLogo"
            />
          </Link>
          <Slogan className="slogan" color={BLACK} />
          {headerButtons}
        </header>

        <div className="content-wrapper">{children}</div>
      </div>

      <FooterLanding />
    </main>
  );
}

LayoutLogin.propTypes = {
  children: PropTypes.element.isRequired,
  headerButtons: PropTypes.element,
};
