/** @jsxImportSource @emotion/react */
import moment from 'moment';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import {
  GRAY,
  BLACK,
  WHITE,
  GRAY_COLD,
  GRAY_DARK,
  PURPLE_FEEK,
  WHITE_ALMOST,
  GRAY_LIGHTEST,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import { titlesFeekApp } from 'constants';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { formatNumberToShort } from 'utils/format';
import { AvatarsCabinet } from 'components/AvatarsCabinet';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import ImagePlaceholder from 'assets/images/ImagePlaceholder.jpg';
import CardFeekspotSmall from 'components/cards/CardFeekspotSmall';
import { ReactComponent as ZapMini } from 'assets/icons/ZapMini.svg';
import { ReactComponent as Instagram } from 'assets/icons/Instagram.svg';
import ModalLastLikedFeekers from 'components/modals/ModalLastLikedFeekers';
import { ReactComponent as HeartBicolor } from 'assets/icons/HeartBicolor.svg';
import ModalFavouriteFeekspots from 'components/modals/ModalFavouriteFeekspots';

const styles = css`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: start;
  background-color: ${GRAY_COLD};
  padding-block: 50px 85px;

  .profile {
    --vertical-gap: 30px;
    --horizontal-gap: 24px;
    --grid-full-row: 1 / 3;
    --border-radius: 10px;
    --border-radius-big: 20px;
    flex: 0 1 min(45%, 685px);
    display: grid;
    grid: auto-flow / repeat(2, minmax(0, 1fr));
    flex-direction: column;
    justify-content: space-between;
    gap: var(--horizontal-gap) var(--vertical-gap);
    padding: 24px 48px 48px;
    background-color: ${WHITE};
    border-radius: var(--border-radius-big);

    .profile-image {
      width: calc(100% + 48px);
      grid-column: var(--grid-full-row);
      aspect-ratio: 2/1;
      border-radius: var(--border-radius);
      object-fit: cover;
      justify-self: center;
    }

    .profile-header {
      grid-column: var(--grid-full-row);
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-block-start: calc(-55px - var(--vertical-gap));
      margin-inline: 24px;

      .avatar {
        border-width: 4px;
      }

      .personal-info {
        flex: 1;
        justify-self: start;
        align-self: end;

        .name-wrapper {
          display: grid;
          grid-template:
            'icon name' auto
            'icon pronoun' auto
            / auto 1fr;
          align-items: center;
          column-gap: 12px;
          row-gap: 4px;
          margin-block-end: 7px;

          .instagram-icon {
            grid-area: icon;
            background-color: ${GRAY_DARK};
            padding: 8.5px;
            border-radius: 8px;
          }

          .username {
            align-self: start;
          }

          .pronoun {
            align-self: end;
          }
        }
      }
    }

    .profile-statistics {
      grid-column: var(--grid-full-row);
      display: grid;
      grid-template: 105px / repeat(3, minmax(0, 1fr));
      gap: var(--horizontal-gap);

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: ${GRAY_COLD};
        border-radius: var(--border-radius-big);

        dd {
          font-size: 32px;
          font-weight: 600;
        }

        :last-child > dd {
          color: ${PURPLE_FEEK};
        }
      }
    }

    .profile-modal-button {
      flex: 1;
      justify-content: space-between;
      border-radius: var(--border-radius);
    }

    .profile-section-wrapper {
      > div:first-of-type {
        margin-block-end: 16px;
      }

      &:last-of-type {
        grid-column: var(--grid-full-row);

        > div:first-of-type {
          margin-block-end: 32px;
        }
      }

      .last-visited {
        li {
          &:not(:last-of-type) {
            margin-block-end: 12px;
          }

          .card-feekspot-small {
            border-color: ${GRAY_LIGHTEST};

            .button {
              padding: 24px;
              height: auto;

              .feekspot-info {
                .avatar {
                  width: 80px;
                  height: 80px;
                }

                .name-wrapper {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  gap: 8px;

                  .name {
                    font-size: 24px;
                    text-transform: uppercase;
                  }

                  .location,
                  .distance {
                    font-size: 16px;
                  }
                }

                .chevron {
                  background-color: ${WHITE_ALMOST};
                  border-radius: 50%;
                  height: 32px;
                  width: 32px;
                  padding-block: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

function ProfileFeeker() {
  const { feekerUsername } = useParams();
  const [currentModal, setCurrentModal] = useState('');
  const [favouriteFeekspots, setFavouriteFeekspots] = useState([
    {
      image: '',
      id: '',
      location: { city: '', state: '' },
      name: '',
    },
  ]);
  const [lastLikedFeekers, setLastLikedFeekers] = useState([
    {
      avatar: '',
      name: '',
      profile_username: '',
      total_feek_points: 0,
      _id: '',
    },
  ]);
  const [lastVisitedFeekspots, setLastVisitedFeekspots] = useState(
    [],
  );
  const [
    {
      username,
      instagram_username,
      background_image: backgroundImage,
      pronoun,
      createdAt,
      instagram_avatar: avatar,
      bio,
      total_feek_points: totalFeekPoints,
      moments_completed: momentsCompleted,
      total_rewards: totalRewards,
    },
    setFeeker,
  ] = useState({
    username: '',
    instagram_username: '',
    background_image: '',
    pronoun: '',
    createdAt: '',
    instagram_avatar: '',
    bio: '',
    total_feek_points: 0,
    moments_completed: 0,
    total_rewards: 0,
  });

  useEffect(() => {
    document.title = titlesFeekApp.ProfileFeekerEdit;

    if (!feekerUsername) return;

    APIClient.getProfileFeekerPublicByUsername(feekerUsername)
      .then(({ data }) => {
        setFeeker(data);
        document.title = data.username + ' • Feek';

        APIClient.getFeekerLastLikedFeekers(data.id)
          .then(({ data: { docs } }) => setLastLikedFeekers(docs))
          .catch(handleError);

        APIClient.getFeekerFavouriteFeekspots(data.id)
          .then(({ data: { docs } }) => setFavouriteFeekspots(docs))
          .catch(handleError);

        APIClient.getLastFeekspotsByUser(data.id)
          .then(({ data }) =>
            setLastVisitedFeekspots(data.toReversed()),
          )
          .catch(handleError);
      })
      .catch(() => {
        handleError('No se pudo encontrar la información del feeker');
      });
  }, []);

  return (
    <main css={styles}>
      <article className="profile">
        <ModalLastLikedFeekers
          feekers={lastLikedFeekers}
          visible={currentModal === 'ModalLastLikedFeekers'}
          onRequestClose={() => setCurrentModal('')}
        />
        <ModalFavouriteFeekspots
          favouriteFeekspots={favouriteFeekspots}
          visible={currentModal === 'ModalFavouriteFeekspots'}
          onRequestClose={() => setCurrentModal('')}
        />

        <img
          className="profile-image"
          src={backgroundImage || ImagePlaceholder}
          alt={username + ' • Feek'}
        />

        <header className="profile-header">
          <Avatar
            className="avatar"
            name={username}
            image={avatar}
            size={140}
            alt={username + ' • Feek'}
          />
          <span className="personal-info">
            <div className="name-wrapper">
              {instagram_username && (
                <Link
                  target="_blank"
                  className="instagram-icon"
                  to={`https://instagram.com/${instagram_username}`}
                >
                  <Button layoutOnly>
                    <Icon icon={Instagram} color={WHITE} size={20} />
                  </Button>
                </Link>
              )}
              <Text
                className="username"
                fontSize={24}
                lineHeight={24}
                fontWeight={600}
                color={BLACK}
              >
                {instagram_username || username}
              </Text>
              <Text
                className="pronoun"
                lineHeight={12}
                fontSize={12}
                color={GRAY}
              >
                {pronoun && <strong>{pronoun}, </strong>}Feeker desde{' '}
                <time dateTime="createdAt">
                  {moment(createdAt).format('MMMM [de] YYYY')}
                </time>
              </Text>
            </div>
            <Text fontSize={16} color={BLACK}>
              {bio}
            </Text>
          </span>
        </header>

        <dl className="profile-statistics">
          <div>
            <dt>Promociones</dt>
            <dd>{momentsCompleted?.toString().padStart(2, '0')}</dd>
          </div>
          <div>
            <dt>Recompensas</dt>
            <dd>{totalRewards?.toString().padStart(2, '0')}</dd>
          </div>
          <div>
            <dt>Feekpoints</dt>
            <dd>
              <Icon icon={ZapMini} color={PURPLE_FEEK} height={16} />
              {formatNumberToShort(totalFeekPoints)
                .toString()
                .padStart(2, '0')}
            </dd>
          </div>
        </dl>

        <div className="profile-section-wrapper">
          <Text fontSize={21} color={BLACK} fontWeight={600}>
            Últimos me gusta
          </Text>
          <Button
            hideDisabledStyles
            onClick={() => setCurrentModal('ModalLastLikedFeekers')}
            disabled={
              !lastLikedFeekers || lastLikedFeekers?.length === 0
            }
          >
            <AvatarsCabinet
              avatars={lastLikedFeekers
                ?.slice(0, 6)
                ?.map((like) => like.avatar)}
              avatarsSize={48}
              overlap={18}
              {...(lastLikedFeekers?.length > 6 && {
                text: `+ ${formatNumberToShort(
                  lastLikedFeekers?.length - 6,
                  1,
                )}`,
              })}
              textBGColor={BLACK}
            />
          </Button>
        </div>

        <div className="profile-section-wrapper">
          <Text fontSize={21} color={BLACK} fontWeight={600}>
            Feekspots
          </Text>
          <ButtonPrimary
            className="profile-modal-button"
            fontSize={16}
            height={48}
            label="Mis favoritos"
            textColor={WHITE}
            color={GRAY_DARK}
            icon={
              <Icon icon={HeartBicolor} color={WHITE} width={14} />
            }
            iconPosition="right"
            onClick={() => setCurrentModal('ModalFavouriteFeekspots')}
          />
        </div>

        <div className="profile-section-wrapper">
          <Text fontSize={21} color={BLACK} fontWeight={600}>
            Últimas 3 visitas
          </Text>
          {lastVisitedFeekspots.length > 0 ? (
            <ul className="last-visited">
              {lastVisitedFeekspots.map(
                (
                  {
                    _id,
                    name,
                    image,
                    distance,
                    instagram_username,
                    location: { city, state },
                  },
                  i,
                ) => (
                  <li key={_id + i}>
                    <CardFeekspotSmall
                      instagramUsername={instagram_username}
                      className="card-feekspot-small"
                      city={city}
                      state={state}
                      name={name}
                      image={image}
                      distance={distance}
                    />
                  </li>
                ),
              )}
            </ul>
          ) : null}
        </div>
      </article>
    </main>
  );
}

export default ProfileFeeker;
