/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import Text from 'components/Text';
import { WHITE } from 'styles/colors';
import handleError from 'utils/handleError';
import { rolesSelectValues } from 'constants';
import { updateFeekspotCollaborator } from 'redux/entities';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { FormInputSelect } from 'components/inputs/InputSelect';

const styles = css`
  display: flex;
  flex-direction: column;
  width: 30rem;
  height: auto;
  background: ${WHITE};
  border-radius: 8px;
  padding: 30px;

  .title {
    margin-bottom: 21px;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .label {
    margin-bottom: 5px;
  }
`;

function ModalEditCollaborator({ handleClose, collaborator }) {
  const dispatch = useDispatch();
  const selectedFeekspotId = useSelector(
    (state) => state.managerApp.selectedFeekspotId,
  );

  const formInitialValues = {
    role: collaborator?.role || '',
  };

  const handleClick = (event) => {
    event.stopPropagation();
  };

  const handleFormSubmit = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);

    try {
      const response = await APIClient.updateCollaborator({
        ...values,
        collaborator_id: collaborator._id,
        customer_id: selectedFeekspotId,
      });
      if (response.status === 200) {
        dispatch(
          updateFeekspotCollaborator({
            collaborator: response.data,
            feekspotId: selectedFeekspotId,
          }),
        );
        actions.setSubmitting(false);
        handleClose();
      }
    } catch (error) {
      actions.setSubmitting(false);
      handleError(error);
    }
  };

  return (
    <div css={styles} onClick={handleClick}>
      <Text
        className="title"
        fontSize={24}
        fontWeight="bold"
        align="center"
      >
        Editar datos de colaborador
      </Text>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={formInitialValues}
      >
        {(formik) => {
          return (
            <Form className="form">
              <Text className="label" fontSize={14}>
                Rol
              </Text>
              <FormInputSelect
                name="role"
                options={rolesSelectValues}
              />
              <div className="buttonsContainer">
                <ButtonPrimary
                  label="Cancelar"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                  }}
                  strokeVariant
                />
                <ButtonPrimary
                  label="Actualizar"
                  onClick={formik.submitForm}
                  disabled={formik.isSubmitting || !formik.dirty}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

ModalEditCollaborator.propTypes = {
  handleClose: PropTypes.func,
  collaborator: PropTypes.object,
};

export default ModalEditCollaborator;
