/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  WHITE,
  BLACK,
  GRAY_MID,
  GRAY_COLD,
  PURPLE_FEEK,
  PURPLE_LIGHT,
  PURPLE_MEDIUM,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Avatar from 'components/Avatar';
import handleError from 'utils/handleError';

const styles = css`
  .empty-card {
    padding: 32px;
    border-radius: 16px;
    border: 1px solid ${GRAY_MID};
  }
  .score-item {
    padding: 24px;
    display: flex;
    position: relative;
    align-items: center;
    border-radius: 16px;
    margin-bottom: 10px;
    justify-content: space-between;
    background-color: ${GRAY_COLD};
  }

  .score-item.active {
    border: 2px solid ${PURPLE_FEEK};
    background-color: ${PURPLE_LIGHT};
  }

  .score-item.active-white-shadow {
    background: radial-gradient(
          circle at 100% 100%,
          #efeeff 0,
          #efeeff 14px,
          transparent 14px
        )
        0% 0%/16px 16px no-repeat,
      radial-gradient(
          circle at 0 100%,
          #efeeff 0,
          #efeeff 14px,
          transparent 14px
        )
        100% 0%/16px 16px no-repeat,
      radial-gradient(
          circle at 100% 0,
          #efeeff 0,
          #efeeff 14px,
          transparent 14px
        )
        0% 100%/16px 16px no-repeat,
      radial-gradient(
          circle at 0 0,
          #efeeff 0,
          #efeeff 14px,
          transparent 14px
        )
        100% 100%/16px 16px no-repeat,
      linear-gradient(#efeeff, #efeeff) 50% 50% / calc(100% - 4px)
        calc(100% - 32px) no-repeat,
      linear-gradient(#efeeff, #efeeff) 50% 50% / calc(100% - 32px)
        calc(100% - 4px) no-repeat,
      linear-gradient(0deg, transparent 0%, #5956f2 100%);
  }

  .image-container {
    width: 56px;
    height: 56px;
    margin: 0 12px;
    display: flex;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    background-color: ${PURPLE_LIGHT};
    border: 2px solid ${PURPLE_MEDIUM};
  }

  .image {
    border-radius: 10px;
  }

  .white-shadow {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${WHITE};
    background: linear-gradient(
      0deg,
      ${WHITE} 0%,
      rgba(255, 255, 255, 0.27) 80%
    );
  }
`;

export default function Scoreboard({ experienceId, showAll }) {
  const [scoreboard, setScoreboard] = useState([]);

  const { instagramUserName } = useSelector((state) => state.feekApp);

  const { participations } = useSelector((state) => state.entities);

  useEffect(() => {
    APIClient.getExperienceScoreboard(experienceId)
      .then(({ data }) => setScoreboard(data))
      .catch(handleError);
  }, [experienceId, participations]);

  const dataToRender = showAll ? scoreboard : scoreboard.slice(0, 3);

  return (
    <div css={styles}>
      {dataToRender.length === 0 && (
        <div className="empty-card">
          <Text
            align="center"
            fontSize={16}
            lineHeight={20}
            fontWeight={500}
          >
            😳
            <br /> Por el momento ningún <b>Feeker</b>
            <br /> está participando por esta Recompensa.
            <br /> ¡Sé el primero!
          </Text>
        </div>
      )}
      {dataToRender.map((scoreboardItem, index) => {
        const isCurrentUser =
          instagramUserName === scoreboardItem.username;
        return (
          <div
            key={scoreboardItem._id}
            className={`score-item ${
              isCurrentUser &&
              (dataToRender.length === index + 1 && !showAll
                ? 'active-white-shadow'
                : 'active')
            }`}
          >
            <div className="d-flex align-items-center">
              <Text color={BLACK} fontSize={21} fontWeight={700}>
                {scoreboardItem.statistics.ranking}º
              </Text>
              <div className="image-container">
                <Avatar
                  size={48}
                  className="image"
                  image={scoreboardItem.avatar}
                />
              </div>
              <Text color={BLACK} fontSize={16}>
                @{scoreboardItem.username}
              </Text>
            </div>
            <div>
              <Text
                color={BLACK}
                fontSize={21}
                align="center"
                fontWeight={700}
              >
                {scoreboardItem.statistics.participations}
              </Text>
              <Text color={BLACK} fontSize={14} align="center">
                {scoreboardItem.statistics.participations === 1
                  ? 'Promoción'
                  : 'Promociones'}
              </Text>
            </div>
            {!showAll && dataToRender.length === index + 1 && (
              <div className="white-shadow"></div>
            )}
          </div>
        );
      })}
    </div>
  );
}

Scoreboard.propTypes = {
  showAll: PropTypes.bool,
  experienceId: PropTypes.string.isRequired,
};
