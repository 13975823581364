/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import {
  PURPLE_FEEK,
  GRAY_LIGHTER,
  GRAY_LIGHTEST,
} from 'styles/colors';
import Text from 'components/Text';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import ModalEditUser from 'components/legacy/modals/ModalEditUser';

const styles = css`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  width: 100%;
  height: 100%;
  border: 1px solid ${GRAY_LIGHTER};
  border-radius: 8px;
  background: ${GRAY_LIGHTEST};

  .headerTitle {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    .headerTitleEditText {
      font-family: 'Roobert';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;
      text-decoration-line: underline;
      color: ${PURPLE_FEEK};
    }
  }

  .dataWrapper {
    display: grid;
    grid: auto auto / max-content minmax(0, 100%) max-content minmax(
        0,
        100%
      );
    gap: 20px 30px;

    > * {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    padding: 24px 20px;

    .headerTitleText {
      font-size: 18px;
    }

    .dataWrapper {
      grid: repeat(8, auto) / 1fr;
      gap: 5px;

      & :nth-of-type(even):not(:last-of-type) {
        padding-block-end: 15px;
      }
    }
  }
`;

function AccountData() {
  const { email, name, phone, id } = useSelector(
    (state) => state.managerApp,
  );
  const [isModalVisble, setIsModalVisble] = useState(false);

  const handleModal = () => {
    setIsModalVisble((crr) => !crr);
  };

  return (
    <>
      <ModalEditUser
        visible={isModalVisble}
        onRequestClose={handleModal}
        customerData={{
          email: email,
          name: name,
          phone: phone,
          id: id,
        }}
      />

      <div css={styles}>
        <div className="headerTitle">
          <Text
            fontSize={20}
            fontWeight="600"
            lineHeight={24}
            className="headerTitleText"
          >
            Datos de cuenta
          </Text>
          <Button
            className="headerTitleEditText"
            onClick={handleModal}
          >
            Editar
          </Button>
        </div>

        <div className="dataWrapper">
          <Text
            fontWeight={600}
            fontSize={16}
            className="accountDataTitle"
          >
            Nombre
          </Text>
          <Text fontSize={16} className="descriptionDataContainer">
            {name}
          </Text>

          <Text
            fontWeight={600}
            fontSize={16}
            className="accountDataTitle"
          >
            Teléfono
          </Text>
          <Text fontSize={16} className="descriptionDataContainer">
            {phone}
          </Text>

          <Text
            fontWeight={600}
            fontSize={16}
            className="accountDataTitle"
          >
            Correo
          </Text>
          <Text fontSize={16} className="descriptionDataContainer">
            {email}
          </Text>

          <Text
            fontWeight={600}
            fontSize={16}
            className="accountDataTitle"
          >
            Contraseña
          </Text>
          <Text fontSize={16} className="descriptionDataContainer">
            ●●●●●●●●●
          </Text>
        </div>
      </div>
    </>
  );
}

export default AccountData;
