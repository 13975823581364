/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  setCampaigns,
  setCategories,
  setCampaignTypes,
} from 'redux/entities';
import APIClient from 'redux/api';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_FEEKAPP } from 'constants/routes';
import NoDataMessage from 'components/NoDataMessage';
import { campaignCategoriesEmojies } from 'constants';
import CardCampaign from 'components/cards/CardCampaign';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import CardCampaignSmall from 'components/cards/CardCampaignSmall';
import { BLACK, GRAY, GRAY_MID, PURPLE_MEDIUM } from 'styles/colors';

const styles = css`
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 70px;
  margin-top: 20px;
  min-height: 70vh;

  .border-difuse {
    position: relative;
    overflow: hidden;
  }

  .border-difuse:before,
  .border-difuse:after {
    content: '';
    width: 50px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.7) 25%,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    top: 0;
    bottom: 0;
    position: absolute;
  }

  .border-difuse:after {
    right: 0;
    opacity: 1;
  }

  .categories-container {
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    gap: 10px;
    margin-bottom: 24px;
  }

  .categories-container::-webkit-scrollbar {
    display: none;
  }

  .category-badge {
    gap: 5px;
    display: flex;
    cursor: pointer;
    padding: 11px 30px;
    width: fit-content;
    align-items: center;
    border-radius: 50px;
    border: 1px solid ${GRAY_MID};
    justify-content: space-between;
  }

  .category-badge.active {
    border: 2px solid ${PURPLE_MEDIUM};
  }

  .no-data {
    margin: 150px auto;
    border: 1px solid ${PURPLE_MEDIUM};
    width: 459px;
    padding: 20px;
    border-radius: 16px;
  }

  .cirle-button {
    margin: 70px auto 40px auto;
    height: 60px;
  }

  .campaign-card-img {
    object-fit: cover;
    width: 100%;
  }

  .hide-on-desktop {
    display: none;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  ${MEDIA_QUERIES.mobile} {
    padding: 0;
    margin-top: 0;

    .mb-16 {
      margin-bottom: 16px;
    }
    .categories-container {
      padding: 16px;
      border-bottom: 1px solid ${GRAY_MID};
    }

    .category-badge {
      padding: 11px 16px;
    }

    .promotions-container {
      padding: 0 16px;
    }

    .hide-on-desktop {
      display: block;
    }

    .hide-on-mobile {
      display: none !important;
    }

    .cirle-button {
      width: fit-content;
      padding: 0 40px;
      margin: 16px auto 40px auto;
    }
  }
`;

export default function SeachByCategory() {
  const [loading, setLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [paginationData, setPaginationData] = useState([]);

  const lastPage = paginationData[paginationData.length - 1];

  const { category } = useParams();
  const navigate = useNavigate();

  const { categories } = useSelector((state) => state.entities);
  const dispatch = useDispatch();

  document.title = [currentCategory?.name, 'Categorias', 'Feek']
    .filter(Boolean)
    .join(' | ');

  useEffect(() => {
    if (!category)
      navigate(ROUTES_FEEKAPP.CATEGORIES + '/restaurant');
    setCurrentCategory(
      Object.values(categories).find((categoryItem) => {
        return categoryItem.key === category;
      }),
    );
  }, [category]);

  useEffect(() => {
    APIClient.getCategories()
      .then(({ data }) => {
        dispatch(setCategories(data));
        setCurrentCategory(
          data.find((categoryItem) => {
            return (
              categoryItem.key.toLowerCase() ===
              category.toLowerCase()
            );
          }),
        );
      })
      .catch(handleError);

    APIClient.getCampaignTypes().then((res) => {
      dispatch(setCampaignTypes(res.data));
    });
  }, []);

  useEffect(() => {
    if (!currentCategory) return;
    getCampaigns(currentCategory?._id);
  }, [currentCategory]);

  const getCampaigns = (category) => {
    setLoading(true);
    APIClient.getCampaignsByCategory({
      categories: [category || ''],
    })
      .then(({ data }) => {
        setPaginationData([data]);
        dispatch(setCampaigns(data.docs.map((doc) => doc.campaign)));
      })
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  const fetchMore = () => {
    setLoading(true);
    APIClient.getCampaignsByCategory({
      categories: [currentCategory._id || ''],
      page: lastPage.nextPage,
      limit: lastPage.limit,
    })
      .then(({ data }) => {
        setPaginationData([...paginationData, data]);
        dispatch(setCampaigns(data.docs.map((doc) => doc.campaign)));
      })
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  const campaigns = useMemo(
    () =>
      paginationData?.reduce(
        (prev, crr) => [...prev, ...crr.docs],
        [],
      ),
    [paginationData],
  );

  return (
    <main css={styles}>
      <div className="categories-container d-xxl-flex justify-content-xxl-center">
        {Object.values(categories).map((category) => (
          <div
            onClick={() =>
              navigate(ROUTES_FEEKAPP.CATEGORIES + '/' + category.key)
            }
            key={category._id}
            className={`category-badge ${
              category._id === currentCategory?._id ? 'active' : ''
            }`}
          >
            <Text
              color={BLACK}
              fontSize={30}
              fontWeight={600}
              fontSizeMobile={16}
            >
              {campaignCategoriesEmojies[category.key] || '✨'}{' '}
            </Text>
            <Text color={BLACK} fontSize={16} fontWeight={600}>
              {category.name}
            </Text>
          </div>
        ))}
      </div>
      <div className="row promotions-container">
        <Text
          fontSize={24}
          fontWeight={600}
          color={BLACK}
          className="mb-16 hide-on-desktop"
        >
          Promociones
        </Text>
        {campaigns.map((campaign, i) => (
          <>
            <CardCampaign
              campaignId={campaign._id}
              key={campaign._id + i}
              className="col-xxl-3 col-xl-4 col-md-6 mb-4"
              image={campaign.campaign.image}
              places={campaign.campaign.places}
              title={campaign.campaign.title}
              type={campaign.campaign.type}
              colorVariant={GRAY}
              feekspot={campaign?.customer?.name}
              feekpoints={campaign.campaign?.task?.feek_points}
            />
            <CardCampaignSmall
              className="hide-on-desktop"
              campaign={campaign._id}
              feekspotName={campaign.customer.name}
            />
          </>
        ))}

        {campaigns.length === 0 && !loading && (
          <NoDataMessage
            message={
              <Text
                color={BLACK}
                fontSize={20}
                align="center"
                fontSizeMobile={16}
              >
                No hay resultados en la categoría{' '}
                <b>
                  {`"`}
                  {currentCategory?.name.trim()}
                  {`"`}
                </b>
                , intenta en otras categorías
              </Text>
            }
            emoji="😮"
          />
        )}
      </div>
      {lastPage?.hasNextPage && (
        <ButtonPrimary
          pillShape
          className="cirle-button"
          label="Mostrar más negocios"
          color={BLACK}
          onClick={fetchMore}
        />
      )}
    </main>
  );
}
