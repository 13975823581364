/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useContext, useEffect, useMemo } from 'react';

import {
  BLACK,
  WHITE,
  GRAY_COLD,
  RED_NOTIFICATIONS,
} from 'styles/colors';
import Icon from 'components/Icon';
import Text from 'components/Text';
import Modal from 'components/modals/Modal';
import { ReactComponent as X } from 'assets/icons/X.svg';
import { HeaderContext } from 'screens/feekApp/OutletFeekapp';

const styles = css`
  inline-size: 400px;
  padding: 12px 28px 24px;

  .strikes-visualization {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    gap: 7px;
    padding: 32px 54px;
    margin: 20px auto 0;
    width: 344px;
    min-height: 214px;
    background-color: ${GRAY_COLD};
    border-radius: 16px;

    .strikes-count {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin-block-start: 13px;

      .red-cross-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        padding: 4px;
        border-radius: 8px;
        gap: 10px;
        background-color: ${WHITE};

        .red-cross {
          padding: 8px;
          background-color: ${RED_NOTIFICATIONS};
          border-radius: 4px;
        }
      }
    }
  }

  .warning {
    margin-block-start: 20px;

    > div:not(:first-of-type) {
      margin-block-start: 10px;
    }
  }
`;

function ModalStrikesCount({
  message,
  visible,
  noStrikesData,
  onRequestClose,
  strikesCount = 0,
  title = 'Mis strikes',
  ...restProps
}) {
  const setHeaderResponsiveAction = useContext(HeaderContext);

  useEffect(() => {
    if (visible)
      setHeaderResponsiveAction(() => () => {
        setHeaderResponsiveAction(null);
        onRequestClose?.();
      });
  }, [visible]);

  const strikesData = noStrikesData
    ? null
    : useMemo(() => {
        const strikesCountCorrected =
          strikesCount < 0 ? 0 : strikesCount;

        return (
          <article
            role="presentation"
            className="strikes-visualization"
          >
            <Text fontSize={20} className="emoji-font">
              {
                { 0: '🥳', 1: '😮', 2: '🥲', 3: '😭' }[
                  strikesCountCorrected
                ]
              }
            </Text>
            <Text fontSize={14} color={BLACK}>
              Has cancelado{' '}
              {strikesCountCorrected +
                ' ' +
                (strikesCountCorrected === 1
                  ? 'Promoción.'
                  : ' Promociones.')}
            </Text>
            {strikesCountCorrected === 2 ? (
              <Text fontSize={14} color={BLACK}>
                Te queda <strong>1 Strike.</strong>
              </Text>
            ) : (
              <Text fontSize={14} color={BLACK}>
                Te quedan{' '}
                <strong>{3 - strikesCountCorrected} Strikes.</strong>
              </Text>
            )}

            <div className="strikes-count">
              {[...Array(3)].map((e, i) => (
                <div key={i} className="red-cross-wrapper">
                  {i < strikesCountCorrected && (
                    <Icon
                      icon={X}
                      color={WHITE}
                      size={28}
                      className="red-cross"
                      key={Math.random()}
                    />
                  )}
                </div>
              ))}
            </div>
          </article>
        );
      }, [strikesCount, noStrikesData]);

  return (
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      responsiveBehindHeaderFooter
      responsiveFullscreen
      withoutCloseButton
      {...restProps}
    >
      <div className="container" css={styles}>
        <Text
          className="title"
          fontSize={20}
          fontWeight="600"
          color={BLACK}
        >
          {title}
        </Text>

        {strikesData}

        <section className="warning">
          {message || (
            <>
              <Text fontSize={16} fontWeight="700" color={BLACK}>
                <span className="emoji-font">⚠️</span> Recuerda
              </Text>
              <Text fontSize={16} lineHeight={24} color={BLACK}>
                Puedes cancelar <u>4 horas antes</u> de tu fecha y
                hora agendada sin recibir un <strong>Strike</strong>,
                de lo contrario tendrás uno.
              </Text>
              <Text fontSize={16} lineHeight={24} color={BLACK}>
                Si no asistes a tu lugar agendado en un plazo de 48
                horas, también recibirás un <strong>Strike.</strong>
              </Text>
            </>
          )}
        </section>
      </div>
    </Modal>
  );
}

ModalStrikesCount.propTypes = {
  ...Modal.propTypes,
  title: PropTypes.string,
  noStrikesData: PropTypes.bool,
  strikesCount: PropTypes.number,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default ModalStrikesCount;
