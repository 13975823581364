/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import Text from 'components/Text';
import Icon from 'components/Icon';
import { Link } from 'react-router-dom';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { campaignTypeMiniIconsById } from 'constants';
import { BLACK, GRAY_DARK, GRAY_LIGHTEST } from 'styles/colors';
import ImagePlaceholder from 'assets/images/ImagePlaceholder.jpg';
import { ReactComponent as ZapMini } from 'assets/icons/ZapMini.svg';

const styles = css`
  padding: 10px;
  border-radius: 15px;
  border: 1px solid ${GRAY_LIGHTEST};

  .text-no-decoration {
    text-decoration: none;
  }

  img {
    width: 90px;
    height: 100px;
    margin-right: 10px;
    border-radius: 10px;
  }
  .places-badge {
    padding: 2px 6px;
    width: fit-content;
    border-radius: 16px;
    border: 2px solid ${BLACK};
  }

  .title {
    text-overflow: ellipsis;
  }
  .campaign-info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .mr-4 {
    margin-right: 4px;
  }

  .mr-8 {
    margin-right: 8px;
  }

  ${MEDIA_QUERIES.mobile} {
    .places-badge {
      border: 1px solid ${BLACK};
    }
  }
`;

export default function CardCampaignSmall({
  campaign,
  className,
  feekspotName,
  colorVariant,
}) {
  const { campaigns, campaignTypes } = useSelector(
    (state) => state.entities,
  );

  const selectedCampaign = campaigns[campaign];

  return (
    <Link to={`/campaign/${campaign}`} className="text-no-decoration">
      <Button css={styles} className={className} layoutOnly>
        <div className="d-flex">
          <img
            src={selectedCampaign?.image || ImagePlaceholder}
            alt={selectedCampaign?.title}
            className="img-fluid"
          />
          <div className="campaign-info-container">
            <div className="d-flex justify-content-between">
              <Text fontSize={16} color={BLACK} fontWeight={600}>
                {feekspotName}
              </Text>
              <Text
                fontSize={14}
                color={BLACK}
                fontWeight={600}
                fontSizeMobile={12}
                className="places-badge"
              >
                {selectedCampaign?.places}{' '}
                {selectedCampaign?.places > 1 ? 'lugares' : 'lugar'}
              </Text>
            </div>
            <Text
              fontSize={16}
              fontWeight={600}
              color={BLACK}
              fontSizeMobile={14}
              className="title"
            >
              {selectedCampaign?.title}
            </Text>
            <div className="d-flex">
              <Icon
                icon={
                  selectedCampaign?.type in campaignTypeMiniIconsById
                    ? campaignTypeMiniIconsById[
                        selectedCampaign?.type
                      ]
                    : ZapMini
                }
                color={colorVariant || GRAY_DARK}
                size={16}
                sizeMobile={14}
                className="mr-4"
              />
              <Text
                fontSize={14}
                fontWeight={600}
                className="mr-8"
                color={colorVariant || GRAY_DARK}
              >
                {campaignTypes[selectedCampaign?.type]?.name}
              </Text>
              <Icon
                size={16}
                icon={ZapMini}
                className="mr-4"
                color={colorVariant || GRAY_DARK}
              />
              <Text
                color={colorVariant || GRAY_DARK}
                fontSize={14}
                fontWeight={600}
              >
                +{selectedCampaign?.task?.feek_points}
              </Text>
            </div>
          </div>
        </div>
      </Button>
    </Link>
  );
}

CardCampaignSmall.propTypes = {
  className: PropTypes.string,
  colorVariant: PropTypes.string,
  feekspotName: PropTypes.string,
  campaign: PropTypes.string.isRequired,
};
