/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';

import {
  BLACK,
  GRAY,
  WHITE,
  PURPLE_FEEK,
  PURPLE_LIGHT,
  GRAY_LIGHTEST,
  PURPLE_MEDIUM,
} from 'styles/colors';
import {
  setCampaigns,
  setFeekspots,
  setCategories,
} from 'redux/entities';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import { titlesFeekApp } from 'constants';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { campaignCategoriesEmojies } from 'constants';
import { ReactComponent as X } from 'assets/icons/X.svg';
import { hideFooter, showFooter } from 'redux/app/feekApp';
import DistanceIndicator from 'components/DistanceIndicator';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as Dots } from 'assets/icons/Dots.svg';
import ImagePlaceholder from 'assets/images/ImagePlaceholder.jpg';
import CardCampaignSmall from 'components/cards/CardCampaignSmall';

const styles = css`
  width: 100%;
  height: calc(100vh - 95px);

  .map {
    width: 100%;
    height: calc(100vh - 95px);
    position: relative;
  }
  .cursor-pointer {
    cursor: pointer;
    user-select: none;
  }

  .categories-container {
    position: absolute;
    top: 24px;
    left: 70px;
    display: flex;
    gap: 10px;
  }

  .feekspot-marker {
    background-color: ${WHITE};
    border-radius: 16px;
    width: fit-content;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    user-select: none;
  }

  .results-container {
    height: 100%;
    left: 0;
    top: 0;
    background-color: ${WHITE};
    padding: 40px 24px;
    overflow-y: auto;
  }

  .feekspot-info {
    padding-left: 10px;
    margin-bottom: 20px;
  }

  .feekspot-category-badge {
    border-radius: 16px;
    background-color: ${PURPLE_LIGHT};
    width: fit-content;
    padding: 10px;
    cursor: pointer;
  }

  .campaign-card {
    margin-bottom: 15px;
  }

  .text-no-decoration {
    text-decoration: none;
  }

  .campaign-img {
    margin-right: 10px;
    aspect-ratio: 8/9;
    border-radius: 10px;
  }
  .category-badge {
    padding: 9px 20px;
    width: fit-content;
    border-radius: 30px;
    background-color: ${WHITE};
    border: 2px solid ${GRAY_LIGHTEST};
  }

  .category-badge.selected {
    border: 2px solid ${PURPLE_MEDIUM};
  }
  .title {
    text-overflow: ellipsis;
    text-transform: uppercase;
  }

  .show-results-button {
    padding: 10px 20px;
    width: fit-content;
    border-radius: 30px;
    position: absolute;
    bottom: 40px;
    left: calc(50% - 100px);
  }

  .cluster-marker {
    background: ${PURPLE_FEEK};
    border-radius: 50%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 9px solid ${PURPLE_MEDIUM};
  }
`;

const ClusterMarker = ({ size, pointCount, onClick }) => {
  return (
    <div
      className="cluster-marker"
      style={{
        width: size,
        height: size,
      }}
      onClick={onClick}
    >
      <Text fontSize={18} fontWeight={700} color={WHITE}>
        {pointCount}
      </Text>
    </div>
  );
};

const Marker = ({ categoryKey, campaignsCount, onClick }) => {
  return (
    <Button className="feekspot-marker" onClick={onClick}>
      <Text fontSize={16} fontWeight={700}>
        {campaignsCount}
      </Text>
      <Text fontSize={16}>
        {campaignCategoriesEmojies[categoryKey]}
      </Text>
    </Button>
  );
};

export default function Map() {
  const [zoom, setZoom] = useState(11);
  const [bounds, setBounds] = useState(null);
  const [clusterBounds, setClusterBounds] = useState(null);
  const [campaignResults, setCampaignResults] = useState([]);
  const [feekspotSelected, setFeekspotSelected] = useState(null);
  const [categoriesSelected, setCategoriesSelected] = useState([]);

  const { feekspots, categories } = useSelector(
    (state) => state.entities,
  );

  const lastSelectedLocation = JSON.parse(
    localStorage.getItem('last_location'),
  );
  const dispatch = useDispatch();

  const mapRef = useRef(null);

  document.title = titlesFeekApp.Map;

  const defaultProps = useMemo(() => {
    return {
      center: {
        lat: lastSelectedLocation
          ? lastSelectedLocation?.coordinates?.[1]
          : 25.67507,
        lng: lastSelectedLocation
          ? lastSelectedLocation?.coordinates?.[0]
          : -100.31847,
      },
      zoom: 11,
    };
  }, [lastSelectedLocation]);

  useEffect(() => {
    dispatch(hideFooter());
    APIClient.getCategories()
      .then(({ data }) => {
        dispatch(setCategories(data));
      })
      .catch(handleError);

    return () => dispatch(showFooter());
  }, []);

  useEffect(() => {
    if (bounds) getCampaigns();
  }, [categoriesSelected, bounds, zoom]);

  const campaignResultClick = (campaignResult) => {
    APIClient.getManyCampaigns(campaignResult.campaigns).then(
      ({ data }) => {
        dispatch(setCampaigns(data));
      },
    );

    APIClient.getFeekspotForFeeker(campaignResult.customer._id).then(
      ({ data }) => {
        dispatch(setFeekspots([{ customer: data }]));
      },
    );
    setFeekspotSelected(campaignResult);
  };

  const getCampaigns = () => {
    APIClient.getCampaignsByLocation(
      {
        northEast: {
          latitude: bounds.northEast.lat(),
          longitude: bounds.northEast.lng(),
        },
        southWest: {
          latitude: bounds.southWest.lat(),
          longitude: bounds.southWest.lng(),
        },
      },
      categoriesSelected,
    )
      .then((response) => {
        setCampaignResults(response.data);
      })
      .catch(handleError);
  };

  const points = useMemo(() => {
    return campaignResults.map((campaignResult) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        feekspotCampaign: campaignResult,
      },
      geometry: {
        type: 'Point',
        coordinates: [
          parseFloat(campaignResult.customer.location.coordinates[0]),
          parseFloat(campaignResult.customer.location.coordinates[1]),
        ],
      },
    }));
  }, [campaignResults]);

  const setBoundaries = (map) => {
    setBounds({
      northEast: map.getBounds().getNorthEast(),
      southWest: map.getBounds().getSouthWest(),
    });
    setZoom(map.getZoom());
  };

  const { clusters, supercluster } = useSupercluster({
    points: campaignResults.map((campaignResult) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        feekspotCampaign: campaignResult,
      },
      geometry: {
        type: 'Point',
        coordinates: [
          parseFloat(campaignResult.customer.location.coordinates[0]),
          parseFloat(campaignResult.customer.location.coordinates[1]),
        ],
      },
    })),
    bounds: clusterBounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  return (
    <div css={styles}>
      <div className="row">
        {feekspotSelected && (
          <div className="results-container col-xxl-3 col-md-4">
            <div className="d-flex justify-content-end">
              <Button
                className="cursor-pointer"
                onClick={() => {
                  setFeekspotSelected(null);
                }}
              >
                <Icon icon={X} />
              </Button>
            </div>
            <div className="feekspot-info">
              <div className="d-flex">
                <Avatar
                  size={90}
                  image={
                    feekspotSelected?.customer?.image ||
                    ImagePlaceholder
                  }
                  className="campaign-img img-fluid"
                />
                <div className="d-flex flex-column justify-content-center">
                  <Text
                    fontSize={18}
                    fontWeight={600}
                    color={BLACK}
                    className="title mb-1"
                  >
                    {feekspotSelected?.customer?.name}
                  </Text>
                  <Text
                    fontSize={12}
                    fontWeight={500}
                    color={GRAY}
                    className="mb-1"
                  >
                    {[
                      feekspotSelected?.customer?.location?.city,
                      feekspotSelected?.customer?.location?.state,
                    ]
                      .filter((item) => item)
                      .join(', ')}
                  </Text>
                  <div>
                    <DistanceIndicator
                      distance={
                        feekspots[feekspotSelected?.customer?._id]
                          ?.customer?.distance
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <Text
              fontSize={14}
              fontWeight={500}
              color={GRAY}
              className="feekspot-category-badge mb-4"
            >
              {
                categories[
                  feekspotSelected?.customer?.categories?.[0]
                ]?.name
              }
            </Text>

            {feekspotSelected?.campaigns?.map((campaign, i) => (
              <CardCampaignSmall
                key={campaign + i}
                className="campaign-card"
                campaign={campaign}
                colorVariant={GRAY}
              />
            ))}
          </div>
        )}
        <div
          className={`${
            feekspotSelected ? 'col-xxl-9 col-md-8' : 'col-12'
          }`}
        >
          <div className="map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              yesIWantToUseGoogleMapApiInternals
              onDragEnd={setBoundaries}
              onGoogleApiLoaded={({ map }) => {
                mapRef.current = map;
                setBoundaries(map);
              }}
              onZoomAnimationEnd={() => {
                setBoundaries(mapRef.current);
              }}
              onChange={({ zoom, bounds }) => {
                setZoom(zoom);
                setClusterBounds([
                  bounds.nw.lng,
                  bounds.se.lat,
                  bounds.se.lng,
                  bounds.nw.lat,
                ]);
              }}
            >
              {clusters.map((cluster, i) => {
                const [longitude, latitude] =
                  cluster.geometry.coordinates;
                const {
                  cluster: isCluster,
                  point_count: pointCount,
                  feekspotCampaign,
                } = cluster.properties;
                const size = 10 + (pointCount / points.length) * 20;

                return isCluster ? (
                  <ClusterMarker
                    size={size}
                    lat={latitude}
                    lng={longitude}
                    pointCount={pointCount}
                    key={`cluster-${cluster.id}` + i}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(
                          cluster.id,
                        ),
                        20,
                      );
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({
                        lat: latitude,
                        lng: longitude,
                      });
                    }}
                  />
                ) : (
                  <Marker
                    key={feekspotCampaign.customer._id + i}
                    lat={latitude}
                    lng={longitude}
                    categoryKey={
                      categories[
                        feekspotCampaign.customer.categories[0]
                      ]?.key
                    }
                    campaignsCount={feekspotCampaign.campaigns.length}
                    onClick={() =>
                      campaignResultClick(feekspotCampaign)
                    }
                  />
                );
              })}
            </GoogleMapReact>

            <div className="categories-container">
              {Object.values(categories).map((category, i) => (
                <Button
                  className={`category-badge cursor-pointer ${
                    categoriesSelected.includes(category._id) &&
                    'selected'
                  }`}
                  key={category.key + i}
                  onClick={() => {
                    if (categoriesSelected.includes(category._id)) {
                      setCategoriesSelected(
                        categoriesSelected.filter(
                          (item) => item !== category._id,
                        ),
                      );
                    } else {
                      setCategoriesSelected([
                        ...categoriesSelected,
                        category._id,
                      ]);
                    }
                  }}
                >
                  <Text fontSize={16} fontWeight={600} color={BLACK}>
                    {campaignCategoriesEmojies[category.key] ||
                      campaignCategoriesEmojies.DEFAULT}{' '}
                    {category.name}
                  </Text>
                </Button>
              ))}
            </div>

            <Link
              to={`/map/results?northEast=${bounds?.northEast.lat()},${bounds?.northEast.lng()}&southWest=${bounds?.southWest.lat()},${bounds?.southWest.lng()}${
                categoriesSelected?.length
                  ? '&categories=' + categoriesSelected.join()
                  : ''
              }`}
            >
              <ButtonPrimary
                label="Mostrar resultados"
                color={BLACK}
                className="show-results-button"
                icon={<Icon icon={Dots} color={WHITE} />}
                iconPosition="right"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

Marker.propTypes = {
  categoryKey: PropTypes.string.isRequired,
  campaignsCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

ClusterMarker.propTypes = {
  size: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  pointCount: PropTypes.number.isRequired,
};
