/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import { BLACK, WHITE } from 'styles/colors';
import Button from 'components/buttons/Button';
import { setExperiences } from 'redux/entities';
import Modal from 'components/modals/Modal/index';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import CardExperienceSmall from 'components/cards/CardExperienceSmall';

const styles = css`
  width: 500px;
  padding: 24px;
  max-width: 650px;
  background-color: ${WHITE};

  .button-back {
    margin: 0 auto;
    width: fit-content;
  }

  .action-button {
    width: 343px;
    margin: 30px auto;
  }
`;

export default function ModalExperiencesSuggest({
  visible,
  campaignId,
  onRequestClose,
}) {
  const [experienceSelected, setExperienceSelected] = useState(null);

  const { experiences } = useSelector((state) => state.entities);

  const { lastMomentCompletedId } = useSelector(
    (state) => state.feekApp,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    APIClient.getExperiencesLanding()
      .then(({ data }) => {
        dispatch(setExperiences(data.docs));
      })
      .catch(handleError);
  }, []);

  const bookExperience = () => {
    APIClient.bookExperience(
      experienceSelected,
      true,
      lastMomentCompletedId,
      campaignId,
    )
      .then(({ data }) => {
        dispatch(
          setExperiences({
            _id: experienceSelected?._id,
            subscribed: data._id,
          }),
        );
        setExperienceSelected(null);
        onRequestClose();
      })
      .catch(handleError);
  };
  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <div css={styles}>
        {!experienceSelected ? (
          <>
            <Text
              fontSize={21}
              color={BLACK}
              align="center"
              fontWeight={600}
              className="mb-3"
            >
              No pierdas la oportunidad de participar y ganar
              recompensas
            </Text>
            <Text
              color={BLACK}
              fontSize={16}
              align="center"
              className="mb-3"
            >
              Selecciona la recompensa que más te guste.
            </Text>

            {experiences &&
              Object.values(experiences)?.map((experience) => (
                <CardExperienceSmall
                  key={experience?._id}
                  image={experience?.image}
                  title={experience?.title}
                  places={experience?.places}
                  eventDate={experience?.event_date}
                  onPress={() =>
                    setExperienceSelected(experience?._id)
                  }
                />
              ))}
          </>
        ) : (
          <>
            <Text
              fontSize={21}
              color={BLACK}
              align="center"
              fontWeight={600}
              className="mb-4"
            >
              Confirmar participación
            </Text>
            <Text
              color={BLACK}
              fontSize={16}
              align="center"
              className="mb-4"
            >
              Comenzará tu participación y se sumará tu promoción
              completada a la sigueinte recompensa:
            </Text>
            <CardExperienceSmall
              image={experiences[experienceSelected]?.image}
              title={experiences[experienceSelected]?.title}
              places={experiences[experienceSelected]?.places}
              ranking={experienceSelected?.statistics?.ranking}
              eventDate={experiences[experienceSelected]?.event_date}
            />
            <ButtonPrimary
              color={BLACK}
              label="Confirmar"
              onClick={bookExperience}
              className="action-button"
              pillShape
            />
            <Button
              className="mt-4 button-back"
              onClick={() => setExperienceSelected(null)}
            >
              <Text color={BLACK} fontSize={16}>
                ← Volver a seleccionar
              </Text>
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
}

ModalExperiencesSuggest.propTypes = {
  visible: PropTypes.bool,
  momentId: PropTypes.string,
  campaignId: PropTypes.string,
  onRequestClose: PropTypes.func,
};
