/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { createPortal } from 'react-dom';
import { useEffect, useRef } from 'react';

import Icon from 'components/Icon';
import Button from 'components/buttons/Button';
import { ReactComponent as X } from 'assets/icons/X.svg';
import { MEDIA_QUERIES, Z_INDEX } from 'styles/constants';
import { BLACK, GRADIENT_PURPLE, WHITE } from 'styles/colors';
import { headerHeight } from 'screens/feekApp/OutletFeekapp/Header';
import { footerHeight } from 'screens/feekApp/OutletFeekapp/Footer';

function Modal({
  visible,
  children,
  className,
  showScrollbar,
  cssProp = null,
  onRequestClose,
  onOverlayClick,
  withoutCloseButton,
  responsiveFullscreen,
  responsiveBehindHeaderFooter,
}) {
  const styles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${Z_INDEX.modal};
    background: ${BLACK}99;

    .modalMain {
      --margin: 40px;
      cursor: initial;
      width: auto;
      height: auto;
      max-width: calc(100vw - calc(var(--margin) * 2));
      max-height: calc(100vh - calc(var(--margin) * 2));
      margin: var(--margin);
      padding: 24px;
      border-radius: 16px;
      background: ${WHITE};
      overflow: hidden auto;

      scrollbar-width: none;
      ::-webkit-scrollbar {
        display: none;
      }

      .closeButton {
        width: max-content;
        margin: 8px 8px 0 auto;
      }

      ${showScrollbar &&
      `
      scrollbar-width: thin;

      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      ::-webkit-scrollbar-thumb {
        background: ${GRADIENT_PURPLE};
        border-radius: 8px;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
        margin: 11px;`}

      &.overflow {
        overflow: unset;
      }
    }

    ${MEDIA_QUERIES.mobile} {
      align-items: end;
      ${responsiveBehindHeaderFooter
        ? `
        height: calc(100vh - ${headerHeight.mobile} - ${footerHeight.mobile});
        margin: ${headerHeight.mobile} 0 ${footerHeight.mobile};
        z-index: ${Z_INDEX.overContent};
        `
        : ''}

      .modalMain {
        --margin: 0;
        min-height: 65%;
        max-width: none;
        width: 100vw;
        margin: auto 0 0;
        padding: 16px;
        border-radius: 16px 16px 0 0;

        ${responsiveFullscreen
          ? `
        --margin: 0;
        height: 100vh;
        border-radius: 0;`
          : ''}

        > :not(.closeButton) {
          width: 100%;
          padding: ${withoutCloseButton ? '0' : '20px 0'};
          max-width: none;
        }
      }
    }
  `;

  const sectionRef = useRef(null);

  useEffect(() => {
    if (!visible) return;
    sectionRef.current.onkeydown = ({ key }) => {
      if (key === 'Escape') onRequestClose?.();
    };
    sectionRef.current.focus();
  }, [visible]);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onOverlayClick?.();
      onRequestClose?.();
    }
  };

  return visible
    ? createPortal(
        <Button
          className={className}
          css={[styles, cssProp]}
          hideHoverActiveStyles
          onClick={handleOverlayClick}
        >
          <section
            ref={sectionRef}
            tabIndex={-1}
            className="modalMain"
          >
            {!withoutCloseButton && (
              <Button
                className="closeButton"
                onClick={onRequestClose}
              >
                <Icon icon={X} size={16} color={BLACK} />
              </Button>
            )}
            {children}
          </section>
        </Button>,
        document.getElementById('outletFeekapp') || document.body,
      )
    : null;
}

export default Modal;

Modal.propTypes = {
  visible: PropTypes.bool,
  className: PropTypes.string,
  cssProp: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
      styles: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        styles: PropTypes.string,
      }),
    ),
  ]),
  showScrollbar: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onOverlayClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  withoutCloseButton: PropTypes.bool,
  responsiveFullscreen: PropTypes.bool,
  responsiveBehindHeaderFooter: PropTypes.bool,
};
