/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import moment from 'moment';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Form, FormikProvider, useFormik } from 'formik';

import {
  GRAY,
  BLACK,
  WHITE,
  GRAY_MID,
  WHITE_ALMOST,
  PURPLE_MEDIUM,
} from 'styles/colors';
import APIClient from 'redux/api';
import { useDispatch } from 'react-redux';
import Modal from 'components/modals/Modal';
import handleError from 'utils/handleError';
import { setFeeker } from 'redux/app/feekApp';
import { availablePronouns } from 'constants';
import Button from 'components/buttons/Button';
import validateImage from 'utils/validateImage';
import { MEDIA_QUERIES } from 'styles/constants';
import { FormInputText } from 'components/inputs/InputText';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import CameraPlusSign from 'assets/icons/CameraPlusSign.svg';
import { FormInputSelect } from 'components/inputs/InputSelect';
import { FormInputTextArea } from 'components/inputs/InputTextArea';

const styles = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  inline-size: min(40vw, 600px);
  padding: 24px;

  .full-row {
    grid-column: 1 / 3;
  }

  .image-input {
    position: relative;
    color: transparent;
    background-color: ${PURPLE_MEDIUM};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition-property: transform;
    transition-timing-function: ease-in-out;
    transition-duration: 250ms;

    :after {
      --size: 48px;
      content: '';
      position: absolute;
      display: block;
      width: 48px;
      aspect-ratio: 1;
      background-color: ${WHITE}7f;
      background-image: url(${CameraPlusSign});
      filter: invert(100%);
      background-size: 22px;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%;
    }

    :hover {
      transform: scale(1.025) translateY(-5px);
    }

    ::file-selector-button {
      visibility: hidden;
    }

    &.background-image {
      width: 100%;
      height: 298px;
      border: 1px solid ${GRAY_MID};
      border-radius: 10px;

      :after {
        top: 16px;
        left: 16px;
      }
    }

    &.avatar {
      justify-self: center;
      margin-block-start: calc(30px - 26%);
      width: 26%;
      aspect-ratio: 1;
      border: 4px solid ${WHITE_ALMOST};
      border-radius: 50%;

      :after {
        top: calc(50% - calc(var(--size) / 2));
        left: calc(50% - calc(var(--size) / 2));
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 46px;

    .cancel-button {
      text-decoration: underline;
      color: ${GRAY};
    }
  }

  ${MEDIA_QUERIES.mobile} {
    * {
      max-width: 80vw;
    }
    .input.username.full-row,
    .input.selector.pronoun {
      grid-column: 1 / 3;
      width: 100%;
    }
  }
`;

const formValidationSchema = Yup.object().shape({
  background_image: Yup.string().notRequired(),
  avatar: Yup.string().notRequired(),
  username: Yup.string().required('¡Necesitas un nombre de usuario!'),
  pronoun: Yup.string()
    .oneOf(
      availablePronouns,
      'Necesitamos que tu pronombre pertenezca a la lista',
    )
    .notRequired(),
  birthdate: Yup.string()
    .notRequired()
    .test('min-date', 'Fecha anterior al 01-01-1901', (value) => {
      if (!value) return true;
      return (
        new Date(value).getTime() > new Date('1900-12-31').getTime()
      );
    })
    .test(
      'max-date',
      'Debes elegir una fecha anterior al día de hoy',
      (value) => {
        if (!value) return true;
        return new Date(value).getTime() < new Date().getTime();
      },
    ),
  bio: Yup.string()
    .max(80, 'La bio solo puede contener 80 caracteres')
    .notRequired(),
});

function ModalEditFeekerPersonalData({
  profileFeekerDataSettings: {
    avatar,
    backgroundImage,
    username,
    pronoun,
    birthdate,
    bio,
    id,
  },
  visible,
  onRequestClose,
  ...restProps
}) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    validateOnMount: false,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    initialValues: {
      background_image: backgroundImage,
      avatar: avatar,
      username: username,
      pronoun: pronoun,
      birthdate: birthdate
        ? moment(birthdate).format('YYYY-MM-DD')
        : '',
      bio: bio,
    },
    validationSchema: formValidationSchema,
  });

  async function handleSubmit() {
    formik?.setSubmitting(true);
    APIClient.updateUser(id, formik.values)
      .then(() =>
        dispatch(
          setFeeker({
            ...formik.values,
            backgroundImage: formik.values.background_image,
          }),
        ),
      )
      .then(() => formik.resetForm({ ...formik.values }))
      .then(() => onRequestClose?.())
      .finally(() => formik?.setSubmitting(false))
      .catch(handleError);
  }

  const handleChangeImage = async (e) => {
    e.stopPropagation();
    setIsLoading(true);
    validateImage(e.target.files[0])
      .then((file) =>
        APIClient.uploadFile({ file: file }).then(
          ({ data: { Location } }) =>
            formik.setFieldValue(e.target.name, Location),
        ),
      )
      .finally(() => setIsLoading(false))
      .catch(handleError);
  };

  function handleClose() {
    formik.resetForm();
    onRequestClose?.();
  }

  return (
    <FormikProvider value={formik}>
      <Modal
        visible={visible}
        onRequestClose={handleClose}
        {...restProps}
      >
        <Form css={styles} id="signInForm" className="signInForm">
          <input
            disabled={isLoading}
            className="image-input background-image full-row"
            css={{
              backgroundImage: `url(${formik?.values?.background_image})`,
            }}
            type="file"
            accept="image/*"
            name="background_image"
            onChange={handleChangeImage}
            onClick={(event) => {
              event.stopPropagation();
            }}
            title={username + ' • Feek'}
          />

          <input
            disabled={isLoading}
            className="image-input avatar full-row"
            css={{
              backgroundImage: `url(${formik?.values?.avatar})`,
            }}
            type="file"
            accept="image/*"
            name="avatar"
            onChange={handleChangeImage}
            onClick={(event) => {
              event.stopPropagation();
            }}
            title={username + ' • Feek'}
          />

          <FormInputText
            label="Usuario"
            className="input username full-row"
            name="username"
            width="100%"
          />

          <FormInputSelect
            label="Pronombre"
            name="pronoun"
            options={availablePronouns}
            className="input selector pronoun"
            placeholder="Seleccionar"
          />

          <FormInputText
            label="Fecha de nacimiento"
            type="date"
            name="birthdate"
            className="birthdate"
            max={moment().format('YYYY-MM-DD')}
            min={'1901-01-01'}
            placeholder="Seleccionar"
          />

          <FormInputTextArea
            className="full-row"
            width="100%"
            name="bio"
            label="Bio"
          />

          <div className="full-row buttons">
            <Button onClick={handleClose} className="cancel-button">
              Cancelar
            </Button>
            <ButtonPrimary
              disabled={
                !formik.dirty ||
                !formik.isValid ||
                formik.isSubmitting ||
                isLoading
              }
              pillShape
              onClick={formik.submitForm}
              label="Guardar cambios"
              fontSize={16}
              textColor={WHITE}
              color={BLACK}
              className="submitSignInFormButton"
            />
          </div>
        </Form>
      </Modal>
    </FormikProvider>
  );
}

ModalEditFeekerPersonalData.propTypes = {
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func,
  profileFeekerDataSettings: PropTypes.shape({
    avatar: PropTypes.string,
    backgroundImage: PropTypes.string,
    username: PropTypes.string,
    pronoun: PropTypes.string,
    birthdate: PropTypes.string,
    bio: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default ModalEditFeekerPersonalData;
