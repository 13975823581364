/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import APIClient from 'redux/api';
import Text from 'components/Text';
import { BLACK } from 'styles/colors';
import { authSteps } from 'constants';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_FEEKAPP } from 'constants/routes';
import ButtonBack from 'components/buttons/ButtonBack';
import { FormInputText } from 'components/inputs/InputText';
import ButtonPrimary from 'components/buttons/ButtonPrimary';

const styles = css`
  .title {
    margin-bottom: 40px;
  }

  .instructions {
    + .instructions {
      margin-block: 16px 40px;
    }
  }

  .button-continue {
    margin: 20px auto 40px;
  }

  ${MEDIA_QUERIES.mobile} {
    .title {
      margin-bottom: 30px;
    }

    .instructions {
      + .instructions {
        margin-block: 16px 20px;
      }
    }

    .button-continue {
      margin: 20px auto;
    }
  }
`;

const accessCodeValidateSchema = Yup.object({
  accessCode: Yup.string().required('Campo requerido'),
});

const ReferralCode = ({
  onBackButton,
  setCurrentStep,
  setUserCredentials,
}) => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleBack =
    onBackButton || (() => navigate(ROUTES_FEEKAPP.EXPLORE));

  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    setError('');
    APIClient.getReferralCode(values.accessCode)
      .then((res) => {
        if (res.data) {
          setUserCredentials({
            referralCode: values.accessCode,
          });
          setCurrentStep(authSteps.CREATE_USER);
        }
      })
      .catch(() => {
        setError('Código inválido');
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };
  return (
    <div css={styles}>
      <Text
        color={BLACK}
        fontSize={24}
        fontSizeMobile={16}
        align="center"
        fontWeight={600}
        className="title"
      >
        Ingresa tu código de acceso
      </Text>

      <Text
        fontSize={16}
        color={BLACK}
        align="center"
        lineHeight={22}
        className="instructions"
      >
        Ingresa el código de acceso de la persona que te invitó a
        formar parte de la comunidad <b>Feek</b>.
      </Text>
      <Text
        fontSize={16}
        color={BLACK}
        align="center"
        lineHeight={22}
        className="instructions"
      >
        Si no cuentas con un código, ingresa: <b>hellomexico</b>
      </Text>

      <Formik
        validateOnMount
        onSubmit={onSubmit}
        initialValues={{ accessCode: '' }}
        validationSchema={accessCodeValidateSchema}
      >
        {(formik) => {
          return (
            <Form className="formContainer">
              <FormInputText
                heightMobile={59}
                error={error || formik.errors.accessCode}
                name="accessCode"
                className="input-referral"
                inputClassName="text-center"
                placeholder="Ingresa el código aquí"
              />
              <ButtonPrimary
                height={59}
                color={BLACK}
                label="Continuar"
                pillShape
                className="button-continue"
                onClick={formik.submitForm}
                textProps={{ fontSize: 16, fontWeight: 600 }}
                disabled={!formik.isValid || formik.isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>

      <ButtonBack onClick={handleBack} className="button-back" />
    </div>
  );
};

ReferralCode.propTypes = {
  onBackButton: PropTypes.func,
  setCurrentStep: PropTypes.func.isRequired,
  setUserCredentials: PropTypes.func.isRequired,
};

export default ReferralCode;
