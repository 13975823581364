/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { forwardRef, useRef } from 'react';

import { mergeRefs } from 'utils/react';
import getClassNameFromArray from 'utils/getClassNameFromArray';

const Button = forwardRef(
  (
    {
      onClick,
      onKeyUp,
      children,
      className,
      onKeyDown,
      disabled = false,
      hideDisabledStyles,
      layoutOnly = false,
      hideHoverActiveStyles,
      ...rest
    },
    ref,
  ) => {
    const innerRef = useRef(null);

    const styles = css`
      cursor: ${disabled || hideHoverActiveStyles
        ? 'default'
        : 'pointer'};
      transition-property: opacity, transform;
      transition-timing-function: ease-in-out;
      transition-duration: 250ms;

      ${disabled && !hideDisabledStyles
        ? 'opacity: 0.5;'
        : !disabled &&
          !hideHoverActiveStyles &&
          `
          &:hover {
            opacity: 0.5;
            transition-property: opacity, transform;
            transition-timing-function: ease-in-out;
            transition-duration: 250ms;
          }

          &:active {
            transform: translateY(1px);
            opacity: 1;
            transition-property: opacity, transform;
            transition-timing-function: ease-in-out;
            transition-duration: 200ms;
          }
        `};
    `;

    function handleClick(event) {
      if (!disabled) {
        onClick?.(event);
      }
    }

    function handleKeyDown(event) {
      const firedByItself = event.target === innerRef.current;

      if (event.key === ' ') {
        if (firedByItself) {
          event.preventDefault();
        }
      } else if (event.key === 'Enter') {
        if (firedByItself) {
          event.preventDefault();
          innerRef.current.click();
        }
      }

      onKeyDown?.(event);
    }

    function handleKeyUp(event) {
      const firedByItself = event.target === innerRef.current;

      if (event.key === ' ') {
        if (firedByItself) {
          event.preventDefault();
          innerRef.current.click();
        }
      }

      onKeyUp?.(event);
    }

    let containerProps = { ...rest };

    if (!layoutOnly) {
      containerProps = {
        tabIndex: 0,
        role: 'button',
        ...rest,
        onKeyUp: handleKeyUp,
        onClick: handleClick,
        onKeyDown: handleKeyDown,
      };
    }

    return (
      <div
        css={styles}
        className={getClassNameFromArray([
          className,
          disabled && 'disabled',
        ])}
        ref={mergeRefs(ref, innerRef)}
        onClick={onClick}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        {...containerProps}
      >
        {children}
      </div>
    );
  },
);

Button.propTypes = {
  onClick: PropTypes.func,
  onKeyUp: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onKeyDown: PropTypes.func,
  layoutOnly: PropTypes.bool,
  className: PropTypes.string,
  hideDisabledStyles: PropTypes.bool,
  hideHoverActiveStyles: PropTypes.bool,
};

Button.displayName = 'Button';
export default Button;
