import { createSlice } from '@reduxjs/toolkit';

import APIClient from 'redux/api';

const initialState = {
  role: '',
  name: '',
  email: '',
  phone: '',
  password: '',
  id: '',
  isSideMenuVisible: false,
  selectedFeekspotId: localStorage.getItem('selectedFeekspotId'),
  /**
   * @deprecated This property doesn't follow our architecture principles
   */
  campaigns: [],
};

export const appSlice = createSlice({
  name: 'managerApp',
  initialState,
  reducers: {
    selectFeekspot: (state, action) => {
      localStorage.setItem('selectedFeekspotId', action.payload);
      state.selectedFeekspotId = action.payload;
    },

    toggleSideMenu: (state) => {
      state.isSideMenuVisible = !state.isSideMenuVisible;
    },

    openSideMenu: (state) => {
      state.isSideMenuVisible = true;
    },

    closeSideMenu: (state) => {
      state.isSideMenuVisible = false;
    },

    setCustomer: (state, { payload }) => {
      state.phone = payload.phone || state.phone;
      state.name = payload.name || state.name;
      state.email = payload.email || state.email;
      state.role = payload.role || state.role;
      state.id = payload._id || state.id;
    },

    logout: () => {
      APIClient.unsetAuthenticatedInstance();
    },

    /**
     * @deprecated This function will not be used anymore,
     */
    setCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },

    /**
     * @deprecated This function will not be used anymore,
     */

    signUpSuccess: (state, action) => {
      state.email = action.payload?.email || '';
      state.password = action.payload.password;
      state.phone = '+' + action.payload?.phone || '';
    },
  },
});

export const {
  logout,
  setCustomer,
  openSideMenu,
  setCampaigns,
  signUpSuccess,
  closeSideMenu,
  selectFeekspot,
  toggleSideMenu,
} = appSlice.actions;

export default appSlice.reducer;
