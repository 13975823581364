/** @jsxImportSource @emotion/react */
import { useRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';

import APIClient from 'redux/api';
import handleError from 'utils/handleError';
import { setFeekspots } from 'redux/entities';
import { GRAY_LIGHT, WHITE } from 'styles/colors';
import AccordionStatus from 'components/AccordionStatus';
import ButtonPrimary from 'components/buttons/ButtonPrimary';

const imageData = {
  image: {
    title: 'Logo del negocio',
    buttonText: 'Editar',
    type: 'image',
  },
  background_image: {
    title: 'Imagen del negocio',
    buttonText: 'Editar Imagen',
    type: 'background_image',
  },
};

function AccordionProfileImage({
  statusIcon = false,
  imageType,
  feekspot,
  className,
}) {
  const inputFile = useRef(null);
  const currentImage = feekspot?.[imageType];
  const dispatch = useDispatch();

  const openFileBrowser = (event) => {
    event.stopPropagation();
    inputFile.current.click();
  };

  function handleChangeImage(event) {
    event.stopPropagation();
    const file = event.target.files[0];

    const errorMessage = !file?.type?.includes('image')
      ? 'El archivo no es una imagen'
      : file?.size > 5 * 10 ** 6
      ? 'La imagen pesa más de 5mb'
      : null;

    if (!errorMessage) {
      APIClient.uploadFile({ file })
        .then(({ data: { Location } }) => {
          APIClient.updateFeekspot(
            { [imageData[imageType].type]: Location },
            feekspot._id,
          ).then(() => {
            APIClient.getFeekspotsByCustomer().then(({ data }) => {
              const feekspots = data.reduce(
                (prev, crr) => ({
                  ...prev,
                  [crr.customer._id]: crr,
                }),
                {},
              );
              dispatch(setFeekspots(feekspots));
            });
          });
        })
        .catch((e) => handleError(e));
    } else {
      handleError(errorMessage);
    }
  }

  const styles = css`
    .image {
      margin-block-end: 12px;
      border: 1px solid ${GRAY_LIGHT};
      border-radius: 4px;
      width: 64px;
      height: 64px;
      background: url(${currentImage}) 100% / cover ${WHITE};
    }
    .editBtn {
      width: 120px;
      height: 30px;
    }
  `;

  return (
    <AccordionStatus
      status={statusIcon ? !!currentImage : undefined}
      title={imageData[imageType].title}
      className={className}
      content={
        <div css={styles}>
          <input
            type="file"
            id="file"
            accept="image/*"
            onChange={handleChangeImage}
            onClick={(event) => {
              event.stopPropagation();
            }}
            ref={inputFile}
            hidden={true}
          />

          <div className="image"></div>

          <ButtonPrimary
            fontSize={13}
            strokeVariant
            className="editBtn"
            onClick={openFileBrowser}
            label={imageData[imageType].buttonText}
          />
        </div>
      }
    />
  );
}

AccordionProfileImage.propTypes = {
  statusIcon: PropTypes.bool,
  imageType: PropTypes.oneOf(['image', 'background_image'])
    .isRequired,
  feekspot: PropTypes.object,
  className: PropTypes.string,
};

export default AccordionProfileImage;
