/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import APIClient from 'redux/api';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import { setFeekspots } from 'redux/entities';
import { GRAY_TEXT, WHITE } from 'styles/colors';
import { ROUTES_MANAGER } from 'constants/routes';
import { selectFeekspot } from 'redux/app/managerApp';
import { convertGooglePlaceToAddress } from 'utils/format';
import { FormInputText } from 'components/inputs/InputText';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import ProgressLinearBar from 'components/ProgressLinearBar';
import feekLogoOverBackground from 'utils/feekLogoOverBackground';
import TurbulentFlowColorsBg from 'assets/images/TurbulentFlowColorsBg.png';
import { FormPlacesAutoCompleteInput } from 'components/inputs/PlacesAutocompleteInput';

const styles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 47px;
  ${feekLogoOverBackground(TurbulentFlowColorsBg)}

  .cardInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 75px;
    width: 600px;
    height: min-content;
    background-color: ${WHITE};
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: ${GRAY_TEXT};
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .rowContainer {
    width: 100%;
    padding-bottom: 15px;
  }

  .rowContainer h3 {
    margin-block-end: 5px;
  }
  .rowContainer button {
    width: 100% !important;
    height: 48px !important;
  }

  .label {
    font-size: 14px;
    margin-bottom: 15px;
    color: ${GRAY_TEXT};
    font-weight: 500;
    margin: 5px 0;
    width: 100%;
  }

  .ProgressBar {
    margin-top: 15px;
  }

  .ProgressBar .stepLabel {
    text-align: end;
    font-size: 12px;
    margin-bottom: 10px;
  }
`;

const formValidationSchema = Yup.object({
  name: Yup.string().required('El nombre del negocio es requerido'),
  location: Yup.object().shape({
    formatedLocation: Yup.string().required(
      'La direccion del negocio es requerido',
    ),
    lng: Yup.number().required(
      'La direccion del negocio es inválida',
    ),
    lat: Yup.number().required(
      'La direccion del negocio es inválida',
    ),
  }),
});

const formInitialValues = {
  name: '',
  location: '',
};

function RegistrationAddFeekspot() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFormSubmit = async (values, actions) => {
    const { lat, lng } = values.location;

    const addressComponents = convertGooglePlaceToAddress(
      values.location.addressComponents,
    );

    actions.resetForm({ values });
    actions.setSubmitting(true);

    const payload = {
      ...values,
      location: {
        state: addressComponents.administrative_area_level_1,
        exterior_number: addressComponents.street_number,
        city: addressComponents.locality,
        cp: addressComponents.postal_code,
        suburb: addressComponents.sublocality_level_1,
        street: addressComponents.route,
        country: addressComponents.country,
        coordinates: [lng, lat],
      },
    };

    try {
      const response = await APIClient.createFeekspot(payload);
      if (response.status === 201) {
        dispatch(setFeekspots([response.data]));
        dispatch(selectFeekspot(response.data.customer._id));
        navigate(ROUTES_MANAGER.REGISTRATION_FEEKSPOT_CATEGORIES);
      }
    } catch (error) {
      actions.setSubmitting(false);
      handleError(error);
    }
  };

  return (
    <div css={styles}>
      <Formik
        validateOnMount
        onSubmit={handleFormSubmit}
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
      >
        {(formik) => {
          return (
            <Form className="cardInnerContainer">
              <div className="rowContainer">
                <Text className="title">Crear nuevo negocio</Text>
                <Text className="label">
                  Compártenos los datos principales de tu negocio.
                </Text>
              </div>
              <Text className="label">Nombre del negocio</Text>
              <FormInputText
                name="name"
                placeholder="Nombre del negocio"
              />
              <Text className="label">Dirección del negocio</Text>
              <FormPlacesAutoCompleteInput name="location" withMap />
              <ButtonPrimary
                label="Siguiente"
                onClick={formik.submitForm}
                disabled={!formik.isValid || formik.isSubmitting}
              />
              <div className="rowContainer">
                <div className="ProgressBar">
                  <Text className="stepLabel">Paso 1 de 3 </Text>
                  <ProgressLinearBar progress={33} />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default RegistrationAddFeekspot;
