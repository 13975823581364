/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { mergeRefs } from 'utils/react';
import { createPortal } from 'react-dom';
import React, { useCallback, useEffect } from 'react';

import Icon from 'components/Icon';
import { Z_INDEX } from 'styles/constants';
import Button from 'components/buttons/Button';
import { GRAY_TEXT, WHITE } from 'styles/colors';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';

const validCombinations = {
  left: ['top', 'bottom'],
  right: ['top', 'bottom'],
  top: ['left', 'right'],
  bottom: ['left', 'right'],
};

function alignmentValidation(props, propName, componentName) {
  if (
    props.alignment !== undefined &&
    props.alignment !== 'center' &&
    !validCombinations[props.alignment]?.includes(
      props.position || 'bottom',
    )
  ) {
    return new Error(
      `Invalid value "${
        props.alignment
      }" suplied to ${propName} in ${componentName}

With "position:${
        props.position || 'bottom'
      }", alignment can only have this values: ${
        validCombinations[props.position || 'bottom']
      }`,
    );
  }
}

const getCoordinates = (coordinates, position, alignment) => {
  const finalCoordinates = {
    over: {
      center: `left: ${coordinates.left + coordinates.width / 2}px;
        top: ${coordinates.top + coordinates.height / 2}px;
        transform: translate(-50%, -50%);`,
    },

    top: {
      center: `left: ${coordinates.left + coordinates.width / 2}px;
        top: ${coordinates.top - 5}px;
        transform: translate(-50%, -100%);`,

      left: `left: ${coordinates.left}px;
        top: ${coordinates.top - 5}px;
        transform: translate(0, -100%);`,

      right: `left: ${coordinates.right}px;
        top: ${coordinates.top - 5}px;
        transform: translate(-100%, -100%);`,
    },

    bottom: {
      center: `left: ${coordinates.left + coordinates.width / 2}px;
        top: ${coordinates.bottom + 5}px;
        transform: translate(-50%, 0);`,

      left: `left: ${coordinates.left}px;
        top: ${coordinates.bottom + 5}px;`,

      right: `left: ${coordinates.right}px;
        top: ${coordinates.bottom + 5}px;
        transform: translate(-100%, 0);`,
    },

    left: {
      center: `left: ${coordinates.left - 5}px;
        top: ${coordinates.top + coordinates.height / 2}px;
        transform: translate(-100%, -50%);`,

      top: `left: ${coordinates.left - 5}px;
        top: ${coordinates.top}px;
        transform: translate(-100%, 0);`,

      bottom: `left: ${coordinates.left - 5}px;
        top: ${coordinates.bottom}px;
        transform: translate(-100%, -100%);`,
    },

    right: {
      center: `left: ${coordinates.right + 5}px;
        top: ${coordinates.top + coordinates.height / 2}px;
        transform: translate(0, -50%);`,

      top: `left: ${coordinates.right + 5}px;
        top: ${coordinates.top}px;`,

      bottom: `left: ${coordinates.right + 5}px;
        top: ${coordinates.bottom}px;
        transform: translate(0, -100%);`,
    },

    'right-top': {
      center: `left: ${coordinates.right + 5}px;
        top: ${coordinates.top - 5}px;
        transform: translate(0%, -100%);`,
    },

    'right-bottom': {
      center: `left: ${coordinates.right + 5}px;
        top: ${coordinates.bottom + 5}px;
        transform: translate(0%, 0%);`,
    },

    'left-top': {
      center: `left: ${coordinates.left - 5}px;
        top: ${coordinates.top - 5}px;
        transform: translate(-100%, -100%);`,
    },

    'left-bottom': {
      center: `left: ${coordinates.left - 5}px;
        top: ${coordinates.bottom + 5}px;
        transform: translate(-100%, 0%);`,
    },
  };

  return finalCoordinates[position][alignment];
};

const stylesButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const DropDown = React.forwardRef((props, ref) => {
  const {
    className,
    childrenWrapperCSS,
    children,
    isOpen,
    onOpen,
    onClose,
    position = 'bottom',
    alignment = 'center',
    icon,
  } = props;

  const refButton = React.useRef(null);
  const [coordinates, setCoordinates] = React.useState();

  const stylesWrapper = css`
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: ${Z_INDEX.dropDown};

    .overlay {
      top: -200px;
      left: -200px;
      position: fixed;
      width: 200vw;
      height: 200vh;
      background-color: transparent;

      &:hover {
        cursor: default;
      }
    }

    .content {
      position: fixed;
      ${coordinates}
      width: fit-content;
      height: fit-content;
      border-radius: 8px;
      padding: 10px;
      backdrop-filter: blur(15px);
      box-shadow: 0px 4px 24px ${GRAY_TEXT}80;
      background-color: ${WHITE};
    }
  `;

  const updateCoordinates = useCallback(() => {
    setCoordinates(
      getCoordinates(
        refButton.current?.getBoundingClientRect(),
        position,
        alignment,
      ),
    );
  }, []);

  const handleOpen = async () => {
    updateCoordinates();
    onOpen();
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('scroll', updateCoordinates);
    } else {
      document.removeEventListener('scroll', updateCoordinates);
    }

    return () => {
      document.removeEventListener('scroll', updateCoordinates);
    };
  }, [isOpen]);

  return (
    <div className={className}>
      <Button
        css={stylesButton}
        ref={mergeRefs(ref, refButton)}
        className="btn"
        onClick={(e) => {
          e.stopPropagation();
          if (isOpen) onClose?.();
          else handleOpen();
        }}
      >
        {icon || <Icon icon={Chevron} size={12} />}
      </Button>

      {isOpen &&
        createPortal(
          <div
            css={
              childrenWrapperCSS
                ? [stylesWrapper, childrenWrapperCSS]
                : stylesWrapper
            }
          >
            <Button
              tabIndex="0"
              className="overlay"
              onClick={(e) => {
                e.stopPropagation();
                if (e.target === e.currentTarget) onClose?.();
              }}
            />
            <div className="content">{children}</div>
          </div>,
          document.body,
        )}
    </div>
  );
});

DropDown.displayName = 'Button';

DropDown.propTypes = {
  className: PropTypes.string,
  childrenWrapperCSS: PropTypes.object,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf([
    'left',
    'left-top',
    'left-bottom',
    'right',
    'right-top',
    'right-bottom',
    'top',
    'bottom',
    'over',
  ]),
  /* USE OF POSITION 

    left-top       top       right-top
            |----------------|
            |                |
            |                |
            |    DROPDOWN    |
        left|      over      |right
            |                |
            |                |
            |----------------|
  left-bottom     bottom     right-bottom

  */

  alignment: alignmentValidation,
  /* USE OF ALIGNMENT

                        TOP
                         |
              ___________|____________
              left     center    right
      |    top|----------------------|top     |
      |       |                      |        |
      |       |                      |        |
LEFT  | center|       dropdown       |center  |  RIGHT
      |       |         OVER         |        |
      |       |                      |        |
      | bottom|----------------------|bottom  |
              left     center    right
              _______________________
                         |
                         |
                       BOTTOM

  */

  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.node,
};

export default DropDown;
