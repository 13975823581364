/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import Button from 'components/buttons/Button';
import { BLACK, PURPLE_FEEK } from 'styles/colors';

const styles = css`
  .tab-item {
    cursor: pointer;
    color: ${BLACK};
    font-weight: 500;
    margin-bottom: -1px;
    padding-bottom: 8px;
  }

  .tab-item.active {
    font-weight: 600;
    color: ${PURPLE_FEEK};
    border-bottom: 4px solid ${PURPLE_FEEK};
  }
`;

export default function TabButton({ label, isActive, onClick }) {
  return (
    <Button css={styles} onClick={onClick}>
      <Text
        fontSize={16}
        className={`mx-3 mx-md-5 tab-item ${isActive && 'active'}`}
      >
        {label}
      </Text>
    </Button>
  );
}

TabButton.propTypes = {
  label: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
