/** @jsxImportSource @emotion/react */
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { forwardRef, useRef } from 'react';

import {
  RED,
  WHITE,
  PURPLE_FEEK,
  PURPLE_INACTIVE,
} from 'styles/colors';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Button from 'components/buttons/Button';
import { propTypeLength } from 'utils/propValidation';
import { forwardInputEventHandlers } from 'utils/form.utils';
import { ReactComponent as Check } from 'assets/icons/Check.svg';
import InputWrapper from 'components/inputs/InputWrapper/input';

const InputCheckbox = forwardRef((props, ref) => {
  const {
    width,
    error,
    label,
    checked,
    disabled,
    className,
    labelProps,
    heightMobile,
    height = 'fit-content',
    ...restProps
  } = props;

  const checkboxRef = useRef('');

  const styles = css`
    &,
    :focus-visible,
    :hover {
      border: none;
    }

    .inputAndLabel {
      display: flex;
      align-items: center;
      user-select: none;
      cursor: default;

      .checkboxSurrogate {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        margin-right: 5px;
        color: ${WHITE};
        background: ${WHITE};
        border: 1px solid ${error ? RED : PURPLE_INACTIVE};
        border-radius: 6px;
        outline-offset: 3px;
      }

      &:hover .checkboxSurrogate {
        border-color: ${error ? RED : PURPLE_FEEK};
        cursor: pointer;
      }

      input {
        display: none;
        visibility: hidden;

        &:checked + .checkboxSurrogate {
          background: ${PURPLE_FEEK};
        }
      }
    }

    .error-text {
      margin: 3px 0;
      min-height: 19px;
    }
  `;

  const handleClick = () => {
    checkboxRef.current?.click();
  };
  return (
    <InputWrapper
      css={styles}
      error={error}
      width={width}
      height={height}
      heightMobile={heightMobile}
      className={className}
    >
      <Button
        className="inputAndLabel"
        onClick={handleClick}
        ref={ref}
        disabled={disabled}
      >
        <input
          ref={checkboxRef}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          {...restProps}
        />
        <div className="checkboxSurrogate">
          {checked && <Icon icon={Check} color={WHITE} size={13} />}
        </div>
        {typeof label === 'string' ? (
          <Text {...labelProps}>{label}</Text>
        ) : (
          label
        )}
      </Button>
    </InputWrapper>
  );
});

export const FormInputCheckbox = (props) => {
  const { name, error, ...restProps } = props;

  return (
    <Field name={name}>
      {({ meta, field }) => (
        <InputCheckbox
          name={name}
          checked={meta.value}
          error={meta.touched && (meta.error || error)}
          {...restProps}
          {...forwardInputEventHandlers(restProps, field)}
        />
      )}
    </Field>
  );
};

InputCheckbox.propTypes = {
  width: propTypeLength,
  height: propTypeLength,
  heightMobile: propTypeLength,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelProps: PropTypes.shape({ ...Text.propTypes }),
  name: PropTypes.string,
};

FormInputCheckbox.propTypes = InputCheckbox.propTypes;

InputCheckbox.displayName = InputCheckbox;

export default InputCheckbox;
