/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Text from 'components/Text';
import Button from 'components/buttons/Button';
import { ROUTES_FEEKAPP } from 'constants/routes';
import { BLACK, WHITE, GRAY_COLD } from 'styles/colors';

const styles = css`
  flex: 1;
  width: 100%;
  background-color: ${GRAY_COLD};

  .card {
    width: 560px;
    margin: 50px auto;
    padding: 36px 0px;
    border-radius: 30px;
    background-color: ${WHITE};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .header {
    width: 100%;
    padding: 0px 36px;
  }

  .toogle-button-container {
    margin: 36px auto;
    width: fit-content;
    padding: 6px;
    display: flex;
    width: fit-content;
    border-radius: 50px;
    justify-content: space-between;
    background-color: ${BLACK};
  }

  .toggle-item {
    cursor: pointer;
    user-select: none;
    padding: 12px 30px;
    border-radius: 50px;
  }
  .toggle-item.active {
    color: ${BLACK};
    font-weight: 600;
    background-color: ${WHITE};
  }
`;

export default function Agenda() {
  const navigate = useNavigate();
  const location = useLocation();
  const isRewards = location.pathname.includes(
    ROUTES_FEEKAPP.AGENDA_REWARDS,
  );

  return (
    <main css={styles}>
      <article className="card">
        <header className="header">
          <Text color={BLACK} fontSize={21} fontWeight={600}>
            Mi agenda
          </Text>
          <Button
            onClick={() =>
              navigate(
                isRewards
                  ? ROUTES_FEEKAPP.AGENDA_PROMOTIONS
                  : ROUTES_FEEKAPP.AGENDA_REWARDS,
              )
            }
            className="toogle-button-container"
          >
            <Text
              color={WHITE}
              fontSize={20}
              fontWeight={500}
              className={`toggle-item ${!isRewards && 'active'}`}
            >
              Promociones
            </Text>
            <Text
              color={WHITE}
              fontSize={20}
              fontWeight={500}
              className={`toggle-item ${isRewards && 'active'}`}
            >
              Recompensas 🎁
            </Text>
          </Button>
        </header>
        <Outlet />
      </article>
    </main>
  );
}
