/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import APIClient from 'redux/api';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import Modal from 'components/modals/Modal/index';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import RankingItem from 'screens/feekApp/Campaign/RankingItem';
import { BLACK, GRAY_MID, PURPLE_LIGHT, WHITE } from 'styles/colors';
import { ReactComponent as ColorSparks } from 'assets/images/ColorSparks.svg';

const styles = css`
  .modalMain {
    background-color: ${PURPLE_LIGHT};
  }

  > .wrapper {
    width: 370px;
    padding: 24px;
    max-width: 650px;
  }

  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: center;
  }
  .stars {
    width: 30px;
    height: 30px;
  }

  .rank-container {
    padding: 24px 0px;

    border-radius: 16px;
    margin-bottom: 24px;
    background-color: ${WHITE};
    border: 1px solid ${GRAY_MID};
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: ${GRAY_MID};
    margin: 20px 0 16px 0;
  }

  .action-button {
    border-radius: 40px;
    height: 48px;
  }
`;

export default function ModalMomentCompleted({
  visible,
  onRequestClose,
}) {
  const [serviceStars, setServiceStars] = useState(0);
  const [productStars, setProductStars] = useState(0);

  const { lastMomentCompletedId } = useSelector(
    (state) => state.feekApp,
  );

  const onSuccess = () => {
    if (serviceStars > 0 || productStars > 0) {
      APIClient.momentRating({
        momentId: lastMomentCompletedId,
        serviceRating: serviceStars,
        productRating: productStars,
      })
        .catch(handleError)
        .finally(() => {
          onRequestClose();
        });
    } else {
      onRequestClose();
    }
  };

  return (
    <Modal
      cssProp={styles}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      <div className="wrapper">
        <div className="title-container">
          <ColorSparks className="stars" />
          <Text color={BLACK} fontSize={20} fontWeight={600}>
            ¡Felicidades!
          </Text>
          <ColorSparks />
        </div>
        <Text
          color={BLACK}
          fontSize={16}
          align="center"
          className="mb-4"
        >
          Has completado tu Promoción <b>Feek</b>
        </Text>

        <div className="rank-container">
          <Text
            color={BLACK}
            fontSize={14}
            align="center"
            className="mb-2"
          >
            Califica el <b>Servicio</b> que recibiste
          </Text>
          <RankingItem onChange={setServiceStars} />
          <div className="divider"></div>
          <Text
            color={BLACK}
            fontSize={14}
            align="center"
            className="mb-2"
          >
            Califica el <b>Producto</b> que recibiste
          </Text>
          <RankingItem onChange={setProductStars} />
        </div>
        <ButtonPrimary
          label="Continuar"
          onClick={onSuccess}
          className="action-button"
        />
      </div>
    </Modal>
  );
}

ModalMomentCompleted.propTypes = {
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func,
};
