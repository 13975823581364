/** @jsxImportSource @emotion/react */
import moment from 'moment';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import {
  BLACK,
  WHITE,
  GRAY_MID,
  GREEN_STATUS,
  WHITE_ALMOST,
  RED_NOTIFICATIONS,
} from 'styles/colors';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Button from 'components/buttons/Button';
import { ReactComponent as Check } from 'assets/icons/Check.svg';
import { ReactComponent as XMini } from 'assets/icons/XMini.svg';
import ImagePlaceholder from 'assets/images/ImagePlaceholder.jpg';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import { ReactComponent as CalendarMini } from 'assets/icons/CalendarMini.svg';

const styles = css`
  padding: 10px;
  border-radius: 15px;
  border: 1px solid ${GRAY_MID};

  .text-no-decoration {
    text-decoration: none;
  }

  .img-participation {
    width: 90px;
    height: 100px;
    margin-right: 10px;
    border-radius: 10px;
  }

  .title {
    padding-top: 8px;
    padding-bottom: 10px;
  }

  .circle {
    display: flex;
    min-width: 22px;
    min-height: 22px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }

  .circle.bg-green {
    background-color: ${GREEN_STATUS};
  }

  .circle.bg-gray {
    background-color: ${WHITE_ALMOST};
  }

  .circle.bg-red {
    background-color: ${RED_NOTIFICATIONS};
  }
`;

export default function ParticipationCardSmall({
  image,
  title,
  status,
  ranking,
  eventDate,
  eventTime,
  className,
  experienceId,
}) {
  return (
    <Button css={styles} className={className}>
      <Link
        to={`/experience/${experienceId}`}
        className="text-no-decoration"
      >
        <div className="d-flex">
          <img
            src={image || ImagePlaceholder}
            alt={title}
            className="img-fluid img-participation"
          />
          <div className="d-flex flex-column justify-content-between me-2">
            <Text fontSize={14} color={BLACK}>
              {status === 'PENDING'
                ? 'Participando'
                : status === 'WINNED'
                ? 'Ganaste'
                : 'Quedaste'}{' '}
              en <b>{ranking}º lugar</b>
            </Text>

            <Text
              fontSize={16}
              fontWeight={600}
              color={BLACK}
              className="title"
            >
              {title}
            </Text>
            <div className="d-flex align-items-center justify-content-start">
              <Icon
                size={16}
                color={BLACK}
                icon={CalendarMini}
                className="me-1"
              />

              <Text fontSize={14}>
                {`${moment(eventDate, ['YYYY-MM-DD'])
                  .format('dddd DD MMM')
                  .replace(/(?<=\s)\w|^\w/g, (l) => l.toUpperCase())
                  .slice(0, -1)} | ${moment(
                  eventTime,
                  'hh:mm A',
                ).format('hh:mm A')}`}
              </Text>
            </div>
          </div>
          {status === 'PENDING' && (
            <div className="ms-auto my-auto circle bg-gray">
              <Icon
                size={12}
                rotate={270}
                color={BLACK}
                icon={Chevron}
              />
            </div>
          )}
          {status === 'WINNED' && (
            <div className="ms-auto my-auto circle bg-green">
              <Icon icon={Check} color={WHITE} size={12} />
            </div>
          )}
          {status === 'LOSED' && (
            <div className="ms-auto my-auto circle bg-red">
              <Icon icon={XMini} color={WHITE} size={8} />
            </div>
          )}
        </div>
      </Link>
    </Button>
  );
}

ParticipationCardSmall.propTypes = {
  className: PropTypes.string,
  eventDate: PropTypes.string,
  eventTime: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  ranking: PropTypes.number.isRequired,
  experienceId: PropTypes.string.isRequired,
};
