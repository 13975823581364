/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import Text from 'components/Text';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_AGNOSTIC } from 'constants/routes';
import { GRAY, BLACK, GRAY_MID, PURPLE_FEEK } from 'styles/colors';
import { getFontSizeAndLineHeigh } from 'utils/cssStringManipulation';
import ExperiencesFeed from 'screens/feekApp/Explore/ExperiencesFeed';
import PastExperiencesFeed from 'screens/feekApp/Explore/PastExperiences';

const styles = css`
.border-bottom {
  border-bottom: 1px solid ${GRAY_MID};
}

.tab-item {
  cursor: pointer;
  padding-bottom: 16px;
  font-weight: 500;
  color: ${BLACK};
}
.tab-item.active {
  font-weight: 700;
  color: ${PURPLE_FEEK};
  border-bottom: 4px solid ${PURPLE_FEEK};
}

.feekspots-container {
  gap: 20px;
  overflow-x: scroll; */
}

.button-rounded {
  width: fit-content;
  height: 16px;
  padding: 23px 25px;
  margin-right: 20px;
  border-radius: 30px;
  border: 2px solid ${BLACK};
}

.experiences-term-conditions {
  gap: 20px;
  display: flex;
  margin-bottom: 79px;
  flex-direction: column;
}

.experience-card:nth-child(1) {
  margin-left: 70px;
}

${MEDIA_QUERIES.mobile} {
  .tab-item {
    ${getFontSizeAndLineHeigh(16)}
    padding-bottom: 8px;
  }

  .experiences-feed {
    padding-top: 0;
    margin-top: 24px;
  }

  .experience-card:nth-child(1) {
    margin-left: 16px;
  }
  .experience-card {
    margin-right: 8px;
  }
  .experience-card {
    width: calc(100vw - 30px);
  }
  .title{
    font-size: 24px;
  }
}
`;

export default function ExploreRewards() {
  return (
    <div css={styles}>
      <div className="d-flex justify-content-center border-bottom">
        <Text fontSize={24} className="mx-3 mx-md-5 tab-item active">
          Experiencias
        </Text>
      </div>

      <ExperiencesFeed />

      <Text
        fontSize={32}
        color={BLACK}
        align="center"
        fontWeight={500}
        className="title"
      >
        Recompensas finalizadas
      </Text>

      <PastExperiencesFeed />

      <div className="experiences-term-conditions px">
        <Text color={GRAY} fontSize={12} fontWeight={300}>
          Disposiciones generales aplicables a las recompensas.
        </Text>
        <Text color={GRAY} fontSize={12} fontWeight={300}>
          Es indispensable consultar las bases de cada una de las
          recompensas ofrecidas en nuestra sección de “Recompensas”,
          así como nuestros{' '}
          <Link
            to={ROUTES_AGNOSTIC.TERMS_AND_CONDITIONS}
            target="_blank"
          >
            Términos y Condiciones.
          </Link>
          <br />
          Independientemente de las bases de cada una de las
          recompensas, estarán sujetas en general a lo siguiente:
        </Text>
        <Text color={GRAY} fontSize={12} fontWeight={300}>
          1.Las recompensas estarán limitadas a una cantidad
          específica de lugares. 2.Tendrán una vigencia para la
          participación dentro de las mismas. 3.Deberán incluir la
          fecha y hora del evento con el que se estará recompensando
          y/o la fecha y hora para reclamar las recompensas. 4.Al
          publicarse una recompensa aparecerá clasificada como “Coming
          Soon” y pasará a “In Progress” llegada la fecha de inicio
          para participar por la recompensa. 5.Por default, se
          mostrarán solo experiencias en status “In Progress”, pero se
          puede seleccionar la opción para mostrar experiencias con
          status “Coming Soon”, con la restricción de que la
          participación comenzará únicamente hasta que tenga el status
          “In Progress”. 6.Los usuarios recibirán un notificación
          “push” el día y la hora de inicio de las recompensas. 7.Las
          recompensas pueden ser locales (por estado o ciudad) o
          nacionales (en toda la República Mexicana). 8.En caso de
          empate en el ranking para ganar una recompensa, el primer
          criterio de desempate entre Feekers será el número total de
          promociones canceladas, es decir, el que tenga una menor
          cantidad de promociones canceladas tendrá la preferencia
          para adquirir la recompensa, y posteriormente, en caso de
          seguir empatados, el criterio de desempate será la fecha de
          inscripción a la recompensa, es decir, tendrá preferencia
          quien se inscribió primero a esta Feekexperience para poder
          ganar la recompensa. 9.El usuario debe cumplir con un mínimo
          de promociones completadas para poder ser acreedor a las
          recompensas.
        </Text>
      </div>
    </div>
  );
}
