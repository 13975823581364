/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { HashLink } from 'react-router-hash-link';

import Icon from 'components/Icon';
import Text from 'components/Text';
import * as Colors from 'styles/colors';
import { ReactComponent as Arrow } from 'assets/icons/Arrow.svg';

const arrowPointingTable = {
  down: 90,
  up: -90,
  right: 0,
  left: 180,
};

const HashLinkArrowButton = ({
  to,
  width,
  label,
  textProps,
  className,
  elementId,
  color = Colors.WHITE,
  arrowPosition = 'right',
  fontSize = label?.props?.fontSize || 24,
  height = fontSize * 2.5,
  arrowPointingTo = 'down',
  arrowColor = Colors.BLACK,
  ...props
}) => {
  const padding = `${height * 0.09 < 6 ? height * 0.09 : 6}px`;

  const styles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${height}px;
    border: 2px solid ${color};
    border-radius: ${height}px;
    padding: ${padding} ${padding} ${padding}
      ${height * 0.8 > 40 ? 40 : height * 0.8}px;
    text-decoration: none;
    width: ${width ? width + 'px' : 'fit-content'};
    height: ${height}px;

    .circle {
      flex: 0 0 auto;
      aspect-ratio: 1;
      height: 100%;
      border-radius: 50%;
      background-color: ${color};
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `;

  return (
    <HashLink
      css={styles}
      to={to}
      elementId={elementId}
      className={className}
      smooth
      {...props}
    >
      {arrowPosition === 'left' && (
        <div className="circle">
          <Icon
            icon={Arrow}
            color={arrowColor}
            rotate={
              Number.isFinite(arrowPointingTo)
                ? arrowPointingTo
                : arrowPointingTable[arrowPointingTo]
            }
            size={height / 2}
          />
        </div>
      )}

      {label && typeof label === 'string' ? (
        <Text
          className="label"
          color={color}
          fontSize={fontSize}
          {...textProps}
        >
          {label}
        </Text>
      ) : (
        label
      )}

      {arrowPosition === 'right' && (
        <div className="circle">
          <Icon
            icon={Arrow}
            color={arrowColor}
            rotate={
              Number.isFinite(arrowPointingTo)
                ? arrowPointingTo
                : arrowPointingTable[arrowPointingTo]
            }
            size={height / 2}
          />
        </div>
      )}
    </HashLink>
  );
};

HashLinkArrowButton.propTypes = {
  to: PropTypes.string,
  elementId: PropTypes.string,

  color: PropTypes.oneOf(Object.values(Colors)),

  arrowPointingTo: PropTypes.oneOfType([
    PropTypes.oneOf(['up', 'down', 'left', 'right']),
    PropTypes.number,
  ]),
  arrowPosition: PropTypes.oneOf(['left', 'right']),
  arrowColor: PropTypes.oneOf(Object.values(Colors)),

  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fontSize: function (props, propName, componentName) {
    if (props[propName]) {
      if (typeof props['label'] !== 'string') {
        return new Error(
          `Invalid prop ${propName} supplied to ${componentName}. ${propName} will not be supplied if the label prop is not of type string.`,
        );
      } else {
        PropTypes.checkPropTypes(
          Text.propTypes,
          props,
          propName,
          componentName,
        );
      }
    }
  },
  textProps: function (props, propName, componentName) {
    if (typeof props['label'] !== 'string' && props[propName]) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. ${propName} will not be supplied if the label prop is not of type string.`,
      );
    }
  },

  height: PropTypes.number,
  width: PropTypes.number,
  dark: PropTypes.bool,
  className: PropTypes.string,
};

export default HashLinkArrowButton;
