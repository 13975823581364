/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Outlet } from 'react-router-dom';

import SideMenu from 'components/legacy/SideMenu';
import { MEDIA_QUERIES } from 'styles/constants';
import Header from 'screens/manager/legacy/Outlet/Header';

const styles = css`
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 96px 1fr;
  grid-template-columns: 304px 1fr;
  grid-template-areas:
    'sideMenu header'
    'sideMenu main';
  grid-gap: 0px;

  & > div:first-of-type {
    grid-area: sideMenu;
  }

  & > div:nth-of-type(2) {
    grid-area: header;
  }

  .main {
    position: relative;
    display: flex;
    grid-area: main;

    & > :where(:first-of-type) {
      width: 100%;
      height: 100%;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    grid-auto-flow: column;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto;
    grid-template-areas:
      'header'
      'main';
    grid-gap: 0px;

    & > div:first-of-type {
      grid-area: main;
    }

    .main {
      overflow-x: hidden;
    }
  }
`;

function OutletManagerLegacy() {
  return (
    <div css={styles}>
      <SideMenu />
      <Header />
      <main className="main">
        <Outlet />
      </main>
    </div>
  );
}

export default OutletManagerLegacy;
