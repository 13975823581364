/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';

import APIClient from 'redux/api';
import Icon from 'components/Icon';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { setFeekspotLike } from 'redux/entities';
import { BLACK, RED, WHITE } from 'styles/colors';
import { propTypeLength } from 'utils/propValidation';
import { getColorWithAlpha } from 'utils/cssStringManipulation';
import { ReactComponent as HeartBicolor } from 'assets/icons/HeartBicolor.svg';

const styles = css`
  :active {
    transition: transform cubic-bezier(0, 0.57, 0.59, 2.06) 0.3s;
    transform: scale(1.4);
  }
`;

const activeIconProps = {
  color: RED,
  secondaryColor: RED,
};

function ButtonLikeFeekspot({
  dark,
  isLiked,
  disabled,
  className,
  feekspotId,
  iconSize = 18,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentIsLiked, setCurrentIsLiked] = useState(isLiked);
  const dispatch = useDispatch();

  const inactiveIconProps = {
    color: dark ? BLACK : WHITE,
    secondaryColor: getColorWithAlpha(WHITE, 40),
  };

  function toggleLike() {
    setIsLoading(true);
    APIClient.setFeekspotLike(feekspotId, !currentIsLiked)
      .then(({ data: { is_liked, customer } }) => {
        setCurrentIsLiked(is_liked);
        dispatch(
          setFeekspotLike({
            feekspotId: customer,
            currentIsLiked: is_liked,
          }),
        );
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }

  return (
    <Button
      css={styles}
      onClick={toggleLike}
      disabled={disabled || isLoading}
      className={className}
      {...props}
    >
      <Icon
        size={iconSize}
        icon={HeartBicolor}
        {...(currentIsLiked ? activeIconProps : inactiveIconProps)}
      />
    </Button>
  );
}

ButtonLikeFeekspot.propTypes = {
  dark: PropTypes.bool,
  isLiked: PropTypes.bool,
  disabled: PropTypes.bool,
  iconSize: propTypeLength,
  className: PropTypes.string,
  feekspotId: PropTypes.string,
};

export default ButtonLikeFeekspot;
