import axios from 'axios';
import handleError from 'utils/handleError';

const API =
  process.env.REACT_APP_ENV_URL || 'https://feek-back.herokuapp.com/';

class APIClient {
  client;

  constructor() {
    const currentFeekToken = localStorage.getItem('currentFeekToken');
    this.client = axios.create({
      baseURL: API,
      timeout: 10000,
      headers: {
        'Accept-language': 'es',
        Auth: process.env.REACT_APP_API_KEY,
        ...(currentFeekToken
          ? { Authorization: `Bearer ${currentFeekToken}` }
          : {}),
      },
    });

    this.client.interceptors.response.use(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        if (
          401 === error.response?.status &&
          window.location.pathname !== '/' &&
          window.location.pathname !== '/login'
        )
          this.unsetAuthenticatedInstance();
        else if (
          error.code === 'ECONNABORTED' &&
          error.message?.includes('timeout')
        )
          handleError('La conexión es demasiado lenta');

        return Promise.reject(
          new Error(
            error.response?.data?.message ||
              error?.response?.statusText ||
              error?.message ||
              'Hubo un error inesperado',
          ),
        );
      },
    );

    this.client.interceptors.request.use(
      async (request) => {
        const currentFeekToken = localStorage.getItem(
          'currentFeekToken',
        );
        const lastSelectedLocation =
          localStorage.getItem('last_location');

        if (lastSelectedLocation) {
          const coordinates = JSON.parse(
            lastSelectedLocation,
          ).coordinates;

          request.headers.set(
            'last_location',
            JSON.stringify(coordinates),
          );
        }

        request.headers.set('Auth', process.env.REACT_APP_API_KEY);

        if (currentFeekToken) {
          request.headers.set(
            'Authorization',
            `Bearer ${currentFeekToken}`,
          );
        }
        return request;
      },
      (error) => {
        return Promise.reject(
          new Error(
            error.response.data.message || 'Hubo un error inesperado',
          ),
        );
      },
    );
  }

  setAuthenticatedInstance(currentFeekToken) {
    localStorage.setItem('currentFeekToken', currentFeekToken);
    this.client.defaults.headers.Authorization = `Bearer ${currentFeekToken}`;
  }

  unsetAuthenticatedInstance() {
    localStorage.clear();
    this.client.defaults.headers.Authorization = undefined;
    window.location.reload();
  }

  feekspotSignInRequest(payload) {
    return this.client.post('customers/request', payload);
  }

  getLandingSelectedFeekspots() {
    return this.client.get('customers/select_landing');
  }

  getCitiesToShow() {
    return this.client.get('feek-location/customers');
  }

  getFeekersToShow() {
    return this.client.get('users/landing/customers');
  }

  updateUser(userId, payload) {
    return this.client.put(`users/${userId}`, payload);
  }

  getProfileFeeker() {
    return this.client.get('v2/profile');
  }

  getProfileFeekerPublic(userId) {
    return this.client.get(`users/profile/public/${userId}`);
  }

  getProfileFeekerPublicByUsername(username) {
    return this.client.get(
      `users/profile-username/public/${username}`,
    );
  }

  getFeekerLastLikedFeekers(userId, page, offset, limit) {
    return this.client.get(
      `story/last-likes/${userId}?page=${page || 1}&offset=${
        offset || 0
      }${limit ? `&limit=${limit}` : ''}`,
    );
  }

  getFeekerFavouriteFeekspots(userId, page, offset, limit) {
    return this.client.get(
      `/users/customers/likes/${userId}?page=${page || 1}&offset=${
        offset || 0
      }${limit ? `&limit=${limit}` : ''}`,
    );
  }

  getRoles() {
    return this.client.get('roles/roles-partners');
  }

  signUpUserByEmail(payload) {
    return this.client.post('users', payload);
  }

  signUpUserByPhone(payload) {
    return this.client.post('user/phone', payload);
  }

  verifyCode(payload) {
    return this.client.post('users/verifications', payload);
  }

  resendVerifyCode(key) {
    return this.client.post('users/verifications/resend', {
      key_validate: key,
    });
  }

  linkEmailOrPhone(payload) {
    const { keyUser, ...rest } = payload;
    return this.client.put('users/link/email-or-phone', {
      key_user: keyUser,
      ...rest,
    });
  }

  loginCustomer(payload) {
    return this.client.post('auth/login', payload);
  }

  loginFeeker(payload) {
    return this.client.post('v3/auth/login/feeker', payload);
  }

  loginByGoogle(payload) {
    return this.client.post('auth/google-autentication', payload);
  }

  sendForgotPasswordVerificationCode({ key_user }) {
    return this.client.post('auth/reset-password-send', {
      key_user,
      device: 'WEB',
    });
  }

  forgotPasswordValidateCode(payload) {
    return this.client.post('auth/reset-password-validate', payload);
  }

  resetPassword(payload) {
    return this.client.post('auth/reset-password', payload);
  }

  createCampaign(payload) {
    return this.client.post('campaigns', payload);
  }

  updateCampaign(campaign_id, payload) {
    return this.client.put(`campaigns/${campaign_id}`, payload);
  }

  getCampaignTypes() {
    return this.client.get('campaigns-types');
  }

  createFeekspot(payload) {
    return this.client.post('customers', payload);
  }

  updateFeekspot(payload, feekspotId) {
    return this.client.put(`customers/${feekspotId}`, payload);
  }

  getCategories(payload) {
    return this.client.get('categories/get-all', payload);
  }

  getFeekspotCard(feekspotId) {
    return this.client.get(
      `payments/credits-cards/${feekspotId}/default`,
    );
  }

  getProfile() {
    return this.client.get('profile');
  }

  getFeekspotsByCustomer() {
    return this.client.get('users/customers/all');
  }

  getFeekspot(feekspotId) {
    return this.client.get(`customers/${feekspotId}`);
  }

  getFeekspotForFeeker(feekspotId) {
    return this.client.get(`customers/feekers/${feekspotId}`);
  }

  getFeekspotForFeekerByInstagramUsername(igUsername) {
    return this.client.get(`/customers/username/${igUsername}`);
  }

  getLastFeekspotsByUser(userId) {
    return this.client.get(`/V2/users/customers/last/${userId}`);
  }

  uploadFile(payload) {
    const data = new FormData();
    data.append('file', payload.file);
    const folder = 'feek-dev';
    if (payload.isFeekspotLogo) {
      folder.concat('customer/logo');
    }

    if (payload.isFeekspotImage) {
      folder.concat('customer/image');
    }

    data.append('folder', folder);

    return this.client.post('s3/upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  uploadCampaignFile(file) {
    const data = new FormData();
    data.append('file', file);
    data.append(
      'folder',
      `temp/${process.env.NODE_ENV || 'development'}`,
    );

    return this.client.post('s3/upload', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  getPlans() {
    return this.client.get('plans');
  }

  deleteCollaborator(feekspotId, collaboratorId) {
    return this.client.delete('collaborator', {
      data: {
        collaborator_id: collaboratorId,
        customer_id: feekspotId,
      },
    });
  }

  unlinkFeekspot(feekspotId) {
    return this.client.delete(
      `collaborator/unlink/customer/${feekspotId}`,
    );
  }

  createCollaborator(payload) {
    return this.client.post('collaborator', payload);
  }

  changePasswordCollaborator(payload) {
    return this.client.post('collaborator/change_password', payload);
  }

  validateInvitCollaborator(accountInvite) {
    return this.client.post('collaborator/validate_account_invite', {
      account_invite: accountInvite,
    });
  }

  updateCollaborator(payload) {
    return this.client.put('collaborator', payload);
  }

  inviteCollaborator(payload) {
    return this.client.post('collaborator/invite', payload);
  }

  respondFeekspotInvitation(feekspotId, accepted) {
    return this.client.post('collaborator/invitation_update', {
      customer_id: feekspotId,
      accepted: accepted,
    });
  }

  getCampaignsByFeekspot(feekspotId, payload) {
    return this.client.get(
      `campaigns-feekers/${feekspotId}`,
      payload?.search && {
        params: { search: payload.search },
      },
    );
  }

  getCampaignsFeekspot(feekspotId) {
    return this.client.get(`/campaigns/customer/${feekspotId}`);
  }

  downloadInvoice(invoiceId) {
    return this.client.get(
      `payments/customer/invoice-payment/${invoiceId}`,
      {
        responseType: 'arraybuffer',
      },
    );
  }

  getPaymentHistory(feekspotId, page, limit = 10) {
    return this.client.get(`payments/report/${feekspotId}`, {
      params: {
        page,
        limit,
      },
    });
  }

  suscriptionPayment(payload) {
    return this.client.post('payments/subscriptions', payload);
  }

  getSpeiToBePayed(payload) {
    const { stripeProductId, feekspotId, coupon } = payload;

    return this.client.get('payments/get-spei-to-be-payed', {
      params: {
        coupon,
        customer_id: feekspotId,
        stripe_product_id: stripeProductId,
      },
    });
  }

  /**
   * @deprecated This function will not be used anymore,
   * Use getValidateCoupon instead to get the coupon validated according to the plan selected
   */
  getCoupon(coupon) {
    return this.client.get(`payments/get-coupon/${coupon}`);
  }

  getValidatedCoupon(coupon, productId) {
    return this.client.get(
      `v2/payments/get-coupon?coupon=${coupon}&stripe_product_id=${productId}`,
    );
  }

  saveCreditCardMethod(payload) {
    return this.client.post('payments/credit-cards', payload);
  }

  updateBilling(feekspotId, payload) {
    return this.client.put(
      `customers/billing/${feekspotId}`,
      payload,
    );
  }

  deleteCampaign(campaignId) {
    return this.client.delete(`campaigns/${campaignId}`);
  }

  getAnalytics(payload) {
    const { feekspotId, ...rest } = payload;
    const params = new URLSearchParams();
    Object.entries(rest).forEach((param) =>
      params.append(param[0], param[1]),
    );
    return this.client.get(`analytics/dashboard/${feekspotId}`, {
      params,
    });
  }

  syncUpFeekspotInstagram(payload) {
    return this.client.post('customers/instagram/sync-up', payload);
  }

  searchInstagramUser(query, signal) {
    return this.client.get(`instagram/search/${query}`, {
      signal: signal,
    });
  }

  linkInstagram(username) {
    return this.client.patch(`v2/users/instagram-link/${username}`);
  }

  getAddOns(payload) {
    return this.client.get(`add-ons?customer_id=${payload}`);
  }

  postAddOns(payload) {
    return this.client.post('payments/add-ons', payload);
  }

  cancelSuscription(feekspotId) {
    return this.client.patch(
      `payments/cancel/subscription/${feekspotId}`,
    );
  }

  changeSubscription(payload) {
    return this.client.patch('payments/change/subscription/', {
      plan_id: payload.planId,
      customer_id: payload.feekspotId,
    });
  }

  getLocationFeekspots() {
    return this.client.get('feek-location/customers');
  }

  getCategoriesLanding() {
    return this.client.get('categories/landing/active');
  }

  getExperiencesLanding() {
    return this.client.get('experiences/landing/feed');
  }

  getCampaignsLandingHeader() {
    return this.client.get('campaigns/feed/landing/header');
  }

  getCampaignsLandingPromotions(page = 1, limit = 10) {
    return this.client.get('campaigns/feed/landing/promotions', {
      params: {
        page,
        limit,
      },
    });
  }

  getManyCampaigns(campaignsIdsList) {
    return this.client.get(
      'v2/campaigns/many?'.concat(
        campaignsIdsList
          .map((item) => `campaigns[]=${item}`)
          .join('&'),
      ),
    );
  }

  feekLocationAvailability(payload) {
    return this.client.get('feek-location/validate', {
      params: {
        lat: payload.lat,
        lng: payload.lng,
      },
    });
  }

  feekLocationRequest(payload) {
    return this.client.post('feek-location/request', payload);
  }

  getReferralCode(code) {
    return this.client.get(`referral-code/${code}`);
  }

  createFeekerIntent(payload) {
    return this.client.post('users/verifications/send', payload);
  }

  createVerifiedFeeker(payload) {
    return this.client.post('v2/users/verifications', {
      ...payload,
      referral_code: payload.referralCode,
      terms_conditions: payload.termsConditions,
    });
  }

  getCampaignList(payload = {}) {
    const { category, sort, types, search, limit, offset, page } =
      payload;
    let endpoint = `campaigns/public`;

    endpoint += `?page=${page ?? 1}`;
    endpoint += `&limit=${limit ?? 5}`;
    endpoint += `&offset=${offset ?? 0}`;
    payload.search && (endpoint += `&search=${search}`);
    payload.sort && (endpoint += `&order[sort]=${sort}`);
    payload.category && (endpoint += `&category[]=${category}`);
    types &&
      types?.length > 0 &&
      (endpoint += types.map((item) => `&type[]=${item}`).join(''));

    return this.client.get(endpoint);
  }

  getBanners() {
    return this.client.get('banners');
  }
  getPublicTopFeekspots(payload = {}) {
    const { page, limit } = payload;

    return this.client.get('campaigns/public/top-customers', {
      params: {
        page,
        limit,
      },
    });
  }

  getCampaign(campaignId) {
    return this.client.get(`campaigns/public/feekers/${campaignId}`);
  }

  campaignWithExtraParticipations() {
    return this.client.get('campaigns/participations');
  }

  getFeekspotLikedFeekers(payload = {}) {
    const { customerId, limit, offset, page } = payload;
    return this.client.get(`customers/likes/feekers`, {
      params: {
        customer: customerId,
        page: page ?? 1,
        offset: offset ?? 0,
        limit: limit ?? 5,
      },
    });
  }

  setFeekspotLike(customerId, isLiked) {
    return this.client.post(`customers/like`, {
      customer_id: customerId,
      is_liked: isLiked,
    });
  }

  feekspotGetLike(customerId) {
    return this.client.get(`customers/like/${customerId}`);
  }

  getCampaignsByCategory(payload = {}) {
    const { categories, limit, offset, page } = payload;

    return this.client.get(
      'campaigns/categories?'.concat(
        categories.map((item) => `categories[]=${item}`).join('&'),
      ),
      {
        params: {
          page: page ?? 1,
          offset: offset ?? 0,
          limit: limit ?? 5,
        },
      },
    );
  }

  createMoment(userId, campaignId, date, time, branch, diners) {
    return this.client.post('v2/moments', {
      user_id: userId,
      campaign_id: campaignId,
      reservation_day: date,
      reservation_time: time,
      branch: branch,
      diners: diners,
    });
  }

  getMomentsByUser(payload) {
    let endpoint = `moments/user/${payload.userId || ''}`;
    const { page, status } = payload;
    endpoint += `?page=${page ?? 1}`;

    if (status) {
      endpoint += `&status=${status ?? 5}`;
    }
    return this.client.get(endpoint);
  }

  getStories() {
    return this.client.get(`v2/moments/stories-instagram`);
  }

  uploadToS3(file, folder) {
    const payload = new FormData();
    payload.append('file', file, file.name);
    payload.append('folder', folder);
    return this.client.post('s3/upload', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  validateTaskLocalMoment(momentId, storiesTask) {
    return this.client.put('moments/validate_task_local', {
      moment_id: momentId,
      stories_task: storiesTask,
    });
  }

  validateExtraTaskLocalMoment(momentId, storiesTask) {
    return this.client.put('moments/validate_extra_local', {
      moment_id: momentId,
      stories_task: storiesTask,
    });
  }
  getCampaignsByLocation({ northEast, southWest }, categories) {
    let endpoint = `v3/campaigns/location?northEast[latitude]=${northEast.latitude}&northEast[longitude]=${northEast.longitude}&southWest[latitude]=${southWest.latitude}&southWest[longitude]=${southWest.longitude}`;
    if (categories && categories?.length > 0) {
      endpoint += categories
        .map((item) => `&category[]=${item}`)
        .join('');
    }

    return this.client.get(endpoint);
  }

  validateMoment(momentId, ticketUrl) {
    return this.client.post(`v2/moments/validate`, {
      moment_id: momentId,
      ticket_url: ticketUrl,
    });
  }

  momentRating(payload) {
    return this.client.put(`moments/rating`, {
      moment_id: payload.momentId,
      ...(payload.serviceRating && {
        service_rating: payload.serviceRating,
      }),
      ...(payload.productRating && {
        product_rating: payload.productRating,
      }),
    });
  }

  getParticipationsByUser(payload) {
    return this.client.get('participation', {
      params: {
        status: payload.status,
        limit: payload?.limit || 5,
        offset: payload?.offset || 0,
        page: payload?.page || 1,
      },
    });
  }

  getExperiencesFeed(payload) {
    return this.client.get('experiences/public/feed', {
      params: {
        limit: payload?.limit || 5,
        offset: payload?.offset || 0,
        page: payload?.page || 1,
        ...(payload?.status ? { status: payload?.status } : {}),
      },
    });
  }

  bookExperience(id, add_moment, moment, campaign) {
    return this.client.post('participation', {
      experience_id: id,
      ...(add_moment ? { add_moment } : {}),
      ...(add_moment ? { moment } : {}),
      ...(add_moment ? { campaign } : {}),
    });
  }

  getExperience(id) {
    return this.client.get(`experience/${id}`);
  }

  getInstagramAccountStatus() {
    return this.client.get('users/instagram/account-status');
  }

  validateTaskMoment(momentId, storieId, userId) {
    return this.client.post('moments/validate_task', {
      moment_id: momentId,
      storie_id: storieId,
      user_id: userId,
    });
  }

  validateExtraMoment(momentId, storieId, userId) {
    return this.client.post('moments/validate_extra_points', {
      moment_id: momentId,
      storie_id: storieId,
      user_id: userId,
    });
  }

  getExperienceUserRanking(experienceId) {
    return this.client.get(
      `experiences/user-ranking/${experienceId}`,
    );
  }

  getExperienceScoreboard(experienceId) {
    return this.client.get(`experience/scoreboard/${experienceId}`);
  }

  deleteFeekerAccount(userId) {
    return this.client.delete(`users/${userId}`);
  }

  changeUserLocation(location) {
    return this.client.put('users/location', location);
  }

  getUnseenNotificationsCount() {
    return this.client.get('notifications/unseen');
  }

  getNotifications(limit, page) {
    return this.client.get(`v2/notifications`, {
      params: { limit: limit ?? 5, page: page ?? 1 },
    });
  }

  setnotificationsSeen(notificationIds) {
    return this.client.patch(
      `notifications/see-multi-notification/`,
      {
        notifications: notificationIds,
      },
    );
  }

  deleteNotification(id) {
    return this.client.delete(`notifications/${id}`);
  }

  loginWithInstagram(code) {
    return this.client.post('auth/instagram-web-login', {
      instagram_code: code,
    });
  }

  updateMoment(id, reservationDay, reservationTime, branch, diners) {
    return this.client.patch(`moments/reservation`, {
      moment_id: id,
      reservation_day: reservationDay,
      reservation_time: reservationTime,
      branche: branch,
      diners: diners,
    });
  }

  cancelMoment({ momentId, cancellationMessage }) {
    return this.client.post(`moments/canceled/${momentId}`, {
      cancell_message: cancellationMessage,
    });
  }
}

export default new APIClient();
