/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import Button from 'components/buttons/Button';
import RadioInput from 'components/inputs/RadioInput';
import { BLACK, PURPLE_MEDIUM, WHITE } from 'styles/colors';

const styles = css`
  .filter-item {
    display: flex;
    cursor: pointer;
    padding-top: 12px;
    flex-direction: row;
    padding-bottom: 12px;
    justify-content: space-between;
    border-bottom: 1px solid ${PURPLE_MEDIUM};
  }

  .filter-item.disabled {
    opacity: 0.35;
  }

  .filter-item.disabled .filter-counter {
  }

  .filter-counter {
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${BLACK};
  }

  .filter-counter.active {
    background-color: ${BLACK};
  }
`;

export default function FilterItem({
  counter,
  label,
  checked,
  onChange,
  categoryId,
}) {
  const disabled = useMemo(() => counter === 0, [counter]);

  const handleClick = () => {
    if (!disabled) {
      onChange?.(!checked);
    }
  };

  return (
    <Button disabled={disabled} css={styles} onClick={handleClick}>
      <div className={`filter-item ${disabled && 'disabled'}`}>
        <div className="d-flex align-items-center">
          <div
            className={`filter-counter me-2 ${checked && 'active'}`}
          >
            <Text color={checked ? WHITE : BLACK} fontSize={14}>
              {counter}
            </Text>
          </div>
          <Text color={BLACK} fontSize={16}>
            {label} {categoryId}
          </Text>
        </div>
        <RadioInput
          disabled={disabled}
          onChange={handleClick}
          checked={checked}
        />
      </div>
    </Button>
  );
}

FilterItem.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  counter: PropTypes.number,
  categoryId: PropTypes.string,
};
