import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  plans: {},
  moments: {},
  roles: {},
  cards: {},
  campaigns: {},
  feekspots: {},
  categories: {},
  experiences: {},
  campaignTypes: {},
  feekspotCampaigns: {},
  participations: {},
  feekspotInvitations: {},
};

export const entitiesSlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    setPlans: (state, action) => {
      _.mergeWith(
        state.plans,
        _.keyBy(action.payload, '_id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    setMoments: (state, action) => {
      _.mergeWith(
        state.moments,
        _.keyBy(action.payload, '_id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    setRoles: (state, action) => {
      _.mergeWith(
        state.roles,
        _.keyBy(action.payload, '_id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    setCategories: (state, action) => {
      _.mergeWith(
        state.categories,
        _.keyBy(action.payload, '_id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    setExperiences: (state, action) => {
      _.mergeWith(
        state.experiences,
        _.keyBy(action.payload, '_id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    setCampaignTypes: (state, action) => {
      _.mergeWith(
        state.campaignTypes,
        _.keyBy(action.payload, '_id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    setParticipations: (state, action) => {
      _.mergeWith(
        state.participations,
        _.keyBy(action.payload, '_id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    setFeekspots: (state, action) => {
      _.mergeWith(
        state.feekspots,
        _.keyBy(action.payload, 'customer._id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    setFeekspotLike: (state, action) => {
      _.set(
        state.feekspots,
        `${action.payload.feekspotId}.customer.is_liked`,
        action.payload.isLiked,
      );
    },
    setCampaigns: (state, action) => {
      _.mergeWith(
        state.campaigns,
        _.keyBy(action.payload, '_id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    removeFeekspot: (state, action) => {
      _.unset(state.feekspots, action.payload);
    },
    setFeekspotInvitations: (state, action) => {
      _.mergeWith(
        state.feekspotInvitations,
        _.keyBy(action.payload, 'customer._id'),
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    removeFeekspotInvitations: (state, action) => {
      _.unset(state.feekspotInvitations, action.payload);
    },
    setCardByFeekspot: (state, action) => {
      _.mergeWith(
        state.cards,
        {
          [action.payload.feekspotId]: action.payload.card,
        },
        (a, b) => (_.isArray(a) ? b : undefined),
      );
    },
    setFeekspotCollaborator: (state, action) => {
      _.set(
        state.feekspots,
        `${action.payload.feekspotId}.customer.collaborators`,
        [
          ...state.feekspots[action.payload.feekspotId].customer
            .collaborators,
          action.payload.collaborator,
        ],
      );
    },
    updateFeekspotCollaborator: (state, action) => {
      _.set(
        state.feekspots,
        `${action.payload.feekspotId}.customer.collaborators`,
        state.feekspots[
          action.payload.feekspotId
        ].customer.collaborators.map((item) =>
          item._id !== action.payload.collaborator._id
            ? item
            : action.payload.collaborator,
        ),
      );
    },
    removeFeekspotCollaborator: (state, action) => {
      _.set(
        state.feekspots,
        `${action.payload.feekspotId}.customer.collaborators`,
        state.feekspots[
          action.payload.feekspotId
        ].customer.collaborators.filter(
          (item) => item._id !== action.payload.collaboratorId,
        ),
      );
    },
    setFeekspotSubscriptionCancelled: (state, action) => {
      _.set(
        state.feekspots,
        `${action.payload}.customer.suscription_active`,
        false,
      );
    },
    setFeekspotPlan: (state, action) => {
      _.set(
        state.feekspots,
        `${action.payload.feekspotId}.customer.plan_id`,
        action.payload.planId,
      );
    },
    setFeekspotBilling: (state, action) => {
      _.set(
        state.feekspots,
        `${action.payload.feekspotId}.customer.billing`,
        action.payload.billing,
      );
    },
    setFeekspotCampaigns: (state, action) => {
      _.mergeWith(state.feekspotCampaigns, action.payload, (a, b) =>
        _.isArray(a) ? b : undefined,
      );
    },
    removeMoment: (state, action) => {
      _.unset(state.moments, action.payload);
    },
  },
});

export const {
  setPlans,
  setRoles,
  setMoments,
  setFeekspots,
  setCampaigns,
  removeMoment,
  setCategories,
  removeFeekspot,
  setExperiences,
  setFeekspotLike,
  setFeekspotPlan,
  setCampaignTypes,
  setParticipations,
  setCardByFeekspot,
  setFeekspotBilling,
  setFeekspotCampaigns,
  setFeekspotInvitations,
  setFeekspotCollaborator,
  removeFeekspotInvitations,
  removeFeekspotCollaborator,
  updateFeekspotCollaborator,
  setFeekspotSubscriptionCancelled,
} = entitiesSlice.actions;

export default entitiesSlice.reducer;
