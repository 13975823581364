/** @jsxImportSource @emotion/react */
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Field, useFormikContext } from 'formik';

import Text from 'components/Text';
import {
  RED,
  WHITE,
  GRAY_TEXT,
  RED_LIGHT,
  GRAY_LIGHT,
  PURPLE_FEEK,
  PURPLE_INACTIVE,
} from 'styles/colors';
import { defaultFont } from 'styles/fonts';

const CardInput = forwardRef((props, ref) => {
  const {
    error,
    onChange,
    className,
    inputClasses,
    placeholder,
    showIcon = false,
    autoValidate = true,
    type = 'number',
    ...restProps
  } = props;

  const [selfValidateError, setSelfValidateError] = useState('');

  const styles = css`
    width: 100%;

    & > * {
    }

    .inputBase {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 60px;
      width: 100%;
      border-radius: 16px;
      padding: 0 19px;
      font-family: ${defaultFont};
      border: 1px solid ${error ? RED : PURPLE_INACTIVE};
      background-color: ${error ? RED_LIGHT : WHITE};
      outline: none;
    }

    .webkitAutoFill {
      -webkit-text-fill-color: ${GRAY_TEXT};
      background-color: ${error ? RED_LIGHT : WHITE} !important;
      box-shadow: inset 0 0 0 1px rgb(255 255 255 / 0%),
        inset 0 0 0 1000px ${WHITE};
    }

    ${(!!setSelfValidateError || !!error) &&
    `.inputError {
      background-color: ${RED_LIGHT};
      border-color: ${RED} !important;
    }`}

    .inputFocus {
      border-color: ${error ? RED : PURPLE_FEEK};
    }

    .error-text {
      margin: 3px 0;
      min-height: 19px;
    }
  `;

  const options = {
    classes: {
      base: 'inputBase',
      focus: 'inputFocus',
      invalid: 'inputError',
      webkitAutofill: 'webkitAutoFill',
      ...inputClasses,
    },
    style: {
      base: {
        color: GRAY_TEXT,
        fontWeight: '300',
        fontSize: '16px',
        '::placeholder': { color: GRAY_LIGHT },
      },
      invalid: {
        color: error || setSelfValidateError ? RED : GRAY_TEXT,
      },
    },
  };

  const handleChange = (data) => {
    if (autoValidate) setSelfValidateError(data.error?.message);
    onChange?.(data);
  };

  let ReactElement;

  switch (type) {
    case 'cvc':
      ReactElement = CardCvcElement;
      break;
    case 'expiry':
      ReactElement = CardExpiryElement;
      break;
    default:
      ReactElement = CardNumberElement;
      break;
  }

  return (
    <div ref={ref} css={styles} className={className}>
      <ReactElement
        options={{
          ...(type !== 'cvc' && type !== 'expiry' && { showIcon }),
          placeholder,
          ...options,
        }}
        {...restProps}
        onChange={handleChange}
      />
      <Text
        fontSize={14}
        lineHeight={17}
        fontWeight="500"
        color={RED}
        className="error-text"
      >
        {typeof error === 'string' ? error || selfValidateError : ''}
      </Text>
    </div>
  );
});

export const FormCardInput = (props) => {
  const { name, error, onChange, onBlur, ...restProps } = props;

  const { setFieldError, setFieldTouched, setFieldValue } =
    useFormikContext();

  return (
    <Field name={name}>
      {({ meta }) => {
        const handleBlur = (data) => {
          setFieldTouched(name, true);
          onBlur?.(data);
        };

        const handleChange = (data) => {
          setFieldError(name, data.error?.message);
          setFieldValue(name, !!data.empty);
          onChange?.(data);
        };

        return (
          <CardInput
            name={name}
            value={meta.value}
            error={meta.touched && (meta.error || error)}
            {...restProps}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        );
      }}
    </Field>
  );
};

FormCardInput.propTypes = {
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};

CardInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  inputClasses: PropTypes.shape({
    base: PropTypes.string,
    focus: PropTypes.string,
    invalid: PropTypes.string,
    webkitAutofill: PropTypes.string,
  }),
  onChange: PropTypes.func,
  autoValidate: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['number', 'cvc', 'expiry']),
  showIcon: function (props, propName, componentName) {
    if (
      (props['type'] === 'cvc' || props['type'] === 'expiry') &&
      props[propName]
    ) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. ${propName} will not be supplied if the type prop is cvc or expiry.`,
      );
    } else {
      PropTypes.checkPropTypes(
        {
          showIcon: PropTypes.bool,
        },
        props,
        propName,
        componentName,
      );
    }
  },
};

CardInput.displayName = 'Button';

export default CardInput;
