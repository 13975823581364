/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import {
  BLACK,
  WHITE,
  GRAY_MID,
  GRAY_COLD,
  GRAY_DARK,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import { titlesFeekApp } from 'constants';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { setCategories } from 'redux/entities';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_FEEKAPP } from 'constants/routes';
import { campaignCategoriesEmojies } from 'constants';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { useScrollHorizontally } from 'hooks/useScrollHorizontally';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import { ReactComponent as Location } from 'assets/icons/Location.svg';
import CampaignsSearchInput from 'components/inputs/InputSearchCampaigns/Index';

const styles = css`
  .px-70 {
    padding-left: 70px;
  }

  .toggle-container {
    width: fit-content;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr;
    justify-items: space-between;
    background-color: ${GRAY_DARK};
    border-radius: 50px;
    padding: 6px;

    .toggle-item {
      cursor: pointer;
      user-select: none;
      border-radius: 50px;
      padding: 12px 30px;
      white-space: nowrap;

      &.active {
        background-color: ${WHITE};
        color: ${BLACK};
        font-weight: 600;
      }
    }
  }

  .categories-container {
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    gap: 10px;
    position: relative;
  }

  .categories-container::-webkit-scrollbar {
    display: none;
  }

  .border-difuse {
    position: relative;
    overflow: hidden;
  }

  .border-difuse:before,
  .border-difuse:after {
    content: '';
    width: 50px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.7) 25%,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    top: 0;
    bottom: 0;
    position: absolute;
  }

  .border-difuse:after {
    right: 0;
    opacity: 1;
  }

  .category-badge {
    gap: 5px;
    display: flex;
    cursor: pointer;
    padding: 11px 30px;
    width: fit-content;
    align-items: center;
    border-radius: 50px;
    border: 1px solid ${GRAY_MID};
    justify-content: space-between;
  }

  .next-button {
    cursor: pointer;
    border-radius: 50%;
    padding: 10px;
    border: 1px solid ${GRAY_MID};
    min-width: 60px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-decoration-none {
    text-decoration: none;
  }

  .map-button {
    gap: 8px;
    bottom: 20px;
    position: fixed;
    width: fit-content;
    border-radius: 30px;
    padding: 13px 24px;
    left: calc(50% - 100px);
  }

  > .header {
    padding-inline: 70px;
    margin-block: 24px 36px;
  }

  .campaign-search-input {
    display: none;

    .search-input {
      margin-left: auto;
      max-width: 500px;
      align-self: center;
      background-color: ${GRAY_COLD};
      border-radius: 10px;
      border: none;
    }
  }

  ${MEDIA_QUERIES.tablet} {
    .campaign-search-input {
      display: block;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    .px-16 {
      padding-left: 16px;
      padding-right: 16px;
    }

    > .header {
      padding-inline: 16px;
      margin-block: 16px 24px;
    }

    .toggle-container {
      flex: 1;
      margin-inline: 16px;

      .toggle-item {
        font-size: 16px;
        padding: 6px 10px;
      }
    }
  }

  ${MEDIA_QUERIES.bigScreen} {
    margin-top: 20px;
  }
`;

export default function Explore() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  document.title = titlesFeekApp.Explore;

  const { categories } = useSelector((state) => state.entities);

  const isRewards = location.pathname.includes(
    ROUTES_FEEKAPP.EXPLORE_REWARDS,
  );

  const categoriesRef = useScrollHorizontally({ stopOnEdges: true });

  document.title = titlesFeekApp.Explore;

  useEffect(() => {
    APIClient.getCategories()
      .then(({ data }) => {
        dispatch(setCategories(data));
      })
      .catch(handleError);
  }, []);

  return (
    <main css={styles}>
      <header className="header">
        <CampaignsSearchInput className="campaign-search-input" />
        <div className="row justify-content-between">
          <Button
            className="toggle-container"
            onClick={() =>
              navigate(
                isRewards
                  ? ROUTES_FEEKAPP.EXPLORE
                  : ROUTES_FEEKAPP.EXPLORE_REWARDS,
              )
            }
          >
            <Text
              color={WHITE}
              fontSize={20}
              fontWeight={500}
              align="center"
              className={`toggle-item ${!isRewards && 'active'}`}
            >
              Promociones
            </Text>
            <Text
              color={WHITE}
              fontSize={20}
              fontWeight={500}
              align="center"
              className={`toggle-item ${isRewards && 'active'}`}
            >
              Recompensas 🎁
            </Text>
          </Button>

          <div className="col-12 col-lg-7 d-none d-lg-block">
            <div className="d-flex justify-content-between">
              <div className="border-difuse">
                <div
                  className="categories-container"
                  ref={categoriesRef}
                >
                  {Object.values(categories).map((category) => (
                    <Link
                      to={
                        ROUTES_FEEKAPP.CATEGORIES +
                        '/' +
                        category.key.toLowerCase()
                      }
                      key={category._id}
                      className="category-badge"
                    >
                      <Text
                        color={BLACK}
                        fontSize={30}
                        fontWeight={600}
                      >
                        {campaignCategoriesEmojies[category.key] ||
                          '✨'}{' '}
                      </Text>
                      <Text
                        color={BLACK}
                        fontSize={16}
                        fontWeight={600}
                      >
                        {category.name}
                      </Text>
                    </Link>
                  ))}
                </div>
              </div>

              <div className="next-button">
                <Icon icon={Chevron} rotate={-90} size={14} />
              </div>
            </div>
          </div>
        </div>
      </header>

      <Outlet />

      <Link to={ROUTES_FEEKAPP.MAP}>
        <ButtonPrimary
          pillShape
          color={BLACK}
          label="Ver mapa"
          layoutOnly
          iconPosition="right"
          className="map-button"
          icon={<Icon icon={Location} color={WHITE} size={22} />}
        />
      </Link>
    </main>
  );
}
