/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import {
  BLACK,
  GRAY_MID,
  PURPLE_FEEK,
  PURPLE_MEDIUM,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import { setCategories } from 'redux/entities';
import { ROUTES_FEEKAPP } from 'constants/routes';
import ModalAuth from 'components/modals/ModalAuth';
import FeekspotCampaign from 'components/FeekspotCampaign';

const styles = css`
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 70px;
  min-height: 70vh;

  .border-difuse {
    position: relative;
    overflow: hidden;
  }

  .border-difuse:before,
  .border-difuse:after {
    content: '';
    width: 50px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.7) 25%,
      rgba(255, 255, 255, 0.9) 50%,
      rgba(255, 255, 255, 1) 100%
    );
    top: 0;
    bottom: 0;
    position: absolute;
  }

  .border-difuse:after {
    right: 0;
    opacity: 1;
  }

  .categories-container {
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    gap: 10px;
    margin-bottom: 24px;
  }

  .categories-container::-webkit-scrollbar {
    display: none;
  }

  .category-badge {
    gap: 5px;
    display: flex;
    cursor: pointer;
    padding: 11px 30px;
    width: fit-content;
    align-items: center;
    border-radius: 50px;
    border: 1px solid ${GRAY_MID};
    justify-content: space-between;
  }

  .category-badge.active {
    border: 2px solid ${PURPLE_MEDIUM};
  }

  .no-data {
    margin: 150px auto;
    border: 1px solid ${PURPLE_MEDIUM};
    width: 459px;
    padding: 20px;
    border-radius: 16px;
  }

  .cirle-button {
    border-radius: 40px;
    width: 450px;
    margin: 70px auto 40px auto;
    height: 60px;
  }
`;

export default function MapResults() {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [authModalData, setAuthModalData] = useState({
    show: false,
    title: '',
  });

  const dispatch = useDispatch();

  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const likeButtonOnClick = useMemo(
    () =>
      !localStorage.getItem('currentFeekToken')
        ? () =>
            setAuthModalData({
              show: true,
              title: 'Inicia sesión para agregar a favoritos',
            })
        : null,
    [],
  );

  useEffect(() => {
    APIClient.getCategories().then(({ data }) => {
      dispatch(setCategories(data));
    });
  }, []);

  useEffect(() => {
    if (params) {
      getCampaigns();
    }
  }, [params]);

  const getCampaigns = () => {
    setLoading(true);
    APIClient.getCampaignsByLocation(
      {
        northEast: {
          latitude: params.get('northEast').split(',')?.[0],
          longitude: params.get('northEast').split(',')?.[1],
        },
        southWest: {
          latitude: params.get('southWest').split(',')?.[0],
          longitude: params.get('southWest').split(',')?.[1],
        },
      },
      params.get('categories')?.split(','),
    )
      .then((response) => {
        setResults(response.data);
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ModalAuth
        title={authModalData.title}
        visible={authModalData.show}
        onRequestClose={() =>
          setAuthModalData({ show: false, title: '' })
        }
      />

      <main className="mt-4" css={styles}>
        <Text
          color={BLACK}
          fontSize={32}
          fontWeight={600}
          className="my-4"
        >
          Resultados de zona
        </Text>
        <div className="row w-100">
          {results?.map((result) => (
            <div
              key={result.customer._id}
              className="col-xxl-3 col-xl-4 col-md-6 mb-4"
            >
              <FeekspotCampaign
                category={result.customer.categories[0]}
                campaigns={result.campaigns}
                feekspotName={result.customer.name}
                backgroundImage={result.customer?.image}
                feekspotId={result.customer._id}
                isLiked={result.customer?.is_liked}
                likeButtonOnClick={likeButtonOnClick}
              />
            </div>
          ))}
          {results.length === 0 && !loading && (
            <div className="col-12 no-data">
              <Text align="center" fontSize={22} className="mb-1">
                😮
              </Text>
              <Text
                color={BLACK}
                fontSize={20}
                align="center"
                className="me-1"
              >
                No hay resultados para esta zona
              </Text>
              <Link to={ROUTES_FEEKAPP.MAP}>
                <Text
                  color={PURPLE_FEEK}
                  fontSize={20}
                  fontWeight={600}
                  align="center"
                  className="mt-2"
                >
                  Explorar
                </Text>
              </Link>
            </div>
          )}
        </div>
      </main>
    </>
  );
}
