import { ReactComponent as Cash } from 'assets/icons/Cash.svg';
import { ReactComponent as Plane } from 'assets/icons/Plane.svg';
import { ReactComponent as Music } from 'assets/icons/Music.svg';
import { ReactComponent as ZapMini } from 'assets/icons/ZapMini.svg';
import { ReactComponent as GiftMini } from 'assets/icons/GiftMini.svg';
import { ReactComponent as PowerMini } from 'assets/icons/PowerMini.svg';
import { ReactComponent as GroupMini } from 'assets/icons/GroupMini.svg';
import { ReactComponent as CoupleMini } from 'assets/icons/CoupleMini.svg';
import { ReactComponent as IndividualMini } from 'assets/icons/IndividualMini.svg';
import { ReactComponent as StarExplodingMini } from 'assets/icons/StarExplodingMini.svg';
import { ReactComponent as ArrowDownPesoMini } from 'assets/icons/ArrowDownPesoMini.svg';
import { ReactComponent as ArrowDownPercentageMini } from 'assets/icons/ArrowDownPercentageMini.svg';

const rolesSelectValues = [
  {
    label: 'Administrador',
    value: 'customerAdmin',
  },
  {
    label: 'Supervisor',
    value: 'customerSupervisor',
  },
  {
    label: 'Espectador',
    value: 'customerViewer',
  },
];

const hoursSelectValues = [
  { value: '0:00', label: '0:00' },
  { value: '1:00', label: '1:00' },
  { value: '2:00', label: '2:00' },
  { value: '3:00', label: '3:00' },
  { value: '4:00', label: '4:00' },
  { value: '5:00', label: '5:00' },
  { value: '6:00', label: '6:00' },
  { value: '7:00', label: '7:00' },
  { value: '8:00', label: '8:00' },
  { value: '9:00', label: '9:00' },
  { value: '10:00', label: '10:00' },
  { value: '11:00', label: '11:00' },
  { value: '12:00', label: '12:00' },
  { value: '13:00', label: '13:00' },
  { value: '14:00', label: '14:00' },
  { value: '15:00', label: '15:00' },
  { value: '16:00', label: '16:00' },
  { value: '17:00', label: '17:00' },
  { value: '18:00', label: '18:00' },
  { value: '19:00', label: '19:00' },
  { value: '20:00', label: '20:00' },
  { value: '21:00', label: '21:00' },
  { value: '22:00', label: '22:00' },
  { value: '23:00', label: '23:00' },
];

const roles = {
  customer: 'customer',
  customerOwner: 'Propietario',
  customerAdmin: 'Administrador',
  customerSupervisor: 'Supervisor',
  customerViewer: 'Espectador',
};
const weekDaysEs = {
  lunes: 'monday',
  martes: 'tuesday',
  miércoles: 'wednesday',
  jueves: 'thursday',
  viernes: 'friday',
  sábado: 'saturday',
  domingo: 'sunday',
};

const weekDays = {
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo',
};

const weekDaysShort = {
  monday: 'Lun',
  tuesday: 'Mar',
  wednesday: 'Miér',
  thursday: 'Jue',
  friday: 'Vier',
  saturday: 'Sáb',
  sunday: 'Dom',
};

const campaignStatusAbbreviation = {
  FULL_ACCOUNT: 'Cuenta completa',
  DISCOUNT_PERCENTAGE: 'Porcentaje',
  MONETARY_DISCOUNT: 'Descuento',
  GIFT: 'Regalo',
};

const campaignTypeMiniIconsById = {
  '61b577fa1e87dc21e2b19cfa': GiftMini,
  '61b5780c1e87dc21e2b19d00': StarExplodingMini,
  '61b577ff1e87dc21e2b19cfc': ArrowDownPesoMini,
  '61b578061e87dc21e2b19cfe': ArrowDownPercentageMini,
};

const campaignTypeMiniIconsByName = {
  GIFT: GiftMini,
  CASHBACK: Cash,
  FEEK_POINTS: ZapMini,
  FULL_ACCOUNT: StarExplodingMini,
  MONETARY_DISCOUNT: ArrowDownPesoMini,
  DISCOUNT_PERCENTAGE: ArrowDownPercentageMini,
};

const campaignCategoriesEmojies = {
  BAR: '🍸',
  FUN: '🎉',
  PETS: '🐶',
  MUSIC: '🎵',
  COFFEE: '☕',
  BEAUTY: '✨',
  BRUNCH: '🥞',
  HEALTH: '🥼',
  CULTURE: '🖼️',
  DEFAULT: '✨',
  OUTDOORS: '🏕️',
  WELLNESS: '🤍',
  SHOPPING: '🛍️',
  NIGHTLIFE: '🪩',
  RESTAURANT: '🍔',
  HOSPITALITY: '🏨',
};

const campaignParticipationMiniIconsByName = {
  'En grupo': GroupMini,
  Individual: IndividualMini,
  'En pareja': CoupleMini,
};

const authSteps = {
  LOGIN_OPTIONS: 0,
  LOGIN: 1,
  ACCESS_CODE: 2,
  CREATE_USER: 3,
  CODE_VERIFICATION: 4,
};

const titlesFeekApp = {
  Explore: 'Feek',
  ProfileFeeker: 'Perfil de feeker',
  ProfileFeekerEdit: 'Feek • Edita tu perfil',
  LandingManager: 'Feek para negocios',
  Info: 'Información y contacto',
  AgendaRecompensas: 'Recompensas | Agenda | Feek',
  AgendaPromotions: 'Recompensas | Agenda | Feek',
  Login: 'Feek | Login',
  Registration: 'Feek | Registro',
  Map: 'Explorar por ubicación | Feek',
  Notifications: 'Notificaciones',
  ProfileFeekspot:
    'Colabora con marcas, disfruta beneficios y gana recompensas',
};

const cssRegExp = {
  number: /\d+$|^\d*(\.\d+)?/,
  sizeKeywords:
    /auto|max-content|min-content|fit-content(?:\(.*?\))?|clamp\(.*?\)|inherit|initial|revert|revert-layer|unset|min\(.*?\)|max\(.*?\)|minmax\(.*?\)/,
  get validSize() {
    return new RegExp(
      `^(${this.number.source})(px|%|cm|mm|in|pc|pt|Q|ch|cqw|cqh|cqi|cqb|cqmin|cqmax|ex|ic|lh|em|rem|rlh|vb|vh|vi|dvb|dvh|dvi|dvmax|dvmin|dvw|lvb|lvh|lvi|lvmax|lvmin|lvw|svb|svh|svi|svmax|svmin|svw|vmax|vmin|vw)|${this.sizeKeywords.source}$`,
      'i',
    );
  },
};

const availablePronouns = ['Él', 'Ella', 'Elle'];

const experienceTypeIcons = {
  MUSIC: Music,
  GADGET: PowerMini,
  TRAVEL: Plane,
  GIFT: GiftMini,
};

const experiencesStatus = {
  FINISHED: 'FINISHED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMING_SOON: 'COMING_SOON',
};

export {
  roles,
  weekDays,
  authSteps,
  cssRegExp,
  weekDaysEs,
  weekDaysShort,
  titlesFeekApp,
  availablePronouns,
  rolesSelectValues,
  hoursSelectValues,
  experiencesStatus,
  experienceTypeIcons,
  campaignTypeMiniIconsById,
  campaignCategoriesEmojies,
  campaignStatusAbbreviation,
  campaignTypeMiniIconsByName,
  campaignParticipationMiniIconsByName,
};
