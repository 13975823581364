/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Form, FormikProvider, useFormik } from 'formik';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import {
  GRAY,
  BLACK,
  WHITE,
  GRAY_MID,
  GRAY_DARK,
  GREEN_LIGHT,
  PURPLE_FEEK,
  PURPLE_LIGHT,
  WHITE_ALMOST,
  GRAY_LIGHTER,
  PURPLE_MEDIUM,
  GRAY_LIGHTEST,
} from 'styles/colors';
import APIClient from 'redux/api';
import Icon from 'components/Icon';
import Text from 'components/Text';
import {
  getFluidSize,
  getFontSizeAndLineHeigh,
} from 'utils/cssStringManipulation';
import Avatar from 'components/Avatar';
import { titlesFeekApp } from 'constants';
import { Slogan } from 'components/Slogan';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import FooterLanding from 'components/FooterLanding';
import { AvatarsCabinet } from 'components/AvatarsCabinet';
import { convertGooglePlaceToAddress } from 'utils/format';
import { FormInputText } from 'components/inputs/InputText';
import FriendsEating from 'assets/images/FriendsEating.webp';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { FormPhoneInput } from 'components/inputs/PhoneInput';
import FriendsEatingPNG from 'assets/images/FriendsEating.png';
import { FormInputSelect } from 'components/inputs/InputSelect';
import { ReactComponent as Arrow } from 'assets/icons/Arrow.svg';
import HashLinkArrowButton from 'components/HashLinkArrowButton';
import PhotographingFood from 'assets/images/PhotographingFood.jpg';
import { ReactComponent as FeekLogo } from 'assets/icons/FeekLogo.svg';
import { ReactComponent as CheckCircle } from 'assets/icons/CheckCircle.svg';
import { ReactComponent as FeekerShopping } from 'assets/images/FeekerShopping.svg';
import { FormPlacesAutoCompleteInput } from 'components/inputs/PlacesAutocompleteInput';
import { ReactComponent as FeekerBehindPhone } from 'assets/images/FeekerBehindPhone.svg';

const styles = css`
  ul,
  ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  min-height: 100vh;
  scroll-behavior: smooth;

  .draggableEmoji {
    transition: font-size 0.1s ease-in-out,
      text-shadow 0.1s ease-in-out;
    position: absolute;
    font-size: 60px;
    cursor: grab;

    :hover,
    :active {
      font-size: 120%;
      text-shadow: 10px 10px 4px ${GRAY_LIGHTER};
    }
  }

  .roundedCardBig {
    border: 1px solid ${GRAY_MID};
    border-radius: 30px;
  }

  .tag {
    display: inline flex;
    align-items: center;
    border-radius: 30px;
  }

  .listWrapper {
    & > *:not(.listWrapperTitle) {
      margin-block-start: 40px;
    }

    .checkList {
      .checkListElement {
        display: flex block;
        align-items: center;
        gap: 10px;
        margin-inline-start: 40px;

        :not(:first-of-type) {
          margin-block-start: 20px;
        }

        .bullet {
          background-color: ${WHITE};
          border: 2px solid ${WHITE};
          border-radius: 50%;
          filter: invert(1);
        }
      }
    }
  }

  .header {
    border: 1px solid ${PURPLE_LIGHT}4D;
    background: ${GRAY_LIGHTEST}B2;
    margin-inline: 70px;
    padding: 20px 20px 20px 35px;
    position: fixed;
    top: 40px;
    border-radius: 30px;
    width: calc(100% - 140px);
    backdrop-filter: blur(5px);
    ${'' /* z-index avoid phone input overlapping */}
    z-index: 1;

    &,
    .brandWrapper,
    .navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: ${getFluidSize(5, 40)};
    }

    .navigation {
      gap: ${getFluidSize(0, 48)};

      .navButton {
        display: flex;
        align-items: center;
        text-decoration: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: ${GRAY};
      }

      .goToSignInButton {
        width: auto;
        height: 48px;
        border-radius: 24px;
        padding: 17px 25px;
        margin-inline-start: 12px;
        background-color: ${BLACK};
        color: ${WHITE};
        font-size: 20px;
        line-height: 100%;
      }
    }
  }

  .hero {
    padding: 307px 70px 70px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: ${GREEN_LIGHT};

    .hero-title {
      width: 45%;
      margin-block-end: 158px;

      > :nth-of-type(1) {
        margin-block-end: 60px;
      }
    }
  }

  .callToActionWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 40px;
    height: 267px;
    padding-inline: 70px;

    .knowOurFeekers {
      display: flex;
      align-items: center;
      gap: 30px;
      border-radius: 30px;
      border: 1px solid ${GRAY_MID};
      padding: 30px;

      .circularHashLink {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 47px;
        aspect-ratio: 1;
        background-color: ${WHITE_ALMOST};
      }
    }

    .signInFormWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 36%;
      margin-inline-end: 7%;
      position: relative;

      .feekerShoppingImage {
        padding-inline: 39px;
        width: 100%;
      }

      .signInForm {
        width: 100%;
        height: fit-content;
        background-color: ${PURPLE_LIGHT};
        border-radius: 30px;
        padding: 24px;

        .signInFormInput,
        .locationInput input,
        .feekspotTypeWrapper .inputWrapper {
          height: 59px;
          border-radius: 10px;
          border: 1px solid ${PURPLE_MEDIUM};
          padding-inline-end: 16px;

          ::placeholder,
          select:required:invalid {
            color: ${GRAY};
          }

          .signInFormInput {
            border: none;
          }

          &:not(.react-tel-input, .inputWrapper) {
            padding-inline-start: 16px;
          }

          .selected-flag {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          .flag-dropdown.btn {
            border-color: ${PURPLE_MEDIUM};
          }

          &,
          .form-control .phoneInput {
            font-size: 16px;
          }
        }

        .submitSignInFormButton {
          width: 196px;
          height: 60px;
          border-radius: 40px;
          margin-left: auto;
        }
      }

      .successSignInMsg {
        display: flex;
        gap: 20px;
        position: absolute;
        bottom: 0;
        margin: 24px;
        background-color: ${GREEN_LIGHT};
        padding: 40px;
        border-radius: 20px;

        .successSignInMsgTextWrapper {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-content: center;
          justify-content: center;
          gap: 20px;

          .successSignInMsgButton {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 48px;
            padding-inline: 25px;
            border-radius: 24px;
            place-self: start center;
            font-size: 20px;
            font-weight: 600;
            color: ${WHITE};
            background-color: ${BLACK};
          }
        }
      }
    }
  }

  .article {
    position: relative;
    margin-inline: 70px;

    .titlePill {
      position: absolute;
      top: 0;
      left: 0;
      padding: 15px 20px;
      border: 1px solid ${GRAY_MID};
      border-radius: 10px;
    }
  }

  .users {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10vw;
    margin-block-start: 196px;
    padding-inline: calc(125px + 5vw);

    .imageFriendsEating {
      width: 450px;
      height: 560px;
      border-radius: 30px;
      object-fit: cover;
      object-position: center;
    }

    .checkList {
      margin-block-start: 60px;
    }
  }

  .clients {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 150px;
    margin-block-start: 200px;

    .landing-title .draggableEmoji {
      &:first-of-type {
        top: 40px;
        left: 22%;
        rotate: -30deg;
      }
      &:nth-of-type(2) {
        top: 100px;
        left: 35%;
      }
      &:nth-of-type(3) {
        top: 43px;
        left: 61%;
      }
      &:last-of-type {
        top: -12px;
        left: 75%;
        rotate: 30deg;
      }
    }

    .clientsList {
      display: grid;
      grid-template: 1fr / repeat(4, minmax(0, 1fr));
      gap: 40px;

      .feekspotCard {
        position: relative;
        max-width: 415px;

        .feekspotCardBgImage {
          display: block;
          margin-block-end: 15px;
          width: 100%;
          aspect-ratio: 415 / 218;
          border-radius: 20px;
          object-fit: cover;
          object-position: left top;
        }

        .feekspotCategoryPill {
          position: absolute;
          top: 20px;
          right: 20px;
          padding: 5px 10px;
          background-color: ${PURPLE_LIGHT};
          border-radius: 16px;
        }

        .feekspotCardFooter {
          display: grid;
          grid-template:
            'avatar' 1fr
            'avatar' 1fr
            / 60px 1fr;
          gap: 3px 10px;

          .feekspotCardLogo {
            grid-area: avatar;
          }

          .feekspotCardName {
            text-transform: uppercase;
            align-self: flex-end;
          }
        }
      }
    }
  }

  .benefits {
    margin-block-start: 231px;

    .benefitsList {
      display: flex;
      justify-content: center;
      gap: 40px;
      margin: 150px 0 40px;

      .roundedCardBig {
        max-width: 415px;
        padding: 30px;

        .benefitHeader {
          display: flex;
          justify-content: space-between;
          align-items: start;
          margin-block-end: 80px;
        }

        .benefitTitle {
          width: 50%;
        }

        .benefitDescription {
          margin-block: 30px;
        }

        .benefitFooter {
          .tag {
            height: 29px;
            padding-inline: 20px;
            border: 1px solid ${BLACK};

            :not(:last-of-type) {
              margin-inline-end: 10px;
            }
          }
        }
      }
    }

    .benefitsTagsCloud {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 27px;

      .tag {
        height: 55px;
        padding-inline: 30px;
        background-color: ${PURPLE_LIGHT};
        border: none;
      }
    }
  }

  .cities {
    margin-block-start: 180px;

    .citiesList {
      margin-block-start: 120px;
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
      gap: 24px;

      .roundedCardBig {
        padding: 40px;

        .cityCategoryTags {
          min-height: 198px;
          margin: 20px 0;
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          gap: 12px 10px;

          .tag {
            height: 31px;
            padding-inline: 20px;
            border: 1px solid ${BLACK};
          }
        }

        .citiesFooter {
          display: flex;
          justify-content: space-between;

          .feekspotsCount {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            margin-inline-start: auto;
            border: 2px solid ${BLACK};
            border-radius: 50%;
          }
        }
      }
    }
  }

  .requirements {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 9vw;
    margin-block: 180px 160px;

    .photographingFood {
      width: 23.4vw;
      height: auto;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    width: 100vw;
    overflow: hidden;

    .showOnMobileOnly {
      display: revert !important;
    }

    .hideOnMobile {
      display: none !important;
    }

    .listWrapper {
      display: contents;

      & > *:not(.listWrapperTitle) {
        margin-block-start: 0;
      }

      .listWrapperTitle {
        ${getFontSizeAndLineHeigh(32)}
        text-align: start;
        place-self: start;
      }

      .checkList {
        .checkListElement {
          margin: 0;
        }
      }
    }

    .landing-title {
      ${getFontSizeAndLineHeigh(32)}
      text-align: center;
      place-self: center;
    }

    .roundedCardBig {
      width: 80vw;
      flex: 1 0 80vw;
      border-radius: 20px;
      scroll-snap-align: center;
      max-width: initial;

      :first-of-type {
        margin-inline-start: 20px;
      }

      :last-of-type {
        margin-inline-end: 20px;
      }
    }

    .header {
      top: 0;
      width: 100vw;
      padding: 20px;
      margin: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .navigation {
        .goToSignInButton {
          font-size: 17px;
        }
      }
    }

    .hero {
      padding: 129px 20px 608px;
      border-radius: 0px;
      background-color: ${GREEN_LIGHT};

      .hero-title {
        width: 100%;
        margin-block-end: 0;

        > :nth-of-type(1) {
          margin-block-end: 40px;
          ${getFontSizeAndLineHeigh(32)}
          font-weight: 600;
        }
        > :nth-of-type(2) {
          ${getFontSizeAndLineHeigh(16)}
        }
      }
    }

    .callToActionWrapper {
      flex-direction: column-reverse;
      gap: 100px;
      height: 548px;
      padding: 0 20px;

      .signInFormWrapper {
        margin: 0;
        width: 100%;

        .feekerShoppingImage {
          padding-inline: 20px;
          width: 100%;
        }

        .signInForm {
          .submitSignInFormButton {
            width: 169px;
            height: 57px;
            > div {
              font-weight: 500;
              font-size: 16px;
              line-height: 16px;
            }
          }
        }

        .successSignInMsg {
          margin: 20px;
          padding: 20px;
          border-radius: 10px;

          .successSignInMsgTextWrapper {
            > * {
              text-align: center;
            }

            .successSignInMsgButton {
              place-self: center;
              font-size: 16px;
            }
          }
        }
      }

      .knowOurFeekers {
        flex-wrap: wrap;
        gap: 20px;
        border-radius: 20px;
        padding: 20px;

        .knowOurFeekersTitle {
          flex: 1 0 100%;
          ${getFontSizeAndLineHeigh(20)}
        }

        .circularHashLink {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 47px;
          aspect-ratio: 1;
          background-color: ${WHITE_ALMOST};
        }
      }
    }

    .article {
      display: flex;
      margin: 100px 20px;
      padding: 0;
      flex-direction: column;
      gap: 40px;
      align-items: start;

      .titlePill {
        position: static;
      }
    }

    .users {
      .imageFriendsEating {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
      }
    }

    .clients {
      .landing-title .draggableEmoji {
        &:nth-of-type(2) {
          top: 140px;
          left: 2%;
        }
        &:nth-of-type(3) {
          top: 128px;
          left: 80%;
        }
        &:last-of-type {
          top: 35px;
          left: 50%;
          rotate: 30deg;
        }
      }

      .clientsList {
        display: contents;
      }
    }

    .benefits .benefitsList,
    .cities .citiesList {
      width: 100%;
      justify-content: start;
      gap: 20px;
      margin: 0;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .benefits {
      margin: 100px 0;

      .titlePill {
        margin-inline-start: 20px;
      }

      .benefitsList {
        .roundedCardBig {
          .benefitHeader {
            margin-block-end: 58px;
          }
        }
      }

      .benefitsTagsCloud {
        margin-inline: 20px;
        gap: 10px;

        .tag {
          height: 40px;
          padding-inline: 20px;
          ${getFontSizeAndLineHeigh(14)}
        }
      }
    }

    .cities {
      margin: 100px 0;

      .titlePill {
        margin-inline-start: 20px;
      }

      .citiesList {
        .roundedCardBig {
          padding: 20px;

          .cityCategoryTags {
            min-height: 273px;

            .tag {
              height: 29px;
            }
          }

          .citiesFooter {
            .feekspotsCount {
              width: 50px;
              height: 50px;
            }
          }
        }
      }
    }

    .requirements {
      .photographingFood {
        width: 100%;
        height: auto;
      }

      .landing-title {
        place-self: start;
        text-align: start;
      }
    }
  }
`;

export const landingIds = {
  signInForm: 'signInForm',
  users: 'users',
  clients: 'clients',
  benefits: 'benefits',
  cities: 'cities',
  requirements: 'requirements',
};

const formValidationSchema = Yup.object({
  name: Yup.string().required('Necesitamos el nombre de tu negocio'),
  contact: Yup.string().required('Necesitamos tu nombre completo'),
  feekspot_type: Yup.string()
    .oneOf([
      'Restaurant',
      'Coffee',
      'Bar',
      'Snacks',
      'Shopping',
      'Fitness',
      'Beauty',
      'Hobby',
    ])
    .required('Debes elegir el giro de tu negocio'),
  phone: Yup.string()
    .required('El télefono es requerido')
    .min(12, 'El télefono debe tener más de 10 numeros'),
  email: Yup.string()
    .email('Introduce una dirección de email válida')
    .required('Necesitamos tu email'),
  location: Yup.object().shape({
    formatedLocation: Yup.string().required(
      'Necesitamos la dirección aproximada de tu negocio',
    ),
  }),
});

const formInitialValues = {
  name: '',
  contact: '',
  feekspot_type: '',
  phone: '',
  email: '',
  location: '',
};

const usersTraits = [
  'Mujeres y hombres.',
  'Tienen más de 300 seguidores en Instagram.',
  'De 18 a 40 años.',
  'Viven en las principales ciudades',
  'Les gustan los restaurantes, cafeterías o bares.',
];

const feekspotTypes = [
  { value: 'Restaurant', label: 'Restaurant' },
  { value: 'Coffee', label: 'Coffee' },
  { value: 'Bar', label: 'Bar' },
  { value: 'Snacks', label: 'Snacks' },
  { value: 'Shopping', label: 'Shopping' },
  { value: 'Fitness', label: 'Fitness' },
  { value: 'Beauty', label: 'Beauty' },
  { value: 'Hobby', label: 'Hobby' },
];

const benefits = [
  {
    emoji: '👯‍♀️',
    title: 'Nuevos Clientes',
    description:
      'La exposición a través de nuestros usuarios posiciona a tu negocio en distintos sectores llenos de nuevos clientes potenciales.',
    tags: ['NC', 'Expansión'],
  },
  {
    emoji: '💸',
    title: 'Ventas Generadas',
    description:
      'Nuestro sistema atrae usuarios directamente a tu negocio; los clientes aprovechan las promociones y generan consumo.',
    tags: ['NC', 'Rentabilidad'],
  },
  {
    emoji: '📢',
    title: 'Publicidad Orgánica',
    description:
      'Nuestros usuarios comparten en redes su experiencia en tu negocio; garantizando impresiones orgánicas y genuinas entre su círculo social.',
    tags: ['NC', 'Posicionamiento'],
  },
  {
    emoji: '❤️',
    title: 'Nuevos Seguidores',
    description:
      'Los seguidores de nuestros usuarios son ahora seguidores; ven de primera mano lo que tu negocio ofrece, se enamoran y comienzan a seguirlo',
    tags: ['NC', 'Posicionamiento'],
  },
];

const benefitsTagsCloudTexts = [
  'Capacitación',
  'Soporte personalizado',
  'Notificaciones de reservación',
  'Reporte de resultados',
  'Consultoría de campañas',
  'Y muchos más...',
];

const requirements = [
  'Nombre de negocio',
  'Usuario de Instagram',
  'Teléfono',
  'Email',
  'Dirección',
  'Horario de servicio',
];

function TitlePill({ title }) {
  return (
    <Text
      className="titlePill"
      fontSize={16}
      fontWeight={500}
      color={GRAY_MID}
    >
      {title}
    </Text>
  );
}

function CheckListElement({ text }) {
  return (
    <li className="checkListElement">
      <Icon
        className="bullet"
        icon={CheckCircle}
        color={BLACK}
        size={22}
      />
      <Text className="checkListText" fontSize={16} color={BLACK}>
        {text}
      </Text>
    </li>
  );
}

function dragElement(e) {
  const emoji = e.target;
  let clientX = e.clientX;
  let clientY = e.clientY;

  e.preventDefault();

  document.onmouseup = () => {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  };

  document.onmousemove = (e) => {
    e.preventDefault();

    const finalX = clientX - e.clientX;
    const finalY = clientY - e.clientY;
    clientX = e.clientX;
    clientY = e.clientY;

    emoji.style.left = emoji.offsetLeft - finalX + 'px';
    emoji.style.top = emoji.offsetTop - finalY + 'px';
  };
}

function LandingManager() {
  const [showSignInSuccessMsg, setShowSignInSuccessMsg] =
    useState(false);
  const [totalFeekers, setTotalFeekers] = useState({
    feekersAvatars: Array(7),
    totalFeekersCount: 0,
  });
  const [feekspotsCards, setFeekspotsCards] = useState(Array(4));
  const [cities, setCities] = useState(Array(3));

  const formik = useFormik({
    validateOnMount: true,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
  });

  useEffect(() => {
    APIClient.getLandingSelectedFeekspots()
      .then(({ data }) => setFeekspotsCards(data))
      .catch(handleError);

    APIClient.getCitiesToShow()
      .then(({ data }) =>
        setCities(
          data
            .slice(0, 3)
            .map(
              ({
                location: { city: location },
                customers: feekspots,
                total,
                categories,
              }) => ({
                name: location,
                categories: categories || [
                  'Restaurante',
                  'Bar',
                  'Coffee',
                  'Snacks',
                  'Shopping',
                  'Beauty',
                ],
                feekspotsAvatars:
                  feekspots.slice(0, 7).map(({ image }) => image) ||
                  [],
                feekspotsCount: total,
              }),
            ),
        ),
      )
      .catch(handleError);

    APIClient.getFeekersToShow()
      .then(({ data: { users, total } }) =>
        setTotalFeekers({
          feekersAvatars: users.map(({ avatar }) => avatar),
          totalFeekersCount:
            total > 999
              ? `+${Math.floor(total / 1000)}k`
              : total > 99
              ? `+${Math.floor(total / 10) * 10}`
              : total,
        }),
      )
      .catch(handleError);
  }, []);

  function handleSubmit(values, actions) {
    actions.setSubmitting(true);
    const {
      administrative_area_level_1: state,
      street_number: exterior_number,
      locality: city,
      postal_code: cp,
      sublocality_level_1: suburb,
      route: street,
      country: country,
    } = convertGooglePlaceToAddress(
      values.location.addressComponents,
    );

    APIClient.feekspotSignInRequest({
      ...values,
      location: {
        state: state,
        exterior_number: exterior_number,
        city: city,
        cp: cp,
        suburb: suburb,
        street: street,
        country: country,
        coordinates: [values.location.lat, values.location.lng],
      },
    })
      .then(() => {
        setShowSignInSuccessMsg(true);
        actions.resetForm(values);
      })
      .catch(handleError)
      .finally(() => actions.setSubmitting(false));
  }

  document.title = titlesFeekApp.LandingManager;

  return (
    <div css={styles}>
      <header className="header">
        <div className="brandWrapper">
          <Icon size={60} icon={FeekLogo} color={PURPLE_FEEK} />
          <Slogan
            className="hideOnMobile"
            color={GRAY_DARK}
            fontSize={20}
            type="inline"
          />
        </div>

        <nav className="navigation">
          <HashLink
            className="navButton hideOnMobile"
            elementId={landingIds.users}
            smooth
          >
            Usuarios
          </HashLink>

          <HashLink
            className="navButton hideOnMobile"
            elementId={landingIds.clients}
            smooth
          >
            Nuestros clientes
          </HashLink>

          <HashLink
            className="navButton hideOnMobile"
            elementId={landingIds.benefits}
            smooth
          >
            Beneficios
          </HashLink>

          <HashLink
            className="navButton hideOnMobile"
            elementId={landingIds.cities}
            smooth
          >
            Ciudades
          </HashLink>

          <HashLink
            className="navButton hideOnMobile"
            elementId={landingIds.requirements}
            smooth
          >
            Requerimientos
          </HashLink>

          <HashLink
            className="goToSignInButton navButton"
            elementId={landingIds.signInForm}
            smooth
          >
            Registra tu negocio
          </HashLink>
        </nav>
      </header>

      <section className="hero">
        <div className="hero-title">
          <Text color={BLACK} fontSize={93} fontWeight={300}>
            Llega a más personas y consigue más clientes
          </Text>

          <Text color={BLACK} fontSize={32}>
            Registra tu negocio y consigue llegar a más personas con
            la app de <b>Feek</b>.
          </Text>
        </div>

        <HashLinkArrowButton
          className="hideOnMobile"
          color={BLACK}
          width={213}
          height={48}
          arrowColor={WHITE}
          label="Scroll down"
          fontSize={16}
          elementId={landingIds.users}
        />
      </section>

      <div className="callToActionWrapper">
        <article className="knowOurFeekers">
          <Text
            className="knowOurFeekersTitle"
            fontSize={24}
            color={BLACK}
          >
            Conoce a nuestros usuarios
          </Text>

          <AvatarsCabinet
            text={totalFeekers.totalFeekersCount.toString()}
            avatars={totalFeekers.feekersAvatars}
          />

          <HashLink
            elementId={landingIds.users}
            smooth
            className="circularHashLink"
          >
            <Icon icon={Arrow} rotate={90} color={BLACK} size={24} />
          </HashLink>
        </article>

        <div id={landingIds.signInForm} className="signInFormWrapper">
          <FeekerShopping
            width={null}
            height={null}
            className="feekerShoppingImage"
            alt="Regístrese en Feek"
          />

          <FormikProvider value={formik}>
            <Form className="signInForm">
              <FormInputText
                inputClassName="signInFormInput"
                name="contact"
                placeholder="Nombre completo"
              />

              <FormInputText
                inputClassName="signInFormInput"
                name="email"
                placeholder="Email"
              />

              <FormPhoneInput
                inputClassName="signInFormInput"
                name="phone"
              />

              <FormPlacesAutoCompleteInput
                placeholder="Dirección del negocio"
                inputClassName="signInFormInput"
                name="location"
              />

              <FormInputText
                inputClassName="signInFormInput"
                name="name"
                placeholder="Nombre de tu negocio "
              />

              <FormInputSelect
                name="feekspot_type"
                placeholder="Seleccionar giro"
                options={feekspotTypes}
                className="feekspotTypeWrapper"
                inputClassName="signInFormInput"
              />

              <ButtonPrimary
                disabled={
                  !formik.dirty ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                onClick={formik.submitForm}
                label="Enviar solicitud"
                fontSize={20}
                textColor={WHITE}
                color={BLACK}
                textProps={{ fontWeight: 500 }}
                className="submitSignInFormButton"
              />
            </Form>
          </FormikProvider>

          {showSignInSuccessMsg && (
            <aside className="successSignInMsg">
              <div className="successSignInMsgTextWrapper">
                <Text color={BLACK} fontSize={24} fontWeight="600">
                  ¡Mensaje enviado con éxito!
                </Text>
                <Text color={BLACK} fontSize={16}>
                  En breve uno de nuestros ejecutivos se pondrá en
                  contacto contigo
                </Text>
                <Button
                  onClick={() => setShowSignInSuccessMsg(false)}
                  className="successSignInMsgButton"
                >
                  Entendido
                </Button>
              </div>

              <FeekerBehindPhone className="hideOnMobile" />
            </aside>
          )}
        </div>
      </div>

      <article id={landingIds.users} className="article users">
        <TitlePill title="Usuarios" />

        <picture>
          <source srcSet={FriendsEating} />
          <img
            className="imageFriendsEating"
            src={FriendsEatingPNG}
            alt="¿Quién usa Feek?"
          />
        </picture>

        <div className="listWrapper">
          <Text
            className=" title listWrapperTitle"
            color={BLACK}
            fontWeight={500}
            fontSize={64}
          >
            Nuestros usuarios son
          </Text>
          <ul className="checkList">
            {usersTraits.map((trait, index) => (
              <CheckListElement key={index} text={trait} />
            ))}
          </ul>
        </div>
      </article>

      <article id={landingIds.clients} className="article clients">
        <TitlePill title="Clientes" />

        <Text
          align="center"
          color={BLACK}
          fontWeight={500}
          fontSize={64}
          className="landing-title"
        >
          Clientes que confían <br />
          en <b>Feek</b>
          <div
            onMouseDown={dragElement}
            className="draggableEmoji hideOnMobile"
            role="presentation"
          >
            🫶🏻
          </div>
          <div
            onMouseDown={dragElement}
            className="draggableEmoji"
            role="presentation"
          >
            ✨
          </div>
          <div
            onMouseDown={dragElement}
            className="draggableEmoji"
            role="presentation"
          >
            🪅
          </div>
          <div
            onMouseDown={dragElement}
            className="draggableEmoji"
            role="presentation"
          >
            😍
          </div>
        </Text>

        <ul className="clientsList">
          {feekspotsCards?.map((feekspot) => (
            <li key={feekspot?._id} className="feekspotCard">
              <img
                className="feekspotCardBgImage"
                src={feekspot?.background_image}
                alt={'Feek ' + feekspot?.name}
              />

              <Text
                className="feekspotCategoryPill"
                fontSize={16}
                fontWeight={500}
              >
                {feekspot?.categories[0].name}
              </Text>

              <footer className="feekspotCardFooter">
                <Avatar
                  className="feekspotCardLogo"
                  image={feekspot?.image}
                  size={60}
                  sizeMobile={60}
                  name={feekspot?.name}
                  alt={'Feek ' + feekspot?.name}
                />

                <Text
                  className="feekspotCardName"
                  fontSize={20}
                  fontWeight={700}
                  color={BLACK}
                >
                  {feekspot?.name}
                </Text>

                <Text fontSize={14} fontWeight={500} color={GRAY}>
                  {feekspot?.location?.city},{' '}
                  {feekspot?.location?.state}
                </Text>
              </footer>
            </li>
          ))}
        </ul>
      </article>

      <article id={landingIds.benefits} className="article benefits">
        <TitlePill title="Beneficios" />

        <Text
          align="center"
          color={BLACK}
          fontWeight={500}
          fontSize={64}
          className="landing-title"
        >
          Beneficios
          <br />
          que ofrecemos
        </Text>

        <ol className="benefitsList">
          {benefits.map((benefit, index) => (
            <li key={index} className="roundedCardBig">
              <header className="benefitHeader">
                <Text type="inline" fontSize={60}>
                  {benefit.emoji}
                </Text>
                <Text type="inline" fontSize={48} color={BLACK}>
                  0{index + 1}
                </Text>
              </header>

              <Text
                color={BLACK}
                className="benefitTitle"
                fontSize={32}
              >
                {benefit.title}
              </Text>

              <Text
                className="benefitDescription"
                color={BLACK}
                fontSize={16}
              >
                {benefit.description}
              </Text>

              <footer className="benefitFooter">
                {benefit.tags?.map((tag, index) => (
                  <Text
                    key={tag + index}
                    className="tag"
                    fontSize={16}
                    color={BLACK}
                    type="inline"
                  >
                    {tag}
                  </Text>
                ))}
              </footer>
            </li>
          ))}
        </ol>

        <ul className="benefitsTagsCloud">
          {benefitsTagsCloudTexts.map((tag, index) => (
            <Text
              className="tag"
              key={index}
              fontSize={24}
              color={BLACK}
              align="center"
            >
              {tag}
            </Text>
          ))}
        </ul>
      </article>

      <article id={landingIds.cities} className="article cities">
        <TitlePill title="Ciudades" />

        <Text
          align="center"
          color={BLACK}
          fontWeight={500}
          fontSize={64}
          className="landing-title"
        >
          Ciudades en donde
          <br />
          encuentras <b>Feek</b>
        </Text>

        <ul className="citiesList">
          {cities
            .slice(0, 3)
            .map(
              (
                {
                  name,
                  categories,
                  feekspotsAvatars,
                  feekspotsCount,
                },
                index,
              ) => (
                <li key={index} className="roundedCardBig">
                  <Text
                    className="cityName"
                    fontSize={32}
                    fontWeight={500}
                    color={BLACK}
                  >
                    {name}
                  </Text>

                  <ul className="cityCategoryTags">
                    {categories?.map((category, index) => (
                      <Text
                        className="tag"
                        key={index}
                        fontSize={16}
                        color={BLACK}
                        align="center"
                      >
                        {category}
                      </Text>
                    ))}
                  </ul>

                  <footer className="citiesFooter">
                    <AvatarsCabinet
                      overlap={22}
                      avatars={feekspotsAvatars?.slice(0, 7)}
                    />

                    <Text
                      type="inline"
                      fontSize={16}
                      fontWeight={600}
                      className="feekspotsCount"
                    >
                      +{feekspotsCount || feekspotsAvatars.length}
                    </Text>
                  </footer>
                </li>
              ),
            )}
        </ul>
      </article>

      <article
        id={landingIds.requirements}
        className="article requirements"
      >
        <TitlePill title="Requerimientos" />

        <img
          className="photographingFood"
          src={PhotographingFood}
          alt="El registro en Feek es muy sencillo"
        />

        <div className="listWrapper">
          <Text
            className="landing-title listWrapperTitle"
            color={BLACK}
            fontSize={48}
          >
            Anunciarte en <b>Feek</b>
            <br />
            es muy fácil
          </Text>
          <Text color={BLACK} fontSize={24}>
            Solo necesitamos los siguientes datos
          </Text>

          <ul className="checkList">
            {requirements.map((trait, index) => (
              <CheckListElement key={index} text={trait} />
            ))}
          </ul>
        </div>
      </article>

      <FooterLanding />
    </div>
  );
}

TitlePill.propTypes = {
  title: PropTypes.string.isRequired,
};

CheckListElement.propTypes = {
  text: PropTypes.string.isRequired,
};

export default LandingManager;
