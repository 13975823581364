/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';

import APIClient from 'redux/api';
import Text from 'components/Text';
import { authSteps } from 'constants';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_AGNOSTIC } from 'constants/routes';
import ButtonBack from 'components/buttons/ButtonBack';
import { BLACK, PURPLE_INACTIVE } from 'styles/colors';
import { FormInputText } from 'components/inputs/InputText';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import ProgressLinearBar from 'components/ProgressLinearBar';
import { FormInputPhone } from 'components/inputs/InputPhone';
import { FormInputPassword } from 'components/inputs/InputPassword';
import { FormInputCheckbox } from 'components/inputs/InputCheckbox';

const styles = css`
  .form-wrapper {
    > * {
      margin-block: 16px;
    }

    .password-safety-indicator {
      > * {
        margin-block: 8px;
        line-height: 100%;
      }
    }

    .checkbox {
      margin-block: 20px;
    }

    .button-submit {
      margin: 0 auto 32px;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    .form-wrapper {
      > * {
        margin-block: 16px;
      }

      .password-safety-indicator {
        > * {
          margin-block: 8px;
          line-height: 100%;
        }
      }

      .checkbox {
        margin-block: 20px;
      }

      .button-submit {
        margin: 0 auto 20px;
      }
    }
  }
`;

const registrationInitValues = {
  phone: '',
  username: '',
  password: '',
  termsConditions: false,
};

const registrationValidateSchema = Yup.object({
  phone: Yup.string()
    .required('El télefono es requerido')
    .min(10, 'El télefono tiene que tener al menos 10 numeros'),
  username: Yup.string().required('El usuario es requerido'),
  password: Yup.string()
    .required('La contraseña es requerida')
    .min(6, 'Debe tener al menos 6 caracteres')
    .matches(/^(?=.*[A-Z])/, 'Debe contener al menos una mayúscula')
    .matches(/^(?=.*[0-9])/, 'Debe contener al menos un número'),
  termsConditions: Yup.boolean().oneOf(
    [true],
    'Debes aceptar los términos y condiciones',
  ),
});

const FormRegistration = ({
  onBackButton,
  setCurrentStep,
  userCredentials,
  setUserCredentials,
}) => {
  const [error, setError] = useState(null);

  const handleSubmit = async (values, actions) => {
    setError(null);
    actions.setSubmitting(true);

    try {
      const { data } = await APIClient.createFeekerIntent({
        ...values,
        phone: `+${values.phone.trim()}`,
      });
      if (data.success) {
        setUserCredentials({
          ...userCredentials,
          ...values,
        });
        setCurrentStep(authSteps.CODE_VERIFICATION);
      } else {
        throw new Error('Hubo un error inesperado');
      }
    } catch (e) {
      actions.setSubmitting(false);
      if (e.message.includes(values.username)) {
        actions.setErrors({
          username: `El usuario ${values.username} ya existe`,
        });
      } else if (e.message.includes(values.phone)) {
        actions.setErrors({
          phone: `El teléfono ${userCredentials.phoneFormat} ya existe`,
        });
      } else {
        setError(e.message);
      }
    }
  };

  return (
    <article css={styles}>
      <Text
        color={BLACK}
        fontSize={24}
        fontSizeMobile={16}
        align="center"
        fontWeight={600}
        className="title"
      >
        Crea tu usuario
      </Text>

      <Formik
        validateOnMount
        onSubmit={handleSubmit}
        initialValues={registrationInitValues}
        validationSchema={registrationValidateSchema}
      >
        {(formik) => {
          return (
            <Form className="form-wrapper">
              <FormInputText
                heightMobile={60}
                placeholder="Usuario"
                name="username"
              />

              <FormInputPhone
                heightMobile={60}
                label="fuck"
                className="input-phone"
                name="phone"
                onChange={(value, data) => {
                  setUserCredentials({
                    ...userCredentials,
                    phoneFormat: `+${data.dialCode} ${value.slice(
                      data.dialCode.length,
                    )}`,
                  });
                }}
              />

              <FormInputPassword
                placeholder="Contraseña"
                name="password"
                error={error}
              />

              <div className="password-safety-indicator">
                <Text
                  fontSize={12}
                  lineHeight={14}
                  align="center"
                  color={BLACK}
                >
                  Seguridad de contraseña
                </Text>
                <ProgressLinearBar
                  progress={
                    [
                      formik.values.password.length > 6,
                      formik.values.password.match(/[0-9]/),
                      formik.values.password.match(/[A-Z]/g),
                    ].filter((value) => Boolean(value)).length * 33.33
                  }
                />
                <Text
                  fontSize={12}
                  align="center"
                  lineHeight={17}
                  color={PURPLE_INACTIVE}
                >
                  Debe tener al menos 6 caractéres, una mayúscula y un
                  número.
                </Text>
              </div>

              <FormInputCheckbox
                className="checkbox"
                checked={formik.values.termsConditions}
                name="termsConditions"
                onChange={(e) => {
                  formik.setFieldValue(
                    'termsConditions',
                    e.target.checked,
                  );
                }}
                label={
                  <Text color={BLACK} fontSize={14}>
                    Aceptar{' '}
                    <Link
                      to={ROUTES_AGNOSTIC.TERMS_AND_CONDITIONS}
                      target="_blank"
                    >
                      Términos
                    </Link>
                    {' y '}
                    <Link to={ROUTES_AGNOSTIC.POLICY} target="_blank">
                      Políticas de Privacidad
                    </Link>
                  </Text>
                }
              />

              <ButtonPrimary
                color={BLACK}
                pillShape
                className="button-submit"
                label={'Registrarme'}
                onClick={formik.submitForm}
                textProps={{ fontSize: 16, fontWeight: 600 }}
                disabled={!formik.isValid || formik.isSubmitting}
              />

              <ButtonBack
                onClick={() => {
                  formik.resetForm();
                  onBackButton?.();
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </article>
  );
};

FormRegistration.propTypes = {
  onBackButton: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  userCredentials: PropTypes.object.isRequired,
  setUserCredentials: PropTypes.func.isRequired,
};

export default FormRegistration;
