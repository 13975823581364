/** @jsxImportSource @emotion/react */
import {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import {
  WHITE,
  BLACK,
  GRAY_LIGHT,
  PURPLE_LIGHT,
} from 'styles/colors';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Button from 'components/buttons/Button';
import InputText from 'components/inputs/InputText';
import { ReactComponent as Location } from 'assets/icons/Location.svg';
import ProgressIndicatorSpinner from 'components/ProgressIndicatorSpinner';
import { ReactComponent as LocationMini } from 'assets/icons/LocationMini.svg';

const styles = css`
  width: 100%;
  height: fit-content;

  .error-text {
    min-height: 0px;
    display: none;
    margin: 3px 0;
  }

  .input {
    --border-radius: 10px;
  }

  .places-container {
    background-color: ${WHITE};
    border: 1px solid ${GRAY_LIGHT};
    border-radius: 16px;
    padding: 0px;

    > .place-item {
      padding: 10px 0;
      border-bottom: 1px solid ${GRAY_LIGHT};
      padding-top: 16px;
      padding-left: 20px;
      padding-right: 20px;

      :hover {
        background-color: ${PURPLE_LIGHT};
      }
      :last-of-type {
        border-bottom: none;
        padding-bottom: 20px;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }
      :first-of-type {
        padding-top: 20px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
    }
  }

  .load {
    margin: 0 auto;
    margin-top: 8px;
  }
`;

const InputLocationAutoComplete = forwardRef((props, ref) => {
  const {
    limit,
    className,
    placeholder,
    onInputChange,
    onPlaceSelected,
    hideLoadingIndicator,
  } = props;
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [showResults, setShowResults] = useState(false);

  const onPlaceSelect = (item) => {
    setShowResults(false);
    placesService?.getDetails(
      {
        placeId: item.place_id,
      },
      (placeDetails) => {
        onPlaceSelected(placeDetails);
      },
    );
  };

  const renderItem = (item, i) => (
    <Button
      onClick={() => onPlaceSelect(item)}
      className="place-item"
      key={i}
    >
      <Text color={BLACK} fontWeight={700} fontSize={12}>
        {item.structured_formatting.main_text}
      </Text>
      <Text color={BLACK} fontSize={12}>
        {item.structured_formatting.secondary_text}
      </Text>
    </Button>
  );

  useImperativeHandle(ref, () => ({
    reset,
  }));

  const reset = () => {
    inputRef.current.value = '';
    getPlacePredictions({
      input: '',
    });
    onInputChange?.('');
  };

  const inputRef = useRef(null);

  return (
    <div className={className} css={styles}>
      <InputText
        icon={
          <Icon
            icon={Location}
            iconMobile={LocationMini}
            height={22}
          />
        }
        className="input"
        height={60}
        ref={inputRef}
        placeholder={placeholder}
        onChange={(evt) => {
          setShowResults(true);
          onInputChange?.(evt.target.value);
          getPlacePredictions({
            input: evt.target.value,
            componentRestrictions: {
              country: 'mx',
            },
          });
        }}
      />
      {isPlacePredictionsLoading && !hideLoadingIndicator ? (
        <ProgressIndicatorSpinner className="load" />
      ) : placePredictions.length > 0 && showResults ? (
        <div className="places-container">
          {placePredictions
            .slice(0, limit || placePredictions.length)
            .map(renderItem)}
        </div>
      ) : null}
    </div>
  );
});

InputLocationAutoComplete.propTypes = {
  limit: PropTypes.number,
  className: PropTypes.string,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  hideLoadingIndicator: PropTypes.bool,
  onPlaceSelected: PropTypes.func.isRequired,
};
InputLocationAutoComplete.displayName = 'InputLocationAutoComplete';

export default InputLocationAutoComplete;
