/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';

import APIClient from 'redux/api';
import { titlesFeekApp } from 'constants';
import handleError from 'utils/handleError';
import { GRAY_LIGHTEST } from 'styles/colors';
import { ROUTES_FEEKAPP } from 'constants/routes';
import NoDataMessage from 'components/NoDataMessage';
import TabButton from 'screens/feekApp/Agenda/TabButton';
import CardParticipationSmall from 'components/cards/CardParticipationSmall';
import ProgressIndicatorSpinnerMessage from 'components/ProgressIndicatorSpinnerMessage';

const styles = css`
  hr.separator {
    margin: 0;
    border: 1px solid ${GRAY_LIGHTEST};
  }

  .content {
    padding: 36px;
  }
`;
export default function Participations() {
  const [loading, setLoading] = useState(true);
  const [tabActive, setTabActive] = useState('ACTIVE');
  const [participations, setParticipations] = useState({
    ACTIVE: null,
    EXPIRED: null,
  });

  document.title = titlesFeekApp.AgendaRecompensas;

  useEffect(() => {
    if (participations[tabActive] !== null) return;
    setLoading(true);
    APIClient.getParticipationsByUser({ status: tabActive })
      .then(({ data }) =>
        setParticipations((crrPromos) => ({
          ...crrPromos,
          [tabActive]: data.docs,
        })),
      )
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [tabActive]);

  return (
    <div css={styles}>
      <div className="d-flex justify-content-center">
        <TabButton
          label="Activas"
          isActive={tabActive === 'ACTIVE'}
          onClick={() => setTabActive('ACTIVE')}
        />
        <TabButton
          label="Finalizadas"
          isActive={tabActive === 'EXPIRED'}
          onClick={() => setTabActive('EXPIRED')}
        />
      </div>

      <hr className="separator" />

      <div className="content">
        {loading ? (
          <ProgressIndicatorSpinnerMessage className="my-3" />
        ) : participations?.[tabActive]?.length > 0 ? (
          participations[tabActive].map(
            ({ participation, experience }) => (
              <CardParticipationSmall
                className="mb-3"
                key={participation._id}
                title={experience.title}
                image={experience.image[0]}
                experienceId={experience._id}
                status={participation.status}
                eventDate={experience.event_date}
                eventTime={experience.event_time}
                ranking={participation.statistics.ranking}
              />
            ),
          )
        ) : (
          <NoDataMessage
            emoji={tabActive === 'ACTIVE' ? '😮' : '😳'}
            buttonLabel="Explorar Recompensas"
            redirectTo={ROUTES_FEEKAPP.EXPLORE}
            message={`No ${
              tabActive === 'ACTIVE'
                ? 'estás participando'
                : 'has participado'
            } por Recompensas.`}
          />
        )}
      </div>
    </div>
  );
}
