/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  GRAY_TEXT,
  GREEN_LIGHT,
  YELLOW_LIGHT,
  GRAY_LIGHTER,
  GRAY_LIGHTEST,
} from 'styles/colors';
import Icon from 'components/Icon';
import Text from 'components/Text';
import Button from 'components/buttons/Button';
import { propTypeLength } from 'utils/propValidation';
import { getLength } from 'utils/cssStringManipulation';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import { ReactComponent as WarningCircleBicolor } from 'assets/icons/WarningCircleBicolor.svg';
import { ReactComponent as CheckCircleDoubleBicolor } from 'assets/icons/CheckCircleDoubleBicolor.svg';

const AccordionStatus = ({
  icon,
  title,
  status,
  content,
  className,
  titleProps,
  height = 45,
  width = 'auto',
}) => {
  const [isActive, setIsActive] = useState(false);

  const finalHeight = getLength(height);

  const styles = css`
    display: grid;
    grid-template-columns: ${icon || status !== undefined
      ? 'auto 1fr auto'
      : '1fr auto'};
    grid-template-rows: ${isActive
      ? finalHeight + ' auto'
      : finalHeight};
    grid-template-areas: ${isActive
      ? `"${
          icon || status !== undefined ? 'statusIcon ' : ''
        }title chevron"
      "${
        icon || status !== undefined
          ? '. content ."'
          : 'content content"'
      }`
      : `"${
          icon || status !== undefined ? 'statusIcon ' : ''
        }title chevron"`};
    justify-items: start;
    align-items: center;
    column-gap: 12px;
    background: ${GRAY_LIGHTEST};
    border-radius: 8px;
    border: 1px ${GRAY_LIGHTER} solid;
    padding: 0 13px;
    position: relative;
    ${getLength({ width })};
    overflow: hidden;

    .summaryTouch {
      position: absolute;
      height: ${finalHeight};
      width: 100%;
      top: 0;
      z-index: 1;
    }

    .statusIcon {
      grid-area: statusIcon;
      justify-self: self-start;
    }

    .title {
      grid-area: title;
      justify-self: self-start;
    }

    .chevron {
      grid-area: chevron;
      transform: ${isActive ? 'rotate(-180deg)' : 'rotate(0deg)'};
      transition: transform ease-in-out 0.2s;
      justify-self: self-end;
    }

    .content {
      grid-area: content;
      padding-block-end: 13px;
    }
  `;

  return (
    <div css={styles} className={className}>
      <Button
        className="summaryTouch"
        onClick={() => setIsActive(!isActive)}
      ></Button>

      {icon
        ? icon
        : status !== undefined &&
          (status ? (
            <Icon
              className="statusIcon"
              icon={CheckCircleDoubleBicolor}
              color={GREEN_LIGHT}
              secondaryColor={GRAY_TEXT}
              size={32}
            />
          ) : (
            <Icon
              className="statusIcon"
              icon={WarningCircleBicolor}
              color={YELLOW_LIGHT}
              secondaryColor={GRAY_TEXT}
              size={32}
            />
          ))}
      {typeof title === 'string' ? (
        <Text
          className="title"
          fontSize={14}
          lineHeight={finalHeight}
          {...titleProps}
        >
          {title}
        </Text>
      ) : (
        title
      )}

      <Icon className="chevron" icon={Chevron} size={12} />

      {isActive && <div className="content">{content}</div>}
    </div>
  );
};

AccordionStatus.propTypes = {
  width: propTypeLength,
  height: propTypeLength,
  status: PropTypes.bool,
  icon: PropTypes.shape({
    type: PropTypes.oneOf([Icon]),
  }),
  className: PropTypes.string,
  titleProps: PropTypes.shape(Text.propTypes),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
export default AccordionStatus;
