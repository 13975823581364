/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  BLACK,
  WHITE,
  GRAY_MID,
  GRAY_TEXT,
  GRAY_DARK,
  PURPLE_LIGHT,
  GRAY_LIGHTEST,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import { setCampaigns } from 'redux/entities';
import Button from 'components/buttons/Button';
import { ReactComponent as X } from 'assets/icons/X.svg';
import { ReactComponent as Plus } from 'assets/icons/Plus.svg';
import ImagePlaceholder from 'assets/images/ImagePlaceholder.jpg';
import CardCampaignSmall from 'components/cards/CardCampaignSmall';
import ButtonLikeFeekspot from 'components/buttons/ButtonLikeFeekspot';

const styles = css`
  position: relative;

  .campaigns-container {
    top: 70px;
    z-index: 1;
    width: 100%;
    overflow: hidden;
    position: absolute;
    border-radius: 30px;
    background-color: ${WHITE};
    border: 1px solid ${GRAY_LIGHTEST};
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .campaigns {
    padding: 20px;
    max-height: 480px;
    overflow-y: auto;
  }

  .close {
    width: 32px;
    height: 32px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid ${GRAY_MID};
  }

  .image {
    width: 100%;
    height: 218px;
    user-select: none;
    border-radius: 20px;
    pointer-events: none;
    object-fit: cover;
    -webkit-user-select: none;
    background-repeat: no-repeat;
    border: 2px solid ${GRAY_LIGHTEST};
  }

  .category-name {
    padding: 5px 12px;
    border-radius: 16px;
    background-color: ${PURPLE_LIGHT};
  }

  .circle-icon {
    width: 32px;
    height: 32px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid ${GRAY_MID};
  }

  .like-button {
    position: absolute;
    top: 17px;
    right: 16px;
  }
`;
export default function FeekspotCampaign({
  isLiked,
  category,
  campaigns,
  feekspotId,
  feekspotName,
  backgroundImage,
  likeButtonOnClick,
}) {
  const [campaignsOpen, setCampaignsOpen] = useState(false);

  const dispatch = useDispatch();

  const categories = useSelector(
    (state) => state.entities.categories,
  );

  useEffect(() => {
    if (campaignsOpen) {
      APIClient.getManyCampaigns(campaigns).then(({ data }) => {
        dispatch(setCampaigns(data));
      });
    }
  }, [campaignsOpen]);

  return (
    <div css={styles}>
      {campaignsOpen && (
        <div className="campaigns-container">
          <div className="campaigns">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                {categories[category] && (
                  <div className="category-name">
                    <Text
                      fontSize={16}
                      fontWeight={500}
                      color={GRAY_DARK}
                    >
                      {categories[category]?.name}
                    </Text>
                  </div>
                )}
              </div>

              <Button
                className="d-flex align-items-center"
                onClick={() => setCampaignsOpen(false)}
              >
                <Text
                  fontSize={16}
                  color={GRAY_TEXT}
                  fontWeight={500}
                >
                  Cerrar
                </Text>
                <div className="close ms-2">
                  <Icon icon={X} size={14}>
                    X
                  </Icon>
                </div>
              </Button>
            </div>

            {(campaigns || []).map((campaign) => (
              <div className="mb-3" key={campaign}>
                <CardCampaignSmall campaign={campaign} />
              </div>
            ))}
          </div>
        </div>
      )}
      <ButtonLikeFeekspot
        iconSize={24}
        isLiked={isLiked}
        feekspotId={feekspotId}
        {...(likeButtonOnClick && { onClick: likeButtonOnClick })}
        className="like-button"
      />
      <img
        src={backgroundImage ? backgroundImage : ImagePlaceholder}
        className="image mb-3"
        alt={'Feek ' + feekspotName}
      />
      <Text
        color={BLACK}
        fontSize={20}
        fontWeight={700}
        className="mb-2"
      >
        {feekspotName}
      </Text>
      <Text
        fontSize={16}
        fontWeight={700}
        className="mb-3 pb-1"
        color={GRAY_DARK}
      >
        {campaigns.length} Promocion
        {campaigns.length !== 1 && 'es'} disponible
        {campaigns.length !== 1 && 's'}
      </Text>
      <div className="d-flex justify-content-between align-items-center">
        {categories[category] && (
          <div className="category-name">
            <Text fontSize={16} fontWeight={700} color={GRAY_DARK}>
              {categories[category]?.name}
            </Text>
          </div>
        )}

        <Button
          className="d-flex justify-content-between align-items-center"
          onClick={() => setCampaignsOpen(!campaignsOpen)}
        >
          <Text fontSize={16} color={BLACK} fontWeight={500}>
            Ver promociones
          </Text>
          <div className="circle-icon ms-2">
            <Icon icon={Plus} size={14} color={BLACK} />
          </div>
        </Button>
      </div>
    </div>
  );
}

FeekspotCampaign.propTypes = {
  isLiked: PropTypes.bool,
  category: PropTypes.string,
  likeButtonOnClick: PropTypes.func,
  backgroundImage: PropTypes.string,
  campaigns: PropTypes.array.isRequired,
  feekspotId: PropTypes.string.isRequired,
  feekspotName: PropTypes.string.isRequired,
};
