/** @jsxImportSource @emotion/react */
import {
  useInfiniteHits,
  useRefinementList,
  useClearRefinements,
} from 'react-instantsearch-hooks';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setFeekspots,
  setCategories,
  setCampaignTypes,
} from 'redux/entities';
import APIClient from 'redux/api';
import Text from 'components/Text';
import FilterItem from 'components/FilterItem';
import { BLACK, GRAY_DARK } from 'styles/colors';

const styles = css`
  .cursor-pointer {
    cursor: pointer;
    user-select: none;
  }
  .underline {
    text-decoration: underline;
  }
`;

export default function CampaignFilters() {
  const { categories, campaignTypes, feekspots } = useSelector(
    (state) => state.entities,
  );

  const [typesFilter, setTypesFilter] = useState([]);
  const [categoriesFilter, setCategoriesFilter] = useState([]);

  const dispatch = useDispatch();

  const { results } = useInfiniteHits();

  const campaignTypesRefinements = useRefinementList({
    attribute: 'type',
    operator: 'or',
  });
  const categoriesRefinements = useRefinementList({
    attribute: 'categories',
    operator: 'or',
  });

  const clearTypesRefinement = useClearRefinements({
    includedAttributes: ['type'],
  });
  const clearCategoriesRefinement = useClearRefinements({
    includedAttributes: ['categories'],
  });

  useEffect(() => {
    APIClient.getCampaignTypes().then(({ data }) => {
      dispatch(setCampaignTypes(data));
    });
    APIClient.getCategories().then(({ data }) => {
      dispatch(setCategories(data));
    });
  }, []);

  useEffect(() => {
    if (typesFilter.length === 0) {
      clearTypesRefinement.refine();
    } else {
      typesFilter.forEach((type) => {
        campaignTypesRefinements.refine(type);
      });
    }
  }, [typesFilter]);

  useEffect(() => {
    if (typesFilter.length === 0) {
      clearCategoriesRefinement.refine();
    } else {
      categoriesFilter.forEach((category) => {
        categoriesRefinements.refine(category);
      });
    }
  }, [categoriesFilter]);

  useEffect(() => {
    results.hits.forEach((campaign) => {
      APIClient.getFeekspotForFeeker(campaign.customer._id).then(
        ({ data }) => {
          dispatch(setFeekspots([{ customer: data }]));
        },
      );
    });
  }, [results]);

  const clearFilters = () => {
    setCategoriesFilter?.([]);
    setTypesFilter?.([]);
  };

  return (
    <div css={styles}>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Text color={BLACK} fontSize={24} fontWeight={600}>
          Filtros
        </Text>
        <Text
          onClick={clearFilters}
          color={GRAY_DARK}
          fontSize={16}
          className="cursor-pointer underline"
        >
          Borrar filtros
        </Text>
      </div>
      <Text
        color={BLACK}
        fontSize={16}
        fontWeight={600}
        className="mb-1"
      >
        Tipo de oferta
      </Text>

      {Object.values(campaignTypes).map((type) => (
        <FilterItem
          key={type._id}
          label={type.name}
          checked={typesFilter.includes(type._id)}
          counter={
            results?.hits?.filter(
              (campaign) => campaign.type === type._id,
            ).length
          }
          onChange={(checked) =>
            checked
              ? setTypesFilter([...typesFilter, type._id])
              : setTypesFilter(
                  typesFilter.filter((cat) => cat !== type._id),
                )
          }
        />
      ))}
      {Object.values(campaignTypes).length === 0 && (
        <Text>Cargando...</Text>
      )}

      <Text
        color={BLACK}
        fontSize={16}
        fontWeight={600}
        className="mb-1 mt-4 pt-3"
      >
        Categoría
      </Text>

      <form>
        {Object.values(categories).map((category) => (
          <FilterItem
            key={category._id}
            label={category.name}
            checked={categoriesFilter.includes(category._id)}
            onChange={(checked) =>
              checked
                ? setCategoriesFilter([
                    ...categoriesFilter,
                    category._id,
                  ])
                : setCategoriesFilter(
                    categoriesFilter.filter(
                      (cat) => cat !== category._id,
                    ),
                  )
            }
            counter={
              results?.hits?.filter(
                (campaign) =>
                  feekspots[campaign?.customer?._id]?.customer
                    ?.categories?.[0] === category._id,
              ).length
            }
          />
        ))}
        {Object.values(categories).length === 0 && (
          <Text>Cargando...</Text>
        )}
      </form>
    </div>
  );
}
