/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';

import APIClient from 'redux/api';
import Text from 'components/Text';
import { BLACK } from 'styles/colors';
import handleError from 'utils/handleError';
import ButtonBack from 'components/buttons/ButtonBack';
import { FormInputText } from 'components/inputs/InputText';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { FormPhoneInput } from 'components/inputs/PhoneInput';

const styles = css`
  .mt-32 {
    margin-top: 32px;
  }

  .mb-32 {
    margin-bottom: 32px;
  }

  .mbl-16 {
    margin-block: 16px;
  }

  .br-10 {
    &,
    & .inputContainer {
      border-radius: 10px;
    }
  }
`;

const formInitialValue = {
  phone: '',
  email: '',
};

const formValidationSchema = Yup.object().shape({
  email: Yup.string()
    .test(
      'one-not-empty',
      'Uno de los datos es requerido',
      function (value) {
        const phone = this.parent.phone || '';
        return !!value || !(phone.length < 3);
      },
    )
    .when('phone', {
      is: (phone) => {
        return !phone;
      },
      then: Yup.string().email('El email es inválido'),
    }),
  phone: Yup.string()
    .test(
      'one-not-empty',
      'Uno de los datos es requerido',
      function (value) {
        const { email } = this.parent;
        return !((value || '').length < 3) || !!email;
      },
    )
    .test(
      'min-length',
      'El télefono tiene que tener al menos 10 numeros',
      function (value) {
        const { email } = this.parent;
        return !!email || (value || '').length >= 10;
      },
    ),
});

export default function IdentifyUser({ next, onBack }) {
  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    try {
      const { status } =
        await APIClient.sendForgotPasswordVerificationCode({
          key_user: values.email.toLowerCase() || '+' + values.phone,
        });
      if (status === 200) {
        next(values.email.toLowerCase() || '+' + values.phone);
      }
    } catch (error) {
      handleError(error);
    } finally {
      actions.setSubmitting(false);
    }
  };
  return (
    <div css={styles}>
      <Text
        color={BLACK}
        fontSize={21}
        align="center"
        fontWeight={600}
        className="mb-32"
      >
        Para resetear tu contraseña ingresa tu email o teléfono
      </Text>

      <Formik
        validateOnMount
        onSubmit={handleSubmit}
        initialValues={formInitialValue}
        validationSchema={formValidationSchema}
      >
        {({
          isValid,
          resetForm,
          submitForm,
          isSubmitting,
          setFieldValue,
        }) => {
          return (
            <Form className="formContainer">
              <FormInputText
                placeholder="Email"
                name="email"
                onChange={() => setFieldValue('phone', '')}
                heightMobile={59}
                className="br-10"
              />

              <Text
                fontSize={16}
                color={BLACK}
                align="center"
                className="mbl-16"
              >
                o
              </Text>

              <FormPhoneInput
                className="br-10"
                name="phone"
                onChange={() => setFieldValue('email', '')}
              />

              <ButtonPrimary
                color={BLACK}
                pillShape
                label={'Continuar'}
                onClick={submitForm}
                disabled={!isValid || isSubmitting}
                textProps={{ fontSize: 16, fontWeight: 600 }}
                heightMobile={59}
              />
              <ButtonBack
                className="mt-32"
                onClick={() => {
                  resetForm();
                  onBack?.();
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

IdentifyUser.propTypes = {
  next: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};
