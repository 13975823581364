/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useState, useEffect, useContext } from 'react';

import APIClient from 'redux/api';
import { titlesFeekApp } from 'constants';
import handleError from 'utils/handleError';
import { GRAY_COLD, WHITE } from 'styles/colors';
import { MEDIA_QUERIES } from 'styles/constants';
import { HeaderContext } from 'screens/feekApp/OutletFeekapp';
import ProfileData from 'screens/feekApp/ProfileFeekerEdit/ProfileData';
import ContactData from 'screens/feekApp/ProfileFeekerEdit/ContactData';

export default function ProfileFeekerEdit() {
  const setHeaderResponsiveAction = useContext(HeaderContext);
  const [showResponsiveContactData, setShowResponsiveContactData] =
    useState(false);
  const [favouriteFeekspots, setFavouriteFeekspots] = useState([
    {
      image: '',
      id: '',
      location: { city: '', state: '' },
      name: '',
    },
  ]);
  const [lastLikedFeekers, setLastLikedFeekers] = useState([
    {
      avatar: '',
      name: '',
      profile_username: '',
      total_feek_points: 0,
      _id: '',
    },
  ]);

  const {
    username,
    pronoun,
    birthdate,
    bio,
    avatar,
    backgroundImage,
    createdAt,
    momentsCompleted,
    totalRewardsWinner,
    totalFeekPoints,
    strike,
    referralPoints,
    myReferralCode,
    id,
    instagramUserName,
    feekerPhone,
    feekerEmail,
    isFeekerVerifiedEmail,
    isFeekerVerifiedPhone,
  } = useSelector((state) => state.feekApp);

  useEffect(() => {
    if (!id) return;

    APIClient.getFeekerLastLikedFeekers(id)
      .then(({ data: { docs } }) => setLastLikedFeekers(docs))
      .catch(handleError);

    APIClient.getFeekerFavouriteFeekspots(id)
      .then(({ data: { docs } }) => setFavouriteFeekspots(docs))
      .catch(handleError);
  }, [id]);

  const styles = css`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 24px;
    padding-block: 50px 85px;
    background-color: ${GRAY_COLD};
    position: relative;

    .profile-data {
      flex: 0 1 min(45%, 685px);
    }

    .contact-data {
      flex: 0 1 min(22%, 360px);
    }

    ${MEDIA_QUERIES.mobile} {
      gap: 0;
      padding-block: 0;
      background-color: ${WHITE};

      .profile-data {
        ${showResponsiveContactData
          ? 'display: none !important'
          : ''};
        flex: 0 1 100%;
      }

      .contact-data {
        ${showResponsiveContactData
          ? ''
          : 'display: none !important'};
        flex: 0 1 100%;
      }
    }
  `;

  document.title = titlesFeekApp.ProfileFeekerEdit;

  function openContactData() {
    setShowResponsiveContactData(true);

    setHeaderResponsiveAction(() => () => {
      setShowResponsiveContactData(false);
      setHeaderResponsiveAction(null);
    });
  }

  return (
    <main css={styles}>
      <ProfileData
        openContactData={openContactData}
        profileFeekerData={{
          username,
          pronoun,
          birthdate,
          bio,
          avatar,
          backgroundImage,
          createdAt,
          momentsCompleted,
          totalRewardsWinner,
          totalFeekPoints,
          strike,
          referralPoints,
          myReferralCode,
          id,
        }}
        lastLikedFeekers={lastLikedFeekers}
        favouriteFeekspots={favouriteFeekspots}
        className="profile-data"
      />
      <ContactData
        feekerContactData={{
          instagramUserName,
          feekerPhone,
          feekerEmail,
          isFeekerVerifiedEmail,
          isFeekerVerifiedPhone,
        }}
        className="contact-data"
      />
    </main>
  );
}
