/** @jsxImportSource @emotion/react */
import moment from 'moment';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import {
  GRAY,
  BLACK,
  GRAY_MID,
  TRANSPARENT,
  GRAY_LIGHTEST,
} from 'styles/colors';
import Icon from 'components/Icon';
import Text from 'components/Text';
import Button from 'components/buttons/Button';
import { experienceTypeIcons } from 'constants';
import { ROUTES_FEEKAPP } from 'constants/routes';
import ProgressDuration from 'components/ProgressDuration';
import { campaignParticipationMiniIconsByName } from 'constants';
import { ReactComponent as GiftMini } from 'assets/icons/GiftMini.svg';
import { ReactComponent as CalendarMini } from 'assets/icons/CalendarMini.svg';

const styles = css`
  width: 449px;
  min-width: 400px;

  .image-container {
    width: 100%;
    position: relative;
  }
  .image-content {
    width: 100%;
    padding: 10px;
    height: 449px;
    position: absolute;
  }

  .experience-icon {
    top: 12px;
    right: 24px;
    padding: 10.5px;
    border-radius: 10px;
    position: absolute;
    border: 1px solid ${GRAY_LIGHTEST};
  }

  .experience-img {
    width: 100%;
    height: 449px;
    user-select: none;
    object-fit: cover;
    border-radius: 20px;
    pointer-events: none;
    -webkit-user-select: none;
    border: 1px solid ${GRAY_LIGHTEST};
  }

  .experience-info {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    justify-content: space-between;
  }

  .places-badge {
    padding: 5px 12px;
    width: fit-content;
    border-radius: 17px;
    margin-bottom: 12px;
    border: 1px solid ${GRAY_MID};
  }

  .title {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 12px;
    white-space: nowrap;
  }

  .upperCase {
    text-transform: uppercase;
  }

  .flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .text-icon-container {
    display: flex;
    margin-right: 10px;
    align-items: center;
  }

  .capitalize {
    text-transform: capitalize;
  }
`;
export default function CardExperience({
  id,
  icon,
  image,
  title,
  places,
  onclick,
  end_date,
  className,
  start_date,
  participation,
}) {
  const iconBackgroundColorStyle = css`
    .experience-icon {
      background-color: ${icon?.color ?? TRANSPARENT};
    }
  `;
  return (
    <Link
      className={className}
      css={[styles, iconBackgroundColorStyle]}
      to={ROUTES_FEEKAPP.EXPERIENCE.replace(/:\w*/, id)}
    >
      <Button layoutOnly {...(onclick && { onclick })}>
        <div className="image-container">
          <div className="image-content">
            {icon && (
              <Icon
                className="experience-icon"
                icon={experienceTypeIcons[icon.type] ?? GiftMini}
                size={40}
                color={BLACK}
              />
            )}
            <ProgressDuration
              start_date={start_date}
              end_date={end_date}
            />
          </div>
          {image && <img src={image} className="experience-img" />}
        </div>

        <div className="experience-info">
          <div className="places-badge">
            <Text fontSize={16} fontWeight={600} color={GRAY}>
              {places} Ganador{places > 1 && 'es'}
            </Text>
          </div>
          <Text
            color={BLACK}
            fontSize={21}
            fontWeight={700}
            className="title"
          >
            {title}
          </Text>

          <div className="d-flex">
            <div className="text-icon-container">
              <Icon
                size={16}
                color={GRAY}
                className="me-1"
                icon={
                  campaignParticipationMiniIconsByName?.[
                    participation
                  ]
                }
              />
              <Text color={GRAY} fontSize={16} fontWeight={600}>
                {participation}
              </Text>
            </div>
            <div className="text-icon-container">
              <Icon
                size={16}
                color={GRAY}
                className="me-1"
                icon={CalendarMini}
              />
              <Text color={GRAY} fontSize={16} fontWeight={600}>
                <span className="capitalize">
                  {moment(end_date).format('dddd')}{' '}
                </span>
                {moment(end_date).format('DD')} de{' '}
                {moment(end_date).format('MMMM, YYYY')}
              </Text>
            </div>
          </div>
        </div>
      </Button>
    </Link>
  );
}

CardExperience.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.object,
  image: PropTypes.string,
  title: PropTypes.string,
  onclick: PropTypes.func,
  places: PropTypes.number,
  end_date: PropTypes.string,
  className: PropTypes.string,
  start_date: PropTypes.string,
  participation: PropTypes.string,
};
