/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon';
import { PURPLE_FEEK, WHITE } from 'styles/colors';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as Crown } from 'assets/icons/Crown.svg';
import WhirlpoolOfColorsBg from 'assets/images/WhirlpoolOfColorsBg.png';
import { ReactComponent as PopcornBucket } from 'assets/icons/PopcornBucket.svg';
import IconContainer from 'screens/manager/legacy/Collaborators/IconContainer.png';
import { ReactComponent as MagnifyingGlassRealistic } from 'assets/icons/MagnifyingGlassRealistic.svg';

const styles = css`
  width: 100%;
  flex: 1 0 100%;
  background-image: url(${WhirlpoolOfColorsBg});
  background-size: cover;

  .container {
    display: flex;
    height: auto;
  }

  .CollaboratorsScreen {
    display: flex;
    width: 100% !important;
    height: auto;
  }

  .backgroundContainer {
    min-height: 83.5vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;
  }

  .titleText {
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${WHITE};
  }

  .descriptionText {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    color: ${WHITE};
  }

  .card {
    display: flex;
    flex-direction: row;
    min-width: 527px;
    background: ${WHITE};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
    border-radius: 10.881px;
    margin: 1rem;
    padding: 25px;
    margin-bottom: 60px;
  }

  .cardTitleText {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 33px;
    /* identical to box height */
    display: flex;
    align-items: center;

    color: ${PURPLE_FEEK};
  }
  .cardDescriptionText {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    display: flex;
    align-items: center;

    color: ${PURPLE_FEEK};
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 112px;
    height: 100px;
    margin: 1rem;
    margin-right: 2rem;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    background-image: url(${IconContainer});
    background-size: cover;
  }

  .buttonsWrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 550px;
    height: 40px;
  }

  .button {
    height: 50px;
  }
`;

function getIcon(role) {
  switch (role) {
    case 'customerAdmin':
      return Crown;
    case 'customerSupervisor':
      return MagnifyingGlassRealistic;
    case 'customerViewer':
      return PopcornBucket;
    default:
      return PopcornBucket;
  }
}

function CollaborationCreationSuccess(props) {
  const { handleIndex, collaborators, collaboratorsTotalPlaces } =
    props;
  const { t } = useTranslation('common');

  const roles = {
    customerAdmin: 'Administrador',
    customerSupervisor: 'Supervisor',
    customerViewer: 'Espectador',
  };

  return (
    <div css={styles}>
      <div className="container">
        <div className="backgroundContainer">
          <span className="titleText">¡Felicidades!</span>
          <h3 className="descriptionText">
            Colaboradores creados con éxito.
          </h3>

          <div className="card">
            <div className="column">
              <div className="iconContainer">
                <Icon
                  color={WHITE}
                  icon={getIcon(
                    collaborators[collaborators.length - 1]?.role,
                  )}
                  size={60}
                />
              </div>
            </div>
            <div className="column">
              <div>
                <h2 className="cardTitleText">
                  {collaborators &&
                    roles[
                      collaborators[collaborators.length - 1]?.role
                    ]}
                </h2>
                <h4 className="cardDescriptionText">
                  {t(
                    'collaborators.' +
                      collaborators[collaborators.length - 1]?.role +
                      'Description',
                  )}
                </h4>
              </div>
            </div>

            <div className="column"></div>
          </div>
          <div className="buttonsWrapper">
            <ButtonPrimary
              label="Salir"
              strokeVariant
              className="button"
              onClick={() => handleIndex(0)}
            />
            {collaboratorsTotalPlaces - collaborators?.length >=
              1 && (
              <ButtonPrimary
                className="button"
                label="Agregar otro +"
                onClick={() => handleIndex(1)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

CollaborationCreationSuccess.propTypes = {
  handleIndex: PropTypes.func,
  collaborators: PropTypes.arrayOf(PropTypes.object),
  collaboratorsTotalPlaces: PropTypes.number,
};

export default CollaborationCreationSuccess;
