/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import APIClient from 'redux/api';
import Text from 'components/Text';
import { setFeeker } from 'redux/app/feekApp';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_FEEKAPP } from 'constants/routes';
import { BLACK, PURPLE_FEEK } from 'styles/colors';
import ButtonBack from 'components/buttons/ButtonBack';
import { FormInputText } from 'components/inputs/InputText';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { FormInputPhone } from 'components/inputs/InputPhone';
import { FormInputPassword } from 'components/inputs/InputPassword';

const styles = css`
  .title {
    margin-bottom: 32px;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .link-reset-password {
      text-decoration: underline;
      margin-block-end: 16px;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    .title {
      margin-bottom: 20px;
    }

    .form-wrapper {
      .link-reset-password {
        margin-block-end: 4px;
      }
    }
  }
`;

const formInitialValuesPhone = {
  phone: '',
  password: '',
};

const formInitialValuesEmail = {
  email: '',
  password: '',
};

const formValidationEmailSchema = Yup.object({
  email: Yup.string()
    .email('El email es inválido')
    .required('El email es requerido'),

  password: Yup.string().required('La contraseña es requerida'),
});

const formValidationPhoneSchema = Yup.object({
  phone: Yup.string()
    .min(10, 'El télefono tiene que tener al menos 10 numeros')
    .required('El télefono es requerido'),

  password: Yup.string().required('La contraseña es requerida'),
});

const FormLogin = ({ usePhone, onBackButton, onLoginSuccess }) => {
  const dispatch = useDispatch();

  const handleSignIn = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);

    try {
      const response = await APIClient.loginFeeker({
        key_user: usePhone
          ? `+${values.phone}`
          : values.email.toLowerCase().trim(),
        password: values.password,
      });
      if (response.status === 201) {
        APIClient.setAuthenticatedInstance(
          response.data.access_token,
        );

        dispatch(setFeeker(response.data));
        setTimeout(() => {
          onLoginSuccess?.();
        }, 1000);
      }
    } catch (e) {
      actions.setSubmitting(false);
    }
  };

  return (
    <div css={styles}>
      <Text
        color={BLACK}
        fontSize={24}
        fontSizeMobile={16}
        align="center"
        fontWeight={600}
        className="title"
      >
        Iniciar sesión con {usePhone ? 'teléfono' : 'email'}
      </Text>

      <Formik
        validateOnMount
        onSubmit={handleSignIn}
        initialValues={
          usePhone ? formInitialValuesPhone : formInitialValuesEmail
        }
        validationSchema={
          usePhone
            ? formValidationPhoneSchema
            : formValidationEmailSchema
        }
      >
        {(formik) => {
          return (
            <Form className="form-wrapper">
              {usePhone ? (
                <FormInputPhone heightMobile={59} name="phone" />
              ) : (
                <FormInputText
                  placeholder="Email"
                  name="email"
                  heightMobile={59}
                />
              )}

              <FormInputPassword
                placeholder="Contraseña"
                name="password"
              />

              <ButtonPrimary
                pillShape
                color={BLACK}
                label={'Iniciar sesión'}
                onClick={formik.submitForm}
                textProps={{ fontSize: 16, fontWeight: 600 }}
                disabled={!formik.isValid || formik.isSubmitting}
                heightMobile={59}
              />

              <Link
                className="link-reset-password"
                to={ROUTES_FEEKAPP.RESET_PASSWORD}
              >
                <Button layoutOnly>
                  <Text
                    fontSize={16}
                    align="center"
                    fontWeight={600}
                    color={PURPLE_FEEK}
                  >
                    Olvidé mi contraseña
                  </Text>
                </Button>
              </Link>

              <ButtonBack
                onClick={() => {
                  onBackButton?.();
                  formik.resetForm();
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

FormLogin.propTypes = {
  usePhone: PropTypes.bool.isRequired,
  onBackButton: PropTypes.func.isRequired,
  onLoginSuccess: PropTypes.func.isRequired,
};

export default FormLogin;
