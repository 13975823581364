import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import APIClient from 'redux/api';
import LayoutLogin from 'components/LayoutLogin';
import { ROUTES_FEEKAPP } from 'constants/routes';
import CodeVerification from 'components/CodeVerification';
import NewPassword from 'screens/feekApp/ResetPassword/NewPassword';
import IdentifyUser from 'screens/feekApp/ResetPassword/IdentifyUser';

export default function ResetPassword() {
  const [keyUser, setKeyUser] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();

  const steps = [IdentifyUser, CodeVerification, NewPassword];
  const Step = steps[currentStep];

  const handleNext = (key) => {
    if (key) {
      setKeyUser(key);
    }
    setCurrentStep((prevStep) =>
      Math.min(steps.length - 1, prevStep + 1),
    );
  };

  const handleVerifyUser = async (verificationCode) => {
    if (typeof keyUser !== 'string')
      throw new Error('Hubo un error inesperado');

    return APIClient.forgotPasswordValidateCode({
      key_user: keyUser,
      code: verificationCode,
    }).then(() => handleNext());
  };

  const handleBack = () => {
    switch (currentStep) {
      case 0:
        navigate(ROUTES_FEEKAPP.LOGIN);
        break;

      case steps.length - 1:
        setCurrentStep(0);
        break;

      default:
        setCurrentStep((crr) => crr - 1);
        break;
    }
  };

  return (
    <LayoutLogin>
      <Step
        keyUser={keyUser}
        next={handleNext}
        className="no-padding"
        onSend={handleVerifyUser}
        onBack={handleBack}
        contactMethod={keyUser.includes('@') ? 'email' : 'sms'}
      />
    </LayoutLogin>
  );
}
