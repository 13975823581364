/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';

import {
  formatLocation,
  convertGooglePlaceToAddress,
} from 'utils/format';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Modal from 'components/modals/Modal';
import handleError from 'utils/handleError';
import { setFeekspots } from 'redux/entities';
import { PURPLE_FEEK, WHITE } from 'styles/colors';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { FormPlacesAutoCompleteInput } from 'components/inputs/PlacesAutocompleteInput';

const styles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  inline-size: 520px;
  padding: 40px 50px;
  background: ${WHITE};
  border-radius: 16px;

  form {
    width: 100%;
  }

  .button {
    width: 249px;
    height: 60px;
  }

  .label {
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 17px;
  }
  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
`;

const formValidationSchema = Yup.object().shape({
  location: Yup.object().shape({
    formatedLocation: Yup.string().required(
      'La direccion del negocio es requerida',
    ),
    lng: Yup.number().required(
      'La direccion del negocio es inválida',
    ),
    lat: Yup.number().required(
      'La direccion del negocio es inválida',
    ),
  }),
});

function ModalAddress({
  location,
  feekspotId,
  visible,
  onRequestClose,
}) {
  const dispatch = useDispatch();

  const handleFormSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    const { lat, lng } = values.location;
    const addressComponents = convertGooglePlaceToAddress(
      values.location.addressComponents,
    );

    try {
      const res = await APIClient.updateFeekspot(
        {
          location: {
            state: addressComponents.administrative_area_level_1,
            exterior_number: addressComponents.street_number,
            city: addressComponents.locality,
            cp: addressComponents.postal_code,
            suburb: addressComponents.sublocality_level_1,
            street: addressComponents.route,
            country: addressComponents.country,
            coordinates: [lng, lat],
          },
        },
        feekspotId,
      );

      if (res.status === 200) {
        await APIClient.getFeekspotsByCustomer().then(({ data }) => {
          const feekspots = data.reduce(
            (prev, crr) => ({ ...prev, [crr.customer._id]: crr }),
            {},
          );
          dispatch(setFeekspots(feekspots));
          actions.setSubmitting(false);
          onRequestClose();
        });
      }
    } catch (error) {
      handleError(error);
      actions.setSubmitting(false);
    }
  };

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: handleFormSubmit,
    enableReinitialize: true,
    initialValues: {
      location: {
        formatedLocation: formatLocation(location),
      },
    },
    validationSchema: formValidationSchema,
  });

  return (
    <Modal
      visible={visible}
      {...(!formik.isSubmitting && {
        onRequestClose: onRequestClose,
      })}
      className="overflow"
    >
      <div css={styles}>
        <Text align="center" fontSize={20} fontWeight="700">
          Dirección
        </Text>
        <FormikProvider value={formik}>
          <Form className="form">
            <div className="address">
              <Text fontSize={14} fontWeight="500" className="label">
                Asegúrate de escoger la dirección correcta de tu
                Negocio, tal y como está indicada en Google Maps.
              </Text>

              <FormPlacesAutoCompleteInput
                name="location"
                withMap
                {...(location?.coordinates && {
                  mapProps: {
                    center: {
                      lng: location?.coordinates[0],
                      lat: location?.coordinates[1],
                    },
                    zoom: 15,
                  },
                })}
              />
            </div>

            <div className="buttonsWrapper">
              <ButtonPrimary
                color={WHITE}
                textColor={PURPLE_FEEK}
                fontSize={16}
                label="Cancelar"
                onClick={onRequestClose}
                className="button"
                strokeVariant
                disabled={formik.isSubmitting}
              />
              <ButtonPrimary
                label="Guardar Cambios"
                fontSize={16}
                disabled={
                  !formik.dirty ||
                  !formik.isValid ||
                  formik.isSubmitting
                }
                className="button"
                onClick={formik.submitForm}
              />
            </div>
          </Form>
        </FormikProvider>
      </div>
    </Modal>
  );
}

ModalAddress.propTypes = {
  location: PropTypes.object,
  days: PropTypes.arrayOf(PropTypes.object),
  feekspotId: PropTypes.string,
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default ModalAddress;
