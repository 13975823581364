/** @jsxImportSource @emotion/react */
import moment from 'moment';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import Text from 'components/Text';
import Avatar from 'components/Avatar';
import { MEDIA_QUERIES } from 'styles/constants';
import FooterLegalTexts from 'components/legacy/FooterLegalTexts';
import AccountData from 'screens/manager/legacy/ProfileCustomer/AccountData';
import FeekspotsList from 'screens/manager/legacy/ProfileCustomer/FeekspotsList';

const styles = css`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 80%;
  padding: 30px 40px;

  .mainHeader {
    display: flex;
    margin-block-end: 10px;
    gap: 30px;
    align-items: center;

    .mainHeaderText {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 15px;
      height: 100%;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    padding: 40px 24px;
    gap: 20px;

    .mainHeader {
      gap: 13px;

      .mainHeaderText {
        gap: 8px;

        .name {
          font-size: 25px;
          font-weight: 500;
          line-height: 30px;
        }

        .registrationDate {
          font-size: 11px;
        }
      }
    }
  }
`;

function Profile() {
  const { name, selectedFeekspotId } = useSelector(
    (state) => state.managerApp,
  );
  const feekspots = useSelector((state) => state.entities.feekspots);
  const selectedFeekspot = feekspots[selectedFeekspotId]?.customer;

  return (
    <div css={styles}>
      <header className="mainHeader">
        <Avatar name={name} size={130} sizeMobile={65} />

        <div className="mainHeaderText">
          <Text className="name" fontSize={40} fontWeight="600">
            {name}
          </Text>

          <Text
            fontSize={14}
            fontWeight="400"
            className="registrationDate"
          >
            Registrado desde el{' '}
            {moment(selectedFeekspot?.created_at).format(
              'DD MMMM [de] YYYY',
            )}
          </Text>
        </div>
      </header>

      <AccountData />

      <FeekspotsList feekspots={Object.values(feekspots)} />

      <FooterLegalTexts />
    </div>
  );
}

export default Profile;
