/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { HashLink } from 'react-router-hash-link';

import Text from 'components/Text';
import Icon from 'components/Icon';
import { Slogan } from 'components/Slogan';
import { MEDIA_QUERIES } from 'styles/constants';
import AppStore from 'assets/images/AppStore.svg';
import getFormattedUrl from 'utils/getFormattedUrl';
import { landingIds } from 'screens/manager/Landing';
import GooglePlay from 'assets/images/GooglePlay.svg';
import HashLinkArrowButton from 'components/HashLinkArrowButton';
import { BLACK, GRAY_DARK, GRAY_MID, WHITE } from 'styles/colors';
import { ROUTES_MANAGER, ROUTES_AGNOSTIC } from 'constants/routes';
import { ReactComponent as Tiktok } from 'assets/icons/Tiktok.svg';
import { ReactComponent as FeekLogo } from 'assets/icons/FeekLogo.svg';
import { ReactComponent as Facebook } from 'assets/icons/Facebook.svg';
import { ReactComponent as Instagram } from 'assets/icons/Instagram.svg';

const styles = css`
  display: grid;
  grid-template:
    'logo callToAction' auto
    'description siteMap' auto
    'copyright .' auto
    / 1fr 1fr;
  justify-content: start;
  gap: 72px;

  background-color: ${BLACK};
  padding-top: 0;
  margin: 70px;
  padding: 70px 60px;
  border-radius: 30px;

  .link {
    text-decoration: none;
    color: ${WHITE};
    font-size: 14px;
    line-height: 14px;
  }

  .feekLogo {
    grid-area: logo;
  }

  .call-to-action {
    grid-area: callToAction;
    width: 100%;
  }

  .description {
    grid-area: description;

    > :not(:first-of-type) {
      margin-block-start: 40px;
    }

    .store-logo-container {
      .store-logo {
        display: inline-block;
        border-radius: 12px;
        border: 1px solid ${GRAY_MID};

        :first-of-type {
          margin-inline-end: 20px;
        }
      }
    }
  }

  .site-map {
    grid-area: siteMap;
    display: grid;
    grid-template:
      'content social' auto
      'legal legal' 1fr
      / 1fr 1fr;
    gap: 40px;

    .footer-title {
      margin-block-end: 40px;
    }

    .content {
      grid-area: content;

      .link {
        display: block;

        :not(:last-of-type) {
          margin-block-end: 15px;
        }
      }
    }

    .social-networks {
      grid-area: social;
      justify-self: end;
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      .icons {
        .icon {
          display: inline flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          aspect-ratio: 1;
          border-radius: 50%;
          background-color: ${GRAY_DARK};

          :nth-of-type(2) {
            margin-inline: 40px;
          }
        }
      }
    }

    .legal-info {
      grid-area: legal;
      align-self: end;

      .link:not(:last-of-type) {
        margin-inline-end: 30px;
      }
    }
  }

  .copyright {
    grid-area: copyright;
  }

  ${MEDIA_QUERIES.mobile} {
    margin: 0;
    padding: 100px 20px 145px;
    grid-template:
      'logo' auto
      'description' auto
      'callToAction' auto
      'siteMap' auto
      'copyright' auto
      / 100%;
    gap: 40px;
    border-radius: 0;

    .description {
      .store-logo-container {
        margin: 0;
        .store-logo {
          margin: 20px 0 0;
        }
      }
    }

    .call-to-action {
      margin-block: 60px 20px;
      padding-inline-start: 20px;
      gap: 20px;

      .label {
        font-size: 16px;
      }
    }

    .site-map {
      grid-template:
        'content' auto
        'social' auto
        'legal' 1fr
        / 1fr;

      .footer-title {
        margin-block-end: 20px;
      }

      .content {
        .link {
          :not(:last-of-type) {
            margin-block-end: 30px;
          }
        }
      }

      .social-networks {
        justify-self: start;
      }

      .legal-info {
        .link {
          display: block;
          margin: 20px 0;
        }
      }
    }

    .copyright {
      margin-block-start: 40px;
    }
  }
`;
export default function FooterLanding() {
  return (
    <footer css={styles}>
      <Icon
        icon={FeekLogo}
        color={WHITE}
        size={60}
        className="feekLogo"
      />

      <HashLinkArrowButton
        arrowPointingTo="right"
        className="call-to-action"
        label={
          <Text
            className="label"
            fontSize={24}
            lineHeight={24}
            color={WHITE}
            fontWeight={500}
          >
            Contratar <b>Feek</b> ahora
          </Text>
        }
        to={getFormattedUrl({
          route: ROUTES_MANAGER.LANDING,
          hash: landingIds.signInForm,
          isManagerRoute: true,
        })}
      />

      <div className="description">
        <Slogan color={WHITE} fontSize={18} />

        <Text color={WHITE} fontSize={16}>
          Posiciona tu negocio en redes, aumenta tus ventas e
          incrementa tus clientes con nuestra ayuda.
          <br />
          <br />
          Explora la app y registra tu negocio en Feek.
        </Text>

        <div className="store-logo-container">
          <a
            href="https://qrco.de/be4ftN"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="store-logo"
              src={AppStore}
              alt="Enlace a Feek en App Store"
            />
          </a>

          <a
            href="https://qrco.de/be57iC"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="store-logo"
              src={GooglePlay}
              alt="Enlace a Feek en Google play"
            />
          </a>
        </div>
      </div>

      <div className="site-map">
        <div className="content">
          <Text
            className="footer-title"
            fontSize={24}
            color={WHITE}
            fontWeight={700}
          >
            Contenido
          </Text>

          <HashLink
            className="link"
            to={getFormattedUrl({
              route: ROUTES_MANAGER.LANDING,
              hash: landingIds.signInForm,
              isManagerRoute: true,
            })}
          >
            Cómo crecer tu negocio
          </HashLink>
          <HashLink
            className="link"
            to={getFormattedUrl({
              route: ROUTES_MANAGER.LANDING,
              hash: landingIds.clients,
              isManagerRoute: true,
            })}
          >
            Nuestros clientes
          </HashLink>
          <HashLink
            className="link"
            to={getFormattedUrl({
              route: ROUTES_MANAGER.LANDING,
              hash: landingIds.requirements,
              isManagerRoute: true,
            })}
          >
            Posiciona tu negocio
          </HashLink>
        </div>

        <div className="social-networks">
          <Text
            className="footer-title"
            fontSize={24}
            color={WHITE}
            fontWeight={700}
          >
            Síguenos
          </Text>

          <div className="icons">
            <a
              href="https://www.instagram.com/feekmx"
              target="_blank"
              className="icon"
              rel="noreferrer"
            >
              <Icon icon={Instagram} size={24} color={WHITE} />
            </a>
            <a
              href="https://www.tiktok.com/@feekmx"
              target="_blank"
              className="icon"
              rel="noreferrer"
            >
              <Icon icon={Tiktok} size={24} color={WHITE} />
            </a>
            <a
              href="https://www.facebook.com/feekmx"
              target="_blank"
              className="icon"
              rel="noreferrer"
            >
              <Icon icon={Facebook} size={24} color={WHITE} />
            </a>
          </div>
        </div>

        <div className="legal-info">
          <HashLink className="link" to={ROUTES_AGNOSTIC.POLICY}>
            Política de privacidad
          </HashLink>
          <HashLink
            className="link"
            to={ROUTES_AGNOSTIC.TERMS_AND_CONDITIONS}
          >
            Términos y condiciones
          </HashLink>
          <HashLink className="link" to={ROUTES_AGNOSTIC.POLICY}>
            Cookies
          </HashLink>
        </div>
      </div>

      <Text color={WHITE} fontSize={14} className="copyright">
        ©2022 Feek Technologies.
      </Text>
    </footer>
  );
}
