/** @jsxImportSource @emotion/react */
import {
  Filler,
  Tooltip,
  LineElement,
  PointElement,
  Chart as ChartJS,
} from 'chart.js';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Line } from 'react-chartjs-2';

import {
  WHITE,
  GRAY_TEXT,
  GRAY_LIGHT,
  PURPLE_FEEK,
  GRAY_LIGHTEST,
} from 'styles/colors';
import MissingData from 'screens/manager/legacy/Dashboard/MissingData';

ChartJS.register(Tooltip, Filler, PointElement, LineElement);

Tooltip.positioners.top = function (items) {
  const pos = Tooltip.positioners.average(items);
  if (pos === false) {
    return false;
  }

  const chart = this.chart;

  return {
    x: pos.x,
    y: chart.chartArea.top,
    xAlign: 'center',
    yAlign: 'bottom',
  };
};

const verticalLiner = {
  id: 'verticalLiner',
  afterInit: (chart) => {
    chart.verticalLiner = {};
  },
  afterEvent: (chart, args) => {
    const { inChartArea } = args;
    chart.verticalLiner = { draw: inChartArea };
  },
  beforeTooltipDraw: (chart, args) => {
    const { draw } = chart.verticalLiner;
    if (!draw) return;

    const { ctx } = chart;
    const { top, bottom } = chart.chartArea;
    const { tooltip } = args;
    const x = tooltip?.caretX;
    if (!x) return;

    ctx.lineWidth = 1;
    ctx.setLineDash([5, 5]);
    ctx.strokeStyle = PURPLE_FEEK;
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(x, top);
    ctx.lineTo(x, bottom);
    ctx.stroke();
    ctx.restore();
  },
};

const LinearChartStyle = css`
  display: grid;
  grid-template-rows: 32px 1fr;
  padding: 25px 20px;
  gap: 14px;
  height: 100%;
  .title {
    display: flex;
    align-items: center;
    height: 32px;
    margin: 0 auto;
    gap: 5px;
  }
  .chart {
    position: relative;
  }
`;

export default function LinearChart({ data, options }) {
  const { values, labels } = data;
  const { title, tooltipCallbacks } = options;

  return !values || values.length < 2 ? (
    <MissingData />
  ) : (
    <div css={[LinearChartStyle]}>
      {title(values)}
      <div className="chart">
        <Line
          plugins={[verticalLiner]}
          options={{
            locale: 'en-US',
            maintainAspectRatio: false,
            font: { family: 'Roobert' },
            scales: {
              x: {
                grid: { display: false },
                ticks: {
                  autoSkip: false,
                  maxRotation: 0,
                  callback: (_, index) =>
                    index === 0 ||
                    index === Math.floor(values.length / 2) ||
                    index === Math.floor(values.length / 2) * 2
                      ? labels[index]
                      : '',
                },
              },
              y: {
                grid: {
                  z: 1,
                },
                ticks: {
                  precision: 0,
                  beginAtZero: true,
                },
              },
            },
            plugins: {
              tooltip: {
                position: 'top',
                backgroundColor: WHITE,
                displayColors: false,
                cornerRadius: 4,
                bodyFont: {
                  size: 12,
                  family: 'Roobert',
                  weight: 'bold',
                },
                titleFont: {
                  size: 12,
                  family: 'Roobert',
                  weight: 'normal',
                },
                titleAlign: 'center',
                titleColor: GRAY_TEXT,
                titleMarginBottom: 0,
                borderColor: GRAY_LIGHT,
                borderWidth: 1,
                callbacks: {
                  ...tooltipCallbacks(values),
                  title: (tooltipItem) => tooltipItem[0].label,
                },
              },
              legend: {
                display: false,
              },
              plugins: {
                verticalLiner: {},
              },
            },
          }}
          data={{
            labels: labels,
            datasets: [
              {
                data: values,
                pointRadius: 0,
                pointHitRadius: 10,
                pointHoverRadius: 3,
                pointHoverBorderWidth: 3,
                pointHoverBackgroundColor: WHITE,
                borderColor: PURPLE_FEEK,
                borderJoinStyle: 'round',
                fill: 'origin',
                backgroundColor: GRAY_LIGHTEST + '96',
              },
            ],
          }}
        />
      </div>
    </div>
  );
}

LinearChart.propTypes = {
  data: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.number),
    labels: PropTypes.arrayOf(PropTypes.string),
  }),
  options: PropTypes.shape({
    title: PropTypes.func,
    tooltipCallbacks: PropTypes.func,
  }),
};
