/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';

import APIClient from 'redux/api';
import Icon from 'components/Icon';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import Modal from 'components/modals/Modal';
import { BLACK, WHITE } from 'styles/colors';
import { MEDIA_QUERIES } from 'styles/constants';
import { setParticipations } from 'redux/entities';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as ZapMini } from 'assets/icons/ZapMini.svg';
import { ReactComponent as TrophyMini } from 'assets/icons/TrophyMini.svg';

const styles = css`
  width: 400px;
  max-width: 650px;
  padding: 20px 40px 40px;
  background-color: ${WHITE};

  ${MEDIA_QUERIES.mobile} {
    &&& {
      padding: 20px 16px;
    }
  }
`;
export default function ModalBookingExperience({
  visible,
  experienceId,
  onRequestClose,
  minMomentsRequired,
}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleBookExperience = () => {
    setLoading(true);
    APIClient.bookExperience(experienceId)
      .then(({ data }) => {
        dispatch(setParticipations([data]));
        onRequestClose();
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      visible={visible}
      onRequestClose={!loading && onRequestClose}
    >
      <div css={styles}>
        <Text
          color={BLACK}
          fontSize={16}
          align="center"
          fontWeight={600}
        >
          Estás apunto de participar por esta recompensa Feek,
          recuerda:
        </Text>

        <div className="d-flex mt-4">
          <Icon
            icon={ZapMini}
            color={BLACK}
            size={16}
            className="me-1"
          />
          <Text fontSize={15} color={BLACK}>
            Completar <b>{minMomentsRequired}</b> Promociones
          </Text>
        </div>
        <div className="d-flex mt-2 pt-1">
          <Icon
            icon={TrophyMini}
            color={BLACK}
            size={16}
            className="me-1"
          />
          <Text fontSize={15} color={BLACK}>
            Mantente en el Top <b>1</b>
          </Text>
        </div>
        <Text fontSize={15} color={BLACK} className="mt-4">
          Para ganar esta recompensa, completa el mayor número de
          Promociones antes de la fecha de finalización
        </Text>
        <ButtonPrimary
          pillShape
          disabled={loading}
          className="mt-4"
          onClick={handleBookExperience}
          label="Confirmar participación"
        />
      </div>
    </Modal>
  );
}

ModalBookingExperience.propTypes = {
  visible: PropTypes.bool,
  minMomentsRequired: PropTypes.number,
  experienceId: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};
