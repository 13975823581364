/** @jsxImportSource @emotion/react */
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  RED,
  WHITE,
  GRAY_TEXT,
  GRAY_LIGHT,
  PURPLE_FEEK,
  GRAY_LIGHTER,
  GRADIENT_PURPLE,
  GRADIENT_SIDEBAR,
  PURPLE_INACTIVE_LIGHT,
} from 'styles/colors';
import {
  setRoles,
  setFeekspots,
  setFeekspotInvitations,
} from 'redux/entities';
import {
  setCustomer,
  closeSideMenu,
  selectFeekspot,
  toggleSideMenu,
} from 'redux/app/managerApp';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import DropDown from 'components/DropDown';
import handleError from 'utils/handleError';
import Modal from 'components/modals/Modal';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_MANAGER } from 'constants/routes';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as Gear } from 'assets/icons/Gear.svg';
import { ReactComponent as Home } from 'assets/icons/Home.svg';
import { ReactComponent as Bars } from 'assets/icons/Bars.svg';
import { ReactComponent as UserPlus } from 'assets/icons/UserPlus.svg';
import { ReactComponent as PlusCircle } from 'assets/icons/PlusCircle.svg';
import { ReactComponent as FeekMoment } from 'assets/icons/FeekMoment.svg';
import { ReactComponent as DollarSign } from 'assets/icons/DollarSign.svg';
import AddCampaignButton from 'components/legacy/buttons/AddCampaignButton';
import { useProfileCompletedStatus } from 'hooks/useProfileCompletedStatus';
import CreateFeekspotModal from 'components/legacy/SideMenu/CreateFeekspotModal';
import AcceptInvitationToFeekspotModal from 'components/legacy/SideMenu/AcceptInvitationToFeekspotModal';
import RequestInvitationToFeekspotModal from 'components/legacy/SideMenu/RequestInvitationToFeekspotModal';

const style = css`
  display: flex;
  z-index: 100;
  position: relative;
  max-height: 100vh;
  position: sticky;
  top: 0;

  .feekspotsRow,
  .containerMenu {
    overflow: auto;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${GRADIENT_PURPLE};
      border-radius: 8px;
    }
    ::-webkit-scrollbar-track {
      background: ${WHITE};
    }
  }

  .feekspotsRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    height: 100%;
    width: 64px;
    padding-block: 15px 20px;
    background: ${GRADIENT_SIDEBAR}, ${PURPLE_INACTIVE_LIGHT};

    .feekspotItemBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      border-radius: 5px;

      &.--selected {
        padding: 5px;
        border: 2px solid ${WHITE};
      }

      &.--invitation {
        position: relative;
        padding: 5px;
        border: 2px solid ${RED};

        &::after {
          position: absolute;
          left: -18px;
          content: '';
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background-color: ${RED};
        }
      }

      .feekspotLogo {
        width: 34px;
        height: 34px;
        border-radius: 5px;
        object-fit: cover;
      }

      .feekspotLogoPlaceholder {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        border-radius: 5px;
        background-color: ${GRAY_LIGHT};
        text-transform: capitalize;
      }
    }

    .dropDown {
      flex: 0 0 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${WHITE};
      border-radius: 5px;
      width: 32px;
    }

    .btn {
      aspect-ratio: 1;
    }
  }

  .containerMenu {
    display: flex;
    flex-direction: column;
    background-color: ${WHITE};
    min-height: 100vh;
    width: 240px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  }

  .dropDownContainer {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    padding-left: 10px;
    padding-right: 15px;
    border-bottom: 1px solid ${PURPLE_INACTIVE_LIGHT};
  }

  .sectionMenuContainer {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 10px;
    gap: 10px;
  }

  .navLink {
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    border-radius: 9px;
  }

  .navLinkGradient {
    background: ${GRADIENT_SIDEBAR};
  }

  .navLink__inner {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px;
  }

  .navLink__inner .icon {
    margin-right: 20px;
    width: 20px;
  }

  .navLink__text a {
    text-decoration: none;
  }

  .addCampaignButtonWrapperSideMenu {
    display: none;
  }
`;

const stylesDropDownAddFeekspots = css`
  .content {
    width: max-content;
    padding: 8px 0;

    .button {
      padding: 12px;

      &:hover {
        background-color: ${GRAY_LIGHTER};
      }
    }
  }
`;

const stylesDropDownFeekspotMenu = css`
  .content {
    min-width: 250px;
    width: max-content;

    .feekspotLabel {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;
    }

    .button {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 10px;
      height: 34px;
      padding: 12px;
      border-radius: 4px;
      text-decoration: none;

      &:hover {
        background-color: ${GRAY_LIGHTER};
      }
    }
  }

  ${MEDIA_QUERIES.mobile} {
    .content {
      left: 50%;
    }
  }
`;

const alertUserChangeModalStyles = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 50px 60px;

  > div:nth-of-type(3) {
    margin-block-start: -12px;
  }

  .buttons {
    display: flex;
    gap: 20px;
    width: 90%;
    margin-inline: auto;
  }
`;

function SideMenu() {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isAddFeekspotDropDownOpen, setIsAddFeekspotDropDownOpen] =
    useState(false);
  const [isAddNewFeekspotModalOpen, setIsAddNewFeekspotModalOpen] =
    useState(false);
  const [
    isRequestInvitationToFeekspotModalOpen,
    setIsRequestInvitationToFeekspotModalOpen,
  ] = useState(false);
  const [selectedFeekspotInvitation, setSelectedFeekspotInvitation] =
    useState(null);
  const [modalContent, setModalContent] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { selectedFeekspotId, isSideMenuVisible } = useSelector(
    (state) => state.managerApp,
  );
  const { feekspots, feekspotInvitations, roles } = useSelector(
    (state) => state.entities,
  );
  const selectedFeekspot = feekspots[selectedFeekspotId]?.customer;
  const { isProfileCompleted } = useProfileCompletedStatus();

  const handleToggleSideMenu = () => {
    dispatch(toggleSideMenu());
    setIsDropDownOpen(false);
    setIsAddFeekspotDropDownOpen(false);
  };

  const handleCloseSideMenu = () => {
    dispatch(closeSideMenu());
    setIsDropDownOpen(false);
    setIsAddFeekspotDropDownOpen(false);
  };

  useEffect(() => {
    APIClient.getProfile()
      .then(({ data }) => {
        const feekspots = data.user.customers?.reduce(
          (prev, crr) => ({ ...prev, [crr.customer?._id]: crr }),
          {},
        );
        if (!(selectedFeekspotId in feekspots)) {
          dispatch(selectFeekspot(Object.keys(feekspots)[0]));
        }
        dispatch(setFeekspots(feekspots));
        dispatch(
          setFeekspotInvitations(data.user?.customers_invitations),
        );
        dispatch(setCustomer(data.user));
      })
      .catch(handleError);

    APIClient.getRoles()
      .then(({ data }) => dispatch(setRoles(data)))
      .catch(handleError);
  }, []);

  useEffect(() => {
    if (!isSideMenuVisible) {
      setIsDropDownOpen(false);
      setIsAddFeekspotDropDownOpen(false);
    }
  }, [isSideMenuVisible]);

  const handleDropDownToggle = () => {
    setIsDropDownOpen((crr) => !crr);
  };

  const handleIsAddFeekspotDropDownOpen = () => {
    setIsAddFeekspotDropDownOpen((crr) => !crr);
  };

  const handleAddNewFeekspotModalClose = () => {
    setIsAddNewFeekspotModalOpen(false);
  };
  const handleAddNewFeekspotModalOpen = () => {
    handleCloseSideMenu();
    setIsAddFeekspotDropDownOpen(false);
    setIsAddNewFeekspotModalOpen(true);
  };

  const handleRequestInvitationToFeekspotModalClose = () => {
    setIsRequestInvitationToFeekspotModalOpen(false);
  };
  const handleRequestInvitationToFeekspotModalOpen = () => {
    handleCloseSideMenu();
    setIsAddFeekspotDropDownOpen(false);
    setIsRequestInvitationToFeekspotModalOpen(true);
  };

  const handleAcceptInvitationToFeekspotModalClose = () => {
    setSelectedFeekspotInvitation(null);
  };
  const handleAcceptInvitationToFeekspotModalOpen = (id) => {
    setSelectedFeekspotInvitation(id);
  };

  const handleSelectFeekspot = (id) => {
    if (id === selectedFeekspotId) return;
    if (pathname === ROUTES_MANAGER.ADD_CAMPAIGNS) {
      setModalContent(
        <div css={alertUserChangeModalStyles}>
          <Text align="center" fontSize={20} fontWeight="600">
            ¿Abandonar creación de campaña?
          </Text>
          <Text fontSize={16}>
            ¿Estás seguro de que deseas abandonar la creación de esta
            campaña?
          </Text>
          <Text fontSize={14}>
            La campaña será borrada, pero recuerda que antes puedes
            guardarla como un borrador
          </Text>
          <div className="buttons">
            <ButtonPrimary
              onClick={() => {
                setModalContent(null);
                return;
              }}
              label="Cancelar"
              strokeVariant
            />
            <ButtonPrimary
              onClick={() => {
                navigate(ROUTES_MANAGER.CAMPAIGNS);
                setModalContent(null);
                dispatch(selectFeekspot(id));
              }}
              label="Salir"
              color={RED}
            />
          </div>
        </div>,
      );
    } else {
      dispatch(selectFeekspot(id));
    }
  };

  const selectedFeekspotInvitationRoleData = Object.values(
    roles,
  ).find(
    (item) =>
      item.name ===
      feekspotInvitations[selectedFeekspotInvitation]?.role,
  );

  const mobileStyle = css`
    ${MEDIA_QUERIES.mobile} {
      max-height: calc(100vh - 62px);
      top: 62px;
      z-index: 50;
      transform: ${isSideMenuVisible
        ? 'translateX(0)'
        : 'translateX(-100%)'};
      transition: transform ease-in-out 0.3s;

      .containerMenu {
        width: 216px;
      }

      .sectionMenuContainer {
        padding: 20px 10px;

        .addCampaignButtonWrapperSideMenu {
          display: initial;
        }
      }
    }
  `;

  return (
    <div
      className="container"
      css={[style, mobileStyle]}
      onClick={(e) => {
        if (e.target === e.currentTarget) handleToggleSideMenu();
      }}
    >
      <Modal
        visible={!!modalContent}
        onRequestClose={() => setModalContent(null)}
      >
        {modalContent}
      </Modal>
      <CreateFeekspotModal
        isOpen={isAddNewFeekspotModalOpen}
        handleClose={handleAddNewFeekspotModalClose}
      />
      <RequestInvitationToFeekspotModal
        isOpen={isRequestInvitationToFeekspotModalOpen}
        handleClose={handleRequestInvitationToFeekspotModalClose}
      />
      <AcceptInvitationToFeekspotModal
        isOpen={!!selectedFeekspotInvitation}
        handleClose={handleAcceptInvitationToFeekspotModalClose}
        roleName={t(
          'collaborators.' + selectedFeekspotInvitationRoleData?.name,
        )}
        roleDescription={t(
          'collaborators.' +
            selectedFeekspotInvitationRoleData?.name +
            'Description',
        )}
        feekspotName={
          feekspotInvitations[selectedFeekspotInvitation]?.feekspot
            .name
        }
        feekspotId={selectedFeekspotInvitation}
      />

      <div className="feekspotsRow">
        <DropDown
          isOpen={isAddFeekspotDropDownOpen}
          onOpen={handleIsAddFeekspotDropDownOpen}
          onClose={handleIsAddFeekspotDropDownOpen}
          className="dropDown"
          childrenWrapperCSS={stylesDropDownAddFeekspots}
          position="right"
          alignment="top"
          icon={<Icon size={16} icon={PlusCircle} />}
        >
          <Button
            className="button"
            onClick={handleAddNewFeekspotModalOpen}
          >
            <Text fontSize={14}>Agregar un Negocio nuevo</Text>
          </Button>

          <Button
            className="button"
            onClick={handleRequestInvitationToFeekspotModalOpen}
          >
            <Text fontSize={14}>Vincular un Negocio existente</Text>
          </Button>
        </DropDown>

        {Object.values(feekspots).map(
          ({ customer: { _id, image, name } }) => (
            <Button
              key={_id}
              className={`feekspotItemBtn ${
                _id === selectedFeekspotId ? '--selected' : ''
              }`}
              onClick={() => handleSelectFeekspot(_id)}
              title={name}
            >
              {image ? (
                <img
                  draggable={false}
                  src={image}
                  className="feekspotLogo"
                  alt="feekspot logo"
                />
              ) : (
                <Text
                  className="feekspotLogoPlaceholder"
                  fontSize={20}
                >
                  {name[0]}
                </Text>
              )}
            </Button>
          ),
        )}
        {Object.values(feekspotInvitations).map(
          ({ customer: { _id, image, name } }) => (
            <Button
              key={_id}
              className={'feekspotItemBtn --invitation'}
              onClick={() =>
                handleAcceptInvitationToFeekspotModalOpen(_id)
              }
            >
              {image ? (
                <img
                  draggable={false}
                  src={image}
                  className="feekspotLogo"
                  alt="feekspot logo"
                />
              ) : (
                <Text
                  className="feekspotLogoPlaceholder"
                  fontSize={20}
                >
                  {name[0]}
                </Text>
              )}
            </Button>
          ),
        )}
      </div>

      <div className="containerMenu">
        <div className="dropDownContainer">
          <Text fontSize={16} fontWeight="bold">
            {selectedFeekspot?.name}
          </Text>

          <DropDown
            isOpen={isDropDownOpen}
            onOpen={handleDropDownToggle}
            onClose={handleDropDownToggle}
            className="dropDown"
            childrenWrapperCSS={stylesDropDownFeekspotMenu}
          >
            <div className="feekspotLabel">
              <Avatar
                image={selectedFeekspot?.image}
                name={selectedFeekspot?.name}
                size={40}
              />

              <Text fontSize={16}>{selectedFeekspot?.name}</Text>
            </div>

            <Link
              onClick={handleCloseSideMenu}
              className="button"
              to={ROUTES_MANAGER.PROFILE_FEEKSPOT}
            >
              <Icon icon={Gear} color={PURPLE_FEEK} size={19} />
              <Text fontSize={16} lineHeight={19}>
                Configuración
              </Text>
            </Link>

            <Link
              onClick={handleCloseSideMenu}
              className="button"
              to={ROUTES_MANAGER.PAYMENT_HISTORY}
            >
              <Icon icon={DollarSign} color={PURPLE_FEEK} size={19} />
              <Text fontSize={16} lineHeight={19}>
                Historial de Pagos
              </Text>
            </Link>
          </DropDown>
        </div>

        <div className="sectionMenuContainer">
          {isProfileCompleted ? (
            <AddCampaignButton
              isProfileCompleted={isProfileCompleted}
              places={selectedFeekspot?.places}
              wrapperClassName="addCampaignButtonWrapperSideMenu"
            />
          ) : (
            <NavLink
              to={ROUTES_MANAGER.HOME}
              className="navLink navLinkGradient"
              onClick={handleCloseSideMenu}
            >
              {({ isActive }) => (
                <div className="navLink__inner">
                  <Icon
                    className="icon"
                    icon={Home}
                    color={WHITE}
                    size={16}
                  />
                  <Text
                    fontSize={16}
                    fontWeight={isActive ? '700' : '400'}
                    className="navLink__text"
                    color={WHITE}
                  >
                    Completar Perfil
                  </Text>
                </div>
              )}
            </NavLink>
          )}

          <NavLink
            onClick={handleCloseSideMenu}
            to={ROUTES_MANAGER.DASHBOARD}
            className="navLink"
          >
            {({ isActive }) => (
              <div className="navLink__inner">
                <Icon
                  className="icon"
                  icon={Bars}
                  color={isActive ? PURPLE_FEEK : GRAY_TEXT}
                  size={14}
                />
                <Text
                  fontSize={16}
                  fontWeight={isActive ? '700' : '400'}
                  className="navLink__text"
                >
                  Analíticas
                </Text>
              </div>
            )}
          </NavLink>

          <NavLink
            onClick={handleCloseSideMenu}
            to={ROUTES_MANAGER.CAMPAIGNS}
            className="navLink"
          >
            {({ isActive }) => (
              <div className="navLink__inner">
                <Icon
                  className="icon"
                  icon={FeekMoment}
                  color={isActive ? PURPLE_FEEK : GRAY_TEXT}
                  size={20}
                />
                <Text
                  fontSize={16}
                  fontWeight={isActive ? '700' : '400'}
                  className="navLink__text"
                >
                  Campañas
                </Text>
              </div>
            )}
          </NavLink>

          {(feekspots[selectedFeekspotId]?.role === 'customerOwner' ||
            feekspots[selectedFeekspotId]?.role ===
              'customerAdmin') && (
            <NavLink
              onClick={handleCloseSideMenu}
              to={ROUTES_MANAGER.COLLABORATORS}
              className="navLink"
            >
              {({ isActive }) => (
                <div className="navLink__inner">
                  <Icon
                    className="icon"
                    icon={UserPlus}
                    color={isActive ? PURPLE_FEEK : GRAY_TEXT}
                    size={20}
                  />
                  <Text
                    fontSize={16}
                    fontWeight={isActive ? '700' : '400'}
                    className="navLink__text"
                  >
                    Colaboradores
                  </Text>
                </div>
              )}
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
}

export default SideMenu;
