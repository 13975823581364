/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  RED,
  PURPLE_B,
  GRADIENT_PURPLE,
  GRADIENT_INSTAGRAM,
} from 'styles/colors';
import Text from 'components/Text';
import Modal from 'components/modals/Modal';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import AccordionStatus from 'components/AccordionStatus';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import ButtonInstagramLogin from 'components/buttons/ButtonInstagramLogin';

const modalStyles = css`
  padding: 40px 44.5px;
  background: white;
  max-width: 450px;

  > div:first-of-type {
    margin-block-end: 15px;
  }

  > div:nth-of-type(2) {
    margin-block-end: 37px;
  }

  > div:nth-of-type(3) {
    display: flex;
    gap: 21px;
    width: 100%;
    margin-block-end: 15px;

    .instagramBtn {
      gap: 10px;
      border-radius: 12px;
      height: 40px;
      padding: 0 20px;
      background: ${GRADIENT_INSTAGRAM} ${PURPLE_B};

      &.changeAccount {
        gap: 18px;
        padding: 0 10px;
        border-radius: 8px;
        background: ${GRADIENT_PURPLE};

        .label {
          text-align: center;
          font-size: 16px;
          line-height: 17.6px;
        }

        ${MEDIA_QUERIES.mobile} {
          height: 40px;
        }
      }
    }
  }
`;

function AccordionProfileInstagramSync({
  statusIcon = false,
  feekspot,
  className,
}) {
  const styles = css`
    display: flex;
    flex-direction: column;
    gap: 12px;

    .instagramBtn {
      gap: 10px;
      border-radius: 12px;
      height: 40px;
      padding: 0 20px;
      background: ${GRADIENT_INSTAGRAM} ${PURPLE_B};
      opacity: 1;
    }
  `;

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        visible={showModal}
        onRequestClose={() => setShowModal(false)}
      >
        <div css={modalStyles}>
          <Text
            fontSize={20}
            fontWeight="700"
            lineHeight={24}
            align="center"
          >
            ¿Estás seguro que deseas vincular otra cuenta de
            Instagram?
          </Text>
          <Text
            fontSize={14}
            fontWeight="500"
            lineHeight={21}
            align="center"
          >
            Esta acción provocará que la cuenta vinculada actualmente
            se desvincule.
          </Text>
          <div>
            <ButtonPrimary
              strokeVariant
              label="Cancelar"
              textProps={{
                align: 'center',
                fontSize: 16,
                lineHeight: 17.6,
              }}
              onClick={() => setShowModal(false)}
            />
            <ButtonInstagramLogin
              label="Continuar con cambio de cuenta"
              className="instagramBtn changeAccount"
              showIcon={false}
            />
          </div>
        </div>
      </Modal>

      <AccordionStatus
        className={className}
        status={
          statusIcon ? !!feekspot?.instagram?.username : undefined
        }
        title="Instagram vinculado"
        content={
          <div css={styles}>
            {feekspot?.instagram?.username ? (
              <>
                <Text fontSize={12} fontWeight="500">
                  Estado de vinculación: <b>Activa</b>
                </Text>
                <ButtonInstagramLogin
                  label={feekspot?.instagram?.username}
                  className="instagramBtn"
                  showIcon={false}
                  disabled={true}
                  hideDisabledStyles
                />
                <Button onClick={() => setShowModal(true)}>
                  <Text fontSize={12} fontWeight="500" color={RED}>
                    Vincular otra cuenta
                  </Text>
                </Button>
              </>
            ) : (
              <>
                <Text fontSize={12} fontWeight="500">
                  Ingresa con la cuenta de Instagram de tu negocio.
                </Text>
                <ButtonInstagramLogin
                  label="Vincular Instagram"
                  className="instagramBtn"
                  showIcon={true}
                  disabled={false}
                />
              </>
            )}
          </div>
        }
      />
    </>
  );
}

AccordionProfileInstagramSync.propTypes = {
  statusIcon: PropTypes.bool,
  feekspot: PropTypes.object,
  className: PropTypes.string,
};

export default AccordionProfileInstagramSync;
