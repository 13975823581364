/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import { useNavigate } from 'react-router-dom';
import { ROUTES_MANAGER } from 'constants/routes';
import { PURPLE_FEEK, WHITE } from 'styles/colors';
import { setFeekspotBilling } from 'redux/entities';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { FormInputText } from 'components/inputs/InputText/index';

const styles = css`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 56px 48px;

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 2rem;
  }

  .form {
    display: grid;
    gap: 60px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin-top: 20px;
    padding-left: 8px;
  }

  .billing-form {
    width: 100%;
  }

  .address-form {
    width: 100%;
  }

  .form-title {
    margin-bottom: 20px;
  }
  .label {
    margin-bottom: 0.75rem;
  }

  .justify-between {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    & > * {
      width: 100%;
    }
  }

  @media (max-width: 1200px) {
    .form {
      display: block;
    }
  }

  .button {
    height: 60px;
    width: 100%;
    border: 1px ${PURPLE_FEEK} solid;
    border-radius: 8px;
  }
`;

const formValidationSchema = Yup.object({
  email: Yup.string()
    .required('El email es requerido')
    .email('El email inválido'),
  bussinesName: Yup.string().required(
    'El nombre o razón social es requerido',
  ),
  rfc: Yup.string().required('El RFC es requerido'),
  street: Yup.string().required('La calle es requerida'),
  numExt: Yup.string().required('El número ext. es requerido'),
  city: Yup.string().required('La ciudad / localidad es requerida'),
  cp: Yup.string().required('El código postal es requerido'),
  state: Yup.string().required('El estado / provincia es requerido'),
  country: Yup.string().required('El país es requerido'),
});

function Billing() {
  const dispatch = useDispatch();
  const feekspots = useSelector((state) => state.entities.feekspots);
  const navigate = useNavigate();

  const selectedFeekspotId = useSelector(
    (state) => state.managerApp.selectedFeekspotId,
  );

  const feekspot = feekspots[selectedFeekspotId]?.customer;

  const { email, name, rfc, address } = feekspot?.billing || {};

  const formInitialValues = {
    email: email || '',
    bussinesName: name || '',
    rfc: rfc || '',
    street: address?.street || '',
    numExt: address?.exterior_number || '',
    numInt: address?.interior_number || '',
    city: address?.city || '',
    cp: address?.cp || '',
    state: address?.state || '',
    country: address?.country || '',
  };

  const handleFormSubmit = async (values, actions) => {
    actions.setSubmitting(true);

    try {
      const res = await APIClient.updateBilling(feekspot._id, values);
      if (res.status === 200) {
        toast.success('Datos de facturación actualizados');
        dispatch(
          setFeekspotBilling({
            billing: res.data.billing,
            feekspotId: feekspot._id,
          }),
        );
      }
      actions.setSubmitting(false);
    } catch (error) {
      actions.setSubmitting(false);
      handleError(error);
    }
  };

  return (
    <div css={styles}>
      <div>
        <Formik
          validateOnMount
          validateOnChange
          enableReinitialize
          onSubmit={handleFormSubmit}
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
        >
          {(formik) => {
            return (
              <Form className="form">
                <div className="billing-form">
                  <Text
                    fontSize={16}
                    fontWeight={600}
                    className="form-title"
                  >
                    Datos de Facturación
                  </Text>

                  <Text className="label" fontSize={16}>
                    Correo electrónico
                  </Text>
                  <FormInputText
                    name="email"
                    placeholder="Correo electrónico"
                  />

                  <Text className="label" fontSize={16}>
                    Nombre o Razón Social
                  </Text>
                  <FormInputText
                    name="bussinesName"
                    placeholder="Nombre o Razón Social"
                  />

                  <Text className="label" fontSize={16}>
                    RFC
                  </Text>
                  <FormInputText name="rfc" placeholder="RFC" />
                </div>
                <div className="address-form">
                  <Text
                    fontSize={16}
                    fontWeight={600}
                    className="form-title"
                  >
                    Domicilio Fiscal
                  </Text>

                  <div className="justify-between">
                    <div>
                      <Text className="label" fontSize={16}>
                        Calle
                      </Text>
                      <FormInputText
                        name="street"
                        placeholder="Calle"
                      />
                    </div>
                    <div>
                      <Text className="label" fontSize={16}>
                        Número Ext.
                      </Text>
                      <FormInputText
                        name="numExt"
                        type="number"
                        placeholder="Número Ext."
                      />
                    </div>
                    <div>
                      <Text className="label" fontSize={16}>
                        Número Int.
                      </Text>
                      <FormInputText
                        name="numInt"
                        type="number"
                        placeholder="Número Int."
                      />
                    </div>
                  </div>

                  <div className="justify-between">
                    <div>
                      <Text className="label" fontSize={16}>
                        Ciudad / Localidad
                      </Text>
                      <FormInputText
                        name="city"
                        placeholder="Ciudad / Localidad"
                      />
                    </div>
                    <div>
                      <Text className="label" fontSize={16}>
                        Código Postal
                      </Text>
                      <FormInputText
                        name="cp"
                        placeholder="Código Postal"
                        type="number"
                      />
                    </div>
                  </div>
                  <div className="justify-between">
                    <div>
                      <Text className="label" fontSize={16}>
                        Estado / Provincia
                      </Text>
                      <FormInputText
                        name="state"
                        placeholder="Estado / Provincia"
                      />
                    </div>
                    <div>
                      <Text className="label" fontSize={16}>
                        País
                      </Text>
                      <FormInputText
                        name="country"
                        placeholder="País"
                      />
                    </div>
                  </div>

                  <div className="buttonsWrapper">
                    <ButtonPrimary
                      color={WHITE}
                      textColor={PURPLE_FEEK}
                      textProps={{ fontSize: 16, fontWeight: 600 }}
                      disabled={formik.isSubmitting}
                      label="Cancelar"
                      onClick={() => {
                        navigate(ROUTES_MANAGER.PROFILE_FEEKSPOT);
                      }}
                      className="button"
                    />
                    <ButtonPrimary
                      disabled={
                        !formik.isValid ||
                        formik.isSubmitting ||
                        Object.keys(formik.touched).length === 0
                      }
                      color={PURPLE_FEEK}
                      textColor={WHITE}
                      textProps={{ fontSize: 16, fontWeight: 600 }}
                      label="Guardar"
                      onClick={formik.submitForm}
                      className="button"
                    />
                  </div>
                </div>
                <div className="buttonsWrapper"></div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default Billing;
