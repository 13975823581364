/** @jsxImportSource @emotion/react */
import { Field } from 'formik';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  RED,
  WHITE,
  GRAY_TEXT,
  RED_LIGHT,
  GRAY_LIGHT,
  PURPLE_FEEK,
  PURPLE_INACTIVE,
} from 'styles/colors';
import Text from 'components/Text';
import { defaultFont } from 'styles/fonts';
import { forwardInputEventHandlers } from 'utils/form.utils';

const TextAreaInput = forwardRef((props, ref) => {
  const { error, className, textAreaClassName, ...restProps } = props;

  const styles = css`
    width: 100%;

    .error-text {
      min-height: 19px;
      margin: 3px 0;
    }

    textarea {
      resize: none;
      height: 100px;
      width: 100%;
      color: ${GRAY_TEXT};
      border-radius: 8px;
      padding: 12px;
      border: 1px solid ${error ? RED : PURPLE_INACTIVE};
      background-color: ${error ? RED_LIGHT : WHITE};
      font-family: ${defaultFont};
      font-size: 16px;
      line-height: 19.2px;
      outline: none;

      &:-webkit-autofill {
        -webkit-text-fill-color: ${GRAY_TEXT};
        background-color: ${error ? RED_LIGHT : WHITE} !important;
        box-shadow: inset 0 0 0 1px rgb(255 255 255 / 0%),
          inset 0 0 0 1000px ${WHITE};
      }

      &:focus {
        border-color: ${error ? RED : PURPLE_FEEK};
      }

      &::placeholder {
        color: ${GRAY_LIGHT};
      }
    }
  `;

  return (
    <div ref={ref} css={styles} className={className}>
      <textarea
        type="text"
        className={textAreaClassName}
        {...restProps}
      />
      <Text
        fontSize={14}
        lineHeight={17}
        fontWeight="500"
        color={RED}
        className="error-text"
      >
        {typeof error === 'string' ? error : ''}
      </Text>
    </div>
  );
});

export const FormTextAreaInput = (props) => {
  const { name, error, ...restProps } = props;

  return (
    <Field name={name}>
      {({ meta, field }) => (
        <TextAreaInput
          name={name}
          value={meta.value}
          error={meta.touched && (meta.error || error)}
          {...restProps}
          {...forwardInputEventHandlers(restProps, field)}
        />
      )}
    </Field>
  );
};

FormTextAreaInput.propTypes = {
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

TextAreaInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onKeyDown: PropTypes.func,
  className: PropTypes.string,
  textAreaClassName: PropTypes.string,
};

TextAreaInput.displayName = 'TextAreaInput';

export default TextAreaInput;
