/** @jsxImportSource @emotion/react */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import {
  RED,
  WHITE,
  RED_LIGHT,
  GRAY_LIGHT,
  PURPLE_FEEK,
  GRAY_LIGHTEST,
  GRADIENT_PURPLE,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { setCampaignTypes } from 'redux/entities';
import InputText from 'components/inputs/InputText/index';
import { ReactComponent as Gift } from 'assets/icons/Gift.svg';
import { getColorWithAlpha } from 'utils/cssStringManipulation';
import { ReactComponent as MagicWand } from 'assets/icons/MagicWand.svg';
import { ReactComponent as ArrowDownPeso } from 'assets/icons/ArrowDownPeso.svg';
import { ReactComponent as ArrowDownPercentage } from 'assets/icons/ArrowDownPercentage.svg';

const styles = css`
  display: grid;
  grid-template-columns: repeat(4, 210px);
  grid-auto-rows: 170px;
  justify-content: center;
  gap: 18px;
  margin: 0;
  padding: 0;

  .cardButton {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    outline: solid 1px ${GRAY_LIGHT};
    margin: 0;
    padding: 0;
    background: ${GRADIENT_PURPLE};
    z-index: 0;
  }

  .cardButton::after {
    background: ${WHITE};
    transition: background 0.3s;
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }

  .cardBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
  .cardBody svg {
    display: block;
    margin: 0;
    height: 60px;
  }

  .cardHeading {
    border-top: solid 1px ${getColorWithAlpha(PURPLE_FEEK, 50)};
    background-color: ${GRAY_LIGHTEST};
    height: 43px;
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0 0 8px 8px;
  }

  .inputLabel {
    display: none;
  }

  .inputDiscount.inputDiscount {
    display: none;
    position: relative;
    margin: 0;
    justify-content: center;
    margin-block-start: 8px;
  }

  .percentageAfter::after,
  .discountBefore::before {
    display: flex;
    align-items: center;
    margin-inline-start: 10px;
    font-size: 16px;
    font-weight: 700;
    color: ${WHITE};
  }

  .inputDiscount.inputDiscount.percentageAfter::after {
    margin-inline-start: 10px;
    content: '%';
  }
  .inputDiscount.inputDiscount.discountBefore::before {
    margin-inline-end: 10px;
    content: '$';
  }

  .selectedTypeCampaignCard .inputLabel {
    display: block;
    margin-block-end: 8px;
  }
  .selectedTypeCampaignCard .inputDiscount {
    display: flex;
  }

  .inputDiscount__input.inputDiscount__input {
    border-radius: 8px;
    border-style: none;
    text-align: center;
    width: 80px;
    height: 40px;
    padding: 0;
    margin: 0;
  }

  .inputDiscount__input::-webkit-outer-spin-button,
  .inputDiscount__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .inputDiscount__input:focus {
    outline-width: 0;
  }

  .inputDiscount__input.error:focus {
    outline-width: 1px;
  }

  .error {
    color: ${RED};
    background-color: ${RED_LIGHT};
    outline: 1px solid ${RED};
  }

  .selectedTypeCampaignCard.cardButton::after {
    background: transparent;
  }

  .selectedTypeCampaignCard .inputDiscount + svg {
    display: none;
  }

  .selectedTypeCampaignCard svg {
    filter: brightness(10);
  }

  .selectedTypeCampaignCard .cardHeading {
    border-top: solid 1px ${getColorWithAlpha(WHITE, 25)};
    background-color: transparent;
    color: ${WHITE};
  }

  ${MEDIA_QUERIES.smallScreen} {
    grid-template-columns: repeat(2, 210px);
  }

  ${MEDIA_QUERIES.mobile} {
    grid-template-columns: 267px;
    margin-inline: 20px;

    .cardButton {
      flex: 1 1 100%;
      max-width: 100%;
    }
  }
`;

const labelTexts = {
  DISCOUNT_PERCENTAGE: {
    title: '¿Cuánto porcentaje?',
    description: 'Mínimo 10% y Máximo 99%',
    class: 'percentageAfter',
  },
  MONETARY_DISCOUNT: {
    title: '¿Qué cantidad?',
    description: 'Mínimo $10 y Máximo $9,999',
    class: 'discountBefore',
  },
};

const logos = {
  GIFT: Gift,
  FULL_ACCOUNT: MagicWand,
  MONETARY_DISCOUNT: ArrowDownPeso,
  DISCOUNT_PERCENTAGE: ArrowDownPercentage,
};

function Step1Type() {
  const campaignTypes = useSelector(
    (state) => state.entities,
  ).campaignTypes;
  const dispatch = useDispatch();
  const { values, setFieldTouched, setFieldValue, errors, touched } =
    useFormikContext();

  useEffect(() => {
    APIClient.getCampaignTypes()
      .then(({ data }) => dispatch(setCampaignTypes(data)))
      .catch(handleError);
  }, []);

  const handleInputValue = (value, type) => {
    const newValue = !value
      ? value
      : parseInt(
          (type === 'DISCOUNT_PERCENTAGE'
            ? /^\d{0,2}$/
            : /^\d{0,4}$/
          ).exec(value) || values.discount,
        );
    setFieldValue('discount', newValue);
    return newValue;
  };

  const handleSelectType = (type) => {
    setFieldValue('type', type);
    setFieldTouched('discount', false, false);
    if (
      type === 'DISCOUNT_PERCENTAGE' ||
      type === 'MONETARY_DISCOUNT'
    ) {
      setFieldValue('discount', 0);
    }
  };

  const cards = Object.values(campaignTypes).map(
    ({ name, _id, type }) => {
      return (
        <Button
          key={_id}
          className={
            values.type === type
              ? 'cardButton selectedTypeCampaignCard'
              : 'cardButton'
          }
          onClick={() => handleSelectType(type)}
        >
          <div className="cardBody">
            {values.type === type &&
              (type === 'MONETARY_DISCOUNT' ||
                type === 'DISCOUNT_PERCENTAGE') && (
                <>
                  <Text
                    className="inputLabel"
                    fontSize={14}
                    fontWeight="600"
                    color={WHITE}
                  >
                    {labelTexts[type].title}
                  </Text>
                  <Text
                    className="inputLabel"
                    fontSize={14}
                    fontWeight="400"
                    color={WHITE}
                  >
                    {labelTexts[type].description}
                  </Text>
                  <InputText
                    autoFocus
                    className={`inputDiscount ${labelTexts[type].class} `}
                    inputClassName={`inputDiscount__input ${
                      !!errors.discount && touched.discount
                        ? 'error'
                        : ''
                    }`}
                    inputMode="numeric"
                    onChange={(e) =>
                      handleInputValue(e.target.value, type)
                    }
                    onBlur={() => setFieldTouched('discount')}
                    type="number"
                    value={values.discount || ''}
                  />
                </>
              )}
            <Icon icon={logos[type]} color={PURPLE_FEEK} size={60} />
          </div>

          <Text
            className="cardHeading"
            fontSize={16}
            fontWeight="600"
            lineHeight={43}
            align="center"
          >
            {name}
          </Text>
        </Button>
      );
    },
  );

  return <menu css={styles}>{cards}</menu>;
}

Step1Type.propTypes = {
  setChange: PropTypes.func,
  values: PropTypes.object,
  setIsNextButtonDisabled: PropTypes.func,
};

export default Step1Type;
