import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import APIClient from 'redux/api';
import { authSteps } from 'constants';
import { titlesFeekApp } from 'constants';
import handleError from 'utils/handleError';
import { setFeeker } from 'redux/app/feekApp';
import LayoutLogin from 'components/LayoutLogin';
import { ROUTES_FEEKAPP } from 'constants/routes';
import CodeVerification from 'components/CodeVerification';
import ReferralCode from 'components/FlowRegistration/ReferralCode';
import FormRegistration from 'components/FlowRegistration/FormRegistration';

export default function Registration() {
  const [userCredentials, setUserCredentials] = useState({});
  const [currentStep, setCurrentStep] = useState(
    authSteps.ACCESS_CODE,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  document.title = titlesFeekApp.Registration;

  const handleVerifyUser = async (verificationCode) => {
    APIClient.createVerifiedFeeker({
      ...userCredentials,
      phone: `+${userCredentials.phone}`,
      code: verificationCode,
    })
      .then(({ data }) => {
        if (data.access_token) {
          APIClient.setAuthenticatedInstance(data.access_token);
          dispatch(setFeeker(data.user));
          navigate(ROUTES_FEEKAPP.EXPLORE);
        } else {
          throw new Error('El código no es válido');
        }
      })
      .catch(handleError);
  };

  const handleBack = () => {
    if (currentStep > 2) setCurrentStep((crrStep) => crrStep - 1);
    else () => navigate(ROUTES_FEEKAPP.EXPLORE);
  };

  const steps = [
    null,
    null,
    ReferralCode,
    FormRegistration,
    CodeVerification,
  ];
  const Step = steps[currentStep];

  return (
    <LayoutLogin>
      <Step
        setCurrentStep={setCurrentStep}
        userCredentials={userCredentials}
        setUserCredentials={setUserCredentials}
        contactMethod="sms"
        keyUser={userCredentials.phoneFormat}
        onBackButton={handleBack}
        onSend={handleVerifyUser}
      ></Step>
    </LayoutLogin>
  );
}
