/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { MEDIA_QUERIES } from 'styles/constants';
const { default: Text } = require('components/Text');
const { WHITE, GRAY_TEXT, PURPLE_FEEK } = require('styles/colors');

const style = css`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${WHITE};
  box-shadow: 0px 4px 10px ${GRAY_TEXT}26;
  max-height: 90vh;
  overflow-x: hidden;

  :last-of-type:nth-of-type(even) {
    grid-column: 1 / span 2;
    margin-inline: 25%;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    padding: 0 30px;
    border-radius: 8px 8px 0 0;
    background-color: ${PURPLE_FEEK};
    flex-shrink: 0;
  }

  ${MEDIA_QUERIES.tablet} {
    :last-of-type:nth-of-type(even) {
      grid-column: auto;
      margin-inline: 0;
    }
  }
`;

export default function DashboardCard(props) {
  const { children, title, dates, className } = props;
  return (
    <div css={style} className={className}>
      <div className="header">
        <Text color={WHITE} fontSize={18} fontWeight="bold">
          {title}
        </Text>
        <Text color={WHITE} fontSize={17}>
          {dates}
        </Text>
      </div>
      {children}
    </div>
  );
}

DashboardCard.propTypes = {
  title: PropTypes.string,
  dates: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element,
};
