import { useLayoutEffect, useRef } from 'react';

export const useScrollHorizontally = (options) => {
  const containerRef = useRef(null);
  useLayoutEffect(() => {
    containerRef.current.addEventListener(
      'wheel',
      (e) => scrollHorizontally(e, containerRef.current),
      {
        passive: false,
      },
    );
  }, []);

  const scrollHorizontally = (e, container) => {
    const containerScrollPosition = container.scrollLeft;
    if (
      !options?.stopOnEdges &&
      ((containerScrollPosition === 0 && e.deltaY < 0) ||
        (containerScrollPosition ===
          container.scrollWidth - container.clientWidth &&
          e.deltaY > 0))
    ) {
      return;
    }
    e.preventDefault();
    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
      behaviour: 'smooth',
    });
  };

  return containerRef;
};
