/** @jsxImportSource @emotion/react */
import moment from 'moment';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import {
  GRAY,
  BLACK,
  WHITE,
  GRAY_MID,
  GRAY_COLD,
  GRAY_DARK,
  PURPLE_FEEK,
  GREEN_WHATSAPP,
} from 'styles/colors';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_FEEKAPP } from 'constants/routes';
import { formatNumberToShort } from 'utils/format';
import copyToClipboard from 'utils/copyToClipboard';
import ModalShare from 'components/modals/ModalShare';
import { AvatarsCabinet } from 'components/AvatarsCabinet';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as Zap } from 'assets/icons/Zap.svg';
import { ReactComponent as Share } from 'assets/icons/Share.svg';
import ModalStrikesCount from 'components/modals/ModalStrikesCount';
import { ReactComponent as ZapMini } from 'assets/icons/ZapMini.svg';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import { ReactComponent as GearMini } from 'assets/icons/GearMini.svg';
import { ReactComponent as WhatsApp } from 'assets/icons/WhatsApp.svg';
import ModalLastLikedFeekers from 'components/modals/ModalLastLikedFeekers';
import { ReactComponent as HeartBicolor } from 'assets/icons/HeartBicolor.svg';
import ModalFavouriteFeekspots from 'components/modals/ModalFavouriteFeekspots';
import { ReactComponent as StackedSheets } from 'assets/icons/StackedSheets.svg';
import ModalEditFeekerPersonalData from 'components/modals/ModalEditFeekerPersonalData';

const styles = css`
  --vertical-gap: 30px;
  --horizontal-gap: 24px;
  --grid-full-row: 1 / 3;
  --border-radius: 10px;
  --border-radius-big: 20px;
  display: grid;
  grid: auto-flow / repeat(2, minmax(0, 1fr));
  flex-direction: column;
  justify-content: space-between;
  gap: var(--horizontal-gap) var(--vertical-gap);
  padding: 24px;
  background-color: ${WHITE};
  border-radius: var(--border-radius-big);

  .show-on-responsive {
    display: none;
  }

  .share-content {
    border-radius: var(--border-radius);
    background-color: ${GRAY_COLD};
  }

  .profile-image {
    width: 100%;
    grid-column: var(--grid-full-row);
    aspect-ratio: 2/1;
    border-radius: var(--border-radius);
    object-fit: cover;
  }

  .profile-header {
    grid-column: var(--grid-full-row);
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .header-texts {
      flex: 1;
      justify-self: start;
      align-self: end;
    }
  }

  .profile-statistics {
    grid-column: var(--grid-full-row);
    display: grid;
    grid-template: 105px / repeat(3, minmax(0, 1fr));
    gap: var(--horizontal-gap);

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: ${GRAY_COLD};
      border-radius: var(--border-radius-big);

      dt {
        font-size: 16px;
      }
      dd {
        font-size: 32px;
        font-weight: 600;
      }

      :last-child > dd {
        color: ${PURPLE_FEEK};
      }
    }
  }

  .profile-modal-button {
    flex: 1;
    justify-content: space-between;
    border-radius: var(--border-radius);
  }

  .profile-last-likes,
  .profile-strikes {
    > div:first-of-type {
      margin-block-end: 16px;
    }
  }

  .profile-referral {
    background-color: ${GRAY_COLD};
    border-radius: var(--border-radius);
    grid-column: var(--grid-full-row);
    padding: 24px;

    * {
      line-height: 100%;
    }

    .referral-code-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-block: 16px var(--vertical-gap);

      .referral-code {
        margin-block-start: 10px;
      }

      .button {
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 14px;
        font-weight: 500;
        color: ${GRAY_DARK};
      }
    }

    .referral-points-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .referral-points {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .referral-share {
      display: flex;
      justify-content: center;
      gap: 24px;
      margin-block-start: 30px;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    --border-radius: 16px;
    --horizontal-gap: 16px;
    --vertical-gap: 16px;

    padding: 16px 16px 32px;
    gap: 16px;

    .show-on-responsive {
      display: revert;
    }

    .profile-header {
      display: grid;
      grid-template-rows: 35px 35px;
      grid-template-columns: auto 1fr;
      grid-template-areas:
        'avatar edit'
        'avatar texts';
      gap: 10px;

      .header-avatar {
        grid-area: avatar;
      }

      .header-edit {
        display: flex;
        grid-area: edit;
        align-items: center;
        gap: 15px;
        justify-self: end;

        .button-cogs {
          display: flex;
        }
      }

      .header-texts {
        grid-area: texts;
        align-self: start;

        .header-username {
          font-size: 16px;
          line-height: 100%;
        }
        .header-since {
          grid-area: avatar;
          font-size: 12px;
        }
      }
    }

    .profile-statistics {
      grid-template: 60px / repeat(3, minmax(0, 1fr));

      div {
        dt {
          font-size: 10px;
        }
        dd {
          font-size: 21px;
        }
      }
    }

    .profile-modal-button {
      border-radius: 10px;
    }

    .separator {
      grid-column: var(--grid-full-row);
      margin: 14px 0px;
      width: 100vw;
      border-block-start: 1px ${GRAY_MID} solid;
      justify-self: center;
    }

    .profile-last-likes,
    .profile-strikes {
      > div:first-of-type {
        margin-block-end: 16px;
        font-size: 16px;
        line-height: 100%;
      }
    }

    .profile-referral {
      margin-block-start: 14px;
      padding: 16px;

      .title {
        font-size: 16px;
      }

      .referral-code-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-block-end: 22px;

        .referral-description {
          font-size: 14px;
        }

        .referral-code {
          font-size: 16px;
        }

        .button {
          align-self: end;
          color: ${GRAY};
          font-size: 12px;

          svg
            :is(
              circle,
              ellipse,
              line,
              path,
              polygon,
              polyline,
              rect
            ) {
            fill: ${GRAY};
          }
        }
      }

      .referral-points-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .referral-points-description {
          font-size: 12;
        }

        .referral-points {
          font-size: 16;
          gap: 3px;
        }
      }

      .referral-share {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: var(--horizontal-gap);
        margin-block-start: var(--vertical-gap);
      }
    }
  }
`;

const ProfileData = ({
  className,
  openContactData,
  lastLikedFeekers,
  favouriteFeekspots,
  profileFeekerData: {
    avatar,
    myReferralCode,
    username,
    backgroundImage,
    createdAt,
    momentsCompleted,
    totalRewardsWinner,
    totalFeekPoints,
    strike,
    referralPoints,
    pronoun,
    birthdate,
    bio,
    id,
  },
}) => {
  const [currentModal, setCurrentModal] = useState('');

  return (
    <article css={styles} className={className}>
      <ModalLastLikedFeekers
        feekers={lastLikedFeekers}
        visible={currentModal === 'ModalLastLikedFeekers'}
        onRequestClose={() => setCurrentModal('')}
      />
      <ModalFavouriteFeekspots
        favouriteFeekspots={favouriteFeekspots}
        visible={currentModal === 'ModalFavouriteFeekspots'}
        onRequestClose={() => setCurrentModal('')}
      />
      <ModalShare
        image={avatar}
        title={myReferralCode}
        url="http://q-r.to/go-to-feek"
        header="Invita a tus amigos y gana Feekpoints"
        messageText={`¡Bienvenidx a Feek!, ${myReferralCode} te invita a formar parte de la comunidad, en donde encontrarás Promociones exclusivas y recibirás recompensas por usar la app, tu código de acceso es: ${myReferralCode}, descárgala aquí:`}
        body={
          <div className="share-content mt-4 pt-1">
            <Text color={BLACK} fontSize={14}>
              Tu código de invitación es:
            </Text>
            <Text color={BLACK} fontSize={21} fontWeight={600}>
              {myReferralCode}
            </Text>
          </div>
        }
        onRequestClose={() => setCurrentModal('')}
        visible={currentModal === 'ModalShareCampaign'}
        feekspotName={'Comparte tu código de invitación'}
      />
      <ModalEditFeekerPersonalData
        visible={currentModal === 'ModalEditFeekerPersonalData'}
        onRequestClose={() => setCurrentModal('')}
        profileFeekerDataSettings={{
          avatar: avatar,
          backgroundImage: backgroundImage,
          username: username,
          pronoun: pronoun,
          birthdate: birthdate,
          bio: bio,
          id: id,
        }}
      />
      <ModalStrikesCount
        strikesCount={strike}
        visible={currentModal === 'ModalStrikesCount'}
        onRequestClose={() => setCurrentModal('')}
      />

      <img
        className="profile-image"
        src={backgroundImage}
        alt={username + ' • Feek'}
      />

      <header className="profile-header">
        <Avatar
          name={username}
          image={avatar}
          size={140}
          sizeMobile={80}
          alt={username + ' • Feek'}
          className="header-avatar"
        />
        <span className="header-texts">
          <Text
            className="header-username"
            fontSize={24}
            fontWeight={600}
            color={BLACK}
          >
            {username}
          </Text>
          <Text className="header-since" fontSize={16} color={GRAY}>
            Feeker desde{' '}
            <time dateTime="createdAt">
              {moment(createdAt).format('MMMM [de] YYYY')}
            </time>
          </Text>
        </span>

        <div className="header-edit">
          <Button
            className="button-cogs show-on-responsive"
            onClick={openContactData}
          >
            <Icon color={BLACK} sizeMobile={22} icon={GearMini} />
          </Button>

          <ButtonPrimary
            pillShape
            height={35}
            width={124}
            strokeVariant
            fontSize={16}
            textColor={BLACK}
            label="Editar perfil"
            strokeColor={GRAY_MID}
            onClick={() =>
              setCurrentModal('ModalEditFeekerPersonalData')
            }
          />
        </div>
      </header>

      <dl className="profile-statistics">
        <div>
          <dt>Promociones</dt>
          <dd>{momentsCompleted?.toString().padStart(2, '0')}</dd>
        </div>
        <div>
          <dt>Recompensas</dt>
          <dd>{totalRewardsWinner?.toString().padStart(2, '0')}</dd>
        </div>
        <div>
          <dt>Feekpoints</dt>
          <dd>
            <Icon icon={ZapMini} color={PURPLE_FEEK} height={16} />
            {formatNumberToShort(totalFeekPoints)
              .toString()
              .padStart(2, '0')}
          </dd>
        </div>
      </dl>

      <Link
        to={ROUTES_FEEKAPP.PROFILE_FEEKER.replace(/:\w*/, username)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ButtonPrimary
          className="profile-modal-button"
          fontSize={16}
          fontSizeMobile={14}
          height={48}
          label="Ver perfil"
          textColor={BLACK}
          strokeVariant
          strokeColor={GRAY_MID}
          layoutOnly
          icon={
            <Icon
              icon={Chevron}
              color={BLACK}
              width={14}
              rotate={-90}
            />
          }
          iconPosition="right"
          onClick={() => setCurrentModal()}
        />
      </Link>

      <ButtonPrimary
        className="profile-modal-button"
        fontSize={16}
        fontSizeMobile={14}
        height={48}
        label="Mis favoritos"
        textColor={WHITE}
        color={GRAY_DARK}
        icon={<Icon icon={HeartBicolor} color={WHITE} width={14} />}
        iconPosition="right"
        onClick={() => setCurrentModal('ModalFavouriteFeekspots')}
      />

      <hr className="separator show-on-responsive" />

      <div className="profile-last-likes">
        <Text fontSize={21} color={BLACK} fontWeight={600}>
          Últimos me gusta
        </Text>
        <Button
          hideDisabledStyles
          onClick={() => setCurrentModal('ModalLastLikedFeekers')}
          disabled={
            !lastLikedFeekers || lastLikedFeekers?.length === 0
          }
        >
          <AvatarsCabinet
            avatars={lastLikedFeekers
              ?.slice(0, 6)
              ?.map((like) => like.avatar)}
            avatarsSize={48}
            overlap={18}
            {...(lastLikedFeekers?.length > 5 && {
              text: `+ ${lastLikedFeekers?.length - 5}`,
            })}
            textBGColor={BLACK}
          />
        </Button>
      </div>

      <div className="profile-strikes">
        <Text fontSize={21} color={BLACK} fontWeight={600}>
          Strikes
        </Text>
        <ButtonPrimary
          className="profile-modal-button"
          height={48}
          label={
            <Text fontSize={16} color={BLACK}>
              <strong>{strike}/3</strong> strikes
            </Text>
          }
          strokeVariant
          strokeColor={GRAY_MID}
          icon={
            <Icon
              icon={Chevron}
              color={BLACK}
              width={14}
              rotate={-90}
            />
          }
          iconPosition="right"
          onClick={() => setCurrentModal('ModalStrikesCount')}
        />
      </div>

      <section className="profile-referral">
        <Text
          className="title"
          color={BLACK}
          fontSize={24}
          fontWeight={600}
        >
          Invita a tus amigxs
        </Text>

        <div className="referral-code-wrapper">
          <Text
            className="referral-description"
            color={BLACK}
            fontSize={14}
          >
            Tu username y código de invitación es:
            <Text
              className="referral-code"
              color={BLACK}
              fontSize={24}
              fontWeight={600}
            >
              {myReferralCode}
            </Text>
          </Text>

          <Button
            className="button"
            onClick={() => copyToClipboard(myReferralCode)}
          >
            <Icon
              color={GRAY_DARK}
              height={22}
              icon={StackedSheets}
            />
            Copiar
          </Button>
        </div>

        <div className="referral-points-wrapper">
          <Text
            className="referral-points-description"
            color={GRAY}
            fontSize={16}
          >
            Tu código ha sido usado <b>{referralPoints / 100 || 0}</b>{' '}
            veces.
          </Text>
          <Text
            className="referral-points"
            color={PURPLE_FEEK}
            fontSize={21}
            fontWeight={600}
          >
            <Icon color={PURPLE_FEEK} height={22} icon={Zap} />+
            {referralPoints}
          </Text>
        </div>

        <div className="referral-share">
          <a
            href={`https://api.whatsapp.com/send?text="¡Bienvenidx a Feek!, ${username} te invita a formar parte de la comunidad, en donde encontrarás Promociones exclusivas y recibirás recompensas por usar la app, tu código de acceso es: ${myReferralCode}, descárgala aquí: http://q-r.to/go-to-feek"`}
            target="_blank"
            rel="noreferrer"
          >
            <ButtonPrimary
              icon={<Icon icon={WhatsApp} size={20} color={WHITE} />}
              color={GREEN_WHATSAPP}
              fontSize={16}
              height={48}
              width="auto"
              label="Compartir por Whatsapp"
              layoutOnly
              gapMobile={10}
            />
          </a>
          <ButtonPrimary
            icon={<Icon icon={Share} height={22} color={WHITE} />}
            color={GRAY_DARK}
            height={48}
            width={48}
            onClick={() => setCurrentModal('ModalShareCampaign')}
          />
        </div>
      </section>
    </article>
  );
};

ProfileData.propTypes = {
  className: PropTypes.string,
  openContactData: PropTypes.func,
  lastLikedFeekers: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      name: PropTypes.string,
      profile_username: PropTypes.string,
      total_feek_points: PropTypes.number,
      _id: PropTypes.string,
    }),
  ),
  favouriteFeekspots: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      location: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
      }),
      id: PropTypes.string,
    }),
  ),
  profileFeekerData: PropTypes.shape({
    avatar: PropTypes.string,
    myReferralCode: PropTypes.string,
    username: PropTypes.string,
    backgroundImage: PropTypes.string,
    createdAt: PropTypes.string,
    momentsCompleted: PropTypes.number,
    totalRewardsWinner: PropTypes.number,
    totalFeekPoints: PropTypes.number,
    strike: PropTypes.number,
    referralPoints: PropTypes.number,
    pronoun: PropTypes.string,
    birthdate: PropTypes.string,
    bio: PropTypes.string,
    id: PropTypes.string,
  }),
};

export default ProfileData;
