/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import APIClient from 'redux/api';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { toast } from 'react-toastify';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import ButtonBack from 'components/buttons/ButtonBack';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { BLACK, PURPLE_FEEK, GREEN_WHATSAPP } from 'styles/colors';
import { ReactComponent as WhatsApp } from 'assets/icons/WhatsApp.svg';
import SplittedNumericalInput from 'components/inputs/SplittedNumericalInput';

const styles = css`
  .title {
    margin-block-end: 32px;
  }

  .input-code,
  .button-whatsapp {
    margin: 20px auto 0;
  }

  .button-back {
    margin-block-start: 32px;
  }

  .underline {
    text-decoration: underline;
  }

  ${MEDIA_QUERIES.mobile} {
    .input-code,
    .button-whatsapp {
      margin: 16px auto 0;
    }

    .button-back {
      margin-block-start: 30px;
    }

    .underline {
      margin-block-end: 30px;
    }
  }
`;

const CodeVerification = ({
  onSend,
  keyUser,
  className,
  onBackButton,
  contactMethod,
}) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = async (numberCode) => {
    setCode(numberCode);
    if (numberCode.length === 6) {
      setIsLoading(true);
      onSend(numberCode)
        ?.finally?.(() => setIsLoading(false))
        .catch?.((e) => setError(e.message));
    }
  };

  const resendCode = (sendOverWhatsapp) => {
    APIClient.resendVerifyCode(
      sendOverWhatsapp ? `whatsapp:${keyUser}` : keyUser,
    )
      .then(({ data }) => {
        if (data.success) {
          toast.success('Código reenviado');
        }
      })
      .finally(() => setIsLoading(false))
      .catch((error) => {
        if (error instanceof Error) {
          if (error.message instanceof Array) {
            setError(error.message.join(' '));
          } else {
            setError(error.message || 'Sucedió algo inesperado');
          }
        } else {
          setError(error || 'Sucedió algo inesperado');
        }
      });
  };

  return (
    <article css={styles} className={className}>
      <Text
        color={BLACK}
        fontSize={24}
        fontSizeMobile={18}
        align="center"
        fontWeight={600}
        className="title"
      >
        Confirma tu {contactMethod === 'sms' ? 'teléfono' : 'email'}
      </Text>

      <div>
        <Text
          fontSize={14}
          fontSizeMobile={16}
          color={BLACK}
          align="center"
        >
          Ingresa el código que te enviamos vía{' '}
          {contactMethod === 'sms' ? 'SMS' : 'email'} al número
        </Text>
        <Text
          fontSize={16}
          fontWeight={700}
          color={BLACK}
          align="center"
          lineHeight={22}
        >
          {keyUser}
        </Text>
      </div>

      <SplittedNumericalInput
        autoFocus
        length={6}
        value={code}
        disabled={isLoading}
        onChange={handleInputChange}
        className="input-code"
        error={error}
      />

      <Text fontSize={16} color={BLACK} align="center">
        ¿No recibiste el código?
      </Text>

      <Button>
        <Text
          fontSize={16}
          color={PURPLE_FEEK}
          align="center"
          className="underline"
          fontWeight={600}
          disabled={isLoading}
          onClick={() => resendCode()}
        >
          Reenviar código
        </Text>
      </Button>

      {contactMethod === 'sms' && (
        <ButtonPrimary
          height={40}
          width="fit-content"
          strokeVariant
          pillShape
          strokeColor={GREEN_WHATSAPP}
          textColor={GREEN_WHATSAPP}
          disabled={isLoading}
          className="button-whatsapp "
          label="Reenviar por Whatsapp"
          onClick={() => resendCode(true)}
          icon={
            <Icon icon={WhatsApp} color={GREEN_WHATSAPP} size={20} />
          }
        />
      )}

      <ButtonBack className="button-back" onClick={onBackButton} />
    </article>
  );
};

export default CodeVerification;

CodeVerification.propTypes = {
  className: PropTypes.string,
  onSend: PropTypes.func.isRequired,
  keyUser: PropTypes.string.isRequired,
  onBackButton: PropTypes.func.isRequired,
  contactMethod: PropTypes.oneOf(['sms', 'email']),
};
