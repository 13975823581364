/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { BLACK, WHITE } from 'styles/colors';
import Modal from 'components/modals/Modal/index';
import ButtonPrimary from 'components/buttons/ButtonPrimary';

const styles = css`
  width: 375px;
  padding: 24px;
  max-width: 650px;
  background-color: ${WHITE};

  .cursor-pointer {
    cursor: pointer;
  }

  .action-button {
    border-radius: 40px;
    height: 48px;
  }

  .image {
    width: 320px;
    height: 460px;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    object-fit: cover;
    border-radius: 16px;
    border: 1px solid #e5e5e5;
  }
`;

export default function ModalImagePreview({
  image,
  visible,
  disabled,
  onConfirm,
  onRequestClose,
}) {
  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <div css={styles}>
        {image && (
          <img
            className="image"
            src={URL.createObjectURL(image)}
            alt="preview"
          />
        )}
        <div className="row">
          <div className="col-md-6">
            <ButtonPrimary
              label="Regresar"
              strokeVariant
              fontSize={16}
              textColor={BLACK}
              strokeColor={BLACK}
              onClick={onRequestClose}
              className="action-button mt-3"
            />
          </div>
          <div className="col-md-6">
            <ButtonPrimary
              fontSize={16}
              color={BLACK}
              label="Confirmar"
              textColor={WHITE}
              onClick={onConfirm}
              disabled={disabled}
              className="action-button mt-3"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

ModalImagePreview.propTypes = {
  visible: PropTypes.bool,
  image: PropTypes.string,
  disabled: PropTypes.bool,
  onConfirm: PropTypes.func,
  onRequestClose: PropTypes.func,
};
