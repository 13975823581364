import { toast } from 'react-toastify';

export default function handleError(error) {
  console.error(error);

  if (error instanceof Error) {
    if (error.message instanceof Array) {
      toast.error(error.message.join(' '));
    } else {
      toast.error(error.message || 'Sucedió algo inesperado');
    }
  } else {
    toast.error(error || 'Sucedió algo inesperado');
  }
}
