/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import Icon from 'components/Icon';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_MANAGER } from 'constants/routes';
import { setCampaigns } from 'redux/app/managerApp';
import { ReactComponent as File } from 'assets/icons/File.svg';
import { GRAY_LIGHTEST_PLUS, PURPLE_FEEK } from 'styles/colors';
import CampaignsSlider from 'screens/manager/legacy/Campaigns/CampaignsSlider';
import CampaignsHistory from 'screens/manager/legacy/Campaigns/CampaignsHistory';

const styles = css`
  .showOnMobileOnly {
    display: none !important;
  }

  .container {
    display: grid;
    grid-template: auto minmax(314px, 1fr) minmax(314px, 1fr) / auto;
    height: 100%;
    width: 100%;
    background-color: ${GRAY_LIGHTEST_PLUS};
    padding: 45px;
  }

  .historyButtonContainer {
    display: flex;
    justify-content: end;
    margin-block-end: 46px;
  }

  .headerHistoryButton {
    display: inline-flex;
    justify-content: end;
    gap: 7px;
    flex: 0 0 max-content;
  }

  ${MEDIA_QUERIES.mobile} {
    .showOnMobileOnly {
      display: revert !important;
    }

    .hideOnMobile {
      display: none !important;
    }

    .container {
      padding: 40px 24px;
    }

    .historyButtonContainer {
      justify-content: space-between;
      margin-block-end: 30px;
    }

    .campaignsHistory {
      font-size: 14px;
    }

    .iconFile {
      width: 14px;
      height: 14px;
    }
  }
`;

function Campaigns() {
  const navigate = useNavigate();
  const { selectedFeekspotId, campaigns } = useSelector(
    (state) => state.managerApp,
  );
  const [showHistoryOfCampaigns, setShowHistoryOfCampaigns] =
    useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getCampaigns();
  }, [selectedFeekspotId]);

  const { campaignsCompleted, campaignsInProgress } = useMemo(
    () =>
      campaigns?.reduce(
        (prev, crr) => {
          return crr.status === 'Completada'
            ? {
                ...prev,
                campaignsCompleted: [...prev.campaignsCompleted, crr],
              }
            : crr.status === 'En curso'
            ? {
                ...prev,
                campaignsInProgress: [
                  ...prev.campaignsInProgress,
                  crr,
                ],
              }
            : prev;
        },
        { campaignsCompleted: [], campaignsInProgress: [] },
      ),
    [campaigns],
  );

  const getCampaigns = () => {
    APIClient.getCampaignsByFeekspot(selectedFeekspotId)
      .then(({ data }) =>
        dispatch(
          setCampaigns(
            data?.map((campaign) => ({
              ...campaign.campaign,
              moments: campaign.moments || [],
            })),
          ),
        ),
      )
      .catch(handleError);
  };

  const handleEditCampaign = (campaign_id) => {
    navigate({
      pathname: ROUTES_MANAGER.ADD_CAMPAIGNS,
      search: `?update_id=${campaign_id}`,
    });
  };

  const handleCloneAndEditCampaign = (campaign_id, step) => {
    navigate({
      pathname: ROUTES_MANAGER.ADD_CAMPAIGNS,
      search: !step
        ? `?clone_id=${campaign_id}`
        : `?clone_id=${campaign_id}&step=${step}`,
    });
  };

  const handleDeleteCampaign = (campaign_id) => {
    APIClient.deleteCampaign(campaign_id)
      .then(() => {
        getCampaigns();
        toast.success('Campaña borrada con éxito');
      })
      .catch(handleError);
  };

  return (
    <div css={[styles]}>
      {campaignsCompleted?.length + campaignsInProgress?.length > 0 &&
        (showHistoryOfCampaigns ? (
          <CampaignsHistory
            setShowHistoryOfCampaigns={setShowHistoryOfCampaigns}
            handleCloneAndEditCampaign={handleCloneAndEditCampaign}
            handleDeleteCampaign={handleDeleteCampaign}
            campaigns={campaigns}
          />
        ) : (
          <div className="container">
            <div className="historyButtonContainer">
              <Text fontSize={20} className="showOnMobileOnly">
                Campañas
              </Text>

              <Button
                onClick={() => {
                  setShowHistoryOfCampaigns(true);
                }}
                className="headerHistoryButton"
              >
                <Icon
                  icon={File}
                  color={PURPLE_FEEK}
                  size={16}
                  className="iconFile"
                />

                <Text
                  color={PURPLE_FEEK}
                  fontSize={16}
                  fontWeight="600"
                  className="campaignsHistory"
                >
                  Historial de campañas
                </Text>
              </Button>
            </div>

            <CampaignsSlider
              campaigns={campaignsInProgress}
              handleEditCampaign={handleEditCampaign}
              handleCloneAndEditCampaign={handleCloneAndEditCampaign}
              handleDeleteCampaign={handleDeleteCampaign}
              title="Campañas en curso"
              showValidateInfo={true}
              selectedFeekspotId={selectedFeekspotId}
            />

            <CampaignsSlider
              campaigns={campaignsCompleted}
              handleEditCampaign={handleEditCampaign}
              handleCloneAndEditCampaign={handleCloneAndEditCampaign}
              handleDeleteCampaign={handleDeleteCampaign}
              title="Campañas completadas"
              showValidateInfo={false}
              selectedFeekspotId={selectedFeekspotId}
            />
          </div>
        ))}
    </div>
  );
}

export default Campaigns;
