/** @jsxImportSource @emotion/react */
import { Field } from 'formik';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Icon from 'components/Icon';
import { BLACK } from 'styles/colors';
import { propTypeLength } from 'utils/propValidation';
import { forwardInputEventHandlers } from 'utils/form.utils';
import InputWrapper from 'components/inputs/InputWrapper/input';

const IGNORED_KEYS = ['e', 'E', '-', '+', '.'];

const InputText = forwardRef(
  (
    {
      icon,
      width,
      error,
      label,
      height,
      onKeyDown,
      className,
      defaultValue,
      heightMobile,
      type = 'text',
      ...restProps
    },
    ref,
  ) => {
    const styles = css`
      display: flex;
      align-items: start;
      height: 100%;
      width: 100%;
      color: ${BLACK};
      border: none;
      background-color: transparent;
      font-size: 16px;
      outline: none;

      padding-block: ${label ? '24.5px 10.5px' : '14.5px 14.5px'};
      padding-inline: ${icon ? '10px' : '25px 20px'};

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    `;

    const handleKeyDown = (e) => {
      if (IGNORED_KEYS.includes(e.key) && restProps.type === 'number')
        e.preventDefault();

      onKeyDown?.(e);
    };

    return (
      <InputWrapper
        icon={icon}
        width={width}
        label={label}
        error={error}
        height={height}
        className={className}
        heightMobile={heightMobile}
      >
        <input
          css={styles}
          defaultValue={defaultValue}
          ref={ref}
          type={type}
          {...restProps}
          onKeyDown={handleKeyDown}
        />
      </InputWrapper>
    );
  },
);

export const FormInputText = ({
  icon,
  name,
  width,
  error,
  height,
  heightMobile,
  type = 'text',
  ...restProps
}) => {
  return (
    <Field name={name}>
      {({ meta, field }) => (
        <InputText
          heightMobile={heightMobile}
          icon={icon}
          type={type || 'text'}
          height={height}
          width={width}
          name={name}
          value={meta.value}
          error={meta.touched && (meta.error || error)}
          {...restProps}
          {...forwardInputEventHandlers(restProps, field)}
        />
      )}
    </Field>
  );
};

const sharedPropTypes = {
  width: propTypeLength,
  icon: PropTypes.shape({
    type: PropTypes.oneOf([Icon]),
  }),
  name: PropTypes.string,
  height: propTypeLength,
  type: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  heightMobile: propTypeLength,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

FormInputText.propTypes = {
  ...sharedPropTypes,
};

InputText.propTypes = {
  onKeyDown: PropTypes.func,
  defaultValue: PropTypes.string,
  ...sharedPropTypes,
};

InputText.displayName = 'InputText';
FormInputText.displayName = 'FormInputText';

export default InputText;
