/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import Text from 'components/Text';
import Icon from 'components/Icon';
import { useSelector } from 'react-redux';
import { ROUTES_FEEKAPP } from 'constants/routes';
import getFormattedUrl from 'utils/getFormattedUrl';
import { campaignTypeMiniIconsById } from 'constants';
import { GRAY, BLACK, GRAY_MID, GRAY_LIGHTEST } from 'styles/colors';
import { ReactComponent as ZapMini } from 'assets/icons/ZapMini.svg';

const styles = css`
  width: 330px;
  flex: 0 0 330px;

  .campaign-card-img {
    user-select: none;
    -webkit-user-select: none;
    pointer-events: none;
    width: 100%;
    aspect-ratio: 4/3;
    border-radius: 30px;
    border: 1px solid ${GRAY_LIGHTEST};
    object-fit: cover;
  }

  .campaign-card-info {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      justify-content: space-between;
      gap: 10px;

      .feekspot-name {
        text-transform: uppercase;
      }

      .places-badge {
        white-space: nowrap;
        padding: 6px 12px;
        border-radius: 30px;
        border: 1px solid ${GRAY_MID};
      }
    }

    .title {
      margin-block: 12px;
    }

    .campaign-type-info {
      gap: 12px;

      .icon-text {
        gap: 4px;
      }

      .distance {
        margin-left: auto;
        white-space: nowrap;
      }
    }
  }

  .ellipsed {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .flexRow {
    display: flex;
    align-items: center;
  }
`;

export default function CardCampaign(props) {
  const {
    type,
    image,
    title,
    feekspot,
    distance,
    className,
    campaignId,
    places = 0,
    imageContent,
    colorVariant,
    feekpoints = 0,
  } = props;
  const campaignTypes = useSelector(
    (state) => state.entities,
  ).campaignTypes;

  const typeText = campaignTypes[type]?.name;

  const WrapperElement = campaignId ? Link : 'div';

  return (
    <WrapperElement
      css={styles}
      className={className}
      {...(campaignId && {
        to: getFormattedUrl({
          route: ROUTES_FEEKAPP.CAMPAIGN.replace(/:\w*/, campaignId),
        }),
      })}
    >
      {imageContent}
      {image && (
        <img
          src={image}
          className="campaign-card-img"
          alt={'Feek ' + title + ' ' + feekspot}
        />
      )}

      <div className="campaign-card-info">
        <div className="header flexRow">
          <Text
            className="feekspot-name ellipsed"
            color={BLACK}
            fontSize={16}
            fontWeight={700}
          >
            {feekspot}
          </Text>
          <Text
            className="places-badge"
            color={GRAY}
            fontSize={14}
            fontWeight={600}
          >
            {places} Lugar{places != 1 && 'es'}
          </Text>
        </div>

        <Text
          color={BLACK}
          fontSize={18}
          fontWeight={500}
          className="title ellipsed"
        >
          {title}
        </Text>

        <div className="campaign-type-info flexRow">
          {typeText && (
            <Text
              color={colorVariant || GRAY}
              fontSize={14}
              lineHeight={16}
              fontWeight={600}
              className="icon-text flexRow ellipsed"
            >
              <Icon
                icon={
                  type in campaignTypeMiniIconsById
                    ? campaignTypeMiniIconsById[type]
                    : ZapMini
                }
                color={colorVariant || GRAY}
                height={16}
              />
              {typeText}
            </Text>
          )}

          <Text
            color={colorVariant || GRAY}
            fontSize={14}
            lineHeight={16}
            fontWeight={600}
            className="icon-text flexRow"
          >
            <Icon
              icon={ZapMini}
              color={colorVariant || GRAY}
              height={16}
            />
            +{feekpoints}
          </Text>

          {distance && (
            <Text
              color={colorVariant || GRAY}
              fontSize={14}
              lineHeight={16}
              fontWeight={600}
              className="distance"
            >
              {Math.round(distance * 10) / 10} km
            </Text>
          )}
        </div>
      </div>
    </WrapperElement>
  );
}

CardCampaign.propTypes = {
  type: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
  places: PropTypes.number,
  distance: PropTypes.number,
  feekspot: PropTypes.string,
  className: PropTypes.string,
  campaignId: PropTypes.string,
  feekpoints: PropTypes.number,
  colorVariant: PropTypes.string,
  imageContent: PropTypes.element,
};
