/** @jsxImportSource @emotion/react */
import moment from 'moment';
import PropTypes from 'prop-types';
import es from 'date-fns/locale/es';
import { css } from '@emotion/react';
import { useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

import {
  BLACK,
  WHITE,
  GRAY_TEXT,
  PURPLE_FEEK,
  PURPLE_MEDIUM,
} from 'styles/colors';
import APIClient from 'redux/api';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { weekDaysEs } from 'constants';
import { toast } from 'react-toastify';
import handleError from 'utils/handleError';
import { setMoments } from 'redux/entities';
import { MEDIA_QUERIES } from 'styles/constants';
import Modal from 'components/modals/Modal/index';
import InputText from 'components/inputs/InputText';
import InputSelect from 'components/inputs/InputSelect';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as Cash } from 'assets/icons/Cash.svg';
import { ReactComponent as ZapMini } from 'assets/icons/ZapMini.svg';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import { ReactComponent as StarMini } from 'assets/icons/StarMini.svg';
import { ReactComponent as ClockMini } from 'assets/icons/ClockMini.svg';

const styles = css`
  width: 550px;
  max-width: 650px;
  padding: 20px 40px 40px;
  background-color: ${WHITE};

  .list {
    margin-block: 0 24px;

    .list-item {
      display: flex;
      margin-top: 15px;
      align-items: center;
      gap: 8px;
    }
  }

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px 20px;
    max-width: 100%;
    margin-block: 24px;

    > .input-wrapper {
      > :first-child {
        margin-bottom: 10px;
      }

      :last-child > :first-child,
      :has(+ :last-child) > :first-child {
        margin-bottom: 8px;
      }

      > :nth-child(2) {
        min-width: 0;
      }

      .select-input input[type='time'] {
        color: ${GRAY_TEXT};
        font-size: 14px;
      }

      .dropDownDateRange {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        padding: 0 10px;
        border-radius: 10px;
        border: 1px solid ${PURPLE_MEDIUM};

        &,
        * {
          line-height: 100%;
        }

        :hover {
          border: 1px solid ${PURPLE_FEEK};
        }
      }
    }

    .full-row {
      grid-column: 1/3;
      margin-block-start: 9px;
    }
  }

  .action-button {
    margin-inline: auto;
  }

  ${MEDIA_QUERIES.mobile} {
    width: auto;
    max-width: initial;
    padding: 12px 0;
    background-color: ${WHITE};
  }
`;

registerLocale('es', es);
const numberOfDaysToAllowBookAfterToday = 15;
export default function ModalBooking({
  visible,
  endTime,
  momentId,
  startTime,
  momentDate,
  momentTime,
  campaignId,
  availableDays,
  onRequestClose,
  feekspotBranches,
  campaignBranches,
}) {
  const dispatch = useDispatch();
  const [date, setDate] = useState(
    momentDate ? moment(momentDate).utc() : '',
  );
  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState(
    momentTime || moment(startTime, 'HH').toDate(),
  );
  const [branch, setBranch] = useState(
    campaignBranches?.length === 1 ? campaignBranches[0]?._id : null,
  );

  const { campaigns } = useSelector((state) => state.entities);
  const userId = useSelector((state) => state.feekApp.id);

  const selectedCampaign = useMemo(
    () => campaigns[campaignId],
    [campaigns, campaignId],
  );
  const [diners, setDiners] = useState(selectedCampaign?.min_diners);

  const branchesOptions = useMemo(() => {
    return campaignBranches?.map((branch) => ({
      value: branch._id,
      label: branch.name,
    }));
  }, [campaignBranches]);

  const dinersOptions = useMemo(() => {
    return Array.from(
      {
        length:
          20 -
          (selectedCampaign?.min_diners
            ? (selectedCampaign?.min_diners || 1) - 1
            : 0),
      },
      (_, index) => ({
        label: index + (selectedCampaign?.min_diners || 1),
        value: index + (selectedCampaign?.min_diners || 1),
      }),
    );
  }, [selectedCampaign]);

  const disabledDays = useMemo(() => {
    return Array(numberOfDaysToAllowBookAfterToday)
      .fill(null)
      .reduce(
        (prev, _, i) => {
          return [
            ...prev,
            moment(prev[i]).add(1, 'day').toISOString(true),
          ];
        },
        [moment().toISOString(true)],
      )
      .filter(
        (dateString) =>
          !availableDays
            ?.map((day) => day.day)
            .includes(weekDaysEs[moment(dateString).format('dddd')]),
      )
      .reduce((prev, crr) => [...prev, crr], []);
  }, [availableDays]);

  const isTimeValid = useMemo(() => {
    const validHours = (
      endTime
        ? Array(
            Math.floor(
              moment
                .duration(
                  moment(endTime, 'HHmm').diff(
                    moment(endTime, 'HHmm').isBefore(
                      moment(startTime, 'HHmm'),
                    )
                      ? moment(startTime, 'HHmm').subtract(1, 'day')
                      : moment(startTime, 'HHmm'),
                  ),
                )
                .asHours(),
            ) + 1,
          )
        : []
    )
      .fill(null)
      .map((_, i) => {
        return moment(startTime, 'HH').add(i, 'hours').hours();
      });

    const indexOfSelectedHour = validHours.indexOf(
      moment(time).hours(),
    );

    const minutesStartTimeValid =
      indexOfSelectedHour === 0
        ? moment(time).minutes() >=
          moment(startTime, 'HHmm').minutes()
        : true;

    const minutesEndTimeValid =
      indexOfSelectedHour === validHours.length - 1
        ? moment(time).minutes() <= moment(endTime, 'HHmm').minutes()
        : true;

    return (
      validHours.includes(moment(time).hours()) &&
      minutesStartTimeValid &&
      minutesEndTimeValid
    );
  }, [endTime, startTime, time]);

  const handleBookMoment = () => {
    setIsLoading(true);

    (momentId
      ? APIClient.updateMoment(
          momentId,
          moment(date).toDate(),
          moment(time).format('HH:mm'),
          branch,
          diners,
        )
      : APIClient.createMoment(
          userId,
          campaignId,
          moment(date).toDate(),
          moment(time).format('HH:mm'),
          campaignBranches?.length === 1
            ? campaignBranches[0]?._id
            : branch,
          diners,
        )
    )
      .then(({ data }) => {
        dispatch(setMoments({ [data._id]: data }));
        if (momentId) toast.success('¡Momento editado!');
        setIsLoading(false);
        onRequestClose?.();
      })
      .catch((e) => {
        handleError(e);
        setIsLoading(false);
      });
  };

  const heading = momentId ? (
    <Text
      color={BLACK}
      fontSize={24}
      fontSizeMobile={20}
      align="center"
    >
      Edita tu lugar agendado
    </Text>
  ) : (
    <>
      <Text
        color={BLACK}
        fontSize={24}
        fontSizeMobile={20}
        align="center"
      >
        Estás a punto de agendar tu lugar, recuerda:
      </Text>
      <ul className="list">
        <ListItem
          icon={ClockMini}
          primaryText="Llegar a tiempo,"
          secondaryText="revisa la fecha y hora."
        />
        <ListItem
          icon={StarMini}
          primaryText="Crea contenido"
          secondaryText="atractivo para el Feekspot"
        />
        <ListItem
          icon={Cash}
          primaryText="¡Deja propina!"
          secondaryText="calcúlala sin el descuento."
        />
        <ListItem
          icon={ZapMini}
          primaryText="¡Genera puntos!"
          secondaryText="Para ganar Recompensas."
        />
      </ul>
    </>
  );

  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <div css={styles}>
        {heading}

        <div className="form">
          {campaignBranches && campaignBranches.length === 1 && (
            <div className="input-wrapper">
              <Text fontSize={16} fontWeight="600">
                {feekspotBranches.length > campaignBranches.length &&
                campaignBranches?.length === 1
                  ? 'Sólo aplica en sucursal'
                  : 'Selecciona la sucursal'}
              </Text>

              <InputSelect
                className="select-input"
                options={branchesOptions}
                placeholder="Selecciona la sucursal"
                value={branch}
                onChange={(value) => setBranch(value.target.value)}
              />
            </div>
          )}

          <div className="input-wrapper">
            <Text fontSize={16} fontWeight={600}>
              No. de personas
            </Text>
            <InputSelect
              options={dinersOptions}
              value={diners}
              className="select-input"
              placeholder="No. de personas"
              onChange={(value) => setDiners(value.target.value)}
              defaultValue={selectedCampaign?.min_diners}
            />
          </div>

          <Text fontSize={16} fontWeight={600} className="full-row">
            Selecciona la fecha
          </Text>

          <div className="input-wrapper">
            <Text fontWeight={500} fontSize={14}>
              Día
            </Text>
            <ReactDatePicker
              customInput={
                <div className="dropdownDateRange">
                  <Text fontSize={14}>
                    {date
                      ? moment(date)
                          .locale('es')
                          .format('D MMM. YYYY')
                          .replace(/[a-z]/, (char) =>
                            char.toUpperCase(),
                          )
                          .replace(/\./, '')
                      : 'Seleccionar'}
                  </Text>
                  <Icon icon={Chevron} size={14} />
                </div>
              }
              minDate={moment().toDate()}
              maxDate={moment()
                .add(numberOfDaysToAllowBookAfterToday, 'days')
                .toDate()}
              className="dropDownDateRange"
              placeholder="Seleccionar"
              excludeDates={disabledDays.map((date) =>
                moment(date).toDate(),
              )}
              locale="es"
              onChange={(date) => setDate(date)}
              withPortal={false}
              selected={moment(date || undefined).toDate()}
            />
          </div>

          <div className="input-wrapper">
            <Text fontWeight={500} fontSize={14}>
              Hora
            </Text>
            <InputText
              className="select-input"
              type="time"
              onChange={(value) =>
                setTime(moment(value.target.value, 'HH').toDate())
              }
              defaultValue={moment(time).format('hh:mm')}
            />
          </div>
        </div>

        <ButtonPrimary
          width={300}
          pillShape
          label="Confirmar lugar"
          className="action-button mt-3"
          onClick={handleBookMoment}
          disabled={
            isLoading ||
            !isTimeValid ||
            !date ||
            (campaignBranches.length > 1 && !branch)
          }
        />
      </div>
    </Modal>
  );
}

const ListItem = ({ primaryText, secondaryText, icon }) => {
  return (
    <li className="list-item">
      <Icon
        icon={icon}
        color={BLACK}
        size={14}
        className="list-icon"
      />
      <Text fontSize={14} lineHeight={16} color={BLACK}>
        <b>{primaryText}</b> {secondaryText}
      </Text>
    </li>
  );
};

ModalBooking.propTypes = {
  visible: PropTypes.bool,
  endTime: PropTypes.string,
  momentId: PropTypes.string,
  momentDate: PropTypes.string,
  startTime: PropTypes.string,
  momentTime: PropTypes.string,
  campaignId: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  availableDays: PropTypes.arrayOf(PropTypes.object),
  feekspotBranches: PropTypes.arrayOf(PropTypes.object),
  campaignBranches: PropTypes.arrayOf(PropTypes.object),
};

ListItem.propTypes = {
  icon: PropTypes.object,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
};
