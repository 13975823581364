/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  WHITE,
  GRAY_TEXT,
  GRAY_DEFAULT,
  GRADIENT_BLUE,
  GRADIENT_PURPLE,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import { setFeekspots } from 'redux/entities';
import Button from 'components/buttons/Button';
import ButtonPrimary from 'components/buttons/ButtonPrimary';

const styles = css`
  display: flex;
  flex-direction: column;
  gap: 30px;

  .header {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .categoriesList {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    padding-top: 10px;
    content: max-content;
  }

  .category {
    display: flex;
    margin: 5px;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding: 10px 30px;
    border: 1px solid ${GRAY_DEFAULT};
    border-radius: 16px;
    cursor: pointer;
    color: ${GRAY_TEXT};
    font-size: 14px;
    user-select: none;
  }

  .categoryActive {
    color: ${WHITE};
    background: ${GRADIENT_BLUE};
  }

  .buttonSend {
    background: ${GRADIENT_PURPLE};
    color: ${WHITE};
    border-radius: 16px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    width: 100%;
    padding-block: 19px;
  }
`;

function FeekspotCategoriesSelector({
  className,
  title,
  buttonText,
  onSuccess,
  categories,
  feekspot,
}) {
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState(
    new Set(...(feekspot?.categories || [])),
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedItems(new Set(feekspot?.categories || []));
  }, [feekspot]);

  async function handleClick() {
    setIsLoading(true);
    try {
      const response = await APIClient.updateFeekspot(
        { categories: Array.from(selectedItems) },
        feekspot._id,
      );

      if (response.status === 200) {
        await APIClient.getFeekspotsByCustomer()
          .then(({ data }) => {
            const feekspots = data.reduce(
              (prev, crr) => ({ ...prev, [crr.feekspot._id]: crr }),
              {},
            );
            dispatch(setFeekspots(feekspots));
          })
          .then(() => {
            setIsLoading(false);
            onSuccess();
          });
      }
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  }

  const handleSelectCategory = (selected, id) => {
    if (!selected) {
      setSelectedItems(new Set(selectedItems).add(id));
    } else {
      const temp = new Set(selectedItems);
      temp.delete(id);
      setSelectedItems(temp);
    }
  };

  return (
    <div className={className} css={styles}>
      <header className="header">
        <Text fontSize={20} fontWeight="600">
          {title}
        </Text>
        <Text fontSize={16}>
          Esta información nos ayudará a conectarte con los Feekers
          adecuados, e identificará tu negocio con claridad.
        </Text>
      </header>

      <div className="categoriesContainer">
        <Text fontSize={16} fontWeight="500">
          Selecciona las 3 categorías que mejor identifiquen a tu
          negocio.
        </Text>

        <div className="categoriesList">
          {Object.values(categories).map(({ _id, name }) => {
            const selected = selectedItems.has(_id);

            return (
              <Button
                onClick={() => handleSelectCategory(selected, _id)}
                className={
                  'category' + (selected ? ' categoryActive' : '')
                }
                disabled={!selected && selectedItems.size > 2}
                key={_id}
              >
                {name}
              </Button>
            );
          })}
        </div>
      </div>

      <ButtonPrimary
        onClick={handleClick}
        label={buttonText}
        disabled={selectedItems.size < 1 || isLoading}
      />
    </div>
  );
}

FeekspotCategoriesSelector.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  onSuccess: PropTypes.func,
  categories: PropTypes.object,
  feekspot: PropTypes.object,
};

export default FeekspotCategoriesSelector;
