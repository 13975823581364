/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';

import APIClient from 'redux/api';
import Text from 'components/Text';
import { toast } from 'react-toastify';
import handleError from 'utils/handleError';
import Modal from 'components/modals/Modal';
import { BLACK, WHITE } from 'styles/colors';
import { setFeeker } from 'redux/app/feekApp';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import CodeVerification from 'components/CodeVerification';
import { FormInputText } from 'components/inputs/InputText';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { FormPhoneInput } from 'components/inputs/PhoneInput';

const styles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  inline-size: min(33vw, 500px);
  padding: 24px;

  .cancel-button {
    text-decoration: underline;
  }

  ${MEDIA_QUERIES.mobile} {
    gap: 20px;
  }
`;

function ModalEditPhoneOrEmail({
  type = 'phone',
  onRequestClose,
  visible,
  ...restProps
}) {
  const dispatch = useDispatch();
  const [keyUser, setKeyUser] = useState(false);
  const [isValidationSent, setIsValidationSent] = useState(false);

  const formik = useFormik({
    validateOnMount: false,
    onSubmit: () => {
      formik?.setSubmitting(true);
      APIClient.linkEmailOrPhone(formik?.values)
        .then(() => setKeyUser(formik?.values?.keyUser))
        .then(() => {
          formik.resetForm();
          setIsValidationSent(true);
        })
        .finally(() => formik?.setSubmitting(false))
        .catch(handleError);
    },
    enableReinitialize: true,
    initialValues: {
      keyUser: '',
    },
    validationSchema: Yup.object().shape({
      keyUser:
        type === 'phone'
          ? Yup.string()
              .required('El télefono es requerido')
              .min(
                12,
                'El télefono tiene que tener al menos 10 numeros',
              )
          : Yup.string()
              .email('Necesitas una dirección de email válida')
              .required('El email es requerido'),
    }),
  });

  function handleClose() {
    formik.resetForm();
    onRequestClose?.();
  }

  const handleVerifyUser = (verificationCode) => {
    return APIClient.verifyCode({
      key_validate: keyUser,
      code: verificationCode,
    }).then(({ data: { valid } }) => {
      if (!valid) throw new Error('El código es erróneo');
      dispatch(
        setFeeker({
          [type === 'phone' ? 'feekerPhone' : 'feekerEmail']: keyUser,
          [type === 'phone'
            ? 'isFeekerVerifiedEmail'
            : 'isFeekerVerifiedPhone']: true,
        }),
      );
      toast.success(
        `${
          type === 'phone' ? 'Teléfono' : 'Email'
        } cambiado con éxito`,
      );
      handleClose();
      setIsValidationSent(false);
    });
  };

  return (
    <Modal
      visible={visible}
      onRequestClose={handleClose}
      {...restProps}
      withoutCloseButton={isValidationSent}
    >
      {isValidationSent ? (
        <CodeVerification
          onBack={() => setIsValidationSent(false)}
          contactMethod={type === 'phone' ? 'sms' : 'email'}
          onSend={handleVerifyUser}
          keyUser={keyUser}
        />
      ) : (
        <FormikProvider value={formik}>
          <Form css={styles} id="signInForm" className="signInForm">
            {type === 'phone' ? (
              <>
                <Text
                  fontSize={24}
                  fontSizeMobile={20}
                  fontWeight={600}
                >
                  Ingresa tu teléfono
                </Text>
                <FormPhoneInput height={48} name="keyUser" />
              </>
            ) : (
              <>
                <Text
                  fontSize={24}
                  fontSizeMobile={20}
                  fontWeight={600}
                >
                  Ingresa tu email
                </Text>
                <FormInputText
                  placeholder="email"
                  className="email-input"
                  name="keyUser"
                  width="100%"
                  type="email"
                />
              </>
            )}

            <ButtonPrimary
              width={343}
              disabled={
                !formik.dirty ||
                !formik.isValid ||
                formik.isSubmitting
              }
              pillShape
              onClick={formik.submitForm}
              label="Confirmar"
              fontSize={16}
              textColor={WHITE}
              color={BLACK}
              style={{
                marginBlockStart: type === 'phone' ? '-27px' : '-8px',
              }}
            />

            <Button onClick={handleClose} className="cancel-button">
              Cancelar
            </Button>
          </Form>
        </FormikProvider>
      )}
    </Modal>
  );
}

ModalEditPhoneOrEmail.propTypes = {
  type: PropTypes.oneOf(['phone', 'email']),
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default ModalEditPhoneOrEmail;
