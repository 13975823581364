/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import APIClient from 'redux/api';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import { ROUTES_FEEKAPP } from 'constants/routes';
import { setCustomer } from 'redux/app/managerApp';
import { BLACK, PURPLE_INACTIVE } from 'styles/colors';
import ButtonBack from 'components/buttons/ButtonBack';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { FormInputPassword } from 'components/inputs/InputPassword';
import PasswordSecurityLevel from 'components/PasswordSecurityLevel';

const styles = css`
  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .password-level {
    margin-top: 0;
    height: auto;
  }

  .mt-32 {
    margin-top: 32px;
  }

  .mb-32 {
    margin-bottom: 32px;
  }
`;

const formNewPasswordInitialValue = {
  password: '',
  confirmPassword: '',
};

const formNewPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('La contraseña es requerida')
    .min(6, 'Debe tener al menos 6 caracteres')
    .matches(/^(?=.*[A-Z])/, 'Debe contener al menos una mayúscula')
    .matches(/^(?=.*[0-9])/, 'Debe contener al menos un número'),
  confirmPassword: Yup.string()
    .required('La contraseña es requerida')
    .oneOf(
      [Yup.ref('password'), null],
      'Las contraseñas no coinciden',
    ),
});

export default function NewPassword({ onBack, keyUser }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);

    try {
      const {
        status,
        data: { access_token, user },
      } = await APIClient.resetPassword({
        key_user: keyUser,
        new_password: values.password,
      });
      if (status === 201) {
        APIClient.setAuthenticatedInstance(access_token);
        dispatch(setCustomer(user));
      }
      navigate(ROUTES_FEEKAPP.EXPLORE);
    } catch (error) {
      actions.setSubmitting(false);
      handleError(error);
    }
  };

  return (
    <div css={styles}>
      <Text
        color={BLACK}
        fontSize={21}
        align="center"
        fontWeight={600}
        className="mb-32"
      >
        Crea tu nueva contraseña
      </Text>
      <Formik
        validateOnMount
        onSubmit={handleSubmit}
        initialValues={formNewPasswordInitialValue}
        validationSchema={formNewPasswordValidationSchema}
      >
        {({
          isValid,
          resetForm,
          submitForm,
          isSubmitting,
          values,
        }) => {
          return (
            <Form className="formContainer">
              <FormInputPassword
                name="password"
                placeholder="Contraseña"
                heightMobile={48}
              />

              <FormInputPassword
                name="confirmPassword"
                placeholder="Confirmar contraseña"
                heightMobile={48}
              />

              <PasswordSecurityLevel
                className="password-level"
                value={
                  [
                    values.password.length > 6,
                    values.password.match(/[0-9]/),
                    values.password.match(/[A-Z]/g),
                  ].filter((value) => Boolean(value)).length
                }
                maxValue={3}
              />

              <Text fontSize={12} color={PURPLE_INACTIVE}>
                Debe tener al menos 6 caracteres, una mayúscula y un
                número.
              </Text>

              <ButtonPrimary
                color={BLACK}
                pillShape
                label={'Guardar contraseña'}
                onClick={submitForm}
                disabled={!isValid || isSubmitting}
                textProps={{ fontSize: 16, fontWeight: 600 }}
                heightMobile={59}
              />

              <ButtonBack
                className="mt-32"
                onClick={() => {
                  resetForm();
                  onBack?.();
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

NewPassword.propTypes = {
  keyUser: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
};
