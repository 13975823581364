/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import * as COLORS from 'styles/colors';
import { propTypeLength } from 'utils/propValidation';
import { getLength } from 'utils/cssStringManipulation';

const colorsArray = Object.values(COLORS);

const getKeyFrames = (color, secondaryColor, spinningDuration) => {
  let keyFrames = [];
  let currentPercentage = 0;

  for (let i = 0; i <= 50 * spinningDuration; i++) {
    currentPercentage = i / spinningDuration;

    keyFrames.splice(
      i,
      0,
      `${currentPercentage}% {background: conic-gradient(${color} ${
        currentPercentage * 2
      }%, ${secondaryColor} 0)}`,
    );

    keyFrames.push(
      `${
        50 + currentPercentage
      }% {background: conic-gradient(${secondaryColor} ${
        currentPercentage * 2
      }%, ${color} 0)}`,
    );
  }

  return keyFrames;
};

function ProgressIndicatorSpinner({
  value,
  max = 1,
  className,
  size = 24,
  spinningDuration = 2.75,
  color = COLORS.PURPLE_FEEK,
  secondaryColor = COLORS.GRAY_LIGHTEST,
  thickness = typeof size === 'number' ? size / 6 : 4,
}) {
  const styles = css`
    appearance: none;
    display: block;
    ${getLength({ width: size, height: size })};
    aspect-ratio: 1;
    border-radius: 50%;
    background: conic-gradient(
      ${color + ' 0% ' + (value ? (value * 100) / max + '%' : '0%')},
      ${secondaryColor} 0%
    );
    mask-image: radial-gradient(
      farthest-side,
      #0000 calc(99% - ${thickness}px),
      #000 calc(100% - ${thickness}px)
    );

    :indeterminate {
      animation: spinning ${spinningDuration}s linear infinite;
    }

    &::-webkit-progress-inner-element,
    &::-webkit-progress-bar {
      background: ${COLORS.TRANSPARENT};
    }

    ::-moz-progress-bar {
      background: ${COLORS.TRANSPARENT};
    }
    ::-webkit-progress-value {
      background: ${COLORS.TRANSPARENT};
    }

    @keyframes spinning {
      ${getKeyFrames(color, secondaryColor, spinningDuration)}
    }
  `;

  return (
    <progress
      max={max}
      css={styles}
      value={value}
      className={className}
    />
  );
}

export default ProgressIndicatorSpinner;

ProgressIndicatorSpinner.propTypes = {
  size: propTypeLength,
  max: PropTypes.number,
  value: PropTypes.number,
  className: PropTypes.string,
  thickness: PropTypes.number,
  spinningDuration: PropTypes.number,
  color: PropTypes.oneOf(colorsArray),
  secondaryColor: PropTypes.oneOf(colorsArray),
};
