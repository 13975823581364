import { useSelector } from 'react-redux';

export const useProfileCompletedStatus = () => {
  const { name, email, phone, selectedFeekspotId } = useSelector(
    (state) => state.managerApp,
  );
  const feekspots = useSelector((state) => state.entities.feekspots);
  const selectedFeekspot = feekspots[selectedFeekspotId]?.customer;

  const completionRequirements = [
    !!name && !!email && !!phone,
    !!selectedFeekspot?.instagram,
    !!selectedFeekspot?.image,
    !!selectedFeekspot?.background_image,
    !!selectedFeekspot?.location &&
      selectedFeekspot?.days?.length > 0,
    !!selectedFeekspot?.plan_id,
    selectedFeekspot?.categories.length > 0,
  ];

  return {
    isProfileCompleted: completionRequirements.every(
      (requirement) => requirement,
    ),
    completionRequirements: completionRequirements,
  };
};
