/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import Icon from 'components/Icon';
import { propTypeLength } from 'utils/propValidation';
import { getLength } from 'utils/cssStringManipulation';
import { WHITE, RED_NOTIFICATIONS } from 'styles/colors';
import { ReactComponent as WarningCircleBicolor } from 'assets/icons/WarningCircleBicolor.svg';

const InputWarningMessage = ({
  message,
  className,
  width = 'auto',
  height = 'auto',
  bgColor = WHITE,
  color = RED_NOTIFICATIONS,
  ...restProps
}) => {
  const styles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    min-height: 35px;
    ${getLength({ width, height })}
    padding: 6px 14px;
    position: absolute;
    bottom: 0px;
    transform: translateY(55%);
    justify-self: center;
    border-radius: 20px;
    background-color: ${bgColor};
    box-shadow: 0px 5px 10px 0.3px ${color}40;
  `;

  return message ? (
    <Text
      css={styles}
      className={className}
      align="center"
      fontSize={14}
      color={color}
      lineHeight={typeof height === 'number' ? height : 16.8}
      {...restProps}
    >
      <Icon
        size={20}
        icon={WarningCircleBicolor}
        color={RED_NOTIFICATIONS}
        secondaryColor={WHITE}
      />
      {typeof message === 'string' ? message : ''}
    </Text>
  ) : null;
};

InputWarningMessage.propTypes = {
  height: propTypeLength,
  width: propTypeLength,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.string,
};

InputWarningMessage.displayName = 'InputWarningMessage';

export default InputWarningMessage;
