/** @jsxImportSource @emotion/react */
import moment from 'moment';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { useEffect, useState, useRef, Fragment } from 'react';

import {
  GRAY,
  BLACK,
  WHITE,
  GRAY_COLD,
  PURPLE_FEEK,
  TRANSPARENT,
  WHITE_ALMOST,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import { titlesFeekApp } from 'constants';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { ReactComponent as X } from 'assets/icons/X.svg';
import ProgressIndicatorSpinner from 'components/ProgressIndicatorSpinner';

const styles = css`
  flex: 1;
  height: 100%;
  background-color: ${GRAY_COLD};

  .notifications {
    margin: 48px auto 65px;
    padding: 24px;
    border-radius: 20px;
    background-color: ${WHITE};
    width: clamp(500px, 686px, 45%);
    min-height: 305px;

    .heading {
      margin-block-end: 40px;
    }

    .loading-message {
      border: none;
      margin: 0 auto 12px;
    }

    .list {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 30px;
      margin-block: 40px;

      .notification {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;

        &:before {
          content: '';
          height: 12px;
          aspect-ratio: 1;
          border-radius: 50%;
          background-color: ${PURPLE_FEEK};
        }
        &.seen:before {
          background-color: ${TRANSPARENT};
        }

        .link-wrapper {
          flex: 1;
        }

        .button-wrapper {
          flex: 1;
          display: grid;
          grid-template:
            'image title' auto
            'image body' auto
            'image date' auto
            / auto 1fr;
          align-items: center;
          gap: 6px 12px;
          user-select: auto;

          .avatar {
            grid-area: image;
          }

          .title {
            text-transform: uppercase;
          }
        }

        .button-close {
          width: fit-content;
          .close-icon {
            padding: 8px;
            background-color: ${WHITE_ALMOST};
            border-radius: 50%;
          }
        }
      }
    }
  }
`;

function Notifications() {
  document.title = titlesFeekApp.Notifications;

  const lastNotificationRef = useRef(null);
  const [isLoading, setIsloading] = useState(false);
  const [notificationsData, setNotificationsData] = useState(null);

  function handleDeleteNotification(id) {
    return () => {
      APIClient.deleteNotification(id)
        .then(() =>
          setNotificationsData((crrNotifications) => {
            const updatedNotifications = { ...crrNotifications };
            delete updatedNotifications.docs[id];
            return updatedNotifications;
          }),
        )
        .catch(handleError);
    };
  }

  function fetchNotifications() {
    setIsloading(true);
    APIClient.getNotifications(10, notificationsData?.nextPage || 1)
      .then(({ data: { docs, nextPage } }) => {
        const notificationIds = [];

        setNotificationsData(
          !notificationsData && (!docs || docs.length === 0)
            ? { nextPage: null }
            : (crrNotifications) => ({
                nextPage: nextPage,
                docs: docs.reduce((acc, crr) => {
                  if (!crr.seen) notificationIds.push(crr._id);

                  return {
                    ...acc,
                    [crr._id]: {
                      user: crr.user,
                      title: crr.title,
                      body: crr.body,
                      image: crr.image,
                      link: crr.link,
                      seen: crr.seen,
                      created_at: crr.created_at,
                      web_link: crr.web_link,
                    },
                  };
                }, crrNotifications?.docs || {}),
              }),
        );

        return notificationIds;
      })
      .then((notificationIds) => {
        if (notificationIds)
          APIClient.setnotificationsSeen(notificationIds);
      })
      .catch(handleError)
      .finally(() => setIsloading(false));
  }

  useEffect(() => {
    if (isLoading) return;

    let infiniteScrollObserver = null;

    if (notificationsData?.nextPage && lastNotificationRef.current) {
      infiniteScrollObserver = new IntersectionObserver(
        ([list]) => {
          if (list.isIntersecting) fetchNotifications();
        },
        {
          threshold: 0.35,
        },
      );
      infiniteScrollObserver.observe(lastNotificationRef.current);
    } else if (!notificationsData) {
      fetchNotifications();
    }

    return () => {
      if (
        Object.hasOwn({ ...infiniteScrollObserver }, 'unobserve') &&
        lastNotificationRef.current
      )
        infiniteScrollObserver.unobserve(lastNotificationRef.current);
    };
  }, [isLoading]);

  return (
    <main css={styles}>
      <article className="notifications">
        <Text
          className="heading"
          fontSize={24}
          fontWeight={600}
          color={BLACK}
        >
          Notificaciones
        </Text>

        {notificationsData?.docs ? (
          <ul className="list">
            {Object.entries(notificationsData.docs).map(
              (
                [
                  _id,
                  { body, seen, title, image, web_link, created_at },
                ],
                i,
                { length },
              ) => {
                const WrapperElement = web_link ? Link : Fragment;

                return (
                  <li
                    className={'notification' + (seen ? ' seen' : '')}
                    key={_id + i}
                    {...(i === length - 1 && {
                      ref: lastNotificationRef,
                    })}
                  >
                    <WrapperElement
                      {...(web_link && {
                        to: web_link,
                        className: 'link-wrapper',
                      })}
                    >
                      <Button
                        layoutOnly
                        hideDisabledStyles={!web_link}
                        disabled={!web_link}
                        className="button-wrapper"
                      >
                        <Avatar
                          className="avatar"
                          image={image}
                          size={60}
                          name={title}
                        />
                        <Text
                          className="title"
                          fontSize={16}
                          lineHeight={16}
                          fontWeight={600}
                          color={BLACK}
                        >
                          {title}
                        </Text>
                        <Text fontSize={16} color={BLACK}>
                          {body}
                        </Text>
                        <Text
                          fontSize={14}
                          lineHeight={14}
                          color={GRAY}
                        >
                          {moment(created_at).fromNow()}
                        </Text>
                      </Button>
                    </WrapperElement>

                    <Button
                      className="button-close"
                      onClick={handleDeleteNotification(_id)}
                    >
                      <Icon
                        size={32}
                        color={BLACK}
                        className="close-icon"
                        icon={X}
                      />
                    </Button>
                  </li>
                );
              },
            )}
          </ul>
        ) : notificationsData?.nextPage === null ||
          (!notificationsData && !isLoading) ? (
          <Text fontSize={14} color={BLACK}>
            Sin notificaciones por el momento.
          </Text>
        ) : null}
        {isLoading && (
          <ProgressIndicatorSpinner className="loading-message" />
        )}
      </article>
    </main>
  );
}

export default Notifications;
