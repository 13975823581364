/** @jsxImportSource @emotion/react */
import { Field } from 'formik';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import { BLACK, GRAY } from 'styles/colors';
import { propTypeLength } from 'utils/propValidation';
import { forwardInputEventHandlers } from 'utils/form.utils';
import InputWrapper from 'components/inputs/InputWrapper/input';

const wrapperStyles = css`
  .char-count {
    position: absolute;
    top: 10px;
    right: 21px;
  }
`;

const InputTextArea = forwardRef(
  (
    {
      value,
      error,
      label,
      className,
      width = 343,
      heightMobile,
      height = 120,
      hideMaxLength,
      maxLength = 80,
      ...restProps
    },
    ref,
  ) => {
    const styles = css`
      --margin-top: ${maxLength === 0 || hideMaxLength
        ? '0'
        : '14px'};
      display: block;
      height: calc(100% - var(--margin-top));
      width: 100%;
      resize: none;
      color: ${BLACK};
      border: none;
      background-color: transparent;
      font-size: 16px;
      outline: none;

      padding: 10.5px 25px 10.5px 20px;
      margin-block-start: var(--margin-top);
    `;

    return (
      <InputWrapper
        height={height}
        width={width}
        label={label}
        error={error}
        className={className}
        css={wrapperStyles}
        heightMobile={heightMobile}
      >
        {maxLength !== 0 && !hideMaxLength && (
          <Text fontSize={12} color={GRAY} className="char-count">
            {value.length}/{maxLength}
          </Text>
        )}

        <textarea
          {...(maxLength !== 0 && { maxLength: maxLength })}
          value={value}
          ref={ref}
          css={styles}
          {...restProps}
        />
      </InputWrapper>
    );
  },
);

export const FormInputTextArea = ({
  height,
  width,
  name,
  error,
  maxLength,
  heightMobile,
  ...restProps
}) => (
  <Field name={name}>
    {({ meta, field }) => (
      <InputTextArea
        heightMobile={heightMobile}
        height={height}
        width={width}
        name={name}
        value={meta.value}
        error={meta.touched && (meta.error || error)}
        maxLength={maxLength}
        {...restProps}
        {...forwardInputEventHandlers(restProps, field)}
      />
    )}
  </Field>
);

InputTextArea.propTypes = {
  width: propTypeLength,
  name: PropTypes.string,
  height: propTypeLength,
  type: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  heightMobile: propTypeLength,
  hideMaxLength: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

FormInputTextArea.propTypes = {
  ...InputTextArea.propTypes,
};

InputTextArea.displayName = 'InputTextArea';
FormInputTextArea.displayName = 'FormInputTextArea';

export default InputTextArea;
