/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import PropTypes from 'prop-types';
import * as Colors from 'styles/colors';
import { MEDIA_QUERIES } from 'styles/constants';
import { propTypeLength } from 'utils/propValidation';
import { getLength } from 'utils/cssStringManipulation';

const showOnMobileOnlyStyles = css`
  display: none !important;
  ${MEDIA_QUERIES.mobile} {
    display: revert !important;
  }
`;

const hideOnMobileStyles = css`
  ${MEDIA_QUERIES.mobile} {
    display: none !important;
  }
`;

const Icon = (props) => {
  const {
    icon,
    width,
    height,
    rotate,
    className,
    size = 20,
    iconMobile,
    sizeMobile,
    widthMobile,
    heightMobile,
    color = Colors.BLACK,
    secondaryColor = Colors.TRANSPARENT,
  } = props;

  const styles = css`
    flex-shrink: 0;
    width: auto;
    height: auto;
    ${getLength(
      !width && !height
        ? { width: size, height: size }
        : { height, width },
    )};
    margin: 0;
    padding: 0;
    ${rotate && `transform: rotate(${rotate}deg);`}

    circle,
    ellipse,
    line,
    path,
    polygon,
    polyline,
    rect {
      fill: ${color};
    }

    #primaryColor {
      fill: ${color};
    }

    #secondaryColor {
      fill: ${secondaryColor};
    }

    ${(widthMobile || heightMobile || sizeMobile) &&
    `
    ${MEDIA_QUERIES.mobile} {
      ${getLength(
        !widthMobile && !heightMobile
          ? { width: sizeMobile, height: sizeMobile }
          : { height: heightMobile, width: widthMobile },
      )};
    }`}
  `;

  const Icon = icon;
  const IconMobile = iconMobile;

  return iconMobile ? (
    <>
      {icon && (
        <Icon
          css={[styles, hideOnMobileStyles]}
          className={className}
        />
      )}

      <IconMobile
        css={[styles, showOnMobileOnlyStyles]}
        className={className}
      />
    </>
  ) : icon ? (
    <Icon css={styles} className={className} />
  ) : null;
};

function propTypeIconAndIconMobile(props, componentName) {
  if (
    (!props.icon || typeof props.icon.render !== 'function') &&
    (!props.iconMobile ||
      typeof props.iconMobile.render !== 'function')
  ) {
    return new Error(
      `No SVG supplied to icon or iconMobile prop in ${componentName} component.`,
    );
  }
}

Icon.propTypes = {
  size: propTypeLength,
  width: propTypeLength,
  height: propTypeLength,
  rotate: PropTypes.number,
  sizeMobile: propTypeLength,
  widthMobile: propTypeLength,
  className: PropTypes.string,
  heightMobile: propTypeLength,
  icon: propTypeIconAndIconMobile,
  iconMobile: propTypeIconAndIconMobile,
  color: PropTypes.oneOf(Object.values(Colors)),
  secondaryColor: PropTypes.oneOf(Object.values(Colors)),
};

export default Icon;
