/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  GRAY,
  WHITE,
  BLACK,
  PURPLE_FEEK,
  PURPLE_MEDIUM,
  RED_NOTIFICATIONS,
} from 'styles/colors';
import Icon from 'components/Icon';
import { MEDIA_QUERIES } from 'styles/constants';
import { propTypeLength } from 'utils/propValidation';
import { getLength } from 'utils/cssStringManipulation';
import InputWarningMessage from 'components/inputs/InputWarningMessage';

const InputWrapper = ({
  icon,
  label,
  error,
  children,
  className,
  heightMobile,
  width = 'auto',
  height = label ? '55px' : '48px',
}) => {
  const styles = css`
    --border-radius: 8px;
    display: flex;
    justify-content: center;
    position: relative;
    color: ${BLACK};

    ${getLength({ width, height })}
    min-width: 210px;

    border-radius: var(--border-radius);
    border: 1px solid ${error ? RED_NOTIFICATIONS : PURPLE_MEDIUM};
    background-color: ${WHITE};

    label {
      position: absolute;
      top: 10px;
      left: 21px;
      font-size: 12px;
      color: ${error ? RED_NOTIFICATIONS : GRAY};
      pointer-events: none;
    }

    > *::placeholder {
      color: ${GRAY};
    }

    :focus-visible {
      border: 1px solid ${error ? RED_NOTIFICATIONS : PURPLE_FEEK};
    }

    :hover {
      border: 1px solid ${error ? RED_NOTIFICATIONS : PURPLE_FEEK};
    }

    .icon {
      align-self: center;
      margin-inline-start: 20px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      border-radius: var(--border-radius);
      -webkit-background-clip: text;
      -webkit-text-fill-color: ${BLACK};
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: inset 0 0 20px 20px ${WHITE};
    }

    ${MEDIA_QUERIES.tablet} {
      ${getLength({ height: heightMobile })}
    }
  `;

  return (
    <div css={styles} className={className}>
      {!!label && <label htmlFor="name">{label}</label>}

      {icon && (
        <Icon
          {...icon.props}
          className={
            icon.props.className
              ? icon.props.className + ' icon'
              : 'icon'
          }
        />
      )}

      {children}

      {typeof error === 'string' && (
        <InputWarningMessage message={error} />
      )}
    </div>
  );
};

InputWrapper.propTypes = {
  width: propTypeLength,
  height: propTypeLength,
  icon: PropTypes.shape({
    type: PropTypes.oneOf([Icon]),
    props: PropTypes.object,
  }),
  label: PropTypes.string,
  className: PropTypes.string,
  heightMobile: propTypeLength,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

InputWrapper.displayName = 'InputWrapper';

export default InputWrapper;
