/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import {
  ROUTES_FEEKAPP,
  ROUTES_MANAGER,
  ROUTES_AGNOSTIC,
} from 'constants/routes';
import Text from 'components/Text';
import Icon from 'components/Icon';
import { Slogan } from 'components/Slogan';
import AppStore from 'assets/images/AppStore.svg';
import getFormattedUrl from 'utils/getFormattedUrl';
import GooglePlay from 'assets/images/GooglePlay.svg';
import { MEDIA_QUERIES, Z_INDEX } from 'styles/constants';
import { ReactComponent as House } from 'assets/icons/House.svg';
import { ReactComponent as Avatar } from 'assets/icons/Avatar.svg';
import { BLACK, WHITE, GRAY_MID, PURPLE_FEEK } from 'styles/colors';
import { ReactComponent as Calendar } from 'assets/icons/Calendar.svg';
import { ReactComponent as MagnifyingGlassCut } from 'assets/icons/MagnifyingGlassCut.svg';

export const footerHeight = { normal: '135px', mobile: '80px' };

const styles = css`
  justify-content: space-between;
  padding: 40px 70px;
  margin-block-start: 70px;
  border-top: 1px solid ${GRAY_MID};
  position: relative;
  bottom: 0;
  z-index: ${Z_INDEX.footer};
  height: ${footerHeight.normal};

  &,
  & > * {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .nav {
    gap: 5px;

    a {
      color: ${BLACK};
      font-size: 16px;

      :first-of-type {
        margin-inline-start: 40px;
      }

      :not(:last-of-type)::after {
        content: '·';
        margin-inline-start: 5px;
      }
    }
  }

  .store-logos-wrapper {
    gap: 20px;

    .slogan {
      margin-inline-end: 20px;
    }

    .store-logo {
      width: 169px;
      border-radius: 15px;
      border: 1px solid ${WHITE};
    }
  }

  ${MEDIA_QUERIES.mobile} {
    display: none;
  }
`;

const stylesResponsive = css`
  display: none;

  ${MEDIA_QUERIES.tablet} {
    display: revert;
    width: 100vw;
    position: sticky;
    bottom: 0;
    z-index: ${Z_INDEX.footer};
    background-color: ${WHITE};
    border-block-start: 1px solid ${GRAY_MID};
    height: ${footerHeight.mobile};

    .nav-wrapper {
      display: grid;
      grid-template-columns: repeat(4, auto);
      justify-items: center;
      justify-content: space-between;
      gap: 25px;
      padding: 11.5px 30px 27px;

      .responsive-nav-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
      }
    }
  }
`;

function Footer() {
  const { isFooterHidden } = useSelector((state) => state.feekApp);
  const { pathname } = useLocation();
  const linkIconsColors = useMemo(
    () => ({
      [ROUTES_FEEKAPP.EXPLORE]: BLACK,
      [ROUTES_FEEKAPP.SEARCHER]: BLACK,
      [ROUTES_FEEKAPP.AGENDA]: BLACK,
      [ROUTES_FEEKAPP.PROFILE_FEEKER_EDIT]: BLACK,
      ['/' + pathname.split('/')[1]]: PURPLE_FEEK,
    }),
    [pathname],
  );
  const screensWithHiddenResponsiveFooter = [
    ROUTES_FEEKAPP.CAMPAIGN,
    ROUTES_FEEKAPP.EXPERIENCE,
  ];

  return isFooterHidden ? null : (
    <>
      <footer css={styles}>
        <nav className="nav">
          <Text color={BLACK} fontSize={16}>
            ©2022 <b>Feek</b> Technologies.
          </Text>

          <Link to={ROUTES_AGNOSTIC.POLICY} fontSize={16}>
            Política
          </Link>
          <Link
            to={ROUTES_AGNOSTIC.TERMS_AND_CONDITIONS}
            fontSize={16}
          >
            Términos
          </Link>
          <Link
            to={getFormattedUrl({
              route: ROUTES_MANAGER.LANDING,
              isManagerRoute: true,
            })}
          >
            Feek para negocios
          </Link>
        </nav>

        <div className="store-logos-wrapper">
          <Slogan color={BLACK} fontSize={24} className="slogan" />

          <a
            href="https://qrco.de/be4ftN"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={AppStore}
              alt="Feek App Store"
              className="store-logo img-fluid"
              width={300}
              height={90}
            />
          </a>

          <a
            href="https://qrco.de/be57iC"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={GooglePlay}
              alt="Feek Google play"
              className="store-logo img-fluid"
              width={300}
              height={90}
            />
          </a>
        </div>
      </footer>

      {!screensWithHiddenResponsiveFooter.some((screen) =>
        pathname.includes(screen.replace(/:.*/, '')),
      ) && (
        <footer css={stylesResponsive}>
          <nav className="nav-wrapper">
            <Link
              className="responsive-nav-link"
              to={getFormattedUrl({
                route: ROUTES_FEEKAPP.EXPLORE,
              })}
              fontSize={16}
            >
              <Icon
                icon={House}
                height={22}
                color={linkIconsColors[ROUTES_FEEKAPP.EXPLORE]}
              />
              <Text color={linkIconsColors[ROUTES_FEEKAPP.EXPLORE]}>
                Home
              </Text>
            </Link>

            <Link
              className="responsive-nav-link"
              to={getFormattedUrl({
                route: ROUTES_FEEKAPP.SEARCHER,
              })}
              fontSize={16}
            >
              <Icon
                icon={MagnifyingGlassCut}
                height={22}
                color={linkIconsColors[ROUTES_FEEKAPP.SEARCHER]}
              />
              <Text color={linkIconsColors[ROUTES_FEEKAPP.SEARCHER]}>
                Buscar
              </Text>
            </Link>

            <Link
              className="responsive-nav-link"
              to={getFormattedUrl({
                route: ROUTES_FEEKAPP.AGENDA,
              })}
            >
              <Icon
                icon={Calendar}
                height={22}
                color={linkIconsColors[ROUTES_FEEKAPP.AGENDA]}
              />
              <Text color={linkIconsColors[ROUTES_FEEKAPP.AGENDA]}>
                Agenda
              </Text>
            </Link>

            <Link
              className="responsive-nav-link"
              to={getFormattedUrl({
                route: ROUTES_FEEKAPP.PROFILE_FEEKER_EDIT,
              })}
            >
              <Icon
                icon={Avatar}
                height={22}
                color={
                  linkIconsColors[ROUTES_FEEKAPP.PROFILE_FEEKER_EDIT]
                }
              />
              <Text
                color={
                  linkIconsColors[ROUTES_FEEKAPP.PROFILE_FEEKER_EDIT]
                }
              >
                Perfil
              </Text>
            </Link>
          </nav>
        </footer>
      )}
    </>
  );
}

export default Footer;
