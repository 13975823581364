import 'translations';
import React from 'react';
import i18next from 'i18next';
import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';

import 'index.css';
import App from 'App';
import { store } from 'redux/store';
import reportWebVitals from 'reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CustomToastContainer from 'components/CustomToastContainer';

ReactGA.initialize('G-YDPX62HKJ1');

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_TAG_MANAGER_GMTID,
  auth: process.env.REACT_APP_TAG_MANAGER_AUTH,
  preview: process.env.REACT_APP_TAG_MANAGER_PREVIEW,
};

TagManager.initialize(tagManagerArgs);

if (typeof window !== 'undefined') {
  window.ResizeObserver =
    window.ResizeObserver || ResizeObserverPolyfill;
}

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

/* This silences false Emotion errors related to conflicting selectors and SSR
  (https://github.com/emotion-js/emotion/issues/1105) */
const consoleError = console.error;
console.error = function filterErrors(msg, ...args) {
  if (/server-side rendering/.test(msg)) {
    return;
  }
  consoleError(msg, ...args);
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleOAuthProvider clientId={clientId}>
        <I18nextProvider i18n={i18next}>
          <App />
          <CustomToastContainer />
        </I18nextProvider>
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
