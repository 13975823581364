/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import Text from 'components/Text';
import { WHITE } from 'styles/colors';
import handleError from 'utils/handleError';
import { removeFeekspotCollaborator } from 'redux/entities';
import ButtonPrimary from 'components/buttons/ButtonPrimary';

const styles = css`
  display: flex;
  flex-direction: column;
  padding: 40px;
  background: ${WHITE};
  border-radius: 16px;
  min-width: 50vw;
  max-width: 480px;

  .title {
    margin-block-end: 20px;
  }

  .bodyText {
    margin-block-end: 40px;
  }

  .buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }
`;

const handleClick = (event) => {
  event.stopPropagation();
};

function ModalEraseCollaborator({ onClose, collaboratorToDelete }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const selectedFeekspotId = useSelector(
    (state) => state.managerApp.selectedFeekspotId,
  );

  const hadleDeleteCollaborator = async () => {
    try {
      const res = await APIClient.deleteCollaborator(
        selectedFeekspotId,
        collaboratorToDelete,
      );

      if (res.status === 200) {
        dispatch(
          removeFeekspotCollaborator({
            collaboratorId: collaboratorToDelete,
            feekspotId: selectedFeekspotId,
          }),
        );
        onClose();
      }
    } catch (error) {
      setIsLoading(false);
      handleError(error);
    }
  };

  return (
    <div css={styles} onClick={handleClick}>
      <Text
        align="center"
        className="title"
        fontSize={24}
        fontWeight="700"
      >
        ¿Eliminar colaborador?
      </Text>

      <Text
        align="center"
        className="bodyText"
        fontSize={16}
        fontWeight="500"
        lineHeight={24}
      >
        Esta acción borrará toda la información <br /> y permisos de
        esta cuenta.
      </Text>
      <div className="buttonsContainer">
        <ButtonPrimary
          label="Cancelar"
          onClick={onClose}
          strokeVariant
        />
        <ButtonPrimary
          label="Eliminar"
          onClick={hadleDeleteCollaborator}
          disabled={isLoading}
        />
      </div>
    </div>
  );
}

ModalEraseCollaborator.propTypes = {
  onClose: PropTypes.func,
  collaboratorToDelete: PropTypes.string,
};

export default ModalEraseCollaborator;
