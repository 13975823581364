/** @jsxImportSource @emotion/react */
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { forwardRef, useRef } from 'react';

import {
  RED,
  WHITE,
  PURPLE_FEEK,
  PURPLE_INACTIVE,
} from 'styles/colors';
import Text from 'components/Text';
import Button from 'components/buttons/Button';
import { forwardInputEventHandlers } from 'utils/form.utils';

const RadioInput = forwardRef((props, ref) => {
  const {
    error,
    label,
    checked,
    disabled,
    size = 16,
    labelProps,
    wrapperClassName,
    ...restProps
  } = props;

  const checkboxRef = useRef('');

  const styles = css`
    --outline-width: ${size / 6}px;

    .inputAndLabel {
      display: flex;
      align-items: center;
      user-select: none;

      .radioSurrogate {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${size}px;
        height: ${size}px;
        color: ${WHITE};
        background-color: ${WHITE};
        border: 1px solid ${error ? RED : PURPLE_INACTIVE};
        border-radius: 50%;
        outline-offset: calc((var(--outline-width) + 1px) * -1);
        outline-width: 0;
        outline-color: ${WHITE};
        outline-style: solid;
        transition: outline-width cubic-bezier(0, 0.57, 0.59, 2.06)
          0.3s;
      }

      &:hover .radioSurrogate {
        border-color: ${error ? RED : PURPLE_FEEK};
      }

      .text-label {
        margin-left: 5px;
      }

      input {
        display: none;
        visibility: hidden;

        &:checked + .radioSurrogate {
          background: ${PURPLE_FEEK};
          outline-width: var(--outline-width);
        }
      }
    }

    .error-text {
      margin: 3px 0;
      min-height: 19px;
    }
  `;

  const handleClick = () => {
    checkboxRef.current?.click();
  };
  return (
    <div css={styles} className={wrapperClassName}>
      <Button
        className="inputAndLabel"
        onClick={handleClick}
        ref={ref}
        disabled={disabled}
        hideHoverActiveStyles
      >
        <input
          ref={checkboxRef}
          type="radio"
          checked={checked}
          {...restProps}
        />

        <div role="presentation" className="radioSurrogate" />

        {typeof label === 'string' ? (
          <Text {...labelProps} className="text-label">
            {label}
          </Text>
        ) : (
          label
        )}
      </Button>

      <Text
        fontSize={14}
        lineHeight={17}
        fontWeight="500"
        color={RED}
        className="error-text"
      >
        {typeof error === 'string' ? error : ''}
      </Text>
    </div>
  );
});

export const FormRadioInput = (props) => {
  const { name, error, ...restProps } = props;

  return (
    <Field name={name}>
      {({ meta, field }) => (
        <RadioInput
          name={name}
          checked={meta.value}
          error={meta.touched && (meta.error || error)}
          {...restProps}
          {...forwardInputEventHandlers(restProps, field)}
        />
      )}
    </Field>
  );
};

RadioInput.propTypes = {
  checked: PropTypes.bool,
  size: PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  wrapperClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelProps: PropTypes.shape({ ...Text.propTypes }),
};

FormRadioInput.propTypes = {
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

RadioInput.displayName = RadioInput;

export default RadioInput;
