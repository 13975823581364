/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import Icon from 'components/Icon';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import { experiencesStatus } from 'constants';
import Button from 'components/buttons/Button';
import { setExperiences } from 'redux/entities';
import { MEDIA_QUERIES } from 'styles/constants';
import CardExperience from 'components/cards/CardExperience';
import { GRAY_LIGHTEST, GRAY_MID, WHITE } from 'styles/colors';
import { useScrollHorizontally } from 'hooks/useScrollHorizontally';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';

const styles = css`
  .position-relative {
    position: relative;
  }

  .experiences-feed {
    display: flex;
    margin-top: 40px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    padding-right: 70px;
    flex-direction: row;
    margin-bottom: 100px;
  }

  .experiences-feed::-webkit-scrollbar {
    display: none;
  }

  .circle-button {
    width: 58px;
    height: 58px;
    display: flex;
    top: 220px;
    position: absolute;
    border-radius: 36px;
    align-items: center;
    justify-content: center;
    background-color: ${WHITE};
    border: 2px solid ${GRAY_LIGHTEST};
  }

  .circle-button.prev {
    left: 2em;
  }
  .circle-button.next {
    right: 1em;
  }
`;

export default function ExperiencesFeed() {
  const ref = useScrollHorizontally();
  const dispatch = useDispatch();
  const { experiences } = useSelector((state) => state.entities);

  useEffect(() => {
    APIClient.getExperiencesFeed()
      .then(({ data }) => {
        dispatch(setExperiences(data.docs));
      })
      .catch(handleError);
  }, []);

  const experiencesArray = useMemo(
    () =>
      Object.values(experiences).filter(
        (experience) =>
          experience.status === experiencesStatus.IN_PROGRESS ||
          experience.status === experiencesStatus.COMING_SOON,
      ),
    [experiences],
  );

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  return (
    <div css={styles}>
      <div className="position-relative pt-4">
        <div className="experiences-feed" ref={ref}>
          {experiencesArray.map((experience) => {
            return (
              <div
                key={experience._id}
                className="d-flex justify-content-center ms-lg-5 ms-md-3 me-5 "
              >
                <CardExperience
                  id={experience._id}
                  icon={experience.icon}
                  image={experience.image}
                  title={experience.title}
                  places={experience.places}
                  end_date={experience.end_date}
                  start_date={experience.start_date}
                  participation={experience.participation}
                  className="experience-card"
                />
              </div>
            );
          })}
          {!experiencesArray[0] && (
            <ExperienceNotFound
              className="experience-card"
              emoji={'🥳'}
            />
          )}
          {!experiencesArray[1] && (
            <ExperienceNotFound
              className="experience-card"
              emoji={'🤩'}
            />
          )}
          {!experiencesArray[2] && (
            <ExperienceNotFound
              className="experience-card"
              emoji={'🤭'}
            />
          )}
        </div>

        <Button
          className="circle-button prev d-none d-md-flex"
          onClick={() => {
            scroll(-520);
          }}
        >
          <Icon
            size={14}
            rotate={90}
            icon={Chevron}
            color={GRAY_MID}
          />
        </Button>
        <Button
          className="circle-button next  d-none d-md-flex"
          onClick={() => {
            scroll(570);
          }}
        >
          <Icon
            size={14}
            rotate={270}
            icon={Chevron}
            color={GRAY_MID}
          />
        </Button>
      </div>
    </div>
  );
}

const ExperienceNotFoundStyles = css`
  width: 449px;
  height: 449px;
  min-width: 400px;
  margin-left: 20px;
  margin-right: 20px;

  .card {
    height: 100%;
    width: 100%;
    display: flex;
    border-radius: 20px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    border: 2px solid ${GRAY_LIGHTEST};
  }

  .text {
    width: 280px;
  }
  .skeleton {
    width: 100%;
    height: 22px;
    border-radius: 4px;
    margin-bottom: 12px;
    background-color: ${GRAY_LIGHTEST};
  }

  .badge {
    width: 92px;
    height: 28px;
    border-radius: 20px;
  }

  ${MEDIA_QUERIES.mobile} {
    margin-left: 16px;
  }
`;

const ExperienceNotFound = ({ emoji, className }) => {
  return (
    <div css={ExperienceNotFoundStyles} className={className}>
      <div className="card">
        <Text
          fontSize={16}
          align="center"
          className="text emoji-font"
          fontWeight={500}
          color={GRAY_MID}
          lineHeight={'130%'}
        >
          {emoji}
          <br /> Por el momento estamos preparando nuevas recompensas.
        </Text>
      </div>

      <div className="skeleton badge"></div>
      <div className="skeleton"></div>
      <div className="skeleton"></div>
    </div>
  );
};

ExperienceNotFound.propTypes = {
  emoji: PropTypes.string,
  className: PropTypes.string,
};
