/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import { setRoles } from 'redux/entities';
import handleError from 'utils/handleError';
import { ROUTES_MANAGER } from 'constants/routes';
import CollaboratorList from 'screens/manager/legacy/Collaborators/CollaboratorList';
import CollaborationCreationSuccess from 'screens/manager/legacy/Collaborators/CollaborationCreationSuccess';
import CollaborationCreationSelection from 'screens/manager/legacy/Collaborators/CollaborationCreationSelection';

const styles = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
`;

function Collaborators() {
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const selectedFeekspotId = useSelector(
    (state) => state.managerApp.selectedFeekspotId,
  );
  const feekspots = useSelector((state) => state.entities.feekspots);
  const selectedFeekspot = feekspots[selectedFeekspotId]?.customer;

  useEffect(() => {
    APIClient.getRoles()
      .then(({ data }) => dispatch(setRoles(data)))
      .catch(handleError);
  }, []);

  useEffect(() => {
    setIndex(0);
  }, [selectedFeekspotId]);

  const steps = [
    CollaboratorList,
    CollaborationCreationSelection,
    CollaborationCreationSuccess,
  ];

  const CurrentStep = steps[index];

  const handleIndex = (i) => {
    setIndex(i);
  };

  return feekspots[selectedFeekspotId]?.role === 'customerOwner' ||
    feekspots[selectedFeekspotId]?.role === 'customerAdmin' ? (
    <div css={styles}>
      <CurrentStep
        handleIndex={handleIndex}
        collaborators={selectedFeekspot?.collaborators || []}
        collaboratorsTotalPlaces={
          selectedFeekspot?.collaborators_total_places || 0
        }
      />
    </div>
  ) : (
    <Navigate to={ROUTES_MANAGER.DASHBOARD} replace />
  );
}
export default Collaborators;
