import { configureStore, combineReducers } from '@reduxjs/toolkit';

import entitiesReducer from 'redux/entities';
import feekAppReducer from 'redux/app/feekApp';
import managerAppReducer from 'redux/app/managerApp';

const reducers = {
  feekApp: feekAppReducer,
  managerApp: managerAppReducer,
  entities: entitiesReducer,
};

const rootReducer = combineReducers(reducers);

const resettableRootReducer = (state, action) => {
  if (action.type === 'managerApp/logout') {
    return rootReducer(state.feekApp, action);
  }
  if (action.type === 'feekApp/logout') {
    return rootReducer(state.managerApp, action);
  }
  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: resettableRootReducer,
});
