/** @jsxImportSource @emotion/react */
import Text from 'components/Text';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import { BLACK, WHITE } from 'styles/colors';

const styles = css`
  margin-top: 0.5em;
  width: 100%;
  background-color: ${WHITE};
  border-radius: 16px;
  padding: 20px;
`;

const PlaceSelectedItem = ({ name, formatted_address }) => {
  return (
    <div css={styles}>
      <Text color={BLACK} fontWeight={700} fontSize={16}>
        {name}
      </Text>
      <Text color={BLACK} fontSize={16}>
        {formatted_address}
      </Text>
    </div>
  );
};

PlaceSelectedItem.propTypes = {
  name: PropTypes.string,
  formatted_address: PropTypes.string,
};

export default PlaceSelectedItem;
