/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createContext, useEffect, useState } from 'react';

import APIClient from 'redux/api';
import handleError from 'utils/handleError';
import Header, {
  headerHeight,
} from 'screens/feekApp/OutletFeekapp/Header';
import { setFeeker } from 'redux/app/feekApp';
import { MEDIA_QUERIES } from 'styles/constants';
import Footer from 'screens/feekApp/OutletFeekapp/Footer';
import ModalSelectLocation from 'components/modals/ModalSelectLocation';

const styles = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 100vh;

  > :nth-child(2) {
    margin-block-start: ${headerHeight.normal};
  }

  ${MEDIA_QUERIES.mobile} {
    > :nth-child(2) {
      margin-block-start: ${headerHeight.mobile};
    }
  }
`;

export const HeaderContext = createContext(null);

function OutletFeekapp() {
  const [headerResponsiveAction, setHeaderResponsiveAction] =
    useState(null);
  const [showSelectLocationModal, setShowSelectLocationModal] =
    useState(false);
  const { id } = useSelector((state) => state.feekApp);
  const dispatch = useDispatch();

  const lastSelectedLocation = JSON.parse(
    localStorage.getItem('last_location'),
  );

  useEffect(() => {
    if (
      id ||
      !lastSelectedLocation ||
      !localStorage.getItem('currentFeekToken')
    )
      return;

    APIClient.getProfileFeeker()
      .then(({ data }) => {
        dispatch(setFeeker(data));
      })
      .then(() => APIClient.getUnseenNotificationsCount())
      .then(({ data: { count } }) =>
        dispatch(setFeeker({ notificationsCount: count })),
      )
      .catch(handleError);
  }, []);

  return (
    <HeaderContext.Provider value={setHeaderResponsiveAction}>
      <div id="outletFeekapp" css={styles}>
        <ModalSelectLocation
          onLocationChanged={() => window.location.reload()}
          onRequestClose={() => setShowSelectLocationModal(false)}
          {...(lastSelectedLocation
            ? { visible: showSelectLocationModal }
            : {
                withoutCloseButton: true,
                visible: true,
                className: 'html-overflow-hidden',
                cssProp: css`
                  backdrop-filter: blur(10px);
                `,
              })}
        />

        <Header
          responsiveAction={headerResponsiveAction}
          setShowSelectLocationModal={setShowSelectLocationModal}
        />

        {lastSelectedLocation && <Outlet />}

        <Footer />
      </div>
    </HeaderContext.Provider>
  );
}

export default OutletFeekapp;
