/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import {
  GRAY,
  BLACK,
  GRAY_MID,
  PURPLE_LIGHT,
  WHITE_ALMOST,
} from 'styles/colors';
import Icon from 'components/Icon';
import Text from 'components/Text';
import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_FEEKAPP } from 'constants/routes';
import DistanceIndicator from 'components/DistanceIndicator';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';

const styles = css`
  display: block;

  .feekspot-info {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 92px;
    padding: 16px;
    border: 1px solid ${GRAY_MID};
    border-radius: 16px;

    .avatar {
      border-radius: 8px;
    }

    .data-wrapper {
      flex: 1;
    }
  }
`;

const responsiveFullVersionStyles = css`
  ${MEDIA_QUERIES.mobile} {
    --card-height: 80px;

    .feekspot-info {
      gap: 10px;
      height: var(--card-height);
      padding: 0;
      border: none;
      border-radius: 0;

      :has(.responsive-data) {
        align-items: end;
      }

      .avatar {
        border-radius: 14px;
        width: var(--card-height);
        height: var(--card-height);
      }

      .data-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .location:not(:last-child) {
          flex: 1;
        }

        .responsive-data {
          display: flex;
          gap: 8px;

          .tag {
            background-color: ${PURPLE_LIGHT};
            width: fit-content;
            padding-inline: 10px;
            border-radius: 12px;
          }
        }
      }

      .chevron {
        background-color: ${WHITE_ALMOST};
        border-radius: 50%;
        height: 32px;
        width: 32px;
        padding: 9px;
      }
    }
  }
`;

function CardFeekspotSmall({
  tag,
  name,
  city,
  image,
  state,
  distance,
  className,
  instagramUsername,
  responsiveFullVersion,
}) {
  return (
    <Link
      className={className}
      css={[
        styles,
        responsiveFullVersion ? responsiveFullVersionStyles : null,
      ]}
      to={ROUTES_FEEKAPP.PROFILE_FEEKSPOT.replace(
        /:\w*/,
        instagramUsername,
      )}
    >
      <Button className="feekspot-info" layoutOnly>
        <Avatar
          className="avatar"
          size={60}
          image={image}
          alt={'Feek ' + name}
        />

        <div className="data-wrapper">
          <Text
            className="name"
            fontSize={14}
            fontWeight={600}
            color={BLACK}
          >
            {name}
          </Text>
          <Text
            className="location"
            fontSize={11}
            fontWeight={500}
            color={GRAY}
          >
            {`${city}, ${state}`}
          </Text>

          {responsiveFullVersion && (tag || distance) && (
            <div className="responsive-data">
              {tag && (
                <Text
                  className="tag"
                  color={GRAY}
                  fontSize={12}
                  fontWeight={600}
                  // lineHeight="100%"
                  lineHeight={24}
                >
                  {tag}
                </Text>
              )}
              {distance && (
                <DistanceIndicator
                  fontSize={12}
                  distance={distance}
                />
              )}
            </div>
          )}
        </div>

        <Icon
          className="chevron"
          icon={Chevron}
          color={BLACK}
          width={14}
          rotate={-90}
        />
      </Button>
    </Link>
  );
}

CardFeekspotSmall.propTypes = {
  tag: PropTypes.string,
  name: PropTypes.string,
  city: PropTypes.string,
  image: PropTypes.string,
  state: PropTypes.string,
  distance: PropTypes.number,
  className: PropTypes.string,
  instagramUsername: PropTypes.string,
  responsiveFullVersion: PropTypes.bool,
};

export default CardFeekspotSmall;
