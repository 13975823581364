import PropTypes from 'prop-types';

import Text from 'components/Text';
import * as Colors from 'styles/colors';

export const Slogan = ({
  fontSize = 20,
  color = Colors.GRAY_TEXT,
  className,
  ...restProps
}) => (
  <Text
    className={className}
    color={color}
    fontSize={fontSize}
    {...restProps}
  >
    Live&nbsp;it, share&nbsp;it, <b>Feek</b>&nbsp;it!
  </Text>
);

Slogan.propTypes = {
  fontSize: PropTypes.number,
  color: PropTypes.oneOf(Object.values(Colors)),
  className: PropTypes.string,
};

export default Slogan;
