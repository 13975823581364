/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Icon from 'components/Icon';
import Button from 'components/buttons/Button';
import { TRANSPARENT, YELLOW_MARKER } from 'styles/colors';
import { ReactComponent as StarThinBicolor } from 'assets/icons/StarThinBicolor.svg';

const styles = css`
  display: flex;
  align-items: center;
  justify-content: center;

  .star {
    margin: 0px 5px;
  }
`;
export default function RankingItem({ stars = 5, onChange }) {
  const [value, setValue] = useState(0);

  const onPress = (index) => {
    setValue(index);
    onChange?.(index);
  };

  return (
    <div css={styles}>
      {[...Array(stars)].map((item, index) => (
        <Button key={index} onClick={() => onPress(index + 1)}>
          <Icon
            icon={StarThinBicolor}
            size={30}
            secondaryColor={
              value > index ? YELLOW_MARKER : TRANSPARENT
            }
            color={YELLOW_MARKER}
            className="star"
          />
        </Button>
      ))}
    </div>
  );
}

RankingItem.propTypes = {
  stars: PropTypes.number,
  onChange: PropTypes.func,
};
