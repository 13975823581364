/** @jsxImportSource @emotion/react */
import moment from 'moment';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  GRAY,
  BLACK,
  WHITE,
  GRAY_MID,
  GRAY_TEXT,
  GRAY_LIGHT,
  PURPLE_FEEK,
  GRAY_LIGHTEST,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import handleError from 'utils/handleError';
import { experiencesStatus } from 'constants';
import Button from 'components/buttons/Button';
import ModalAuth from 'components/modals/ModalAuth';
import ModalShare from 'components/modals/ModalShare';
import Ranking from 'screens/feekApp/Experience/Ranking';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as Zap } from 'assets/icons/Zap.svg';
import Scoreboard from 'screens/feekApp/Experience/Scoreboard';
import { ReactComponent as Check } from 'assets/icons/Check.svg';
import { campaignParticipationMiniIconsByName } from 'constants';
import { ReactComponent as Share } from 'assets/icons/Share.svg';
import ImagePlaceholder from 'assets/images/ImagePlaceholder.jpg';
import { setExperiences, setParticipations } from 'redux/entities';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import { ReactComponent as Location } from 'assets/icons/Location.svg';
import { ReactComponent as FeekLogo } from 'assets/icons/FeekLogo.svg';
import { ReactComponent as TrophyMini } from 'assets/icons/TrophyMini.svg';
import { ReactComponent as CalendarMini } from 'assets/icons/CalendarMini.svg';
import ModalInstagramVinculation from 'components/modals/ModalInstagramVinculation';
import ModalBookingExperience from 'screens/feekApp/Experience/ModalBookingExperience';

const styles = css`
  margin-top: 50px;

  .content-container {
    margin: 0 auto;
    max-width: 1480px;
    padding: 0 1em;
  }

  .expand-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    justify-content: center;
    border: 1px solid ${GRAY_MID};
  }

  .flex-align-center-justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .campaign-image {
    width: 100%;
    max-width: 724px;
    max-height: 543px;
    border-radius: 20px;
    margin-bottom: 40px;
    object-fit: cover;
  }

  .circle {
    min-width: 22px;
    height: 22px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${GRAY_MID};
  }

  .card {
    padding: 40px;
    border-radius: 20px;
    border: 1px solid ${GRAY_MID};
  }

  .card-shadow {
    top: 20px;
    position: sticky;
    padding: 40px 0px;
    height: fit-content;
    margin-bottom: 40px;
    border-radius: 20px;
    border: 1px solid ${GRAY_MID};
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);
  }

  .card-container {
    padding: 0 40px;
  }

  .feek-image {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    border: 2px solid ${GRAY_LIGHT};
    background-color: ${PURPLE_FEEK};
  }

  .icon-button {
    padding: 16px;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${GRAY_MID};
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .card-footer {
    margin-top: 36px;
    padding: 32px 32px 0 32px;
    border-top: 1px solid ${GRAY_MID};
  }

  .finished-badge {
    width: 200px;
    display: flex;
    padding: 14px 30px;
    border-radius: 40px;
    align-content: center;
    justify-content: center;
    border: 1px solid ${BLACK};
  }

  .places-badge {
    display: flex;
    align-items: center;
    height: 34px;
    padding-inline: 16px;
    width: fit-content;
    border-radius: 21px;
    border: 1px solid ${BLACK};
  }

  .participation-badge {
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 0;
    border-radius: 40px;
    border: 1px solid ${PURPLE_FEEK};
  }
  .divider {
    height: 1px;
    border: none;
    margin: 40px 0;
    background-color: ${GRAY_LIGHTEST};
  }
`;

export default function Experience() {
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState('');
  const [showAuthModal, setShowAuthModal] = useState(false);

  const [isScoreboardExpanded, setIsScoreboardExpanded] =
    useState(false);
  const { id } = useParams();

  const { experiences, participations } = useSelector(
    (state) => state.entities,
  );
  const { instagramUserName } = useSelector((state) => state.feekApp);
  const userId = useSelector((state) => state.feekApp.id);

  const experience = experiences[id];

  document.title = experience?.title;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!userId) return;
    APIClient.getParticipationsByUser({
      status: 'ACTIVE',
    })
      .then(async ({ data }) => {
        dispatch(
          setParticipations(
            data.docs.map((item) => item.participation),
          ),
        );
      })
      .catch(handleError);
  }, [userId]);

  useEffect(() => {
    setLoading(true);
    APIClient.getExperience(id)
      .then(({ data }) => {
        dispatch(setExperiences([data]));
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const shareUrl = useMemo(
    () =>
      `${window.location.protocol}//${window.location.hostname}/share/experience/${id}`,
    [],
  );

  const experienceIsOver =
    experience?.status !== experiencesStatus.IN_PROGRESS &&
    experience?.status !== experiencesStatus.COMING_SOON;

  const experienceSelected = useMemo(() => {
    return Object.values(participations).find(
      (item) => item.experience === id || item.experience?._id === id,
    );
  }, [participations, id]);

  return (
    <main css={styles}>
      <ModalAuth
        visible={showAuthModal}
        onRequestClose={() => setShowAuthModal(false)}
      />
      <ModalShare
        url={shareUrl}
        body={
          <div className="d-flex justify-content-start align-items-center mt-4 pt-1">
            <img
              src={experience?.images?.[0] || ImagePlaceholder}
              alt="campaign image"
              className="image me-3"
            />
            <div>
              <Text fontSize={16} color={BLACK} fontWeight={600}>
                {experience?.title}
              </Text>
              <Text fontSize={16} color={GRAY_TEXT}>
                FEEK REWARDS
              </Text>
            </div>
          </div>
        }
        header="Comparte esta recompensa"
        visible={visibleModal === 'shareModal'}
        onRequestClose={() => setVisibleModal('')}
        messageText={`Participa y gana ${experience?.title} esta y más recompensas increíbles podrás ganar en Feek, solo por usarla.`}
      />
      <ModalBookingExperience
        experienceId={id}
        places={experience?.places}
        visible={visibleModal === 'modalBooking'}
        onRequestClose={() => setVisibleModal('')}
        minMomentsRequired={experience?.min_moments}
      />
      <ModalInstagramVinculation
        onRequestClose={() => setVisibleModal(null)}
        visible={visibleModal === 'ModalInstagramVinculation'}
      />
      <div className="content-container">
        <div className="row">
          <div className="col-md-6 pb-5">
            <img
              src={experience?.images?.[0] || ImagePlaceholder}
              className="campaign-image"
            />
            <HeaderText className="mb-20">Descripción</HeaderText>
            {experience?.description
              ?.trim()
              .split(/\n+/)
              .map((text, i) => (
                <Text
                  className="mb-20"
                  key={i}
                  color={BLACK}
                  fontSize={16}
                >
                  {text}
                </Text>
              ))}
            <hr className="divider" />
            <HeaderText className="mb-20">Checklist</HeaderText>
            <CheckListItem
              isChecked={
                experienceSelected?.statistics?.moments_completed >=
                experience?.min_moments
              }
              label={
                <>
                  Debes completar mínimo{' '}
                  <b>{experience?.min_moments} Promociones</b> una vez
                  que empieces a participar.
                </>
              }
            />
            <hr className="divider" />
            <HeaderText className="mb-20">Cómo ganar</HeaderText>
            <CheckListItem
              isChecked={
                experienceSelected?.statistics?.ranking === 1
              }
              label={
                <>
                  Mantente en el <b>Top {experience?.places}</b>{' '}
                  completando el mayor número de Promociones antes de
                  la fecha de finalización.
                </>
              }
            />
            <hr className="divider" />

            {experienceSelected && (
              <>
                <HeaderText className="mb-20" align="center">
                  Estás en el lugar
                </HeaderText>

                <Ranking experienceId={id} />
                <hr className="divider" />
              </>
            )}
            <div className="d-flex justify-content-between mb-4 pb-2">
              <div className="d-flex align-items-center ">
                <Icon icon={TrophyMini} size={18} />
                <HeaderText className="ms-1">Scoreboard</HeaderText>
              </div>
              <Button
                className="expand-icon"
                onClick={() =>
                  setIsScoreboardExpanded(!isScoreboardExpanded)
                }
              >
                <Icon
                  icon={Chevron}
                  size={14}
                  rotate={isScoreboardExpanded && 180}
                />
              </Button>
            </div>

            <Scoreboard
              experienceId={id}
              showAll={isScoreboardExpanded}
            />
          </div>

          <div className="col-md-6 card-shadow">
            <div className="card-container">
              <div className="flex-align-center-justify-between mb-20 flex-wrap">
                <div className="d-flex align-items-center mb-3">
                  <div className="feek-image">
                    <Icon icon={FeekLogo} color={WHITE} size={32} />
                  </div>
                  <Text
                    color={BLACK}
                    fontSize={18}
                    fontWeight={600}
                    className=" px-2"
                  >
                    FEEK REWARDS
                  </Text>
                </div>
                <div className="d-flex">
                  <Button
                    className="icon-button"
                    onClick={() => setVisibleModal('shareModal')}
                  >
                    <Icon icon={Share} color={BLACK} size={18} />
                  </Button>
                </div>
              </div>
              <Text
                color={BLACK}
                fontSize={21}
                fontWeight={600}
                className="mb-20 "
              >
                {experience?.title}
              </Text>
              <div className="d-flex mb-20 ">
                <Icon
                  className="me-1"
                  size={20}
                  color={GRAY}
                  icon={
                    campaignParticipationMiniIconsByName?.[
                      experience?.participation
                    ] || Zap
                  }
                />
                <Text
                  color={GRAY}
                  fontSize={16}
                  fontWeight={500}
                  className="me-3"
                >
                  {experience?.participation}
                </Text>
                <Icon
                  size={16}
                  color={GRAY}
                  className="me-1"
                  icon={CalendarMini}
                />
                <Text color={GRAY} fontSize={16} fontWeight={500}>
                  <span className="capitalize">
                    {moment(experience?.end_date).format('dddd')}{' '}
                  </span>
                  {moment(experience?.end_date).format('DD')} de{' '}
                  {moment(experience?.end_date).format('MMMM, YYYY')}
                </Text>
              </div>

              <div className="d-flex align-items-center">
                <Icon
                  size={16}
                  color={BLACK}
                  icon={Location}
                  className="me-2"
                />
                <Text color={BLACK} fontSize={16}>
                  {[
                    experience?.location?.street,
                    experience?.location?.city,
                    experience?.location?.state,
                  ]
                    .filter((item) => item)
                    .join(', ')}
                </Text>
              </div>
            </div>

            <div className="card-footer">
              {!experienceSelected ? (
                experienceIsOver ? (
                  <div className="d-flex justify-content-center">
                    <FinishedBadge />
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-lg-5 d-flex align-items-center justify-content-center">
                      <div className="places-badge">
                        <Text
                          color={BLACK}
                          fontSize={16}
                          fontWeight={500}
                        >
                          {experience?.places} Ganador
                          {experience?.places > 1 && 'es'}
                        </Text>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <ButtonPrimary
                        legacy={false}
                        pillShape
                        disabled={loading}
                        label="Quiero participar ✨"
                        className="action-button"
                        onClick={
                          userId
                            ? !instagramUserName
                              ? () => {
                                  setVisibleModal(
                                    'ModalInstagramVinculation',
                                  );
                                  handleError(
                                    '¡Ups!, tu perfil no está completo. Debes vincular tu Instagram para continuar.',
                                  );
                                }
                              : () => setVisibleModal('modalBooking')
                            : () => setShowAuthModal(true)
                        }
                      />
                    </div>
                  </div>
                )
              ) : (
                <div className="d-flex justify-content-center">
                  {experienceIsOver ? (
                    <FinishedBadge />
                  ) : (
                    <div className="participation-badge">
                      <Text
                        fontSize={16}
                        fontWeight={500}
                        color={PURPLE_FEEK}
                      >
                        Estás participando
                      </Text>
                    </div>
                  )}
                  <div className="ms-3 ps-2">
                    <div className="d-flex align-items-center ">
                      <Icon
                        size={11}
                        icon={Check}
                        className="me-1"
                        color={PURPLE_FEEK}
                      />
                      <Text fontSize={16} color={BLACK}>
                        {experienceIsOver
                          ? 'Quedaste en'
                          : 'Estás en'}{' '}
                        <b>
                          {experienceSelected?.statistics?.ranking}
                        </b>
                        º Lugar
                      </Text>
                    </div>
                    <div className="d-flex align-items-center ">
                      <Icon
                        size={11}
                        icon={Check}
                        className="me-1"
                        color={PURPLE_FEEK}
                      />
                      <Text fontSize={16} color={BLACK}>
                        {experienceIsOver ? 'Con' : 'Llevas'}{' '}
                        <b>
                          {
                            experienceSelected?.statistics
                              ?.moments_completed
                          }
                        </b>{' '}
                        {experienceSelected?.statistics
                          ?.moments_completed === 1
                          ? 'promoción'
                          : 'promociones'}
                      </Text>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

const HeaderText = ({ children, align, className }) => {
  return (
    <Text
      color={BLACK}
      fontSize={21}
      align={align}
      fontWeight={600}
      className={className}
    >
      {children}
    </Text>
  );
};

const CheckListItem = ({ label, isChecked = false }) => {
  return (
    <div className="d-flex">
      <div
        className="circle me-2"
        style={{
          backgroundColor: isChecked ? PURPLE_FEEK : GRAY_MID,
        }}
      >
        <Icon size={12} icon={Check} color={WHITE} />
      </div>

      <Text
        color={BLACK}
        fontSize={16}
        fontWeight={500}
        className="me-3"
      >
        {label}
      </Text>
    </div>
  );
};

const FinishedBadge = () => {
  return (
    <Text
      className="finished-badge"
      fontSize={16}
      color={BLACK}
      align="center"
      fontWeight={500}
    >
      Finalizada
    </Text>
  );
};

HeaderText.propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CheckListItem.propTypes = {
  isChecked: PropTypes.bool,
  label: PropTypes.node.isRequired,
};
