import PropTypes from 'prop-types';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useState } from 'react';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Modal from 'components/modals/Modal';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { GRAY_LIGHT, PURPLE_FEEK } from 'styles/colors';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as Eye } from 'assets/icons/Eye.svg';
import Carousel from 'screens/manager/legacy/Dashboard/Carousel';
import DashboardCard from 'screens/manager/legacy/Dashboard/DashboardCard';
import { ReactComponent as ImagePlaceholder } from 'assets/icons/ImagePlaceholder.svg';

const style = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .imagePlaceholder {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 172px;
    padding: 5px;
    border: 3px solid ${GRAY_LIGHT};
    border-radius: 8px;

    .iconWrapper {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .imageWrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }

  .storyItem {
    display: flex;
    gap: 25px;
    padding: 30px;
    user-select: none;

    .imagePlaceholder {
      height: 390px;
      width: 220px;
      border-color: ${PURPLE_FEEK};

      .image {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .table {
      width: 260px;
      height: 100%;

      .fullWidth {
        grid-column: 1 / -1;
      }

      .rowContainer {
        display: grid;
        grid-template-columns: 155px 1fr;
        align-items: center;
        height: 37px;
      }

      .lineTop {
        border-top: 1px solid ${GRAY_LIGHT};
      }

      .tagedAccounts {
        grid-column: 1 / -1;
        height: 120px;
        overflow-y: auto;
      }
    }
  }

  .cardCarousel {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 15px;

    .storyFilled {
      display: flex;
      flex-direction: column;
      align-items: center;

      .imagePlaceholder {
        &:hover {
          border-color: ${PURPLE_FEEK};
        }
      }

      .views {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        margin-top: 10px;
      }
    }

    .storyMissing {
      display: flex;
      flex-direction: column;
      align-items: center;

      .text {
        background-color: ${GRAY_LIGHT};
        border-radius: 2px;
        width: 80%;
        height: 14px;
        margin-top: 10px;
      }
    }
  }
`;

const cardStyle = css`
  display: flex;
  gap: 25px;
  padding: 30px;
  user-select: none;

  .closeButton {
    display: none;
  }

  .imagePlaceholder {
    height: 390px;
    width: 220px;
    border-color: ${PURPLE_FEEK};

    .image {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .table {
    width: 290px;
    height: 100%;

    .fullWidth {
      grid-column: 1 / -1;
    }

    .rowContainer {
      display: grid;
      grid-template-columns: 155px 1fr;
      align-items: center;
      height: 37px;
    }

    .lineTop {
      border-top: 1px solid ${GRAY_LIGHT};
    }

    .tagedAccounts {
      grid-column: 1 / -1;
      height: 120px;
      overflow-y: auto;
    }
  }

  ${MEDIA_QUERIES.tablet} {
    flex-direction: column;

    .closeButton {
      display: flex;
    }

    .imagePlaceholder {
      height: 310px;
      width: 175px;
      border-color: ${PURPLE_FEEK};

      .image {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .table {
      width: 175px;
    }
  }
`;

export default function MostViewedStoriesCarousel({
  storiesList,
  dates,
}) {
  const [mainCarouselIndex, setMainCarouselIndex] = useState(0);
  const [modalCarouselIndex, setModalCarouselIndex] = useState(null);

  const handleStoryOnModalIndex = (index) => {
    setModalCarouselIndex(index);
  };

  const handleModalClose = () => {
    setModalCarouselIndex(null);
  };

  const storiesGrouped =
    !storiesList || storiesList.length === 0
      ? [Array(3).fill(null)]
      : Array(Math.max(Math.ceil(storiesList.length / 3), 1))
          .fill(null)
          .map((_, index) => {
            return index === Math.ceil(storiesList.length / 3) - 1
              ? [
                  ...storiesList.slice(index * 3, index * 3 + 3),
                  ...Array(3).fill(null),
                ].slice(0, 3)
              : storiesList.slice(index * 3, index * 3 + 3);
          });

  return (
    <div css={style}>
      <Modal
        visible={typeof modalCarouselIndex === 'number'}
        onRequestClose={handleModalClose}
      >
        <DashboardCard title="Stories más vistas" dates={dates}>
          <Carousel
            index={modalCarouselIndex}
            onBack={() => setModalCarouselIndex((crr) => crr - 1)}
            onNext={() => setModalCarouselIndex((crr) => crr + 1)}
            isBackDisabled={modalCarouselIndex === 0}
            isNextDisabled={
              modalCarouselIndex === storiesList?.length - 1
            }
            className="carousel"
            itemList={storiesList?.map((storyData) => (
              <div css={cardStyle} key={storyData.moment_id}>
                <div className="imagePlaceholder">
                  <img
                    className="image"
                    src={storyData?.storie_url}
                    onError={(event) =>
                      (event.target.style.display = 'none')
                    }
                  />
                </div>
                <div className="table">
                  <Text
                    fontWeight="bold"
                    fontSize={14}
                    className="fullWidth"
                  >
                    Resumen
                  </Text>
                  <div className="rowContainer">
                    <Text fontSize={14}>Impresiones</Text>
                    <Text fontSize={14}>
                      {storyData?.impressions || 0}
                    </Text>
                  </div>
                  <div className="rowContainer lineTop">
                    <Text fontWeight="bold" fontSize={14}>
                      Interacciones
                    </Text>
                    <Text fontWeight="bold" fontSize={14}>
                      {(storyData?.replies || 0) +
                        (storyData?.likes || 0) +
                        (storyData?.shared || 0)}
                    </Text>
                  </div>
                  <div className="rowContainer">
                    <Text fontSize={14}>Respuestas</Text>
                    <Text fontSize={14}>
                      {storyData?.replies || 0}
                    </Text>
                  </div>
                  <div className="rowContainer">
                    <Text fontSize={14}>Me gusta</Text>
                    <Text fontSize={14}>{storyData?.likes || 0}</Text>
                  </div>
                  <div className="rowContainer">
                    <Text fontSize={14}>Compartida</Text>
                    <Text fontSize={14}>
                      {storyData?.shared || 0}
                    </Text>
                  </div>
                  <div className="rowContainer lineTop ">
                    <Text fontWeight="bold" fontSize={14}>
                      Cuentas etiquetadas
                    </Text>
                    <Text fontWeight="bold" fontSize={14}>
                      {storyData.tags?.length || 0}
                    </Text>
                  </div>
                  <div className="tagedAccounts">
                    {storyData.tags?.map((data, i) => (
                      <div className="rowContainer" key={i}>
                        <Text fontSize={14}>@{data.account}</Text>
                        <Text fontSize={14}>
                          {data.clicks || 0}
                          {data.clicks > 1 ? ' clicks' : ' click'}
                        </Text>
                      </div>
                    ))}
                  </div>
                </div>
                <ButtonPrimary
                  className="closeButton"
                  label="Cerrar"
                  onClick={handleModalClose}
                />
              </div>
            ))}
          />
        </DashboardCard>
      </Modal>
      <Carousel
        index={mainCarouselIndex}
        onBack={() => setMainCarouselIndex((crr) => crr - 1)}
        onNext={() => setMainCarouselIndex((crr) => crr + 1)}
        isBackDisabled={mainCarouselIndex === 0}
        isNextDisabled={
          mainCarouselIndex === storiesGrouped.length - 1
        }
        className="carousel"
        itemList={storiesGrouped.map((storyGroup, mainIndex) => (
          <div className="cardCarousel" key={mainIndex}>
            {storyGroup.map((data, i) =>
              data ? (
                <div key={i} className="storyFilled">
                  <div className="imagePlaceholder">
                    <div className="iconWrapper">
                      <Icon
                        icon={ImagePlaceholder}
                        size={24}
                        color={GRAY_LIGHT}
                      />
                    </div>
                    <Button
                      className="imageWrapper"
                      onClick={() =>
                        handleStoryOnModalIndex(mainIndex * 3 + i)
                      }
                    >
                      <img
                        className="image"
                        src={data?.storie_url}
                        onError={(event) =>
                          (event.target.style.display = 'none')
                        }
                      />
                    </Button>
                  </div>
                  <div className="views">
                    <Icon icon={Eye} size={16} />
                    <Text fontSize={14}>{data.views}</Text>
                  </div>
                </div>
              ) : (
                <div key={i} className="storyMissing">
                  <div className="imagePlaceholder">
                    <div className="iconWrapper">
                      <Icon
                        icon={ImagePlaceholder}
                        size={24}
                        color={GRAY_LIGHT}
                      />
                    </div>
                  </div>
                  <div className="text" />
                </div>
              ),
            )}
          </div>
        ))}
      />
    </div>
  );
}

MostViewedStoriesCarousel.propTypes = {
  dates: PropTypes.string,
  storiesList: PropTypes.arrayOf(
    PropTypes.shape({
      moment_id: PropTypes.string,
      stori_url: PropTypes.string,
      impressions: PropTypes.string,
      replies: PropTypes.string,
      reactions: PropTypes.string,
      likes: PropTypes.string,
      shared: PropTypes.string,
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          account: PropTypes.string,
          clicks: PropTypes.string,
        }),
      ),
    }),
  ),
};
