/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import Button from 'components/buttons/Button';
import { GRAY_TEXT, GRAY_LIGHT, GRAY_LIGHTEST } from 'styles/colors';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';

const style = css`
  display: flex;
  justify-content: space-beetwen;
  align-items: center;
  height: 100%;

  .main {
    flex: 1;
    margin: 0 10px;
    max-width: calc(100% - 70px);
  }

  .button {
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    background-color: ${GRAY_LIGHTEST};
    border-radius: 50%;
    flex-shrink: 0;

    &:hover:not(.--disabled) {
      background-color: ${GRAY_LIGHT};

      .arrow {
        fill: ${GRAY_TEXT};
      }
    }

    &:last-of-type .arrow {
      transform: rotate(-90deg);
    }

    .arrow {
      transform: rotate(90deg);
    }
  }
`;

export default function Carousel({
  itemList,
  index,
  onBack,
  onNext,
  isBackDisabled,
  isNextDisabled,
  className,
}) {
  return (
    <div css={style} className={className}>
      <Button
        className={`button  ${isBackDisabled ? '--disabled' : ''}`}
        onClick={onBack}
        disabled={isBackDisabled}
      >
        <Icon
          size={10}
          icon={Chevron}
          color={GRAY_LIGHT}
          className="arrow"
        />
      </Button>
      <div className="main">{itemList?.[index]}</div>
      <Button
        className={`button  ${isNextDisabled ? '--disabled' : ''}`}
        onClick={onNext}
        disabled={isNextDisabled}
      >
        <Icon
          size={10}
          icon={Chevron}
          color={GRAY_LIGHT}
          className="arrow"
        />
      </Button>
    </div>
  );
}

Carousel.propTypes = {
  itemList: PropTypes.arrayOf(PropTypes.element),
  className: PropTypes.string,
  index: PropTypes.number,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  isBackDisabled: PropTypes.bool,
  isNextDisabled: PropTypes.bool,
};
