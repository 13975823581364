/** @jsxImportSource @emotion/react */
import moment from 'moment';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  BLACK,
  GRAY,
  WHITE,
  GRAY_MID,
  PURPLE_FEEK,
} from 'styles/colors';
import Icon from 'components/Icon';
import Text from 'components/Text';
import Button from 'components/buttons/Button';
import ImagePlaceholder from 'assets/images/ImagePlaceholder.jpg';
import { ReactComponent as CalendarMini } from 'assets/icons/CalendarMini.svg';

const styles = css`
  gap: 10px;
  display: flex;
  padding: 10px;
  margin-bottom: 16px;
  align-items: center;
  border-radius: 16px;
  background-color: ${WHITE};
  border: 1px solid ${GRAY_MID};

  .participation-info {
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .places-badge {
    padding: 4px 7px;
    border-radius: 16px;
    border: 1px solid ${BLACK};
  }

  .image {
    width: 90px !important;
    height: 100px;
    object-fit: cover;
    border-radius: 8px;
  }

  .capitalize {
    text-transform: capitalize;
  }
`;

export default function CardExperienceSmall({
  image,
  title,
  places,
  ranking,
  eventDate,
  onPress,
}) {
  return (
    <Button
      onClick={onPress}
      css={styles}
      hideHoverActiveStyles={!onPress}
    >
      <div>
        <img
          className="image"
          src={image}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = ImagePlaceholder;
          }}
        />
      </div>
      <div className="participation-info">
        {places && (
          <div className="places-badge">
            <Text color={BLACK} fontWeight={600} fontSize={12}>
              {places} lugar{places > 1 ? 'es' : ''}
            </Text>
          </div>
        )}
        {ranking && (
          <Text
            fontSize={14}
            color={ranking > 3 ? BLACK : PURPLE_FEEK}
          >
            Participando en <b>{ranking}° lugar</b>
          </Text>
        )}
        <Text color={BLACK} fontSize={16} fontWeight={600}>
          {title}
        </Text>
        <div className="d-flex">
          <Icon icon={CalendarMini} size={16} />
          <Text
            color={GRAY}
            fontSize={14}
            className="capitalize ms-1"
          >
            {moment(eventDate).format('dddd DD MMM | H:mm A')}
          </Text>
        </div>
      </div>
    </Button>
  );
}

CardExperienceSmall.propTypes = {
  places: PropTypes.number,
  ranking: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  eventDate: PropTypes.string.isRequired,
};
