/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Avatar from 'components/Avatar';
import { MEDIA_QUERIES } from 'styles/constants';
import { propTypeLength } from 'utils/propValidation';
import { GRAY_LIGHTEST, PURPLE_FEEK, WHITE } from 'styles/colors';

export const AvatarsCabinet = ({
  avatars,
  text,
  textColor = WHITE,
  textBGColor = PURPLE_FEEK,
  className,
  avatarsSize = 50,
  sizeMobile,
  overlap = 25,
  overlapMobile,
  borderRadius = '50%',
}) => {
  const styles = css`
    display: flex;
    flex-direction: row-reverse;
    width: fit-content;

    .avatar {
      background-color: ${WHITE};
      border: 2px solid ${GRAY_LIGHTEST};

      :not(:last-child) {
        margin-inline-start: -${overlap}px;
      }

      &.avatarText {
        font-size: 16px;
        font-weight: 600;
        color: ${textColor};
        background: ${textBGColor};
      }
    }

    ${MEDIA_QUERIES.mobile} {
      .avatar {
        :not(:last-child) {
          margin-inline-start: -${overlapMobile}px;
        }
      }
    }
  `;

  return (
    <article css={styles} className={className}>
      {text && (
        <Avatar
          fullName
          name={text}
          size={avatarsSize}
          sizeMobile={sizeMobile}
          className="avatar avatarText"
          borderRadius={borderRadius}
        />
      )}
      {avatars?.map((avatar, index) => (
        <Avatar
          key={index}
          image={avatar}
          className="avatar"
          size={avatarsSize}
          sizeMobile={sizeMobile}
          borderRadius={borderRadius}
        />
      ))}
    </article>
  );
};

AvatarsCabinet.propTypes = {
  text: PropTypes.string,
  overlap: PropTypes.number,
  className: PropTypes.string,
  textColor: PropTypes.string,
  sizeMobile: PropTypes.number,
  borderRadius: propTypeLength,
  textBGColor: PropTypes.string,
  avatarsSize: PropTypes.number,
  overlapMobile: PropTypes.number,
  avatars: PropTypes.arrayOf(PropTypes.string),
};
