import Text from 'components/Text';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';

import APIClient from 'redux/api';
import { setCampaigns } from 'redux/entities';
import FilterItem from 'components/FilterItem';
import ModalAuth from 'components/modals/ModalAuth';
import { BLACK, PURPLE_FEEK, WHITE } from 'styles/colors';
import FeekspotCampaign from 'components/FeekspotCampaign';
import ButtonPrimary from 'components/buttons/ButtonPrimary';

export default function TopFeekspots({ className }) {
  const [loading, setLoading] = useState(false);
  const [typesFilter, setTypesFilter] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [categoriesFilter, setCategoriesFilter] = useState([]);
  const [authModalData, setAuthModalData] = useState({
    show: false,
    title: '',
  });

  const lastPage = paginationData[paginationData.length - 1];

  const dispatch = useDispatch();

  const { categories, campaignTypes, campaigns } = useSelector(
    (state) => state.entities,
  );

  const likeButtonOnClick = useMemo(
    () =>
      !localStorage.getItem('currentFeekToken')
        ? () =>
            setAuthModalData({
              show: true,
              title: 'Inicia sesión para agregar a favoritos',
            })
        : null,
    [],
  );

  useEffect(() => {
    setLoading(true);
    APIClient.getPublicTopFeekspots({ page: 1, limit: 30 })
      .then(({ data }) => {
        setPaginationData([
          {
            ...data,
            docs: data.docs,
          },
        ]);
        return data.docs;
      })
      .then((feekspotCampaigns) => {
        feekspotCampaigns?.map((feekspotCampaign) => {
          APIClient.getManyCampaigns(feekspotCampaign.campaigns).then(
            ({ data }) => {
              dispatch(setCampaigns(data));
            },
          );
        });
      })
      .finally(() => setLoading(false));
  }, []);

  const fetchMore = () => {
    setLoading(true);

    APIClient.getPublicTopFeekspots({
      page: lastPage.nextPage,
      limit: lastPage.limit,
    })
      .then(({ data }) => {
        setPaginationData([
          ...paginationData,
          {
            ...data,
            docs: data.docs,
          },
        ]);
        return data.docs;
      })
      .then((feekspotCampaigns) => {
        feekspotCampaigns?.map((feekspotCampaign) => {
          APIClient.getManyCampaigns(feekspotCampaign.campaigns).then(
            ({ data }) => {
              dispatch(setCampaigns(data));
            },
          );
        });
      })
      .finally(() => setLoading(false));
  };

  const feekspots = useMemo(
    () =>
      paginationData?.reduce(
        (prev, crr) => [...prev, ...crr.docs],
        [],
      ),
    [paginationData],
  );

  const dataToRender = useMemo(() => {
    return categoriesFilter.length > 0 || typesFilter.length > 0
      ? feekspots.filter(
          (feekspot) =>
            categoriesFilter.includes(
              feekspot.customer.categories[0],
            ) ||
            typesFilter.includes(
              campaigns?.[feekspot?.campaigns?.[0]]?.type,
            ),
        )
      : feekspots;
  }, [categoriesFilter, feekspots, typesFilter]);

  const clearFilters = () => {
    setCategoriesFilter([]);
    setTypesFilter([]);
  };

  return (
    <>
      <ModalAuth
        title={authModalData.title}
        visible={authModalData.show}
        onRequestClose={() =>
          setAuthModalData({ show: false, title: '' })
        }
      />

      <div className={`row w-100 ${className}`}>
        <div className="col-12 col-md-3 mb-4">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <Text color={BLACK} fontSize={32} fontWeight={600}>
              Filtros
            </Text>
            <Text
              onClick={clearFilters}
              color={PURPLE_FEEK}
              fontSize={16}
              className="cursor-pointer"
            >
              Borrar filtros
            </Text>
          </div>
          <Text
            color={BLACK}
            fontSize={16}
            fontWeight={600}
            className="mb-1"
          >
            Tipo de oferta
          </Text>

          {Object.values(campaignTypes).map((type) => (
            <FilterItem
              key={type._id}
              label={type.name}
              checked={typesFilter.includes(type._id)}
              counter={
                feekspots.filter(
                  (feekspot) =>
                    campaigns?.[feekspot?.campaigns?.[0]]?.type ===
                    type._id,
                ).length
              }
              onChange={(checked) =>
                checked
                  ? setTypesFilter([...typesFilter, type._id])
                  : setTypesFilter(
                      typesFilter.filter((cat) => cat !== type._id),
                    )
              }
            />
          ))}

          <Text
            color={BLACK}
            fontSize={16}
            fontWeight={600}
            className="mb-1 mt-4 pt-3"
          >
            Categoría
          </Text>

          <form>
            {Object.values(categories).map((category) => (
              <FilterItem
                key={category._id}
                label={category.name}
                checked={categoriesFilter.includes(category._id)}
                onChange={(checked) =>
                  checked
                    ? setCategoriesFilter([
                        ...categoriesFilter,
                        category._id,
                      ])
                    : setCategoriesFilter(
                        categoriesFilter.filter(
                          (cat) => cat !== category._id,
                        ),
                      )
                }
                counter={
                  feekspots.filter(
                    (feekspot) =>
                      feekspot.customer.categories[0] ===
                      category._id,
                  ).length
                }
              />
            ))}
          </form>
        </div>
        <div className="col-12 col-md-9">
          <Text
            color={BLACK}
            fontSize={32}
            fontWeight={600}
            className="mb-5 ps-md-4"
          >
            Explorar negocios cerca de ti
          </Text>

          <div className="row w-100 ps-md-4">
            {dataToRender &&
              dataToRender.map((feekspot, index) => (
                <div
                  key={`${feekspot.customer._id}-${index}`}
                  className="col-lg-6 col-xl-4 mb-4"
                >
                  <FeekspotCampaign
                    campaigns={feekspot.campaigns}
                    feekspotName={feekspot.customer.name}
                    feekspotId={feekspot.customer._id}
                    category={
                      feekspot.customer.categories &&
                      feekspot.customer.categories[0]
                    }
                    backgroundImage={
                      feekspot.customer.background_image
                    }
                    isLiked={feekspot.customer.is_liked}
                    likeButtonOnClick={likeButtonOnClick}
                  />
                </div>
              ))}
          </div>

          {lastPage?.hasNextPage && (
            <div className="d-flex justify-content-center my-5 py-5">
              <ButtonPrimary
                disabled={loading}
                strokeVariant
                color={BLACK}
                textColor={WHITE}
                onClick={fetchMore}
                className="button-rounded"
                label="Mostrar más negocios"
                textProps={{ fontSize: 20, fontWeight: 500 }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

TopFeekspots.propTypes = {
  className: PropTypes.string,
};
