/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import {
  GRAY,
  BLACK,
  WHITE,
  GRAY_MID,
  GRAY_DARK,
  INSTAGRAM,
  TRANSPARENT,
  GREEN_STATUS,
  GREEN_WHATSAPP,
  RED_NOTIFICATIONS,
  PINK_BRIGHT_SECUNDARY,
} from 'styles/colors';
import Text from 'components/Text';
import Icon from 'components/Icon';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/app/feekApp';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_FEEKAPP } from 'constants/routes';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as Phone } from 'assets/icons/Phone.svg';
import { ReactComponent as XMini } from 'assets/icons/XMini.svg';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import { ReactComponent as WhatsApp } from 'assets/icons/WhatsApp.svg';
import { ReactComponent as Instagram } from 'assets/icons/Instagram.svg';
import ModalEditPhoneOrEmail from 'components/modals/ModalEditPhoneOrEmail';
import { ReactComponent as CheckCircle } from 'assets/icons/CheckCircle.svg';
import { ReactComponent as MailEnvelope } from 'assets/icons/MailEnvelope.svg';
import ModalInstagramVinculation from 'components/modals/ModalInstagramVinculation';

const styles = css`
  --gap-small: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 30px;
  padding: 24px;
  background-color: ${WHITE};
  border-radius: 20px;

  .info-links {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    gap: var(--gap-small);

    .info-button {
      padding-inline: 20px 16px;
    }
  }

  .info-section {
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);

    .contact-button-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;

      .contact-button {
        padding: 14px 26.5px;
        flex: 1;

        &:has(.explicit) {
          gap: 0;
        }

        .explicit {
          flex: 1;
          margin-inline: 15px;
        }
      }

      .error-icon {
        background-color: ${RED_NOTIFICATIONS};
        border-radius: 50%;
        padding: 3px;
      }
    }
  }

  .button-logout {
    display: none;
  }

  ${MEDIA_QUERIES.mobile} {
    padding: 16px 16px 32px;

    .button-logout {
      display: revert;
      width: fit-content;
      margin-inline: auto;
      text-decoration: underline;
      font-size: 16px;
      font-weight: 600;
    }
  }
`;

const ContactData = ({
  feekerContactData: {
    instagramUserName,
    isFeekerVerifiedEmail,
    isFeekerVerifiedPhone,
    feekerPhone,
    feekerEmail,
  },
  className,
}) => {
  const [currentModal, setCurrentModal] = useState(null);
  const dispatch = useDispatch();

  return (
    <aside css={styles} className={className}>
      <ModalEditPhoneOrEmail
        onRequestClose={() => setCurrentModal(null)}
        {...(currentModal === 'ModalEditPhone'
          ? { visible: true, type: 'phone' }
          : currentModal === 'ModalEditEmail'
          ? { visible: true, type: 'email' }
          : { visible: false })}
      />
      <ModalInstagramVinculation
        onRequestClose={() => setCurrentModal(null)}
        visible={currentModal === 'ModalInstagramVinculation'}
      />

      <section className="info-links">
        <Link to={ROUTES_FEEKAPP.INFO}>
          <ButtonPrimary
            layoutOnly
            distribution="space-between"
            className="info-button"
            fontSize={14}
            height={48}
            label="Información"
            textColor={BLACK}
            strokeVariant
            strokeColor={GRAY_MID}
            icon={
              <Icon
                icon={Chevron}
                color={BLACK}
                width={14}
                rotate={-90}
              />
            }
            iconPosition="right"
          />
        </Link>

        <a
          href={`https://wa.me/${process.env.REACT_APP_WHATSAPP_HELP_NUMBER}/?text=%C2%A1Hola%2C%20Feek!%20%F0%9F%93%B1`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonPrimary
            layoutOnly
            distribution="space-between"
            className="info-button"
            fontSize={14}
            height={48}
            label="Ayuda"
            textColor={WHITE}
            color={GREEN_WHATSAPP}
            strokeVariant
            strokeColor={GRAY_MID}
            icon={<Icon icon={WhatsApp} size={20} color={WHITE} />}
            iconPosition="right"
          />
        </a>
      </section>

      <section className="info-section">
        <Text color={BLACK} fontSize={18} fontWeight={600}>
          Instagram para validar contenido
        </Text>
        <Text color={BLACK} fontSize={14} lineHeight={21}>
          Por cuestiones de seguridad el cambio de cuenta está
          limitado a 1 vez cada 30 días.
        </Text>

        <div className="contact-button-wrapper">
          <ButtonPrimary
            className="contact-button"
            icon={
              <Icon
                size={20}
                icon={Instagram}
                color={
                  instagramUserName ? PINK_BRIGHT_SECUNDARY : WHITE
                }
              />
            }
            distribution="start"
            onClick={() =>
              setCurrentModal('ModalInstagramVinculation')
            }
            {...(instagramUserName
              ? {
                  label: (
                    <Text
                      color={PINK_BRIGHT_SECUNDARY}
                      fontSize={16}
                      fontWeight={600}
                      lineHeight={48}
                      align="center"
                      className="label explicit"
                    >
                      {instagramUserName}
                    </Text>
                  ),
                  strokeColor: PINK_BRIGHT_SECUNDARY,
                  strokeVariant: true,
                  color: TRANSPARENT,
                }
              : { color: INSTAGRAM, label: 'Vincular Instagram' })}
          />
          {instagramUserName && (
            <Icon icon={CheckCircle} color={GREEN_STATUS} size={16} />
          )}
        </div>
      </section>

      <section className="info-section">
        <Text color={BLACK} fontSize={18} fontWeight={600}>
          Datos de contacto{' '}
          <Text
            fontSize={18}
            fontWeight={600}
            type="inline"
            color={GRAY}
          >
            (
            {100 -
              (isFeekerVerifiedPhone ? 0 : 50) -
              (isFeekerVerifiedEmail ? 0 : 50)}
            %)
          </Text>
        </Text>
        <Text color={BLACK} fontSize={14} lineHeight={21}>
          Para aprovechar las ofertas y promociones que Feek te ofrece
          te sugerimos que completes los siguientes datos:
        </Text>

        <div className="contact-button-wrapper">
          <ButtonPrimary
            width="clamp(100px, 260px, 300px)"
            className="contact-button"
            distribution="start"
            onClick={() => setCurrentModal('ModalEditPhone')}
            icon={
              <Icon
                color={
                  feekerPhone && isFeekerVerifiedPhone
                    ? BLACK
                    : feekerPhone
                    ? RED_NOTIFICATIONS
                    : WHITE
                }
                height={20}
                icon={Phone}
              />
            }
            {...(feekerPhone
              ? {
                  strokeVariant: true,
                  label: (
                    <Text
                      color={BLACK}
                      fontSize={16}
                      fontWeight={600}
                      lineHeight={48}
                      align="center"
                      className="label explicit"
                    >
                      {feekerPhone}
                    </Text>
                  ),
                  ...(isFeekerVerifiedPhone
                    ? {
                        strokeColor: GRAY_DARK,
                      }
                    : {
                        title: 'Teléfono sin verificar',
                        strokeColor: RED_NOTIFICATIONS,
                      }),
                }
              : {
                  label: 'Ingresa un teléfono',
                  color: BLACK,
                })}
          />
          {feekerPhone &&
            (isFeekerVerifiedPhone ? (
              <Icon
                icon={CheckCircle}
                color={GREEN_STATUS}
                size={16}
              />
            ) : (
              <Icon
                icon={XMini}
                color={WHITE}
                size={16}
                className="error-icon"
              />
            ))}
        </div>

        <div className="contact-button-wrapper">
          <ButtonPrimary
            width="clamp(100px, 260px, 300px)"
            className="contact-button"
            distribution="start"
            onClick={() => setCurrentModal('ModalEditEmail')}
            icon={
              <Icon
                color={
                  feekerEmail && isFeekerVerifiedEmail
                    ? BLACK
                    : feekerEmail
                    ? RED_NOTIFICATIONS
                    : WHITE
                }
                height={20}
                icon={MailEnvelope}
              />
            }
            {...(feekerEmail
              ? {
                  strokeVariant: true,
                  label: (
                    <Text
                      color={BLACK}
                      fontSize={16}
                      fontWeight={600}
                      lineHeight={48}
                      align="center"
                      className="label explicit"
                    >
                      {feekerEmail}
                    </Text>
                  ),
                  ...(isFeekerVerifiedEmail
                    ? {
                        strokeColor: GRAY_DARK,
                      }
                    : {
                        title: 'Email sin verificar',
                        strokeColor: RED_NOTIFICATIONS,
                      }),
                }
              : {
                  label: 'Ingresa un email',
                  color: BLACK,
                })}
          />
          {feekerEmail &&
            (isFeekerVerifiedEmail ? (
              <Icon
                icon={CheckCircle}
                color={GREEN_STATUS}
                size={16}
              />
            ) : (
              <Icon
                icon={XMini}
                color={WHITE}
                size={16}
                className="error-icon"
              />
            ))}
        </div>
      </section>

      <Button
        className="button-logout"
        onClick={() => dispatch(logout())}
      >
        Cerrar sesión
      </Button>
    </aside>
  );
};

ContactData.propTypes = {
  className: PropTypes.string,
  feekerContactData: PropTypes.shape({
    instagramUserName: PropTypes.string,
    isFeekerVerifiedEmail: PropTypes.bool,
    isFeekerVerifiedPhone: PropTypes.bool,
    feekerPhone: PropTypes.string,
    feekerEmail: PropTypes.string,
  }),
};

export default ContactData;
