/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Icon from 'components/Icon';
import Text from 'components/Text';
import { GRAY } from 'styles/colors';
import { getNumberWithUSAFormat } from 'utils/format';
import { ReactComponent as LocationMini } from 'assets/icons/LocationMini.svg';

export default function DistanceIndicator({
  color,
  gap = 4,
  fontSize,
  className,
  distance = 0,
  iconHeight = 16,
}) {
  const styles = css`
    display: flex;
    align-items: center;
    gap: ${gap}px;
  `;

  return (
    <Text
      css={styles}
      className={className}
      fontSize={fontSize || 14}
      color={color || GRAY}
      lineHeight={iconHeight}
      fontWeight={600}
    >
      <Icon
        height={iconHeight}
        icon={LocationMini}
        color={color || GRAY}
      />
      {getNumberWithUSAFormat(distance || 0)} km
    </Text>
  );
}

DistanceIndicator.propTypes = {
  gap: PropTypes.number,
  color: PropTypes.string,
  fontSize: PropTypes.number,
  distance: PropTypes.number,
  className: PropTypes.string,
  iconHeight: PropTypes.number,
};
