/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  RED,
  BLACK,
  WHITE,
  PURPLE_FEEK,
  GRAY_DEFAULT,
  GRAY_LIGHTEST,
  PURPLE_INACTIVE_LIGHT,
} from 'styles/colors';
import Text from 'components/Text';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import FeekspotCampaignCard from 'screens/manager/legacy/Campaigns/FeekspotCampaignCard';

const styles = css`
  .headerTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      cursor: help;
    }
    .navBar {
      display: flex;

      .navButton {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background: ${PURPLE_FEEK};
        border-radius: 4.5px;
        margin-left: 12px;
        padding-bottom: 5px;
        color: ${WHITE};
        user-select: none;

        &.disabled {
          border: 1.145px solid ${PURPLE_FEEK};
          background: transparent;
          color: ${PURPLE_FEEK};
        }
      }
    }
  }

  .campaignsSlider {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 56px;
    margin-block: 25px 40px;
    justify-content: center;

    .campaignCardContainer {
      position: relative;
      display: flex;
      flex-direction: column;
      background: ${GRAY_LIGHTEST};
      border-radius: 8px;
      border: solid 1px ${GRAY_DEFAULT};
      width: 100%;
      height: fit-content;

      .deleteWarningContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background: ${BLACK}40;
        backdrop-filter: blur(2.5px);
        border-radius: 8px;
      }

      .deleteWarningContainerBackground {
        position: fixed;
        width: 1000vw;
        height: 1000vh;
        z-index: 150;
        left: 50%;
        top: 50%;
        translate: -50% -50%;
        cursor: default;
      }

      .confirmDeleteCampaignButton {
        z-index: 151;
        color: ${WHITE};
        font-size: 16px;
        font-weight: 700;
        background: ${RED};
        border-radius: 12px;
        padding: 16px 14px;
        position: absolute;
        left: 50%;
        top: 50%;
        translate: -50% -50%;
      }

      .topCampaignCardContainer {
        display: grid;
        gap: 15px;
        grid-template-rows: repeat(3, 25px);
        background-color: ${WHITE};
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 20px;
        height: 55%;

        .statusAndDropDownContainer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .statusCard {
            display: flex;
            align-items: center;
            padding-inline: 5px;
            height: 25px;
            background: ${PURPLE_INACTIVE_LIGHT};
            border-radius: 4px;
          }

          .completed {
            background: ${PURPLE_FEEK};
          }
        }

        .campaignTitle {
          height: fit-content;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }
      }
    }
  }

  ${MEDIA_QUERIES.smallScreen} {
    .campaignsSlider {
      grid-template-columns: 1fr;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    .campaignsSlider {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      margin-block: 30px;

      .campaignCardContainer {
        .topCampaignCardContainer {
          height: auto;
          grid-template-rows: unset;

          .campaignTitle {
            overflow: initial;
            -webkit-line-clamp: initial;
            -webkit-box-orient: initial;
            display: initial;
          }
        }
      }
    }
  }
`;

function CampaignsSlider({
  title,
  campaigns,
  handleDeleteCampaign,
  handleEditCampaign,
  handleCloneAndEditCampaign,
  showValidateInfo,
  selectedFeekspotId,
}) {
  const [numberOfPage, setNumberOfPage] = useState(0);

  useEffect(() => setNumberOfPage(0), [selectedFeekspotId]);

  const numberOfColumns = window.innerWidth > 520 ? 2 : 1;

  const nextPage = () => {
    if (campaigns?.length >= numberOfColumns * (numberOfPage + 1))
      setNumberOfPage((current) => current + 1);
  };

  const backPage = () => {
    if (numberOfPage > 0) setNumberOfPage((current) => current - 1);
  };

  return (
    <div css={styles}>
      <div className="headerTitle">
        <Text
          title="Estas campañas son ofertas que se publican como Momentos en la Feek App para los Feekers."
          fontSize={22}
          fontWeight="600"
          className="title"
        >
          {title}
        </Text>

        <div className="navBar">
          <Button
            onClick={backPage}
            {...(numberOfPage === 0
              ? { className: 'navButton disabled', disabled: true }
              : { className: 'navButton' })}
          >
            ←
          </Button>
          <Button
            onClick={nextPage}
            {...(campaigns?.length <= 2 * (numberOfPage + 1)
              ? { className: 'navButton disabled', disabled: true }
              : { className: 'navButton' })}
          >
            →
          </Button>
        </div>
      </div>

      <div className="campaignsSlider">
        {campaigns?.map((campaign, index) => {
          if (
            numberOfPage * numberOfColumns <= index &&
            (numberOfPage + 1) * numberOfColumns > index
          ) {
            return (
              <FeekspotCampaignCard
                key={campaign._id}
                campaign={campaign}
                handleCloneAndEditCampaign={
                  handleCloneAndEditCampaign
                }
                handleEditCampaign={handleEditCampaign}
                handleDeleteCampaign={handleDeleteCampaign}
                showValidateInfo={showValidateInfo}
              />
            );
          }
        })}
      </div>
    </div>
  );
}

CampaignsSlider.propTypes = {
  title: PropTypes.string,
  campaigns: PropTypes.arrayOf(PropTypes.object),
  handleDeleteCampaign: PropTypes.func,
  handleEditCampaign: PropTypes.func,
  handleCloneAndEditCampaign: PropTypes.func,
  setModalContent: PropTypes.func,
  showValidateInfo: PropTypes.bool,
  selectedFeekspotId: PropTypes.string,
};

export default CampaignsSlider;
