/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';

import {
  setFeekspots,
  removeFeekspotInvitations,
} from 'redux/entities';
import APIClient from 'redux/api';
import Text from 'components/Text';
import { RED, WHITE } from 'styles/colors';
import Modal from 'components/modals/Modal';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { selectFeekspot } from 'redux/app/managerApp';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import FeekerLookingReport from 'assets/images/FeekerLookingReport.png';

const styles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 450px;
  padding: 32px;
  background-color: ${WHITE};
  border-radius: 8px;

  .image {
    width: 190px;
    height: 140px;
    margin-bottom: 20px;
  }

  .button {
    height: 46px;
  }

  .declineBtn {
    margin-top: -10px;
    text-decoration: underline;
    text-decoration-color: ${RED};

    &:disabled {
      opacity: 0.5;
    }
  }
`;

function AcceptInvitationToFeekspotModal({
  isOpen,
  roleName,
  feekspotId,
  handleClose,
  feekspotName,
  roleDescription,
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleRespondInvitation = async (accepted) => {
    setIsLoading(true);

    try {
      const res = await APIClient.respondFeekspotInvitation(
        feekspotId,
        accepted,
      );

      if (res.status === 201) {
        dispatch(removeFeekspotInvitations(feekspotId));

        if (accepted) {
          dispatch(setFeekspots([res.data]));
          dispatch(selectFeekspot(res.data.customer._id));
        }

        handleClose();
      }
    } catch (error) {
      setIsLoading(false);
      handleError(error);
    }
  };

  return (
    <Modal visible={isOpen} onRequestClose={handleClose}>
      <div css={styles}>
        <Text fontSize={24} align="center" lineHeight={32}>
          Te han invitado a ser <strong>{roleName}</strong> de{' '}
          <strong>{feekspotName}</strong>
        </Text>
        <Text fontSize={16} lineHeight={24} align="center">
          Como {roleName} podrás: {roleDescription}
        </Text>
        <img
          src={FeekerLookingReport}
          alt="Feeker looking report"
          className="image"
        />
        <ButtonPrimary
          className="button"
          disabled={isLoading}
          label="Aceptar Invitación"
          onClick={() => handleRespondInvitation(true)}
        />
        <Button
          disabled={isLoading}
          className="declineBtn"
          onClick={() => handleRespondInvitation(false)}
        >
          <Text color={RED} fontSize={14}>
            Rechazar Invitación
          </Text>
        </Button>
      </div>
    </Modal>
  );
}

AcceptInvitationToFeekspotModal.propTypes = {
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
  roleName: PropTypes.string,
  roleDescription: PropTypes.string,
  feekspotName: PropTypes.string,
  feekspotId: PropTypes.string,
};

export default AcceptInvitationToFeekspotModal;
