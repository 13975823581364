import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { authSteps } from 'constants';
import { titlesFeekApp } from 'constants';
import { WHITE, BLACK } from 'styles/colors';
import LayoutLogin from 'components/LayoutLogin';
import { ROUTES_FEEKAPP } from 'constants/routes';
import FormLogin from 'components/FlowLogin/FormLogin';
import LoginMethod from 'components/FlowLogin/LoginMethod';
import ButtonPrimary from 'components/buttons/ButtonPrimary';

export default function LoginFeeker() {
  const [currentStep, setCurrentStep] = useState(
    authSteps.LOGIN_OPTIONS,
  );
  const [usePhone, setUsePhone] = useState(false);
  const navigate = useNavigate();

  const steps = [LoginMethod, FormLogin];
  const Step = steps[currentStep];

  function handleBack() {
    if (currentStep > 0) setCurrentStep((crrStep) => crrStep - 1);
    else navigate(ROUTES_FEEKAPP.LANDING);
  }

  document.title = titlesFeekApp.Login;

  return (
    <LayoutLogin
      headerButtons={
        <Link to={ROUTES_FEEKAPP.REGISTRATION}>
          <ButtonPrimary
            textColor={WHITE}
            label="Registrarse"
            color={BLACK}
            pillShape
            layoutOnly
            textProps={{ fontSize: 16, fontWeight: 600 }}
          />
        </Link>
      }
    >
      <Step
        usePhone={usePhone}
        setUsePhone={setUsePhone}
        setCurrentStep={setCurrentStep}
        onBackButton={handleBack}
        onLoginSuccess={() => {
          navigate(ROUTES_FEEKAPP.EXPLORE);
        }}
      ></Step>
    </LayoutLogin>
  );
}
