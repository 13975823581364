/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import {
  RED,
  GRAY_LIGHT,
  GRAY_LIGHTER,
  GRAY_DEFAULT,
  GRAY_LIGHTEST,
} from 'styles/colors';
import { roles } from 'constants';
import Text from 'components/Text';
import Avatar from 'components/Avatar';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import ModalUnlinkFeekspot from 'screens/manager/legacy/ProfileCustomer/ModalUnlinkFeekspot';

const styles = css`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 30px;
  background: ${GRAY_LIGHTEST};
  border: 1px solid ${GRAY_LIGHTER};
  border-radius: 8px;

  .feekspotsList {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 15px;
    align-items: center;
  }

  .feekspotsListHeader {
    display: contents;
  }

  .feekspot {
    display: contents;

    .nameWrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .label {
      display: none;
    }

    .data:not(:last-of-type),
    .name {
      height: fit-content;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .name {
      padding-block-end: 2px;
    }

    .unlinkButton {
      color: ${RED};
      font-size: 14px;

      &.disabled {
        color: ${GRAY_DEFAULT};
        opacity: 1;
      }
    }
  }

  ${MEDIA_QUERIES.mobile} {
    padding: 24px 20px;
    gap: 25px;

    .feekspotsMainHeader {
      font-size: 18px;
    }

    .feekspotsList {
      display: contents;

      .feekspotsListHeader {
        display: none;
      }

      .feekspot {
        display: grid;
        grid: repeat(4, auto) / min-content 47px auto;
        align-items: center;
        gap: 15px 5px;
        padding: 0 0 25px 0;
        margin: 0;
        border-block-end: 2px dotted ${GRAY_LIGHT};

        .nameWrapper {
          display: contents;
        }

        .logoWrapper {
          grid-column-start: 1;
          grid-column-end: 2;
        }

        .label {
          display: inline;
          grid-column-start: 1;
          grid-column-end: 3;
        }

        .name {
          grid-column-start: 2;
          grid-column-end: 4;
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
        }

        .data {
          grid-column-start: 3;
          grid-column-end: 4;
        }
      }
    }
  }
`;

function AccountData({ name, image, instagram, role, handleUnlink }) {
  return (
    <div className="feekspot">
      <div className="nameWrapper">
        <Avatar name={name} image={image} size={25} sizeMobile={32} />

        <Text className="name" fontSize={14}>
          {name}
        </Text>
      </div>

      <Text fontWeight={600} className="label" fontSize={14}>
        Rol
      </Text>
      <Text className="data" fontSize={14}>
        {roles[role]}
      </Text>

      <Text fontWeight={600} className="label" fontSize={14}>
        Instagram
      </Text>
      <Text className="data" fontSize={14}>
        {instagram ? `@${instagram}` : 'No vinculado'}
      </Text>

      <Text fontWeight={600} className="label" fontSize={14}>
        Opciones
      </Text>
      <Button
        className="data unlinkButton"
        onClick={handleUnlink}
        disabled={!instagram || role === 'customerViewer'}
      >
        Desvincular Negocio
      </Button>
    </div>
  );
}

AccountData.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
  instagram: PropTypes.string,
  role: PropTypes.string,
  handleUnlink: PropTypes.func,
};

function FeekspotsList() {
  const feekspots = useSelector((state) => state.entities.feekspots);
  const [selectedFeekspot, setSelectedFeekspot] = useState(undefined);

  return (
    <>
      <ModalUnlinkFeekspot
        visible={!!selectedFeekspot}
        feekspotToUnlink={selectedFeekspot}
        onRequestClose={() => setSelectedFeekspot(undefined)}
      />

      <div css={styles}>
        <Text
          className="feekspotsMainHeader"
          fontSize={20}
          fontWeight={600}
        >
          Negocios
        </Text>

        <div className="feekspotsList">
          <div className="feekspotsListHeader">
            <Text fontWeight={600} fontSize={14}>
              Nombre
            </Text>

            <Text fontWeight={600} fontSize={14}>
              Rol
            </Text>

            <Text fontWeight={600} fontSize={14}>
              Instagram
            </Text>

            <Text fontWeight={600} fontSize={14}>
              Opciones
            </Text>
          </div>

          {Object.values(feekspots).map(
            ({ customer: { _id, name, image, instagram }, role }) => {
              return (
                <AccountData
                  key={_id}
                  name={name}
                  role={role}
                  image={image}
                  handleUnlink={() => setSelectedFeekspot(_id)}
                  instagram={instagram?.username}
                />
              );
            },
          )}
        </div>
      </div>
    </>
  );
}

export default FeekspotsList;
