/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Text from 'components/Text';
import { MEDIA_QUERIES } from 'styles/constants';
import Detective404 from 'assets/images/Detective404.png';
import ButtonPrimary from 'components/buttons/ButtonPrimary';

const styles = css`
  flex: 1;
  gap: min(7vw, 130px);

  &,
  .explanation {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .image-detective {
    max-height: 60vh;
    max-width: 40vw;
  }

  .explanation {
    flex-direction: column;
    gap: 15px;
    max-width: 365px;

    > *:last-child {
      margin-block-start: 15px;
      border-radius: 16px;
    }
  }

  ${MEDIA_QUERIES.smallScreen} {
    flex-direction: column;
    gap: min(4vw, 40px);
  }
`;

function NotFound() {
  return (
    <article css={styles}>
      <img
        src={Detective404}
        role="presentation"
        className="image-detective"
      />
      <div className="explanation">
        <Text fontSize={32} fontWeight="600">
          ¡UUUUPS!
        </Text>
        <Text fontSize={24}>
          Ha ocurrido un error, no pudimos encontrar lo que estás
          buscando. Te recomendamos volver al inicio
        </Text>
        <ButtonPrimary
          label="Volver a página de inicio"
          width={235}
        />
      </div>
    </article>
  );
}

export default NotFound;
