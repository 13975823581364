/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Icon from 'components/Icon';
import Text from 'components/Text';
import Button from 'components/buttons/Button';
import { PURPLE_FEEK, WHITE } from 'styles/colors';
import getClassNameFromArray from 'utils/getClassNameFromArray';
import ProgressIndicatorSpinner from 'components/ProgressIndicatorSpinner';
import { ReactComponent as CompassArrow } from 'assets/icons/CompassArrow.svg';

const styles = css`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: fit-content;

  .text {
    text-decoration: underline;
  }
`;
export default function ButtonCurrentLocation({
  className,
  onLocationSelected,
}) {
  const [loading, setLoading] = useState(false);
  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          convertToLocation(
            position.coords.latitude,
            position.coords.longitude,
          );
        },
        () => {
          setLoading(false);
          alert(
            'No pudimos obtener tu ubicación, por favor ingresa una dirección',
          );
        },
      );
    } else {
      alert(
        'No pudimos obtener tu ubicación, por favor ingresa una dirección',
      );
    }
  };
  const convertToLocation = (lat, lng) => {
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
    )
      .then((res) => res.json())
      .then((address) => {
        const location =
          getLocationComponent('sublocality', address.results) ||
          getLocationComponent(
            'administrative_area_level_2',
            address.results,
          );
        onLocationSelected({
          ...location,
          geometry: {
            location: {
              lat: () => lat,
              lng: () => lng,
            },
          },
        });
      })
      .finally(() => setLoading(false));
  };
  const getLocationComponent = (type, locationsList) => {
    return locationsList.find((component) =>
      component.types.includes(type),
    );
  };

  return (
    <Button
      className={getClassNameFromArray([
        'current-location-container',
        className,
      ])}
      css={styles}
      onClick={getCurrentLocation}
      disabled={loading}
      hideDisabledStyles
    >
      {loading ? (
        <ProgressIndicatorSpinner size={22} secondaryColor={WHITE} />
      ) : (
        <Icon icon={CompassArrow} color={PURPLE_FEEK} size={22} />
      )}
      <Text
        fontSize={16}
        lineHeight="100%"
        fontWeight={600}
        color={PURPLE_FEEK}
        className="text"
      >
        Usar mi ubicación actual
      </Text>
    </Button>
  );
}

ButtonCurrentLocation.propTypes = {
  className: PropTypes.string,
  onLocationSelected: PropTypes.func.isRequired,
};
