export const MANAGER_SUBDOMAIN = 'partners.';

export const ROUTES_FEEKAPP = {
  LANDING: '/landing',
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  CAMPAIGN: '/campaign/:id',
  PROFILE_FEEKER: '/users/:feekerUsername',
  PROFILE_FEEKER_EDIT: '/user_account',
  REGISTRATION: '/registration',
  CATEGORIES: '/category',
  CATEGORY: '/category/:category',
  SEARCHER: '/search',
  SEARCH: '/search/:search',
  EXPLORE: '/',
  EXPLORE_REWARDS: '/rewards',
  MAP: '/map',
  MAP_RESULTS: '/map/results',
  AGENDA: '/agenda',
  AGENDA_PROMOTIONS: '/agenda/promotions',
  AGENDA_REWARDS: '/agenda/rewards',
  PROFILE_FEEKSPOT: '/feekspot/:instagramUsername',
  EXPERIENCE: '/experience/:id',
  INFO: '/info',
  NOTIFICATIONS: '/notifications',
};

export const ROUTES_MANAGER = {
  LANDING: '/',
  LOGIN: '/login',
  HOME: '/home',
  CAMPAIGNS: '/campaigns',
  COLLABORATORS: '/collaborators',
  FORGOT_PASSWORD: '/forgotPassword',
  REGISTRATION: '/registration',
  REGISTRATION_VERIFICATION_CODE_EMAIL:
    '/RegistrationVerificationEmailCode',
  REGISTRATION_VERIFICATION_CODE_PHONE:
    '/RegistrationVerificationPhoneCode',
  REGISTRATION_FEEKSPOT: '/registrationFeekspot',
  REGISTRATION_FEEKSPOT_CATEGORIES: '/registrationFeekspotCategories',
  REGISTRATION_FEEKSPOT_SUCESS: '/registrationFeekspotSuccess',
  PAYMENT: '/payment',
  PAYMENT_HISTORY: '/paymentHistory',
  DASHBOARD: '/dashboard',
  ADD_CAMPAIGNS: '/addCampaign',
  COLLABORATOR_NEW_PASSWORD: '/newPasswordCollaborator',
  SUBSCRIPTION_SUCCESS: '/subscriptionSuccess',
  PROFILE_CUSTOMER: '/profileCustomer',
  PROFILE_FEEKSPOT: '/profileFeekspot',
  BILLING: '/billing',
};

export const ROUTES_AGNOSTIC = {
  SHARE_APP_DOWNLOAD: '/share/:router',
  SHARE_APP_DOWNLOAD_PARAMS: '/share/:router/:param',
  POLICY: '/policy',
  TERMS_AND_CONDITIONS: '/termsAndConditions',
  NOT_FOUND: '/404',
};
