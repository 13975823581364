/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import Icon from 'components/Icon';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { propTypeLength } from 'utils/propValidation';
import { getLength } from 'utils/cssStringManipulation';
import { GRADIENT_PURPLE, PURPLE_FEEK, WHITE } from 'styles/colors';

const ButtonPrimary = (props) => {
  const {
    icon,
    label,
    width,
    pillShape,
    strokeColor,
    gapMobile,
    heightMobile,
    textProps = {},
    fontSizeMobile,
    disabled = false,
    hideDisabledStyles,
    strokeVariant = false,
    iconPosition = 'left',
    distribution = 'center',
    height = 48,
    fontSize = 16,
    gap = distribution === 'space-around' ? 0 : 20,
    textColor = strokeVariant ? PURPLE_FEEK : WHITE,
    color = strokeVariant ? WHITE : GRADIENT_PURPLE,
    ...restProps
  } = props;

  const mobileStyles = [
    getLength({ height: heightMobile, gap: gapMobile }),
    fontSizeMobile
      ? `.label {font-size: ${getLength(fontSizeMobile)}}`
      : '',
  ];

  const styles = css`
    ${getLength({ width: width || 'auto', height })}
    display: flex;
    ${getLength({ gap })}
    padding: 0 16px;
    border-radius: ${pillShape ? '100vmax' : '10px'};
    align-items: center;
    justify-content: ${distribution};
    flex-direction: ${iconPosition === 'left'
      ? 'row'
      : 'row-reverse'};

    ${!strokeVariant
      ? `background: ${color};`
      : `background: ${color};
        border: 1px solid ${strokeColor || PURPLE_FEEK};`}

    .label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      user-select: none;
    }

    ${MEDIA_QUERIES.mobile} {
      ${mobileStyles.join(' ')}
    }
  `;

  return (
    <Button
      css={styles}
      disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      hideDisabledStyles={hideDisabledStyles}
      {...restProps}
    >
      {icon && icon}
      {typeof label === 'string' || typeof label === 'number' ? (
        <Text
          className="label"
          color={textColor}
          fontWeight={600}
          fontSize={fontSize}
          lineHeight={height}
          align="center"
          {...textProps}
        >
          {label}
        </Text>
      ) : (
        label
      )}
    </Button>
  );
};

function fontSizePropType(props, propName, componentName) {
  if (props[propName]) {
    if (typeof props['label'] !== 'string') {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. ${propName} will not be supplied if the label prop is not of type string.`,
      );
    } else {
      PropTypes.checkPropTypes(
        Text.propTypes,
        props,
        propName,
        componentName,
      );
    }
  }
}

ButtonPrimary.propTypes = {
  ...Button.propTypes,
  color: PropTypes.string,
  width: propTypeLength,
  height: propTypeLength,
  icon: PropTypes.shape({
    type: PropTypes.oneOf([Icon]),
  }),
  pillShape: PropTypes.bool,
  layoutOnly: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]),
  heightMobile: propTypeLength,
  strokeColor: PropTypes.string,
  strokeVariant: PropTypes.bool,
  hideDisabledStyles: PropTypes.bool,

  fontSize: fontSizePropType,
  fontSizeMobile: fontSizePropType,

  textColor: function (props, propName, componentName) {
    if (props[propName]) {
      if (
        typeof props['label'] !== 'string' &&
        typeof props['label'] !== 'number' &&
        props[propName]
      ) {
        return new Error(
          `Invalid prop ${propName} supplied to ${componentName}. ${propName} will not be supplied if the label prop is not of type string.`,
        );
      } else {
        PropTypes.checkPropTypes(
          Text.propTypes,
          props,
          propName,
          componentName,
        );
      }
    }
  },

  textProps: function (props, propName, componentName) {
    if (
      typeof props['label'] !== 'string' &&
      typeof props['label'] !== 'number' &&
      props[propName]
    ) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}. ${propName} will not be supplied if the label prop is not of type string.`,
      );
    }
  },

  iconPosition: function (props, propName, componentName) {
    if (props[propName]) {
      if (!props['icon']) {
        return new Error(
          `Invalid prop ${propName} supplied to ${componentName}. ${propName} will not be supplied if the icon prop is missing.`,
        );
      } else {
        if (!['left', 'right'].includes(props[propName])) {
          return new Error(
            `Invalid prop ${propName} supplied to ${componentName} expected one of ['left', 'right'].`,
          );
        }
      }
    }
  },

  distribution: function (props, propName, componentName) {
    if (props[propName]) {
      if (
        ![
          'center',
          'space-around',
          'space-between',
          'start',
          'end',
        ].includes(props[propName])
      ) {
        return new Error(
          `Invalid prop ${propName} supplied to ${componentName}. Expected one of [
              'center',
              'space-around',
              'space-between',
              'start',
              'end',
            ].`,
        );
      } else if (!props['icon']) {
        console.warn(
          `${propName} supplied to ${componentName}. ${propName} is meanly meant to manage the spacing between text and icon, but the icon prop is missing.`,
        );
      }
    }
  },
  gap: propTypeLength,
  gapMobile: propTypeLength,
};

export default ButtonPrimary;
