/** @jsxImportSource @emotion/react */
import moment from 'moment';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import { BLACK, GREEN_LIGHT, WHITE } from 'styles/colors';

const styles = css`
  display: contents;

  .duration-progress {
    bottom: 12px;
    position: absolute;
    left: 12px;
    width: calc(100% - 30px);
    height: 23px;
    appearance: none;

    &,
    &::-webkit-progress-inner-element,
    &::-webkit-progress-bar {
      background-color: ${WHITE};
      border: none;
      border-radius: 10px;
    }

    ::-moz-progress-bar {
      border-radius: 10px 0 0 10px;
      background-color: ${GREEN_LIGHT};
    }
    ::-webkit-progress-value {
      border-radius: 10px 0 0 10px;
      background-color: ${GREEN_LIGHT};
    }
  }

  .duration-label-text {
    bottom: 12px;
    position: absolute;
    height: 23px;
    border-radius: 12px;
    right: 35px;
    bottom: 8px;
  }
`;

function ProgressDuration({ end_date, start_date, className }) {
  const {
    totalDuration = 0,
    daysRemaining = 0,
    hoursRemaining = 0,
  } = useMemo(() => {
    const daysRemaining = Math.floor(
      moment.duration(moment(end_date).diff(moment())).asDays(),
    );
    const hoursRemaining =
      !daysRemaining &&
      Math.floor(
        moment.duration(moment(end_date).diff(moment())).asHours(),
      );
    return {
      totalDuration: Math.abs(
        Math.floor(
          daysRemaining
            ? moment
                .duration(moment(start_date).diff(moment(end_date)))
                .asDays()
            : moment
                .duration(moment(start_date).diff(moment(end_date)))
                .asHours(),
        ),
      ),
      daysRemaining: daysRemaining,
      hoursRemaining: hoursRemaining,
    };
  }, []);

  return (
    <label css={styles} className={className}>
      <progress
        className="duration-progress"
        max={totalDuration || 0}
        value={
          totalDuration &&
          totalDuration - Math.abs(daysRemaining || hoursRemaining)
        }
      ></progress>

      <Text
        type="inline"
        fontSize={14}
        color={BLACK}
        className="duration-label-text"
      >
        <b>
          {daysRemaining || hoursRemaining}{' '}
          {daysRemaining
            ? `${daysRemaining > 1 ? 'días' : 'día'}`
            : `${hoursRemaining > 1 ? 'horas' : 'hora'}`}
        </b>{' '}
        para finalizar
      </Text>
    </label>
  );
}

ProgressDuration.propTypes = {
  end_date: PropTypes.string,
  className: PropTypes.string,
  start_date: PropTypes.string,
};

export default ProgressDuration;
