/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Icon from 'components/Icon';
import * as COLORS from 'styles/colors';
import Button from 'components/buttons/Button';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as Instagram } from 'assets/icons/Instagram.svg';

const ButtonInstagramLogin = (props) => {
  const {
    disabled = false,
    hideDisabledStyles,
    showIcon = true,
    className,
    onClick,
    label,
    clientId = '531961008591092',
    // ! All redirectUris should be allowed first on meta developers console
    redirectUri = window.location.href,
    scope = 'user_profile,user_media',
    noRedirect,
    color = COLORS.INSTAGRAM,
    strokeVariant,
    textColor = hideDisabledStyles || !disabled
      ? COLORS.WHITE
      : COLORS.GRAY_LIGHTEST,
    ...rest
  } = props;

  function handleClick(event) {
    onClick?.(event);
    if (!noRedirect)
      window.location.href = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`;
  }

  const styles = css`
    padding: 14px 45px 14px 36px;
  `;

  return (
    <ButtonPrimary
      disabled={disabled}
      onClick={handleClick}
      css={styles}
      className={className}
      hideDisabledStyles={hideDisabledStyles}
      {...(showIcon && {
        icon: <Icon size={20} icon={Instagram} color={textColor} />,
      })}
      label={label}
      textColor={typeof label === 'string' ? textColor : null}
      color={color}
      strokeVariant={strokeVariant}
      {...rest}
    />
  );
};

ButtonInstagramLogin.propTypes = {
  ...Button.propTypes,
  ...ButtonPrimary.propTypes,
  clientId: PropTypes.string,
  redirectUri: PropTypes.string,
  scope: PropTypes.oneOf([
    'user_profile',
    'user_media',
    'user_profile,user_media',
    'user_media,user_profile',
  ]),
  noRedirect: PropTypes.bool,
  distribution: PropTypes.oneOf([
    'center',
    'space-around',
    'space-between',
    'start',
    'end',
  ]),
};

export default ButtonInstagramLogin;
