/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';

import APIClient from 'redux/api';
import { authSteps } from 'constants';
import { WHITE } from 'styles/colors';
import { setFeeker } from 'redux/app/feekApp';
import { MEDIA_QUERIES } from 'styles/constants';
import Modal from 'components/modals/Modal/index';
import FormLogin from 'components/FlowLogin/FormLogin';
import CodeVerification from 'components/CodeVerification';
import LoginMethod from 'components/FlowLogin/LoginMethod';
import ReferralCode from 'components/FlowRegistration/ReferralCode';
import FormRegistration from 'components/FlowRegistration/FormRegistration';

const styles = css`
  width: 500px;
  padding: 40px;
  max-width: 650px;
  background-color: ${WHITE};

  .title {
    margin-bottom: 40px;
  }

  ${MEDIA_QUERIES.mobile} {
    width: 100%;
    padding: 20px;

    .title {
      margin-bottom: 20px;
    }
  }
`;

export default function ModalAuth({ visible, onRequestClose }) {
  const [usePhone, setUsePhone] = useState(false);
  const [currentStep, setCurrentStep] = useState(
    authSteps.LOGIN_OPTIONS,
  );
  const [userCredentials, setUserCredentials] = useState({});
  const steps = [
    LoginMethod,
    FormLogin,
    ReferralCode,
    FormRegistration,
    CodeVerification,
  ];
  const Step = steps[currentStep];

  const dispatch = useDispatch();

  function handleClose() {
    setCurrentStep(0);
    setUserCredentials({});
    setUsePhone(false);
    onRequestClose?.();
  }

  function handleBack() {
    if (currentStep === 0) handleClose();
    else if (steps[currentStep] === ReferralCode) setCurrentStep(0);
    else setCurrentStep((crr) => crr - 1);
  }

  const handleVerifyUser = async (verificationCode) => {
    try {
      if (typeof userCredentials.phone !== 'string') {
        throw new Error('Hubo un error inesperado');
      }
      const { data } = await APIClient.createVerifiedFeeker({
        ...userCredentials,
        phone: `+${userCredentials.phone}`,
        code: verificationCode,
      });

      if (!('success' in data)) {
        APIClient.setAuthenticatedInstance(data.access_token);
        dispatch(setFeeker(data.user));
        location.reload();
      } else {
        throw new Error('El código no es válido');
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <Modal visible={visible} onRequestClose={handleClose}>
      <div css={styles}>
        {Step && (
          <Step
            isModal
            usePhone={usePhone}
            onLoginSuccess={() => {
              onRequestClose();
              window.location.reload();
            }}
            contactMethod="sms"
            onSend={handleVerifyUser}
            keyUser={userCredentials.phoneFormat}
            setUsePhone={setUsePhone}
            onBackButton={handleBack}
            setCurrentStep={setCurrentStep}
            userCredentials={userCredentials}
            setUserCredentials={setUserCredentials}
          ></Step>
        )}
      </div>
    </Modal>
  );
}

ModalAuth.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func,
};
