/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import APIClient from 'redux/api';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { authSteps } from 'constants';
import handleError from 'utils/handleError';
import { setFeeker } from 'redux/app/feekApp';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_FEEKAPP } from 'constants/routes';
import ButtonBack from 'components/buttons/ButtonBack';
import { WHITE, BLACK, PURPLE_FEEK } from 'styles/colors';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as Email } from 'assets/icons/Email.svg';
import ButtonInstagramLogin from 'components/buttons/ButtonInstagramLogin';
import { ReactComponent as Smartphone } from 'assets/icons/Smartphone.svg';
import ProgressIndicatorSpinner from 'components/ProgressIndicatorSpinner';

const styles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .title {
    margin-bottom: 24px;
  }

  .button {
    gap: 10px;

    .label {
      width: 180px;
      text-align: start;
    }
  }

  .registration {
    cursor: pointer;
    font-weight: 600;
    display: inline-block;
    color: ${PURPLE_FEEK};
    margin-block-end: 16px;
  }

  ${MEDIA_QUERIES.mobile} {
    .title {
      margin-bottom: 4px;
    }

    .registration {
      margin-block-end: 4px;
    }
  }
`;

const LoginMethod = ({
  isModal,
  setUsePhone,
  onBackButton,
  setCurrentStep,
  onLoginSuccess,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  useEffect(async () => {
    const code = searchParams.get('code');
    if (code) {
      setIsLoading(true);
      try {
        const { status, data } = await APIClient.loginWithInstagram(
          code,
        );
        if (status === 201) {
          dispatch(setFeeker(data));
          APIClient.setAuthenticatedInstance(data.access_token);
          setTimeout(() => {
            onLoginSuccess?.();
          }, 1000);
        } else {
          throw new Error('Hubo un error inesperado');
        }
      } catch (error) {
        setIsLoading(false);
        handleError(error);
      }
    }
  }, [searchParams]);

  return (
    <div css={styles}>
      <Text
        color={BLACK}
        fontSize={24}
        fontSizeMobile={16}
        align="center"
        fontWeight={600}
        className="title"
      >
        Iniciar sesión
      </Text>

      <ButtonPrimary
        pillShape
        className="button"
        color={BLACK}
        label={'Ingresar con teléfono'}
        textProps={{ fontSize: 16, fontWeight: 600 }}
        icon={<Icon icon={Smartphone} color={WHITE} />}
        onClick={() => {
          setUsePhone(true);
          setCurrentStep((crrStep) => crrStep + 1);
        }}
        heightMobile={59}
      />

      <ButtonPrimary
        pillShape
        color={BLACK}
        className="button"
        label={'Ingresar con email'}
        icon={<Icon icon={Email} color={WHITE} />}
        textProps={{ fontSize: 16, fontWeight: 600 }}
        onClick={() => {
          setUsePhone(false);
          setCurrentStep((crrStep) => crrStep + 1);
        }}
        heightMobile={59}
      />

      <ButtonInstagramLogin
        pillShape
        label="Ingresar con Instagram"
        className="button"
        disabled={isLoading}
        textProps={{ fontSize: 16, fontWeight: 600 }}
        height={48}
        heightMobile={59}
      />

      {isLoading && <ProgressIndicatorSpinner className="mx-auto" />}

      <Text
        color={BLACK}
        fontSize={14}
        align="center"
        fontWeight={500}
      >
        ¿Aún no tienes cuenta?{' '}
        {isModal ? (
          <Button
            className="registration"
            onClick={() => setCurrentStep(authSteps.ACCESS_CODE)}
          >
            Regístrate
          </Button>
        ) : (
          <Link
            to={ROUTES_FEEKAPP.REGISTRATION}
            className="registration"
          >
            Regístrate
          </Link>
        )}
      </Text>

      <ButtonBack onClick={onBackButton} />
    </div>
  );
};

LoginMethod.propTypes = {
  isModal: PropTypes.bool,
  setUsePhone: PropTypes.func.isRequired,
  onBackButton: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  onLoginSuccess: PropTypes.func.isRequired,
};

export default LoginMethod;
