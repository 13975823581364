/** @jsxImportSource @emotion/react */
import moment from 'moment';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import {
  campaignCategoriesEmojies,
  campaignParticipationMiniIconsByName,
} from 'constants';
import {
  GRAY,
  BLACK,
  WHITE,
  GRAY_MID,
  GREEN_LIGHT,
  PURPLE_FEEK,
  TRANSPARENT,
  PURPLE_LIGHT,
  GREEN_STATUS,
  GRAY_LIGHTER,
  PURPLE_MEDIUM,
  GRAY_LIGHTEST,
} from 'styles/colors';
import APIClient from 'redux/api';
import Icon from 'components/Icon';
import Text from 'components/Text';
import {
  getFluidSize,
  getColorWithAlpha,
  getFontSizeAndLineHeigh,
} from 'utils/cssStringManipulation';
import handleError from 'utils/handleError';
import { MEDIA_QUERIES } from 'styles/constants';
import { setCampaignTypes } from 'redux/entities';
import { ROUTES_FEEKAPP } from 'constants/routes';
import AppStore from 'assets/images/AppStore.svg';
import FooterLanding from 'components/FooterLanding';
import { Link, useNavigate } from 'react-router-dom';
import GooglePlay from 'assets/images/GooglePlay.svg';
import CardCampaign from 'components/cards/CardCampaign';
import LandingStep1 from 'assets/images/LandingStep1.png';
import LandingStep2 from 'assets/images/LandingStep2.png';
import LandingStep3 from 'assets/images/LandingStep3.png';
import LandingStep4 from 'assets/images/LandingStep4.png';
import FriendsEating from 'assets/images/FriendsEating.webp';
import { ReactComponent as Zap } from 'assets/icons/Zap.svg';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import FriendsEatingPNG from 'assets/images/FriendsEating.png';
import { ReactComponent as Arrow } from 'assets/icons/Arrow.svg';
import HashLinkArrowButton from 'components/HashLinkArrowButton';
import { useScrollHorizontally } from 'hooks/useScrollHorizontally';
import { ReactComponent as ZapMini } from 'assets/icons/ZapMini.svg';
import { ReactComponent as FeekLogo } from 'assets/icons/FeekLogo.svg';
import ModalSelectLocation from 'components/modals/ModalSelectLocation';
import { ReactComponent as Individual } from 'assets/icons/Individual.svg';
import ModalRequestLocation from 'screens/feekApp/Landing/ModalRequestLocation';
import InputLocationAutoComplete from 'components/inputs/InputLocationAutoComplete';
import GirlsTakingSelfieOverPinkBg from 'assets/images/GirlsTakingSelfieOverPinkBg.webp';
import GirlsTakingSelfieOverPinkBgPNG from 'assets/images/GirlsTakingSelfieOverPinkBg.png';

const styles = css`
  min-height: 100vh;

  .headerContainer {
    top: 0px;
    width: 100%;
    display: flex;
    padding: 20px;
    position: absolute;
    align-items: center;
    border-radius: 30px;
    backdrop-filter: blur(10px) opacity(0.7);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    justify-content: space-between;
    background-color: ${getColorWithAlpha()};
  }

  .feekLogo {
    margin-right: 4px;
  }

  .flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .buttonRounded {
    width: fit-content;
    height: 16px;
    padding: 20px 25px;
    border-radius: 30px;
    white-space: nowrap;
  }

  .borderGreenLight {
    border-width: 3px;

    :hover {
      opacity: 1;
      background-color: ${GREEN_LIGHT};

      * {
        color: ${BLACK};
        fill: ${BLACK};
      }
    }
  }
  .heroContainer {
    overflow-x: hidden;
    background-image: url(${GirlsTakingSelfieOverPinkBgPNG});
    background-image: url(${GirlsTakingSelfieOverPinkBg});
    background-size: cover;
    background-position: 70% 50%;
    background-repeat: no-repeat;
    padding-left: 20px;
    height: 100vh;
    min-height: 800px;
    display: flex;
    flex-direction: column;
  }
  .w-50 {
    width: 50%;
  }
  .search-section {
    display: flex;
    justify-content: end;
    flex-direction: column;
    padding-top: 170px;
    height: 100vh;
    min-height: 800px;
  }
  .hero-title {
    font-size: 32px;
    line-height: 38px;
    max-width: 800px;
  }
  .searchContainer {
    margin-top: 3em;
    margin-bottom: 5em;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
  }

  .location-search-container {
    flex: 1;
    position: relative;
    margin-bottom: 15px;
  }

  .placesContainer {
    margin-top: 0.5em;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  }
  .buttonSearch {
    width: 100%;
    padding: 30px 40px;
    align-self: flex-start;
  }

  .scrollDownButton {
    margin-bottom: 4em;
    align-self: start;
  }
  .campaignsFeed {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    min-height: 800px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    margin-right: -100px;
    margin-left: 100px;
    align-self: flex-start;
  }

  .campaignsFeed::-webkit-scrollbar {
    display: none;
  }

  .step-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid ${GRAY_MID};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .campaignSmallCard:nth-child(3n + 1) {
    align-self: center;
    background-color: ${GREEN_STATUS};
  }
  .campaignSmallCard:nth-child(3n + 2) {
    align-self: flex-end;
    background-color: ${PURPLE_MEDIUM};
  }
  .campaignSmallCard:nth-child(3n + 3) {
    align-self: flex-start;
    background-color: ${PURPLE_LIGHT};
  }

  .campaignSmallCard:nth-child(4n + 1) {
    background-color: ${GREEN_STATUS};
  }
  .campaignSmallCard:nth-child(4n + 2) {
    background-color: ${PURPLE_MEDIUM};
  }
  .campaignSmallCard:nth-child(4n + 3) {
    background-color: ${PURPLE_LIGHT};
  }

  .campaignSmallCard:nth-child(4n + 4) {
    background-color: ${GREEN_LIGHT};
  }

  .campaignSmallCard {
    border-radius: 20px;
    padding: 20px;
    width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 100px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  }
  .campaignSmallCardImage {
    width: 110px;
    height: 120px;
    border-radius: 10px;
    margin-right: 10px;
  }

  .countryBadge {
    border-radius: 30px;
    border: 1px solid ${BLACK};
    text-transform: uppercase;
    width: fit-content;
    padding: 6px 9px;
  }
  .upperCase {
    text-transform: uppercase;
  }
  .campaignSmallCardInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .section {
    padding: 60px 0px 60px 20px;
  }

  .section-title {
    font-size: 42px;
  }

  .ps-60 {
    padding-left: 60px;
  }

  .mx-150 {
    margin-left: 150px;
    margin-right: 150px;
  }
  .title {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    gap: 1em;
  }
  .about {
    padding-left: 0;
    padding-bottom: 0;
    border-radius: 30px;
    padding: 60px 0px 60px 0px;
    border-top: 2px solid ${GRAY_LIGHTER};
  }

  .about .title {
    padding-left: 60px;
  }

  .about-info {
    max-width: 850px;
  }

  .about-info-title {
    margin-bottom: 60px;
  }

  .about-info-text {
    margin-bottom: 60px;
  }

  .mb60 {
    margin-bottom: 60px;
  }
  .aboutButton {
    padding: 33px 40px;
  }

  .stepsContainer {
    cursor: move;
    overflow-x: scroll;
  }

  .stepsContainer::-webkit-scrollbar {
    display: none;
  }

  .stepsContainer > .stepCard {
    margin-left: 20px;
  }
  .stepCard {
    border-radius: 20px;
    border: 3px solid ${PURPLE_LIGHT};
    padding: 60px;
    margin-right: 70px;
    min-width: 500px;
  }

  .step-title,
  .step-description {
    margin-bottom: 30px;
  }

  .mb30 {
    margin-bottom: 30px;
  }
  .alignCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ciudades {
    background-color: ${PURPLE_FEEK};
  }

  .ciudades-content {
    overflow: hidden;
    padding-top: 150px;
  }

  .ciudad-card {
    width: 593px;
    padding: 40px;
    border-radius: 20px;
    border: 2px solid ${WHITE};
    margin-left: auto;
    margin-right: auto;
    background-color: ${PURPLE_FEEK};
    transition: all 0.3s ease-in-out;
  }

  .ciudad-card.active {
    background-color: ${GREEN_LIGHT};
    border: 2px solid ${GREEN_LIGHT};
  }

  .w-100 {
    width: 100%;
  }

  .badge-category {
    padding: 6px 12px;
    margin-right: 8px;
    width: fit-content;
    border-radius: 30px;
    border: 1px solid ${WHITE};
  }

  .badge-white {
    z-index: 10;
    transition: all 0.0025s ease-in-out;
    background-color: ${PURPLE_FEEK};
    cursor: pointer;
    border: 2px solid ${WHITE};
    width: fit-content;
    margin-top: -20px;
    margin-bottom: 200px;
    margin-right: auto;
    margin-left: auto;
    color: ${BLACK};
    border-radius: 40px;
    padding: 1.2em 1.7em;
  }

  .badge-white.swiping {
    border: 2px solid ${PURPLE_FEEK};
  }

  .ciudad-card.active .badge-category {
    background-color: ${GREEN_STATUS};
    border: 1px solid ${GREEN_STATUS};
  }

  .ciudad-card .categories {
    margin-top: 40px;
    margin-bottom: 115px;
  }

  .ciudad-card .feekspots {
    margin-top: 115px;
  }

  .feekspot-image {
    width: 60px !important;
    height: 60px !important;
    border-radius: 50%;
    border: 2px solid ${WHITE};
    margin-left: -28px;
  }

  .feekspot-image:first-child {
    margin-left: 0px;
  }

  .feekspots-counter-circle {
    min-width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid ${WHITE};
    margin-left: -28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ciudad-card.active .feekspots-counter-circle {
    background-color: ${WHITE};
    border: 2px solid ${GRAY_LIGHTEST};
  }

  .bottom-radius-white {
    height: 60px;
    background-color: ${WHITE};
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .top-radius-white {
    height: 60px;
    background-color: ${WHITE};
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }

  .show-more-campaigns-link {
    margin-left: 70px;
    margin-bottom: 40px;
    cursor: pointer;
  }

  .campaigns-feed {
    gap: 24px;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    margin-top: 0px;
    margin-bottom: 200px;
  }

  .campaign-card:first-of-type {
    margin-inline-start: 70px;
  }
  .campaign-card:last-of-type {
    margin-inline-end: 70px;
  }

  .campaigns-feed::-webkit-scrollbar {
    display: none;
  }
  .h1 {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  .cities-badges {
    gap: ${getFluidSize(10, 20)};
  }
  .badge-city {
    border-radius: 30px;
    width: fit-content;
    padding: 10px 20px;
    margin-bottom: 40px;
    background-color: ${GREEN_LIGHT};
  }

  .mb80 {
    margin-bottom: 80px;
  }

  .color-purple-feek {
    font-weight: 700;
    color: ${PURPLE_FEEK};
  }

  .store-logo {
    margin-right: 20px;
    border-radius: 15px;
    border: 1px solid ${WHITE};
  }

  .badge-category-carousel {
    display: flex;
    overflow: hidden;
    user-select: none;
    margin-bottom: 40px;
  }

  .badge-category-carousel-container {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    min-width: 100%;
    animation: scroll 40s linear infinite;
  }

  .badge-category-carousel:first-child {
    margin-top: 40px;
  }
  .badge-category-carousel-container.reverse {
    animation: scrollReverse 52s linear infinite;
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 40px));
    }
  }

  @keyframes scrollReverse {
    from {
      transform: translateX(calc(-100%));
    }
    to {
      transform: translateX(0 - 40px);
    }
  }

  .badge-category-lg {
    margin-left: 40px;
    padding: 20px 50px;
    width: fit-content;
    border-radius: 85px;
    background-color: ${PURPLE_LIGHT};
  }

  .px-40 {
    padding-left: 3em;
    padding-right: 3em;
  }
  .px-70 {
    padding-left: 70px;
    padding-right: 70px;
  }

  .experiences {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-inline: auto;
  }
  .experience-card {
    border: 2px solid ${PURPLE_LIGHT};
    border-radius: 30px;
    max-width: 480px;
    padding: 20px;
    margin-bottom: 50px;
  }
  .experience-card-img {
    max-width: 100%;
    border-radius: 30px;
  }
  .place-badge {
    border: 2px solid ${GRAY_MID};
    width: fit-content;
    border-radius: 16px;
    padding: 5px 9px;
    margin-block: 10px 20px;
  }
  .mr4 {
    margin-right: 4px;
  }
  .capitalize::first-letter {
    text-transform: upperCase;
  }
  .border-radius-25 {
    border-radius: 25px;
  }

  .want-feek {
    display: flex;
    cursor: pointer;
    align-items: center;
    border-radius: 50px;
    margin-bottom: 150px;
    margin-right: 3.25em;
    padding: 4px 4px 4px 40px;
    border: 2px solid ${WHITE};
    justify-content: space-between;
  }

  .want-feek * {
    font-size: 16px;
  }

  .want-feek {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .white-circle {
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${WHITE};
  }

  .bg-black {
    background-color: ${BLACK};
  }

  .about-image {
    width: 520px;
    height: 638px;
    object-fit: cover;
    margin-bottom: 40px;
    border-radius: 46px;
    object-position: -350px;
  }

  .ml12 {
    margin-left: 12px;
  }

  @media (min-width: 576px) {
    .want-feek * {
      font-size: 32px;
    }
    .want-feek {
      max-width: 550px;
    }

    .white-circle {
      min-width: 90px;
      min-height: 90px;
    }

    .section {
      padding: 60px 0px 60px 70px;
    }

    .h1 {
      font-size: 54px;
      margin-bottom: 80px;
      line-height: 54px;
    }

    .h2 {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 80px;
    }

    .hero-title {
      font-size: 50px;
      line-height: 58px;
    }
  }
  ${MEDIA_QUERIES.bigScreen} {
    .stepsContainer > .stepCard {
      margin-left: 100px;
    }

    .section-title {
      font-size: 80px;
    }

    .h1 {
      font-size: 80px;
      margin-bottom: 80px;
      line-height: 80px;
    }

    .h2 {
      font-size: 48px;
      line-height: 48px;
    }

    .badge-category-carousel:first-child {
      margin-top: 140px;
    }

    .headerContainer {
      display: flex;
      position: absolute;
      top: 40px;
      width: calc(100% - 220px);
      align-items: center;
      border-radius: 30px;
      padding: 30px 40px;
      margin-left: 110px;
      justify-content: space-between;

      background-color: ${getColorWithAlpha(PURPLE_FEEK, 70)};
      backdrop-filter: blur(10px) opacity(0.7);
    }
    .searchContainer {
      flex-direction: row;
    }

    .buttonSearch {
      width: fit-content;
    }

    .heroContainer {
      padding-left: 70px;
      background-position: center;
    }

    .hero-title {
      font-size: 60px;
      line-height: 68px;
    }
    .feekLogo {
      margin-right: 60px;
    }
  }

  ${MEDIA_QUERIES.tablet} {
    .ciudad-card {
      width: calc(100% - 40px);
    }

    .location-description {
      ${getFontSizeAndLineHeigh(32)}
    }

    .ciudad-card .categories {
      margin-top: 20px;
      margin-bottom: 55px;
    }

    .feekspots-counter-circle {
      margin-left: -45px;
      background-color: ${WHITE};
    }
  }
  ${MEDIA_QUERIES.mobile} {
    .about-info-title {
      ${getFontSizeAndLineHeigh(40)}
      margin-bottom: 40px;
    }

    .about-info-text {
      ${getFontSizeAndLineHeigh(16)}
      margin-bottom: 40px;
    }

    .about .title {
      padding-left: 20px;
    }
    .stepCard {
      padding: 30px;
      min-width: 335px;
      margin-right: 30px;
    }
    .step-title {
      ${getFontSizeAndLineHeigh(24)}
      margin-bottom: 30px;
    }
    .step-description {
      ${getFontSizeAndLineHeigh(14)}
      margin-bottom: 30px;
    }
    .ciudades-content {
      padding-top: 100px;
    }

    .badge-white {
      margin-bottom: 150px;
    }

    .badge-city * {
      ${getFontSizeAndLineHeigh(14)}
    }

    .location-description {
      ${getFontSizeAndLineHeigh(24)}
    }

    .campaign-card:nth-child(1) {
      margin-left: 20px;
    }
    .campaign-card-img {
      width: 330px;
    }

    .campaigns-feed {
      margin-bottom: 100px;
    }

    .ciudad-card {
      width: calc(100% - 40px);
    }
  }
`;

const steps = [
  {
    step: '01',
    title: 'Descubre y agenda',
    description: (
      <>
        Encuentra los <b>Feekspots</b> favoritos en tu ciudad, agenda
        en la app y asiste con <u>beneficios exclusivos</u>.
      </>
    ),
    image: LandingStep1,
  },
  {
    step: '02',
    title: 'Sube contenido',
    description: (
      <>
        Asiste al <b>Feekspot</b> agendado, disfruta y{' '}
        <u>sube una historia a Instagram</u> etiquetando al Feekspot.
      </>
    ),
    image: LandingStep2,
  },
  {
    step: '03',
    title: 'Valida y disfruta',
    description: (
      <>
        Busca la promoción en tu agenda, selecciona la historia
        correspondiente, <u>valida y muestra tu cupón</u>.
      </>
    ),
    image: LandingStep3,
  },
  {
    step: '04',
    title: 'Gana Feekpoints',
    description: (
      <>
        Completa tomando una foto de tu ticket y recibe{' '}
        <b>Feekpoints</b> que podrás canjear próximamente.
      </>
    ),
    image: LandingStep4,
  },
];

function Landing() {
  const dispatch = useDispatch();
  const [locations, setLocations] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [locationSwiping, setLocationSwiping] = useState(false);
  const [campaignsFeedHeader, setCampaignsFeedHeader] = useState([]);
  const [campaignsFeedPromotions, setCampaignsFeedPromotions] =
    useState([]);
  const [showRequestLocationModal, setShowRequestLocationModal] =
    useState(false);
  const [showSelectLocationModal, setShowSelectLocationModal] =
    useState(false);
  const [carrouselSelectedLocation, setCarrouselSelectedLocation] =
    useState(0);
  const [categoriesMarqueeOne, setCategoriesMarqueeOne] = useState(
    [],
  );
  const [categoriesMarqueeTwo, setCategoriesMarqueeTwo] = useState(
    [],
  );
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const [locationSelected, setLocationSelected] = useState(null);
  const [modalLocaltionCurrentStep, setModalLocaltionCurrentStep] =
    useState(0);

  const navigate = useNavigate();

  const campaignsFeedRef = useScrollHorizontally();
  const stepsContainerRef = useScrollHorizontally();

  document.title =
    'Feek | Colabora con marcas, disfruta beneficios y gana recompensas.';

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      const prev = carrouselSelectedLocation;
      setCarrouselSelectedLocation(null);
      setCarrouselSelectedLocation(prev);
    };

    window.addEventListener('resize', handleWindowResize);

    APIClient.getLocationFeekspots()
      .then(({ data }) => {
        setLocations(data);
        setCarrouselSelectedLocation(0);
      })
      .catch(handleError);

    APIClient.getCategoriesLanding()
      .then(({ data }) => {
        //first half
        setCategoriesMarqueeOne(data.slice(0, data?.length / 2));
        //second half
        setCategoriesMarqueeTwo(data.slice(data?.length / 2));
      })
      .catch(handleError);

    APIClient.getExperiencesLanding()
      .then(({ data }) => {
        setExperiences(data.docs.slice(0, 3));
      })
      .catch(handleError);

    APIClient.getCampaignsLandingHeader().then(({ data }) => {
      setCampaignsFeedHeader(data.docs);
    });

    APIClient.getCampaignsLandingPromotions(1, 10).then(
      ({ data }) => {
        setCampaignsFeedPromotions(data.docs);
      },
    );

    APIClient.getCampaignTypes()
      .then(({ data }) => {
        dispatch(setCampaignTypes(data));
      })
      .catch(handleError);

    setTimeout(() => {
      setCarrouselSelectedLocation(1);
    }, 500);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const goToExplore = () => {
    const lastSelectedLocation =
      localStorage.getItem('last_location');
    if (lastSelectedLocation) {
      navigate(ROUTES_FEEKAPP.EXPLORE);
      window.scrollTo({ top: 0, left: 0 });
    } else {
      setShowSelectLocationModal(true);
    }
  };

  return (
    <div css={styles}>
      <ModalSelectLocation
        location={locationSelected}
        step={modalLocaltionCurrentStep}
        visible={showSelectLocationModal}
        onLocationChanged={() => {
          navigate(ROUTES_FEEKAPP.EXPLORE);
          window.scrollTo(0);
        }}
        onRequestClose={() => setShowSelectLocationModal(false)}
      />
      <ModalRequestLocation
        show={showRequestLocationModal}
        handleClose={() => setShowRequestLocationModal(false)}
      />
      <div className="headerContainer">
        <div className="d-flex align-items-center">
          <Icon
            icon={FeekLogo}
            color={WHITE}
            size={50}
            className="feekLogo"
          />
          <Text
            color={WHITE}
            fontSize={20}
            className="d-none d-md-block"
          >
            Live it, share it, <b>Feek</b> it!
          </Text>
        </div>
        <div className="d-flex">
          {localStorage.getItem('currentFeekToken') ? (
            <Link to={ROUTES_FEEKAPP.PROFILE_FEEKER_EDIT}>
              <ButtonPrimary
                strokeVariant
                strokeColor={GREEN_LIGHT}
                color={TRANSPARENT}
                textColor={GREEN_LIGHT}
                pillShape
                label="Gestionar usuario"
                className="borderGreenLight"
                fontSize={20}
                icon={<Icon icon={Individual} color={GREEN_LIGHT} />}
                layoutOnly
              />
            </Link>
          ) : (
            <>
              <Link to={ROUTES_FEEKAPP.LOGIN}>
                <ButtonPrimary
                  strokeVariant
                  strokeColor={GREEN_LIGHT}
                  color={TRANSPARENT}
                  textColor={GREEN_LIGHT}
                  pillShape
                  label="Iniciar sesión"
                  className="borderGreenLight me-2 me-md-3"
                  fontSize={20}
                  icon={
                    <Icon icon={Individual} color={GREEN_LIGHT} />
                  }
                  layoutOnly
                />
              </Link>
              <Link
                to={ROUTES_FEEKAPP.REGISTRATION}
                className="text-decoration-none"
              >
                <ButtonPrimary
                  strokeVariant
                  textColor={BLACK}
                  color={GREEN_LIGHT}
                  pillShape
                  label="Registrarse"
                  fontSize={20}
                />
              </Link>
            </>
          )}
        </div>
      </div>
      <div
        className="heroContainer"
        title="Feek | Colabora con marcas, disfruta beneficios y gana recompensas."
      >
        <div className="row">
          <div className="col-12 col-lg-5 col-xl-6 search-section">
            <Text
              color={WHITE}
              fontSize={60}
              className="hero-title me-4"
            >
              Colabora con marcas, disfruta <b>beneficios</b> y{' '}
              <b>gana</b> recompensas.
            </Text>
            <div className="searchContainer">
              <div className="location-search-container me-0 me-md-3">
                <InputLocationAutoComplete
                  limit={3}
                  hideLoadingIndicator
                  placesContainerClassName="placesContainer"
                  placeholder="Ingresa la dirección para buscar promociones"
                  onPlaceSelected={(place) => {
                    APIClient.feekLocationAvailability({
                      lat: place.geometry.location.lat(),
                      lng: place.geometry.location.lng(),
                    }).then((res) => {
                      setLocationSelected(place);
                      setModalLocaltionCurrentStep(
                        res.data.valid ? 1 : 2,
                      );
                      setShowSelectLocationModal(true);
                    });
                  }}
                />
              </div>

              <ButtonPrimary
                label="Buscar"
                color={BLACK}
                textColor={WHITE}
                className="buttonRounded buttonSearch"
              />
            </div>
            <HashLinkArrowButton
              className="scrollDownButton d-none d-md-flex"
              width={300}
              label="Scroll down"
              to="#what-is-feek"
            />
          </div>
          <div className="col-lg-7 col-xl-6 campaignsFeed d-none d-lg-flex">
            {campaignsFeedHeader.map((campaign, index) => (
              <CampaignSmallCard
                style={{
                  ...(index === 0 ? { marginTop: 60 } : {}),
                }}
                key={campaign._id + index || index}
                color={campaign.color}
                type={campaign.type}
                image={campaign.image}
                location={campaign?.customer?.city}
                feekspot={campaign?.customer?.name}
                feekpoints={campaign?.task.feek_points}
              />
            ))}
          </div>
        </div>
      </div>
      <div id="what-is-feek" className="section">
        <div className="title">
          <div className="step-circle">
            <Text fontSize={32} color={BLACK}>
              1
            </Text>
          </div>
          <Text color={BLACK} fontSize={30}>
            ¿Qué es <b>Feek</b>?
          </Text>
        </div>
        <div className="row w-100">
          <div className="col-xl-6 col-lg-7 d-flex justify-content-center align-items-center">
            <picture>
              <source srcSet={FriendsEating} />
              <img
                src={FriendsEatingPNG}
                alt="¿Qué es Feek?"
                className="about-image px-0 px-md-4 img-fluid"
              />
            </picture>
          </div>
          <div className="col-xl-6 col-lg-5">
            <div className="about-info">
              <Text
                color={BLACK}
                fontSize={60}
                fontWeight={600}
                className="about-info-title"
              >
                Descubre, disfruta y gana
              </Text>
              <Text
                color={BLACK}
                fontSize={32}
                className="about-info-text"
              >
                <u>Colabora con marcas</u> disfrutando{' '}
                <b>beneficios increíbles</b>, descubre que hacer en tu
                ciudad ahorrando y <b>ganando recompensas</b> a cambio
                de contenido orgánico.
              </Text>
              <ButtonPrimary
                strokeVariant
                className="buttonRounded aboutButton"
                color={BLACK}
                textProps={{ fontSize: 20, fontWeight: 500 }}
                textColor={WHITE}
                onClick={() => goToExplore()}
                label="Explorar promociones"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="about">
        <div className="title">
          <div className="step-circle">
            <Text fontSize={32} color={BLACK}>
              2
            </Text>
          </div>
          <Text color={BLACK} fontSize={30} className="">
            ¿Cómo funciona?
          </Text>
        </div>

        <div
          className="flexRow  stepsContainer"
          ref={stepsContainerRef}
        >
          {steps.map((step, index) => (
            <div
              className="stepCard"
              key={step.step + index || index}
            >
              <Text
                color={PURPLE_FEEK}
                fontSize={20}
                fontWeight={600}
                className="mb30"
              >
                PASO {step.step}
              </Text>
              <Text
                color={BLACK}
                fontSize={48}
                fontWeight={600}
                className="step-title"
              >
                {step.title}
              </Text>
              <Text
                color={BLACK}
                fontSize={20}
                className="step-description"
              >
                {step.description}
              </Text>
              <div className="alignCenter">
                <img src={step.image} alt={'Feek ' + step.title} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="ciudades">
        <div className="bottom-radius-white"></div>
        <div className="ciudades-content">
          <Text
            align="center"
            fontSize={80}
            color={WHITE}
            className="mb80 section-title"
          >
            Ciudades
          </Text>

          <div className="ciudades-carrousel flexRow">
            <Carousel
              swipeable
              centerMode
              showStatus={false}
              autoFocus
              infiniteLoop
              showIndicators={false}
              showThumbs={false}
              selectedItem={carrouselSelectedLocation}
              className="w-100"
              centerSlidePercentage={
                windowSize[0] < 1400
                  ? windowSize[0] < 1000
                    ? windowSize[0] < 500
                      ? 100
                      : 80
                    : 65
                  : 50
              }
              swipeScrollTolerance={5}
              transitionTime={500}
              onChange={(index) => {
                setCarrouselSelectedLocation(index);
                setLocationSwiping(true);
                setTimeout(() => {
                  setLocationSwiping(false);
                }, 500);
              }}
            >
              {locations.map((location, index) => (
                <div
                  className={`ciudad-card  ${
                    carrouselSelectedLocation === index && 'active'
                  }`}
                  style={{
                    marginTop:
                      carrouselSelectedLocation !== index
                        ? '60px'
                        : '0px',
                  }}
                  key={location.location._id + index || index}
                  onClick={() => setCarrouselSelectedLocation(index)}
                >
                  <Text
                    fontSize={30}
                    align="center"
                    color={
                      carrouselSelectedLocation === index
                        ? BLACK
                        : WHITE
                    }
                  >
                    {location.location.city}
                  </Text>

                  <div className="flexRow alignCenter categories">
                    <div className="badge-category">
                      <Text color={WHITE} fontSize={16}>
                        Restaurant
                      </Text>
                    </div>
                    <div className="badge-category">
                      <Text color={WHITE} fontSize={16}>
                        Bar
                      </Text>
                    </div>
                    <div className="badge-category">
                      <Text color={WHITE} fontSize={16}>
                        Coffe
                      </Text>
                    </div>
                    <div className="badge-category d-none d-md-block">
                      <Text color={WHITE} fontSize={16}>
                        Y más...
                      </Text>
                    </div>
                  </div>

                  <Text
                    fontSize={48}
                    align="center"
                    className="location-description"
                    color={
                      carrouselSelectedLocation === index
                        ? BLACK
                        : WHITE
                    }
                  >
                    {location.location.description}
                  </Text>
                  <div className="justify-content-between flexRow feekspots">
                    <div className="flexRow">
                      {location.customers.map((feekspot, index) => (
                        <img
                          key={feekspot._id + index || index}
                          src={feekspot.image}
                          alt={feekspot.name}
                          className="feekspot-image"
                        />
                      ))}
                    </div>
                    <div className="feekspots-counter-circle">
                      <Text
                        fontSize={16}
                        fontWeight={600}
                        color={
                          carrouselSelectedLocation === index
                            ? BLACK
                            : WHITE
                        }
                      >
                        +
                        {location?.total - location.customers?.length}
                      </Text>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
          <div
            className={`badge-white ${locationSwiping && 'swiping'}`}
          >
            <Text
              color={locationSwiping ? PURPLE_FEEK : WHITE}
              fontSize={16}
              fontWeight={500}
              onClick={() => setShowSelectLocationModal(true)}
            >
              Explorar{' '}
              {locations[carrouselSelectedLocation]?.location?.city}
            </Text>
          </div>
          <div className="row w-100 px-3">
            <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
              <div
                className="want-feek "
                onClick={() => setShowRequestLocationModal(true)}
              >
                <Text fontSize={32} color={WHITE} fontWeight={500}>
                  Quiero <b>Feek</b> en mi ciudad
                </Text>
                <div className="white-circle">
                  <Icon icon={Arrow} color={BLACK} size={32} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-radius-white"></div>
      </div>
      <div className="px-0 pt-5 pb-md-5 pb-4">
        <Text
          align="center"
          fontSize={80}
          color={BLACK}
          className="section-title"
        >
          Qué hacer en
        </Text>
      </div>

      <div className="d-flex justify-content-center align-items-center cities-badges">
        {locations.map((location, index) => (
          <div
            className="badge-city"
            key={location.location._id + index || index}
          >
            <Text fontSize={24} color={BLACK}>
              {location.location.city}
            </Text>
          </div>
        ))}
      </div>

      <Text
        className="show-more-campaigns-link  d-none d-md-block"
        color={PURPLE_FEEK}
        fontWeight={600}
        fontSize={24}
      >
        <u>Ver más</u>
      </Text>

      <div className="campaigns-feed" ref={campaignsFeedRef}>
        {campaignsFeedPromotions.map((campaign, index) => (
          <CardCampaign
            className="campaign-card"
            campaignId={campaign._id}
            key={campaign._id + index || index}
            color={campaign.color}
            image={campaign.image}
            places={campaign.places}
            title={campaign.title}
            type={campaign.type._id}
            feekspot={campaign?.customer?.name}
            feekpoints={campaign?.task.feek_points}
          />
        ))}
      </div>
      <Text color={BLACK} fontSize={80} align="center" className="h1">
        Live it, share it,{' '}
        <span className="color-purple-feek">Feek</span> it!
      </Text>
      <div className="row w-100">
        <div className="col-md-10 col-lg-8 offset-md-1 offset-lg-2 px-4">
          <Text color={BLACK} align="center" className="h2">
            Colaborar y gozar de increíbles beneficios nunca había
            sido tan accesible y fácil. ¡Descárgala ya!
          </Text>
        </div>
      </div>

      <div className="flexRow alignCenter mx-3">
        <a
          href="https://qrco.de/be4ftN"
          target="_blank"
          rel="noreferrer"
          className="mx-2"
        >
          <img
            src={AppStore}
            alt="Feek App Store"
            className="store-logo border-radius-25 img-fluid"
            width={300}
            height={90}
          />
        </a>
        <a
          href="https://qrco.de/be57iC"
          target="_blank"
          className="mx-2"
          rel="noreferrer"
        >
          <img
            src={GooglePlay}
            alt="Feek Google play"
            className="store-logo border-radius-25 img-fluid"
            width={300}
            height={90}
          />
        </a>
      </div>

      <div className="section px-0">
        <div className="badge-category-carousel">
          <div className="badge-category-carousel-container">
            {categoriesMarqueeOne.map((category, index) => (
              <div
                className="badge-category-lg"
                key={category._id + index || index}
              >
                <Text color={BLACK} fontSize={64} lineHeight={89}>
                  {campaignCategoriesEmojies[category.key] || '✨'}{' '}
                  {category.name}
                </Text>
              </div>
            ))}
          </div>
          <div
            className="badge-category-carousel-container"
            aria-hidden="true"
          >
            {categoriesMarqueeOne.map((category, index) => (
              <div
                className="badge-category-lg"
                key={category._id + index || index}
              >
                <Text color={BLACK} fontSize={64} lineHeight={89}>
                  {campaignCategoriesEmojies[category.key] || '✨'}{' '}
                  {category.name}
                </Text>
              </div>
            ))}
          </div>
        </div>
        <div className="badge-category-carousel">
          <div className="badge-category-carousel-container reverse">
            {categoriesMarqueeTwo.map((category, index) => (
              <div
                className="badge-category-lg"
                key={category._id + index || index}
              >
                <Text color={BLACK} fontSize={64} lineHeight={89}>
                  {campaignCategoriesEmojies[category.key] || '✨'}{' '}
                  {category.name}
                </Text>
              </div>
            ))}
          </div>
          <div
            className="badge-category-carousel-container reverse"
            aria-hidden="true"
          >
            {categoriesMarqueeTwo.map((category, index) => (
              <div
                className="badge-category-lg"
                key={category._id + index || index}
              >
                <Text color={BLACK} fontSize={64} lineHeight={89}>
                  {campaignCategoriesEmojies[category.key] || '✨'}{' '}
                  {category.name}
                </Text>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Text
        color={BLACK}
        fontSize={80}
        align="center"
        className="h1 mb-5 pb-md-4"
      >
        Recompensas
      </Text>
      <div className="row w-100">
        <div className="col-10 col-md-8 offset-1 offset-md-2">
          <Text
            color={BLACK}
            fontSize={48}
            align="center"
            className="h2"
          >
            Con <b>Feek</b> podrás acceder a un sin fin de{' '}
            <u>promociones increíbles</u> y al mismo tiempo{' '}
            <u>ganar recompensas</u>.
          </Text>
        </div>
      </div>

      <div className="section experiences mb80">
        {experiences.map((experience, index) => {
          return (
            <div
              className="experience-card col-12 col-md-12 col-xl-4"
              key={experience._id + index || index}
            >
              <img
                src={experience.image}
                alt={experience.title}
                className="experience-card-img"
              />
              <Text
                color={GRAY}
                fontSize={16}
                fontWeight={600}
                className="place-badge"
              >
                {`${experience.places} lugar${
                  experience.places !== 1 ? 'es' : ''
                }`}
              </Text>

              <Text color={BLACK} fontSize={18} fontWeight={600}>
                {experience.title}
              </Text>

              <div className="flexRow mt-3">
                <Icon
                  size={16}
                  className="mr4"
                  icon={
                    campaignParticipationMiniIconsByName[
                      experience.participation
                    ]
                  }
                  color={GRAY}
                />
                <Text
                  color={GRAY}
                  fontSize={14}
                  fontWeight={600}
                  className="me-1"
                >
                  {experience.participation}
                </Text>
                <Icon
                  size={16}
                  icon={ZapMini}
                  color={GRAY}
                  className="mr4 ml12"
                />
                <Text
                  color={GRAY}
                  fontSize={14}
                  fontWeight={600}
                  className="capitalize"
                >
                  {moment
                    .utc(experience.end_date)
                    .format('dddd D [de] MMMM, YYYY')}
                </Text>
              </div>
            </div>
          );
        })}
      </div>
      <FooterLanding />
    </div>
  );
}
const CampaignSmallCard = (props) => {
  const { image, location, feekspot, type, feekpoints, style } =
    props;

  return (
    <div className="campaignSmallCard" style={style}>
      {image && (
        <img
          src={image}
          alt={'Feek ' + feekspot}
          className="campaignSmallCardImage"
        />
      )}
      <div className="campaignSmallCardInfo">
        <Text color={BLACK} fontWeight={700} className="countryBadge">
          {location}
        </Text>
        <Text
          color={BLACK}
          fontSize={24}
          fontWeight={700}
          className="upperCase"
        >
          {feekspot}
        </Text>
        <div className="flexRow">
          <div className="flexRow">
            <Icon icon={Zap} color={BLACK} />
            <Text color={BLACK} className="me-2">
              {type?.name}
            </Text>
          </div>
          <div className="flexRow">
            <Icon icon={Zap} color={BLACK} />
            <Text color={BLACK}>+{feekpoints}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};
CampaignSmallCard.propTypes = {
  image: PropTypes.string,
  type: PropTypes.object,
  location: PropTypes.string,
  feekspot: PropTypes.string,
  feekpoints: PropTypes.number,
  style: PropTypes.object,
};

CampaignSmallCard.defaultProps = {
  style: {},
};

export default Landing;
