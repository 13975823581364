/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import Text from 'components/Text';
import { ROUTES_MANAGER } from 'constants/routes';
import { useProfileCompletedStatus } from 'hooks/useProfileCompletedStatus';
import FeekerFlyingLinearChart from 'assets/images/FeekerFlyingLinearChart.png';
import ContentCompleteProfile from 'screens/manager/legacy/Home/ContentCompleteProfile';

const styles = css`
  display: flex;
  width: 100%;
  padding: 1rem;

  .placeholderData {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px;

    img {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

function Home() {
  const navigate = useNavigate();
  const { isProfileCompleted, completionRequirements } =
    useProfileCompletedStatus();

  useEffect(() => {
    if (isProfileCompleted) navigate(ROUTES_MANAGER.DASHBOARD);
  }, [isProfileCompleted]);

  return (
    <div css={styles}>
      <div className="placeholderData">
        <img
          src={FeekerFlyingLinearChart}
          alt="Feeker flying linear chart image"
        />
        <Text align="center" fontSize={14}>
          Aquí podrás ver tu actividad reciente una vez que hayas
          completado tu perfil.
        </Text>
      </div>

      <ContentCompleteProfile
        completionRequirements={completionRequirements}
      />
    </div>
  );
}

export default Home;
