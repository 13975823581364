/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Icon from 'components/Icon';
import Text from 'components/Text';
import { GRAY_TEXT } from 'styles/colors';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as ArrowMini } from 'assets/icons/ArrowMini.svg';

const styles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1.5px solid ${GRAY_TEXT};
  }
`;

function PaginationButtons({
  className = '',
  currentPage = 1,
  onBackPage,
  onNextPage,
  backPageDisabled,
  nextPageDisabled,
}) {
  return (
    <div className={className} css={styles}>
      <ButtonPrimary
        className="button"
        strokeVariant
        icon={<Icon icon={ArrowMini} size={10} rotate={180} />}
        onClick={onBackPage}
        disabled={backPageDisabled}
      />
      <Text className="button" fontSize={16} lineHeight={24}>
        {currentPage}
      </Text>
      <ButtonPrimary
        className="button"
        strokeVariant
        icon={<Icon icon={ArrowMini} size={10} />}
        disabled={nextPageDisabled}
        onClick={onNextPage}
      />
    </div>
  );
}

PaginationButtons.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number,
  onBackPage: PropTypes.func,
  onNextPage: PropTypes.func,
  backPageDisabled: PropTypes.bool,
  nextPageDisabled: PropTypes.bool,
};

export default PaginationButtons;
