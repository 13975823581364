/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import { BLACK, WHITE } from 'styles/colors';
import Button from 'components/buttons/Button';
import { setExperiences } from 'redux/entities';
import { MEDIA_QUERIES } from 'styles/constants';
import Modal from 'components/modals/Modal/index';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import CardExperienceSmall from 'components/cards/CardExperienceSmall';

const styles = css`
  width: 500px;
  padding: 24px;
  max-width: 650px;
  background-color: ${WHITE};

  .mb-30-centered {
    margin: 30px auto;
  }

  .action-button {
    width: 343px;
  }

  .button-back {
    width: fit-content;
  }

  ${MEDIA_QUERIES.mobile} {
    .action-button {
      width: 100%;
    }
  }
`;

export default function ModalAddParticipation({
  visible,
  campaignId,
  onRequestClose,
}) {
  const [participationSelected, setParticipationSelected] =
    useState(null);

  const { participations, experiences } = useSelector(
    (state) => state.entities,
  );

  const { lastMomentCompletedId } = useSelector(
    (state) => state.feekApp,
  );

  const dispatch = useDispatch();

  const bookExperience = () => {
    APIClient.bookExperience(
      participationSelected?.experience,
      true,
      lastMomentCompletedId,
      campaignId,
    )
      .then(({ data }) => {
        dispatch(
          setExperiences({
            _id: participationSelected?.experience,
            subscribed: data._id,
          }),
        );
        setParticipationSelected(null);
        onRequestClose(true);
      })
      .catch(handleError);
  };
  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <div css={styles}>
        {!participationSelected ? (
          <>
            <Text
              fontSize={21}
              fontSizeMobile={20}
              color={BLACK}
              align="center"
              fontWeight={600}
              className="mb-30-centered"
            >
              Selecciona la recompensa a la cual quieres sumar tu
              participación
            </Text>

            {participations &&
              Object.values(participations)?.map((participation) => (
                <CardExperienceSmall
                  key={participation._id}
                  image={
                    experiences[participation.experience]?.image[0]
                  }
                  ranking={participation.statistics.ranking}
                  title={experiences[participation.experience]?.title}
                  onPress={() =>
                    setParticipationSelected(participation)
                  }
                  eventDate={
                    experiences[participation.experience]?.event_date
                  }
                />
              ))}
          </>
        ) : (
          <>
            <Text
              fontSize={21}
              fontSizeMobile={20}
              color={BLACK}
              align="center"
              fontWeight={600}
              className="mb-30-centered"
            >
              Confirmar participación
            </Text>
            <Text
              color={BLACK}
              fontSize={16}
              align="center"
              className="mb-30-centered"
            >
              Se sumará una promoción completada a la sigueinte
              recompensa en la que estás participando:
            </Text>

            <CardExperienceSmall
              image={
                experiences[participationSelected?.experience]
                  ?.image[0]
              }
              ranking={participationSelected?.statistics?.ranking}
              title={
                experiences[participationSelected?.experience]?.title
              }
              eventDate={
                experiences[participationSelected?.experience]
                  ?.event_date
              }
            />

            <ButtonPrimary
              pillShape
              color={BLACK}
              label="Confirmar"
              onClick={bookExperience}
              className="action-button mb-30-centered"
            />

            <Button
              className="mb-30-centered button-back"
              onClick={() => setParticipationSelected(null)}
            >
              <Text color={BLACK} fontSize={16}>
                ← Volver a seleccionar
              </Text>
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
}

ModalAddParticipation.propTypes = {
  visible: PropTypes.bool,
  momentId: PropTypes.string,
  campaignId: PropTypes.string,
  onRequestClose: PropTypes.func,
};
