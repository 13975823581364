/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/Icon';
import Text from 'components/Text';
import { WHITE } from 'styles/colors';
import Button from 'components/buttons/Button';
import { ROUTES_FEEKAPP } from 'constants/routes';
import AbstractWingBg from 'assets/images/AbstractWingBg.png';
import { ReactComponent as FeekLogo } from 'assets/icons/FeekLogo.svg';
import { ReactComponent as FeekLogoComplete } from 'assets/icons/FeekLogoComplete.svg';

const style = css`
  display: flex;
  height: 100vh;
  width: 100%;

  a {
    text-decoration: none;
  }

  th {
    padding-right: 20px;
    vertical-align: top;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url(${AbstractWingBg}) no-repeat center;
    background-size: cover;
    padding: 50px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    flex: 1;

    svg:last-of-type {
      width: 59px;
      height: 59px;
    }
  }

  .info {
    width: 60%;
    height: 100%;
    padding: 40px;
    overflow: auto;
    scroll-behavior: smooth;

    div {
      margin: 20px 0;
    }

    .title {
      display: none;
    }
  }

  @media screen and (max-width: 769px) {
    flex-direction: column;
    height: unset;

    .header {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;

      svg:last-of-type {
        width: 25px;
        height: 25px;
      }

      div:last-of-type {
        display: none;
      }
    }

    .info {
      width: 100%;
      .title {
        display: initial;
      }
    }
  }
`;

function Policy() {
  const navigate = useNavigate();

  return (
    <div css={style}>
      <div className="header">
        <Button onClick={() => navigate(ROUTES_FEEKAPP.LANDING)}>
          <Icon icon={FeekLogo} color={WHITE} />
        </Button>
        <div>
          <Icon icon={FeekLogoComplete} color={WHITE} size={137} />
          <Text fontSize={60} color={WHITE} fontWeight="bold">
            Privacy Policy
          </Text>
        </div>
      </div>
      <div className="info">
        <Text fontSize={24} className="title">
          Privacy Policy
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Effective date: September 30th, 2022
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Updated: December 2nd, 2022
        </Text>
        <br />
        <a href="#policy-1">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            1. About this policy
          </Text>
        </a>
        <a href="#policy-2">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            2. Your personal data rights and controls
          </Text>
        </a>
        <a href="#policy-3">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            3. Personal data we collect about you
          </Text>
        </a>
        <a href="#policy-4">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            4. Our purpose for using your personal data
          </Text>
        </a>
        <a href="#policy-5">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            5. Share/Transfer your personal data
          </Text>
        </a>
        <a href="#policy-6">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            6. Disclosure of Data
          </Text>
        </a>
        <a href="#policy-7">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            7. Data retention and deletion
          </Text>
        </a>
        <a href="#policy-8">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            8. Keeping your personal data safe
          </Text>
        </a>
        <a href="#policy-9">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            9. Privacy applicability
          </Text>
        </a>
        <a href="#policy-10">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            10. Tracking and Cookies Data
          </Text>
        </a>
        <a href="#policy-11">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            11. Changes to this policy
          </Text>
        </a>
        <a href="#policy-12">
          <Text fontSize={20} lineHeight={24} fontWeight="bold">
            12. How to contact us
          </Text>
        </a>
        <br />
        <br />
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="policy-1"
        >
          1.About this policy.
        </Text>
        <Text fontSize={20} lineHeight={24}>
          In order to ensure a correct understanding of this privacy
          policy to our users, the following are the definitions used
          throughout this document:
        </Text>
        <Text fontSize={20} lineHeight={24} fontWeight="bold">
          Definitions
        </Text>
        <ul>
          <li>
            <Text fontSize={20} lineHeight={24}>
              <strong>Feek (or “us”, “we”, “our”):</strong> Frengers,
              INC., a Delaware corporation legally incorporated under
              the commercial laws of the United States of America,
              which is evidenced by its Certificate of Incorporation
              with Company File Number 5645246 and Authentication
              Number 202818604, issued by the Secretary of State of
              the State of Delaware, United States of America, on
              March 24th, 2021, with Employer Identification Number
              (EIN): 35-2709014 and place of business in 651 N Broad
              Street, Suite 206, Middletown, DE 19709.
            </Text>
          </li>
          <li>
            <Text fontSize={20} lineHeight={24}>
              <strong>Personal Data (or User Data):</strong> Personal
              Data means data about an individual who can be
              identified from those data (or from those and other
              information either in our possession or likely to come
              into our possession).
            </Text>
          </li>
          <li>
            <Text fontSize={20} lineHeight={24}>
              <strong>Usage Data:</strong> Usage Data is data
              collected automatically either generated by the use of
              our Service or from the Service infrastructure itself
              (for example, the duration of a page visit).
            </Text>
          </li>
          <li>
            <Text fontSize={20} lineHeight={24}>
              <strong>Cookies:</strong>Cookies are small pieces of
              data stored on a User&apos;s device
            </Text>
          </li>
          <li>
            <Text fontSize={20} lineHeight={24}>
              <strong>Data Controller:</strong> Data Controller means
              a person who (either alone or jointly or in common with
              other persons) determines the purposes for which and the
              way any personal data are, or are to be, processed. For
              the purpose of this Privacy Policy, we are a Data
              Controller of your data.
            </Text>
          </li>
          <li>
            <Text fontSize={20} lineHeight={24}>
              <strong>Data Processor (or Service Providers): </strong>
              Data Processor (or Service Provider) means any person
              (other than an employee of the Data Controller) who
              processes the data on behalf of the Data Controller.
            </Text>
          </li>
          <li>
            <Text fontSize={20} lineHeight={24}>
              <strong>Data Subject:</strong> Data Subject is any
              individual who is the subject of Personal Data.
            </Text>
          </li>
          <li>
            <Text fontSize={20} lineHeight={24}>
              <strong>
                User or Users (also referred to as “you”):
              </strong>
              The User is the individual using our Service. The User
              corresponds to the Data Subject, who is the subject of
              Personal Data.
            </Text>
          </li>
          <li>
            <Text fontSize={20} lineHeight={24}>
              <strong>Information Collection and Use:</strong> We
              collect several different types of information for
              various purposes to provide and improve our Service to
              you.
            </Text>
          </li>
          <li>
            <Text fontSize={20} lineHeight={24}>
              <strong>Service or Services:</strong> all services
              provided by Feek through the{' '}
              <a href="https://feek.app">https://feek.app</a>
              {' and '}
              <a href="https://partners.feek.app">
                https://partners.feek.app
              </a>
              websites and the “Feek App” mobile application.
            </Text>
          </li>
        </ul>
        <Text fontSize={20} lineHeight={24}>
          Feek operates the “Feek App” mobile application, as well as
          the{' '}
          <a href="https://partners.feek.app">
            https://partners.feek.app
          </a>{' '}
          website.
          <br />
          <br />
          This page informs you of our policies regarding the
          collection, use, and disclosure of personal data when you
          use our Services and the choices you have associated with
          that data.
          <br />
          <br />
          We use your data to provide and improve the Service. By
          using the Service, you agree to the collection and use of
          information in accordance with this policy.
          <br />
          <br />
          From time to time, we may develop new or offer additional
          Services. They&apos;ll also be subject to this Policy,
          unless stated otherwise when we introduce them.
          <br />
          <br />
          This Policy IS NOT Feek&apos;s Terms of Use. Feek&apos;s
          Terms of use are a separate document, outlining the legal
          contract between you and Feek for using our Services. It
          also describes the rules of Feek and your user rights.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="policy-2"
        >
          2. Your Personal Data Rights and Controls.
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Privacy Laws, including Mexico&apos;s Federal Law for the
          Protection of Personal Data in possession of Private
          Parties, give rights to individuals over their personal
          data.
          <br />
          <br />
          As a user of our services, you are entitled to the following
          rights:
          <table>
            <thead>
              <tr>
                <th></th>
                <th>
                  <Text
                    fontSize={20}
                    lineHeight={24}
                    fontWeight="bold"
                  >
                    It&apos;s your right to:
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <Text
                    fontSize={20}
                    lineHeight={24}
                    fontWeight="bold"
                  >
                    Access
                  </Text>
                </th>
                <th>
                  <Text fontSize={20} lineHeight={24}>
                    Be informed of the personal data we process about
                    you and to request access to it.
                  </Text>
                </th>
              </tr>
              <tr>
                <th>
                  <Text
                    fontSize={20}
                    lineHeight={24}
                    fontWeight="bold"
                  >
                    Rectify
                  </Text>
                </th>
                <th>
                  <Text fontSize={20} lineHeight={24}>
                    Request that we amend or update your personal data
                    where it&apos;s inaccurate or incomplete.
                  </Text>
                </th>
              </tr>
              <tr>
                <th>
                  <Text
                    fontSize={20}
                    lineHeight={24}
                    fontWeight="bold"
                  >
                    Cancel
                  </Text>
                </th>
                <th>
                  <Text fontSize={20} lineHeight={24}>
                    Request that we temporarily or permanently stop
                    processing all or some of your personal data.
                  </Text>
                </th>
              </tr>
              <tr>
                <th>
                  <Text
                    fontSize={20}
                    lineHeight={24}
                    fontWeight="bold"
                  >
                    Object
                  </Text>
                </th>
                <th>
                  <Text fontSize={20} lineHeight={24}>
                    Object to us processing your personal data at any
                    time, on grounds relating to your particular
                    situation, as well as your right to object to your
                    personal data being processed for direct marketing
                    purposes and your right to not be subject to a
                    decision based solely on automated decision
                    making, including profiling, where the decision
                    would have a legal effect on you or produce a
                    similarly significant effect.
                  </Text>
                </th>
              </tr>
            </tbody>
          </table>
          Other rights you are entitled to as a user of our service
          may include:
          <table>
            <thead>
              <tr>
                <th></th>
                <th>
                  <Text
                    fontSize={20}
                    lineHeight={24}
                    fontWeight="bold"
                  >
                    It&apos;s your right to:
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  <Text
                    fontSize={20}
                    lineHeight={24}
                    fontWeight="bold"
                  >
                    Erasure
                  </Text>
                </th>
                <th>
                  <Text fontSize={20} lineHeight={24}>
                    Request that we delete all or part of your
                    personal data.
                  </Text>
                </th>
              </tr>
              <tr>
                <th>
                  <Text
                    fontSize={20}
                    lineHeight={24}
                    fontWeight="bold"
                  >
                    Data Portability
                  </Text>
                </th>
                <th>
                  <Text fontSize={20} lineHeight={24}>
                    Request that we amend or update your personal data
                    where it&apos;s inaccurate or incomplete.
                  </Text>
                </th>
              </tr>
            </tbody>
          </table>
          <strong>How to exercise your rights with Feek.</strong>
          <br />
          <strong>Access →</strong> To request a copy of your personal
          data from Feek, visit your account Privacy Settings. There
          you can automatically download much of your personal data
          and find out how to request more information.
          <br />
          <br />
          <strong>Rectification →</strong> You can edit your personal
          data under &quot;Edit profile&quot; in your account.
          <br />
          <br />
          <strong>Erasure →</strong> To request erasure of your other
          personal data from Feek (e.g. your User Data, Usage Data and
          other data listed in Section 3 &apos;Personal data we
          collect about you&apos; below), you can permanently close
          your user account. Your other rights: You can contact Feek
          directly to exercise your rights at any time (see Section 12
          &apos;How to Contact Us&apos;), indicating at least your
          name and/or Instagram username or any other information or
          document that allows your identification, as well as the
          purpose of your request and/or procedure to be carried out.
          <br />
          <br />
          We use your Personal Data to contact you with newsletters,
          marketing or promotional materials and other information
          that may be of interest to you. You may opt out of receiving
          any, or all, of these communications from us by following
          the unsubscribe link or instructions provided in any email
          we send.
          <br />
          <br />
          You also have the right to contact the National Institute of
          Transparency, Access to Information and Protection of
          Personal Data (INAI), which is Mexico&apos;s authority for
          privacy protection or your local data protection authority
          about any questions or concerns.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="policy-3"
        >
          3. Personal Data we collect about you.
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Feek collects your personal data in the following ways:
          <table>
            <tbody>
              <tr>
                <th>
                  <Text
                    fontSize={20}
                    lineHeight={24}
                    fontWeight="bold"
                  >
                    Collected when you sign up for our Services or
                    when you update your account.
                  </Text>
                </th>
                <th>
                  <Text fontSize={20} lineHeight={24}>
                    <strong>User Data:</strong> Personal data that we
                    need to create your Feek account and enables you
                    to use our Services. The type of data collected
                    depends on the type of Service Option you have,
                    how you create your account, the country you are
                    in, and if you use third party services to sign
                    in. This may include your:
                    <ul>
                      <li>First name and last name</li>
                      <li>Email address</li>
                      <li>
                        Third party service account name (i.e., your
                        Instagram account name)
                      </li>
                      <li>
                        Business name (only applicable for users of
                        the website{' '}
                        <a href="https://partners.feek.app">
                          https://partners.feek.app
                        </a>
                        )
                      </li>
                      <li>
                        Telephone number (only applicable for users of
                        the website{' '}
                        <a href="https://partners.feek.app">
                          https://partners.feek.app
                        </a>
                        )
                      </li>
                      <li>
                        Business logo and pictures of the business
                        and/or the products and/or services offered by
                        the business (only applicable for users of the
                        website{' '}
                        <a href="https://partners.feek.app">
                          https://partners.feek.app
                        </a>
                        )
                      </li>
                      <li>
                        Address (only applicable for users of the
                        website{' '}
                        <a href="https://partners.feek.app">
                          https://partners.feek.app
                        </a>
                        )
                      </li>
                    </ul>
                  </Text>
                </th>
              </tr>
              <tr>
                <th>
                  <Text
                    fontSize={20}
                    lineHeight={24}
                    fontWeight="bold"
                  >
                    Collected through your use of our Services.
                  </Text>
                </th>
                <th>
                  <Text fontSize={20} lineHeight={24}>
                    Usage Data: Personal data collected about you when
                    you&apos;re accessing and/or using our Services.
                    Examples include:
                    <ul>
                      <li>
                        Information about your Feek Service Option.
                      </li>
                      <li>
                        Your actions with our Services (including date
                        and time), such as: <br />- Search queries{' '}
                        <br /> - Account settings <br /> - Your use of
                        third-party services, devices and applications
                        in connection with our Services.
                      </li>
                      <li>
                        Inferences (i.e., our understanding) of your
                        interests and preferences based on your usage
                        of our Services.
                      </li>
                      <li>
                        Content you post to any part of our Services,
                        such as images, text, communications, and
                        other types of content
                      </li>
                    </ul>
                    <strong>Technical Data:</strong> Examples include:
                    <ul>
                      <li>URL information</li>
                      <li>Online identifiers such as IP addresses</li>
                      <li>
                        Information about the devices you use such as:
                        <br />
                        - device IDs
                        <br />
                        - network connection type (e.g. Wi-Fi, 4G,
                        LTE, Bluetooth)
                        <br />
                        - provider
                        <br />
                        - network and device performance
                        <br />
                        - browser type
                        <br />
                        - language
                        <br />
                        - information enabling digital rights
                        management
                        <br />
                        - operating system
                        <br />- Feek application version
                      </li>
                      <li>
                        Information (such as the device name, device
                        identifiers, brand and version) which enables
                        us to discover and connect with third party
                        devices and applications.
                      </li>
                    </ul>
                    <strong>
                      Your general (non-precise) location:{' '}
                    </strong>
                    This may be understood from technical data (e.g.
                    your IP address, language setting of your device,
                    or payment currency). We need this to meet
                    geographic requirements in order to fully provide
                    our Services, and to deliver content and
                    advertising that&apos;s relevant to you.
                  </Text>
                </th>
              </tr>
              <tr>
                <th>
                  <Text
                    fontSize={20}
                    lineHeight={24}
                    fontWeight="bold"
                  >
                    Collected from other (&apos;third party&apos;)
                    sources
                  </Text>
                </th>
                <th>
                  <Text fontSize={20} lineHeight={24}>
                    <strong>Authentication Partners:</strong> If you
                    register for or log into our services using
                    another service, we&apos;ll receive your
                    information from them to help create your account
                    with us. As Feek uses Instagram&apos;s API Graph
                    to provide its services, Feek will collect basic
                    metadata, metrics and/or &apos;Insights&apos;
                    about your Instagram creator or business account.
                    We may also receive your data from technical
                    service providers, payment partners and
                    advertising and marketing partners.
                  </Text>
                  <Text fontSize={20} lineHeight={24}>
                    <strong>
                      Third party applications, services and devices
                      you connect to your Feek account:
                    </strong>{' '}
                    If you connect your Feek account to a third-party
                    application, service and/or devices, we may
                    collect certain information from them to make the
                    integration possible. These third-party apps,
                    services or devices may include:
                    <ul>
                      <li>social media</li>
                      <li>
                        devices including: <br />
                        -mobile phones and tablets
                      </li>
                    </ul>
                    We&apos;ll ask your permission before we collect
                    your information from certain third parties.
                  </Text>
                  <Text fontSize={20} lineHeight={24}>
                    <strong>Technical Service Partners:</strong> We
                    work with technical service partners that give us
                    certain data, such as mapping IP addresses to
                    non-precise location data (e.g., city, state).
                    This makes it possible for Feek to provide our
                    Service, content, and features.
                  </Text>
                  <Text fontSize={20} lineHeight={24}>
                    <strong>
                      Advertising and marketing partners:
                    </strong>{' '}
                    We may get data about you from certain advertising
                    or marketing partners. This may include:
                    <ul>
                      <li>Send you invoices</li>
                      <li>Process your payment</li>
                      <li>
                        Provide you with the Services you purchased.
                      </li>
                    </ul>
                    While making any purchases from Feek, or signing
                    up for a trial, our payment partners will process
                    your payment data. The exact personal data
                    collected will vary depending on the payment
                    method, but will include information such as:
                    <ul>
                      <li>Name</li>
                      <li>Date of birth</li>
                      <li>
                        Credit or debit card type, expiration date,
                        and certain digits of your card number. NOTE:
                        For your security, Feek will never store your
                        full card number
                      </li>
                      <li>ZIP/Postal Code</li>
                      <li>Mobile phone number</li>
                      <li>
                        Details of your purchase and payment history.
                      </li>
                    </ul>
                  </Text>
                </th>
              </tr>
            </tbody>
          </table>
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="policy-4"
        >
          4. Our purpose for using your personal data.
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Feek uses your collected Personal Data (see Section 3
          &quot;Personal data we collect about you&quot;) for the
          following purposes:
          <ul>
            <li>to provide and maintain our Services</li>
            <li>to notify you about changes to our Services</li>
            <li>
              to understand, diagnose, troubleshoot, and fix issues
              with our Services
            </li>
            <li>
              to evaluate and develop new features, technologies, and
              improvements to our Services
            </li>
            <li>
              for marketing, promotion, and advertising purposes (this
              includes the possibility to advertise using Meta
              advertising or any other digital and social media
              marketing)
            </li>
            <li>
              for marketing, promotion, and advertising purposes
            </li>
            <li>
              to allow you to participate in interactive features of
              our Service when you choose to do so
            </li>
            <li>to provide customer support</li>
            <li>
              to gather analysis or valuable information so that we
              can improve our Service
            </li>
            <li>to monitor the usage of our Service</li>
            <li>to train machine learning models</li>
            <li>to detect, prevent and address technical issues</li>
            <li>
              to provide you with news, special offers and general
              information about other goods, services and events which
              we offer that are like those that you have already
              purchased or enquired about unless you have opted not to
              receive such information
            </li>
            <li>
              to comply with legal obligations and law enforcement
              requests
            </li>
            <li>
              to fulfill contractual obligations with third parties
            </li>
            <li>to establish, exercise, or defend legal claims</li>
            <li>
              to conduct business planning, reporting, and forecasting
            </li>
            <li>to process your payment, if applicable</li>
            <li>
              to detect and prevent fraud, including fraudulent
              payments and fraudulent use of our Services
            </li>
          </ul>
          We rely on one or more of the following legal grounds (as
          appropriate) to process your Personal Data:
          <ul>
            <li>
              Performance of a Contract: When it&apos;s necessary for
              Feek (or a third party) to process your personal data
              to: <br /> -comply with obligations under a contract
              with you, e.g. Feek&apos;s obligations under the Terms
              of Use to provide our Services to you <br /> -or verify
              information before a new contract with you begins
            </li>
            <li>
              Legitimate Interest: When Feek (or a third party) has an
              interest in using your personal data in a certain way,
              which is necessary and justified considering any
              possible risks to you and other Feek users. For example,
              using your Usage Data to improve our Services for all
              users. Contact us if you want to understand a specific
              justification.
            </li>
            <li>
              Consent: When Feek asks you to actively indicate your
              agreement to Feek&apos;s use of your personal data for a
              certain purpose(s).
            </li>
            <li>
              Compliance with Legal Obligations: When Feek must
              process your personal data to comply with a law.
            </li>
          </ul>
          Where Personal Data is processed with consent, you may
          (subject to contractual and legal restrictions) refuse to
          provide your consent or choose to withdraw your consent by
          contacting us as described below. Note that if you refuse to
          consent, or withdraw your consent, to certain processing of
          your personal information, we may not be able to provide
          certain of our services.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="policy-5"
        >
          5. Share/Transfer of your Personal Data.
        </Text>
        <Text fontSize={20} lineHeight={24}>
          <strong>Share</strong>
          <br />
          Feek and/or any of its subsidiaries and/or affiliates may
          share your personal data to third parties that either have
          hired Feek or have been hired by Feek to perform certain
          tasks related to commercial activities and promotion of
          products and/or services. Your consent to this Privacy
          Policy followed by your submission of such information
          represents your agreement to that share.
          <br />
          Whenever your personal data is shared it will always be
          processed in compliance with Feek&apos;s instructions. We do
          this using a variety of protections, e.g. we use:
          <ul>
            <li>
              Standard Contractual Clauses (or an alternative legal
              tool) to require the third party to protect your data
              and to provide you with equivalent rights and
              protections as those stated in Mexico&apos;s laws
            </li>
            <li>
              Technical protections, such as encryption and
              pseudonymization
            </li>
            <li>
              Policies and processes to challenge disproportionate or
              unlawful government authority requests.
            </li>
          </ul>
          You can exercise your rights under the Standard Contractual
          Clauses by contacting us or the third party who processes
          your personal data.
          <br />
          <br />
          <strong>Transfer</strong>
          <br />
          <br />
          Your information, including Personal Data, may be
          transferred to, and maintained on, computer systems owned or
          controlled by us, or our service providers located outside
          of your state, province, country or other governmental
          jurisdiction where the data protection laws may differ than
          those from your jurisdiction.
          <br />
          <br />
          If you are located outside Mexico and choose to provide
          information to us, please note that we transfer the data,
          including Personal Data, to Mexico and process it there.
          <br />
          <br />
          As a result, Feek will take all steps reasonably necessary
          to ensure that your data is treated securely and in
          accordance with this Privacy Policy and no transfer of your
          Personal Data will take place to an organization or a
          country unless there are adequate controls in place
          including the security of your data and other personal
          information.
          <br />
          <br />
          As your personal data may be transferred, Feek will always
          enforce the following actions:
          <ul>
            <li>
              Make sure the data transfer complies with applicable law
            </li>
            <li>
              Help to give your data the same level of protection as
              it has in Mexico
            </li>
            <li>
              Take immediate actions when a third-party is not
              complying with the agreed obligations.
            </li>
          </ul>
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="policy-6"
        >
          6. Disclosure of Data.
        </Text>
        <Text fontSize={20} lineHeight={24}>
          Feek may disclose some of your data when the following
          circumstances are met:
          <table>
            <tbody>
              <tr>
                <th>
                  <Text
                    fontSize={20}
                    lineHeight={24}
                    fontWeight="bold"
                  >
                    Business Transaction
                  </Text>
                </th>
                <th>
                  <Text fontSize={20} lineHeight={24}>
                    If Feek is involved in a merger, acquisition or
                    asset sale, your Personal Data may be transferred.
                    We will provide notice before your Personal Data
                    is transferred and becomes subject to a different
                    Privacy Policy.
                  </Text>
                </th>
              </tr>
              <tr>
                <th>
                  <Text
                    fontSize={20}
                    lineHeight={24}
                    fontWeight="bold"
                  >
                    Disclosure for Law Enforcement
                  </Text>
                </th>
                <th>
                  <Text fontSize={20} lineHeight={24}>
                    Under certain circumstances, Feek may be required
                    to disclose your Personal Data if required to do
                    so by law or in response to valid requests by
                    public authorities (e.g. a court or a government
                    agency).
                  </Text>
                </th>
              </tr>
              <tr>
                <th>
                  <Text
                    fontSize={20}
                    lineHeight={24}
                    fontWeight="bold"
                  >
                    Legal Requirements
                  </Text>
                </th>
                <th>
                  <Text fontSize={20} lineHeight={24}>
                    Feek may disclose your Personal Data in the good
                    faith belief that such action is necessary to:
                    <ul>
                      <li>To comply with a legal obligation</li>
                      <li>
                        To protect and defend the rights or property
                        of Frengers, Inc.
                      </li>
                      <li>
                        To prevent or investigate possible wrongdoing
                        in connection with the Service
                      </li>
                      <li>
                        To protect the personal safety of users of the
                        Service or the public
                      </li>
                      <li>To protect against legal liability</li>
                    </ul>
                  </Text>
                </th>
              </tr>
            </tbody>
          </table>
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="policy-7"
        >
          7. Data Retention and Deletion.
        </Text>
        <Text fontSize={20} lineHeight={24}>
          <strong>Retention</strong>
          <br />
          Feek will retain your Personal Data on our servers or those
          of our service providers only for as long as is necessary to
          provide you with our Services, for Feek&apos;s legitimate
          and essential business purposes and for the purposes set out
          in this Privacy Policy, such as:
          <ul>
            <li> Maintaining the performance of our Services.</li>
            <li>
              Making data-driven business decisions about new features
              and offerings
            </li>
            <li>
              To comply with our legal obligations (for example, if we
              are required to retain your data to comply with
              applicable laws)
            </li>
            <li> Resolve disputes, and </li>
            <li> Enforce our legal agreements and policies.</li>
          </ul>
          Feek will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter
          period, except when this data is used to strengthen the
          security or to improve the functionality of our Service, or
          we are legally obligated to retain this data for longer time
          periods.
          <br />
          <br />
          <strong>Deletion</strong>
          <br />
          If you close or request that we close your account, we will
          delete or anonymize your personal data, so it no longer
          identifies you, unless we&apos;re required to keep
          something, or we still need to use it for a legally
          justifiable reason.
          <br />
          <br />
          Here are some examples of situations where we&apos;re
          legally allowed or required to keep some of your personal
          data:
          <ul>
            <li>
              If there&apos;s an unresolved issue relating to your
              account, such as an outstanding credit or unresolved
              claim or dispute
            </li>
            <li>
              For our legal, tax, audit and accounting obligations
            </li>
            <li>
              For our legitimate business interests such as fraud
              prevention or to maintain security.
            </li>
          </ul>
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="policy-8"
        >
          8. Keeping your personal data safe.
        </Text>
        <Text fontSize={20} lineHeight={24}>
          The security of your data is important to us but remember
          that no method of transmission over the Internet, or method
          of electronic storage is 100% secure. While we strive to use
          commercially and technically acceptable means to protect
          your personal data, we cannot guarantee its absolute
          security. Authorized employees, representatives and agents
          will have access to your personal data for the purposes
          described in our Privacy Policy.
          <br />
          <br />
          We have implemented various safeguards including
          pseudonymization, encryption, access, and retention policies
          to guard against unauthorized access and unnecessary
          retention of personal data in our systems.
          <br />
          <br />
          For the best protection of your user account, we encourage
          you to:
          <ul>
            <li>
              Use a strong and unique password for your Feek Account
            </li>
            <li>Never share your password with anyone</li>
            <li>
              Limit access to your computer and/or mobile device
            </li>
            <li>
              Log out once you have finished using our Services on a
              shared device.
            </li>
          </ul>
          You can log out of your Feek account in multiple places at
          once by using the &quot;Sign out everywhere&quot; function
          on your account page.
          <br />
          <br />
          If other individuals have access to your Feek account (for
          example if you&apos;ve given them permission to use your
          account on a shared device), then they can access personal
          data, controls and our Services available in your account.
          <br />
          <br />
          It&apos;s your responsibility to only provide individuals
          with permission to use your account where you&apos;re
          comfortable sharing this personal data with them. Anyone
          else&apos;s use of your Feek account may impact your
          personalized experience during the use of our Services.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="policy-9"
        >
          9. Privacy Applicability.
        </Text>
        <Text fontSize={20} lineHeight={24}>
          The Services have a minimum &quot;Age Limit&quot; in Mexico.
          The Services are not directed to children whose age:
          <ul>
            <li>
              Is under the age of 18 years or the minimum required age
              to have an Instagram account
            </li>
            <li>
              Or, makes it illegal to process their personal data
            </li>
            <li>
              Or, requires parental consent to process their personal
              data.
            </li>
          </ul>
          We do not knowingly collect personal data from children
          under the applicable Age Limit. If you&apos;re under the Age
          Limit, please do not use the Services, and do not provide
          any personal data to us.
          <br />
          <br />
          If you&apos;re a parent of a child under the Age Limit and
          become aware that your child has provided personal data to
          Feek, please contact us.
          <br />
          <br />
          If we learn that we&apos;ve collected the personal data of a
          child under the applicable Age Limit, we&apos;ll take
          reasonable steps to delete the personal data. This may
          require us to delete the Feek account for that child.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="policy-10"
        >
          10. Tracking and Cookies Data.
        </Text>
        <Text fontSize={20} lineHeight={24}>
          We use cookies and similar tracking technologies to track
          the activity exclusively on the{' '}
          <a href="https://feek.app">https://feek.app</a>
          {' and '}
          <a href="https://partners.feek.app">
            https://partners.feek.app
          </a>
          websites. No tracking and cookies are used while our users
          navigate or enjoy the services provided by the “Feek App”
          mobile application. Some purposes of our Cookies can be
          authenticating users, remembering user preferences and
          settings, determining the popularity of content, delivering
          and measuring the effectiveness of advertising campaigns,
          analysing site traffic and trends, and generally
          understanding the online behaviours and interests of people
          who interact with our website.
          <br />
          <br />
          Cookies are files with small amounts of data which may
          include a unique identifier. Cookies are sent to your
          browser from a website and stored on your device. Tracking
          technologies also used are beacons, tags, and scripts to
          collect and track information and to improve and analyze our
          Service.
          <br />
          <br />
          <strong>Your Choices</strong>
          <br />
          You have the right to choose whether or not to accept
          cookies. However, they are an important part of how our
          Services work, so you should be aware that if you choose to
          refuse or remove cookies, this could affect the availability
          and functionality of the Services.
          <br />
          <br />
          Most web browsers are set to accept cookies by default. If
          you prefer, you can usually choose to set your browser to
          remove or reject browser cookies. To do so, please follow
          the instructions provided by your browser which are usually
          located within the &apos;Help&apos; or
          &apos;Preferences&apos; menu.
          <br />
          <br />
          <strong>Examples of Cookies we use: </strong>
          <br />
          <strong>Preference Cookies.</strong> We use Preference
          Cookies to remember your preferences and various settings.
          <br />
          <strong>Security Cookies. </strong>We use Security Cookies
          for security purposes.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="policy-11"
        >
          11. Changes to this Policy.
        </Text>
        <Text fontSize={20} lineHeight={24}>
          We may update our Privacy Policy from time to time. We will
          notify you of any changes by posting the new Privacy Policy
          on this page.
          <br />
          <br />
          We will let you know via email and/or a prominent notice on
          our Service, prior to the change becoming effective and
          update the &quot;effective date&quot; at the top of this
          Privacy Policy.
          <br />
          <br />
          You are advised to review this Privacy Policy periodically
          for any changes. Changes to this Privacy Policy are
          effective when they are posted on this page.
        </Text>
        <Text
          fontSize={20}
          lineHeight={24}
          fontWeight="bold"
          id="policy-12"
        >
          12. How to contact us
        </Text>
        <Text fontSize={20} lineHeight={24}>
          If you have any questions about this Privacy Policy, please
          contact us by email: privacy@feek.app
          <br />
          <br />
          You can also visit our Privacy Center for more clarification
          of this Policy.
        </Text>
      </div>
    </div>
  );
}

export default Policy;
