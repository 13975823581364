/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';

import APIClient from 'redux/api';
import Text from 'components/Text';
import Modal from 'components/modals/Modal';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { BLACK, RED_NOTIFICATIONS } from 'styles/colors';
import ButtonPrimary from 'components/buttons/ButtonPrimary';

const styles = css`
  width: 400px;
  padding: 20px 24px 0;

  > * {
    margin-bottom: 24px;
  }

  .delete-account {
    border-width: 2px;
  }

  .cancel-button {
    width: fit-content;
    margin-inline: auto;
    text-decoration: underline;
  }

  ${MEDIA_QUERIES.mobile} {
    width: 100%;
    padding: 16px 0;

    > * {
      margin-bottom: 16px;
    }
  }
`;

function ModalDeleteFeekerAccount({ onRequestClose, visible }) {
  const { id } = useSelector((state) => state.feekApp);
  const [step, setStep] = useState(1);
  const handleClose = () => {
    setStep(1);
    onRequestClose?.();
  };

  return (
    <Modal onRequestClose={handleClose} visible={visible}>
      <article css={styles}>
        {step === 1 ? (
          <>
            <Text
              align="center"
              fontSize={21}
              fontSizeMobile={16}
              fontWeight={600}
              color={BLACK}
            >
              ¿Estás segurx que deseas <br /> eliminar tu cuenta?
            </Text>

            <Text
              align="center"
              fontSize={16}
              lineHeight={24}
              color={BLACK}
            >
              Esta acción no puede deshacerse. Una vez que elimines tu
              cuenta, tu puntaje en Feek y todos tus datos se
              borrarán.
            </Text>

            <ButtonPrimary
              pillShape
              label="Deseo eliminar mi cuenta"
              strokeVariant
              strokeColor={RED_NOTIFICATIONS}
              textColor={RED_NOTIFICATIONS}
              textProps={{ fontWeight: 500 }}
              className="delete-account"
              onClick={() => setStep(2)}
            />
          </>
        ) : (
          <>
            <Text
              align="center"
              fontSize={21}
              fontSizeMobile={16}
              fontWeight={600}
              lineHeight={25.3}
              color={BLACK}
            >
              Por favor confirma que deseas <br /> eliminar tu cuenta
            </Text>

            <ButtonPrimary
              pillShape
              label="Confirmar y eliminar"
              color={RED_NOTIFICATIONS}
              textProps={{ fontWeight: 500 }}
              className="delete-account"
              onClick={() => APIClient.deleteFeekerAccount(id)}
            />
          </>
        )}

        <Button className="cancel-button" onClick={handleClose}>
          <Text
            align="center"
            lineHeight={16}
            fontSize={16}
            color={BLACK}
          >
            Cancelar
          </Text>
        </Button>
      </article>
    </Modal>
  );
}

ModalDeleteFeekerAccount.propTypes = {
  onRequestClose: PropTypes.func,
  visible: PropTypes.bool,
};

export default ModalDeleteFeekerAccount;
