export const countNotCanceledMoments = (moments) => {
  return Math.max(
    0,
    moments?.reduce(
      (prev, crr) => (crr.status !== 'Cancelado' ? prev + 1 : prev),
      0,
    ),
  );
};

export const isDayAvailable = (days = [], day) => {
  return days.find((campaignDay) => campaignDay.day === day)
    ? true
    : false;
};
