/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import APIClient from 'redux/api';
import Icon from 'components/Icon';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { setExperiences } from 'redux/entities';
import { GRAY_LIGHTEST, GRAY_MID, WHITE } from 'styles/colors';
import { useScrollHorizontally } from 'hooks/useScrollHorizontally';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import CardExperienceCompleted from 'components/cards/CardExperienceCompleted';

const styles = css`
  position: relative;
  margin-top: 48px;
  margin-bottom: 96px;

  .experiences-feed {
    gap: 30px;
    display: flex;
    overflow-x: scroll;
    padding-right: 70px;
    flex-direction: row;
    scroll-behavior: smooth;
  }

  .experiences-feed::-webkit-scrollbar {
    display: none;
  }

  .circle-button {
    width: 58px;
    height: 58px;
    display: flex;
    top: 50%;
    position: absolute;
    border-radius: 36px;
    align-items: center;
    justify-content: center;
    background-color: ${WHITE};
    border: 2px solid ${GRAY_LIGHTEST};
  }

  .circle-button.prev {
    left: 2em;
  }
  .circle-button.next {
    right: 1em;
  }
`;

const cardWidth = 530;

const PastExperiencesFeed = () => {
  const [loading, setLoading] = useState(false);
  const [paginationData, setPaginationData] = useState([]);

  const lastPage = paginationData[paginationData.length - 1];

  const ref = useScrollHorizontally({ stopOnEdges: true });
  const dispatch = useDispatch();
  const { experiences } = useSelector((state) => state.entities);

  const pastExperiences = useMemo(() => {
    return Object.values(experiences)?.filter(
      ({ status }) => status === 'COMPLETED',
    );
  }, [experiences]);

  useEffect(() => {
    setLoading(true);

    APIClient.getExperiencesFeed({ status: 'COMPLETED' })
      .then(({ data }) => {
        dispatch(setExperiences(data.docs));
        setPaginationData([
          {
            ...data,
            docs: data.docs,
          },
        ]);
      })
      .catch(handleError)
      .finally(() => setLoading(false));
  }, []);

  const handleUserScroll = () => {
    const fullWidth = cardWidth * (pastExperiences.length - 1);
    if (
      ref.current.scrollLeft + ref.current.offsetWidth > fullWidth &&
      !loading
    ) {
      fetchMore();
    }
  };

  const fetchMore = () => {
    setLoading(true);
    APIClient.getExperiencesFeed({
      status: 'COMPLETED',
      page: lastPage.nextPage,
      limit: lastPage.limit,
    })
      .then(({ data }) => {
        dispatch(setExperiences(data.docs));
        setPaginationData([
          ...paginationData,
          {
            ...data,
            docs: data.docs,
          },
        ]);
      })
      .catch(handleError)
      .finally(() => setLoading(false));
  };

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  return (
    <div css={styles}>
      <div
        ref={ref}
        className="experiences-feed"
        onScroll={handleUserScroll}
      >
        {pastExperiences?.map((experience, index) => (
          <CardExperienceCompleted
            className="experience-card"
            key={experience._id + index}
            title={experience.title}
            image={experience.image}
            experienceId={experience._id}
            iconType={experience.icon?.type}
            iconColor={experience.icon?.color}
            description={experience.description}
          />
        ))}
      </div>

      <Button
        className="circle-button prev d-none d-md-flex"
        onClick={() => {
          scroll(-480);
        }}
      >
        <Icon size={14} rotate={90} icon={Chevron} color={GRAY_MID} />
      </Button>
      <Button
        className="circle-button next d-none d-md-flex"
        onClick={() => {
          scroll(480);
        }}
      >
        <Icon
          size={14}
          rotate={270}
          icon={Chevron}
          color={GRAY_MID}
        />
      </Button>
    </div>
  );
};

export default PastExperiencesFeed;
