/** @jsxImportSource @emotion/react */
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { forwardRef, useRef } from 'react';

import {
  GRAY,
  BLACK,
  PURPLE_MEDIUM,
  RED_NOTIFICATIONS,
} from 'styles/colors';
import { mergeRefs } from 'utils/react';
import Chevron from 'assets/icons/Chevron.svg';
import { propTypeLength } from 'utils/propValidation';
import { forwardInputEventHandlers } from 'utils/form.utils';
import InputWrapper from 'components/inputs/InputWrapper/input';

const InputSelect = forwardRef(
  (
    {
      name,
      width,
      error,
      value,
      label,
      height,
      options,
      required,
      className,
      placeholder,
      heightMobile,
      ...restProps
    },
    ref,
  ) => {
    const innerRef = useRef(null);

    const styles = css`
      --font-size: ${label ? '16px' : '14px'};
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      appearance: none;
      cursor: pointer;

      padding: ${label
        ? '24.5px 46px 10.5px 20px'
        : '15.5px 46px 15.5px 20px'};

      background-color: transparent;
      color: ${error
        ? RED_NOTIFICATIONS
        : !!placeholder &&
          !value &&
          (!innerRef.current ||
            innerRef.current.value === placeholder)
        ? label
          ? PURPLE_MEDIUM
          : GRAY
        : BLACK};

      font-size: var(--font-size);
      line-height: calc(var(--font-size) * 1.2);

      background-image: url(${Chevron});
      background-repeat: no-repeat;
      background-position: calc(100% - 16px) calc(50% + 2px);
    `;

    return (
      <InputWrapper
        className={className}
        height={height}
        width={width}
        error={error}
        label={label}
        heightMobile={heightMobile}
      >
        <select
          ref={mergeRefs(ref, innerRef)}
          required={placeholder || required ? true : false}
          name={name}
          css={styles}
          value={value || placeholder}
          {...restProps}
        >
          {placeholder && (
            <option
              className="placeholder"
              value={placeholder}
              disabled
              hidden
            >
              {placeholder}
            </option>
          )}
          {options.map((option, index) =>
            typeof option === 'string' ? (
              <option key={index} value={option}>
                {option}
              </option>
            ) : (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ),
          )}
        </select>
      </InputWrapper>
    );
  },
);

export const FormInputSelect = ({
  name,
  width,
  error,
  height,
  options,
  required,
  className,
  placeholder,
  heightMobile,
  ...restProps
}) => (
  <Field name={name}>
    {({ meta, field }) => (
      <InputSelect
        height={height}
        width={width}
        className={className}
        options={options}
        name={name}
        error={meta.touched && (meta.error || error)}
        placeholder={placeholder}
        required={required}
        value={meta.value}
        heightMobile={heightMobile}
        {...restProps}
        {...forwardInputEventHandlers(restProps, field)}
      />
    )}
  </Field>
);

const sharedPropTypes = {
  height: propTypeLength,
  width: propTypeLength,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
    ]),
  ),
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: (props, propName, componentName) => {
    if (!props[propName]) return;

    if (
      typeof props[propName] !== 'string' &&
      typeof props[propName] !== 'number'
    )
      return new Error(`Invalid prop ${propName} supplied to ${componentName}
      The value of the ${propName} prop must be a string or a number`);

    const arrayOfValues = [];
    if (
      !props.options?.some((option) => {
        const value =
          typeof option === 'string' || typeof option === 'number'
            ? option
            : option.value;
        arrayOfValues.push(value);
        return value == props[propName];
      })
    ) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}
      The value of the ${propName} prop must be included in the array value of the options prop
      Current ${propName} value: ${props[propName]}
      Current options values:\t${arrayOfValues.join()}`);
    }
  },
  required: PropTypes.bool,
  label: PropTypes.string,
};

FormInputSelect.propTypes = { ...sharedPropTypes };

InputSelect.propTypes = {
  onKeyDown: PropTypes.func,
  ...sharedPropTypes,
};

InputSelect.displayName = 'InputSelect';

export default InputSelect;
