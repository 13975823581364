/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Legend, Tooltip, Chart as ChartJS } from 'chart.js';

import {
  WHITE,
  GREEN_E,
  GRAY_TEXT,
  PURPLE_FEEK,
  DYNAMIC_BLUE,
  PURPLE_INACTIVE,
} from 'styles/colors';
import Text from 'components/Text';
import { Doughnut } from 'react-chartjs-2';
import MissingData from 'screens/manager/legacy/Dashboard/MissingData';

const style = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 39px;

  .legends {
    display: flex;
    flex-direction: column;

    ${[GREEN_E, PURPLE_FEEK, DYNAMIC_BLUE, PURPLE_INACTIVE]
      .map(
        (color, i) =>
          `.legend:nth-of-type(${
            1 + i
          }) .point{background-color: ${color}}`,
      )
      .join('')}

    .legend {
      display: flex;
      align-items: center;
      margin-bottom: 22px;

      .point {
        border-radius: 50%;
        width: 14px;
        height: 14px;
        margin-right: 15px;
      }

      .label {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 37px;
      }
    }
  }

  .chartWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .chartCenter {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .chart {
      position: relative;
      width: 190px;
      height: 190px;
    }
  }
`;

ChartJS.register(Legend, Tooltip);

export default function DoughnutChart({
  data = {
    values: [],
    labels: [],
  },
}) {
  const { values, labels } = data;

  const totalFeekers =
    values.reduce((prev, crr) => prev + crr, 0) || 0;

  return !(values.length > 0 && labels.length > 0) ? (
    <MissingData />
  ) : (
    <div css={style}>
      <div className="legends">
        {values.map((value, i) => (
          <div key={i} className="legend">
            <div className="point" />
            <div className="label">
              <Text fontSize={20} fontWeight="bold">
                {((value * 100) / totalFeekers || 0).toFixed(2)}%
              </Text>
              <Text fontSize={12}>{labels[i]}</Text>
            </div>
          </div>
        ))}
      </div>

      <div className="chartWrapper">
        <div className="chartCenter">
          <Text fontSize={48} color={PURPLE_FEEK} fontWeight="bold">
            {totalFeekers > 1000
              ? (totalFeekers / 1000).toFixed(1) + 'k'
              : totalFeekers}
          </Text>
          <Text fontSize={14}>
            {totalFeekers > 1000 && totalFeekers} feekers
          </Text>
        </div>
        <div className="chart">
          <Doughnut
            options={{
              cutout: '78%',
              radius: '90',
              font: { family: 'Roobert' },
              plugins: {
                tooltip: {
                  backgroundColor: WHITE,
                  displayColors: false,
                  cornerRadius: 4,
                  titleColor: GRAY_TEXT,
                  bodyColor: GRAY_TEXT,
                  bodyFont: { size: 12, family: 'Roobert' },
                  titleFont: { size: 12, family: 'Roobert' },
                  titleMarginBottom: 0,
                  borderColor: GRAY_TEXT,
                  borderWidth: 1,
                  callbacks: {
                    title: (tooltipItem) =>
                      tooltipItem[0].formattedValue + ' Feekers',
                    label: (labelItem) => labelItem.label,
                  },
                },
                legend: {
                  display: false,
                },
              },
            }}
            data={{
              labels: labels,
              datasets: [
                {
                  data: values,
                  backgroundColor: [
                    GREEN_E,
                    PURPLE_FEEK,
                    DYNAMIC_BLUE,
                    PURPLE_INACTIVE,
                  ],
                  borderColor: [
                    GREEN_E,
                    PURPLE_FEEK,
                    DYNAMIC_BLUE,
                    PURPLE_INACTIVE,
                  ],
                  borderWidth: 0,
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
}

DoughnutChart.propTypes = {
  data: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.number),
    labels: PropTypes.arrayOf(PropTypes.string),
  }),
};
