/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Field, useFormikContext } from 'formik';

import {
  RED,
  WHITE,
  GRAY_TEXT,
  RED_LIGHT,
  GRAY_LIGHT,
  PURPLE_FEEK,
  PURPLE_INACTIVE,
} from 'styles/colors';
import Text from 'components/Text';
import { mergeRefs } from 'utils/react';

const ignoredKeys = ['e', 'E', '-', '+', '.'];

const SingleNumberInput = React.forwardRef((props, ref) => {
  const {
    error,
    className,
    onKeyDown,
    inputClassName,
    ...restProps
  } = props;

  const styles = css`
    input {
      height: 100%;
      width: 42px;
      margin: 0;
      color: ${GRAY_TEXT};
      text-align: center;
      border: 1px solid ${error ? RED : PURPLE_INACTIVE};
      background-color: ${error ? RED_LIGHT : WHITE};
      font-weight: bold;
      border-radius: 4px;
      font-size: 16px;
      outline: none;
      -moz-appearance: textfield;

      &:-webkit-autofill {
        -webkit-text-fill-color: ${GRAY_TEXT};
        background-color: ${error ? RED_LIGHT : WHITE} !important;
        box-shadow: inset 0 0 0 1px rgb(255 255 255 / 0%),
          inset 0 0 0 1000px ${WHITE};
      }

      &:focus {
        border-color: ${error ? RED : PURPLE_FEEK};
      }

      &::placeholder {
        font-weight: initial;
        color: ${GRAY_LIGHT};
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  `;

  const handleKeyDown = (e) => {
    if (ignoredKeys.includes(e.key)) e.preventDefault();

    onKeyDown?.(e);
  };

  return (
    <div css={styles} className={className}>
      <input
        {...restProps}
        min={0}
        max={9}
        ref={ref}
        maxLength={1}
        type="number"
        placeholder="0"
        onKeyDown={handleKeyDown}
        className={inputClassName}
      />
    </div>
  );
});

SingleNumberInput.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  onKeyDown: PropTypes.func,
  error: PropTypes.bool,
};

SingleNumberInput.displayName = 'SingleNumberInput';

export const SplittedNumericalInput = React.forwardRef(
  (props, ref) => {
    const {
      error,
      value,
      onBlur,
      onChange,
      className,
      autoFocus,
      length = 6,
    } = props;

    const mountedRef = React.useRef(false);
    const isControlledInput = value !== undefined;
    const containerRef = React.useRef();
    const [stateValue, setStateValue] = React.useState(value ?? '');
    const singleNumberInputsRef = React.useRef({});

    const safelySetStateValue = (rawValue) =>
      setStateValue(rawValue.substring(0, length));

    React.useEffect(() => {
      if (value !== undefined) {
        setStateValue(value);
      }
    }, [value]);

    React.useEffect(() => {
      if (mountedRef.current) {
        singleNumberInputsRef.current[
          Math.min(stateValue.length, length - 1)
        ]?.focus();
      }
    }, [length, stateValue]);

    React.useEffect(() => {
      mountedRef.current = true;
    }, []);

    const styles = css`
      display: flex;
      flex-direction: column;
      padding: 0 15px;

      .error-text {
        min-height: 19px;
        margin: 3px 0;
      }

      .inputs-wrapper {
        align-self: center;
        display: flex;
        justify-content: center;
        gap: 12px;
        height: 56px;
        max-width: ${length * 60}px;
      }
    `;

    const handleSplittedNumericalInputChange = (newValue) => {
      const valueChanges = stateValue !== newValue;

      if (valueChanges) {
        onChange?.(newValue);

        if (!isControlledInput) {
          safelySetStateValue(newValue);
        }
      }
    };

    const handleInputsContainerClick = () => {
      singleNumberInputsRef.current[
        Math.min(stateValue.length, length - 1)
      ]?.focus();
    };

    return (
      <div
        css={styles}
        className={className}
        ref={mergeRefs(ref, containerRef)}
      >
        <div
          className="inputs-wrapper"
          onClick={handleInputsContainerClick}
        >
          {Array(length)
            .fill(null)
            .map((_, i) => {
              const isLast = i === length - 1;

              const handleChange = (e) => {
                const safeValue = e.target.value?.substring(0, 1);

                if (isLast) {
                  handleSplittedNumericalInputChange(
                    stateValue.substring(0, length - 1) + safeValue,
                  );
                } else {
                  handleSplittedNumericalInputChange(
                    stateValue.substring(0, i) + safeValue,
                  );
                }
              };

              const handleBlur = (e) => {
                if (!containerRef.current.contains(e.relatedTarget)) {
                  onBlur?.();
                }
              };

              const handleKeyDown = (e) => {
                if (e.key === 'Backspace' && !e.target.value) {
                  handleSplittedNumericalInputChange(
                    stateValue.substring(0, i - 1),
                  );
                }
              };

              const handlePaste = (e) => {
                e.preventDefault();

                const value = e.clipboardData.getData('text/plain');
                const matches = value.match(/^[0-9]+$/);

                if (matches) {
                  handleSplittedNumericalInputChange(
                    stateValue + value,
                  );
                }
              };

              return (
                <SingleNumberInput
                  key={i}
                  onBlur={handleBlur}
                  onPaste={handlePaste}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  value={stateValue[i] ?? ''}
                  error={!!error}
                  ref={(ref) =>
                    (singleNumberInputsRef.current[i] = ref)
                  }
                  disabled={
                    Math.min(stateValue.length, length - 1) !== i
                  }
                  {...(i === 0 && autoFocus
                    ? { autoFocus: true }
                    : { autoFocus: false })}
                />
              );
            })}
        </div>
        <Text
          fontSize={14}
          lineHeight={17}
          fontWeight="500"
          color={RED}
          className="error-text"
          align="center"
        >
          {typeof error === 'string' ? error : ''}
        </Text>
      </div>
    );
  },
);

SplittedNumericalInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.string,
  length: PropTypes.number,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
};

SplittedNumericalInput.displayName = 'SplittedNumericalInput';

export const FormSplittedNumericalInput = (props) => {
  const { name, error, onBlur, onChange, ...restProps } = props;

  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <Field name={name}>
      {({ meta }) => {
        const handleBlur = () => {
          setFieldTouched(name, true);
          onBlur?.();
        };

        const handleChange = (newValue) => {
          setFieldValue(name, newValue);
          onChange?.(newValue);
        };

        return (
          <SplittedNumericalInput
            {...restProps}
            value={meta.value}
            onBlur={handleBlur}
            onChange={handleChange}
            error={meta.touched && (error || meta.error)}
          />
        );
      }}
    </Field>
  );
};

FormSplittedNumericalInput.propTypes = {
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.string,
  length: PropTypes.number,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default SplittedNumericalInput;
