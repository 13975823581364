/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import { BLACK, PURPLE_MEDIUM } from 'styles/colors';
import { propTypeLength } from 'utils/propValidation';
import ProgressIndicatorSpinner from 'components/ProgressIndicatorSpinner';

const styles = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 24px 13px;
  border-radius: 15px;
  border: 1px solid ${PURPLE_MEDIUM};
  gap: 8px;
`;

function ProgressIndicatorSpinnerMessage({
  max,
  value,
  color,
  className,
  thickness,
  secondaryColor,
  size = '24px',
  spinningDuration,
  fontSize = '16px',
  message = 'Cargando...',
}) {
  return (
    <Text
      css={[
        styles,
        {
          gap: `calc(((${
            size + (typeof size === 'number' ? 'px' : '')
          } / 3 ) + ${
            fontSize + (typeof fontSize === 'number' ? 'px' : '')
          } / 2) / 2)`,
        },
      ]}
      color={BLACK}
      fontSize={fontSize}
      fontWeight={500}
      className={className}
      align="center"
    >
      <ProgressIndicatorSpinner
        {...{
          max,
          size,
          color,
          value,
          thickness,
          secondaryColor,
          spinningDuration,
        }}
      />
      {message}
    </Text>
  );
}

ProgressIndicatorSpinnerMessage.propTypes = {
  size: propTypeLength,
  max: PropTypes.number,
  color: PropTypes.string,
  value: PropTypes.number,
  fontSize: propTypeLength,
  message: PropTypes.string,
  className: PropTypes.string,
  thickness: PropTypes.number,
  secondaryColor: PropTypes.string,
  spinningDuration: PropTypes.number,
};

export default ProgressIndicatorSpinnerMessage;
