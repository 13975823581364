import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import Icon from 'components/Icon';
import { BLACK } from 'styles/colors';
import { ROUTES_FEEKAPP } from 'constants/routes';
import { FormInputText } from 'components/inputs/InputText';
import { ReactComponent as MagnifyingGlass } from 'assets/icons/MagnifyingGlass.svg';

export default function CampaignsSearchInput({
  className,
  placeholder,
}) {
  const navigate = useNavigate();
  const { search } = useParams();

  const submitSearch = (values) => {
    navigate(
      ROUTES_FEEKAPP.SEARCH.replace(':search', values.searchValue),
    );
  };

  return (
    <Formik
      onSubmit={submitSearch}
      initialValues={{ searchValue: search || '' }}
      validateOnMount
    >
      {() => (
        <Form className={className}>
          <FormInputText
            className="search-input"
            name="searchValue"
            placeholder={placeholder || 'Buscar'}
            icon={
              <Icon
                icon={MagnifyingGlass}
                color={BLACK}
                height={22}
              />
            }
          />
        </Form>
      )}
    </Formik>
  );
}

CampaignsSearchInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
};
