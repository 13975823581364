import moment from 'moment';

export const formatPrice = (price) => {
  return (
    '$' +
    (price > 1000
      ? Math.floor(price / 1000) + ',' + price.toString().slice(1)
      : price)
  );
};

export const formatLocation = (location) => {
  let addresString = location?.street ?? '';
  if (location?.street) {
    if (location?.exterior_number) {
      addresString += ` #${location?.exterior_number}`;
    }
    if (location.suburb) {
      addresString += `, ${location.suburb}`;
    }
    if (location.cp) {
      addresString += `, C.P. ${location.cp}`;
    }
    if (location.city) {
      addresString += `, ${location.city}`;
    }
    if (location.state) {
      addresString += `, ${location.state}`;
    }
  }
  return addresString;
};

export const formatPhone = (phone) => {
  return phone.length > 7
    ? phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')
    : phone.replace(/(\d{2})(\d{1})(\d{4})/, '$1 $2 $3');
};

export const convertGooglePlaceToAddress = (googlePlace) => {
  const placeSchema = {
    street_number: 'short_name',
    route: 'long_name',
    sublocality_level_1: 'long_name',
    premise: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'long_name',
    postal_code: 'short_name',
    country: 'long_name',
  };

  const address = {};

  googlePlace.forEach((element) => {
    element.types.forEach((elementType) => {
      if (placeSchema[elementType]) {
        address[elementType] = element[placeSchema[elementType]];
      }
    });
  });
  return address;
};

export const convert24to12hoursformat = (time) => {
  return moment(time, ['HH:mm']).format('hh:mm A');
};

export const formatNumberToShort = (num, digitCount = 4) => {
  if (!num) return 0;

  if (num < 1000) return num;

  const format =
    num < 999999
      ? { divisor: 1000, abb: 'k' }
      : { divisor: 1000000, abb: 'm' };

  return (
    Number(
      (num / format.divisor).toPrecision(digitCount),
    ).toLocaleString('es-MX') + format.abb
  );
};

export const getNumberWithUSAFormat = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};
