/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';

import APIClient from 'redux/api';
import { titlesFeekApp } from 'constants';
import handleError from 'utils/handleError';
import { GRAY_LIGHTEST } from 'styles/colors';
import { ROUTES_FEEKAPP } from 'constants/routes';
import NoDataMessage from 'components/NoDataMessage';
import TabButton from 'screens/feekApp/Agenda/TabButton';
import CardMomentSmall from 'components/cards/CardMomentSmall';
import ProgressIndicatorSpinnerMessage from 'components/ProgressIndicatorSpinnerMessage';

const styles = css`
  hr.separator {
    margin: 0;
    border: 1px solid ${GRAY_LIGHTEST};
  }

  .content {
    padding: 36px;
  }
`;

const emptyMessage = {
  Agendado: 'No tienes promociones activas en tu agenda',
  Completado: 'No tienes promociones finalizadas en tu agenda',
  Cancelado: 'No tienes promociones canceladas.',
};

export default function Promotions() {
  const [loading, setLoading] = useState(true);
  const [promotions, setPromotions] = useState({
    Agendado: null,
    Completado: null,
    Cancelado: null,
  });
  const [tabActive, setTabActive] = useState('Agendado');

  document.title = titlesFeekApp.AgendaPromotions;

  useEffect(() => {
    if (promotions[tabActive] !== null) return;

    setLoading(true);
    APIClient.getMomentsByUser({ status: tabActive })
      .then(({ data }) =>
        setPromotions((crrPromos) => ({
          ...crrPromos,
          [tabActive]: data.docs,
        })),
      )
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [tabActive]);

  return (
    <div css={styles}>
      <div className="d-flex justify-content-center">
        <TabButton
          label="Activas"
          isActive={tabActive === 'Agendado'}
          onClick={() => setTabActive('Agendado')}
        />
        <TabButton
          label="Finalizadas"
          isActive={tabActive === 'Completado'}
          onClick={() => setTabActive('Completado')}
        />
        <TabButton
          label="Canceladas"
          isActive={tabActive === 'Cancelado'}
          onClick={() => setTabActive('Cancelado')}
        />
      </div>

      <hr className="separator" />

      <div className="content">
        {loading ? (
          <ProgressIndicatorSpinnerMessage className="my-3" />
        ) : promotions?.[tabActive]?.length > 0 ? (
          promotions[tabActive].map((promotion, i) => (
            <CardMomentSmall
              className="mb-3"
              key={promotion?.campaign?._id + i}
              status={promotion?.moment?.status}
              title={promotion?.campaign?.title}
              image={promotion?.campaign?.image}
              campaignId={promotion?.campaign?._id}
              reservation_time={promotion?.moment?.reservation_time}
              feekspotName={promotion?.customer?.name}
              reservation_day={promotion?.moment?.reservation_day}
            />
          ))
        ) : (
          <NoDataMessage
            emoji={tabActive === 'Cancelado' ? '🥳' : '🥲'}
            message={emptyMessage[tabActive]}
            redirectTo={ROUTES_FEEKAPP.EXPLORE}
            buttonLabel="Explorar Promociones"
          />
        )}
      </div>
    </div>
  );
}
