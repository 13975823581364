/** @jsxImportSource @emotion/react */
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { forwardRef, useRef } from 'react';

import Text from 'components/Text';
import Icon from 'components/Icon';
import {
  RED,
  WHITE,
  PURPLE_FEEK,
  PURPLE_INACTIVE,
} from 'styles/colors';
import Button from 'components/buttons/Button';
import { forwardInputEventHandlers } from 'utils/form.utils';
import { ReactComponent as Check } from 'assets/icons/Check.svg';

const CheckboxInput = forwardRef((props, ref) => {
  const {
    checked,
    error,
    label,
    disabled,
    labelProps,
    wrapperClassName,
    ...restProps
  } = props;

  const checkboxRef = useRef('');

  const styles = css`
    .inputAndLabel {
      display: flex;
      align-items: center;
      user-select: none;
      cursor: default;

      .checkboxSurrogate {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        margin-right: 5px;
        color: ${WHITE};
        background: ${WHITE};
        border: 1px solid ${error ? RED : PURPLE_INACTIVE};
        border-radius: 6px;
        outline-offset: 3px;
      }

      &:hover .checkboxSurrogate {
        border-color: ${error ? RED : PURPLE_FEEK};
        cursor: pointer;
      }

      input {
        display: none;
        visibility: hidden;

        &:checked + .checkboxSurrogate {
          background: ${PURPLE_FEEK};
        }
      }
    }

    .error-text {
      margin: 3px 0;
      min-height: 19px;
    }
  `;

  const handleClick = () => {
    checkboxRef.current?.click();
  };
  return (
    <div css={styles} className={wrapperClassName}>
      <Button
        className="inputAndLabel"
        onClick={handleClick}
        ref={ref}
        disabled={disabled}
      >
        <input
          ref={checkboxRef}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          {...restProps}
        />
        <div className="checkboxSurrogate">
          {checked && <Icon icon={Check} color={WHITE} size={13} />}
        </div>
        {typeof label === 'string' ? (
          <Text {...labelProps}>{label}</Text>
        ) : (
          label
        )}
      </Button>

      <Text
        fontSize={14}
        lineHeight={17}
        fontWeight="500"
        color={RED}
        className="error-text"
      >
        {typeof error === 'string' ? error : ''}
      </Text>
    </div>
  );
});

export const FormCheckboxInput = (props) => {
  const { name, error, ...restProps } = props;

  return (
    <Field name={name}>
      {({ meta, field }) => (
        <CheckboxInput
          name={name}
          checked={meta.value}
          error={meta.touched && (meta.error || error)}
          {...restProps}
          {...forwardInputEventHandlers(restProps, field)}
        />
      )}
    </Field>
  );
};

CheckboxInput.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  wrapperClassName: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelProps: PropTypes.shape({ ...Text.propTypes }),
};

FormCheckboxInput.propTypes = {
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

CheckboxInput.displayName = CheckboxInput;

export default CheckboxInput;
