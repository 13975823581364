/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';

import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import Modal from 'components/modals/Modal/index';
import InputSelect from 'components/inputs/InputSelect';
import { BLACK, WHITE, GREEN_LIGHT } from 'styles/colors';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as FeekLogo } from 'assets/icons/FeekLogo.svg';
import { ReactComponent as Location } from 'assets/icons/Location.svg';
import PlaceSelectedItem from 'screens/feekApp/Landing/PlaceSelectItem';
import ButtonCurrentLocation from 'components/buttons/ButtonCurrentLocation';
import InputLocationAutoComplete from 'components/inputs/InputLocationAutoComplete';

const styles = css`
  .modalMain {
    background-color: ${GREEN_LIGHT};
    padding: 40px;
    width: 650px;
    max-width: 650px;

    .closeButton {
      margin-block-end: 20px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .logoFeek {
      margin: 0 auto;
      display: flex;
    }

    .title {
      margin: 40px auto 48px;
    }

    .subtitle {
      margin-inline: auto;
    }

    .InputLocationAutocomplete {
      margin: 40px auto 32px;

      .places-container {
        margin: 20px auto 0;
      }
    }

    .current-location-container {
      margin: 0 auto;
    }

    .circle-button {
      width: fit-content;
      height: 61px;
      padding: 24px 30px;
      margin-right: 20px;
      border-radius: 30px;
    }

    .selectInput .inputWrapper,
    .selectInput select {
      color: ${BLACK};
      height: 50px;
      padding-inline: 8px 10px;
      line-height: 50px;
      font-size: 16px;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    .modalMain {
      padding: 20px;

      .title {
        margin-block: 40px;
      }

      .InputLocationAutocomplete {
        margin-block: 20px;

        .places-container {
          margin: 20px auto 0;
        }
      }
    }
  }
`;

export default function ModalSelectLocation({
  step,
  cssProp,
  visible,
  location,
  className,
  onRequestClose,
  onLocationChanged,
  withoutCloseButton,
}) {
  const [locationSelected, setLocationSelected] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    LocationSelector,
    LocationAvailable,
    LocationNotAvailable,
    ExploreFeek,
  ];
  const CurrentStep = steps[currentStep];
  const inputRef = useRef(null);
  const handleClose = () => {
    setCurrentStep(0);
    setLocationSelected(null);
    onRequestClose?.();
  };

  useEffect(() => {
    if (step) setCurrentStep(step);
  }, [step]);

  useEffect(() => {
    if (location) {
      setLocationSelected(location);
    }
  }, [location]);

  return (
    <Modal
      className={className}
      withoutCloseButton={withoutCloseButton}
      visible={visible}
      onRequestClose={handleClose}
      cssProp={cssProp ? [styles, cssProp] : styles}
    >
      <form className="form">
        <CurrentStep
          inputRef={inputRef}
          setCurrentStep={setCurrentStep}
          locationSelected={locationSelected}
          onLocationChanged={onLocationChanged}
          setLocationSelected={setLocationSelected}
        />
      </form>
    </Modal>
  );
}

const LocationSelector = ({
  inputRef,
  setCurrentStep,
  setLocationSelected,
}) => {
  const [searchText, setSearchText] = useState('');

  return (
    <>
      {!searchText && (
        <>
          <Icon
            icon={FeekLogo}
            size={60}
            heightMobile={50}
            color={BLACK}
            className="logoFeek"
          />
          <Text
            color={BLACK}
            fontSize={40}
            lineHeight={56}
            fontSizeMobile={24}
            lineHeightMobile={28.6}
            align="center"
            className="title"
          >
            ¿En dónde quieres explorar?
          </Text>
          <Text
            color={BLACK}
            fontSize={21}
            fontSizeMobile={16}
            align="center"
            className="subtitle"
          >
            Por favor indícanos tu dirección para validar
            disponibilidad y cobertura en tu zona.
          </Text>
        </>
      )}

      <InputLocationAutoComplete
        ref={inputRef}
        onInputChange={(value) => {
          setSearchText(value);
        }}
        className="InputLocationAutocomplete"
        placeholder="Ingresa la ubicación a explorar"
        onPlaceSelected={(place) => {
          APIClient.feekLocationAvailability({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          }).then((res) => {
            setLocationSelected(place);
            setCurrentStep(res.data.valid ? 1 : 2);
          });
        }}
      />

      <ButtonCurrentLocation
        onLocationSelected={(location) => {
          APIClient.feekLocationAvailability({
            lat: location.geometry.location.lat(),
            lng: location.geometry.location.lng(),
          }).then((res) => {
            setLocationSelected(location);
            setCurrentStep(res.data.valid ? 1 : 2);
          });
        }}
      />
    </>
  );
};

const LocationAvailable = ({
  setCurrentStep,
  locationSelected,
  onLocationChanged,
}) => {
  const userId = useSelector((state) => state.feekApp.id);

  const saveLocation = async () => {
    localStorage.setItem(
      'last_location',
      JSON.stringify({
        formatted_address: locationSelected?.formatted_address,
        coordinates: [
          locationSelected.geometry.location.lng(),
          locationSelected.geometry.location.lat(),
        ],
      }),
    );

    if (userId)
      await APIClient.changeUserLocation({
        lat: locationSelected.geometry.location.lat(),
        lng: locationSelected.geometry.location.lng(),
      });

    onLocationChanged?.();
  };
  return (
    <>
      <div className="d-flex justify-content-start align-items-center mb-3">
        <Icon
          icon={Location}
          color={BLACK}
          size={22}
          className="me-1"
        />
        <Text color={BLACK} fontSize={24}>
          <b>Feek</b> está disponible en esta ubicación
        </Text>
      </div>
      <PlaceSelectedItem
        name={locationSelected?.name}
        formatted_address={locationSelected?.formatted_address}
      />
      <div className="d-flex justify-content-center mt-3 mb-4">
        <ButtonPrimary
          label="Explorar"
          className="circle-button"
          color={BLACK}
          textColor={WHITE}
          onClick={saveLocation}
        />
      </div>
      <Button
        onClick={() => {
          setCurrentStep(0);
        }}
      >
        <Text fontSize={16} color={BLACK} align="center">
          <u>Ingresar otra dirección</u>
        </Text>
      </Button>
    </>
  );
};

const LocationNotAvailable = ({
  setCurrentStep,
  locationSelected,
}) => {
  const getLocationComponent = (type) => {
    return locationSelected.address_components.find((component) =>
      component.types.includes(type),
    )?.long_name;
  };

  return (
    <>
      <div className="d-flex justify-content-start align-items-center mb-3">
        <Icon
          icon={Location}
          color={BLACK}
          size={22}
          className="me-1"
        />
        <Text color={BLACK} fontSize={24}>
          <b>Feek</b> no está disponible en esta ubicación
        </Text>
      </div>
      <PlaceSelectedItem
        name={locationSelected?.name}
        formatted_address={locationSelected?.formatted_address}
      />
      <Text
        fontSize={16}
        color={BLACK}
        align="center"
        className="my-3"
      >
        ¿Solicitar <b>Feek</b> en esta ubicación?
      </Text>
      <div className="d-flex justify-content-center mb-4">
        <ButtonPrimary
          label="Confirmar y solicitar"
          className="circle-button"
          color={BLACK}
          textColor={WHITE}
          onClick={() => {
            APIClient.feekLocationRequest({
              state: getLocationComponent(
                'administrative_area_level_1',
              ),
              exterior_number: getLocationComponent('street_number'),
              city: getLocationComponent('locality'),
              cp: getLocationComponent('postal_code'),
              suburb: getLocationComponent('sublocality'),
              street: getLocationComponent('route'),
              country: getLocationComponent('country'),
              coordinates: [
                locationSelected.geometry.location.lng(),
                locationSelected.geometry.location.lat(),
              ],
            });
            setCurrentStep(3);
          }}
        />
      </div>
      <Button
        onClick={() => {
          setCurrentStep(0);
        }}
      >
        <Text fontSize={16} color={BLACK} align="center">
          <u>Ingresar otra dirección</u>
        </Text>
      </Button>
    </>
  );
};

const ExploreFeek = ({ setCurrentStep, onLocationChanged }) => {
  const [locations, setLocations] = useState([]);
  const [locationSelected, setLocationSelected] = useState('');

  useEffect(() => {
    APIClient.getLocationFeekspots()
      .then(({ data }) => {
        setLocations(data);
      })
      .catch(handleError);
  }, []);

  const saveLocation = () => {
    const location = locations[locationSelected]?.location;
    localStorage.setItem(
      'last_location',
      JSON.stringify({
        formatted_address: location?.city,
        coordinates: [
          location.coordinates[0],
          location.coordinates[1],
        ],
      }),
    );
    onLocationChanged?.();
  };

  return (
    <>
      <Text
        fontSize={24}
        align="center"
        className="mb-2"
        color={BLACK}
      >
        ¡Tu solicitud fué enviada con éxito!
      </Text>
      <Text
        fontSize={24}
        align="center"
        className="mb-4"
        color={BLACK}
      >
        Te invitamos a explorar <b>Feek</b>
      </Text>
      <InputSelect
        className="selectInput"
        inputClassName="selectInput"
        value={locationSelected}
        placeholder="Selecciona una ciudad"
        onChange={(e) => {
          setLocationSelected(e.target.value);
        }}
        options={[
          { value: '', label: 'Seleccione una ciudad' },
          ...locations.map((location, index) => ({
            value: index,
            label: location.location.city,
          })),
        ]}
      />
      <div className="d-flex justify-content-center mb-4">
        <ButtonPrimary
          label="Explorar"
          className="circle-button"
          color={BLACK}
          textColor={WHITE}
          disabled={!locationSelected}
          onClick={saveLocation}
        />
      </div>
      <Button>
        onClick=
        {() => {
          setCurrentStep(0);
        }}
        <Text fontSize={16} color={BLACK} align="center">
          <u>Ingresar otra dirección</u>
        </Text>
      </Button>
    </>
  );
};

ModalSelectLocation.propTypes = {
  step: PropTypes.number,
  visible: PropTypes.bool,
  cssProp: PropTypes.shape({
    name: PropTypes.string,
    styles: PropTypes.string,
  }),
  location: PropTypes.object,
  className: PropTypes.string,
  onRequestClose: PropTypes.func,
  onLocationChanged: PropTypes.func,
  withoutCloseButton: PropTypes.bool,
};
LocationSelector.propTypes = {
  inputRef: PropTypes.object,
  setCurrentStep: PropTypes.func,
  setLocationSelected: PropTypes.func,
};
LocationAvailable.propTypes = {
  setCurrentStep: PropTypes.func,
  locationSelected: PropTypes.object,
  onLocationChanged: PropTypes.func,
};
LocationNotAvailable.propTypes = {
  locationSelected: PropTypes.object,
  setCurrentStep: PropTypes.func,
};
ExploreFeek.propTypes = {
  setCurrentStep: PropTypes.func,
  onLocationChanged: PropTypes.func,
};
