/** @jsxImportSource @emotion/react */
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { forwardRef, useEffect, useRef, useState } from 'react';

import Icon from 'components/Icon';
import { mergeRefs } from 'utils/react';
import Button from 'components/buttons/Button';
import { RED, BLACK, GRAY_MID } from 'styles/colors';
import { propTypeLength } from 'utils/propValidation';
import { forwardInputEventHandlers } from 'utils/form.utils';
import { ReactComponent as Eye } from 'assets/icons/Eye.svg';
import InputWrapper from 'components/inputs/InputWrapper/input';
import { ReactComponent as EyeOff } from 'assets/icons/EyeOff.svg';

const InputPassword = forwardRef((props, ref) => {
  const {
    error,
    width,
    label,
    height,
    className,
    defaultValue,
    heightMobile,
    ...restProps
  } = props;
  const innerRef = useRef(null);
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [selection, setSelection] = useState();

  useEffect(() => {
    if (selection) {
      innerRef.current.focus();
      innerRef?.current?.setSelectionRange(
        selection.start,
        selection.end,
      );
    }
  }, [selection]);

  const styles = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-inline-end: 25px;

    .input-password {
      flex: 1;
      height: 100%;
      width: 100%;
      padding: ${label
        ? '24.5px 25px 10.5px 20px'
        : '14.5px 25px 14.5px 20px'};
      color: ${BLACK};
      border: none;
      background-color: transparent;
      font-size: 16px;
      outline: none;
    }

    .icon-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .input-password:focus + .icon-button path,
    .icon-button:focus path {
      fill: ${error ? RED : BLACK};
    }
  `;

  const handleIsPasswordHidden = () => {
    setIsPasswordHidden((state) => !state);
    setSelection({
      start: innerRef.current.selectionStart,
      end: innerRef.current.selectionEnd,
    });
  };

  return (
    <InputWrapper
      height={height}
      width={width}
      label={label}
      error={error}
      className={className}
      heightMobile={heightMobile}
    >
      <div css={styles}>
        <input
          css={styles}
          defaultValue={defaultValue}
          ref={mergeRefs(ref, innerRef)}
          type={isPasswordHidden ? 'password' : 'text'}
          className="input-password"
          {...restProps}
        />
        <Button
          className="icon-button"
          tabIndex="1"
          onClick={handleIsPasswordHidden}
        >
          <Icon
            className="icon"
            size={20}
            color={error ? RED : GRAY_MID}
            icon={isPasswordHidden ? Eye : EyeOff}
          />
        </Button>
      </div>
    </InputWrapper>
  );
});

export const FormInputPassword = (props) => {
  const { height, width, name, error, heightMobile, ...restProps } =
    props;

  return (
    <Field name={name}>
      {({ meta, field }) => (
        <InputPassword
          heightMobile={heightMobile}
          height={height}
          width={width}
          name={name}
          value={meta.value}
          error={meta.touched && (meta.error || error)}
          {...restProps}
          {...forwardInputEventHandlers(restProps, field)}
        />
      )}
    </Field>
  );
};

const sharedPropTypes = {
  width: propTypeLength,
  name: PropTypes.string,
  height: propTypeLength,
  type: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  heightMobile: propTypeLength,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

FormInputPassword.propTypes = {
  ...sharedPropTypes,
};

InputPassword.propTypes = {
  defaultValue: PropTypes.string,
  ...sharedPropTypes,
};

InputPassword.displayName = 'InputPassword';
FormInputPassword.displayName = 'FormInputPassword';

export default InputPassword;
