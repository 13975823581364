/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Icon from 'components/Icon';
import Text from 'components/Text';
import { BLACK } from 'styles/colors';
import Button from 'components/buttons/Button';
import { ReactComponent as ArrowMini } from 'assets/icons/ArrowMini.svg';

const styles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const ButtonBack = ({ onClick, className }) => {
  return (
    <Button css={styles} className={className} onClick={onClick}>
      <Icon icon={ArrowMini} color={BLACK} width={10} rotate={180} />
      <Text
        color={BLACK}
        fontSize={16}
        fontWeight={400}
        lineHeight="100%"
      >
        Regresar
      </Text>
    </Button>
  );
};

ButtonBack.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ButtonBack;
