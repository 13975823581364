export function getFluidSize(minSize, maxSize, unit = 'px') {
  return `calc(${
    minSize + unit
  } + (${maxSize} - ${minSize}) * ((100vw - 375px) / (1920 - 375)))`;
}

export function getFontSizeAndLineHeigh(fontSize, unit = 'px') {
  return `font-size: ${fontSize + unit};
  line-height: ${fontSize * 1.2 + unit};`;
}

export function getLength(length) {
  /*
    length: just a length value or and object with this format
      { 
        propertyName1: rawLength1,
        propertyName2: rawLength2
      }
  */
  return length
    ? typeof length === 'object'
      ? Object.keys(length).reduce(
          (accObj, property) =>
            length[property]
              ? '\n' +
                accObj +
                property +
                ': ' +
                (typeof length[property] === 'number'
                  ? length[property] + 'px'
                  : length[property]) +
                ';'
              : accObj,
          '',
        )
      : typeof length === 'number'
      ? length + 'px'
      : length
    : null;
}

export function getColorWithAlpha(hexColor, alphaPercentage) {
  const FFFFFF_TO_INT = 16777215;
  const colorWithoutAlpha = hexColor
    ?.replace('#', '')
    .slice(0, 6)
    .padStart(6, 0);
  const colorValue = parseInt(colorWithoutAlpha, 16);

  if ((!!colorValue && colorValue > FFFFFF_TO_INT) || colorValue < 0)
    console.warn(
      'getColorWithAlpha: hexColor is not a correct hexadecimal color',
    );
  else if (alphaPercentage > 100 || alphaPercentage < 0) {
    console.warn(
      'getColorWithAlpha: alphaPercentage is not a correct percentage',
    );

    return hexColor;
  }

  return (
    '#' +
    colorWithoutAlpha +
    Math.round((alphaPercentage * 255) / 100)
      .toString(16)
      .padStart(2, 0)
  );
}
