/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import 'react-phone-input-2/lib/style.css';
import { forwardRef, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import PhoneInputComponent from 'react-phone-input-2';

import {
  RED,
  WHITE,
  GRAY_TEXT,
  RED_LIGHT,
  GRAY_LIGHT,
  PURPLE_FEEK,
  PURPLE_INACTIVE,
} from 'styles/colors';
import { defaultFont } from 'styles/fonts';
import { propTypeLength } from 'utils/propValidation';
import InputWrapper from 'components/inputs/InputWrapper/input';

const InputPhone = forwardRef((props, ref) => {
  const {
    error,
    width,
    className,
    height = 48,
    heightMobile,
    inputClassName,
    ...restProps
  } = props;
  const [isFocused, setIsFocused] = useState(false);

  const styles = css`
    width: 100%;

    .inputContainer {
      display: flex;
      flex-direction: row-reverse;
      justify-content: left;
      padding-right: 19px;
      border: none;
      color: ${GRAY_TEXT};
      font-family: ${defaultFont};
      font-size: 16px;
      border-radius: 8px;
      background-color: ${error ? RED_LIGHT : WHITE};

      .btn {
        position: static;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        border: none;
        border-radius: unset;
        border-right: 1px solid
          ${error ? RED : isFocused ? PURPLE_FEEK : PURPLE_INACTIVE};
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;

        .selected-flag {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 0;
          background-color: ${WHITE};
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;

          &:hover {
            background: none;
          }

          .flag {
            transform: scale(1.8);
          }
        }

        .dropDown {
          transform: translateY(-60px);
        }
      }

      .phoneInput {
        position: static;
        width: 100%;
        height: 100%;
        flex: 1;
        padding-left: 10px;
        font-family: ${defaultFont};
        color: ${GRAY_TEXT};
        font-size: 16px;
        border: none;
        border-radius: unset;
        background-color: ${error ? RED_LIGHT : WHITE};

        &::placeholder {
          color: ${GRAY_LIGHT};
        }
      }
    }
  `;

  const handleBlur = () => {
    setIsFocused(false);
    restProps.onBlur?.();
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <InputWrapper
      css={styles}
      width={width}
      error={error}
      height={height}
      className={className}
      heightMobile={heightMobile}
    >
      <PhoneInputComponent
        ref={ref}
        dropdownClass="dropDown"
        buttonClass="btn"
        country={'mx'}
        inputProps={{
          onFocus: handleFocus,
        }}
        inputClass="phoneInput"
        countryCodeEditable={false}
        containerClass={'inputContainer ' + inputClassName}
        {...restProps}
        onBlur={handleBlur}
      />
    </InputWrapper>
  );
});

export const FormInputPhone = (props) => {
  const { name, error, onChange, onBlur, ...restProps } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const handleChange = (newValue, country, event, formattedValue) => {
    setFieldValue(name, newValue);
    onChange?.(newValue, country, event, formattedValue);
  };

  const handleBlur = () => {
    setFieldTouched(name, true);
    onBlur?.();
  };

  return (
    <Field name={name}>
      {({ meta }) => (
        <InputPhone
          {...restProps}
          name={name}
          value={meta.value || '52'}
          error={meta.touched && (meta.error || error)}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      )}
    </Field>
  );
};

FormInputPhone.propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

InputPhone.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  width: propTypeLength,
  height: propTypeLength,
  heightMobile: propTypeLength,
};

InputPhone.displayName = 'InputPhone';

export default InputPhone;
