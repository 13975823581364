/** @jsxImportSource @emotion/react */
import moment from 'moment';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import {
  BLACK,
  WHITE,
  GRAY_MID,
  GREEN_STATUS,
  RED_NOTIFICATIONS,
} from 'styles/colors';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Button from 'components/buttons/Button';
import { ReactComponent as Check } from 'assets/icons/Check.svg';
import { ReactComponent as XMini } from 'assets/icons/XMini.svg';
import ImagePlaceholder from 'assets/images/ImagePlaceholder.jpg';
import { ReactComponent as CalendarMini } from 'assets/icons/CalendarMini.svg';

const styles = css`
  padding: 10px;
  border-radius: 15px;
  border: 1px solid ${GRAY_MID};
  text-decoration: none;

  img {
    width: 90px;
    height: 100px;
    margin-right: 10px;
    border-radius: 10px;
  }

  .title {
    padding-top: 8px;
    padding-bottom: 10px;
  }

  .circle {
    display: flex;
    min-width: 22px;
    min-height: 22px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }

  .circle.bg-green {
    background-color: ${GREEN_STATUS};
  }

  .circle.bg-red {
    background-color: ${RED_NOTIFICATIONS};
  }
`;

export default function CardMomentSmall({
  image,
  title,
  status,
  className,
  campaignId,
  feekspotName,
  reservation_day,
  reservation_time,
}) {
  return (
    <Link
      css={styles}
      to={`/campaign/${campaignId}`}
      className="text-no-decoration"
    >
      <Button className={className} layoutOnly>
        <div className="d-flex">
          <img
            src={image || ImagePlaceholder}
            alt={title}
            className="img-fluid"
          />
          <div className="d-flex flex-column justify-content-between me-2">
            <Text fontSize={14} fontWeight={600} color={BLACK}>
              {feekspotName}
            </Text>

            <Text
              fontSize={16}
              fontWeight={600}
              color={BLACK}
              className="title"
            >
              {title}
            </Text>
            <div className="d-flex align-items-center justify-content-start">
              <Icon
                size={16}
                color={BLACK}
                icon={CalendarMini}
                className="me-1"
              />

              <Text fontSize={14}>
                {`${moment(reservation_day, ['YYYY-MM-DD'])
                  .format('dddd DD MMM')
                  .replace(/(?<=\s)\w|^\w/g, (l) => l.toUpperCase())
                  .slice(0, -1)} | ${moment(
                  reservation_time,
                  'hh:mm A',
                ).format('hh:mm A')}`}
              </Text>
            </div>
          </div>
          {status === 'Completado' && (
            <div className="ms-auto my-auto circle bg-green">
              <Icon icon={Check} color={WHITE} size={12} />
            </div>
          )}
          {status === 'Cancelado' && (
            <div className="ms-auto my-auto circle bg-red">
              <Icon icon={XMini} color={WHITE} size={8} />
            </div>
          )}
        </div>
      </Button>
    </Link>
  );
}

CardMomentSmall.propTypes = {
  className: PropTypes.string,
  reservation_day: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  reservation_time: PropTypes.string,
  status: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  feekspotName: PropTypes.string.isRequired,
};
