/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import Text from 'components/Text';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import Modal from 'components/modals/Modal';
import Button from 'components/buttons/Button';
import { ROUTES_FEEKAPP } from 'constants/routes';
import { formatNumberToShort } from 'utils/format';
import { BLACK, GRAY, WHITE } from 'styles/colors';
import { ReactComponent as ZapMini } from 'assets/icons/ZapMini.svg';

const styles = css`
  inline-size: 33vw;
  max-inline-size: 500px;
  padding: 24px;
  background: ${WHITE};
  border-radius: 20px;

  .liked-feekers-list {
    margin-block-start: 20px;
    width: 100%;

    li {
      :not(:first-of-type) {
        margin-block-start: 10px;
      }

      .link {
        .wrapper-button {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 100%;

          &,
          * {
            line-height: 100%;
          }

          > :nth-of-type(2) {
            margin-left: auto;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
`;

function ModalLastLikedFeekers({
  title = 'Últimos me gusta',
  feekers,
  visible,
  onRequestClose,
  ...restProps
}) {
  return (
    <Modal
      visible={visible}
      onRequestClose={onRequestClose}
      {...restProps}
    >
      <div css={styles}>
        <Text
          align="center"
          fontSize={24}
          fontSizeMobile={20}
          fontWeight="600"
          color={BLACK}
        >
          {title}
        </Text>

        <ul className="liked-feekers-list">
          {feekers?.map((feeker) => (
            <li key={feeker._id}>
              <Link
                className="link"
                to={ROUTES_FEEKAPP.PROFILE_FEEKER.replace(
                  /:\w*/,
                  feeker.profile_username,
                )}
                target="_blank"
              >
                <Button className="wrapper-button" layoutOnly>
                  <Avatar
                    size={48}
                    image={feeker.avatar}
                    alt={feeker.profile_username + ' • Feek'}
                  />

                  <Text fontSize={16} fontWeight={600} color={BLACK}>
                    {feeker.profile_username}
                  </Text>

                  <Text
                    fontSize={16}
                    fontWeight={600}
                    lineHeight="100%"
                    color={GRAY}
                  >
                    <Icon icon={ZapMini} height={16} color={GRAY} />+
                    {formatNumberToShort(feeker.total_feek_points)}
                  </Text>
                </Button>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
}

ModalLastLikedFeekers.propTypes = {
  title: PropTypes.string,
  feekers: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      name: PropTypes.string,
      profile_username: PropTypes.string,
      total_feek_points: PropTypes.number,
      _id: PropTypes.string,
    }),
  ),
  visible: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default ModalLastLikedFeekers;
