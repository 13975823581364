/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import moment from 'moment';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  RED,
  WHITE,
  BLACK,
  GREEN,
  GREEN_B,
  GRAY_TEXT,
  RED_LIGHT,
  GRAY_LIGHT,
  PURPLE_FEEK,
  GRAY_DEFAULT,
  GRAY_LIGHTEST,
  GREEN_LIGHTER,
  GRADIENT_PURPLE,
  PURPLE_INACTIVE,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import handleError from 'utils/handleError';
import Modal from 'components/modals/Modal';
import { setFeekspots } from 'redux/entities';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_MANAGER } from 'constants/routes';
import IceCream from 'assets/images/IceCream.png';
import { ReactComponent as Info } from 'assets/icons/Info.svg';
import { FormInputText } from 'components/inputs/InputText/index';
import { ReactComponent as CheckCircle } from 'assets/icons/CheckCircle.svg';
import FeekerFlyingLinearChart from 'assets/images/FeekerFlyingLinearChart.png';

const styles = css`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 450px;
  padding: 40px 30px;
  background-color: ${WHITE};

  & :is(.header, .imagePlusInput) > img:first-of-type {
    margin-block-end: 12px;
  }

  .IceCream {
    background-color: ${WHITE};
  }

  .FeekerFlyingLinearChart {
    margin-inline: auto;
  }

  .imagePlusInput {
    margin-block-end: -22px;

    .IceCream {
      display: block;
      margin-inline: auto;
    }

    .inputWrapper {
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;

      .inputNumber {
        display: flex;
        width: 140px;
        height: 45px;
        border: 1px ${PURPLE_INACTIVE} solid;
        border-radius: 8px;
        margin: 0;
        padding: 0;

        & :where(*) {
          margin: 0;
          padding: 0;
          height: auto;
        }

        .addonCountWrapper {
          width: auto;
          border-inline: 1px ${GRAY_LIGHT} solid;

          .addonCount {
            height: 100%;
            text-align: center;
            border: none;
            border-radius: 0;
            padding: 0;
            margin: 0;
          }
        }

        .add,
        .subtract {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 1 0 40px;
          color: ${GRAY_TEXT};
          user-select: none;
        }
      }

      .error-text {
        display: none;
      }
    }

    .error {
      min-height: 19px;
      margin: 3px 0;
    }
  }

  .imagePlusAddonCount {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  .data {
    margin-inline: auto;
    width: 305px;
    text-align: left;
    font-size: 14px;
    border-collapse: collapse;

    tr {
      height: 33px;
      border-radius: 4px;
      :nth-of-type(odd) {
        background-color: ${GRAY_LIGHTEST};
      }
      th {
        padding-inline: 10px 5px;
        border-radius: 4px 0px 0px 4px;
        font-weight: 700;
      }
      td {
        border-radius: 0px 4px 4px 0px;
        padding-inline: 5px 10px;
        font-weight: 400;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 15px;

    .buttonCancel,
    .buttonAccept {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 1 185px;
      height: 46px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 600;
      user-select: none;
    }

    .buttonCancel {
      border: 2px solid ${PURPLE_FEEK};
      color: ${PURPLE_FEEK};
      background-color: ${WHITE};
    }

    .buttonAccept {
      color: ${WHITE};
      background: ${GRADIENT_PURPLE};
    }
  }

  .footNote {
    display: flex;
    gap: 10px;
  }

  .coupon-link {
    margin-bottom: 15px;
  }
  .cupon-inputWrapper {
    width: 156px;
    display: flex;
    height: 40px;
    margin: 0 auto;
    margin-bottom: 15px;
    .input {
      height: 40px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .disabled {
      opacity: 0.5;
    }

    .error-input {
      border-color: ${RED};
      background-color: ${RED_LIGHT};
    }
    .success-input {
      border-color: ${GREEN};
      background-color: ${GREEN_LIGHTER} !important;
    }

    .button {
      flex-shrink: 0;
      width: 36px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: 1px solid black;
      border-left: 0px;

      & > * {
        margin-top: -5px;
      }
    }
  }

  ${MEDIA_QUERIES.mobile} {
    width: 100%;
    padding: 40px 24px;
  }
`;

const Note = () => (
  <Text className="footNote" fontSize={11} fontWeight="500">
    <Icon icon={Info} color={GRAY_DEFAULT} size={16} />
    Las Campañas extra estarán disponibles en tu cuenta inmediatamente
    después de adquirirlas y se cobrarán en tu próxima factura
    mensual.
  </Text>
);

function Purchase({
  title,
  handleClose,
  addonsData,
  setAddonsData,
  nextStep,
  formik,
}) {
  const [stripeCoupon, setStripeCoupon] = useState(null);
  const [hasCoupon, setHasCoupon] = useState(null);

  const handeleGetCoupon = async (coupon, setSubmitting) => {
    setSubmitting(true);
    try {
      const couponResponse = await APIClient.getCoupon(coupon);
      if (couponResponse.status === 200) {
        if (couponResponse.data.valid) {
          setAddonsData({
            ...addonsData,
            coupon: couponResponse.data,
          });
        } else {
          setAddonsData({
            ...addonsData,
            coupon: undefined,
          });
        }
        setStripeCoupon({
          coupon: couponResponse.data,
          isValid: couponResponse.data.valid ?? false,
        });
      } else {
        setAddonsData({
          ...addonsData,
          coupon: undefined,
        });
        throw new Error('Hubo un error obteniendo el cupon');
      }
    } catch (error) {
      if (error instanceof Error) {
        setStripeCoupon({
          coupon: { name: error.message },
          isValid: false,
        });
        setAddonsData({
          ...addonsData,
          coupon: undefined,
        });
      } else {
        setStripeCoupon({
          coupon: { name: 'El cupón es inválido o está agotado.' },
          isValid: false,
        });
        setAddonsData({
          ...addonsData,
          coupon: undefined,
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const inputNumberRef = useRef(null);
  const purchaseCampaignshandle = () => {
    setAddonsData({
      coupon: { ...stripeCoupon },
      ...addonsData,
    });
    nextStep();
  };

  return (
    <div css={styles}>
      <header className="header">
        <Text fontSize={18} fontWeight="700" align="center">
          {title}
        </Text>

        <Text fontSize={16} fontWeight="500" align="center">
          ¿Cuántas Campañas extra deseas agregar?
        </Text>
      </header>

      <div className="imagePlusInput">
        <img
          className="IceCream"
          src={IceCream}
          alt="Imagen de un add-on representado por un helado"
          width={200}
        />

        <div>
          <div className="inputWrapper">
            <div className="inputNumber" ref={inputNumberRef}>
              <Button
                onClick={() => {
                  if (formik.values.addonCount > 1)
                    formik.setFieldValue(
                      'addonCount',
                      formik.values.addonCount - 1,
                    );
                  inputNumberRef.current
                    .getElementsByTagName('input')[0]
                    .focus();
                }}
                className="subtract"
              >
                -
              </Button>

              <FormInputText
                type="number"
                name="addonCount"
                className="addonCountWrapper"
                inputClassName="addonCount"
                min={1}
                max={9999}
                autoFocus
              />

              <Button
                onClick={() => {
                  if (formik.values.addonCount < 9999)
                    formik.setFieldValue(
                      'addonCount',
                      formik.values.addonCount + 1,
                    );
                  inputNumberRef.current
                    .getElementsByTagName('input')[0]
                    .focus();
                }}
                className="add"
              >
                +
              </Button>
            </div>

            <Text fontSize={18} fontWeight="600">
              {`${
                formik.values.addonCount > 0
                  ? Number(
                      addonsData?.price * formik.values.addonCount,
                    )
                  : 0
              } MXN`}
            </Text>
          </div>
          <Text
            fontSize={14}
            lineHeight={17}
            fontWeight="500"
            color={RED}
            align="center"
            className="error"
          >
            {formik.errors.addonCount || ''}
          </Text>
        </div>
        <Button
          onClick={() => {
            setHasCoupon(true);
          }}
        >
          <Text
            align="center"
            color={PURPLE_FEEK}
            fontSize={13}
            className="coupon-link"
          >
            ¿Tienes un cupón de descuento?
          </Text>
        </Button>
        {hasCoupon && (
          <div className="cupon-inputWrapper">
            <FormInputText
              name="coupon"
              autoComplete="off"
              inputClassName={`input ${
                stripeCoupon
                  ? stripeCoupon?.isValid
                    ? 'success-input'
                    : 'error-input'
                  : ''
              }`}
            />
            <Button
              className={
                formik.isSubmitting ||
                !formik.values.coupon ||
                formik.errors.coupon
                  ? 'button disabled'
                  : 'button'
              }
              onClick={() => {
                handeleGetCoupon(
                  formik.values.coupon,
                  formik.setSubmitting,
                );
              }}
              disabled={
                formik.isSubmitting ||
                !formik.values.coupon ||
                formik.errors.coupon
              }
            >
              <Text color={BLACK} fontSize={20}>
                →
              </Text>
            </Button>
          </div>
        )}
      </div>

      <div className="buttons">
        <Button onClick={handleClose} className="buttonCancel">
          Cancelar
        </Button>
        <Button
          onClick={purchaseCampaignshandle}
          className="buttonAccept"
          disabled={!formik.isValid}
        >
          Agregar Campañas
        </Button>
      </div>

      <Note />
    </div>
  );
}

function PurchaseConfirmation({ formik, addonsData, handleClose }) {
  const subtotal = formik.values.addonCount * addonsData?.price;
  const discount = addonsData.coupon?.valid
    ? addonsData.coupon.amount_off
      ? addonsData.coupon.amount_off / 100
      : (subtotal * addonsData.coupon.percent_off) / 100
    : 0;
  const totalIVA = (subtotal - discount) * 0.16;

  return (
    <div css={styles}>
      <header className="header">
        <Text align="center" fontSize={18} fontWeight="700">
          Confirmar compra de Campañas extra
        </Text>

        <Text align="center" fontSize={16} fontWeight="500">
          Una vez que confirmes la compra podrás hacer uso de tus
          Campañas al instante.
        </Text>
      </header>

      <div className="imagePlusAddonCount">
        <Text fontSize={18} fontWeight="700">
          {`${formik.values.addonCount} x`}
        </Text>
        <img
          className="IceCream"
          src={IceCream}
          alt="Imagen de un add-on representado por un helado"
          width={120}
        />
      </div>

      <table className="data">
        <tbody>
          <tr>
            <th>Fecha de compra</th>
            <td>{moment().format('D MMMM, YYYY')}</td>
          </tr>
          <tr>
            <th>Fecha de pago</th>
            <td>
              {moment(addonsData?.payment_date).format(
                'D MMMM, YYYY',
              )}
            </td>
          </tr>
          <tr>
            <th>Concepto</th>
            <td>{`${formik.values.addonCount} x Campañas extra`}</td>
          </tr>
          <tr>
            <th>Subtotal</th>
            <td>{`${subtotal} MXN`}</td>
          </tr>
          {addonsData.coupon?.valid && (
            <>
              <tr>
                <th>Cupón</th>
                <td>{`-${discount} MXN`}</td>
              </tr>
            </>
          )}
          <tr>
            <th>IVA</th>
            <td>{`${totalIVA} MXN`}</td>
          </tr>
          <tr>
            <th>TOTAL</th>
            <td>{`${subtotal - discount + totalIVA} MXN`}</td>
          </tr>
        </tbody>
      </table>

      <div className="buttons">
        <Button onClick={handleClose} className="buttonCancel">
          Cancelar
        </Button>
        <Button
          className="buttonAccept"
          disabled={formik.isSubmitting || !formik.isValid}
          onClick={formik.submitForm}
        >
          Confirmar
        </Button>
      </div>

      <Note />
    </div>
  );
}

function Success({ handleClose }) {
  const navigate = useNavigate();
  return (
    <div css={styles}>
      <header className="header">
        <Text align="center" fontSize={18} fontWeight="700">
          Campañas adquiridas con éxito
          <Icon icon={CheckCircle} color={GREEN_B} size={16} />
        </Text>

        <Text align="center" fontSize={16} fontWeight="500">
          Ya puedes continuar creando Campañas
        </Text>
      </header>

      <img
        className="FeekerFlyingLinearChart"
        src={FeekerFlyingLinearChart}
        alt="Un Feeker ascendiendo"
        width={280}
      />

      <div className="buttons">
        <Button onClick={handleClose} className="buttonCancel">
          Cerrar
        </Button>
        <Button
          className="buttonAccept"
          onClick={() => {
            handleClose();
            navigate(ROUTES_MANAGER.CAMPAIGNS);
          }}
        >
          Ir a Campañas
        </Button>
      </div>
    </div>
  );
}

const formValidationSchema = Yup.object().shape({
  addonCount: Yup.number()
    .required('El número de add-ons es requerido')
    .min(1, 'El mínimo de campañas extras es 1')
    .max(9999, '¡No puedes comprar más de 9999 campañas!'),
});

const formInitialValues = {
  addonCount: 1,
};

function ModalAddOnPurchase({ show, handleClose, title }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [addonsData, setAddonsData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const feekspots = useSelector((state) => state.entities.feekspots);
  const selectedFeekspotId = useSelector(
    (state) => state.managerApp.selectedFeekspotId,
  );
  const { places, plan_id } = feekspots[selectedFeekspotId].customer;

  const purchaseSteps = [Purchase, PurchaseConfirmation, Success];
  const CurrentPurchaseSteps = purchaseSteps[currentStep];

  async function handleFormSubmit(values, actions) {
    actions.setSubmitting(true);
    APIClient.postAddOns({
      add_on_total: values.addonCount,
      add_on_id: addonsData.addonId,
      customer_id: selectedFeekspotId,
      coupon: addonsData?.coupon?.id,
    })
      .then(() => {
        APIClient.updateFeekspot(
          {
            places: places + values.addonCount,
          },
          selectedFeekspotId,
        ).then(() => {
          APIClient.getFeekspotsByCustomer().then(({ data }) => {
            const feekspots = data.reduce(
              (prev, crr) => ({ ...prev, [crr.customer._id]: crr }),
              {},
            );
            dispatch(setFeekspots(feekspots));
          });
        });

        actions.resetForm({ formInitialValues });
        setCurrentStep((crrStep) => crrStep + 1);
      })
      .catch((error) =>
        toast.error(
          error instanceof Error
            ? error.message
            : 'Error agregando las campañas',
        ),
      )
      .finally(() => actions.setSubmitting(false));
  }

  useEffect(() => {
    if (!show) return;

    if (!plan_id) {
      handleClose();
      toast.error(
        'No se pueden adquirir add-ons sin tener una suscripción activa',
      );
      navigate(ROUTES_MANAGER.PAYMENT);
    } else {
      APIClient.getAddOns(selectedFeekspotId)
        .then(({ data }) =>
          setAddonsData({
            addonId: data[0]._id,
            price: data[0].price,
            taxes: data[0].taxes,
            payment_date: data[0].payment_date,
          }),
        )
        .catch((error) => handleError(error));
    }
  }, [show, plan_id]);

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
    >
      {(formik) => {
        function handleCloseAugmented() {
          formik.resetForm();
          handleClose();
          setAddonsData({
            ...addonsData,
            coupon: undefined,
          });
          setCurrentStep(0);
        }

        return (
          <Modal visible={show} onRequestClose={handleCloseAugmented}>
            <Form>
              <CurrentPurchaseSteps
                handleClose={handleCloseAugmented}
                addonsData={addonsData}
                setAddonsData={setAddonsData}
                title={title}
                formik={formik}
                nextStep={() => setCurrentStep(1)}
              />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}

Purchase.propTypes = {
  title: PropTypes.string,
  nextStep: PropTypes.func,
  formik: PropTypes.object,
  addonsData: PropTypes.object,
  setAddonsData: PropTypes.func,
  handleClose: PropTypes.func,
};

PurchaseConfirmation.propTypes = {
  handleClose: PropTypes.func,
  formik: PropTypes.object,
  addonsData: PropTypes.object,
};

Success.propTypes = {
  handleClose: PropTypes.func,
};

ModalAddOnPurchase.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
};

export default ModalAddOnPurchase;
