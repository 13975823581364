/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import algoliasearch from 'algoliasearch';
import { useParams } from 'react-router-dom';
import { Configure, InstantSearch } from 'react-instantsearch-hooks';

import HitsResults from 'screens/feekApp/Search/HitsResults';
import RefinementList from 'screens/feekApp/Search/RefinementList';
import CampaignFilters from 'screens/feekApp/Search/CampaignFilters';

const algoliaClient = algoliasearch(
  '54Q3EEQXPZ',
  process.env.REACT_APP_ALGOLIA_API_KEY || '',
);

const styles = css`
  margin: 0 70px;
  margin-top: 70px;

  .cursor-pointer {
    cursor: pointer;
    user-select: none;
  }
`;

export default function Search() {
  const { search } = useParams();

  document.title = `Resultados para '${search || ''}' | Feek`;

  const lastSelectedLocation = JSON.parse(
    localStorage.getItem('last_location'),
  );

  return (
    <div css={styles}>
      <InstantSearch
        searchClient={algoliaClient}
        indexName={
          process.env.REACT_APP_CAMPAIGNS_SEARCH_INDEX ||
          'dev_campaigns'
        }
      >
        <Configure
          analytics
          clickAnalytics
          aroundRadius={50000}
          aroundLatLng={`${lastSelectedLocation.coordinates[1]},${lastSelectedLocation.coordinates[0]}`}
        />

        <div className="row w-100">
          <div className="col-12 col-md-4 col-lg-3 mb-4">
            <HitsResults />
            <CampaignFilters />
          </div>
          <div className="col-12 col-md-8 mb-4 col-lg-9 ps-md-4">
            <RefinementList />
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}
