/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_FEEKAPP } from 'constants/routes';
import Modal from 'components/modals/Modal/index';
import InputSelect from 'components/inputs/InputSelect';
import { BLACK, WHITE, PURPLE_LIGHT } from 'styles/colors';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as FeekLogo } from 'assets/icons/FeekLogo.svg';
import { ReactComponent as Location } from 'assets/icons/Location.svg';
import PlaceSelectedItem from 'screens/feekApp/Landing/PlaceSelectItem';
import ButtonCurrentLocation from 'components/buttons/ButtonCurrentLocation';
import InputLocationAutoComplete from 'components/inputs/InputLocationAutoComplete';

const styles = css`
  .modalMain {
    background-color: ${PURPLE_LIGHT};
    padding: 40px;
    width: 650px;
    max-width: 650px;

    .closeButton {
      margin-block-end: 20px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .logoFeek {
      margin: 0 auto;
      display: flex;
    }

    .title {
      margin: 40px auto 48px;
    }

    .subtitle {
      margin-inline: auto;
    }

    .InputLocationAutocomplete {
      margin: 40px auto 32px;

      .places-container {
        margin: 20px auto 0;
      }
    }

    .current-location-container {
      margin: 0 auto;
    }

    .circle-button {
      width: fit-content;
      height: 61px;
      padding: 24px 30px;
      margin-right: 20px;
      border-radius: 30px;
    }

    .selectInput .inputWrapper,
    .selectInput select {
      color: ${BLACK};
      height: 50px;
      padding-inline: 8px 10px;
      line-height: 50px;
      font-size: 16px;
    }
  }

  ${MEDIA_QUERIES.mobile} {
    .modalMain {
      padding: 20px;

      .title {
        margin-block: 40px;
      }

      .InputLocationAutocomplete {
        margin-block: 20px;

        .places-container {
          margin: 20px auto 0;
        }
      }
    }
  }
`;

export default function ModalRequestLocation({ show, handleClose }) {
  const [locationSelected, setLocationSelected] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    LocationSelector,
    LocationAvailable,
    VerifyLocation,
    ExploreFeek,
  ];
  const CurrentStep = steps[currentStep];

  const inputRef = useRef(null);

  return (
    <Modal
      cssProp={styles}
      visible={show}
      onRequestClose={handleClose}
    >
      <form>
        <CurrentStep
          inputRef={inputRef}
          setCurrentStep={setCurrentStep}
          locationSelected={locationSelected}
          setLocationSelected={setLocationSelected}
        />
      </form>
    </Modal>
  );
}

const LocationSelector = ({
  inputRef,
  setCurrentStep,
  setLocationSelected,
}) => {
  const [searchText, setSearchText] = useState('');
  return (
    <>
      {!searchText && (
        <>
          <Icon
            icon={FeekLogo}
            size={60}
            color={BLACK}
            className="logoFeek"
          />
          <Text
            color={BLACK}
            fontSize={40}
            lineHeight={56}
            align="center"
            className="title"
            fontSizeMobile={24}
            lineHeightMobile={33.6}
          >
            Live it, share it, <b>Feek</b> it!
          </Text>
          <Text
            color={BLACK}
            fontSize={24}
            fontSizeMobile={16}
            align="center"
            className="subtitle"
          >
            Solicita <b>Feek</b> en tu ciudad
          </Text>
        </>
      )}
      <InputLocationAutoComplete
        ref={inputRef}
        onInputChange={(value) => {
          setSearchText(value);
        }}
        className="InputLocationAutocomplete"
        placeholder="Ingresa la dirección en donde quieras solicitar Feek"
        onPlaceSelected={(place) => {
          APIClient.feekLocationAvailability({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          }).then((res) => {
            setLocationSelected(place);
            setCurrentStep(res.data.valid ? 1 : 2);
          });
        }}
      />
      <ButtonCurrentLocation
        onLocationSelected={(location) => {
          APIClient.feekLocationAvailability({
            lat: location.geometry.location.lat(),
            lng: location.geometry.location.lng(),
          }).then((res) => {
            setLocationSelected(location);
            setCurrentStep(res.data.valid ? 1 : 2);
          });
        }}
      />
    </>
  );
};

const LocationAvailable = ({ locationSelected, setCurrentStep }) => {
  const navigate = useNavigate();
  const saveLocation = () => {
    localStorage.setItem(
      'last_location',
      JSON.stringify({
        formatted_address: locationSelected?.formatted_address,
        coordinates: [
          locationSelected.geometry.location.lng(),
          locationSelected.geometry.location.lat(),
        ],
      }),
    );
    navigate(ROUTES_FEEKAPP.EXPLORE);
  };

  return (
    <>
      <div className="d-flex justify-content-start align-items-center mb-3">
        <Icon
          icon={Location}
          color={BLACK}
          size={22}
          className="me-1"
        />
        <Text color={BLACK} fontSize={24}>
          <b>Feek</b> está disponible en esta ubicación
        </Text>
      </div>
      <PlaceSelectedItem
        name={locationSelected?.name}
        formatted_address={locationSelected?.formatted_address}
      />
      <div className="d-flex justify-content-center mt-3 mb-4">
        <ButtonPrimary
          label="Explorar"
          className="circle-button"
          color={BLACK}
          textColor={WHITE}
          onClick={saveLocation}
        />
      </div>
      <Button
        onClick={() => {
          setCurrentStep(0);
        }}
      >
        <Text fontSize={16} color={BLACK} align="center">
          <u>Ingresar otra dirección</u>
        </Text>
      </Button>
    </>
  );
};
const VerifyLocation = ({ setCurrentStep, locationSelected }) => {
  const getLocationComponent = (type) => {
    return locationSelected.address_components.find((component) =>
      component.types.includes(type),
    )?.long_name;
  };

  return (
    <>
      <div className="d-flex justify-content-start align-items-center mb-3">
        <Icon
          icon={Location}
          color={BLACK}
          size={22}
          className="me-1"
        />
        <Text color={BLACK} fontSize={24}>
          Verifica la ubicación
        </Text>
      </div>
      <PlaceSelectedItem
        name={locationSelected?.name}
        formatted_address={locationSelected?.formatted_address}
      />

      <div className="d-flex justify-content-center my-4">
        <ButtonPrimary
          label="Confirmar y solicitar"
          className="circle-button"
          color={BLACK}
          textColor={WHITE}
          onClick={() => {
            APIClient.feekLocationRequest({
              state: getLocationComponent(
                'administrative_area_level_1',
              ),
              exterior_number: getLocationComponent('street_number'),
              city: getLocationComponent('locality'),
              cp: getLocationComponent('postal_code'),
              suburb: getLocationComponent('sublocality'),
              street: getLocationComponent('route'),
              country: getLocationComponent('country'),
              coordinates: [
                locationSelected.geometry.location.lng(),
                locationSelected.geometry.location.lat(),
              ],
            });
            setCurrentStep(3);
          }}
        />
      </div>
      <Text
        fontSize={16}
        color={BLACK}
        align="center"
        className="cursor-pointer"
        onClick={() => {
          setCurrentStep(0);
        }}
      >
        <u>Ingresar otra dirección</u>
      </Text>
    </>
  );
};

const ExploreFeek = ({ setCurrentStep }) => {
  const [citySelected, setCitySelected] = useState('');
  return (
    <>
      <Text
        fontSize={24}
        align="center"
        className="mb-2"
        color={BLACK}
      >
        ¡Tu solicitud fué enviada con éxito!
      </Text>
      <Text
        fontSize={24}
        align="center"
        className="mb-4"
        color={BLACK}
      >
        Te invitamos a explora <b>Feek</b>
      </Text>
      <InputSelect
        className="selectInput"
        inputClassName="selectInput"
        value={citySelected}
        placeholder="Selecciona una ciudad"
        onChange={(e) => {
          setCitySelected(e.target.value);
        }}
        options={[
          { value: '', label: 'Seleccione una ciudad' },
          { value: 'monterrey', label: 'Monterrey' },
          { value: 'saltillo', label: 'Saltillo' },
          { value: 'cdmx', label: 'CDMX' },
        ]}
      />
      <div className="d-flex justify-content-center mb-4">
        <ButtonPrimary
          label="Explorar"
          className="circle-button"
          color={BLACK}
          textColor={WHITE}
          disabled={!citySelected}
          onClick={() => {}}
        />
      </div>
      <Text
        fontSize={16}
        color={BLACK}
        align="center"
        className="cursor-pointer"
        onClick={() => {
          setCurrentStep(0);
        }}
      >
        <u>Ingresar otra dirección</u>
      </Text>
    </>
  );
};

ModalRequestLocation.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.bool,
};
LocationSelector.propTypes = {
  inputRef: PropTypes.object,
  setCurrentStep: PropTypes.func,
  setLocationSelected: PropTypes.func,
};
LocationAvailable.propTypes = {
  locationSelected: PropTypes.object,
  setCurrentStep: PropTypes.func,
};
VerifyLocation.propTypes = {
  locationSelected: PropTypes.object,
  setCurrentStep: PropTypes.func,
};
ExploreFeek.propTypes = {
  setCurrentStep: PropTypes.func,
};
