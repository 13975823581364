/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import 'react-phone-input-2/lib/style.css';
import { forwardRef, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import PhoneInputComponent from 'react-phone-input-2';

import {
  RED,
  WHITE,
  GRAY_TEXT,
  RED_LIGHT,
  GRAY_LIGHT,
  PURPLE_FEEK,
  PURPLE_INACTIVE,
} from 'styles/colors';
import Text from 'components/Text';
import { defaultFont } from 'styles/fonts';
import { propTypeLength } from 'utils/propValidation';
import { getLength } from 'utils/cssStringManipulation';

const PhoneInput = forwardRef((props, ref) => {
  const {
    error,
    width,
    className,
    height = 60,
    inputClassName,
    ...restProps
  } = props;
  const [isFocused, setIsFocused] = useState(false);

  const styles = css`
    width: 100%;

    .error-text {
      min-height: 19px;
      margin: 3px 0;
    }

    .inputContainer {
      display: flex;
      flex-direction: row-reverse;
      justify-content: left;
      ${getLength({ height, width })}
      padding-right: 19px;
      border: 1px solid
        ${error ? RED : isFocused ? PURPLE_FEEK : PURPLE_INACTIVE};
      color: ${GRAY_TEXT};
      font-family: ${defaultFont};
      font-size: 16px;
      border-radius: 16px;
      background-color: ${error ? RED_LIGHT : WHITE};

      .btn {
        position: static;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        border: none;
        border-radius: unset;
        border-right: 1px solid
          ${error ? RED : isFocused ? PURPLE_FEEK : PURPLE_INACTIVE};
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;

        .selected-flag {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 0;
          background-color: ${WHITE};
          border-top-left-radius: 16px;
          border-bottom-left-radius: 16px;

          &:hover {
            background: none;
          }

          .flag {
            transform: scale(1.8);
          }
        }

        .dropDown {
          transform: translateY(-60px);
        }
      }

      .phoneInput {
        position: static;
        width: 100%;
        height: 100%;
        flex: 1;
        padding-left: 10px;
        font-family: ${defaultFont};
        color: ${GRAY_TEXT};
        font-size: 16px;
        border: none;
        border-radius: unset;
        background-color: ${error ? RED_LIGHT : WHITE};

        &::placeholder {
          color: ${GRAY_LIGHT};
        }
      }
    }
  `;

  const handleBlur = () => {
    setIsFocused(false);
    restProps.onBlur?.();
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  return (
    <div ref={ref} css={styles} className={className}>
      <PhoneInputComponent
        dropdownClass="dropDown"
        buttonClass="btn"
        country={'mx'}
        inputProps={{
          onFocus: handleFocus,
        }}
        inputClass="phoneInput"
        countryCodeEditable={false}
        containerClass={'inputContainer ' + inputClassName}
        {...restProps}
        onBlur={handleBlur}
      />
      <Text
        fontSize={14}
        lineHeight={17}
        fontWeight="500"
        color={RED}
        className="error-text"
      >
        {typeof error === 'string' ? error : ''}
      </Text>
    </div>
  );
});

export const FormPhoneInput = (props) => {
  const { name, error, onChange, onBlur, ...restProps } = props;
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const handleChange = (newValue, country, event, formattedValue) => {
    setFieldValue(name, newValue);
    onChange?.(newValue, country, event, formattedValue);
  };

  const handleBlur = () => {
    setFieldTouched(name, true);
    onBlur?.();
  };

  return (
    <Field name={name}>
      {({ meta }) => (
        <PhoneInput
          {...restProps}
          name={name}
          value={meta.value || '52'}
          error={meta.touched && (meta.error || error)}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      )}
    </Field>
  );
};

FormPhoneInput.propTypes = {
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

PhoneInput.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  width: propTypeLength,
  height: propTypeLength,
};

PhoneInput.displayName = 'PhoneInput';

export default PhoneInput;
