/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import Text from 'components/Text';
import { BLACK, PURPLE_MEDIUM, WHITE } from 'styles/colors';
import ButtonPrimary from 'components/buttons/ButtonPrimary';

const styles = css`
  display: flex;
  padding: 24px 13px;
  align-items: center;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  border: 1px solid ${PURPLE_MEDIUM};

  .message {
    margin-bottom: 12px;
    padding-bottom: 6px;
  }
`;

export default function NoDataMessage({
  emoji,
  message,
  className,
  redirectTo,
  buttonLabel,
}) {
  return (
    <div css={styles} className={className}>
      <Text fontSize={18} className="emoji-font">
        {emoji}
      </Text>
      {typeof message === 'string' ? (
        <Text color={BLACK} fontSize={16} className="message">
          {message}
        </Text>
      ) : (
        message
      )}

      {buttonLabel && (
        <Link to={redirectTo}>
          <ButtonPrimary
            pillShape
            layoutOnly
            color={BLACK}
            textColor={WHITE}
            fontSizeMobile={14}
            label={buttonLabel}
          />
        </Link>
      )}
    </div>
  );
}

NoDataMessage.propTypes = {
  className: PropTypes.string,
  emoji: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element,
  ]),
  redirectTo: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};
