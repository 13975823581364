/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  GRAY,
  WHITE,
  BLACK,
  PURPLE_FEEK,
  GRAY_LIGHTEST,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Avatar from 'components/Avatar';
import handleError from 'utils/handleError';

const styles = css`
  display: flex;
  align-items: center;
  gap: 36px;
  justify-content: center;

  .ranking-item {
    width: 130px;
    display: flex;
    padding: 24px 0;
    border-radius: 20px;
    align-items: center;
    flex-direction: column;
    border: 1px solid ${GRAY};
  }

  .ranking-item.active {
    width: 160px;
    border: none;
    background-color: ${PURPLE_FEEK};
  }

  .ranking-item .img-container {
    width: 64px;
    height: 64px;
    display: flex;
    border-radius: 11px;
    margin: 22px 0px;
    align-items: center;
    justify-content: center;
    border: 2px solid ${GRAY_LIGHTEST};
  }
  .ranking-item.active .img-container {
    width: 94px;
    height: 94px;
    margin: 24px 0px;
  }
  .avatar {
    border-radius: 9px;
    background-color: ${WHITE};
  }
`;

export default function Ranking({ experienceId }) {
  const [ranking, setRanking] = useState([]);

  const { instagramUserName } = useSelector((state) => state.feekApp);

  useEffect(() => {
    APIClient.getExperienceUserRanking(experienceId)
      .then(({ data }) => {
        setRanking(data.slice(0, 3));
      })
      .catch(handleError);
  }, [experienceId]);

  return (
    <div css={styles}>
      {ranking.map((item) => {
        const isCurrentUser = instagramUserName === item.username;
        return (
          <div
            key={item._id}
            className={`ranking-item ${isCurrentUser && 'active'}`}
          >
            <Text
              fontWeight={700}
              fontSize={16}
              color={isCurrentUser ? WHITE : BLACK}
            >
              {item.statistics.ranking}º
            </Text>
            <div className="img-container">
              <Avatar
                size={isCurrentUser ? 86 : 56}
                className="avatar"
                image={item.avatar}
              />
            </div>
            <Text fontSize={14} color={isCurrentUser ? WHITE : BLACK}>
              {item.statistics.participations}{' '}
              {item.statistics.participations === 1
                ? 'Promoción'
                : 'Promociones'}
            </Text>
            <Text
              fontSize={12}
              fontWeight={700}
              color={isCurrentUser ? WHITE : GRAY}
            >
              @{item.username}
            </Text>
          </div>
        );
      })}
    </div>
  );
}

Ranking.propTypes = {
  experienceId: PropTypes.string.isRequired,
};
