/** @jsxImportSource @emotion/react */
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import 'rc-time-picker/assets/index.css';
import { useState, useRef } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import makeAnimated from 'react-select/animated';
import { useSearchParams } from 'react-router-dom';

import {
  RED,
  WHITE,
  GRAY_TEXT,
  RED_LIGHT,
  GRAY_DARK,
  GRAY_LIGHT,
  PURPLE_FEEK,
} from 'styles/colors';
import APIClient from 'redux/api';
import Text from 'components/Text';
import Icon from 'components/Icon';
import handleError from 'utils/handleError';
import { hoursSelectValues } from 'constants';
import { MEDIA_QUERIES } from 'styles/constants';
import CheckBoxInput from 'components/inputs/CheckBox';
import { FormInputText } from 'components/inputs/InputText';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { FormInputSelect } from 'components/inputs/InputSelect';
import { ReactComponent as Upload } from 'assets/icons/Upload.svg';
import CampaignPreviewCard from 'screens/manager/legacy/AddCampaign/CampaignPreviewCard';

const options = [
  { value: 'monday', label: 'Lunes' },
  { value: 'tuesday', label: 'Martes' },
  { value: 'wednesday', label: 'Miércoles' },
  { value: 'thursday', label: 'Jueves' },
  { value: 'friday', label: 'Viernes' },
  { value: 'saturday', label: 'Sábado' },
  { value: 'sunday', label: 'Domingo' },
];

const styles = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 96px;

  .inputWrapper:not(:last-of-type) {
    margin: 0 0 60px 0;
  }

  .labelTitle {
    margin: 20px 0 10px 0;
  }

  .labelTitleLittle {
    margin: 0;
    display: flex;
    align-items: center;
  }

  .hoursInputsWrapper {
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
  }

  .hourPickerSelector {
    margin: 0 10px;
    width: 120px;
    height: 32px;
  }

  .selectImageContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .imageTip {
    margin-block-end: 15px;
  }

  .selectedImage {
    border-radius: 4px;
    height: 74px;
    width: 236px;
    object-fit: cover;
    object-position: top;
  }

  .uploadBtn {
    height: 38px;
    width: max-content;
    border-radius: 12px;
  }

  .error-text {
    min-height: 19px;
    margin: 3px 0;
  }

  @keyframes fadeIn {
    from {
      opacity: 0.5;
    }
  }

  .loading {
    animation: fadeIn 1s infinite alternate;
  }

  .select,
  .dateInput {
    max-width: 350px;
  }
  ${MEDIA_QUERIES.mobile} {
    grid-template-columns: 1fr;
    gap: 30px;

    .labelTitle {
      margin: 0 0 10px 0;
    }

    .inputWrapper:not(:last-of-type) {
      margin: 0 0 30px 0;
    }

    .selectImageContainer {
      flex-direction: column;
      align-items: start;
    }

    .selectedImage {
      width: 100%;
      height: unset;
      aspect-ratio: 5/1;
    }
  }
`;

const customStyles = {
  menu: (base) => ({
    ...base,
    color: GRAY_TEXT,
    borderRadius: '8px',
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  menuPortal: (base) => ({
    ...base,
    border: '3px solid green',
  }),
  multiValue: (base) => ({
    ...base,
    color: GRAY_TEXT,
    background: WHITE,
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValueRemove: (base) => ({
    ...base,
    '&:hover': {
      backgroundColor: PURPLE_FEEK,
      color: WHITE,
      cursor: 'pointer',
    },
  }),
  option: (base, state) => ({
    ...base,
    background: WHITE,
    color:
      state.isSelected || state.isFocused ? GRAY_LIGHT : GRAY_TEXT,
    borderRadius: '8px',
    '&:hover': {
      background: PURPLE_FEEK,
      color: WHITE,
    },
    '&:active': {
      background: WHITE,
      color: PURPLE_FEEK,
    },
  }),
};

const animatedComponents = makeAnimated();

function Step4Reservation() {
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext();
  const [isLoading, setIsLoading] = useState(false);

  const selectedFeekspotId = useSelector(
    (state) => state.managerApp.selectedFeekspotId,
  );
  const feekspots = useSelector((state) => state.entities.feekspots);
  const selectedFeekspot = feekspots[selectedFeekspotId]?.customer;

  const [isUseWorkingDaysEnabled, setIsUseWorkingDaysEnabled] =
    useState(false);

  const inputFile = useRef();

  const openFileBrowser = (event) => {
    event.stopPropagation();
    inputFile.current.click();
  };

  const [searchParams] = useSearchParams();

  const isClone = !!searchParams.get('clone_id');

  const isEditing = !!searchParams.get('update_id');

  const handleChangeImage = async (event, setFieldTouched) => {
    event.stopPropagation();
    const file = event.target.files[0];
    setIsLoading(true);

    try {
      const errorMessage = !file?.type?.includes('image')
        ? 'El archivo no es una imagen'
        : file?.size > 5 * 10 ** 6
        ? 'La imagen pesa más de 5mb'
        : null;
      if (errorMessage) {
        setFieldTouched('image');

        throw new Error(errorMessage || 'Hubo un error inesperado');
      }

      const res = await APIClient.uploadCampaignFile(file);
      if (res.status === 201) {
        setFieldValue('image', res.data.Location);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleError(error);
    }
  };

  return (
    <div css={styles}>
      <div className="column">
        <div className="inputWrapper">
          <Text fontSize={14} fontWeight="500" className="labelTitle">
            Fecha de inicio de campaña
          </Text>
          <FormInputText
            type="date"
            name="startDate"
            inputClassName="dateInput"
            min={moment().format('YYYY-MM-DD')}
            disabled={isEditing && !isClone}
          />
        </div>

        <div className="inputWrapper">
          <Text fontSize={14} fontWeight="500" className="labelTitle">
            Días activos de la campaña
          </Text>

          <CheckBoxInput
            checked={isUseWorkingDaysEnabled}
            onChange={() => {
              setIsUseWorkingDaysEnabled((crr) => !crr);
              setFieldValue(
                'days',
                selectedFeekspot?.days?.map((day) => day.day),
              );
            }}
            label="Usar los días de mi negocio"
            labelProps={{
              fontSize: 14,
              fontWeight: '500',
              className: styles.labelTitleLittle,
              color: GRAY_DARK,
            }}
          />

          {!isUseWorkingDaysEnabled && (
            <>
              <Text
                fontSize={14}
                fontWeight="500"
                className="labelTitle"
              >
                Seleccionar días
              </Text>
              <Select
                components={animatedComponents}
                isMulti={true}
                value={options.filter((day) =>
                  values.days?.includes(day.value),
                )}
                onChange={(value) =>
                  setFieldValue(
                    'days',
                    value.map((day) => day.value),
                  )
                }
                onBlur={() => setFieldTouched('days')}
                blurInputOnSelect={false}
                placeholder="Seleccionar..."
                allowSelectAll={true}
                className="select"
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={options}
                styles={{
                  ...customStyles,
                  control: (base, state) => ({
                    ...base,
                    color:
                      touched?.days && errors?.days ? RED : GRAY_TEXT,
                    background:
                      errors?.days && touched?.days
                        ? RED_LIGHT
                        : WHITE,
                    minHeight: '40px',
                    border:
                      '1px solid ' +
                      (errors?.days && touched?.days
                        ? RED
                        : state.isFocused || state.isSelected
                        ? PURPLE_FEEK
                        : GRAY_LIGHT),
                    boxShadow: 'none',
                    borderRadius: '8px',
                    '&:hover': {
                      borderColor:
                        errors?.days && touched?.days
                          ? RED
                          : PURPLE_FEEK,
                    },
                  }),
                }}
              />
              <Text
                fontSize={14}
                lineHeight={17}
                fontWeight="500"
                color={RED}
                className="error-text"
              >
                {(touched?.days && errors?.days) || ''}
              </Text>
            </>
          )}
        </div>

        <div className="inputWrapper">
          <Text fontSize={14} fontWeight="500" className="labelTitle">
            Horario activo de la campaña
          </Text>

          <div className="hoursInputsWrapper">
            De
            <FormInputSelect
              name="startTime"
              options={hoursSelectValues}
              className="hourPickerSelector"
              inputClassName="test"
            />
            a
            <FormInputSelect
              name="endTime"
              options={hoursSelectValues}
              className="hourPickerSelector"
            />
          </div>
        </div>
      </div>

      <div className="column">
        <div className="inputWrapper">
          <Text fontSize={14} fontWeight="500" className="labelTitle">
            Imagen de promoción
          </Text>
          <Text
            fontSize={12}
            lineHeight={17}
            className="imageTip"
            color={GRAY_DARK}
          >
            Agregar una imagen de tu negocio que concuerde con la
            promocion creada. Recomendamos que sea algo atractivo para
            tus Feekers <br /> (Máximo 5 Mb)
          </Text>

          <input
            type="file"
            id="file"
            accept=".png, .jpg, .jpeg"
            onChange={(e) => handleChangeImage(e, setFieldTouched)}
            onClick={(event) => {
              event.stopPropagation();
            }}
            ref={inputFile}
            hidden={true}
          />
          <div className="selectImageContainer">
            {values.image && (
              <img
                alt="Imagen de campaña"
                src={values.image}
                className="selectedImage"
              />
            )}
            <ButtonPrimary
              strokeVariant
              disabled={isLoading}
              iconPosition="right"
              className="uploadBtn"
              fontSize={14}
              onClick={openFileBrowser}
              label={`${
                values.image ? 'Cambiar' : 'Seleccionar'
              } Imagen`}
              icon={<Icon icon={Upload} color={PURPLE_FEEK} />}
            />
          </div>
        </div>

        <div className="inputWrapper">
          <Text fontSize={14} fontWeight="500" className="labelTitle">
            Previsualización
          </Text>
          <CampaignPreviewCard
            values={values}
            {...(isLoading && { className: 'loading' })}
          />
          <Text
            fontSize={14}
            lineHeight={17}
            fontWeight="500"
            color={RED}
            className="error-text"
          >
            {(touched?.image && errors?.image) || ''}
          </Text>
        </div>
      </div>
    </div>
  );
}

Step4Reservation.propTypes = {
  setIsNextButtonDisabled: PropTypes.func,
  values: PropTypes.object,
  setChange: PropTypes.func,
  handleError: PropTypes.func,
};

export default Step4Reservation;
