/** @jsxImportSource @emotion/react */
import moment from 'moment';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  WHITE,
  PURPLE_FEEK,
  GRAY_LIGHTER,
  PURPLE_INACTIVE_LIGHT,
} from 'styles/colors';
import Text from 'components/Text';
import Avatar from 'components/Avatar';
import Modal from 'components/modals/Modal';
import CampaignConstrains from 'screens/manager/legacy/Campaigns/CampaignConstrains';

const styles = css`
  width: 420px;
  background-color: ${WHITE};
  border-radius: 8px;

  .campaignDetails {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;

    .campaignStatus {
      display: flex;
      align-items: center;
      border-radius: 4px;
      background-color: ${PURPLE_INACTIVE_LIGHT};
      padding: 0 5px;
      width: fit-content;
      height: 25px;
    }

    .campaignName {
      height: fit-content;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .momentsList {
    height: 257px;
    padding: 20px;
    background-color: ${GRAY_LIGHTER};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: auto;

    table {
      width: 100%;
      border-spacing: 0 10px;
      margin-top: -10px;

      .feekerData {
        display: flex;
        align-items: center;
        width: 160px;
        gap: 20px;

        .username {
          width: 110px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .day {
        text-transform: capitalize;
      }

      td {
        height: 30px;
      }
    }
  }
`;

function CampaignBookingOverviewModal({
  isOpen,
  moments,
  handleClose,
  campaignName,
  campaignStatus,
  campaignEndTime,
  campaignTypeIcon,
  campaignTypeName,
  campaignStartDate,
  campaignStartTime,
}) {
  return (
    <Modal visible={isOpen} onRequestClose={handleClose}>
      <div css={styles}>
        <div className="campaignDetails">
          <Text
            color={PURPLE_FEEK}
            fontSize={12}
            lineHeight={15}
            className="campaignStatus"
          >
            {campaignStatus}
          </Text>
          <Text
            className="campaignName"
            fontWeight="bold"
            fontSize={24}
            lineHeight={29}
          >
            {campaignName}
          </Text>
          <CampaignConstrains
            endTime={campaignEndTime}
            typeIcon={campaignTypeIcon}
            typeName={campaignTypeName}
            startDate={campaignStartDate}
            startTime={campaignStartTime}
          />
        </div>
        <div className="momentsList">
          <table>
            <thead>
              <tr>
                <th>
                  <Text
                    color={PURPLE_FEEK}
                    fontSize={13}
                    fontWeight="bold"
                    lineHeight={16}
                  >
                    Feeker
                  </Text>
                </th>
                <th>
                  <Text
                    color={PURPLE_FEEK}
                    fontSize={13}
                    fontWeight="bold"
                    lineHeight={16}
                  >
                    Día
                  </Text>
                </th>
                <th>
                  <Text
                    color={PURPLE_FEEK}
                    fontSize={13}
                    fontWeight="bold"
                    lineHeight={16}
                  >
                    Hora
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {moments.map((item) => (
                <tr key={item._id}>
                  <td>
                    <div className="feekerData">
                      <Avatar
                        image={item.user.profile.instagram.avatar}
                        size={30}
                      />
                      <Text
                        fontWeight="bold"
                        fontSize={13}
                        lineHeight={16}
                        className="username"
                      >
                        {item.user.profile.instagram.username}
                      </Text>
                    </div>
                  </td>
                  <td>
                    <Text
                      fontSize={13}
                      lineHeight={16}
                      className="day"
                    >
                      {moment(item.reservation_day).format(
                        'dddd DD, MMM',
                      )}
                    </Text>
                  </td>
                  <td>
                    <Text fontSize={13} lineHeight={16}>
                      {moment(item.reservation_time, [
                        'HH:mm',
                      ]).format('hh:mm A')}
                    </Text>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
}

CampaignBookingOverviewModal.propTypes = {
  isOpen: PropTypes.bool,
  moments: PropTypes.array,
  handleClose: PropTypes.func,
  campaignName: PropTypes.string,
  campaignStatus: PropTypes.string,
  campaignEndTime: PropTypes.string,
  campaignTypeIcon: PropTypes.string,
  campaignTypeName: PropTypes.string,
  campaignStartDate: PropTypes.string,
  campaignStartTime: PropTypes.string,
};

export default CampaignBookingOverviewModal;
