/** @jsxImportSource @emotion/react */
import * as Yup from 'yup';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';

import APIClient from 'redux/api';
import Text from 'components/Text';
import handleError from 'utils/handleError';
import { MEDIA_QUERIES } from 'styles/constants';
import Modal from 'components/modals/Modal/index';
import { FormCardInput } from 'components/inputs/CardInput';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { FormInputText } from 'components/inputs/InputText/index';
import { GRADIENT_PURPLE, PURPLE_FEEK, WHITE } from 'styles/colors';

const formValidationSchema = Yup.object().shape({
  cardName: Yup.string().required('Campo requerido '),
  isCardNumberEmpty: Yup.bool().oneOf([false], 'Campo requerido'),
  isCardCvcEmpty: Yup.bool().oneOf([false], 'Campo requerido'),
  isCardExpiryEmpty: Yup.bool().oneOf([false], 'Campo requerido'),
  isPrivacyAccepted: Yup.bool().oneOf([true], 'Campo requerido'),
  name: Yup.string().when('billable', {
    is: true,
    then: Yup.string().required('Campo requerido'),
  }),
});

const formInitialValues = {
  coupon: '',
  cardName: '',
  isCardNumberEmpty: true,
  isCardCvcEmpty: true,
  isCardExpiryEmpty: true,
};

const styles = css`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 40px;
  background: ${WHITE};
  border-radius: 8px;

  .form {
    width: 100%;

    .mb {
      margin-bottom: 20px;
    }

    .rowContainer {
      width: 100%;

      .label {
        margin-bottom: 10px;
      }
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }

    .termsBtn {
      display: inline;
      margin-left: 5px;
    }
  }

  .buttonsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }

  ${MEDIA_QUERIES.mobile} {
    width: 100%;
    padding: 40px 24px;
  }
`;

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_KEY}`,
  {
    locale: 'es',
  },
);

function CardForm({ onCancel, onSave }) {
  const selectedFeekspotId = useSelector(
    (state) => state.managerApp.selectedFeekspotId,
  );

  const elements = useElements();
  const stripe = useStripe();

  const handleFormSubmit = async (values, actions) => {
    actions.resetForm({ values });
    actions.setSubmitting(true);
    try {
      const stripeResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        metadata: {
          cardName: values.cardName,
        },
      });

      const { paymentMethod, error } = stripeResponse;

      if (error) {
        throw new Error(error.message);
      }

      const creditCardResponse = await APIClient.saveCreditCardMethod(
        {
          payment_method_id: paymentMethod.id,
          customer_id: selectedFeekspotId,
        },
      );

      actions.setSubmitting(false);
      if (creditCardResponse.status !== 200) {
        throw new Error(
          'Hubo un error actualizando el metodo de pago',
        );
      }
      onSave(paymentMethod.card);
    } catch (error) {
      actions.setSubmitting(false);
      handleError(error);
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
      validateOnMount
    >
      {(formik) => (
        <Form>
          <div className="form">
            <Text
              align="center"
              fontSize={24}
              fontWeight="bold"
              className="rowContainer mb"
            >
              Método de Pago
            </Text>
            <div className="rowContainer">
              <Text fontSize={16} className="label">
                Nombre en tarjeta
              </Text>
              <FormInputText name="cardName" />
            </div>
            <div className="rowContainer">
              <Text fontSize={16} className="label">
                Número de tarjeta
              </Text>
              <FormCardInput
                type="number"
                placeholder="XXXX XXXX XXXX XXXX"
                name="isCardNumberEmpty"
                showIcon
              />
            </div>
            <div className="rowContainer grid">
              <div>
                <Text fontSize={16} className="label">
                  Fecha de expiración
                </Text>
                <FormCardInput
                  type="expiry"
                  placeholder="MM/AA"
                  name="isCardExpiryEmpty"
                />
              </div>
              <div>
                <Text fontSize={16} className="label">
                  CVV
                </Text>
                <FormCardInput
                  type="cvc"
                  placeholder="• • •"
                  name="isCardCvcEmpty"
                />
              </div>
            </div>
            <div className="buttonsWrapper">
              <ButtonPrimary
                color={WHITE}
                textColor={PURPLE_FEEK}
                fontSize={16}
                label="Cancelar"
                onClick={onCancel}
                className="button"
                strokeVariant
              />
              <ButtonPrimary
                disabled={!formik.isValid || formik.isSubmitting}
                color={GRADIENT_PURPLE}
                label="Guardar"
                fontSize={16}
                onClick={formik.submitForm}
                className="button"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
function ModalPaymentMethod({ show, handleClose, onSave }) {
  return (
    <Modal visible={show} onRequestClose={handleClose}>
      <div css={styles}>
        <Elements stripe={stripePromise}>
          <CardForm onCancel={handleClose} onSave={onSave} />
        </Elements>
      </div>
    </Modal>
  );
}

ModalPaymentMethod.propTypes = {
  handleClose: PropTypes.func,
  onSave: PropTypes.func,
  show: PropTypes.bool,
};

CardForm.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};

export default ModalPaymentMethod;
