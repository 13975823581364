/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import {
  BLACK,
  WHITE,
  GREEN_STATUS,
  YELLOW_MARKER,
  GRAY_LIGHTEST,
} from 'styles/colors';
import Text from 'components/Text';
import Icon from 'components/Icon';
import { campaignTypeMiniIconsByName } from 'constants';
import ImagePlaceholder from 'assets/images/ImagePlaceholder.jpg';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';

const bannerStyles = css`
  width: 500px;
  display: flex;
  padding: 30px;
  flex-wrap: wrap;
  margin-left: 16px;
  border-radius: 16px;
  flex-direction: row;

  .bannerMainText {
    text-transform: uppercase;
  }

  .bannerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }

  .bannerHeaderLogo {
    width: 50px;
    border-radius: 9px;
    border-width: 1px;
    overflow: hidden;
    border-color: ${GRAY_LIGHTEST};
  }

  .button {
    height: 24px;
    display: flex;
    border-radius: 8px;
    flex-direction: row;
    background-color: ${WHITE};
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  .image {
    height: 160px;
    width: 160px;
    border-radius: 8px;
  }

  .bannerData {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
  }
  .bannerDataFooter {
    display: flex;
    justify-content: space-between;
  }

  .buttonText {
    padding-top: 1px;
  }

  .bannerTypeIcon {
    opacity: 0.2;
    margin-left: auto;
  }
`;

const BannerExperience = (props) => {
  const {
    logo,
    image,
    keyIcon,
    mainText,
    className,
    buttonText,
    buttonLink,
    secondaryText,
    mainTextColor,
    buttonTextColor,
    backgroundColor,
    secondaryTextColor,
  } = props;

  return (
    <div
      css={[
        bannerStyles,
        {
          backgroundColor: backgroundColor,
          ...(backgroundColor === GREEN_STATUS && {
            borderWidth: 1,
            overflow: 'hidden',
            borderColor: YELLOW_MARKER,
          }),
        },
      ]}
      className={`banner ${className}`}
    >
      <div className="bannerHeader">
        <Text
          fontSize={24}
          fontWeight="700"
          color={mainTextColor}
          className="bannerMainText"
        >
          {mainText}
        </Text>
        <img
          className="bannerHeaderLogo"
          src={logo || ImagePlaceholder}
        />
      </div>

      <div className="d-flex  w-100">
        <img
          className="image me-2"
          src={image || ImagePlaceholder}
          alt={'Feek | ' + mainText}
        />

        <div className="bannerData w-100">
          <Text fontSize={18} color={secondaryTextColor}>
            {secondaryText}
          </Text>

          <div className="bannerDataFooter">
            {buttonLink && buttonText && (
              <a
                href={buttonLink}
                className="text-decoration-none button"
              >
                <Text
                  fontSize={12}
                  lineHeight={12}
                  fontWeight="600"
                  color={buttonTextColor}
                  className="buttonText"
                >
                  {buttonText}
                </Text>
                <Icon
                  icon={Chevron}
                  size={8}
                  color={BLACK}
                  rotate={-90}
                  className="ms-2"
                />
              </a>
            )}
            <Icon
              icon={
                campaignTypeMiniIconsByName[keyIcon || 'FEEK_POINTS']
              }
              size={32}
              color={BLACK}
              className="bannerTypeIcon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BannerExperience.propTypes = {
  logo: PropTypes.string,
  image: PropTypes.string,
  keyIcon: PropTypes.string,
  mainText: PropTypes.string,
  className: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  secondaryText: PropTypes.string,
  mainTextColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  secondaryTextColor: PropTypes.string,
};

export default BannerExperience;
