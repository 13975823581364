/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import {
  BLACK,
  WHITE,
  GRAY_MID,
  GRAY_COLD,
  WHITE_ALMOST,
  GREEN_WHATSAPP,
  RED_NOTIFICATIONS,
} from 'styles/colors';
import { useState } from 'react';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { titlesFeekApp } from 'constants';
import { MEDIA_QUERIES } from 'styles/constants';
import AccordionStatus from 'components/AccordionStatus';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ROUTES_AGNOSTIC, ROUTES_FEEKAPP } from 'constants/routes';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import { ReactComponent as WhatsApp } from 'assets/icons/WhatsApp.svg';
import ModalDeleteFeekerAccount from 'components/modals/ModalDeleteFeekerAccount';

const styles = css`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 24px;
  background-color: ${GRAY_COLD};
  padding-block: 48px;
  --grid-full-row: 1 / 3;

  .whatsapp-help,
  .main-info {
    background-color: ${WHITE};
    border-radius: 20px;
    padding: 24px;
  }

  .whatsapp-help {
    flex: 0 1 min(20%, 330px);

    .whatsapp-button {
      margin-block-end: 12px;
    }
  }

  .main-info {
    flex: 0 1 min(45%, 686px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    .heading {
      grid-column: var(--grid-full-row);
      margin-block-start: 12px;
    }

    .about-feek-accordion {
      grid-column: var(--grid-full-row);
      background-color: ${WHITE};
      border: 1px solid ${GRAY_MID};
      border-radius: 20px;
      padding-inline: 20px;

      .chevron {
        background-color: ${WHITE_ALMOST};
        border-radius: 50%;
        width: 32px;
        height: 32px;
        padding-inline: 8px;
      }

      .content {
        padding-block-end: 20px;

        > *:not(:last-of-type) {
          margin-block-end: 16px;
        }
      }
    }

    .chevron-link {
      padding-inline: 9px;
      border-radius: 50%;
      background-color: ${WHITE_ALMOST};
    }

    .delete-account {
      grid-column: var(--grid-full-row);
    }
  }

  .underlined {
    text-decoration: underline;
  }

  ${MEDIA_QUERIES.mobile} {
    --grid-full-row: 1;

    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
    gap: 0;
    padding: 16px;

    .whatsapp-help,
    .main-info {
      padding: 16px;
      width: 100%;
    }

    .whatsapp-help {
      border-radius: 0 0 16px 16px;

      .whatsapp-button {
        border-radius: 100vmax;
      }
    }

    .main-info {
      display: grid;
      grid-template-columns: 1fr;
      gap: 12px;
      border-radius: 16px 16px 0 0;

      .heading {
        grid-column: var(--grid-full-row);
        margin-block-start: 12px;
      }

      .about-feek-accordion {
        grid-column: var(--grid-full-row);
        background-color: ${WHITE};
        border: 1px solid ${GRAY_MID};
        border-radius: 20px;
        padding-inline: 20px;

        .chevron {
          background-color: ${WHITE_ALMOST};
          border-radius: 50%;
          width: 32px;
          height: 32px;
          padding-inline: 8px;
        }

        .content {
          padding-block-end: 20px;

          > *:not(:last-of-type) {
            margin-block-end: 16px;
          }
        }
      }

      .chevron-link {
        padding-inline: 9px;
        border-radius: 50%;
        background-color: ${WHITE_ALMOST};
      }

      .delete-account {
        grid-column: var(--grid-full-row);
      }
    }

    .underlined {
      text-decoration: underline;
    }
  }
`;

function Info() {
  const [showModal, setShowModal] = useState(false);
  document.title = titlesFeekApp.ProfileFeeker;

  return (
    <div css={styles}>
      <ModalDeleteFeekerAccount
        onRequestClose={() => setShowModal(false)}
        visible={showModal}
      />
      <aside className="whatsapp-help">
        <a
          href={`https://wa.me/${process.env.REACT_APP_WHATSAPP_HELP_NUMBER}/?text=%C2%A1Hola%2C%20Feek!%20%F0%9F%93%B1`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ButtonPrimary
            layoutOnly
            className="whatsapp-button"
            color={GREEN_WHATSAPP}
            icon={<Icon icon={WhatsApp} color={WHITE} />}
            label="Ayuda"
            iconPosition="right"
            distribution="space-between"
          />
        </a>

        <Link to={ROUTES_FEEKAPP.PROFILE_FEEKER_EDIT}>
          <Text
            align="center"
            className="underlined"
            fontSize={16}
            color={BLACK}
          >
            Regresar a perfil
          </Text>
        </Link>
      </aside>

      <main className="main-info">
        <Text
          className="heading"
          fontSize={21}
          fontSizeMobile={16}
          fontWeight={600}
          color={BLACK}
        >
          Información
        </Text>

        <AccordionStatus
          height={72}
          className="about-feek-accordion"
          title="Acerca de Feek"
          titleProps={{
            color: BLACK,
            lineHeight: 72,
            fontSize: 16,
            fontWeight: 500,
          }}
          content={
            <>
              <Text
                lineHeight={22.4}
                fontSize={16}
                fontWeight={300}
                color={BLACK}
              >
                Feek es la plataforma digital que conecta a personas
                increíbles con Momentos únicos y Experiencias
                exclusivas.
              </Text>
              <Text
                lineHeight={22.4}
                fontSize={16}
                fontWeight={300}
                color={BLACK}
              >
                Cientos de negocios tienen para ti ofertas y
                promociones a las que podrás acceder a cambio de
                stories en Instagram — Momentos Feek para vivir y
                compartir.
              </Text>
              <Text
                lineHeight={22.4}
                fontSize={16}
                fontWeight={300}
                color={BLACK}
              >
                Completando Momentos Feek obtendrás puntos e
                increíbles recompensas: Experiencias como festivales,
                viajes, conciertos ¡y mucho más!
              </Text>
              <Text
                lineHeight={22.4}
                fontSize={16}
                fontWeight={300}
                color={BLACK}
              >
                No olvides seguirnos en Instagram para enterarte de
                todas las sorpresas que tenemos para ti – @feekmx
              </Text>
            </>
          }
        />

        <Text
          className="heading"
          fontSize={21}
          fontSizeMobile={16}
          fontWeight={600}
          color={BLACK}
        >
          Legales
        </Text>

        <Link className="" to={ROUTES_AGNOSTIC.TERMS_AND_CONDITIONS}>
          <ButtonPrimary
            pillShape
            label="Términos y condiciones"
            layoutOnly
            iconPosition="right"
            distribution="space-between"
            strokeVariant
            strokeColor={BLACK}
            textColor={BLACK}
            textProps={{ fontWeight: 500 }}
            icon={
              <Icon
                icon={Chevron}
                className="chevron-link"
                rotate={-90}
                color={BLACK}
                size={32}
              />
            }
          />
        </Link>

        <Link className="" to={ROUTES_AGNOSTIC.POLICY}>
          <ButtonPrimary
            pillShape
            label="Aviso de privacidad"
            layoutOnly
            iconPosition="right"
            distribution="space-between"
            strokeVariant
            strokeColor={BLACK}
            textColor={BLACK}
            textProps={{ fontWeight: 500 }}
            icon={
              <Icon
                icon={Chevron}
                className="chevron-link"
                rotate={-90}
                color={BLACK}
                size={32}
              />
            }
          />
        </Link>

        <Text
          className="heading"
          fontSize={21}
          fontSizeMobile={16}
          fontWeight={600}
          color={BLACK}
        >
          Mi cuenta
        </Text>

        <ButtonPrimary
          pillShape
          label="Eliminar mi cuenta"
          strokeVariant
          strokeColor={RED_NOTIFICATIONS}
          textColor={RED_NOTIFICATIONS}
          textProps={{ fontWeight: 500 }}
          className="delete-account"
          onClick={() => setShowModal(true)}
        />
      </main>
    </div>
  );
}

export default Info;
