/** @jsxImportSource @emotion/react */
import {
  TwitterIcon,
  FacebookIcon,
  WhatsappIcon,
  TelegramIcon,
  TwitterShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from 'react-share';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Icon from 'components/Icon';
import Text from 'components/Text';
import Modal from 'components/modals/Modal/index';
import copyToClipboard from 'utils/copyToClipboard';
import { BLACK, GRAY_MID, WHITE } from 'styles/colors';
import { ReactComponent as Email } from 'assets/icons/Email.svg';
import { ReactComponent as StackedSheets } from 'assets/icons/StackedSheets.svg';

const styles = css`
  width: 550px;
  padding: 20px 40px;
  max-width: 650px;
  background-color: ${WHITE};

  a {
    text-decoration: none;
  }

  .close-icon {
    display: flex;
    justify-content: flex-end;
  }

  .close-icon-body {
    width: 24px;
    height: 24px;
  }

  .image {
    border-radius: 8px;
    width: 70px;
  }

  .button {
    gap: 8px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 14px;
    border-radius: 8px;
    display: flex;
    justify-content: start !important;
    align-items: center !important;
    padding: 20px 40px !important;
    border: 1px solid ${GRAY_MID} !important;
  }
`;
export default function ModalShare({
  url,
  body,
  header,
  visible,
  messageText,
  onRequestClose,
  headerClassName,
}) {
  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <div css={styles}>
        <Text
          color={BLACK}
          fontSize={24}
          fontSizeMobile={20}
          fontWeight={600}
          className={headerClassName}
        >
          {header}
        </Text>

        {body}

        <div className="row mt-4">
          <div className="col-md-6">
            <div
              className="button"
              onClick={() => copyToClipboard(window.location.href)}
            >
              <Icon
                size={20}
                color={BLACK}
                className="me-2"
                icon={StackedSheets}
              />
              <Text fontSize={16} color={BLACK} fontWeight={500}>
                Copiar enlace
              </Text>
            </div>
          </div>

          <div className="col-md-6">
            <a
              className="button"
              target="_blank"
              href={`mailto:example@mail.com?body=${messageText} ${url}`}
              rel="noreferrer"
            >
              <Icon
                size={20}
                color={BLACK}
                className="me-2"
                icon={Email}
              />
              <Text fontSize={16} color={BLACK} fontWeight={500}>
                Email
              </Text>
            </a>
          </div>

          <div className="col-md-6">
            <FacebookShareButton url={url} className="button">
              <FacebookIcon size={22} round className="me-2" />
              <Text fontSize={16} color={BLACK} fontWeight={500}>
                Facebook
              </Text>
            </FacebookShareButton>
          </div>

          <div className="col-md-6">
            <WhatsappShareButton
              url={url}
              title={messageText}
              className="button"
              formTarget="_blank"
              windowWidth={900}
              windowHeight={700}
            >
              <WhatsappIcon
                size={22}
                className="me-2"
                borderRadius={8}
              />
              <Text fontSize={16} color={BLACK} fontWeight={500}>
                WhatsApp
              </Text>
            </WhatsappShareButton>
          </div>
          <div className="col-md-6">
            <TwitterShareButton
              url={url}
              className="button"
              windowWidth={900}
              windowHeight={700}
              title={messageText}
              hashtags={['feek', 'feeker']}
              related={['@feekmx']}
            >
              <TwitterIcon size={22} round className="me-2" />
              <Text fontSize={16} color={BLACK} fontWeight={500}>
                Twitter
              </Text>
            </TwitterShareButton>
          </div>
          <div className="col-md-6">
            <TelegramShareButton
              url={url}
              className="button"
              windowWidth={900}
              windowHeight={700}
              title={messageText}
            >
              <TelegramIcon size={22} round className="me-2" />
              <Text fontSize={16} color={BLACK} fontWeight={500}>
                Telegram
              </Text>
            </TelegramShareButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ModalShare.propTypes = {
  body: PropTypes.node,
  url: PropTypes.string,
  visible: PropTypes.bool,
  header: PropTypes.string,
  messageText: PropTypes.string,
  onRequestClose: PropTypes.func,
  headerClassName: PropTypes.string,
};
