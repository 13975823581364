/** @jsxImportSource @emotion/react */
import APIClient from 'redux/api';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  GRAY,
  WHITE,
  BLACK,
  GRAY_MID,
  GRAY_COLD,
  GRAY_DARK,
  TRANSPARENT,
  PURPLE_FEEK,
  RED_NOTIFICATIONS,
} from 'styles/colors';
import {
  setCampaigns,
  setFeekspots,
  setCategories,
  setFeekspotLike,
  setCampaignTypes,
} from 'redux/entities';
import Text from 'components/Text';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import { titlesFeekApp } from 'constants';
import handleError from 'utils/handleError';
import Button from 'components/buttons/Button';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_FEEKAPP } from 'constants/routes';
import copyToClipboard from 'utils/copyToClipboard';
import NoDataMessage from 'components/NoDataMessage';
import { formatPhone, formatLocation } from 'utils/format';
import { AvatarsCabinet } from 'components/AvatarsCabinet';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import DistanceIndicator from 'components/DistanceIndicator';
import { ReactComponent as Link } from 'assets/icons/Link.svg';
import { ReactComponent as Phone } from 'assets/icons/Phone.svg';
import ImagePlaceholder from 'assets/images/ImagePlaceholder.jpg';
import CardCampaignSmall from 'components/cards/CardCampaignSmall';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import { ReactComponent as Location } from 'assets/icons/Location.svg';
import { ReactComponent as Instagram } from 'assets/icons/Instagram.svg';
import { ReactComponent as HeartBicolor } from 'assets/icons/HeartBicolor.svg';
import { ReactComponent as StackedSheets } from 'assets/icons/StackedSheets.svg';
import ProgressIndicatorSpinnerMessage from 'components/ProgressIndicatorSpinnerMessage';

const styles = css`
  ${'' /* force main to take all possible space */}
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 24px;
  padding: 48px 50px 72px;
  background-color: ${GRAY_COLD};

  .loading-message {
    background-color: ${WHITE};
    align-self: center;
    padding: 30px 40px;
  }

  .feekspot-background {
    width: 100%;
    grid-column: var(--grid-full-row);
    aspect-ratio: 2/1;
    border-radius: var(--border-radius);
    object-fit: cover;
  }

  .feekspot-image-container {
    margin-left: 24px;
    border-radius: 10px;
    margin-top: -70px;
  }

  .feekspot-profile {
    --vertical-gap: 30px;
    --horizontal-gap: 24px;
    --grid-full-row: 1 / 3;
    --border-radius: 10px;
    --border-radius-big: 20px;
    grid: auto-flow / repeat(2, minmax(0, 1fr));
    justify-content: space-between;
    gap: var(--horizontal-gap) var(--vertical-gap);
    padding: 24px;
    width: 686px;
    background-color: ${WHITE};
    border-radius: var(--border-radius-big);
  }
  .flex-align-center-justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .about-feekspot {
    margin-bottom: 24px;
  }
  .feekspot-name {
    margin-top: 16px;
    text-transform: uppercase;
  }
  .instagram-badge {
    gap: 6px;
    display: flex;
    width: fit-content;
  }
  .address {
    margin-top: 8px;
    display: flex;
  }
  .profile-modal-button {
    flex: 1;
    justify-content: space-between;
    border-radius: 24px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .menu-badge {
    gap: 6px;
    display: flex;
    padding: 8px 12px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background-color: ${WHITE};
    border: 1px solid ${GRAY_MID};
  }

  .contact-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .contact-item:nth-child(1) {
    margin-bottom: 12px;
  }
  .contact-item:nth-child(2) {
    border-top: 1px solid ${GRAY_MID};
    padding-top: 12px;
  }
  .contact-info-icon {
    padding: 9px;
    border-radius: 8px;
    border: 1px solid ${GRAY_MID};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }
  .card {
    padding: 24px;
    border-radius: 16px;
    border: 1px solid ${GRAY_MID};
  }

  .avatar-cabinet {
    width: 48px;
  }

  ${MEDIA_QUERIES.mobile} {
    padding: 0;
    background-color: ${WHITE};

    .feekspot-name {
      text-align: center;
    }

    .address {
      padding-bottom: 4px;
      justify-content: center;
    }

    .instagram-badge {
      margin: 0 auto;
    }

    .feekspot-image-container {
      margin-left: calc(50% - 70px);
    }

    .card {
      padding: 16px;
    }
  }
`;

export default function ProfileFeekspot() {
  const { instagramUsername } = useParams();
  const dispatch = useDispatch();

  const [feekers, setFeekers] = useState({
    feekersAvatars: Array(7),
    totalFeekersCount: 0,
  });
  const [id, setId] = useState('');
  const [feekspotCampaigns, setFeekspotCampaigns] = useState([]);
  const [feekspotInfoOpen, setFeekspotInfoOpen] = useState(false);
  const [loadingLike, setLoadingLike] = useState(false);

  const { feekspots, categories } = useSelector(
    (state) => state.entities,
  );

  const feekspot = feekspots[id]?.customer;

  const navigate = useNavigate();

  document.title =
    (feekspot?.name ? `${feekspot?.name} ` : '') +
    (feekspot?.instagram?.username
      ? `(@${feekspot?.instagram?.username}) • `
      : '') +
    titlesFeekApp.ProfileFeekspot;

  useEffect(() => {
    APIClient.getFeekspotForFeekerByInstagramUsername(
      instagramUsername,
    )
      .then(({ data }) => {
        dispatch(setFeekspots([{ customer: data }]));
        setId(data._id);
        return data._id;
      })
      .then((id) => {
        APIClient.feekspotGetLike(id).then(
          ({ data: { is_liked, customer } }) => {
            dispatch(
              setFeekspotLike({
                feekspotId: customer,
                isLiked: is_liked,
              }),
            );
          },
        );

        APIClient.getFeekspotLikedFeekers({
          customerId: id,
          limit: 5,
        }).then(({ data: { docs, totalDocs } }) => {
          setFeekers({
            feekersAvatars: docs
              .slice(0, 7)
              .map(({ avatar }) => avatar),
            totalFeekersCount:
              totalDocs > 999
                ? `+${Math.floor(totalDocs / 1000)}k`
                : totalDocs > 99
                ? `+${Math.floor(totalDocs / 10) * 10}`
                : totalDocs,
          });
        });

        APIClient.getCampaignsFeekspot(id).then((resp) => {
          dispatch(setCampaigns(resp?.data));
          setFeekspotCampaigns(resp?.data);

          if (resp?.data.length === 0)
            APIClient.getCategories().then(({ data }) => {
              dispatch(setCategories(data));
            });
        });
      })
      .catch((error) => {
        handleError(error);
        if (
          error.message.includes(
            'No logramos encontrar la información del cliente inténtelo más tarde.',
          )
        )
          navigate(ROUTES_FEEKAPP.EXPLORE);
      });

    APIClient.getCampaignTypes()
      .then(({ data }) => {
        dispatch(setCampaignTypes(data));
      })
      .catch(handleError);
  }, []);

  const handleLikeFeekspot = useCallback(() => {
    setLoadingLike(true);
    APIClient.setFeekspotLike(feekspot._id, !feekspot.is_liked)
      .then(({ data: { customer, is_liked } }) => {
        dispatch(
          setFeekspotLike({
            feekspotId: customer,
            isLiked: is_liked,
          }),
        );
      })
      .catch(handleError)
      .finally(() => setLoadingLike(false));
  }, [feekspot]);

  return (
    <main css={styles}>
      {!feekspot ? (
        <ProgressIndicatorSpinnerMessage className="loading-message" />
      ) : (
        <article className="feekspot-profile">
          <img
            className="feekspot-background"
            src={feekspot?.background_image || ImagePlaceholder}
            alt={'Feek ' + feekspot?.name}
          />
          <div className="row mb-4">
            <div className="col col-md-auto">
              <div className="flex-align-center-justify-between mb-20">
                <Avatar
                  size={140}
                  image={feekspot?.image}
                  borderWidth={4}
                  className="feekspot-image-container"
                  alt={'Feek ' + feekspot?.name}
                />
              </div>
            </div>
            <div className=" col-lg-8">
              <Text
                color={BLACK}
                fontSize={21}
                fontWeight={600}
                className="feekspot-name"
              >
                {feekspot?.name}
              </Text>
              <div className="address mb-2">
                <Text color={GRAY} fontSize={14} fontWeight={400}>
                  {[
                    feekspot?.location?.city,
                    feekspot?.location?.state,
                  ]
                    .filter((item) => item)
                    .join(', ')}
                </Text>
                {feekspot && (
                  <DistanceIndicator
                    color={GRAY}
                    fontSize={12}
                    distance={feekspot?.distance}
                    className="ms-2"
                  />
                )}
              </div>

              {feekspot?.instagram?.username && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="instagram-badge"
                  href={`https://www.instagram.com/${feekspot?.instagram?.username}`}
                >
                  <Button
                    layoutOnly
                    className="d-flex  align-items-top"
                  >
                    <Icon
                      icon={Instagram}
                      color={PURPLE_FEEK}
                      size={20}
                    />
                    <Text
                      fontSize={16}
                      fontWeight={600}
                      color={PURPLE_FEEK}
                      className="ms-1"
                    >
                      @{feekspot?.instagram?.username}
                    </Text>
                  </Button>
                </a>
              )}
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-6">
              <ButtonPrimary
                className="profile-modal-button"
                fontSize={16}
                fontSizeMobile={14}
                height={48}
                label="Información"
                textColor={BLACK}
                strokeVariant
                strokeColor={GRAY_MID}
                onClick={() => setFeekspotInfoOpen(!feekspotInfoOpen)}
                icon={
                  <Icon
                    icon={Chevron}
                    color={BLACK}
                    width={18}
                    rotate={feekspotInfoOpen ? 180 : 0}
                  />
                }
                iconPosition="right"
              />
            </div>
            <div className="col-6">
              <ButtonPrimary
                className="profile-modal-button"
                fontSize={16}
                fontSizeMobile={14}
                height={48}
                strokeVariant
                strokeColor={GRAY_MID}
                iconPosition="right"
                onClick={handleLikeFeekspot}
                disabled={loadingLike}
                {...(feekspot?.is_liked
                  ? {
                      label: 'En favoritos',
                      textColor: BLACK,
                      color: WHITE,
                      icon: (
                        <Icon
                          size={18}
                          icon={HeartBicolor}
                          color={RED_NOTIFICATIONS}
                          secondaryColor={RED_NOTIFICATIONS}
                        />
                      ),
                    }
                  : {
                      label: 'Agregar a favoritos',
                      textColor: WHITE,
                      color: GRAY_DARK,
                      icon: (
                        <Icon
                          size={18}
                          icon={HeartBicolor}
                          color={TRANSPARENT}
                          secondaryColor={WHITE}
                        />
                      ),
                    })}
              />
            </div>
          </div>

          {feekspotInfoOpen && (
            <div className="about-feekspot">
              <Text fontSize={16} fontWeight={400} color={BLACK}>
                {feekspot?.description}
              </Text>
              <div className="card mt-2">
                {feekspot?.menu && (
                  <div className="flex-align-center-justify-between mb-4">
                    <Text
                      color={BLACK}
                      fontSize={16}
                      fontWeight={600}
                      fontSizeMobile={14}
                    >
                      Lo que ofrecemos
                    </Text>
                    <a
                      className="menu-badge"
                      href={feekspot?.menu}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon icon={Link} color={BLACK} />
                      <Text
                        color={BLACK}
                        fontSize={14}
                        fontWeight={600}
                      >
                        Ver nuestro menú
                      </Text>
                    </a>
                  </div>
                )}
                <div className="feekspot-contact-info">
                  {feekspot?.location && (
                    <div className="contact-item">
                      <div className="d-flex align-items-center">
                        <div className="contact-info-icon me-3">
                          <Icon
                            icon={Location}
                            color={BLACK}
                            size={22}
                          />
                        </div>
                        <Text
                          color={BLACK}
                          fontSize={16}
                          fontSizeMobile={12}
                          lineHeightMobile={16}
                        >
                          {formatLocation(feekspot?.location)}
                        </Text>
                      </div>
                      <div
                        onClick={() =>
                          copyToClipboard(
                            formatLocation(feekspot?.location),
                          )
                        }
                        className="cursor-pointer"
                      >
                        <Icon
                          size={24}
                          color={GRAY_DARK}
                          icon={StackedSheets}
                          className="ms-1"
                        />
                      </div>
                    </div>
                  )}
                  {feekspot?.phone && (
                    <div className="contact-item">
                      <div className="d-flex align-items-center">
                        <div className="contact-info-icon me-3">
                          <Icon
                            icon={Phone}
                            color={BLACK}
                            size={22}
                            sizeMobile={20}
                          />
                        </div>
                        <Text
                          color={BLACK}
                          fontSize={16}
                          fontSizeMobile={12}
                          lineHeightMobile={16}
                        >
                          {formatPhone(feekspot?.phone)}
                        </Text>
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          copyToClipboard(feekspot?.phone)
                        }
                      >
                        <Icon
                          size={24}
                          sizeMobile={20}
                          color={GRAY_DARK}
                          icon={StackedSheets}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {feekspotCampaigns.length > 0 ? (
            <>
              <div className="d-flex justify-content-between mb-4 align-items-center">
                <Text fontSize={21} fontWeight={600} color={BLACK}>
                  Promociones
                </Text>
                <Text
                  fontSize={14}
                  fontWeight={500}
                  color={GRAY}
                  className="text-end"
                >
                  {feekspotCampaigns?.length}{' '}
                  {feekspotCampaigns?.length === 1 ? 'item' : 'items'}
                </Text>
              </div>
              <div>
                <div className=" col-lg-12">
                  {feekspotCampaigns?.length > 0 &&
                    feekspotCampaigns.map((campaign) => (
                      <div className="mb-3" key={campaign._id}>
                        <CardCampaignSmall campaign={campaign._id} />
                      </div>
                    ))}
                </div>
              </div>
            </>
          ) : (
            <NoDataMessage
              emoji="🥳"
              message="Estamos trabajando en nuevas promociones."
              buttonLabel="Explorar similares"
              redirectTo={
                categories[feekspot?.categories[0]]?.name
                  ? ROUTES_FEEKAPP.CATEGORY.replace(
                      ':category',
                      categories[feekspot?.categories[0]]?.name || '',
                    )
                  : ROUTES_FEEKAPP.EXPLORE
              }
              className="mb-4"
            />
          )}
          <div className="flex-align-center-justify-between mb-20 mt-4 align-items-center">
            <Text
              color={GRAY}
              fontSize={21}
              fontWeight={600}
              fontSizeMobile={16}
            >
              Nuestra comunidad
            </Text>
            <div>
              <AvatarsCabinet
                className="avatar-cabinet"
                text={
                  feekers.totalFeekersCount - 3 > 0 &&
                  '+' + (feekers.totalFeekersCount - 3).toString()
                }
                avatars={feekers.feekersAvatars.slice(0, 3)}
                textBGColor={GRAY_DARK}
              />
            </div>
          </div>
        </article>
      )}
    </main>
  );
}
