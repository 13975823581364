/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import {
  BLACK,
  WHITE,
  GRAY_COLD,
  GRAY_LIGHT,
  PURPLE_FEEK,
} from 'styles/colors';
import Text from 'components/Text';
import Icon from 'components/Icon';
import { experienceTypeIcons } from 'constants';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import ImagePlaceholder from 'assets/images/ImagePlaceholder.jpg';
import { ReactComponent as Chevron } from 'assets/icons/Chevron.svg';
import { ReactComponent as FeekLogo } from 'assets/icons/FeekLogo.svg';
import { ReactComponent as GiftMini } from 'assets/icons/GiftMini.svg';

const styles = css`
  min-width: 449px;
  width: 449px;
  padding: 20px;
  border-radius: 20px;
  background-color: ${GRAY_COLD};

  .feek-image {
    width: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    min-width: 50px;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    border: 2px solid ${GRAY_LIGHT};
    background-color: ${PURPLE_FEEK};
  }

  .image {
    width: 140px;
    height: 140px;
    min-width: 140px;
    min-height: 140px;
    margin-right: 24px;
    border-radius: 10px;
  }

  .description {
    height: 100px;
    overflow: hidden;
  }
`;

const CardExperienceCompleted = ({
  image,
  title,
  iconType,
  className,
  iconColor,
  description,
  experienceId,
}) => {
  return (
    <div css={styles} className={className}>
      <div className="d-flex justify-content-between align-items-start">
        <Text
          color={BLACK}
          fontSize={18}
          fontWeight={700}
          className="mb-3"
        >
          {title}
        </Text>
        <div className="feek-image">
          <Icon icon={FeekLogo} color={WHITE} size={32} />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <img
          src={image ? image : ImagePlaceholder}
          title={title}
          className="image"
        />
        <div className="d-flex flex-column justify-content-between">
          <Text
            fontSize={16}
            color={BLACK}
            fontWeight={400}
            className="description"
          >
            {description.length > 125
              ? `${description.substring(0, 125).trim()}...`
              : description}
          </Text>
          <div className="d-flex justify-content-between align-items-end">
            <Link to={`/experience/${experienceId}`}>
              <ButtonPrimary
                pillShape
                layoutOnly
                color={WHITE}
                textColor={BLACK}
                label="Ver resultados"
                icon={
                  <Icon
                    size={8}
                    rotate={270}
                    color={BLACK}
                    icon={Chevron}
                  />
                }
                iconPosition="right"
              />
            </Link>

            <Icon
              size={16}
              color={iconColor}
              icon={experienceTypeIcons[iconType] ?? GiftMini}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CardExperienceCompleted.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  iconType: PropTypes.string,
  iconColor: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  experienceId: PropTypes.string,
};

export default CardExperienceCompleted;
