import {
  ROUTES_FEEKAPP,
  ROUTES_MANAGER,
  ROUTES_AGNOSTIC,
  MANAGER_SUBDOMAIN,
} from 'constants/routes';

export default function getFormattedUrl({
  hash = '',
  route = '',
  isManagerRoute = !Object.hasOwn(
    { ...ROUTES_AGNOSTIC, ...ROUTES_FEEKAPP },
    route,
  ) && Object.hasOwn(ROUTES_MANAGER, route),
}) {
  return (
    window.location.origin.replace(
      /^(?<protocol>https?:\/\/)?(www.|partners.)?(?<domain>.*)/,
      isManagerRoute
        ? '$<protocol>' + MANAGER_SUBDOMAIN + '$<domain>'
        : '$<protocol>' + '$<domain>',
    ) +
    route +
    (hash ? `#${hash}` : '')
  );
}
