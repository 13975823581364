import React from 'react';
import { useParams } from 'react-router-dom';
import { useInfiniteHits } from 'react-instantsearch-hooks';

import Text from 'components/Text';
import { BLACK, GRAY } from 'styles/colors';

export default function HitsResults() {
  const { search } = useParams();

  const { results } = useInfiniteHits();
  return (
    <>
      <Text
        color={BLACK}
        fontSize={24}
        fontWeight={600}
        className="mb-2"
      >
        {'"'}
        {search || ''}
        {'"'}
      </Text>
      <Text color={GRAY} fontSize={16} className="mb-4">
        {results.nbHits} resultados para {'"'}
        {search || ''}
        {'"'}
      </Text>
    </>
  );
}
