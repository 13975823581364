/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

import Text from 'components/Text';
import Icon from 'components/Icon';
import { MEDIA_QUERIES } from 'styles/constants';
import { propTypeLength } from 'utils/propValidation';
import { GRAY, GRAY_LIGHT, WHITE } from 'styles/colors';
import { getLength } from 'utils/cssStringManipulation';
import BlueLuminescenceBg from 'assets/images/BlueLuminescenceBg.png';
import { ReactComponent as Individual } from 'assets/icons/Individual.svg';

function Avatar({
  size,
  name,
  image,
  fullName,
  sizeMobile,
  borderWidth = '1px',
  borderRadius = '50%',
  ...restProps
}) {
  const AvatarType = image
    ? typeof image === 'string'
      ? 'img'
      : image.type
    : null;

  const styles = css`
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    width: ${size}px;
    height: ${size}px;
    border: ${getLength(borderWidth)} solid ${GRAY_LIGHT};
    border-radius: ${getLength(borderRadius)};
    overflow: hidden;

    ${image
      ? `
    object-fit: cover;
    object-position: center;`
      : `
    background: ${name ? `url(${BlueLuminescenceBg})` : GRAY};
    background-size: cover;
    background-position: center;`}

    ${sizeMobile &&
    MEDIA_QUERIES.mobile +
      `{
      width: ${sizeMobile}px;
      height: ${sizeMobile}px;
      font-size: ${sizeMobile * 0.466}px;
    }`}
  `;

  return image ? (
    <AvatarType
      src={image}
      css={styles}
      {...{ ...image.props, ...restProps }}
    />
  ) : name ? (
    <Text
      fontSize={size * 0.466}
      fontWeight="600"
      color={WHITE}
      lineHeight="100%"
      css={styles}
      {...restProps}
    >
      {name?.slice(0, fullName ? undefined : 2).toUpperCase()}
    </Text>
  ) : (
    <Icon css={styles} icon={Individual} color={WHITE} size={size} />
  );
}

Avatar.propTypes = {
  name: PropTypes.string,
  rounded: PropTypes.bool,
  fullName: PropTypes.bool,
  bgColor: PropTypes.string,
  fontColor: PropTypes.string,
  borderWidth: propTypeLength,
  sizeMobile: PropTypes.number,
  borderRadius: propTypeLength,
  size: PropTypes.number.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Avatar;
