/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';

import Icon from 'components/Icon';
import { WHITE } from 'styles/colors';
import { useNavigate } from 'react-router-dom';
import { MEDIA_QUERIES } from 'styles/constants';
import { ROUTES_MANAGER } from 'constants/routes';
import { toggleSideMenu } from 'redux/app/managerApp';
import ButtonPrimary from 'components/buttons/ButtonPrimary';
import { ReactComponent as PlusCircle } from 'assets/icons/PlusCircle.svg';
import ModalAddOnPurchase from 'components/legacy/modals/ModalAddOnPurchase';

const styles = css`
  .addCampaignButton {
    height: 48px;
    padding: 14px 22px;
    border-radius: 16px;
    gap: 16px;
  }

  ${MEDIA_QUERIES.mobile} {
    .addCampaignButton {
      height: 44px;
      padding: 12.5px 14px;
      border-radius: 8px;
      gap: 32px;
    }
  }
`;

function AddCampaignButton({
  isProfileCompleted,
  places,
  wrapperClassName,
}) {
  const dispatch = useDispatch();
  const [showModalAddonPurchase, setShowModalAddonPurchase] =
    useState(false);
  const navigate = useNavigate();

  return isProfileCompleted ? (
    <>
      <ModalAddOnPurchase
        show={showModalAddonPurchase}
        handleClose={() => setShowModalAddonPurchase(false)}
        title="Has agotado todas las Campañas de tu plan."
      />

      <div css={styles} className={wrapperClassName}>
        <ButtonPrimary
          onClick={() => {
            dispatch(toggleSideMenu());
            places
              ? navigate(ROUTES_MANAGER.ADD_CAMPAIGNS)
              : setShowModalAddonPurchase(true);
          }}
          label={'Crear Campaña'}
          icon={<Icon icon={PlusCircle} color={WHITE} />}
          iconPosition={'right'}
          className="addCampaignButton"
          fontSize={16}
        />
      </div>
    </>
  ) : null;
}

export default AddCampaignButton;

AddCampaignButton.propTypes = {
  isProfileCompleted: PropTypes.bool,
  places: PropTypes.number,
  wrapperClassName: PropTypes.string,
};
